import { ActionFunction } from "react-router-dom";
import { verifyMultiFactor } from "src/services/auth";
import { RouteActionResponse } from "src";

export const ERROR_MESSAGE_MFA_VERIFICATION = "You need to verify your MFA first.";

export const mfaAction: ActionFunction = async ({ request }) => {
  const data = Object.fromEntries(await request.formData());

  try {
    const response = await verifyMultiFactor({
      token: data.token as string,
      preventLogout: ((data.preventLogout as string) || "false") === "true"
    });
    return { response };
  } catch (error) {
    const response: RouteActionResponse<unknown> = {};
    response.error = error;

    return response;
  }
};
