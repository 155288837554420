import styled from "styled-components";
import { colorPalette as colors, ColorNames, dropShadows, ShadowNames, fonts } from "src/theme/theme";

export const StyledBrowseContent = styled.div`
  padding: 0 30px;
`;

export const StyledBrowseHeading = styled.div`
  margin-bottom: 20px;
  text-align: left;
  color: ${colors[ColorNames.GRAY5].hex};
  ${fonts.PARAGRAPH_S}

  h4 {
    margin-bottom: 8px;
    color: ${colors[ColorNames.MIDNIGHT].hex};
    ${fonts.PARAGRAPH_L}
  }
`;

export const ListingsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px 8px;
  margin-bottom: 50px;
  width: 100%;
  padding: 0 30px;
  box-sizing: content-box;
  margin-left: -30px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-height: none;

  &::-webkit-scrollbar { 
    display: none; 
  }
`;