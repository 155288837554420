import React, { useState, useEffect, useMemo } from "react";
import { useParams, useFetcher } from "react-router-dom";
import { ApolloQueryResult } from "@apollo/client";

import { useCurrentOrg } from "src/services/organizations";
import useNavigate from "src/util/useNavigate";
import { showNotice } from "src/store/alertState";
import { useGetAccounts } from "src/services/accounts";
import { useNavbar } from "src/util/useNavbar";

import { 
  Deal, 
  DealInvestor, 
  AccountRef, 
  Account,
  BankAccountsQueryVariables, 
  BankAccountsQuery,
  Organization,
  DealListingType
} from "src/generated/client";

import { coreLayoutBodyBackgroundColor } from "src/store/layoutState";
import { NavRoutes } from "src/routes/navRoutes";
import { NavigationDirection } from "src/store/navigationState";
import { ColorNames } from "src/theme/theme";

import InvestmentDetail from "./investment/InvestmentDetail";
import SecondaryDetail from "./secondary/SecondaryDetail";

import ErrorElement from "src/ErrorElement";

export type ListingDetailComponentProps = {
  deal: Deal | undefined;
  orgDeal: DealInvestor | undefined;
  currentOrg?: Organization | undefined;
  accounts: Partial<Account>[] | undefined;
  refetchAccounts: (input?: BankAccountsQueryVariables) => Promise<ApolloQueryResult<BankAccountsQuery>>
};

    //// TEMPORARY TO TEST
    // const tempDeal: Deal = {
    //   __typename:"Deal",
    //   id:"01G7Q61T8MMVMB03QB3F2MFHTK",
    //   listingType: DealListingTypes.SECONDARY,
    //   targetAmount:{
    //     display:"10,000,000",
    //     amount:1000000000
    //   },
    //   minInvestment:{
    //     display:"25,000",
    //     amount:2500000
    //   },
    //   name:"Chime",
    //   createdAt:"Thu Aug 04 2022 12:10:28 GMT-0700 (PDT)",
    //   targetedTotalReturn:{
    //     amount:15,
    //     display:"15.00"
    //   },
    //   termLength:"5 years",
    //   caption:"A diversified commercial real estate debt fund.",
    //   description:"We've partnered with WelcomeFund to bring you private CRE deals no one else has access to.<br /><br />WelcomeFund lends money to real estate developers to build and acquire commercial real estate. They have a proven track record of deploying capital to successful development ventures.",
    //   details:"Targeted returns are 15% annually for an approximate 5 year hold. Investors receive the first 8% of returns and subsequent profits are split with the fund's partners to achieve the 15% IRR.<br /><br />This exclusive investment will be executed via a Letter managed SPV. Letter will manage the process of your investment and provide support along the way.",
    //   closeDate:"Sep. 30th, 2022",
    //   infoLink:"https://letter.welcomelend.com/methodology",
    //   distributions:"Quarterly",
    //   letterFee:{
    //     amount:0.5,
    //     display:"0.5"
    //   },
    //   logoImage:"",
    //   headerImage:"",
    //   buyCommittedAmount: {
    //     display:"172,459",
    //     amount:17245900
    //   },
    //   sellCommittedAmount: {
    //     display:"54,848",
    //     amount:5484800
    //   },
    //   discount: {
    //     display:"23.34",
    //     amount:23.34
    //   },
    //   impliedValuation: {
    //     display:"6.38B",
    //     amount:638000000000
    //   },
    //   lastRound: "July 2021",
    //   lastValuation: {
    //     display:"7.04B",
    //     amount:704000000000
    //   },
    //   minBuy: {
    //     display:"25,000",
    //     amount:2500000
    //   },
    //   minSell: {
    //     display:"25,000",
    //     amount:2500000
    //   },
    //   sharePrice: {
    //     display:"4.28",
    //     amount:428
    //   },
    //   structure: "direct",
    //   buyThreshold: {
    //     display:"250,000",
    //     amount:25000000
    //   },
    //   sellThreshold: {
    //     display:"250,000",
    //     amount:25000000
    //   }
    // };
  //

export const ListingDetailErrorElement = () => {
  return (
    <ErrorElement redirect={NavRoutes.MARKETPLACE_LISTINGS} alert={true} />
  );
};

const ListingDetail = (): JSX.Element => {
  const [deal, setDeal] = useState<Deal>();
  const { data: deals, state: dealsState, load: dealsLoader } = useFetcher<Deal[]>();
  const { listingId } = useParams();
  const currentOrg = useCurrentOrg({ fullFetch: true });

  const { accounts, refetch: refetchAccounts } = useGetAccounts([
    AccountRef.Checking,
  ]);

  const navigate = useNavigate();
  const [orgDeal, setOrgDeal] = useState<DealInvestor>();

  const { updateNavbar } = useNavbar({
    back: true,
    title: deal?.name || "...",
    backgroundColor: ColorNames.WHITE,
    backAction: () =>
      navigate(NavRoutes.MARKETPLACE_LISTINGS, {
        direction: NavigationDirection.BACKWARD,
      }),
  });

  useEffect(() => {
    setTimeout(() => coreLayoutBodyBackgroundColor(ColorNames.GRAY1), 0);
  }, []);

  useEffect(() => {
    if (!deals && dealsState === "idle") dealsLoader(NavRoutes.API_DEAL);
  }, [deals, dealsState, dealsLoader]);

  useEffect(() => {
    if (!deals) return;

    const match = deals.find((d: Deal) => d.id === listingId);
    //const match = tempDeal;
    if (!!match) {
      setDeal(match);
      updateNavbar({ title: match.name });
    } else {
      showNotice("There was a problem finding this deal.", { error: true });
      navigate(NavRoutes.MARKETPLACE_LISTINGS, {
        direction: NavigationDirection.BACKWARD,
      });
    }
  }, [deals]);

  useEffect(() => {
    if (!deal) return;
    const _orgDeal = currentOrg?.deals?.find((d) => d?.id === deal.id);
    setOrgDeal(_orgDeal);
  }, [deal, currentOrg]);
  

  const detailComponent = useMemo(() => {
    if (deal?.listingType === DealListingType.Secondary) {
      return <SecondaryDetail 
        currentOrg={currentOrg}
        deal={deal}
        orgDeal={orgDeal}
        accounts={accounts}
        refetchAccounts={refetchAccounts}
      />;
    } else {
      return <InvestmentDetail 
        deal={deal}
        orgDeal={orgDeal}
        accounts={accounts}
        refetchAccounts={refetchAccounts}
      />;
    }    
  }, [deal, orgDeal, accounts, refetchAccounts]);

  return (
    <React.Fragment>{detailComponent}</React.Fragment>
  );
};

export default ListingDetail;
