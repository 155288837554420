import styled from "styled-components";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

export const ModalContainer = styled.div`
  margin: 40px 0;

  & > * {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const TermsCopy = styled.div`
  ${fonts.PARAGRAPH_XS}
  color: ${colors[ColorNames.GRAY5].hex};
  margin-bottom: 40px;
`;
