// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg width%3D%2215%22 height%3D%2213%22 viewBox%3D%220 0 15 13%22 fill%3D%22none%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E  %3Cpath fill-rule%3D%22evenodd%22 clip-rule%3D%22evenodd%22 d%3D%22M9.00016 3C6.4275 3 4.3335 5.09333 4.3335 7.66667C4.3335 10.24 6.4275 12.3333 9.00016 12.3333C11.5728 12.3333 13.6668 10.24 13.6668 7.66667C13.6668 5.09333 11.5728 3 9.00016 3ZM9.00016 11C7.16216 11 5.66683 9.50467 5.66683 7.66667C5.66683 5.82867 7.16216 4.33334 9.00016 4.33334C10.8382 4.33334 12.3335 5.82867 12.3335 7.66667C12.3335 9.50467 10.8382 11 9.00016 11ZM8.3335 5.66667H9.66683V8.33334H8.3335V5.66667ZM7.00016 0.333336H11.0002V1.66667H7.00016V0.333336ZM1.00016 4.33334H3.66683V5.66667H1.00016V4.33334ZM1.00016 9.66667H3.66683V11H1.00016V9.66667ZM0.333496 7H2.9935V8.33333H0.333496V7ZM13.1955 3.47134L11.8622 2.138L12.8048 1.19534L14.1382 2.52867L13.1955 3.47134Z%22 fill%3D%22%232B2D33%22%2F%3E%3C%2Fsvg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-Welcome-container{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;margin-bottom:.5em}.onfido-sdk-ui-Welcome-content{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;-webkit-box-flex:1;-webkit-flex:1;flex:1}@media(max-width: 479px){.onfido-sdk-ui-Welcome-content{-webkit-box-align:start;-webkit-align-items:flex-start;align-items:flex-start}}.onfido-sdk-ui-Welcome-customDescriptions{-webkit-align-self:center;align-self:center}.onfido-sdk-ui-Welcome-instructions{margin-top:2em}.onfido-sdk-ui-Welcome-instructions>ol{list-style-position:inside;padding:0}.onfido-sdk-ui-Welcome-instructions>ol li{margin-bottom:1em}.onfido-sdk-ui-Welcome-instructions>ol li::marker{color:var(--osdk-color-background-alert-info)}@media(max-width: 479px){.onfido-sdk-ui-Welcome-instructions{text-align:left}.onfido-sdk-ui-Welcome-instructions>ol{padding-left:2em}}.onfido-sdk-ui-Welcome-recordingLimit{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:start;-webkit-align-items:flex-start;align-items:flex-start}.onfido-sdk-ui-Welcome-recordingLimit>.onfido-sdk-ui-Welcome-timer{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") left no-repeat;width:1.25em;-webkit-align-self:stretch;align-self:stretch}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"container": "onfido-sdk-ui-Welcome-container",
	"content": "onfido-sdk-ui-Welcome-content",
	"customDescriptions": "onfido-sdk-ui-Welcome-customDescriptions",
	"instructions": "onfido-sdk-ui-Welcome-instructions",
	"recordingLimit": "onfido-sdk-ui-Welcome-recordingLimit",
	"timer": "onfido-sdk-ui-Welcome-timer"
};
export default ___CSS_LOADER_EXPORT___;
