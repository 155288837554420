import React, { useEffect, useState } from "react";
import { init } from "onfido-sdk-ui";
import { useOnfidoChecks, useOnfido, CreateOnfidoApplication } from "src/services/auth";

import { OnfidoContainer } from "src/pages/auth/onboarding/verify-identity/Onfido.styles";

type Props = { onClose: () => void };

const Onfido = ({ onClose }: Props) => {
  const [options, setOptions] = useState<CreateOnfidoApplication["createOnfidoApplication"] | undefined>(undefined);
  const { check } = useOnfidoChecks();
  const { loading, error, getSdkToken } = useOnfido();

  const containerId = "onfido-mount";

  const onComplete = async () => {
    const success = await check();

    if (success) {
      onClose();
    }
  };

  useEffect(() => {
    async function getToken() {
      const response = await getSdkToken();

      setOptions(response);
    }

    getToken();
  }, []);

  useEffect(() => {
    if (!options) {
      return;
    }

    const sdkHandle = init({
      token: options.sdkToken,
      containerId,
      onComplete,
      steps: [
        "welcome",
        {
          type: "document",
          options: {
            documentTypes: {
              driving_licence: true
            }
          }
        },
        "face",
        "complete"
      ]
    });

    return () => {
      sdkHandle.tearDown();
    };
  }, [options]);

  return (
    <OnfidoContainer>
      <div id={containerId} />
    </OnfidoContainer>
  );
};

export default Onfido;
