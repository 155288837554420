import { useCallback } from "react";
import { useMutation, ApolloError, DocumentNode } from "@apollo/client";
import { useLogger } from "src/util/useLogger";
import { hofundRoutes as userRoutes } from "src/services/users";
import { hofundRoutes as authRoutes } from "src/services/auth";
import { hofundRoutes as cardRoutes } from "src/services/cards";
import { hofundRoutes as orgRoutes } from "src/services/organizations";

const getHoFundRoutes = (): Record<string, DocumentNode> => {
  return {
    ...userRoutes(),
    ...authRoutes(),
    ...cardRoutes(),
    ...orgRoutes()
  };
};

// "Where’s the sword? That sword is the key to opening the Bifrost." //
export const useHoFund = <T, U>(
  path: string,
  getResponseData = false
): {
  action: (values: U) => Promise<T | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const routes = getHoFundRoutes();
  const [mutation, { loading, error }] = useMutation(routes[path], {
    errorPolicy: "all"
  });
  const { captureException } = useLogger();

  const action = useCallback(
    async (values: U): Promise<T | undefined> => {
      try {
        const { data } = await mutation({ variables: values });
        const mutant = Object.keys(data).pop();
        if (getResponseData) return !!mutant ? data[mutant] : undefined;
        else return !!mutant ? data[mutant]?.success : false;
      } catch (err) {
        captureException(err);
      }
    },
    [mutation, captureException, getResponseData]
  );

  return { action, loading, error };
};
