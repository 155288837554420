import React, { useEffect, useMemo } from "react";

import { useSetBottomSheet, useShowBottomSheet } from "src/util/useBottomSheet";
import { ColorNames } from "src/theme/theme";
import { Line } from "src/generated/client";
import { formatMonthYear, decorateCurrencyValue } from "src/util/stringUtils";

import {
  AmortizationSheetContainer,
  AmortizationSheetTitle,
  AmortizationSheetTable,
  AmortizationSheetTableHeader,
  AmortizationSheetTableCell
} from "./AmortizationSheet.styles";

import { Button } from "src/components/button/Button";


export interface AmortizationSheetProps {
  id: string;
  line?: Line;
}

const AmortizationSheet = ({
  id,
  line
}: AmortizationSheetProps): JSX.Element => {
  const setBottomSheet = useSetBottomSheet();
  const { hideBottomSheet } = useShowBottomSheet();

  const bottomSheetChildren = useMemo(
    () => (
      <AmortizationSheetContainer>
        <AmortizationSheetTitle children="Amortization Schedule" />
        <AmortizationSheetTable>
          <>
            <AmortizationSheetTableHeader>
              <div></div>
              <div>Payment</div>
              <div>Interest</div>
              <div>Principal</div>
              <div>Balance</div>
            </AmortizationSheetTableHeader>
            {line?.paymentSchedule?.map((payment, index) => <React.Fragment key={index}>
              <AmortizationSheetTableCell className="date">{formatMonthYear(payment.paymentDate)}</AmortizationSheetTableCell>
              <AmortizationSheetTableCell>{decorateCurrencyValue(line?.monthlyRepaymentAmount.display)}</AmortizationSheetTableCell>
              <AmortizationSheetTableCell>{decorateCurrencyValue(payment.interestAmountPaid.display)}</AmortizationSheetTableCell>
              <AmortizationSheetTableCell>{decorateCurrencyValue(payment.principalAmountPaid.display)}</AmortizationSheetTableCell>
              <AmortizationSheetTableCell>{decorateCurrencyValue(payment.remainingAmount.display)}</AmortizationSheetTableCell>
            </React.Fragment>)}
          </>
        </AmortizationSheetTable>
        <Button
          color={ColorNames.TRANSPARENT}
          onClick={() => hideBottomSheet()}
          children="Close"
        />
      </AmortizationSheetContainer>
    ),
    [hideBottomSheet, line]
  );

  useEffect(() => {
    setBottomSheet({ id, fullHeight: true, noPadding: true, children: bottomSheetChildren });
  }, [bottomSheetChildren, id, setBottomSheet]);

  return <></>;
};

export default AmortizationSheet;
