import React, { useEffect, useMemo, useState } from "react";
import { useSetBottomSheet, useShowBottomSheet } from "src/util/useBottomSheet";
import { PickableListItem } from "src/components/info-list-item/PickableListItem";
import { Button } from "src/components/button/Button";
import { ColorNames } from "src/theme/theme";
import {
  ProvidersContainer,
  ProvidersContent,
  ProvidersHeader,
  CAModalBodyTitle,
  CAModalBodyContent,
  CAModalButtonWrapper
} from "./ConnectAccountSheet.styles";
import { useDeleteConnectedAccount, useGetConnectedAccounts } from "src/services/connected-accounts";
import { ConnectedAccount } from "src/generated/client";

import { showNotice } from "src/store/alertState";
import { useSetRecoilState } from "recoil";
import { expectedNewConnectedAccountsDataState } from "src/store/connectedAccountsState";
import { useBalances } from "src/services/organizations";

interface ComponentProps {
  id: string;
  accounts: ConnectedAccount[];
  onSuccess?: () => void;
}

export const ConnectedAccountsSelectAccountsSheet = (props: ComponentProps): JSX.Element => {
  const setBottomSheet = useSetBottomSheet();
  const { hideBottomSheet } = useShowBottomSheet();

  const [selections, setSelections] = useState<Array<string>>([]);
  const { deleteConnectedAccount, loading, error } = useDeleteConnectedAccount();
  const accounts = props.accounts;
  const setExpectedNewConnectedAccountsData = useSetRecoilState(expectedNewConnectedAccountsDataState);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      setSelections(selections.concat([e.currentTarget.value]));
    } else {
      setSelections(selections.filter((item) => item !== e.currentTarget.value));
    }
  };

  const generateSubtitle = (account: ConnectedAccount) => {
    let subtitle = "";
    switch (true) {
      case account && account.type === "credit":
        subtitle = "Credit";
        break;
      case account && account.type === "depository":
        subtitle = "Bank";
        if (account?.subType === "checking") {
          subtitle = "Checking Account";
        } else if (account?.subType === "savings") {
          subtitle += "Savings Account";
        }
        break;
    }
    return subtitle;
  };

  const bottomSheetChildren = useMemo(
    () => (
      <ProvidersContainer>
        <ProvidersHeader>
          <span>Connecting to External Account</span>
        </ProvidersHeader>
        <ProvidersContent>
          <CAModalBodyTitle>Which accounts do you want to connect to?</CAModalBodyTitle>
          <CAModalBodyContent>
            {!!accounts &&
              accounts.length > 0 &&
              accounts.map((account) => (
                <PickableListItem
                  key={account.id}
                  title={account.name || ""}
                  subtitle={generateSubtitle(account)}
                  inputValue={account.id}
                  onChange={handleChange}
                />
              ))}
            {!!selections && selections.length > 0 && (
              <CAModalButtonWrapper>
                <Button
                  color={ColorNames.LAKE}
                  raised
                  type="submit"
                  loading={loading}
                  onClick={async () => {
                    const allAccountIds = Array.from(accounts || [], (acct) => acct.id);
                    const deleteIds = allAccountIds.filter((id) => !selections.includes(id));
                    const call = deleteIds.length ? await deleteConnectedAccount({ idList: deleteIds }) : true;
                    if (!!call) {
                      setExpectedNewConnectedAccountsData([]);
                      !!props.onSuccess && props.onSuccess();
                      hideBottomSheet();
                      showNotice("Accounts successfully connected.");
                    } else {
                      showNotice("There was a problem connecting all of your accounts.", { error: true });
                    }
                  }}
                >
                  Connect and Finish
                </Button>
              </CAModalButtonWrapper>
            )}
          </CAModalBodyContent>
        </ProvidersContent>
      </ProvidersContainer>
    ),
    [hideBottomSheet, selections]
  );

  useEffect(() => {
    setBottomSheet({
      id: props.id,
      children: bottomSheetChildren,
      fullHeight: true,
      noPadding: true,
      closeOnOutsideClick: false,
      hasHeader: true
    });
  }, [bottomSheetChildren, props, setBottomSheet]);

  return <></>;
};
