import React from "react";
import content from "src/data/electronic_disclosure_consent";
import { TermsPage, Logo } from "src/pages/terms/Terms.styles";

const ElectronicDisclosure = () => (
  <TermsPage>
    <Logo />
    {content}
  </TermsPage>
);

export default ElectronicDisclosure;
