import {
  lowerDashCase,
} from "src/util/stringUtils";

export enum LegalStructure {
  LLC = "LLC",
  INC = "Inc",
  LLP = "LLP",
}

export const TaxStatuses: Record<string, Array<string>> = {
  [lowerDashCase(LegalStructure.LLC)]: ["Single Member", "C-Corp", "S-Corp", "Partnership"],
  [lowerDashCase(LegalStructure.INC)]: ["C-Corp", "S-Corp"],
  [lowerDashCase(LegalStructure.LLP)]: ["Partnership"],
};

export enum OwnerTitle {
  CEO = "CEO",
  CFO = "CFO",
  COO = "COO",
  PRESIDENT = "President",
}