import React from "react";
import classnames from "classnames";
import { createGlobalStyle } from "styled-components";
import { AnimatePresence } from "framer-motion";
import { useRecoilValue } from "recoil";
import { coreSubnavState, useSubnav } from "src/util/useSubnav";
import { Grid, SUBNAV_FIXED_HEIGHT, Tab, TabHighlight, SubnavWrapper } from "./Subnav.styles";

const SubnavDisplay = createGlobalStyle<{ show: boolean }>`
  :root {
    ${(props) => (props.show ? `--subnavHeight: ${SUBNAV_FIXED_HEIGHT}` : "--subnavHeight: 0px")};
  }
`;

const motionOptions = {
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0
  },
  transition: {
    duration: 0.1
  }
};

// have to brute force these types until @types/styled-components is updated with react 18 support
const SubnavDisplayFixed = SubnavDisplay as unknown as React.FC<React.PropsWithChildren<{ show: boolean }>>;

export default function Subnav() {
  const tabs = useRecoilValue(coreSubnavState);
  const hasTabs = tabs.length > 0;
  const { selected, selectTab } = useSubnav(tabs);

  const onClick = ({ event, index }: { event: React.MouseEvent<HTMLButtonElement, MouseEvent>; index: number }) => {
    (event.target as HTMLElement).scrollIntoView({ behavior: "smooth" });
    selectTab(index);
  };

  return (
    <SubnavWrapper className={classnames({ visible: hasTabs})}>
      <SubnavDisplayFixed show={hasTabs} />
      <AnimatePresence>
        {hasTabs && (
          <Grid {...motionOptions}>
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                className={classnames({ active: selected === index})}
                onClick={(event) => onClick({ event, index })}
                data-cy={tab}
              >
                <span>{tab}</span>
                {selected === index && <TabHighlight layoutId="tab-highlight" />}
              </Tab>
            ))}
          </Grid>
        )}
      </AnimatePresence>
    </SubnavWrapper>
  );
}
