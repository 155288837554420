import React, { useEffect, useState } from "react";

import { useShowBottomSheet } from "src/util/useBottomSheet";
import { showNotice } from "src/store/alertState";

import accountTerms from "src/data/account_terms";
import privacyPolicy from "src/data/privacy_policy";
import electronicDisclosure from "src/data/electronic_disclosure_consent";
import bankingAgreementIndividual from "src/data/banking_agreement_individual";
import bankingAgreementBusiness from "src/data/banking_agreement_business";

import { OrganizationType } from "src/generated/client";

import { AuthContainer } from "src/pages/auth/Auth";
import { AuthTitle } from "src/pages/auth/Auth.styles";
import {
  ModalContainer,
  TermsCopy,
} from "src/routes/app/onboarding/Onboarding.styles";
import { AddModal } from "src/components/cta/add-modal/AddModal";
import { Button } from "src/components/button/Button";
import { TermsBottomSheet } from "src/components/bottom-sheets/terms-bottom-sheet/TermsBottomSheet";
import { ColorNames } from "src/theme/theme";
import { storedOnboardingLegalDataKey } from "src/util/localStorage";
import { CypressTestIds } from "src/util/testing-util/test-utils";
import { Toggle } from "src/components/toggle/Toggle";
import { StyledFieldContainer } from "src/components/forms";

const Terms = ({
  orgType,
  nextStep,
  stepDirection,
  isFinalStep,
}: {
  orgType: string;
  nextStep: (setSubmitting: (isSubmitting: boolean) => void) => void;
  stepDirection: number;
  isFinalStep?: boolean;
}): JSX.Element => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();

  const [letterAlertsAcceptance, setLetterAlertsAcceptance] = useState(false);
  const [accountTermsAccepted, setAccountTermsAccepted] = useState(false);
  const [bankingAgreementAccepted, setBankingAgreementAccepted] =
    useState(false);
  const [electronicDisclosureAccepted, setElectronicDisclosureAccepted] =
    useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

  useEffect(() => {
    updateFromLocalStorage();
  }, []);

  const updateFromLocalStorage = () => {
    setAccountTermsAccepted(
      !!localStorage.getItem(storedOnboardingLegalDataKey.TERMS)
    );
    setBankingAgreementAccepted(
      !!localStorage.getItem(storedOnboardingLegalDataKey.BANKING_AGREEMENT)
    );
    setElectronicDisclosureAccepted(
      !!localStorage.getItem(storedOnboardingLegalDataKey.ELECTRONIC_DISCLOSURE)
    );
    setPrivacyPolicyAccepted(
      !!localStorage.getItem(storedOnboardingLegalDataKey.PRIVACY_POLICY)
    );
  };

  const acceptTerms = (termsType: string): void => {
    hideBottomSheet(termsType);

    localStorage.setItem(termsType, "true");
    updateFromLocalStorage();
  };

  const accountTermsHtml: JSX.Element = (
    <>
      {accountTerms}
      <br />
      <br />
      <div className="input-container-for-toggle">
        <StyledFieldContainer type="toggle">
          <Toggle
            displayLabel={
              <>
                I agree to opt into&nbsp;
                <a href="#terms_letter_alerts_text">Letter Alerts</a>
              </>
            }
            name="letterAlertsAcceptance"
            align="left"
            checked={letterAlertsAcceptance}
            onChange={(): void => {
              setLetterAlertsAcceptance(!letterAlertsAcceptance);
            }}
            // cy={CypressTestIds.TOGGLE_SMS}
          />
        </StyledFieldContainer>
      </div>
    </>
  );

  return (
    <AuthContainer stepDirection={stepDirection}>
      <AuthTitle>Terms</AuthTitle>

      <ModalContainer>
        <AddModal
          title="Account Terms"
          onClick={() => showBottomSheet(storedOnboardingLegalDataKey.TERMS)}
          complete={accountTermsAccepted}
          dataCy={CypressTestIds.SIGN_UP_TERMS_ACCOUNT_TERMS_BTN}
        />
        <AddModal
          title="Banking Agreement"
          onClick={() =>
            showBottomSheet(storedOnboardingLegalDataKey.BANKING_AGREEMENT)
          }
          complete={bankingAgreementAccepted}
          dataCy={CypressTestIds.SIGN_UP_TERMS_BANKING_AGREEMENT_BTN}
        />
        <AddModal
          title="Electronic Disclosure"
          onClick={() =>
            showBottomSheet(storedOnboardingLegalDataKey.ELECTRONIC_DISCLOSURE)
          }
          complete={electronicDisclosureAccepted}
          dataCy={CypressTestIds.SIGN_UP_TERMS_ELECTRONIC_DISCLOSURE_BTN}
        />
        <AddModal
          title="Privacy Policy"
          onClick={() =>
            showBottomSheet(storedOnboardingLegalDataKey.PRIVACY_POLICY)
          }
          complete={privacyPolicyAccepted}
          dataCy={CypressTestIds.SIGN_UP_TERMS_PRIVACY_POLICY_BTN}
        />
      </ModalContainer>

      <div>
        <TermsCopy>
          Letter is a financial technology company and not a bank.
          <br />
          Banking services provided by Blue Ridge Bank N.A., Member FDIC
          <br />
          <br />
          The Letter Visa&reg; Debit Card is issued by Blue Ridge Bank N.A.
          pursuant to a license from Visa U.S.A. Inc. and may be used everywhere
          Visa debit cards are accepted.
        </TermsCopy>

        <Button
          raised
          color={isFinalStep ? ColorNames.LAKE : ColorNames.MIDNIGHT}
          disabled={
            !accountTermsAccepted ||
            !bankingAgreementAccepted ||
            !privacyPolicyAccepted
          }
          loading={isSubmitting}
          data-cy={
            isFinalStep
              ? CypressTestIds.SIGN_UP_CREATE_ORGANIZATION_BTN
              : CypressTestIds.SIGN_UP_CONTNUE_TO_VERIFICATION_BTN
          }
          onClick={() => {
            setSubmitting(true);
            nextStep(setSubmitting);
          }}
        >
          {isFinalStep ? "Create Organization" : "Continue"}
        </Button>
      </div>

      <TermsBottomSheet
        id={storedOnboardingLegalDataKey.TERMS}
        dataCy={CypressTestIds.SIGN_UP_TERMS_ACCOUNT_TERMS_CONFIRM_BTN}
        children={accountTermsHtml}
        onAction={() => {
          if (!letterAlertsAcceptance) {
            showNotice("You must agree to opt into Letter Alerts.", {
              error: true,
            });
            return;
          }
          acceptTerms(storedOnboardingLegalDataKey.TERMS);
        }}
      />
      <TermsBottomSheet
        id={storedOnboardingLegalDataKey.BANKING_AGREEMENT}
        children={
          orgType === OrganizationType.Individual
            ? bankingAgreementIndividual
            : bankingAgreementBusiness
        }
        onAction={() =>
          acceptTerms(storedOnboardingLegalDataKey.BANKING_AGREEMENT)
        }
        dataCy={CypressTestIds.SIGN_UP_TERMS_BANKING_AGREEMENT_CONFIRM_BTN}
      />
      <TermsBottomSheet
        id={storedOnboardingLegalDataKey.ELECTRONIC_DISCLOSURE}
        children={electronicDisclosure}
        onAction={() =>
          acceptTerms(storedOnboardingLegalDataKey.ELECTRONIC_DISCLOSURE)
        }
        dataCy={CypressTestIds.SIGN_UP_TERMS_ELECTRONIC_DISCLOSURE_CONFIRM_BTN}
      />
      <TermsBottomSheet
        id={storedOnboardingLegalDataKey.PRIVACY_POLICY}
        children={privacyPolicy}
        onAction={() =>
          acceptTerms(storedOnboardingLegalDataKey.PRIVACY_POLICY)
        }
        dataCy={CypressTestIds.SIGN_UP_TERMS_PRIVACY_POLICY_CONFIRM_BTN}
      />
    </AuthContainer>
  );
};

export default Terms;
