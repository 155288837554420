import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import classnames from "classnames";

import { Avatar } from "../avatar/Avatar";

import {
  EmptyAnimationArea,
  AnimationStage,
  FloatingCardBody,
  FloatingCardContainer
} from "./AnimatedExternalAccountsIllustration.styles";

import chase from "src/assets/external-logos/chase.png";
import bofa from "src/assets/external-logos/bofa.png";
import wellsfargo from "src/assets/external-logos/wells-fargo.png";
import schwab from "src/assets/external-logos/schwab.png";
import citi from "src/assets/external-logos/citi.png";
import amex from "src/assets/external-logos/amex.png";
import binance from "src/assets/external-logos/binance.png";
import coinbase from "src/assets/external-logos/coinbase.png";
import robinhood from "src/assets/external-logos/robinhood.png";
import etrade from "src/assets/external-logos/etrade.png";
import metamask from "src/assets/external-logos/metamask.png";
import gemini from "src/assets/external-logos/gemini.png";

const stageFadeInMotion = {
  initial: {
    opacity: 0,
    scale: 0.25
  },
  animate: {
    opacity: 1,
    scale: 1
  },
  transition: {
    delay: 0.25,
    duration: 2,
    ease: [0.175, 0.8, 0.35, 1]
  }
};

const FloatingCard = (props: { empty?: boolean; image?: string; motion?: any; visible?: boolean }) => {
  const [visible, setVisible] = useState(props.visible);
  const { ref, inView } = useInView({ threshold: 1 });

  useEffect(() => {
    setVisible(inView);
  }, [inView]);

  return (
    <FloatingCardContainer className={classnames({ empty: props.empty, visible })} {...props.motion} ref={ref}>
      {!props.empty && (
        <>
          <Avatar image={props.image} size={32} />
          <FloatingCardBody>
            <div className="main-title"></div>
            <div className="sub-title"></div>
            <div className="sub-title"></div>
          </FloatingCardBody>
        </>
      )}
    </FloatingCardContainer>
  );
};

export default function AnimatedExternalAccountsIllustration() {
  return (
    <EmptyAnimationArea>
      <AnimationStage {...stageFadeInMotion}>
        <motion.div
          className="animation-row"
          animate={{
            x: [-400, 0]
          }}
          transition={{
            ease: "linear",
            duration: 10,
            repeat: Infinity,
            repeatType: "loop"
          }}
        >
          <FloatingCard empty />
          <FloatingCard empty />
          <FloatingCard empty />
          <FloatingCard empty />
          <FloatingCard empty />
        </motion.div>
        <motion.div
          className="animation-row"
          animate={{
            x: [-800, 0]
          }}
          transition={{
            ease: "linear",
            duration: 27,
            repeat: Infinity,
            repeatType: "loop"
          }}
        >
          <FloatingCard image={binance} />
          <FloatingCard image={chase} visible={true} />
          <FloatingCard image={gemini} />
          <FloatingCard image={coinbase} />
          <FloatingCard image={binance} />
          <FloatingCard image={chase} visible={true} />
          <FloatingCard image={coinbase} />
        </motion.div>
        <motion.div
          className="animation-row"
          animate={{
            x: [-800, -476, 0]
          }}
          transition={{
            ease: [0.15, 0.5, 0.5, 0.75],
            duration: 14,
            repeat: Infinity,
            repeatType: "loop"
          }}
        >
          <FloatingCard image={amex} />
          <FloatingCard image={wellsfargo} />
          <FloatingCard image={bofa} />
          <FloatingCard image={robinhood} />
          <FloatingCard image={amex} visible={true} />
          <FloatingCard image={wellsfargo} visible={true} />
          <FloatingCard image={gemini} />
        </motion.div>
        <motion.div
          className="animation-row"
          animate={{
            x: [-800, 0]
          }}
          transition={{
            ease: "linear",
            duration: 33,
            repeat: Infinity,
            repeatType: "loop"
          }}
        >
          <FloatingCard image={citi} />
          <FloatingCard image={schwab} visible={true} />
          <FloatingCard image={metamask} />
          <FloatingCard image={etrade} />
          <FloatingCard image={citi} />
          <FloatingCard image={schwab} visible={true} />
          <FloatingCard image={etrade} />
        </motion.div>
        <motion.div
          className="animation-row"
          animate={{
            x: [-400, 0]
          }}
          transition={{
            ease: "linear",
            duration: 7,
            repeat: Infinity,
            repeatType: "loop"
          }}
        >
          <FloatingCard empty />
          <FloatingCard empty />
          <FloatingCard empty />
          <FloatingCard empty />
          <FloatingCard empty />
          <FloatingCard empty />
        </motion.div>
      </AnimationStage>
    </EmptyAnimationArea>
  );
}
