import React, { useEffect, useMemo } from "react";
import { useSetBottomSheet, useShowBottomSheet } from "src/util/useBottomSheet";
import { InfoListItem } from "src/components/info-list-item/InfoListItem";
import { InfoListItemsWrapper } from "src/components/info-list-item/InfoListItem.styles";
import {
  BankAccountDetailsSheetContainer,
  BankAccountDetailsSheetTitle,
} from "src/components/bottom-sheets/bank-account-details-sheet/BankAccountDetailsSheet.styles";
import { Button } from "src/components/button/Button";
import { colorPalette as colors, ColorNames } from "src/theme/theme";
import { DetailRow } from "src/components/detail-row/DetailRow";
import { Account } from "src/generated/client";

export interface BankAccountDetailsSheetProps {
  id: string;
  closeOnOutsideClick?: boolean;
  account: Partial<Account> | undefined;
  accountHolder: any;
}

const BankAccountDetailsSheet = ({
  id,
  closeOnOutsideClick = true,
  account,
  accountHolder,
}: BankAccountDetailsSheetProps): JSX.Element => {
  const setBottomSheet = useSetBottomSheet();
  const { hideBottomSheet } = useShowBottomSheet();

  const bottomSheetChildren = useMemo(
    () => (
      <BankAccountDetailsSheetContainer>
        <BankAccountDetailsSheetTitle>
          Account Details
        </BankAccountDetailsSheetTitle>
        <InfoListItemsWrapper>
          <InfoListItem title="Nickname" value={account?.nickname} />
          <DetailRow
            large
            reveal={true}
            label="Account Number"
            fullData={account ? account.externalAccountNumber : ""}
            action="copy"
          />
          <DetailRow
            large
            reveal={true}
            label="Routing Number"
            fullData={account ? account.externalRoutingNumber : ""}
            action="copy"
          />
          <InfoListItem title="Account Holder" value={accountHolder?.name} />
          <InfoListItem
            title="Account Address"
            children={
              <>
                {accountHolder?.address.street}
                <br />
                {accountHolder?.address.city}, {accountHolder?.address.state}{" "}
                {accountHolder?.address.postalCode}
              </>
            }
          />
          <InfoListItem title="Account Type" value="Checking" />
          <InfoListItem
            title="Bank Address"
            children={
              <>
                17 West Main Street
                <br />
                Luray, Virginia 22835
              </>
            }
          />
        </InfoListItemsWrapper>

        <Button
          color={ColorNames.TRANSPARENT}
          onClick={() => hideBottomSheet()}
          children="Close"
        />
      </BankAccountDetailsSheetContainer>
    ),
    [account, accountHolder, hideBottomSheet]
  );

  useEffect(() => {
    setBottomSheet({ id, closeOnOutsideClick, children: bottomSheetChildren });
  }, [bottomSheetChildren, id, setBottomSheet, closeOnOutsideClick]);

  return <></>;
};

export default BankAccountDetailsSheet;
