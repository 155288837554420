import React, { useEffect, useState } from "react";
import { useFetcher } from "react-router-dom";

import useNavigate from "src/util/useNavigate";
import { showNotice } from "src/store/alertState";
import { useNavbar } from "src/util/useNavbar";
import { useBalances } from "src/services/organizations";
import { useHistoricalBalances } from "src/services/organizations";
import { formatBalancePeriod, decorateCurrencyValue } from "src/util/stringUtils";
import { Account, AccountBalancesGrouped, BalancesTimeframe } from "src/generated/client";
import { ColorNames } from "src/theme/theme";
import { NavRoutes } from "src/routes/navRoutes";

import { FeatureCard, FeatureCardType } from "src/components/feature-card/FeatureCard";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { LoadingSkeleton } from "src/components/loading-skeleton/LoadingSkeleton";
import { BalanceGraph } from "src/components/balance-graph/BalanceGraph";
import { TimeRangePicker } from "src/components/timerange-picker/TimeRangePicker";
import { BalanceAmount } from "src/components/balance-amount/BalanceAmount";

import { DashboardContent, DashboardInfo, DashboardInfoRow, DashboardRegulationInfo } from "./Dashboard.styles";
import {
  FeatureCardDetails,
  FeatureCardTimeRange,
  FeatureCardBalance,
  FeatureCardAvailable,
  FeatureCardGraphContainer,
} from "src/components/feature-card/FeatureCard.styles";


const Dashboard = (): JSX.Element => {
  const { data: accounts, state: fetcherState, ...fetcher } = useFetcher<Account[]>();

  useNavbar({ title: "Dashboard" });
  const { checkingBalance, availableBalance, deposits, spent, error: balanceError } = useBalances();

  const navigate = useNavigate();
  

  useEffect(() => {
    if (!accounts && fetcherState === "idle") fetcher.load(NavRoutes.API_ACCOUNT);
  }, [accounts, fetcherState, fetcher]);

  useEffect(() => {
    if (balanceError) {
      showNotice(balanceError.message || "There was a problem getting your balance.", { error: true });
    }
  }, [balanceError]);

  return (
    <>
      <DashboardFeatureCard availableBalance={availableBalance?.display} totalBalance={checkingBalance?.display} />

      <DashboardInfo>
        <DashboardInfoRow>
          <div className="label">Deposits</div>
          <div className="amount">
            {deposits ? (
              <span>{decorateCurrencyValue(deposits.display)}</span>
            ) : (
              <LoadingSkeleton width={100} height={20} noMargin />
            )}
          </div>
        </DashboardInfoRow>
        <DashboardInfoRow>
          <div className="label">Spent</div>
          <div className="amount">
            {spent ? (
              <span>{decorateCurrencyValue(spent.display)}</span>
            ) : (
              <LoadingSkeleton width={80} height={20} noMargin />
            )}
          </div>
        </DashboardInfoRow>
      </DashboardInfo>

      <DashboardContent>
        {!!accounts ? (
          <ActionableButton
            label="Checking Accounts"
            extraLabel={accounts?.length || 0}
            color={ColorNames.MINT}
            onClick={() => {
              navigate(NavRoutes.BANK_ACCOUNTS);
            }}
          />
        ) : (
          <LoadingSkeleton height={42} noMargin />
        )}
      </DashboardContent>

      {/* <DashboardCharity>
    <RingProgress value={3} total={10} color={ColorNames.CINNAMON} size={40} strokeWidth={4} showText={false} />
    <div className="info-list">
    <DashboardDonations>$5,293.18 donated</DashboardDonations>
    <DashboardCharities>Thank you for helping Children Incorporated</DashboardCharities>
    </div>
    </DashboardCharity> */}
      <DashboardRegulationInfo>
        Letter is a financial technology company and not a bank.
        <br />
        Banking services provided by Blue Ridge Bank N.A., Member FDIC
        <br />
        The Letter Visa® Debit Card is issued by Blue Ridge Bank N.A. pursuant to a license from Visa U.S.A. Inc. and
        may be used everywhere Visa debit cards are accepted.
      </DashboardRegulationInfo>
    </>
  );
};

export default Dashboard;


const DashboardFeatureCard = ({ availableBalance, totalBalance }: { availableBalance?: string; totalBalance?: string }) => {
  const [timeRange, setTimeRange] = useState<BalancesTimeframe>(BalancesTimeframe.LastThirty);
  const [currentBalance, onUpdateCurrrentBalance] = useState<null | AccountBalancesGrouped>(null);
  const {
    getHistoricalBalances,
    historicalBalance
    // loading: historicalBalanceLoading,
    // error: errorLoading
  } = useHistoricalBalances();

  useEffect(() => {
    getHistoricalBalances(timeRange);
  }, [getHistoricalBalances, timeRange]);

  return (
    <FeatureCard type={FeatureCardType.BANK}>
      <>
        <FeatureCardBalance>
          {!!availableBalance ? (
            <BalanceAmount availableBalance={decorateCurrencyValue(availableBalance)} currentBalance={currentBalance} />
          ) : (
            <LoadingSkeleton width={200} height={42} transparent invert noMargin />
          )}
          <div className="label">{currentBalance ? formatBalancePeriod(currentBalance.period) : "Available Balance"}</div>
        </FeatureCardBalance>
        <FeatureCardGraphContainer>
          <BalanceGraph
            lineColor="white"
            playheadColor="white"
            data={historicalBalance}
            onUpdateCurrrentBalance={onUpdateCurrrentBalance}
          />
        </FeatureCardGraphContainer>

        <FeatureCardDetails>
          <FeatureCardAvailable>
            {!!totalBalance ? (
              decorateCurrencyValue(totalBalance)
            ) : (
              <LoadingSkeleton width={90} height={20} transparent invert noMargin />
            )}
            <div className="label">Total Balance</div>
          </FeatureCardAvailable>
          <FeatureCardTimeRange>
            <TimeRangePicker pickerId="time-picker" label="Time Range" value={timeRange} onChange={setTimeRange} />
          </FeatureCardTimeRange>
        </FeatureCardDetails>
      </>
    </FeatureCard>
  )
}