import React, { useEffect, useState } from "react";
import {
  useFetcher,
  useFetchers,
  useRevalidator,
  ActionFunction,
  generatePath
} from "react-router-dom";
import { useCookies } from "react-cookie";

import { useCurrentUser } from "src/services/users";
import { useCurrentOrg } from "src/services/organizations";
import { runCreditCheck } from "src/services/lines";
import { useNavbar } from "src/util/useNavbar";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import useNavigate from "src/util/useNavigate";
import { showNotice } from "src/store/alertState";
import { needsLogoutVar } from "src/store/currentUserState";
import { NavRoutes } from "src/routes/navRoutes";
import { ColorNames } from "src/theme/theme";
import { NavigationDirection } from "src/store/navigationState";
import { coreLayoutBodyBackgroundColor } from "src/store/layoutState";
import { decorateCurrencyValue } from "src/util/stringUtils";
import creditCheckTerms from "src/data/credit_check_terms";
import { storedCookieDataKey } from "src/util/localStorage";
import { CheckStatus, CollateralType, LineType, PartnershipType } from "src/generated/client";
import { RouteActionResponse } from "src";

import { QualifyContent, QualifyCaption } from "../OneLine.styles";
import { StyledRingCallout } from "src/components/ring-callout/RingCallout.styles";
import { ActionSheetCenteredContainer } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet.styles";

import Layout from "src/components/core-layout/Layout";
import { AddModal } from "src/components/cta/add-modal/AddModal";
import { Button } from "src/components/button/Button";
import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import ConnectAccountSheet from "src/components/bottom-sheets/connect-account-sheet/ConnectAccountSheet";
import { ConnectedAccountsLoadingSheet } from "src/components/bottom-sheets/connect-account-sheet/ConnectedAccountsLoadingSheet";
import { RingProgress } from "src/components/ring-progress/RingProgress";
import { InfoListItem } from "src/components/info-list-item/InfoListItem";
import { BannerNotice } from "src/components/alert/BannerNotice";
import { GlobalSvgs } from "src/components/global-svgs/GlobalSvgs";
import { LogoSpinner } from "src/components/logo-spinner/LogoSpinner";

const CREDIT_TERMS_SHEET_ID = "credit_terms_sheet";
const CONNECT_ACCOUNTS_SHEET_ID = "connect_account_sheet";

export const qualifyAction: ActionFunction = async () => {
  try {
    const response = await runCreditCheck();
    return { response };
  } catch (error) {
    const response: RouteActionResponse<unknown> = {};
    response.error = error;
    return response;
  }
};

const Qualify = () => {
  const { data: lineTypes, state: loadingState, load } = useFetcher<LineType[]>();
  const { data: creditCheck, state: loadingCreditCheck, submit } = useFetcher<RouteActionResponse<{
    success: boolean;
  }>>();
  const fetchers = useFetchers();
  const revalidator = useRevalidator();

  const currentUser = useCurrentUser();
  const currentOrg = useCurrentOrg({ fullFetch: true });
  const navigate = useNavigate();
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();
  const [isWaiting, setIsWaiting] = useState(false);
  const [, setCookie] = useCookies([storedCookieDataKey.CREDIT_CHECK_FAILED_PREFIX]);

  useNavbar({
    title: "Qualify",
    backAction: () => {
      navigate(NavRoutes.ONELINE_DASHBOARD, {
        direction: NavigationDirection.BACKWARD
      });
    }
  });

  useEffect(() => {
    setTimeout(() => coreLayoutBodyBackgroundColor(ColorNames.GRAY1), 0);
  }, []);

  useEffect(() => {
    if (!lineTypes && loadingState === "idle") {
      load(`${NavRoutes.API_LINE}?get=lineTypes`);
    }
  }, [lineTypes, loadingState, load]);

  useEffect(() => {
    if (!!creditCheck?.error) {
      showNotice(creditCheck.error.message || "There was a problem running your credit.", { error: true });
    }
  }, [creditCheck?.error]);

  useEffect(() => {
    if (!!creditCheck?.response && creditCheck.response.success) {
      showBottomSheet("credit_check_passed_sheet");
    }
  }, [creditCheck?.response]);

  useEffect(() => {
    if (currentOrg.creditCheck === CheckStatus.Failed) {
      // cookie and logout on failure
      setCookie(storedCookieDataKey.CREDIT_CHECK_FAILED_PREFIX, "true");
      needsLogoutVar({ logout: true, supressAlert: true });
    }
  }, [currentOrg.creditCheck, setCookie]);

  const runCreditCheck = () => {
    hideBottomSheet();
    submit(null, { method: "post" });
  };

  const continueAction = () => {
    if (currentOrg.creditCheck !== CheckStatus.Passed) {
      return showNotice("You must run your credit before proceeding.", {
        error: true
      });
    } else if (!currentOrg?.oneLine?.eligibleForQualification) {
      return showBottomSheet("connected_threshold_not_met_sheet");
    } else if (currentOrg?.oneLineAvailableToBorrow?.amount === 0) {
      return showBottomSheet("failed_underwriting_sheet");
    }

    if (currentUser?.partnershipType === PartnershipType.TetonRidge) {
      const lineType = lineTypes?.find(({ collateralType }) => collateralType === CollateralType.Horse);

      if (!!lineType)
        navigate(
          generatePath(NavRoutes.ONELINE_CREATE_LINE, {
            lineTypeId: lineType.id
          })
        );
      else navigate(NavRoutes.ONELINE_BORROW);
    } else {
      navigate(NavRoutes.ONELINE_BORROW);
    }
  };

  return (
    <Layout backgroundColor={ColorNames.GRAY1}>
      <QualifyContent>
        <p className="description">
          To see how much money you can borrow, we’ll just need to get some information from you first.
        </p>

        <div className="modal-container">
          <AddModal
            title="Credit Check"
            description="Soft pull credit check. This will not affect your credit score."
            onClick={() => currentOrg.creditCheck !== CheckStatus.Passed && showBottomSheet(CREDIT_TERMS_SHEET_ID)}
            complete={currentOrg.creditCheck === CheckStatus.Passed}
            loading={loadingCreditCheck === "submitting"}
          />
          <AddModal
            title="Connect Accounts"
            description={`Connect at least ${decorateCurrencyValue(
              currentOrg?.oneLine?.eligibilityQualificationAmount?.display
            )} from your financial acounts.`}
            onClick={() => showBottomSheet(CONNECT_ACCOUNTS_SHEET_ID)}
            complete={currentOrg?.oneLine?.eligibleForQualification}
            disabled={currentOrg.creditCheck !== CheckStatus.Passed}
          />
        </div>

        {!isWaiting && (
          <StyledRingCallout color={ColorNames.GRAY2}>
            <div className="heading">
              <div className="title">{decorateCurrencyValue(currentOrg.externalBalance?.display)} Connected</div>
              <RingProgress
                value={Math.min(
                  currentOrg.externalBalance?.amount,
                  currentOrg?.oneLine?.eligibilityQualificationAmount.amount
                )}
                total={currentOrg?.oneLine?.eligibilityQualificationAmount.amount}
                color={ColorNames.MIDNIGHT}
                size={26}
                strokeWidth={4}
                trackStrokeWidth={2}
                showText={false}
              />
            </div>
            <InfoListItem
              title="Connect at least"
              value={decorateCurrencyValue(currentOrg?.oneLine?.eligibilityQualificationAmount.display)}
              valueType="number"
              size="small"
            />
          </StyledRingCallout>
        )}

        {isWaiting && (
          <BannerNotice
            iconLeft={
              <>
                <GlobalSvgs />
                <LogoSpinner />
              </>
            }
            title="We are reviewing your accounts."
            children="It should only take a few seconds..."
            backgroundColor={ColorNames.GRAY2}
            textColor={ColorNames.MIDNIGHT}
            style={{ gridGap: "30px" }}
          />
        )}

        <Button
          raised
          children="Continue"
          disabled={
            loadingCreditCheck === "submitting" || fetchers.some((fetcher) => fetcher.state === "loading") || isWaiting
          }
          loading={false}
          onClick={continueAction}
        />
      </QualifyContent>

      <ActionBottomSheet
        id={CREDIT_TERMS_SHEET_ID}
        title="Soft Pull Credit Check"
        children={creditCheckTerms}
        onAction={runCreditCheck}
        actionText="Agree and Run Credit"
      />
      <ConnectAccountSheet
        id={CONNECT_ACCOUNTS_SHEET_ID}
        onSuccess={() => {
          setIsWaiting(true);
          revalidator.revalidate();
          setTimeout(() => {
            revalidator.revalidate();
            setIsWaiting(false);
          }, 10000);
        }}
      />
      <ConnectedAccountsLoadingSheet id="connected_account_loading_sheet" />
      <ActionBottomSheet
        id="credit_check_passed_sheet"
        success={true}
        title="Success"
        actionText="Close"
        actionColor={ColorNames.TRANSPARENT}
        onAction={(): void => {
          hideBottomSheet();
        }}
        children={
          <ActionSheetCenteredContainer>
            Your credit check looks good.
            <br />
            <br />
            Next, connect your finiancial accounts to complete the qualification.
          </ActionSheetCenteredContainer>
        }
      />
      <ActionBottomSheet
        id="connected_threshold_not_met_sheet"
        title="We're unable to qualify you."
        actionText="Close"
        actionColor={ColorNames.TRANSPARENT}
        onAction={(): void => {
          hideBottomSheet();
        }}
        children={
          <ActionSheetCenteredContainer>
            <QualifyCaption large children="You'll need to connect at least $10,000 in order to qualify. " />
            <QualifyCaption
              gray
              children="If you can, connect more assets. We'll be able to lend once you've connected more. The more assets you connect, the more potential there is for lending."
            />
          </ActionSheetCenteredContainer>
        }
      />
      <ActionBottomSheet
        id="failed_underwriting_sheet"
        title="We're unable to qualify you."
        actionText="Close"
        actionColor={ColorNames.TRANSPARENT}
        onAction={(): void => {
          hideBottomSheet();
        }}
        children={
          <ActionSheetCenteredContainer>
            <QualifyCaption
              large
              children="Your cash balance and spending habits didn't meet our requirements for lending."
            />
            <QualifyCaption
              gray
              children="If you can, go back and connect up more accounts. The more money you connect, the more potential there is for lending."
            />
          </ActionSheetCenteredContainer>
        }
      />
    </Layout>
  );
};

export default Qualify;
