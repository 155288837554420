import { LoaderFunction, ActionFunction } from "react-router-dom";

import { getConnectedAccounts, refreshConnectedAccounts } from "src/services/connected-accounts";
import { RouteActionResponse } from "src";

export const connectedAccountLoader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const selectedId = url.searchParams.get("selectedId") || undefined;
  const moveMoneyCapable = url.searchParams.get("moveMoneyCapable") === "true";
  const fromNetwork = url.searchParams.get("fromNetwork") === "true";

  try {
    return await getConnectedAccounts({ selectedId, moveMoneyCapable, fromNetwork });
  } catch (e) {
    throw e;
  }
};

export const connectedAccountAction: ActionFunction = async ({ request }) => {
  // const data = Object.fromEntries(await request.formData());

  if (request.method === "PUT") {
    // refresh connected accounts
    try {
      const response = await refreshConnectedAccounts();
      // if (response.success) getConnectedAccounts({ fromNetwork: true });
      return response.success;
    } catch (error) {
      const response: RouteActionResponse<unknown> = {};
      response.error = error;

      return response;
    }
  }
};
