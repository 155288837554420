import styled from "styled-components";
import { SelectProps } from "src/components/forms/select/FormikSelect";

import { fieldStyle } from "src/components/forms";
import { ReactComponent as Checkmark } from "src/assets/icons/check.svg";
import DownwardChevron from "src/assets/icons/chevron_down.svg";
import ArrowsExpand from "src/assets/icons/arrows_expand.svg";
import { colorPalette as colors, ColorNames } from "src/theme/theme";

export const StyledSelect = styled.select<SelectProps>`
  ${fieldStyle}

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 22px 12px 0 12px;
  width: (100% - 24px);
  height: 100%;
  background: transparent url(${DownwardChevron}) no-repeat calc(100% - 12px) center;
  background-size: 1rem auto, 100%;
  color: ${({ disabled }): string => (disabled ? colors.gray5.hex : colors.midnight.hex)};
  appearance: none;
`;

export const StyledImageSelect = styled.div<SelectProps>`
  ${fieldStyle}

  input {
    display: none;
  }

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 22px 42px 0 12px;
  width: (100% - 24px);
  height: 100%;
  background: transparent url(${ArrowsExpand}) no-repeat calc(100% - 12px) center;
  background-size: 20px;
  color: ${({ disabled }): string => (disabled ? colors.gray5.hex : colors.midnight.hex)};
  appearance: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const StyledImageThumbnail = styled.div<{ background: string }>`
  width: 40px;
  height: 40px;
  background-color: ${colors[ColorNames.GRAY1].hex};
  background-image: ${({ background }): string => `url(${background})`};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  margin-top: -22px;
  pointer-events: none;
`;

export const ImageSelectOptionCheckmarkHolder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -15px 0 0 -15px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: ${colors[ColorNames.LAKE].hex};
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
`;

export const ImageSelectOptionCheckmark = styled(Checkmark)`
  height: 16px;
  width: 16px;

  path {
    stroke: ${colors[ColorNames.WHITE].hex};
  }
`;

export const MultiselectValueWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  gap: 10px;
  cursor: pointer;
`;

export const MultiselectValue = styled.div`
  color: ${colors[ColorNames.GRAY5].hex};
`;

export const MultiselectPlaceholder = styled.div`
  margin-top: 5px;
  color: ${colors[ColorNames.GRAY5].hex};
  cursor: pointer;
  width: 80%;
`;
