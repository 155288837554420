import React from "react";
import accountTerms from "src/data/account_terms";
import { TermsPage, Logo } from "src/pages/terms/Terms.styles";

const AccountTerms = () => (
  <TermsPage>
    <Logo />
    {accountTerms}
  </TermsPage>
);

export default AccountTerms;
