import React, { useEffect } from "react";
import { useCookies } from "react-cookie";

import { useCurrentUser } from "src/services/users";
import { useShowBottomSheet } from "src/util/useBottomSheet";

import { storedCookieDataKey } from "src/util/localStorage";

import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import Welcome from "./Welcome";
import Dashboard from "./Dashboard";
import Layout from "src/components/core-layout/Layout";

const DashboardRoot = (): JSX.Element => {
  const currentUser = useCurrentUser();
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();

  const [cookies, , removeCookie] = useCookies([storedCookieDataKey.ACCEPTED_INVITE_CODE_PREFIX]);

  useEffect(() => {
    if (!!currentUser && currentUser.hasDeposited && cookies[storedCookieDataKey.ACCEPTED_INVITE_CODE_PREFIX]) {
      showBottomSheet("invite_welcome_sheet");
      removeCookie(storedCookieDataKey.ACCEPTED_INVITE_CODE_PREFIX);
    }
  }, [cookies, removeCookie, currentUser, showBottomSheet]);

  return (
    <Layout loading={!currentUser}>
      {!currentUser?.hasDeposited ? <Welcome /> : <Dashboard />}

      <ActionBottomSheet
        id="invite_welcome_sheet"
        success={true}
        title="Invite Complete"
        description="Your account is now live and ready to use."
        actionText="OK"
        onAction={() => hideBottomSheet()}
      />
    </Layout>
  );
};

export default DashboardRoot;
