import styled from "styled-components";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

export const CardTokenSheetHolder = styled.div``;

export const CardTokenSheetTitle = styled.p`
  width: 100%;
  ${fonts.HEADING_4}
  color: ${colors[ColorNames.MIDNIGHT].hex};
  margin-bottom: 32px;
  text-align: center;
`;

export const CardTokenSheetInstructions = styled.div`
  ${fonts.PARAGRAPH_M};
  text-align: center;
  margin-bottom: 30px;
`;
