import { gql } from "@apollo/client";
import { bankAccountsFragment, fullAccountFragment, accountTransactionFragment } from "src/services/gql/_fragments";

export const OPEN_CHECKING_ACCOUNT = gql`
  mutation OpenCheckingAccount($name: String!) {
    openCheckingAccount(input: { nickname: $name }) {
      id
      totalBalance {
        display
        amount
      }
      availableBalance {
        display
        amount
      }
      externalAccountNumber
      nickname
    }
  }
`;

export const CLOSE_CHECKING_ACCOUNT = gql`
  mutation CloseCheckingAccount($accountId: String!) {
    closeCheckingAccount(input: { accountId: $accountId }) {
      success
    }
  }
`;

export const UPDATE_CHECKING_ACCOUNT = gql`
  mutation EditCheckingAccount($accountId: String!, $name: String!) {
    editCheckingAccount(input: { accountId: $accountId, nickname: $name }) {
      id
      nickname
    }
  }
`;

export const GET_ACCOUNTS = gql`
  query BankAccounts(
    $accountRef: [AccountRef!]!
    $selectedId: String
    $timeframe: BalancesTimeframe
    $groupBy: BalancesGrouping
  ) {
    bankAccounts(input: { accountRef: $accountRef, selectedId: $selectedId }) {
      ...FullBankAccountData
    }
  }
  ${bankAccountsFragment}
`;

export const GET_ACCOUNT_BY_ID = gql`
  query BankAccountById($accountId: String!) {
    account(accountId: $accountId) {
      ...FullAccountData
    }
  }
  ${fullAccountFragment}
`;

export const GET_ACCOUNT_BALANCES_BY_ID = gql`
  query BankAccountBalancesById($accountId: String!, $timeframe: BalancesTimeframe!) {
    account(accountId: $accountId) {
      id
      balances(timeframe: $timeframe) {
        period
        balance {
          amount
          display
        }
      }
    }
  }
`;

export const GET_ACCOUNT_TRANSACTIONS_BY_ID = gql`
  query BankAccountTransactionsById($accountId: String!) {
    transactionsByAccountId(accountId: $accountId) {
      ...FullTransactionAccountData
    }
  }
  ${accountTransactionFragment}
`;

export const GET_ACCOUNT_STATEMENT_BY_ID = gql`
  query BankAccountStatement($statementId: String!, $accountId: String!) {
    statement(statementId: $statementId, accountId: $accountId) {
      url
    }
  }
`;

export const GET_ACCOUNT_OWNERS_BY_ID = gql`
  query BankAccountOwners($accountId: String!, $selectedId: String!) {
    account(accountId: $accountId) {
      id
      owners(selectedId: $selectedId) {
        id
        isAccountOwner
        firstName
        lastName
        selected
        isThisMe
      }
    }
  }
`;

export const ADD_ACCOUNT_OWNER = gql`
  mutation AddOwnersToCheckingAccount($accountId: String!, $userId: String!) {
    addOwnersToCheckingAccount(
      input: { accountId: $accountId, userId: $userId }
    ) {
      ...FullAccountData
    }
    ${fullAccountFragment}
  }
`;

export const REMOVE_ACCOUNT_OWNER = gql`
  mutation RemoveOwnersFromCheckingAccount($accountId: String!, $userId: String!) {
    removeOwnersFromCheckingAccount(input: { accountId: $accountId, userId: $userId }) {
      success
    }
  }
`;
