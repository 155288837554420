import React, { useEffect, useState, useRef } from "react";

import { useShowBottomSheet } from "src/util/useBottomSheet";
import { showNotice } from "src/store/alertState";

import { CardTokenBottomSheet } from "src/components/cards/CardTokenBottomSheet";
import { CardPinSheet } from "src/components/cards/CardPinSheet";
import { Button } from "src/components/button/Button";

import {
  CardSheetDetails,
  CardSheetInstructions,
  CardSheetActivateInputsHolder,
  CardSheetActivateInput,
} from "./CardSheet.styles";
import { StyledFieldContainer, InputRow } from "src/components/forms";
import { StyledLabelContainer, StyledLabel } from "src/components/text";
import { colorPalette as colors, ColorNames } from "src/theme/theme";
import { NeufileGroteskMedium } from "src/fonts/encoded-fonts";
import { Card } from "src/generated/client";

const VGS_FRAME_STYLES = {
  "@font-face": {
    "font-family": "NeufileGrotesk",
    "font-style": "normal",
    "font-weight": "500",
    "font-display": "swap",
    src: `url(${NeufileGroteskMedium})`,
  },
  "font-family": "NeufileGrotesk",
  color: colors[ColorNames.MIDNIGHT].hex,
  "font-size": "16px",
  "font-weight": 500,
  "font-feature-settings": '"tnum" on, "lnum" on',
  display: "block",
  "::placeholder": {
    color: colors[ColorNames.GRAY3].hex,
  },
};

type VGS_ERROR_KEYS = "data.attributes.expirationDate" | "data.attributes.cvv2";
const VGS_MAPPED_ERROR_KEYS: Record<VGS_ERROR_KEYS, string> = {
  "data.attributes.expirationDate": "Expiration date",
  "data.attributes.cvv2": "CVC",
};

export const CardActivateSheet = ({
  card,
  token,
  externalCardId,
  onActivate,
}: {
  card: Card | undefined;
  token: string | undefined;
  externalCardId: string | undefined;
  onActivate: (card: Card) => void;
}) => {
  const didLoadVGSRef = useRef<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [VGSForm, setVGSForm] = useState<any>();
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();

  useEffect(() => {
    if (!!token && !didLoadVGSRef.current) {
      didLoadVGSRef.current = true;
      const script = document.createElement("script");
      const body = document.getElementsByTagName("body")[0];
      script.src =
        "https://js.verygoodvault.com/vgs-collect/2.9.0/vgs-collect.js";
      body.appendChild(script);
      script.addEventListener("load", () => {
        const form = (window as any).VGSCollect.create(
          process.env.REACT_APP_UNIT_VAULT_ID,
          process.env.REACT_APP_UNIT_VAULT_ID === "tntazhyknp1"
            ? "sandbox"
            : "live",
          () => null
        );
        setVGSForm(form);

        form.field("#cc-cvv2", {
          type: "card-security-code",
          name: "data.attributes.cvv2",
          successColor: colors[ColorNames.MIDNIGHT].hex,
          errorColor: colors[ColorNames.MIDNIGHT].hex,
          placeholderColor: "red",
          placeholder: "123",
          maxLength: 3,
          validations: ["required", "validCardSecurityCode"],
          css: VGS_FRAME_STYLES,
        });

        form.field("#cc-expiration-date", {
          type: "card-expiration-date",
          name: "data.attributes.expirationDate",
          successColor: colors[ColorNames.MIDNIGHT].hex,
          errorColor: colors[ColorNames.MIDNIGHT].hex,
          placeholderColor: "red",
          placeholder: "MM / YY",
          yearLength: "2",
          validations: ["required", "validCardExpirationDate"],
          serializers: [
            form.SERIALIZERS.replace("(\\d{2})\\s\\/\\s(\\d{2})", "20$2-$1"),
          ],
          css: VGS_FRAME_STYLES,
        });
      });
    }
  }, [token]);

  const activateCard = () => {
    setIsSubmitting(true);
    VGSForm.submit(
      `/cards/${externalCardId}/activate`,
      {
        // This converts the dot-separated field name strings into a JSON object
        mapDotToObject: "true",
        headers: {
          "Content-Type": "application/vnd.api+json",
          Authorization: "Bearer " + token,
        },
      },
      (status: number, data: any) => {
        setIsSubmitting(false);
        if (!!data.errors) {
          showNotice(
            `There was a problem activating your card. Please verify your info any try again.`,
            {
              error: true,
            }
          );
        } else {
          showBottomSheet("card_edit_pin_sheet");
        }
      },
      (errors: any) => {
        const errorKey = Object.keys(errors)[0];
        const mappedErrorKey =
          VGS_MAPPED_ERROR_KEYS[errorKey as VGS_ERROR_KEYS] || errorKey;
        const message = errors[errorKey!].errorMessages[0];
        showNotice(`${mappedErrorKey} ${message}`, { error: true });
        setIsSubmitting(false);
      }
    );
  };

  return (
    <CardSheetDetails>
      <CardSheetInstructions>Enter your card details.</CardSheetInstructions>
      <CardSheetActivateInputsHolder>
        <InputRow>
          <StyledFieldContainer>
            <StyledLabelContainer>
              <StyledLabel htmlFor="">
                CVC (3 digits on back of card)
              </StyledLabel>
              <CardSheetActivateInput>
                <div id="cc-cvv2"></div>
              </CardSheetActivateInput>
            </StyledLabelContainer>
          </StyledFieldContainer>
        </InputRow>
        <InputRow>
          <StyledFieldContainer>
            <StyledLabelContainer>
              <StyledLabel htmlFor="">Expiration Date</StyledLabel>
              <CardSheetActivateInput>
                <div id="cc-expiration-date"></div>
              </CardSheetActivateInput>
            </StyledLabelContainer>
          </StyledFieldContainer>
        </InputRow>
      </CardSheetActivateInputsHolder>

      <Button
        color={ColorNames.LAKE}
        raised
        onClick={activateCard}
        loading={isSubmitting}
        children="Activate"
      />
      <Button
        color={ColorNames.TRANSPARENT}
        onClick={() => hideBottomSheet()}
        disabled={isSubmitting}
        children="Cancel"
      />

      <CardTokenBottomSheet
        id="card_edit_pin_sheet"
        title="Set a PIN"
        card={card!}
        cardToken={token}
        cardExternalCardId={externalCardId}
        children={({ token, externalCardId }) => {
          return (
            <CardPinSheet
              sheetId="card_edit_pin_sheet"
              token={token}
              externalCardId={externalCardId}
              card={card!}
              onSet={() => {
                showNotice(
                  `Your card was successfully activated and is ready for use.`,
                  { error: false }
                );
                hideBottomSheet();
                onActivate(card!);
              }}
            />
          );
        }}
      />
    </CardSheetDetails>
  );
};
