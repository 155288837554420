import React, { useEffect, useRef } from "react";

import { useShowBottomSheet } from "src/util/useBottomSheet";
import { formatCreditCardExpiry } from "src/util/stringUtils";

import { CardTokenBottomSheet } from "./CardTokenBottomSheet";
import { CardPinSheet } from "./CardPinSheet";
import { Button } from "src/components/button/Button";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";

import {
  CardSheetDetails,
  Row,
  RowLabel,
  RowContent,
} from "./CardSheet.styles";
import { colorPalette as colors, ColorNames } from "src/theme/theme";
import { NeufileGroteskMedium } from "src/fonts/encoded-fonts";
import { ReactComponent as IconEdit } from "src/assets/icons/edit.svg";
import { Card, CardType } from "src/generated/client";

const VGS_FRAME_STYLES = {
  "@font-face": {
    "font-family": "NeufileGrotesk",
    "font-style": "normal",
    "font-weight": "500",
    "font-display": "swap",
    src: `url(${NeufileGroteskMedium})`,
  },
  "font-family": "NeufileGrotesk",
  color: colors[ColorNames.MIDNIGHT].hex,
  "font-size": "16px",
  "font-weight": 500,
  "font-feature-settings": '"tnum" on, "lnum" on',
  display: "block",
  "text-align": "right",
};

export const CardInfoSheet = ({
  card,
  token,
  externalCardId,
}: {
  card: Card | undefined;
  token: string | undefined;
  externalCardId: string | undefined;
}) => {
  const didLoadVGSRef = useRef<boolean>(false);
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();

  useEffect(() => {
    if (!!token && !!externalCardId && !didLoadVGSRef.current) {
      didLoadVGSRef.current = true;
      const script = document.createElement("script");
      const body = document.getElementsByTagName("body")[0];
      script.src =
        "https://js.verygoodvault.com/vgs-show/1.3/ACh8JJTM42LYxwe2wfGQxwj5.js";
      body.appendChild(script);
      script.addEventListener("load", () => {
        const show = (window as any).VGSShow.create(
          process.env.REACT_APP_UNIT_VAULT_ID
        );
        const cvv2Frame = show.request({
          name: "data-text",
          method: "GET",
          path: `/cards/${externalCardId}/secure-data/cvv2`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          htmlWrapper: "text",
          jsonPathSelector: "data.attributes.cvv2",
        });
        cvv2Frame.render("#cvv2", VGS_FRAME_STYLES);

        const cardNumberFrame = show.request({
          name: "data-text",
          method: "GET",
          path: `/cards/${externalCardId}/secure-data/pan`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          htmlWrapper: "text",
          jsonPathSelector: "data.attributes.pan",
          serializers: [
            show.SERIALIZERS.replace(
              "(\\d{4})(\\d{4})(\\d{4})(\\d{4})",
              "$1 $2 $3 $4"
            ),
          ],
        });
        cardNumberFrame.render("#cardNumber", VGS_FRAME_STYLES);
      });
    }
  }, [externalCardId, token]);

  return (
    <CardSheetDetails>
      <Row>
        <RowLabel>Holder</RowLabel>
        <RowContent>
          {card?.cardHolderFirstName} {card?.cardHolderLastName}
        </RowContent>
      </Row>
      <Row>
        <RowLabel>Card Network</RowLabel>
        <RowContent>Visa</RowContent>
      </Row>
      <Row>
        <RowLabel>Number</RowLabel>
        <RowContent id="cardNumber" />
      </Row>
      <Row>
        <RowLabel>Expiration</RowLabel>
        <RowContent>
          {formatCreditCardExpiry(card?.expiryDate || "")}
        </RowContent>
      </Row>
      <Row>
        <RowLabel>CVV</RowLabel>
        <RowContent id="cvv2" />
      </Row>
      <Row>
        <RowLabel>Billing Address</RowLabel>
        <RowContent>
          {card?.address.street}
          <br />
          {card?.address.city}, {card?.address.state} {card?.address.postalCode}
        </RowContent>
      </Row>

      {card?.type === CardType.Physical && (
        <>
          <ActionableButton
            label="Change PIN"
            color={ColorNames.PINE}
            iconRight={<IconEdit />}
            onClick={() => showBottomSheet("card_edit_pin_sheet")}
          />
          <CardTokenBottomSheet
            id="card_edit_pin_sheet"
            title="Change PIN"
            card={card}
            cardToken={token}
            cardExternalCardId={externalCardId}
            children={({ token, externalCardId }) => {
              return (
                <CardPinSheet
                  sheetId="card_edit_pin_sheet"
                  edit
                  token={token}
                  externalCardId={externalCardId}
                  card={card}
                />
              );
            }}
          />
        </>
      )}

      <Button
        color={ColorNames.TRANSPARENT}
        onClick={() => hideBottomSheet()}
        children="Close"
      />
    </CardSheetDetails>
  );
};
