import React, { useState } from "react";

import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { ReactComponent as IconAlert } from "src/assets/icons/alert.svg";
import { ReactComponent as IconClose } from "src/assets/icons/close.svg";
import { AlertContainer, AlertIcon } from "src/components/alert/Alerts.styles";
import { ColorNames } from "src/theme/theme";
import { GlobalSvgs } from "../global-svgs/GlobalSvgs";
import { LogoSpinner } from "../logo-spinner/LogoSpinner";

export interface StickyAlertProps {
  description: JSX.Element | string;
  actionText: string;
  loading?: boolean;
  onAction: () => unknown;
}

export const Alert = ({ description, actionText, loading = false, onAction }: StickyAlertProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <AlertContainer open={isOpen}>
      <div className="content">
        <div className="icon">
          <IconAlert />
        </div>
        <div className="description">{description}</div>
        {loading ? (
          <>
            <GlobalSvgs />
            <LogoSpinner color={ColorNames.MIDNIGHT} />
          </>
        ) : (
          <ActionableButton label={actionText} onClick={onAction} color={ColorNames.MIDNIGHT} iconRight={false} />
        )}
      </div>
      <AlertIcon onClick={() => setIsOpen(!isOpen)}>{isOpen ? <IconClose /> : <IconAlert />}</AlertIcon>
    </AlertContainer>
  );
};
