import React, { useEffect, useRef } from "react";
import { generatePath, useFetcher } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Variants } from "framer-motion";
import useNavigate from "src/util/useNavigate";
import { useShowBottomSheet } from "src/util/useBottomSheet";

import { NavRoutes } from "src/routes/navRoutes";
import { AccountsList } from "./Accounts.styles";
import { AccountCard } from "src/components/account-card/AccountCard";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { ActionableButtonGroup } from "src/components/actionable-button/ActionableButton.styles";
import { ColorNames } from "src/theme/theme";
import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import { StyledForm, StyledFormInputContainer } from "src/components/forms";
import { Input } from "src/components/forms/input/Input";
import { showNotice } from "src/store/alertState";
import {
  CreateCheckingAccount,
  useOpenCheckingAccount,
} from "src/services/accounts";
import Layout from "src/components/core-layout/Layout";
import { useNavbar } from "src/util/useNavbar";
import { Account } from "src/generated/client";
import ErrorElement from "src/ErrorElement";
import { decorateCurrencyValue } from "src/util/stringUtils";

type FormFields = { name: string };

const variants: Variants = {
  hidden: {
    opacity: 1,
  },
  show: {
    opacity: 1,
    transition: { staggerChildren: 0.05 },
  },
};

const NewAccountSchema: Yup.ObjectSchema<FormFields> = Yup.object().shape({
  name: Yup.string()
    .min(2, "Please use a longer name.")
    .max(64, "Please use a shorter name.")
    .required("Please add a nickname for this account."),
});

export const AccountsErrorElement = () => {
  return <ErrorElement redirect={NavRoutes.BANK_DASHBOARD} alert={true} />;
};

const Accounts = (): JSX.Element => {
  const { data: accounts, state: fetcherState, ...fetcher } = useFetcher<Account[]>();

  useNavbar({ title: "Checking Accounts" });
  const navigate = useNavigate();
  const {
    openAccount,
    loading: openLoading,
    error: openError,
  } = useOpenCheckingAccount();
  const openAccountSubmitRef = useRef<any>(null);

  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();

  useEffect(() => {
    if (!accounts && fetcherState === "idle") fetcher.load(NavRoutes.API_ACCOUNT);
  }, [accounts, fetcherState, fetcher]);

  const handleCreateAccount = async (
    values: CreateCheckingAccount,
    setSubmitting: (submitting: boolean) => void,
    resetForm: () => void
  ): Promise<void> => {
    const createAccount = await openAccount(values);

    if (createAccount) {
      setTimeout(() => {
        fetcher.load(NavRoutes.API_ACCOUNT);
      }, 2000);
      hideBottomSheet();
      showNotice(`Account was created.`);
      resetForm();
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (openError) {
      showNotice(
        openError.message || "There was a problem opening a new account.",
        { error: true }
      );
    }
  }, [openError]);

  return (
    <Layout loading={fetcherState === "loading"}>
      <>
        <ActionableButtonGroup noTopMargin>
          <ActionableButton
            label="Open New Account"
            color={ColorNames.PINE}
            iconRight={false}
            onClick={(): void => showBottomSheet("open_account_sheet")}
          />
        </ActionableButtonGroup>

        <AccountsList variants={variants} initial="hidden" animate="show">
          {accounts?.map((account: Account) => (
            <AccountCard
              key={account.id}
              name={account.nickname || ""}
              balances={account.balances}
              amount={decorateCurrencyValue(account.availableBalance?.display)}
              lastFour={account.last4}
              onClick={() =>
                navigate(
                  generatePath(NavRoutes.BANK_ACCOUNT_DETAIL, {
                    accountId: account.id,
                    section: "overview",
                  })
                )
              }
            />
          ))}
        </AccountsList>
      </>

      <ActionBottomSheet
        id="open_account_sheet"
        title="Open New Account"
        description="Set up a new checking account. You are limited to 8 checking accounts."
        actionText="Open Account"
        actionColor={ColorNames.LAKE}
        actionLoading={openLoading}
        onAction={(): void => {
          openAccountSubmitRef.current.click();
        }}
        secondaryActionText="Cancel"
        onSecondaryAction={(): void => hideBottomSheet()}
      >
        <Formik
          initialValues={{ name: "" }}
          enableReinitialize={true}
          validationSchema={NewAccountSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values, { setSubmitting, resetForm }): void => {
            handleCreateAccount(values, setSubmitting, resetForm);
          }}
        >
          {({ isSubmitting, handleChange, setFieldValue }): JSX.Element => (
            <StyledForm
              style={{
                marginBottom: 44,
              }}
            >
              <StyledFormInputContainer>
                <Input
                  name="name"
                  type="text"
                  label="Account Name"
                  autoComplete="off"
                />
              </StyledFormInputContainer>
              {/* 
          hacky: explicit submit with ref here because Formik 
          cannot have the ActionBottomSheet as a child 
        */}
              <input type="submit" hidden ref={openAccountSubmitRef} />
            </StyledForm>
          )}
        </Formik>
      </ActionBottomSheet>
    </Layout>
  );
};

export default Accounts;
