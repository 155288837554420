import React from "react";
import { NoticeController } from "src/NoticeController";
import { AlertController } from "src/AlertController";
import { BottomSheetController } from "src/BottomSheetController";
import { DetailPickerController } from "src/DetailPickerController";

export const OverlayController = () => {
  return (
    <>
      <NoticeController />
      <AlertController />
      <BottomSheetController />
      <DetailPickerController />
    </>
  );
};
