import styled from "styled-components";
import { colorPalette as colors, ColorNames, fonts, dropShadows, ShadowNames } from "src/theme/theme";
import { AvatarProps, InitialsProps } from "./Avatar";

export const Container = styled.div<AvatarProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 100%;
  background-color: ${({ color }) => (color ? colors[color].hex : colors[ColorNames.MIDNIGHT].hex)};
  box-shadow: ${({ shadow }) => (shadow ? dropShadows[ShadowNames.AVATAR] : "none")};
  transition: 0.15s ease transform;
  position: relative;

  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: ${({ fitImage }) => (!!fitImage ? "contain" : "cover")};
    padding: ${({ fitImage }) => (!!fitImage ? "5px" : "0")};
    border-radius: ${({ fitImage }) => (!!fitImage ? "none" : "100%")};
  }

  &:active {
    transform: scale(0.94);
  }

  ${({ onClick }) =>
    onClick &&
    `
    cursor: pointer;
  `};

  ${({ outline, color }) =>
    outline &&
    `
    background: transparent;

    &::before {
      content: '';
      border-radius: 100%;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 0 0 1.5px ${!!color ? colors[color].hex : colors[ColorNames.MIDNIGHT].hex};
      opacity: 0.25;
    }
  `}
`;

export const Initials = styled.span<InitialsProps>`
  ${fonts.PARAGRAPH_M}
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  user-select: none;
  pointer-events: none;
  color: ${({ textColor }) => !!textColor ? colors[textColor].hex : colors[ColorNames.WHITE].hex};

  ${({ outline, color }) => outline && `color: ${!!color ? colors[color].hex : colors[ColorNames.MIDNIGHT].hex};`}
`;
