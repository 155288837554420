import styled from "styled-components/macro";
import { InfoListItemsWrapper } from "src/components/info-list-item/InfoListItem.styles";
import { StyledFormInputContainer } from "src/components/forms";
import { Container } from "src/components/forms/currency-input/LargeCurrencyInput.styles";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

export const ListingDetailSectionWrapper = styled.div`
  ${InfoListItemsWrapper} {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  ${StyledFormInputContainer} {
    margin-bottom: 20px;
  }

  &.has-segmented-control ${Container} {
    margin-top: 10px;
  }

  &.no-padding-currency-input ${Container} {
    margin-bottom: 20px;
  }
`;

export const ListingDetailSectionContent = styled.div`
  padding: 0 30px;
`;

export const ListingDetailContentContainer = styled.div`
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  padding: 0 30px 64px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  &.success {
    justify-content: space-between;
  }

  ${InfoListItemsWrapper} {
    margin-top: 0;
  }
`;

export const ListingDetailBodyCopy = styled.div<{ size?: string }>`
  margin: 20px auto 30px auto;
  color: ${colors[ColorNames.MIDNIGHT].hex};
  color: ${({ size }): string => (size === "small" ? colors[ColorNames.GRAY5].hex : colors[ColorNames.MIDNIGHT].hex)};
  ${({ size }): string => (size === "small" ? fonts.PARAGRAPH_S : fonts.PARAGRAPH_M)}
`;

export const ListingDetailButtonsWrapper = styled.div`
  width: 100%;

  & button:last-of-type {
    margin-top: 30px;
  }
`;