import React, { useEffect, useState, useMemo } from "react";
import { motion } from "framer-motion";
import useMeasure from "react-use-measure";
import classnames from "classnames";
import { useRecoilValue } from "recoil";

import useNavigate from "src/util/useNavigate";
import {
  useSetDetailPicker,
  useShowDetailPicker,
} from "src/util/useDetailPicker";

import { Account as BankAccount, ConnectedAccount, Recipient } from "src/generated/client";
import {
  capitalizeTransferType,
  decorateCurrencyValue,
} from "src/util/stringUtils";
import {
  detailPickerState,
  IDetailPickerState,
} from "src/store/detailPickerState";
import { NavRoutes } from "src/routes/navRoutes";
import { colorPalette as colors, ColorNames } from "src/theme/theme";

import {
  DetailSelectContainer,
  DetailSelectHeader,
  IconExpand,
  DetailSelectRow,
  DetailPickerOptionCheckmarkHolder,
  DetailPickerOptionCheckmark,
  DetailSelectEmptyState,
} from "src/components/forms/detail-select/DetailSelect.styles";
import {
  DetailPickerOptionButton,
  DetailPickerTabHighlight,
  DetailPickerTabsRow,
  PickerTab,
} from "src/components/detail-picker/DetailPicker.styles";

import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { Avatar } from "src/components/avatar/Avatar";


export interface DetailSelectAccountProps {
  pickerId: string;
  name: string;
  defaultValue?: string;
  label: string;
  placeholder: string;
  error?: boolean;
  bankAccounts: Partial<BankAccount>[];
  connectedAccounts?: ConnectedAccount[];
  recipients?: Recipient[];
  shouldHideNonCheckingTabs?: boolean;
  shouldOnlyShowConnectedAccounts?: boolean;
  didSelectRecipient?: (data: object) => void;
  didSelectAccount?: (data: any) => void;
  search?: boolean;
  handleSearch?: (e: {
    target: { value: React.SetStateAction<string> };
  }) => void;
  searchTerm?: string;
}

enum Tab {
  CHECKING = "checking",
  CONNECTED = "connected",
  RECIPIENTS = "recipients",
}


export const DetailSelectAccount = (
  detailSelectProps: DetailSelectAccountProps
): JSX.Element => {
  const {
    pickerId,
    bankAccounts = [],
    connectedAccounts = [],
    recipients,
    shouldHideNonCheckingTabs,
    shouldOnlyShowConnectedAccounts,
    didSelectAccount,
    didSelectRecipient,
    name,
    defaultValue,
    label,
    placeholder,
    error = false,
    search,
  } = detailSelectProps;
  const [value, setValue] = useState(defaultValue || "");
  const [hasError, setHasError] = useState(error);

  const navigate = useNavigate();
  const [ref, bounds] = useMeasure();

  const detailPickers = useRecoilValue<IDetailPickerState>(detailPickerState);
  const setDetailPicker = useSetDetailPicker();
  const { showDetailPicker, hideDetailPicker } = useShowDetailPicker();
  const [isShowing, setIsShowing] = useState(false);
  const [activeTab, setActiveTab] = useState<Tab>(Tab.CHECKING);
  const [selectedOption, setSelectedOption] = useState<any | null>(null);
  const [selectedOptionMetadata, setSelectedOptionMetadata] = useState<
    any | null
  >(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setValue(defaultValue || "");
  }, [defaultValue]);

  const filterByNickname = ({ nickname }: Partial<BankAccount> | ConnectedAccount | Recipient) => {
    return nickname?.toLowerCase().includes(searchTerm.toLowerCase());
  }

  const filterTabData = () => {
    if (activeTab === Tab.CHECKING) {
      const filteredData = bankAccounts.filter(filterByNickname);
      return filteredData;
    }

    if (activeTab === Tab.CONNECTED) {
      const filteredData = connectedAccounts.filter(filterByNickname);
      return filteredData;
    }

    if (activeTab === Tab.RECIPIENTS) {
      const filteredData = recipients?.filter(filterByNickname);

      return filteredData || [];
    }

    return [];
  };

  const didSetRecipientValue = (newValue: string, metadata: object) => {
    didSetValue(newValue, metadata);
    if (!!didSelectRecipient && recipients) didSelectRecipient(metadata);
  };

  const didSetValue = (newValue: string, metadata: object) => {
    setValue(newValue);
    setHasError(false);
    setSelectedOptionMetadata(metadata);
    if (!!didSelectAccount && metadata) didSelectAccount(metadata);
  };

  const openDetailPicker = () => showDetailPicker(pickerId);

  const handleSearch = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setTimeout(() => {
      // clearing after a delay to prevent jank when closing
      setSearchTerm("");
    }, 300);
  };

  useEffect(() => {
    const selectDataType = () => {
      let data: (Partial<BankAccount> | ConnectedAccount | Recipient)[] = [ ...bankAccounts, ...connectedAccounts ];

      if (!!recipients && activeTab === Tab.RECIPIENTS) {
        data = recipients;
      }

      return data;
    };

    const matches = selectDataType().filter((d: (Partial<BankAccount> | ConnectedAccount | Recipient)) => d.id === value);
    setSelectedOption(matches.length > 0 ? matches[0] : null);
  }, [value, bankAccounts, connectedAccounts, recipients, activeTab]);

  const renderTabContent = useMemo((): JSX.Element => {
    if (activeTab === Tab.RECIPIENTS) {
      if (!!recipients && filterTabData().length > 0) {
        return (
          <>
            {filterTabData().map((option: any, index: any) => (
              <DetailPickerOptionButton
                key={option.id + index}
                onClick={() => {
                  if (option.id === selectedOption?.id) {
                    didSetRecipientValue("", []);
                  } else {
                    didSetRecipientValue(option.id, option);
                    setTimeout(() => {
                      hideDetailPicker(pickerId);
                    }, 50);
                  }
                }}
              >
                <div className="detail-picker-option-button-with-avatar">
                  <Avatar
                    name={option.nickname || "Recipient"}
                    size={28}
                    color={ColorNames.GRAY5}
                  />
                  <div>
                    <div>{option.nickname || "Recipient"}</div>
                    <div className="secondary-label">
                      {capitalizeTransferType(option.transferType)}
                    </div>
                  </div>
                </div>
                {option.id === selectedOption?.id && (
                  <DetailPickerOptionCheckmarkHolder>
                    <DetailPickerOptionCheckmark />
                  </DetailPickerOptionCheckmarkHolder>
                )}
              </DetailPickerOptionButton>
            ))}
          </>
        );
      } else {
        return (
          <EmptyStatePlaceholder message="You don't have any matching recipients." />
        );
      }
    } else {
      if (filterTabData().length > 0) {
        return (
          <>
            {filterTabData().map((option: any, index: number) => (
              <DetailPickerOptionButton
                key={option.id + index}
                onClick={() => {
                  if (option.id === selectedOption?.id) {
                    didSetValue("", []);
                  } else {
                    didSetValue(option.id, option);
                    setTimeout(() => {
                      hideDetailPicker(pickerId);
                    }, 50);
                  }
                }}
              >
                <div>
                  <div>
                    {option.nickname.length > 1 ? option.nickname : "Account"}
                  </div>
                  <div className="secondary-label">
                    {option.last4} —{" "}
                    {decorateCurrencyValue(option.availableBalance.display)}
                  </div>
                </div>
                {option.id === selectedOption?.id && (
                  <DetailPickerOptionCheckmarkHolder>
                    <DetailPickerOptionCheckmark />
                  </DetailPickerOptionCheckmarkHolder>
                )}
              </DetailPickerOptionButton>
            ))}
          </>
        );
      } else {
        return (
          <EmptyStatePlaceholder message="You don't have any matching accounts." />
        );
      }
    }
  }, [activeTab, recipients, filterTabData, decorateCurrencyValue]);

  const renderTabAction = useMemo((): JSX.Element | boolean => {
    if (activeTab === Tab.CHECKING && recipients) {
      return (
        <ActionableButton
          label="Open New Account"
          onClick={() => {
            hideDetailPicker(pickerId);
            navigate(NavRoutes.BANK_ACCOUNTS);
          }}
          color={ColorNames.MIDNIGHT}
          iconRight={false}
        />
      );
    }

    if (activeTab === Tab.CONNECTED) {
      return (
        <ActionableButton
          label="Connect New Account"
          onClick={() => {
            hideDetailPicker(pickerId);
            navigate(NavRoutes.BANK_CONNECTED_ACCOUNTS);
          }}
          color={ColorNames.MIDNIGHT}
          iconRight={false}
        />
      );
    }

    if (activeTab === Tab.RECIPIENTS) {
      return (
        <ActionableButton
          label="Add New Recipient"
          onClick={() => {
            hideDetailPicker(pickerId);
            navigate(NavRoutes.BANK_RECIPIENTS_NEW);
          }}
          color={ColorNames.MIDNIGHT}
          iconRight={false}
        />
      );
    }

    return false;
  }, [activeTab, recipients, hideDetailPicker, navigate]);

  const renderSelectChosenOption = () => {
    if (value) {
      if (!!selectedOptionMetadata?.accountHolderName) {
        return (
          <>
            <div className="label">{selectedOption?.nickname}</div>
            <div className="tag">
              {capitalizeTransferType(selectedOption?.transferType)}
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="label">
              {selectedOption?.nickname.length > 1
                ? selectedOption?.nickname
                : "Account"}
            </div>
            <div className="tag">{selectedOption?.last4}</div>
          </>
        );
      }
    } else {
      return placeholder;
    }
  };

  useEffect(() => {
    setIsShowing(detailPickers.showIds.some((id) => id === pickerId));
  }, [detailPickers]);

  useEffect(() => {
    if (shouldHideNonCheckingTabs && !value) setActiveTab(Tab.CHECKING);
    if (shouldOnlyShowConnectedAccounts && !value) setActiveTab(Tab.CONNECTED);

    setDetailPicker({
      id: pickerId,
      origin: bounds,
      displayLabel: label,
      color: ColorNames.POWDER,
      hasValue: !!value,
      search,
      handleSearch,
      searchTerm,
      actions: renderTabAction,
      options: renderTabContent,
      tabs: shouldHideNonCheckingTabs || shouldOnlyShowConnectedAccounts ? (
        <></>
      ) : (
        <DetailPickerTabsRow>
          <PickerTab
            className={classnames({
              active: activeTab === Tab.CHECKING,
            })}
            onClick={() => setActiveTab(Tab.CHECKING)}
          >
            Checking
            <DetailPickerTabHighlight
              className={classnames({
                tab0: activeTab === Tab.CHECKING,
                tab1: activeTab === Tab.CONNECTED,
                tab2: activeTab === Tab.RECIPIENTS,
              })}
            />
          </PickerTab>
          <PickerTab
            className={classnames({
              active: activeTab === Tab.CONNECTED,
            })}
            onClick={() => setActiveTab(Tab.CONNECTED)}
          >
            Connected
          </PickerTab>
          {!!recipients && (
            <PickerTab
              className={classnames({
                active: activeTab === Tab.RECIPIENTS,
              })}
              onClick={() => {
                setActiveTab(Tab.RECIPIENTS);
              }}
            >
              Recipients
            </PickerTab>
          )}
        </DetailPickerTabsRow>
      ),
    });
  }, [
    bounds,
    isShowing,
    showDetailPicker,
    hideDetailPicker,
    label,
    value,
    search,
    handleSearch,
    searchTerm,
    renderTabAction,
    renderTabContent,
    shouldHideNonCheckingTabs,
    activeTab,
    setActiveTab,
    bankAccounts,
    connectedAccounts,
    recipients,
  ]);

  return (
    <>
      <DetailSelectContainer
        ref={ref}
        key={name}
        onClick={openDetailPicker}
        className={classnames({ open: isShowing })}
      >
        <DetailSelectHeader>
          {label}
          <IconExpand />
        </DetailSelectHeader>
        <DetailSelectRow
          error={hasError}
          className={classnames({ empty: !value })}
          initial={false}
          animate={{
            opacity: isShowing ? 0 : 1,
          }}
          transition={{
            duration: 0.15,
            delay: isShowing ? 0 : 0.25,
          }}
        >
          {renderSelectChosenOption()}
        </DetailSelectRow>
        <input type="hidden" name={name} value={value} />
      </DetailSelectContainer>
    </>
  );
};

const EmptyStatePlaceholder = (props: { message: string }): JSX.Element => (
  <motion.div
    initial={{
      opacity: 0,
      y: 10,
    }}
    animate={{
      opacity: 1,
      y: 0,
    }}
  >
    <DetailSelectEmptyState>{props.message}</DetailSelectEmptyState>
  </motion.div>
);
