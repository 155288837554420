// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-PhoneNumberInput-loading{color:rgba(var(--ods-color-content-placeholder));font-size:var(--font-size-large);line-height:1.6;margin:.4em;float:left}.onfido-sdk-ui-PhoneNumberInput-phoneNumberContainer{float:left;width:100%;padding:.5em .75em}.onfido-sdk-ui-PhoneNumberInput-phoneNumberContainer .PhoneInputCountryIcon--border,.onfido-sdk-ui-PhoneNumberInput-phoneNumberContainer .react-phone-number-input__icon{height:1.8em;width:1.8em}.onfido-sdk-ui-PhoneNumberInput-phoneNumberContainer .react-phone-number-input__icon{height:1.8em;width:1.8em;border:0;display:block}.onfido-sdk-ui-PhoneNumberInput-phoneNumberContainer .PhoneInputCountryIcon--border{background-color:transparent;-webkit-box-shadow:none;box-shadow:none}.onfido-sdk-ui-PhoneNumberInput-phoneNumberContainer input{overflow:hidden;width:100%;height:1.875em;border:0;font-size:var(--onfido-font-size-base)}.onfido-sdk-ui-PhoneNumberInput-phoneNumberContainer button:focus,.onfido-sdk-ui-PhoneNumberInput-phoneNumberContainer input:focus{outline:auto 5px -webkit-focus-ring-color}.onfido-sdk-ui-PhoneNumberInput-flagIcon{background-size:100%;background-repeat:no-repeat;background-position:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"loading": "onfido-sdk-ui-PhoneNumberInput-loading",
	"phoneNumberContainer": "onfido-sdk-ui-PhoneNumberInput-phoneNumberContainer",
	"flagIcon": "onfido-sdk-ui-PhoneNumberInput-flagIcon"
};
export default ___CSS_LOADER_EXPORT___;
