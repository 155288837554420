/* eslint no-sequences: 0 */
import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import { StyledCodeInputFormContainer, StyledCodeInput } from "src/components/forms/code-input/CodeInput.styles";
import { formatSingleDigit } from "src/util/stringUtils";
import { DataTestIds } from "src/util/testing-util/test-utils";
import { SecondaryButtonContainer } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet.styles";
import { Button } from "src/components/button/Button";
import { ColorNames } from "src/theme/theme";
import { Form } from "../Form";

export interface CodeInputProps {
  onSubmit: (code: string) => unknown;
  actionText: string;
  mfa?: boolean;
  autoSubmit?: boolean;
  actionLoading?: boolean;
  secondaryActionText?: string;
  onSecondaryAction?: (...args: any[]) => unknown;
  secondaryActionLoading?: boolean;
  secondaryActionTag?: string | undefined;
  dataCy?: string;
}

interface CodeInputFormValues {
  ZERO: string;
  ONE: string;
  TWO: string;
  THREE: string;
  FOUR: string;
  FIVE?: string;
}

interface HandleOnChangeProps {
  event: React.ChangeEvent<HTMLInputElement>;
  order: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const CodeInput = ({
  onSubmit,
  actionText,
  mfa,
  autoSubmit,
  actionLoading,
  secondaryActionText,
  onSecondaryAction,
  secondaryActionLoading,
  secondaryActionTag,
  dataCy
}: CodeInputProps): JSX.Element => {
  const INPUT_ORDER: Array<keyof CodeInputFormValues> = ["ZERO", "ONE", "TWO", "THREE", "FOUR"];

  if (mfa && INPUT_ORDER.length === 5) INPUT_ORDER.push("FIVE");

  const CodeInputSchema: Yup.ObjectSchema<object> = Yup.object().shape(
    INPUT_ORDER.reduce(
      (schema: any, key: keyof CodeInputFormValues) => ((schema[key] = Yup.string().required()), schema),
      {}
    )
  );

  const initialInputValues: CodeInputFormValues = INPUT_ORDER.reduce(
    (initialValues: CodeInputFormValues, key: keyof CodeInputFormValues) => ((initialValues[key] = ""), initialValues),
    {} as CodeInputFormValues
  );

  const handleOnChange = ({ event, order, handleChange, setFieldValue }: HandleOnChangeProps): void => {
    handleChange(event);

    const digitInput = mfa ? formatSingleDigit(event.target.value) : event.target.value.toUpperCase();
    const newValue = digitInput || currentValue;
    setFieldValue(order, newValue);

    const nextInput = event?.target?.nextElementSibling as HTMLInputElement;
    if (!!digitInput && !!nextInput) nextInput.focus();
  };

  const handleKeyUp = (
    event: React.KeyboardEvent<HTMLInputElement>,
    submitForm: (() => Promise<void>) & (() => Promise<any>)
  ): void => {
    const changeEvent = event as unknown as React.ChangeEvent<HTMLInputElement>;
    const input = event?.target as HTMLInputElement;

    if ((event.keyCode === 8 || event.key === "Backspace") && currentValue.length === 0 && input.value.length === 0) {
      const prevInput = changeEvent?.target?.previousElementSibling as HTMLInputElement;
      if (!!prevInput) prevInput.focus();
    }

    const nextInput = changeEvent?.target?.nextElementSibling as HTMLInputElement;
    const mfaCode = mfa && !!formatSingleDigit(changeEvent.target.value) && !nextInput;
    const otherCode = autoSubmit && !nextInput;

    if (mfaCode || otherCode) {
      submitForm();
    }
  };

  const handlePaste = (
    event: any,
    submitForm: (() => Promise<void>) & (() => Promise<any>),
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ): void => {
    event.preventDefault();
    const pastedString = event.clipboardData.getData("Text");
    if (!!pastedString) {
      INPUT_ORDER.map((order, index) => setFieldValue(order, pastedString[index] || ""));
    }

    if (mfa || autoSubmit) {
      // event loop error?
      // i have no clue why this timeout is required to make it work
      // but when it’s not here, that submit does not fire for some reason...
      setTimeout(() => {
        submitForm();
      }, 0);
    }
  };

  const handleSubmit = async (
    values: CodeInputFormValues,
    setSubmitting: (submitting: boolean) => void
  ): Promise<void> => {
    const code: string = INPUT_ORDER.reduce(
      (partialCode: string, key: keyof CodeInputFormValues) => `${partialCode}${values[key]}`,
      ""
    );
    await onSubmit(code);
    setSubmitting(false);
  };

  let currentValue = "";

  return (
    <Formik
      initialValues={initialInputValues}
      validationSchema={CodeInputSchema}
      onSubmit={async (values, { resetForm, setSubmitting }): Promise<void> => {
        await handleSubmit(values, setSubmitting);
        resetForm();
      }}
    >
      {({ isSubmitting, handleChange, setFieldValue, submitForm }): JSX.Element => (
        <Form>
          <>
            <StyledCodeInputFormContainer data-cy={dataCy}>
              {INPUT_ORDER.map((order, idx) => (
                <StyledCodeInput
                  autoFocus={idx === 0 ? true : false}
                  key={order}
                  name={order}
                  type={mfa ? "number" : "text"}
                  placeholder="•"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                    handleOnChange({ event, order, handleChange, setFieldValue });
                  }}
                  onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>): void => {
                    if (event.key === "Enter") {
                      return;
                    }

                    setFieldValue(order, "");
                    currentValue = event.currentTarget.value;
                  }}
                  onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>): void => {
                    handleKeyUp(event, submitForm);
                  }}
                  onPaste={(event: any): void => handlePaste(event, submitForm, setFieldValue)}
                  autoComplete="one-time-code"
                  data-testid={DataTestIds.CODE_INPUT}
                />
              ))}
            </StyledCodeInputFormContainer>

            <Button raised type="submit" loading={isSubmitting || actionLoading}>
              {actionText}
            </Button>

            {!!onSecondaryAction && !!secondaryActionText && (
              <SecondaryButtonContainer>
                <Button
                  color={ColorNames.TRANSPARENT}
                  data-cy={secondaryActionTag}
                  onClick={onSecondaryAction}
                  loading={!!secondaryActionLoading}
                >
                  {secondaryActionText}
                </Button>
              </SecondaryButtonContainer>
            )}
          </>
        </Form>
      )}
    </Formik>
  );
};
