import { ActionFunction } from "react-router-dom";
import * as Yup from "yup";

import {
  createLine,
  updateLine,
  cancelLine,
  acceptLineTerms,
  initiateLineDownPayment,
  allocateLineDownPayment,
  completeDocumentSigning,
  uploadLineInsurance
} from "src/services/lines";
import { allocateRepaymentAccount } from "src/services/organizations";
import { createPayment } from "src/services/payments";
import { CreatePaymentInput, UnitAchDirection, TargetAccountType } from "src/generated/client";
import { decorateCurrencyValue, convertStringNumberToCents, formatCentsToString } from "src/util/stringUtils";
import { getFormValidationErrors } from "src/util/forms";

import { RouteActionResponse } from "src";

export const createLineAction: ActionFunction = async ({ request }) => {
  const formData = Object.fromEntries(await request.formData());
  const response: RouteActionResponse<any> = {};

  if (request.method === "DELETE") {
    try {
      const success = await cancelLine(formData.lineId as string);
      response.response = { cancelLine: success };
    } catch (error) {
      response.error = error;
      return response;
    }
  }

  switch (formData.step as string) {
    case "amount":
      try {
        const amountSchema: Yup.ObjectSchema<object> = Yup.object().shape({
          termLength: Yup.number().required("Please choose a term length."),
          loanAmount: Yup.number()
            .max(Yup.ref("availableToBorrow"), "Your loan amount cannot be greater than you have available to borrow.")
            .min(
              Yup.ref("minimumAmount"),
              `You must specify a loan amount greater than ${decorateCurrencyValue(
                formatCentsToString(formData.minimumAmount as string)
              )}.`
            )
            .required("You must specify a loan amount."),
          auctionStartDate: Yup.string().required("You must specify an auction.")
        });
        await amountSchema.validate(formData, { abortEarly: false });

        if (!!formData.lineId) {
          const line = await updateLine(formData.lineId as string, {
            amount: convertStringNumberToCents((formData.loanAmount as string) || ""),
            termLengthInMonths: parseInt(formData.termLength as string),
            lineTypeId: formData.lineTypeId as string
          });
          response.response = { line };
        } else {
          const line = await createLine({
            amount: convertStringNumberToCents((formData.loanAmount as string) || ""),
            termLengthInMonths: parseInt(formData.termLength as string),
            lineTypeId: formData.lineTypeId as string,
            auctionDate: {
              start: formData.auctionStartDate as string,
              end: formData.auctionEndDate as string
            }
          });
          response.response = { line };
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          response.validationErrors = getFormValidationErrors(error);
        } else {
          response.error = error;
        }
        return response;
      }
      break;
    case "repayment":
      try {
        const repaymentSchema: Yup.ObjectSchema<object> = Yup.object().shape({
          repaymentAccountId: Yup.string().required("Please choose a repayment account.")
        });
        await repaymentSchema.validate(formData, { abortEarly: false });

        const res = await allocateRepaymentAccount(formData.repaymentAccountId as string);
        response.response = { res };
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          response.validationErrors = getFormValidationErrors(error);
        } else {
          response.error = error;
        }
        return response;
      }
      break;
    case "insurance":
      response.response = {};
      break;
    case "pre-approval":
      try {
        const line = await acceptLineTerms(formData.lineId as string);
        response.response = { line };
      } catch (error) {
        response.error = error;
        return response;
      }
      break;
    case "down-payment":
      try {
        const connectedSchema: Yup.ObjectSchema<object> = Yup.object().shape({
          connectedAccountId: Yup.string().required("Please choose a connected account."),
          depositAccountId: Yup.string().required("There was a problem setting up your transfer."),
          amount: Yup.number().required("There was a problem setting up your transfer.")
        });
        const transferSchema: Yup.ObjectSchema<object> = Yup.object().shape({
          sourceAccountId: Yup.string().required("Please choose a connected account.")
        });

        if (!!formData.connectedAccountId) {
          await connectedSchema.validate(formData, { abortEarly: false });

          // make actual transfer
          const payload: CreatePaymentInput = {
            sourceAccountId: formData.depositAccountId as string,
            targetAccountId: formData.connectedAccountId as string,
            lineId: formData.lineId as string,
            amount: convertStringNumberToCents((formData.amount as string) || ""),
            direction: UnitAchDirection.Debit,
            targetAccountType: TargetAccountType.ConnectedAccount
          };
          await createPayment(payload);

          const line = await initiateLineDownPayment(formData.lineId as string);
          response.response = { line };
        } else {
          await transferSchema.validate(formData, { abortEarly: false });
          const line = await allocateLineDownPayment(formData.lineId as string, formData.sourceAccountId as string);
          response.response = { line };
        }
      } catch (error) {
        response.error = error;
        return response;
      }
      break;
    case "sign":
      try {
        const signSchema: Yup.ObjectSchema<object> = Yup.object().shape({
          documentId: Yup.string().required("You must sign the loan document.")
        });
        await signSchema.validate(formData, { abortEarly: false });

        const success = await completeDocumentSigning(formData.lineId as string, formData.documentId as string);
        response.response = { success };
      } catch (error) {
        response.error = error;
        return response;
      }
      break;
    default:
      response.error = Error();
      return response;
  }

  response.response.step = formData.step as string;
  return response;
};

export const finalizeLineAction: ActionFunction = async ({ request }) => {
  const formData = Object.fromEntries(await request.formData());
  const response: RouteActionResponse<any> = {};

  if (request.method === "PUT") {
    try {
      const schema = Yup.object().shape({
        lineId: Yup.string().required("There was a problem finding this loan."),
        insuranceProof: Yup.mixed()
          .notOneOf([""], "You must provide a proof of insurance file.")
          .required("You must provide a proof of insurance file.")
      });
      await schema.validate(formData, { abortEarly: false });
      const line = await uploadLineInsurance({
        lineId: formData.lineId as string,
        insuranceProof: formData.insuranceProof as Blob
      });
      response.response = { step: "insurance", line };
    } catch (error) {
      response.error = error;
      return response;
    }
  } else {
    try {
      const signSchema: Yup.ObjectSchema<object> = Yup.object().shape({
        documentId: Yup.string().required("You must sign the loan document.")
      });
      await signSchema.validate(formData, { abortEarly: false });

      const success = (await completeDocumentSigning(formData.lineId as string, formData.documentId as string)).success;
      response.response = { step: "sign", success };
    } catch (error) {
      response.error = error;
      return response;
    }
  }

  return response;
};
