import { useCallback, useEffect, useRef, useState } from "react";
import {
  ApolloClient,
  useApolloClient,
  useMutation,
  ApolloError,
} from "@apollo/client";
import { useCookies } from "react-cookie";

import { client } from "src";
import { PartnershipType } from "src/generated/client";
import { useCurrentUser } from "src/services/users";
import { needsLogoutVar } from "src/store/currentUserState";
import { NavRoutes } from "src/routes/navRoutes";
import { NavigationDirection } from "src/store/navigationState";
import useNavigate from "src/util/useNavigate";

import { useLogger } from "src/util/useLogger";
import { storedUserDataKey, storedCookieDataKey } from "src/util/localStorage";

import {
  ADD_EMAIL_TO_WAITLIST,
  LOGIN,
  LOGOUT,
  CHANGE_PASSWORD,
  GENERATE_MULTI_FACTOR,
  VERIFY_MULTI_FACTOR,
  IMPERSONATE_REQUEST_RESPONSE,
  RECAPTCHA_SITE_VERIFY,
  CREATE_ONFIDO_APPLICATION,
  CREATE_ONFIDO_CHECK,
} from "src/services/gql/_auth";

export interface LoginData {
  email: string;
  password: string;
  inviteCode?: string;
  recaptchaScore?: number;
}

export interface LoginResult {
  session: string;
  mfaEnabled: boolean;
  organizationId: boolean;
}

export interface SingleSignOnLoginData {
  token: string;
}

export interface ChangePasswordData {
  oldPassword: string;
  newPassword: string;
}

export interface MultiFactor {
  secret: string;
  uri: string;
  qr: string;
}

export interface ImpersonationResponseData {
  id: string;
  allow: boolean;
}

export interface FileUploadData {
  validity: boolean;
  files: Array<string>;
}

export type OnfidoArgs = {
  getSdkToken: () => Promise<
    CreateOnfidoApplication["createOnfidoApplication"] | undefined
  >;
  loading: boolean;
  error: ApolloError | undefined;
};

export type CreateOnfidoApplication = {
  createOnfidoApplication: {
    sdkToken: string;
    applicantId: string;
  };
};

export type CreateOnfidoApplicationVariables = {
  input: {
    referrer: string;
  };
};

export const hofundRoutes = () => {
  return {};
};

export const useSetSession = (): ((session?: string) => void) => {
  const client: ApolloClient<object> = useApolloClient();

  const setSession = useCallback(
    (session?: string): void => {
      if (session) {
        localStorage.setItem(storedUserDataKey.SESSION, session);
      } else {
        localStorage.removeItem(storedUserDataKey.SESSION);
      }
    },
    [client]
  );

  return setSession;
};

export const useAddEmailToWaitlist = (): {
  addEmail: (email: string) => Promise<boolean>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [add, { loading, error }] = useMutation(ADD_EMAIL_TO_WAITLIST, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const addEmail = useCallback(
    async (email: string): Promise<boolean> => {
      try {
        const { data } = await add({ variables: { email } });
        return !!data?.addEmailToWaitlist?.success;
      } catch (err) {
        captureException(err);
        return false;
      }
    },
    [add, captureException]
  );

  return { addEmail, loading, error };
};

export const useSingleSignOn = (): {
  singleSignOnLogin: (
    values: SingleSignOnLoginData
  ) => Promise<LoginResult | undefined>;
  loading: boolean;
  error: Error | undefined;
} => {
  const setSession = useSetSession();
  const loading = false;
  const error = undefined;

  const singleSignOnLogin = useCallback(
    async (values: SingleSignOnLoginData): Promise<LoginResult | undefined> => {
      setSession();
      setSession(values.token);
      return {
        session: values.token,
        mfaEnabled: false,
        organizationId: false,
      }; // TODO: how to fetch these?
    },
    [setSession]
  );

  return { singleSignOnLogin, loading, error };
};

const setSession = (session?: string): void => {
  if (session) {
    localStorage.setItem(storedUserDataKey.SESSION, session);
  } else {
    localStorage.removeItem(storedUserDataKey.SESSION);
  }
};

export const loginUser = async (values: LoginData) => {
  try {
    const res = await client.mutate({
      mutation: LOGIN,
      errorPolicy: "all",
      variables: { ...values },
    });

    setSession();
    if (!!res.data?.login) {
      const loginInfo: LoginResult = res.data.login;
      if (!!loginInfo.session) {
        setSession(loginInfo.session);
      } else {
        throw Error("There was a problem logging in.");
      }
      return loginInfo;
    } else {
      let errorMessage = "There was a problem logging in.";
      if (!!res.errors && res.errors.length > 0) {
        errorMessage = res.errors[0].message;
      }
      throw Error(errorMessage);
    }
  } catch (err) {
    throw err;
  }
};

export const verifyMultiFactor = async ({
  token,
  preventLogout,
}: {
  token: string;
  preventLogout?: boolean;
}) => {
  try {
    const res = await client.mutate({
      mutation: VERIFY_MULTI_FACTOR,
      errorPolicy: "all",
      variables: { token },
    });

    if (!!res.data?.verifyMultiFactor) {
      const loginInfo: LoginResult = res.data.verifyMultiFactor;

      if (!!loginInfo.session) {
        setSession(loginInfo.session);
      } else {
        if (!preventLogout) setSession();
        throw Error("There was a problem verifying your code.");
      }
      return loginInfo;
    } else {
      throw Error("There was a problem verifying your code.");
    }
  } catch (err) {
    throw err;
  }
};

export const useLogin = (): {
  login: (values: LoginData) => Promise<LoginResult | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [loginUser, { loading, error }] = useMutation(LOGIN, {
    errorPolicy: "all",
  });
  const setSession = useSetSession();
  const { captureException } = useLogger();

  const login = useCallback(
    async (values: LoginData): Promise<LoginResult | undefined> => {
      setSession();
      try {
        const { data } = await loginUser({ variables: values });
        if (!!data?.login) {
          const loginInfo: LoginResult = data.login;
          setSession(loginInfo.session);
          return loginInfo;
        }
      } catch (err) {
        captureException(err);
      }
    },
    [loginUser, setSession, captureException]
  );

  return { login, loading, error };
};

export const useChangePassword = (): {
  changePassword: (values: ChangePasswordData) => Promise<boolean>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [updatePassword, { loading, error }] = useMutation(CHANGE_PASSWORD, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const changePassword = useCallback(
    async (values: ChangePasswordData): Promise<boolean> => {
      try {
        const { data } = await updatePassword({ variables: values });
        return !!data?.changePassword?.success;
      } catch (err) {
        captureException(err);
        return false;
      }
    },
    [updatePassword, captureException]
  );

  return { changePassword, loading, error };
};

export const useGenerateMultiFactor = (): {
  generateMultiFactor: () => Promise<MultiFactor | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [generate, { loading, error }] = useMutation(GENERATE_MULTI_FACTOR, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const generateMultiFactor = useCallback(async (): Promise<
    MultiFactor | undefined
  > => {
    try {
      const { data } = await generate();
      if (!!data?.generateMultiFactor) {
        const multiFactorInfo: MultiFactor = data.generateMultiFactor;
        return multiFactorInfo;
      }
    } catch (err) {
      captureException(err);
    }
  }, [generate, captureException]);

  return { generateMultiFactor, loading, error };
};

export const useVerifyMultiFactor = (
  preventLogout?: boolean
): {
  verifyMultiFactor: (token: string) => Promise<LoginResult | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [verify, { loading, error }] = useMutation(VERIFY_MULTI_FACTOR, {
    errorPolicy: "all",
  });
  const setSession = useSetSession();
  const { captureException } = useLogger();

  const verifyMultiFactor = useCallback(
    async (token: string): Promise<LoginResult | undefined> => {
      try {
        const { data } = await verify({ variables: { token } });
        if (!!data?.verifyMultiFactor) {
          const loginInfo: LoginResult = data.verifyMultiFactor;
          if (!!loginInfo.session) {
            setSession(loginInfo.session);
          } else if (!preventLogout) {
            setSession();
          }
          return loginInfo;
        }
      } catch (err) {
        captureException(err);
      }
    },
    [preventLogout, verify, setSession, captureException]
  );

  return { verifyMultiFactor, loading, error };
};

export const performLogout = () => {
  try {
    client.mutate({ mutation: LOGOUT, errorPolicy: "all" });
  } catch (_) {
    // can fail silently
  }
};

export const useImpersonationResponse = (): {
  respondToImpersonationRequest: (
    values: ImpersonationResponseData
  ) => Promise<boolean>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [sendImpersonateResponse, { loading, error }] = useMutation(
    IMPERSONATE_REQUEST_RESPONSE,
    {
      errorPolicy: "all",
    }
  );
  const { captureException } = useLogger();

  const respondToImpersonationRequest = useCallback(
    async (values: ImpersonationResponseData): Promise<boolean> => {
      try {
        const { data } = await sendImpersonateResponse({ variables: values });
        return !!data?.respondToImpersonationRequest?.success;
      } catch (err) {
        captureException(err);
        return false;
      }
    },
    [sendImpersonateResponse, captureException]
  );

  return { respondToImpersonationRequest, loading, error };
};

export const useAuthRedirect = ({
  queryParams,
  invite,
}: {
  queryParams?: URLSearchParams;
  invite?: string;
}): {
  updateAuthInviteCode: (code?: string) => void;
} => {
  const [, setCookie, removeCookie] = useCookies([
    storedCookieDataKey.ACCEPTED_INVITE_CODE_PREFIX,
    storedCookieDataKey.INVITE_CODE_PREFIX,
    storedCookieDataKey.PARTNERSHIP_PREFIX
  ]);
  const didRunInitially = useRef(false);
  const didRunAfterAuth = useRef(false);
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>();
  const currentUser = useCurrentUser();
  const [inviteCode, setInviteCode] = useState<string | undefined>(invite);
  const navigate = useNavigate();

  useEffect(() => {
    if (!!queryParams) {
      const redirect = queryParams.get("redirect");
      if (redirect) setRedirectUrl(decodeURIComponent(redirect));
    }
  }, [queryParams]);

  useEffect(() => {
    // only run once, at the start
    if (!didRunInitially.current) {
      const session: string | null = localStorage.getItem(
        storedUserDataKey.SESSION
      );
      // log current user out if accepting invite
      if (!!session && !!inviteCode) {
        needsLogoutVar({ logout: true, persistCookies: true });
      }
      didRunInitially.current = true;
    }
  }, []);

  useEffect(() => {
    // only run once, when currentUser exists
    if (!!currentUser && !didRunAfterAuth.current && didRunInitially.current) {
      didRunAfterAuth.current = true;

      if (!!inviteCode) {
        setCookie(storedCookieDataKey.ACCEPTED_INVITE_CODE_PREFIX, inviteCode);
        removeCookie(storedCookieDataKey.INVITE_CODE_PREFIX);
      }

      if (!currentUser.onboarded) {
        navigate(NavRoutes.ONBOARD, { direction: NavigationDirection.FADE });
      } else {
        if (!!redirectUrl) {
          navigate(redirectUrl, { direction: NavigationDirection.FADE });
        } else {
          if (currentUser.partnershipType === PartnershipType.TetonRidge) {
            setCookie(storedCookieDataKey.PARTNERSHIP_PREFIX, currentUser.partnershipType);
            navigate(NavRoutes.ONELINE_DASHBOARD, {
              direction: NavigationDirection.FADE,
            });
          } else {
            removeCookie(storedCookieDataKey.PARTNERSHIP_PREFIX);
            navigate(NavRoutes.BANK_DASHBOARD, {
              direction: NavigationDirection.FADE,
            });
          }
        }
      }
    }
  }, [currentUser, inviteCode, setCookie, removeCookie, redirectUrl, navigate]);

  return { updateAuthInviteCode: setInviteCode };
};

export type RecaptchaVerifyVariables = {
  token: string;
};

export type RecaptchaVerifyResponse = {
  recaptchaSiteVerify: {
    success: boolean;
    score: number;
    errorCodesList: string[];
  };
};

export type VerifyResponse =
  | RecaptchaVerifyResponse["recaptchaSiteVerify"]
  | undefined;

export const useRecaptchaSiteVerify = (): {
  verify: (token: string) => Promise<VerifyResponse>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [verifySite, { loading, error }] = useMutation<
    RecaptchaVerifyResponse,
    RecaptchaVerifyVariables
  >(RECAPTCHA_SITE_VERIFY, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const verify = useCallback(
    async (token: string): Promise<VerifyResponse> => {
      try {
        const { data } = await verifySite({ variables: { token } });

        return data?.recaptchaSiteVerify;
      } catch (err) {
        captureException(err);
      }
    },
    [verifySite, captureException]
  );

  return { verify, loading, error };
};

export const useOnfido = (): OnfidoArgs => {
  const [createOnfidoApplication, { loading, error }] = useMutation<
    CreateOnfidoApplication,
    CreateOnfidoApplicationVariables
  >(CREATE_ONFIDO_APPLICATION, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const getSdkToken = useCallback(async (): Promise<
    CreateOnfidoApplication["createOnfidoApplication"] | undefined
  > => {
    const { protocol, host } = window.location;
    try {
      const { data } = await createOnfidoApplication({
        variables: { input: { referrer: `${protocol}//*.${host}/*` } },
      });

      return data?.createOnfidoApplication;
    } catch (error) {
      captureException(error);
    }
  }, [createOnfidoApplication, captureException]);

  return { getSdkToken, loading, error };
};

export type CreateOnfidoCheck = {
  createOnfidoCheck: {
    success: boolean;
  };
};

export const useOnfidoChecks = (): {
  check: () => Promise<boolean | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [createOnfidoCheck, { loading, error }] =
    useMutation<CreateOnfidoCheck>(CREATE_ONFIDO_CHECK, {
      errorPolicy: "all",
    });
  const { captureException } = useLogger();

  const check = useCallback(async (): Promise<boolean | undefined> => {
    try {
      const { data } = await createOnfidoCheck();

      return data?.createOnfidoCheck.success;
    } catch (error) {
      captureException(error);
    }
  }, [createOnfidoCheck, captureException]);

  return { check, loading, error };
};
