import React, { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";

import useNavigate from "src/util/useNavigate";
import { showNotice } from "src/store/alertState";
import { useFetchCurrentUser, useCurrentUser } from "src/services/users";
import useSetOrganization from "src/util/useSetOrganization";

import { useShowBottomSheet } from "src/util/useBottomSheet";
import {
  useAddBusinessInfo,
  AddBusinessInfoData,
} from "src/services/organizations";
import { StyledAuthPage } from "src/pages/auth/Auth.styles";
import BusinessInfo from "src/pages/auth/onboarding/business-info/BusinessInfo";
import BusinessOwners from "src/pages/auth/onboarding/business-info/BusinessOwners";
import Terms from "src/pages/auth/onboarding/terms/Terms";
import { StepperNav } from "src/components/nav-bars/stepper-nav/StepperNav";
import { NavRoutes } from "src/routes/navRoutes";
import { BeneficialOwner, OrgType, UserIdVerificationName } from "src/generated/client";
import {
  UserVerificationType,
  useAddVerificationToUser,
} from "src/services/users";
import { NavigationDirection } from "src/store/navigationState";
import { clearOnboardingTermsProgress } from "src/util/localStorage";

enum AllSteps {
  BUSINESS = "business",
  BUSINESS_OWNERS = "business_owners",
  TERMS = "terms",
}

const NewBusinessOrganization = (): JSX.Element => {
  const currentUser = useCurrentUser();
  const { fetchCurrentUser } = useFetchCurrentUser();
  const { hideBottomSheet } = useShowBottomSheet();
  const {
    addBusinessInfo: addBusinessInfoGQL,
    addOwnerInfo,
    error,
  } = useAddBusinessInfo();
  const { addVerification, error: addError } = useAddVerificationToUser();
  const { setOrganization } = useSetOrganization();

  const [orgPayload, setOrgPayload] = useState<AddBusinessInfoData>();
  const [docIncorporationCertificate, setDocIncorporationCertificate] =
    useState<Blob>();
  const [docEINConfirmation, setDocEINConfirmation] = useState<Blob>();

  const newOrgSteps: string[] = [
    AllSteps.BUSINESS,
    AllSteps.BUSINESS_OWNERS,
    AllSteps.TERMS,
  ];
  const [step, setStep] = useState<string>(AllSteps.BUSINESS);
  const [stepDirection, setStepDirection] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    const err = error || addError;

    if (!!err) {
      showNotice(err.message || "There was a problem creating your account.", {
        error: true,
      });
    }
  }, [error, addError]);

  const handleBackStep = async (): Promise<void> => {
    setStepDirection(-1);

    if (step !== AllSteps.BUSINESS) {
      setStep(newOrgSteps[newOrgSteps.indexOf(step) - 1]);
    } else {
      hideBottomSheet();
    }
  };

  const handleNextStep = async () => {
    setStepDirection(1);

    if (step !== AllSteps.TERMS) {
      setStep(newOrgSteps[newOrgSteps.indexOf(step) + 1]);
    }
  };

  const addBusinessInfo = async (
    values: Omit<AddBusinessInfoData, "orgType">,
    docs: {
      incorporationCertificate: Blob | undefined;
      einConfirmation: Blob | undefined;
    },
    setSubmitting: (submitting: boolean) => void
  ) => {
    setDocIncorporationCertificate(docs.incorporationCertificate);
    setDocEINConfirmation(docs.einConfirmation);

    setOrgPayload({
      ...orgPayload,
      orgType: OrgType.Business,
      ...values,
    });

    setSubmitting(false);
    handleNextStep();
  };

  const addBusinessOwners = async (
    owners: BeneficialOwner[],
    setSubmitting: (isSubmitting: boolean) => void
  ): Promise<void> => {
    setOrgPayload({
      ...orgPayload!,
      beneficialOwners: owners,
    });

    setSubmitting(false);
    handleNextStep();
  };

  const acceptTerms = async (
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const { beneficialOwners, businessOrgData, ...values } = orgPayload!;
    const org = await addBusinessInfoGQL({ values });

    if (!org) {
      setSubmitting(false);
      return;
    }

    await setOrganization(org);

    const certificateUpload = await addVerification({
      verificationType: UserVerificationType.Id,
      verificationImage: docIncorporationCertificate,
      idVerificationName: UserIdVerificationName.CertificateOfIncorporation,
      organizationId: org.id,
      userId: currentUser?.id || "",
    });

    if (!certificateUpload) {
      setSubmitting(false);
      return;
    }

    const confirmationUpload = await addVerification({
      verificationType: UserVerificationType.Id,
      verificationImage: docEINConfirmation,
      idVerificationName: UserIdVerificationName.EinConfirmation,
      organizationId: org.id,
      userId: currentUser?.id || "",
    });

    if (!confirmationUpload) {
      setSubmitting(false);
      return;
    }

    const finalOrg = await addOwnerInfo({
      values: {
        organizationId: org.id,
        orgType: values.orgType,
        beneficialOwners,
        businessOrgData,
      },
    });

    setSubmitting(false);

    if (finalOrg) {
      fetchCurrentUser();
      clearOnboardingTermsProgress();
      hideBottomSheet();
      navigate(NavRoutes.BANK_DASHBOARD, {
        direction: NavigationDirection.FADE,
      });
    }
  };

  const getStepComponent = () => {
    let component: React.ReactElement | null = null;
    switch (step) {
      case AllSteps.BUSINESS:
        component = (
          <BusinessInfo
            orgData={{
              ...orgPayload,
              id: "",
              name: orgPayload?.orgName,
              orgType: OrgType.Business,
              currentContext: false,
            }}
            nextStep={addBusinessInfo}
            stepDirection={stepDirection}
          />
        );
        break;
      case AllSteps.BUSINESS_OWNERS:
        component = (
          <BusinessOwners
            nextStep={addBusinessOwners}
            stepDirection={stepDirection}
          />
        );
        break;
      case AllSteps.TERMS:
        component = (
          <Terms
            orgType={OrgType.Business}
            nextStep={acceptTerms}
            stepDirection={stepDirection}
            isFinalStep={true}
          />
        );
        break;
    }

    return component;
  };

  return (
    <StyledAuthPage>
      <StepperNav
        onBackClick={handleBackStep}
        step={newOrgSteps.indexOf(step)}
        totalSteps={newOrgSteps.length}
      />

      <AnimatePresence initial={false} exitBeforeEnter custom={stepDirection}>
        <React.Fragment key={step}>{getStepComponent()}</React.Fragment>
      </AnimatePresence>
    </StyledAuthPage>
  );
};

export default NewBusinessOrganization;
