import React, { useEffect, useCallback } from "react";
import useNavigate from "src/util/useNavigate";

import { SurveyRedirectPage } from "src/pages/survey-redirect/SurveyRedirect.styles";
import { NavRoutes } from "src/routes/navRoutes";
import { useAddVerificationToUser, UserVerificationType } from "src/services/users";
import { LoadingContainer } from "src/components/loading-container/LoadingContainer";

const SurveyRedirect = (): JSX.Element => {
  const { addVerification, error } = useAddVerificationToUser();
  const navigate = useNavigate();

  const add = useCallback(addVerification, []);

  useEffect(() => {
    const handleTriggerSurveyVerification = async (): Promise<void> => {
      const success = await add({ verificationType: UserVerificationType.Survey, userId: "" });
    };

    if (!error) {
      handleTriggerSurveyVerification();
    } else {
      if (
        error.message === "Authentication error. Please re-log in." ||
        error.message.includes("Invalid authorization token")
      ) {
        navigate(`${NavRoutes.SIGN_IN}?redirect=${encodeURIComponent(NavRoutes.SURVEY_COMPLETE)}`);
      } else {
        navigate(NavRoutes.SIGN_IN);
      }
    }
  }, [error, navigate, add]);

  return (
    <SurveyRedirectPage>
      <LoadingContainer />
    </SurveyRedirectPage>
  );
};

export default SurveyRedirect;
