import styled from "styled-components/macro";
import { motion } from "framer-motion";
import { colorPalette as colors, ColorNames, dropShadows, ShadowNames } from "src/theme/theme";

import { StyledFieldContainer } from "..";

export const SliderInputContainer = styled.div`
  ${StyledFieldContainer} {
    border-radius: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
`;

export const SliderContainer = styled.div`
  height: 30px;
  padding: 0 10px;
  background: ${colors[ColorNames.GRAY3].hex};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const SliderTrackContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  position: relative;
`;

export const SliderTrack = styled.div`
  height: 2px;
  margin: 0 10px;
  border-radius: 1px;
  background: ${colors[ColorNames.GRAY5].hex};
  pointer-events: none;
`;

export const SliderHandle = styled(motion.div)`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: ${colors[ColorNames.WHITE].hex};
  position: absolute;
  cursor: grab;
  box-shadow: ${dropShadows[ShadowNames.AVATAR]};
`;