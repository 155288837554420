/*
Grouping fragments in same file removes bug where files which
import fragments from each other cause a
GraphQLError: Syntax Error: Unexpected Name "undefined" error
*/
import { gql } from "@apollo/client";

export const cardsFragment = gql`
  fragment CardData on Card {
    id
    accountId
    limit {
      display
      amount
    }
    organizationId
    cardHolderFirstName
    cardHolderLastName
    cardHolderEmail
    type
    limitBehaviour
    design
    last4
    expiryDate
    externalCardId
    status
    spent {
      display
      amount
    }
    spentToday {
      display
      amount
    }
    spentThisMonth {
      display
      amount
    }
    address {
      street
      city
      state
      postalCode
      country
    }
  }
`;

export const lineTypeFragment = gql`
  fragment LineTypeData on LineType {
    apyRange {
      lower {
        amount
        display
      }
      upper {
        amount
        display
      }
    }
    collateralType
    description
    id
    ltvRange {
      lower {
        amount
        display
      }
      upper {
        amount
        display
      }
    }
    maxOfferingAmount {
      amount
      display
    }
    requiredDeposit {
      amount
      display
    }
    termLengthMonths
    termMonthRange {
      lower
      upper
    }
    title
  }
`;

export const linePaymentScheduleFragment = gql`
  fragment LinePaymentScheduleData on AmortizationPayment {
    interestAmountPaid {
      display
      amount
    }
    paymentDate
    principalAmountPaid {
      display
      amount
    }
    remainingAmount {
      display
      amount
    }
  }
`;

export const lineMinimalFragment = gql`
  fragment LineMinData on Line {
    id
    loanAmount {
      display
      amount
    }
    createdAt
    finalized
    lineType {
      id
      title
    }
    originationStatus
    status
  }
`;

export const lineFragment = gql`
  fragment LineData on Line {
    amountBorrowed {
      display
      amount
    }
    amountPaidOff {
      display
      amount
    }
    auctionDate {
      start
      end
    }
    collateral {
      collateralType
      currentValue {
        display
        amount
      }
      description
      identification
      originalValue {
        display
        amount
      }
    }
    completedPaymentIds
    createdAt
    documents {
      documentId
      signed
      title
      documentType
      documentStatus
    }
    downPaymentAccountId
    downPaymentAmount {
      display
      amount
    }
    finalized
    id
    interestAmountPaidOff {
      display
      amount
    }
    interestRate {
      display
      amount
    }
    latePaymentIds
    lineType {
      ...LineTypeData
    }
    loanAmount {
      display
      amount
    }
    loanInterestAmount {
      display
      amount
    }
    loanType
    monthlyRepaymentAmount {
      display
      amount
    }
    monthsRemaining
    monthsTotal
    originationStatus
    organizationId
    outstandingBalance {
      display
      amount
    }
    paymentSchedule {
      ...LinePaymentScheduleData
    }
    personalGuarantee
    principalAmountPaidOff {
      display
      amount
    }
    projectedPayoffDate
    proofOfInsuranceUploaded
    repaymentDate
    status
    subscriptionId
    subscription {
      nextPaymentDate
      inDefaultReason
      inDefaultStage
      status
      latePayments {
        id
      }
      completedPayments {
        id
      }
    }
    termLengthInMonths
    userId
  }
  ${lineTypeFragment}
  ${linePaymentScheduleFragment}
`;

export const oneLineFragment = gql`
  fragment OneLineData on OneLine {
    lines {
      ...LineMinData
    }
    totals {
      approxPayOffDate
      availableToBorrow {
        display
        amount
      }
      monthsRemaining
      monthsTotal
      openLines
      outstandingBalance {
        display
        amount
      }
      totalAmountBorrowed {
        display
        amount
      }
      totalAmountPaidOff {
        display
        amount
      }
      totalMonthlyPayment {
        display
        amount
      }
    }
    availableToUnderwrite
    eligibleForQualification
    eligibilityQualificationAmount {
      display
      amount
    }
    repaymentAccount {
      ... on Account {
        nickname
        last4
      }
      ... on ConnectedAccount {
        nickname
        last4
      }
    }
    repaymentAccountId
    repaymentAccountType
    repaymentFeePercent {
      display
      amount
    }
    underwritingReviewStatus
  }
  ${lineMinimalFragment}
`;

// Organization fragments
export const organizationMinimalFragment = gql`
  fragment OrgMinData on Organization {
    id
    currentContext
    name
    orgType
  }
`;

export const organizationFragment = gql`
  fragment OrgData on Organization {
    ...OrgMinData
    ownerUserIds
    # ownerUserIdsList
    accountHolder {
      name
      address {
        street
        city
        state
        postalCode
        country
      }
    }
    legalStructure
    taxId
    taxStatus
    category
    stateOfIncorporation
    businessPhoneNumber
    streetAddress
    city
    state
    zip
    session
    role
    accredited
    creditCheck
    cards {
      ...CardData
    }
    deals {
      id
      fundingAccountId
      committedAmount {
        display
        amount
      }
      commitments {
        dealInvestorId
        dealId
        # this "id" is the Deal commitment ID itself
        id
        numberOfShares
      }
    }
    oneLine {
      ...OneLineData
    }
    oneLineAvailableToBorrow {
      display
      amount
    }
  }
  ${organizationMinimalFragment}
  ${cardsFragment}
  ${oneLineFragment}
`;

export const businessInfoFragment = gql`
  fragment BusinessData on Organization {
    id
    ownerUserIds
    # ownerUserIdsList
    orgType
    name
    legalStructure
    taxId
    taxStatus
    category
    stateOfIncorporation
    businessPhoneNumber
    streetAddress
    city
    state
    zip
    session
    currentContext
  }
`;

export const businessOwnerFragment = gql`
  fragment OwnerData on Organization {
    beneficialOwners {
      id
      firstName
      lastName
      phone
      dob
      ownershipPercentage
      ssn
      email
      streetAddress
      city
      state
      zip
      title
      createdAt
      primaryContact
      primaryOfficer
    }
  }
`;

// User fragments
export const userFragment = gql`
  fragment UserData on User {
    id
    firstName
    lastName
    email
    phone
    DOB
    SSN
    inviteCode
    onboarded
    approved
    idVerifications {
      idVerificationName
      updatedAt
    }
    isThisMe
    hasDeposited
    streetAddress
    city
    state
    zip
    netWorth
    notifications {
      receivePush
      receiveSms
    }
    organizations {
      ...OrgMinData
    }
    partnershipType
    verified {
      wealth
      id
      mfa
      survey
      phone
      email
      face
      _userId
    }
  }
  ${organizationMinimalFragment}
`;

export const createdUserFragment = gql`
  fragment CreatedUserData on User {
    id
    firstName
    lastName
    email
    phone
    netWorth
    notifications {
      receivePush
      receiveSms
    }
  }
`;

export const tranxFragment = gql`
  fragment TranxData on Transaction {
    id
    amount {
      display
      amount
    }
    sourceId
    targetId
    sourceType
    targetType
    organizationId
    merchantDescription
    createdAt
  }
`;

export const fullAccountFragment = gql`
  fragment FullAccountData on Account {
    id
    totalBalance {
      display
      amount
    }
    availableBalance {
      amount
      display
    }
    cards {
      ...CardData
    }
    createdAt
    deposits {
      display
      amount
    }
    externalAccountNumber
    externalRoutingNumber
    last4
    nickname
    organizationDefault
    organizationId
    spent {
      display
      amount
    }
    statements {
      statementId
      month
      year
    }
    selected
    accountType
    owners {
      id
      isAccountOwner
      firstName
      lastName
      status
      email
    }
  }
  ${cardsFragment}
`;

export const bankAccountsFragment = gql`
  fragment FullBankAccountData on BankAccounts {
    accounts {
      balances(timeframe: $timeframe, groupBy: $groupBy) {
        period
        balance {
          amount
          display
        }
      }
      ...FullAccountData
    }
  }
  ${fullAccountFragment}
`;

export const accountActivityFragment = gql`
  fragment AccountActivityFragment on WeeklyActivity {
    activity {
      day
      spendAmount {
        amount
        display
      }
    }
    currentDay
  }
`;

export const accountTransactionFragment = gql`
  fragment FullTransactionAccountData on TransactionListWithPaging {
    items {
      id
      amount {
        amount
        display
      }
      transactionType
      sourceId
      targetId
      entityId
      transactionDate
      merchantDescription
      spendType
      entityType
      sourceType
      targetType
      organizationId
      createdAt
      status
    }
  }
`;

export const organizationTransactionFragment = gql`
  fragment FullTransactionData on TransactionListWithPaging {
    items {
      id
      amount {
        amount
        display
      }
      transactionType
      sourceId
      targetId
      entityId
      transactionDate
      merchantDescription
      spendType
      entityType
      sourceType
      targetType
      organizationId
      createdAt
      status
    }
  }
`;

export const connectedAccountFragment = gql`
  fragment FullConnectedAccountData on ConnectedAccount {
    id
    type
    subType
    organizationId
    userId
    availableBalance {
      display
      amount
    }
    totalBalance {
      display
      amount
    }
    balances(timeframe: $timeframe, groupBy: $groupBy) {
      period
      balance {
        amount
        display
      }
    }
    accountNumber
    routingNumber
    last4
    providerAccountId
    providerEnrollmentId
    connectedInstitutionName
    connectedInstitutionLogo
    connectedInstitutionId
    provider
    nickname
    name
    status
    updatedAt
    createdAt
    selected
  }
`;
