import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import { Button } from "src/components/button/Button";
import { StyledForm, StyledFormInputContainer } from "src/components/forms";
import { useHoFund } from "src/util/useHoFund";
import { useValidateResetPasswordToken } from "src/services/users";

import { ResetPasswordAuthContainer } from "./ResetPassword.styles";
import { AuthTitle, AuthPageWrapper } from "src/pages/auth/Auth.styles";
import { useLocation } from "react-router-dom";
import useNavigate from "src/util/useNavigate";
import { showNotice } from "src/store/alertState";
import { CypressTestIds } from "src/util/testing-util/test-utils";

import { NavRoutes } from "src/routes/navRoutes";
import { NewPasswordInput } from "src/components/forms/new-password-input/NewPasswordInput";
import { MINIMUM_PASSWORD_STRENGTH_SCORE } from "src";
import Layout from "src/components/core-layout/Layout";

type FormFields = { password: string };

const PasswordSchema: Yup.ObjectSchema<FormFields> = Yup.object().shape({
  password: Yup.string()
    .min(8, "Please use a longer password.")
    .max(100, "Please use a shorter password.")
    .required("Please add a password.")
});

const GET_RESPONSE_DATA = true;

type InputType = { password: string; token: string };
type ReturnType = { email: string };

const ResetPassword = (): JSX.Element => {
  const { validateToken, loading: validateLoading, error: validateError } = useValidateResetPasswordToken();
  const {
    action: resetPassword,
    loading: resetLoading,
    error: resetErorr
  } = useHoFund<ReturnType, InputType>(NavRoutes.RESET_PASSWORD, GET_RESPONSE_DATA);
  const [validToken, setValid] = useState(true);
  const [validationAttempt, setAttempt] = useState(false);
  const { search } = useLocation();
  const navigate = useNavigate();
  
  const [passwordScore, setPasswordScore] = useState(0);

  useEffect(() => {
    const handleTokenValidation = async (): Promise<void> => {
      setAttempt(true);
      const isValid = await validateToken(search?.split("=").pop() || "");
      setValid(isValid);
    };

    if (search && !validationAttempt) {
      handleTokenValidation();
    }
  }, [search, validateToken, validationAttempt]);

  useEffect(() => {
    if (resetErorr) {
      showNotice("There was a problem resetting your password.", { error: true });
    } else if (validateError || !validToken)
      showNotice("Your password reset link has expired, please try again.", { error: true });
  }, [resetErorr, validateError, validToken]);

  const handleResetPassword = async (values: FormFields): Promise<void> => {
    if (passwordScore < MINIMUM_PASSWORD_STRENGTH_SCORE) {
      showNotice("Please use a stronger password.", { error: true });
      return;
    }

    const data = await resetPassword({ password: values.password, token: search.split("=").pop() || "" });

    if (data) {
      navigate(NavRoutes.SIGN_IN);
      showNotice("Password reset successfully. Try logging in again.");
    } else {
      showNotice("There was a problem resetting your password.", { error: true });
    }
  };

  const updatePasswordScore = (data: number) => {
    setPasswordScore(data);
  };

  const initialFormValues: FormFields = {
    password: ""
  };

  return (
    <Layout>
      <AuthPageWrapper>
        <ResetPasswordAuthContainer id="authCont">
          <AuthTitle>Reset Password</AuthTitle>

          <Formik
            initialValues={initialFormValues}
            validationSchema={PasswordSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values, { resetForm }): void => {
              handleResetPassword(values);
              resetForm();
            }}
          >
            {({ isSubmitting }): JSX.Element => (
              <StyledForm>
                {validToken && (
                  <>
                    <StyledFormInputContainer>
                      <NewPasswordInput
                        name="password"
                        type="password"
                        label="New Password"
                        data-cy={CypressTestIds.NEW_PASSWORD}
                        autoComplete="new-password"
                        updateFormScore={updatePasswordScore}
                      />
                    </StyledFormInputContainer>

                    <Button
                      raised
                      type="submit"
                      disabled={!!validateError || !validToken}
                      loading={isSubmitting || resetLoading || validateLoading}
                      data-cy={CypressTestIds.SUBMIT_NEW_PASSWORD}
                    >
                      Reset Password
                    </Button>
                  </>
                )}
                {!validToken && (
                  <>
                    <br />
                    <Button
                      disabled={validToken}
                      loading={isSubmitting || resetLoading || validateLoading}
                      onClick={() => navigate(NavRoutes.SIGN_IN)}
                    >
                      Login
                    </Button>
                    <br />
                    <Button
                      raised
                      disabled={validToken}
                      loading={isSubmitting || resetLoading || validateLoading}
                      onClick={() => navigate(NavRoutes.FORGOT_PASSWORD)}
                    >
                      Forgot Password?
                    </Button>
                  </>
                )}
              </StyledForm>
            )}
          </Formik>
        </ResetPasswordAuthContainer>
      </AuthPageWrapper>
    </Layout>
  );
};

export default ResetPassword;
