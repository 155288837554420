import React, { useEffect, useMemo } from "react";
import { useFetcher } from "react-router-dom";
import useNavigate from "src/util/useNavigate";
import { showNotice } from "src/store/alertState";
import { NavRoutes } from "src/routes/navRoutes";
import { useSetBottomSheet, useShowBottomSheet } from "src/util/useBottomSheet";
import { AccreditedSheetTitle, AccreditedSheetDescription } from "./AccreditedInvestorSheet.styles";
import { CAModalAccountTypeButton } from "src/components/bottom-sheets/connect-account-sheet/ConnectAccountSheet.styles";
import { Button } from "src/components/button/Button";
import { ColorNames } from "src/theme/theme";
import { ReactComponent as IconConnect } from "src/assets/icons/connect.svg";
import { ReactComponent as IconEmail } from "src/assets/icons/email.svg";
import { GlobalSvgs } from "src/components/global-svgs/GlobalSvgs";
import { LogoSpinner } from "src/components/logo-spinner/LogoSpinner";

export interface AccreditedInvestorSheetProps {
  id: string;
  closeOnOutsideClick?: boolean;
}

export const AccreditedInvestorSheet = ({
  id,
  closeOnOutsideClick = true
}: AccreditedInvestorSheetProps): JSX.Element => {
  const { data, state: fetcherState, Form, ...fetcher } = useFetcher();

  const navigate = useNavigate();
  
  const setBottomSheet = useSetBottomSheet();
  const { hideBottomSheet } = useShowBottomSheet();

  const requestAccreditation = () => {
    if (fetcherState === "submitting") return;
    fetcher.submit({ _action: "requestAccreditation" }, { method: "post", action: NavRoutes.API_ORGANIZATION });
  };

  useEffect(() => {
    if (!!data?.error) {
      showNotice(data.error.message, { error: true });
    }
  }, [data?.error]);

  useEffect(() => {
    if (data?.response?.requestAccreditation) {
      hideBottomSheet();
      showNotice("Your accreditation request has been received. We'll reach out to you soon.");
    }
  }, [data?.response]);

  const bottomSheetChildren = useMemo(
    () => (
      <>
        <AccreditedSheetTitle>Accredited Investor</AccreditedSheetTitle>
        <AccreditedSheetDescription>
          To make investments and view certain deals on Letter you'll need to confirm you're an{" "}
          <a href="https://www.investopedia.com/terms/a/accreditedinvestor.asp" target="_blank">
            Accredited Investor
          </a>
          . There are two ways to do this on Letter:
        </AccreditedSheetDescription>
        <CAModalAccountTypeButton
          onClick={() => {
            hideBottomSheet();
            setTimeout(() => {
              navigate(NavRoutes.BANK_CONNECTED_ACCOUNTS);
            }, 0);
          }}
        >
          <div className="body-text-area">
            <h4>Connect Wealth</h4>
            <p>Deposit and/or connect $1MM to your Letter account.</p>
          </div>
          <div className="icon-area traditional">
            <IconConnect />
          </div>
        </CAModalAccountTypeButton>
        <CAModalAccountTypeButton onClick={requestAccreditation}>
          <div className="body-text-area">
            <h4>Manual Review</h4>
            <p>Contact with us for a manual review and accreditation.</p>
          </div>
          <div className="icon-area crypto">
            {fetcherState !== "submitting" ? (
              <IconEmail />
            ) : (
              <>
                <GlobalSvgs />
                <LogoSpinner color={ColorNames.WHITE} />
              </>
            )}
          </div>
        </CAModalAccountTypeButton>
        <Button color={ColorNames.TRANSPARENT} children={<span>Close</span>} onClick={() => hideBottomSheet()} />
      </>
    ),
    [hideBottomSheet, navigate]
  );

  useEffect(() => {
    setBottomSheet({ id, closeOnOutsideClick, children: bottomSheetChildren, backgroundColor: ColorNames.GRAY1 });
  }, [bottomSheetChildren, id, setBottomSheet, closeOnOutsideClick]);

  return <></>;
};
