import React, { useEffect, useState, useMemo } from "react";
import useMeasure from "react-use-measure";
import classnames from "classnames";
import { useRecoilValue } from "recoil";
import { User } from "src/generated/client";
import { detailPickerState, IDetailPickerState } from "src/store/detailPickerState";
import { useSetDetailPicker, useShowDetailPicker } from "src/util/useDetailPicker";
import { ColorNames } from "src/theme/theme";

import {
  DetailSelectContainer,
  DetailSelectHeader,
  IconExpand,
  DetailSelectRow,
  DetailPickerOptionCheckmarkHolder,
  DetailPickerOptionCheckmark,
  DetailSelectEmptyState
} from "src/components/forms/detail-select/DetailSelect.styles";
import { DetailPickerOptionButton } from "src/components/detail-picker/DetailPicker.styles";
import { Avatar } from "src/components/avatar/Avatar";

export interface DetailSelectContactProps {
  pickerId: string;
  fields: Record<string, string>;
  defaultUserId?: string;
  label: string;
  error?: boolean;
  placeholder: string;
  users: Partial<User>[];
  actions?: JSX.Element;
  search?: boolean;
  handleSearch?: (e: { target: { value: React.SetStateAction<string> } }) => void;
  searchTerm?: string;
}

export const DetailSelectUser = (detailSelectProps: DetailSelectContactProps): JSX.Element => {
  const { pickerId, fields, defaultUserId, label, error, placeholder, users, actions, search } = detailSelectProps;
  const [searchTerm, setSearchTerm] = useState("");
  // const [selectedOption, setSelectedOption] = useState<User | null>(null);
  const [ref, bounds] = useMeasure();

  const [selectedUser, setSelectedUser] = useState<Partial<User>>();
  const [hasError, setHasError] = useState(error);
  
  const detailPickers = useRecoilValue<IDetailPickerState>(detailPickerState);
  const setDetailPicker = useSetDetailPicker();
  const { showDetailPicker, hideDetailPicker } = useShowDetailPicker();
  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    const user = users.find((u) => u.id === defaultUserId);
    setSelectedUser(user);
  }, [defaultUserId, users]);

  // useEffect(() => {
  //   const matches = users.filter((d) => d.id === selectedUser?.id);
  //   setSelectedOption(matches.length > 0 ? matches[0] : null);
  // }, [selectedUser, users]);

  const didSetValue = (newUser: Partial<User>) => {
    setSelectedUser(newUser);
    setHasError(false);
    setTimeout(() => {
      // clearing after a delay to prevent jank when closing
      setSearchTerm("");
    }, 300);
  };

  const openDetailPicker = () => {
    showDetailPicker(pickerId);
  };

  const renderOptionsContent = useMemo((): JSX.Element => {
    if (users.length > 0) {
      return (
        <>
          {users
            .filter((user) => user.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) || user.lastName?.toLowerCase().includes(searchTerm.toLowerCase()) || user.email?.toLowerCase().includes(searchTerm.toLowerCase()))
            .map((user) => {
              const label = !!user.firstName && !!user.lastName ? `${user.firstName} ${user.lastName}` : user.email;
              return (
                <DetailPickerOptionButton
                  key={user.id}
                  onClick={() => {
                    didSetValue(user);
                    setTimeout(() => {
                      hideDetailPicker(pickerId);
                    }, 50);
                  }}
                >
                  <div className="detail-picker-option-button-with-avatar">
                    <Avatar name={label} size={28} />
                    <div>
                      <div>{label}</div>
                      <div className="secondary-label"></div>
                    </div>
                  </div>
                  {user.id === selectedUser?.id && (
                    <DetailPickerOptionCheckmarkHolder>
                      <DetailPickerOptionCheckmark />
                    </DetailPickerOptionCheckmarkHolder>
                  )}
                </DetailPickerOptionButton>
              )
            })}
        </>
      );
    } else {
      return <DetailSelectEmptyState>You don&rsquo;t have any contacts.</DetailSelectEmptyState>;
    }
  }, [users, searchTerm, selectedUser, hideDetailPicker, pickerId]);

  useEffect(() => {
    setIsShowing(detailPickers.showIds.some((id) => id === pickerId));
  }, [detailPickers, pickerId]);

  useEffect(() => {
    const handleSearch = (e: { target: { value: React.SetStateAction<string> } }) => {
      setSearchTerm(e.target.value);
    };

    setDetailPicker({
      id: pickerId,
      origin: bounds,
      displayLabel: label,
      color: ColorNames.POWDER,
      hasValue: !!selectedUser,
      search,
      handleSearch,
      searchTerm,
      actions,
      options: renderOptionsContent
    });
  }, [
    bounds,
    isShowing,
    showDetailPicker,
    hideDetailPicker,
    label,
    selectedUser,
    search,
    searchTerm,
    actions,
    renderOptionsContent,
    pickerId,
    setDetailPicker
  ]);

  return (
    <>
      <DetailSelectContainer ref={ref} onClick={openDetailPicker} className={classnames({ open: isShowing })}>
        <DetailSelectHeader>
          {label}
          <IconExpand />
        </DetailSelectHeader>
        <DetailSelectRow
          error={hasError}
          className={classnames({ empty: !selectedUser })}
          initial={false}
          animate={{
            opacity: isShowing ? 0 : 1
          }}
          transition={{
            duration: 0.15,
            delay: isShowing ? 0 : 0.25
          }}
        >
          {!!selectedUser ? (
            <div className="contact">
              <Avatar name={!!selectedUser.firstName && !!selectedUser.lastName ? `${selectedUser.firstName} ${selectedUser.lastName}` : selectedUser.email} size={24} />
              <div className="label">{!!selectedUser.firstName && !!selectedUser.lastName ? `${selectedUser.firstName} ${selectedUser.lastName}` : selectedUser.email}</div>
              
              {Object.keys(fields).map((key) => {
                return <input type="hidden" key={key} name={key} value={(selectedUser as any)[fields[key]]} />
              })}
            </div>
          ) : (
            placeholder
          )}
        </DetailSelectRow>
      </DetailSelectContainer>
    </>
  );
};
