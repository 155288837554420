export enum UserIdVerificationType {
  DrivingLicense = "DrivingLicense",
  Passport = "Passport",
  DrivingLicenseFront = "DrivingLicenseFront",
  DrivingLicenseBack = "DrivingLicenseBack",
  AddressVerification = "AddressVerification",
  SocialSecurityCard = "SocialSecurityCard",
  CertificateOfIncorporation = "CertificateOfIncorporation",
  EinConfirmation = "EinConfirmation",
  FacePhoto = "FacePhoto",
}