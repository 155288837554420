import styled from "styled-components";
import { colorPalette as colors, ColorNames } from "src/theme/theme";
import { BasicAuthContainer } from "src/pages/auth/Auth.styles";

export const ResetPasswordPage = styled.div`
  grid-column: 1 / -1;
  height: 100%;
  background-color: ${colors[ColorNames.GRAY1].hex};
`;

export const ResetPasswordPageWrapper = styled.div`
  height: 100%;
  max-width: 570px;
  margin: 0 auto;
  padding-top: 16vh;
`;

export const ResetPasswordAuthContainer = styled(BasicAuthContainer)`
  height: 100%;

  fieldset {
    margin-top: 8vh;
  }
`;
