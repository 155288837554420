import { ActionFunction } from "react-router-dom";
import * as Yup from "yup";

import { loginUser } from "src/services/auth";

import { getFormValidationErrors } from "src/util/forms";
import { RouteActionResponse } from "src";

export const signInAction: ActionFunction = async ({ request }) => {
  const data = Object.fromEntries(await request.formData());

  const schema: Yup.ObjectSchema<object> = Yup.object().shape({
    email: Yup.string().email("Please use a valid email address.").required("Please enter an email address."),
    password: Yup.string().required("Please enter a valid password.")
  });

  try {
    await schema.validate(data, { abortEarly: false });
    const response = await loginUser({
      email: data.email as string,
      password: data.password as string,
      recaptchaScore: parseFloat(data.recaptchaScore as string),
      inviteCode: (!!data.inviteCode && (data.inviteCode as string)) || undefined
    });
    return { response };
  } catch (error) {
    const response: RouteActionResponse<unknown> = {};
    if (error instanceof Yup.ValidationError) {
      response.validationErrors = getFormValidationErrors(error);
    } else {
      response.error = error;
    }

    return response;
  }
};
