import React, { useEffect, useState, useRef } from "react";
import { ulid } from "ulid";
import { useFetcher, useParams, useSearchParams } from "react-router-dom";

import { showNotice } from "src/store/alertState";
import { useNavbar } from "src/util/useNavbar";
import { NavRoutes } from "src/routes/navRoutes";
import { ColorNames } from "src/theme/theme";
import useNavigate from "src/util/useNavigate";
import { TabRouter, TabView, useSubnav } from "src/util/useSubnav";
import { pluralize, formatDateRange } from "src/util/stringUtils";
import { OneLineAuctionNotificationType, CheckStatus } from "src/types/line";
import { UpcomingAuction } from "src/generated/client";

import { AuthPageWrapper, BasicAuthContainer } from "src/pages/auth/Auth.styles";

import { Button } from "src/components/button/Button";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import Layout from "src/components/core-layout/Layout";
import { LabelInput } from "src/components/forms/input/LabelInput";
import { BannerNotice } from "src/components/alert/BannerNotice";
import { ReactComponent as IconClose } from "src/assets/icons/close.svg";
import { ReactComponent as CheckMark } from "src/assets/icons/check.svg";
import { ReactComponent as Clock } from "src/assets/icons/clock.svg";
import { ListButton } from "src/components/lists/ListButton";
import { CurrencyInput } from "src/components/forms/currency-input/CurrencyInput";

type Purchase = {
  horseVin: string;
  amount: string;
  description: string;
};

type Items = Record<string, Purchase>;

const auctionHouses = {
  "western-bloodstock": "Western Bloodstock",
  "triangle-sales": "Triangle Sales"
};

const AuctionSales = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { data: upcomingAuctions, state: loadingState, load } = useFetcher<UpcomingAuction[]>();

  const SUBNAV_TABS = ["add purchases", "close out"];

  const { selected } = useSubnav(SUBNAV_TABS, {
    selectedTab: 0
  });

  const { updateNavbar } = useNavbar();

  useEffect(() => {
    const { auctionHouse } = params;
    const titleAuctionHouse = auctionHouses[(auctionHouse ?? "") as keyof typeof auctionHouses];

    if (!titleAuctionHouse) {
      return navigate(NavRoutes.LANDING);
    }
    updateNavbar({ title: `Auction Sales - ${titleAuctionHouse}`, orgSwitcher: false, menu: false });
  }, [navigate, params, updateNavbar]);

  useEffect(() => {
    if (!upcomingAuctions && loadingState === "idle") {
      load(`${NavRoutes.API_ONELINE_AUCTION}?includePast=true`);
    }
  }, [upcomingAuctions, loadingState, load]);

  return (
    <Layout>
      <AuthPageWrapper>
        <BasicAuthContainer>
          <TabRouter>
            <TabView key={0} show={selected === 0}>
              <AddPurchase upcomingAuctions={upcomingAuctions || []} />
            </TabView>
            <TabView key={1} show={selected === 1}>
              <CloseOutCustomer upcomingAuctions={upcomingAuctions || []} />
            </TabView>
          </TabRouter>
        </BasicAuthContainer>
      </AuthPageWrapper>
    </Layout>
  );
};

const AddPurchase = ({ upcomingAuctions }: { upcomingAuctions: UpcomingAuction[] }) => {
  const [queryParams] = useSearchParams();
  const { data, state: fetcherState, Form, ...fetcher } = useFetcher();
  const [token, setToken] = useState<string | null>(null);
  const defaultItem = { [ulid()]: { horseVin: "", amount: "", description: "" } };
  const [items, setItem] = useState<Items | undefined>(defaultItem);
  const [letterId, setLetterId] = useState("");
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const [selectedAuctionDate, setSelectedAuctionDate] = useState<UpcomingAuction["date"]>();

  useEffect(() => {
    if (!!data?.validationErrors && Object.keys(data.validationErrors).length > 0) {
      const firstError = Object.values(data.validationErrors)[0] as string;
      showNotice(firstError, { error: true });
    }
  }, [data?.validationErrors]);

  useEffect(() => {
    const _token = queryParams.get("token");
    if (!_token) return navigate(NavRoutes.LANDING);
    setToken(_token);
  }, [queryParams]);

  useEffect(() => {
    if (!data?.response?.success && data?.response?.error) {
      const { error } = data.response;
      showNotice(error, { error: true });
    } else if (data?.response?.success) {
      showNotice("Successfully submitted!");
      onReset();
    }
  }, [data]);

  const onSubmit = () => {
    if (!items || !token || fetcherState === "submitting") {
      return;
    }

    const data = {
      letterId,
      token,
      purchases: Object.values(items),
      type: OneLineAuctionNotificationType.Collateral,
      auctionDate: selectedAuctionDate
    };

    fetcher.submit({ data: JSON.stringify(data) }, { method: "post", action: NavRoutes.API_ONELINE_AUCTION });
  };

  const onAddRow = () => {
    setItem((items) => ({
      ...items,
      ...defaultItem
    }));
  };

  const onReset = () => {
    if (!formRef.current) {
      return;
    }

    setItem(defaultItem);
    setLetterId("");
    formRef.current.reset();
  };

  const onChangeHandler =
    (key: keyof Purchase, id: string) => (event: React.ChangeEvent<HTMLInputElement> | string) => {
      setItem((items) => {
        if (!items) {
          return;
        }

        const value = typeof event === "string" ? event : event.target.value;

        items[id] = { ...items[id], [key]: value };

        return items;
      });
    };

  const onDeleteRow = (id: string) => {
    setItem((items) => {
      if (!items) return;

      const { [id]: itemToRemove, ...rest } = items;

      return rest;
    });
  };

  return (
    <>
      <p style={{ textAlign: "center" }}>
        To record a customer purchase, use the form below and enter the horse(s) the customer purchased.
      </p>
      <Form ref={formRef}>
        <fieldset style={{ display: "flex", flexDirection: "column", columnGap: "16px" }}>
          <div
            style={{
              display: "flex",
              alignSelf: "center",
              width: "75%",
              marginBottom: "36px",
              flexDirection: "column"
            }}
          >
            <div style={{ marginBottom: "24px" }}>
              {upcomingAuctions.map((upcomingAuction) => (
                <ListButton
                  key={upcomingAuction.description}
                  label={upcomingAuction.description || ""}
                  sublabel={formatDateRange(upcomingAuction.date)}
                  onClick={() => {
                    if (upcomingAuction.date !== selectedAuctionDate) {
                      return setSelectedAuctionDate(upcomingAuction.date);
                    }
                    setSelectedAuctionDate(undefined);
                  }}
                  selectable={true}
                  isSelected={upcomingAuction.date === selectedAuctionDate}
                />
              ))}
            </div>
            <LabelInput
              name="letterId"
              type="text"
              label="Letter Customer ID"
              onChange={(e: any) => setLetterId(e.target.value)}
            />
          </div>
          <div>
            {items &&
              Object.keys(items).map((id, index) => (
                <div
                  key={id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "12px",
                    marginBottom: "12px",
                    marginRight: "-32px"
                  }}
                >
                  <LabelInput
                    value={items[id].horseVin}
                    name="horseVin"
                    type="text"
                    label="Horse ID"
                    onChange={onChangeHandler("horseVin", id)}
                  />
                  <CurrencyInput
                    name="amount"
                    type="text"
                    label="Sale Amount"
                    defaultValue={items[id].amount}
                    onChange={onChangeHandler("amount", id)}
                  />
                  <LabelInput
                    value={items[id].description}
                    name="description"
                    type="text"
                    label="Horse Name"
                    onChange={onChangeHandler("description", id)}
                  />
                  <IconClose
                    style={{ flex: "0 0 auto", cursor: "pointer" }}
                    color={index === 0 ? ColorNames.WHITE : undefined}
                    onClick={index === 0 ? undefined : () => onDeleteRow(id)}
                  />
                </div>
              ))}
          </div>
          <ActionableButton iconRight={false} label="Add Another Horse" onClick={onAddRow} color={ColorNames.GRAY2} />
        </fieldset>
        <Button raised color={ColorNames.LAKE} loading={fetcherState === "submitting"} onClick={onSubmit}>
          Submit Purchases
        </Button>
      </Form>
    </>
  );
};

type Customer = {
  totalFundsToBePulled: string;
  totalPurchasesAmount: string;
  horsesPurchased: string[];
  name: string;
  address: string;
  fundStatus: CheckStatus;
  purchaseStatus: CheckStatus;
};

const CloseOutCustomer = ({ upcomingAuctions }: { upcomingAuctions: UpcomingAuction[] }) => {
  const [queryParams] = useSearchParams();
  const { data, state: fetcherState, Form, ...fetcher } = useFetcher();
  const [customer, setCustomer] = useState<Customer>();
  const [letterId, setLetterId] = useState("");
  const [token, setToken] = useState<string>();
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const [selectedAuctionDate, setSelectedAuctionDate] = useState<UpcomingAuction["date"]>();

  const inTransitText = "This customer has been closed out. The money transfer has been initiated and is in transit.";
  const closedAndTransferredText = "This customer has been closed out and the money has been transferred.";

  useEffect(() => {
    if (!!data?.validationErrors && Object.keys(data.validationErrors).length > 0) {
      const firstError = Object.values(data.validationErrors)[0] as string;
      showNotice(firstError, { error: true });
    }
  }, [data?.validationErrors]);

  useEffect(() => {
    const token = queryParams.get("token");

    if (!token) {
      return navigate(NavRoutes.LANDING);
    }

    setToken(token);
  }, [queryParams]);

  const fetchCustomer = () => {
    if (!token || fetcherState === "submitting") {
      return;
    }

    const data = { letterId, type: OneLineAuctionNotificationType.Retrieve, token, auctionDate: selectedAuctionDate };
    fetcher.submit({ data: JSON.stringify(data) }, { method: "post", action: NavRoutes.API_ONELINE_AUCTION });
  };

  const onSubmit = () => {
    if (!token || fetcherState === "submitting") {
      return;
    }

    const data = {
      letterId,
      type: OneLineAuctionNotificationType.PurchaseComplete,
      token,
      auctionDate: selectedAuctionDate
    };
    fetcher.submit({ data: JSON.stringify(data) }, { method: "post", action: NavRoutes.API_ONELINE_AUCTION });
  };

  const detailStyle = {
    gridColumn: 2,
    textAlign: "end" as const,
    color: "black",
    fontWeight: 500
  };

  useEffect(() => {
    if (!data?.response?.success && data?.response?.error) {
      const { error } = data.response;
      showNotice(error, { error: true });
    } else if (data?.response?.success) {
      const d = data.response.data;
      if (d && d.auction) {
        setCustomer(d.auction);
      } else {
        formRef.current?.reset();
        setCustomer(undefined);
        return showNotice("Customer successfully closed out.");
      }
    }

    if (!data || data?.validationErrors) {
      return;
    }
  }, [data]);

  return (
    <>
      <p style={{ textAlign: "center" }}>
        Use this form to close out a customer, finalize their purchases, and pull their funds.
      </p>
      <Form ref={formRef}>
        <fieldset style={{ display: "flex", flexDirection: "column", columnGap: "16px" }}>
          <div
            style={{
              display: "flex",
              alignSelf: "center",
              width: "75%",
              marginBottom: "36px",
              flexDirection: "column"
            }}
          >
            <div style={{ marginBottom: "24px" }}>
              {upcomingAuctions.map((upcomingAuction) => (
                <ListButton
                  key={upcomingAuction.description}
                  label={upcomingAuction.description || ""}
                  sublabel={formatDateRange(upcomingAuction.date)}
                  onClick={() => {
                    if (upcomingAuction.date !== selectedAuctionDate) {
                      return setSelectedAuctionDate(upcomingAuction.date);
                    }
                    setSelectedAuctionDate(undefined);
                  }}
                  selectable={true}
                  isSelected={upcomingAuction.date === selectedAuctionDate}
                />
              ))}
            </div>
            <LabelInput
              name="letterId"
              type="text"
              label="Letter Customer ID"
              onChange={(e: any) => setLetterId(e.target.value)}
            />
          </div>

          <div style={{ marginRight: "20px" }}>
            <ActionableButton
              iconRight={false}
              label="Find Customer"
              onClick={fetchCustomer}
              color={ColorNames.GRAY2}
            />
          </div>
        </fieldset>

        {customer ? (
          <>
            <dl
              style={{
                display: "grid",
                rowGap: "20px",
                gridTemplateColumns: "33% auto",
                color: "grey",
                marginRight: "20px",
                marginBottom: "40px"
              }}
            >
              <dt>{pluralize(customer.horsesPurchased.length, "Horse")} Purchased</dt>
              <dd style={detailStyle}>
                {customer.horsesPurchased.map((vin) => (
                  <div key={vin}>{vin}</div>
                ))}
              </dd>
              <dt>Purchase Total</dt>
              <dd style={detailStyle}>${customer.totalPurchasesAmount}</dd>
              <dt>Funds to be Pulled</dt>
              <dd style={detailStyle}>${customer.totalFundsToBePulled}</dd>

              <dt>Name</dt>
              <dd style={detailStyle}>{customer.name}</dd>
              <dt>Address</dt>
              <dd style={detailStyle}>{customer.address}</dd>
            </dl>
            {customer.purchaseStatus === CheckStatus.Passed ? (
              <div style={{ marginRight: "20px" }}>
                <BannerNotice
                  backgroundColor={customer.fundStatus === CheckStatus.Passed ? ColorNames.MINT : ColorNames.NOTE}
                  iconLeft={customer.fundStatus === CheckStatus.Passed ? <CheckMark /> : <Clock />}
                  textColor={customer.fundStatus === CheckStatus.Passed ? ColorNames.PINE : ColorNames.CINNAMON}
                  iconColor={customer.fundStatus === CheckStatus.Passed ? ColorNames.GRASS : ColorNames.SUNSHINE}
                  style={{ marginTop: "auto" }}
                >
                  {customer.fundStatus === CheckStatus.Passed ? closedAndTransferredText : inTransitText}
                </BannerNotice>
              </div>
            ) : (
              <div style={{ marginRight: "20px" }}>
                <Button raised color={ColorNames.LAKE} loading={fetcherState === "submitting"} onClick={onSubmit}>
                  Close Out Customer
                </Button>
              </div>
            )}
          </>
        ) : undefined}
      </Form>
    </>
  );
};

export default AuctionSales;
