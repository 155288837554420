import styled from "styled-components";
import { colorPalette as colors, ColorNames } from "src/theme/theme";

export const NewPasswordInputElements = styled.div`
  position: relative;
`;

export const StrengthIndicator = styled.div`
  width: 3px;
  height: 100%;
  position: absolute;
  left: -10px;
  top: 0;
  transform: scaleY(1.25);
  border-radius: 0.125rem;
  background-color: ${colors[ColorNames.GRAY2].hex};
  transition: background-color 0.2s ease;

  &.bad {
    background-color: ${colors[ColorNames.CARDINAL].hex};
  }

  &.okay {
    background-color: ${colors[ColorNames.MELLOW].hex};
  }

  &.best {
    background-color: ${colors[ColorNames.GRASS].hex};
  }
`;
