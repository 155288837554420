import React from "react";

import { useNavbar } from "src/util/useNavbar";
import useNavigate from "src/util/useNavigate";
import { NavRoutes } from "src/routes/navRoutes";
import { NavigationDirection } from "src/store/navigationState";

import { AuthTitle, AuthCaption } from "src/pages/auth/Auth.styles";
import { StyledLandingPage } from "../Landing.styles";

import Layout from "src/components/core-layout/Layout";


const TetonRidgeAuctionsLate = () => {
  const navigate = useNavigate();

  useNavbar({
    backAction: () => navigate(NavRoutes.TETON_RIDGE_AUCTIONS, { direction: NavigationDirection.BACKWARD }),
    orgSwitcher: false
  });

  return <Layout>
    <StyledLandingPage>
      <div>
        <AuthTitle children="Sign Up Expired" />
        <AuthCaption>The sign up cut off date for this auction has already passed.</AuthCaption>
        <AuthCaption gray>Sorry, but it is too late to sign up for lending for this auction. Please check back at least a day before the next auction to apply for lending.</AuthCaption>
      </div>
    </StyledLandingPage>
  </Layout>;
}

export default TetonRidgeAuctionsLate;