import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { motion } from "framer-motion";
import { colorPalette as colors, ColorNames, ThemeColor, fonts, borderRadiusOuter } from "src/theme/theme";
import { ActionIconContainer } from "src/components/nav-bars/NavBars.styles";

export const BottomSheet = styled(motion.div)<{
  sheetIndex: number;
  sheetsTotal: number;
  backgroundColor?: ThemeColor;
}>`
  position: fixed;
  left: 0;
  bottom: 0;
  overflow: auto;
  background-color: ${({ backgroundColor }): string =>
    !!backgroundColor ? colors[backgroundColor].hex : colors[ColorNames.WHITE].hex};
  width: 100vw;
  padding: 35px 30px;
  border-top-left-radius: 40px;
  z-index: var(--zIndex-bottomSheet);
  transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out, min-height 0.3s ease-out, padding 0.3s ease-out,
    border-radius 0.6s ease-in-out;
  max-height: calc(100vh - 20px);
  min-height: 0px;
  @supports (-webkit-touch-callout: none) {
    max-height: -webkit-fill-available;
  }
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  header {
    height: 90px;

    ${ActionIconContainer} {
      margin-left: 0;
    }
  }

  &.has-header {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  @media (min-width: 768px) {
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
    right: 0;
    border-top-right-radius: 40px;
    z-index: var(--zIndex-bottomSheet);

    &.has-header {
      border-top-right-radius: 12px;
    }
  }

  &.inline {
    position: relative;
    background-color: rgba(${colors[ColorNames.GRAY1].rgb}, 0.85);
    overflow-y: hidden;
  }

  &.no-padding {
    padding: 0;
  }

  &.full-height {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    @media (min-width: 900px) {
      height: auto;
    }
    &[data-sheetid="connect_account_sheet"] {
      border-top-left-radius: ${borderRadiusOuter};
      border-top-right-radius: ${borderRadiusOuter};
    }
  }

  ${({ sheetIndex, sheetsTotal }): FlattenSimpleInterpolation | undefined => {
    return css`
      pointer-events: ${sheetIndex < sheetsTotal ? "none" : "auto"};
      left: ${(sheetIndex - sheetsTotal) * -20}px;
      max-height: calc(100vh - ${sheetIndex * 20}px);
      @supports (-webkit-touch-callout: none) {
        max-height: -webkit-fill-available;
      }
      opacity: ${1 - (sheetsTotal - sheetIndex) * 0.3};
      filter: blur(${(sheetsTotal - sheetIndex) * 3}px);
    `;
  }}
`;

export const Overlay = styled.div<{ show: boolean }>`
  pointer-events: none;
  position: fixed;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--zIndex-overlay);
  background: rgba(${colors[ColorNames.GRAY4].rgb}, 0.6);
  backdrop-filter: blur(0);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease-out, visibility 0.4s ease-out;
  will-change: opacity;

  ${({ show }): FlattenSimpleInterpolation | undefined => {
    if (show) {
      return css`
        visibility: visible;
        pointer-events: auto;
        opacity: 1;
        backdrop-filter: blur(8px);
      `;
    }
  }}
`;

export const SheetContainer = styled.div`
  padding: 35px 30px;
`;

export const SheetTitle = styled.p`
  width: 100%;
  ${fonts.HEADING_4}
  color: ${colors[ColorNames.MIDNIGHT].hex};
  margin-bottom: 32px;
  text-align: center;
`;

export const SheetDescription = styled.p`
  width: 100%;
  ${fonts.PARAGRAPH_M}
  color: ${colors[ColorNames.MIDNIGHT].hex};
  margin-bottom: 48px;
  white-space: pre-wrap;
  text-align: center;
`;
