import { ColorNames } from "src/theme/theme";

export const CardStatusDisplayMap = {
  Active: "Active",
  Inactive: "Inactive",
  Blocked: "Blocked",
  Lost: "Lost",
  Stolen: "Stolen",
  Frozen: "Frozen",
  ClosedByCustomer: "Closed",
  SuspectedFraud: "Fraud",
};

export interface BackgroundInfo {
  backgroundFileName: string;
  displayName: string;
  textColor: ColorNames.MIDNIGHT | ColorNames.WHITE;
}

interface BackgroundMap {
  [id: string]: BackgroundInfo;
}

export const cardBackgroundMap: BackgroundMap = {
  honey: {
    backgroundFileName: "honey.png",
    displayName: "Honey",
    textColor: ColorNames.MIDNIGHT,
  },
  blast: {
    backgroundFileName: "blast.png",
    displayName: "Blast",
    textColor: ColorNames.MIDNIGHT,
  },
  jelly: {
    backgroundFileName: "jelly.png",
    displayName: "Jelly",
    textColor: ColorNames.WHITE,
  },
  spritz: {
    backgroundFileName: "spritz.png",
    displayName: "Spritz",
    textColor: ColorNames.MIDNIGHT,
  },
  ponderosa: {
    backgroundFileName: "ponderosa.png",
    displayName: "Ponderosa",
    textColor: ColorNames.MIDNIGHT,
  },
  watercolor: {
    backgroundFileName: "watercolor.png",
    displayName: "Watercolor",
    textColor: ColorNames.MIDNIGHT,
  },
  rosa: {
    backgroundFileName: "rosa.png",
    displayName: "Rosa",
    textColor: ColorNames.MIDNIGHT,
  },
  sky: {
    backgroundFileName: "sky.png",
    displayName: "Sky",
    textColor: ColorNames.WHITE,
  },
};

export const physicalCardBackgroundMap: BackgroundMap = {
  physical: {
    backgroundFileName: "physical.png",
    displayName: "Physical",
    textColor: ColorNames.MIDNIGHT,
  },
  physicalEarly: {
    backgroundFileName: "physical-early.png",
    displayName: "Physical Early",
    textColor: ColorNames.MIDNIGHT,
  },
};

export const defaultCardBackgroundInfo: BackgroundInfo = {
  backgroundFileName: "default.png",
  displayName: "Default",
  textColor: ColorNames.MIDNIGHT,
};
