import { useCallback } from "react";
import { useCookies } from "react-cookie";

export enum storedOnboardingLegalDataKey {
  TERMS = "letter_onboard_account_terms",
  BANKING_AGREEMENT = "letter_onboard_banking_agreement",
  PRIVACY_POLICY = "letter_onboard_privacy_policy",
  ELECTRONIC_DISCLOSURE = "letter_onboard_electronic_disclosure"
}

export enum storedUserDataKey {
  SESSION = "letter_session",
  TIMERANGE = "letter_timerange"
}

export enum storedCookieDataKey {
  INVITE_CODE_PREFIX = "invite",
  ACCEPTED_INVITE_CODE_PREFIX = "invite_accepted",
  REFERRAL_CODE_PREFIX = "rc",
  PARTNERSHIP_PREFIX = "partner",
  CREDIT_CHECK_FAILED_PREFIX = "credit_check_failed",
  TETON_RIDGE_AUCTION_START_DATE = "tr_auction_start",
  TETON_RIDGE_AUCTION_END_DATE = "tr_auction_end",
  TETON_RIDGE_AUCTION_LOCATION = "tr_auction_location",
}

const cookiesToAlwaysPersist = [
  storedCookieDataKey.PARTNERSHIP_PREFIX,
  storedCookieDataKey.CREDIT_CHECK_FAILED_PREFIX,
  storedCookieDataKey.TETON_RIDGE_AUCTION_START_DATE,
  storedCookieDataKey.TETON_RIDGE_AUCTION_END_DATE,
  storedCookieDataKey.TETON_RIDGE_AUCTION_LOCATION
]

export function clearOnboardingTermsProgress() {
  Object.values(storedOnboardingLegalDataKey).forEach((docKey) => {
    localStorage.removeItem(docKey);
  });
}

export function useWipeStorage() {
  const [, , removeCookie] = useCookies();

  const wipeAllCookiesAndStorage = useCallback(
    ({ persistCookies }: { persistCookies?: boolean }) => {
      if (!persistCookies) {
        Object.values(storedCookieDataKey).forEach((key) => {
          if (!cookiesToAlwaysPersist.includes(key))
            removeCookie(key);
        });
      }

      Object.values({
        ...storedUserDataKey,
        ...storedOnboardingLegalDataKey
      }).forEach((key) => {
        localStorage.removeItem(key);
      });
    },
    [removeCookie]
  );

  return wipeAllCookiesAndStorage;
}
