import React, { useState, useEffect } from "react";
import classnames from "classnames";
import useNavigate from "src/util/useNavigate";
import { useParams, useSearchParams, useFetcher } from "react-router-dom";

import { useNavbar } from "src/util/useNavbar";
import { useOrganizationUsers } from "src/services/organizations";
import { showNotice } from "src/store/alertState";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { useCurrentOrg } from "src/services/organizations";

import { ColorNames } from "src/theme/theme";
import { NavigationDirection } from "src/store/navigationState";
import { cardBackgroundMap } from "src/types/card";
import { NavRoutes } from "src/routes/navRoutes";
import { createdCardVar } from "src/store/cardsState";

import { AppPage, AppContentContainer } from "src/routes/app/App.styles";
import { CardsPageContent } from "./Cards.styles";

import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import { Button } from "src/components/button/Button";
import { CurrencyInput } from "src/components/forms/currency-input/CurrencyInput";
import { Select } from "src/components/forms/select/Select";
import {
  ImageSelect,
  ImageSelectOptions,
} from "src/components/forms/select/ImageSelect";
import { Toggle } from "src/components/toggle/Toggle";
import { DetailSelectUser } from "src/components/forms/detail-select/DetailSelectUser";
import {
  StyledFormInputContainer,
  StyledFormCollapsableGroup,
} from "src/components/forms";
import Layout from "src/components/core-layout/Layout";
import ErrorElement from "src/ErrorElement";
import {
  Card,
  CardLimitBehaviourType,
  Account,
  CardType,
  Role,
  OrgType,
} from "src/generated/client";

const cardRoles = [
  {
    label: "Use Only",
    value: Role.Readonly,
  },
  {
    label: "Use & Manage",
    value: Role.Member,
  },
];

const cardTypes = [
  {
    label: "Virtual",
    value: CardType.Virtual,
  },
  {
    label: "Physical",
    value: CardType.Physical,
  },
];

const limitBehaviors = [
  {
    label: "Monthly Limit",
    value: CardLimitBehaviourType.Monthly,
  },
  {
    label: "One-time Balance",
    value: CardLimitBehaviourType.Refill,
  },
];

const artworkOptions: ImageSelectOptions = Object.keys(
  cardBackgroundMap
).reduce((current: ImageSelectOptions, key: string): ImageSelectOptions => {
  current[key] = {
    label: cardBackgroundMap[key].displayName,
    fileName: `/card-backgrounds/${cardBackgroundMap[key].backgroundFileName}`,
  };
  return current;
}, {});

export const CreateCardErrorElement = () => {
  return <ErrorElement redirect={NavRoutes.BANK_ACCOUNTS} alert={true} />;
};

const CreateCard = (): JSX.Element => {
  const { accountId } = useParams();
  const { data: account, state: loadingState, load } = useFetcher<Account>();
  const { data, state: fetcherState, Form } = useFetcher();
  const { users } = useOrganizationUsers({});
  const currentOrg = useCurrentOrg();

  const navigate = useNavigate();
  const { hideBottomSheet } = useShowBottomSheet();
  const [searchParams] = useSearchParams();

  const [cardType, setCardType] = useState<CardType>(CardType.Virtual);
  const [limitEnabled, setLimitEnabled] = useState(false);
  const queryStringCardType = searchParams.get("cardType");

  useNavbar({
    title: "Create Card",
    backAction: () =>
      navigate(`${NavRoutes.BANK_ACCOUNTS}/${accountId}/cards`, {
        direction: NavigationDirection.BACKWARD,
      }),
  });

  useEffect(() => {
    if (!!searchParams.get("cardType")) {
      setCardType(searchParams.get("cardType") as CardType);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!account && loadingState === "idle")
      load(`${NavRoutes.API_ACCOUNT}?accountId=${accountId}`);
  }, [account, accountId, loadingState, load]);

  useEffect(() => {
    if (
      !!data?.validationErrors &&
      Object.keys(data.validationErrors).length > 0
    ) {
      const firstError = Object.values(data.validationErrors)[0] as string;
      showNotice(firstError, { error: true });
    }
  }, [data?.validationErrors]);

  useEffect(() => {
    if (!!data?.error) {
      const errorMessage =
        data.error.message || "There was a problem creating your card.";
      showNotice(errorMessage, { error: true });
    }
  }, [data?.error]);

  useEffect(() => {
    if (data?.response) {
      const newCard = data.response as Card;
      createdCardVar({
        newCard: true,
        cardType: newCard.type || CardType.Virtual,
      });
      navigate(`${NavRoutes.BANK_ACCOUNTS}/${newCard.accountId}/cards`, {
        direction: NavigationDirection.BACKWARD,
      });
    }
  }, [data?.response, navigate]);

  return (
    <Layout loading={!account} whiteFade={true}>
      <AppPage>
        <AppContentContainer topPadding>
          <CardsPageContent>
            <Form method="post">
              <>
                <DetailSelectUser
                  pickerId="contact_picker"
                  fields={{
                    cardHolderUserId: "id",
                    cardHolderFirstName: "firstName",
                    cardHolderLastName: "lastName",
                    cardHolderEmail: "email",
                  }}
                  users={
                    (currentOrg.orgType === OrgType.Business
                      ? users
                      : account?.owners
                      ? account?.owners.filter(
                          ({ isAccountOwner }) => isAccountOwner
                        )
                      : []) || []
                  }
                  label={"Card Holder"}
                  placeholder={"Pick a person.."}
                  actions={
                    <ActionableButton
                      label="Add New Member"
                      onClick={() => navigate(NavRoutes.MEMBERS)}
                      color={ColorNames.MIDNIGHT}
                      iconRight={false}
                    />
                  }
                />

                <StyledFormInputContainer>
                  <Select
                    name="role"
                    label="Card Holder Access"
                    defaultValue={Role.Member}
                    options={cardRoles}
                  />
                  <Select
                    name="type"
                    label="Card Type"
                    defaultValue={
                      queryStringCardType && queryStringCardType === "Physical"
                        ? CardType.Physical
                        : CardType.Virtual
                    }
                    options={cardTypes}
                    onChange={(event) =>
                      setCardType(event.currentTarget.value as CardType)
                    }
                  />

                  {cardType === CardType.Virtual && (
                    <ImageSelect
                      pickerId="image_picker"
                      name="design"
                      label="Card Artwork"
                      defaultValue="spritz"
                      options={artworkOptions}
                    />
                  )}
                </StyledFormInputContainer>

                {/* <BannerNotice
                iconLeft={<IconDiamond />}
                iconRight={<IconDetails />}
                onClick={() => showBottomSheet("create_card_limits_sheet")}
              >
                <>
                  You’re using <b>1</b> of your 3 physical cards limit. Upgrade for more.
                </>
              </BannerNotice> */}

                <StyledFormCollapsableGroup>
                  <Toggle
                    align="left"
                    displayLabel="Limit Available Balance"
                    name="limitEnabled"
                    checked={limitEnabled}
                    onChange={(event) =>
                      setLimitEnabled(event.currentTarget.checked)
                    }
                  />
                  <div
                    className={classnames("content", { open: limitEnabled })}
                  >
                    <CurrencyInput
                      name="limit"
                      type="text"
                      label="Spend Limit"
                    />
                    <Select
                      name="limitBehaviour"
                      label="Spend Limit Behavior"
                      options={limitBehaviors}
                    />
                  </div>
                </StyledFormCollapsableGroup>

                <Button
                  color={ColorNames.LAKE}
                  raised
                  type="submit"
                  name="accountId"
                  value={accountId}
                  loading={fetcherState === "submitting"}
                >
                  Create Card
                </Button>
              </>
            </Form>
          </CardsPageContent>
        </AppContentContainer>

        <ActionBottomSheet
          id="create_card_limits_sheet"
          title="Card Limits"
          actionText="Close"
          actionColor={ColorNames.TRANSPARENT}
          onAction={(): void => hideBottomSheet()}
          children={<></>}
        />
      </AppPage>
    </Layout>
  );
};

export default CreateCard;
