import React, { useEffect, useCallback } from "react";
import { useReactiveVar } from "@apollo/client";

import { showNotice, removeAlert } from "src/store/alertState";
import useNavigate from "src/util/useNavigate";
import { useSetSession, performLogout } from "src/services/auth";
import { useWipeStorage } from "src/util/localStorage";

import { client } from "src";
import { needsLogoutVar } from "src/store/currentUserState";
import { NavRoutes } from "src/routes/navRoutes";
import { NavigationDirection } from "src/store/navigationState";
import { clearOnboardingTermsProgress, storedUserDataKey } from "src/util/localStorage";


const SessionWatcher = () => {
  const needsLogout = useReactiveVar(needsLogoutVar);
  const setSession = useSetSession();
  
  const navigate = useNavigate();
  const wipeAllCookiesAndStorage = useWipeStorage();

  const logout = useCallback((): void => {
    const session: string | null = localStorage.getItem(storedUserDataKey.SESSION);
    if (!!session) performLogout();
    clearOnboardingTermsProgress();
    setSession();
    removeAlert();
    client.resetStore();
    wipeAllCookiesAndStorage({ persistCookies: needsLogoutVar().persistCookies });
    if (!needsLogoutVar().disableRedirect) {
      navigate(NavRoutes.LANDING, { direction: NavigationDirection.BACKWARD, force: true });
    }

    // reset after short delay to prevent triggering gql error alerts
    setTimeout(() => {
      needsLogoutVar({ logout: false, supressAlert: false, persistCookies: false, disableRedirect: false });
    }, 3000);
  }, [setSession, wipeAllCookiesAndStorage, navigate]);

  useEffect(() => {
    if (needsLogout.logout) {
      !needsLogout.supressAlert && showNotice("Your session has expired. Please sign in.", { error: true });
      logout();
    }
  }, [needsLogout.logout]);

  return <></>;
};

export default SessionWatcher;
