import { useEffect, useState } from "react";
import { usePlaidToken, useConnect } from "src/services/connected-accounts";

import { showNotice } from "src/store/alertState";
import { useShowBottomSheet } from "src/util/useBottomSheet";

const usePlaid = ({
  Plaid,
  onSuccess,
  plaidUpdateToken
}: {
  Plaid: PlaidClient;
  onSuccess: () => void;
  plaidUpdateToken?: string;
}) => {
  const [plaid, setPlaid] = useState<PlaidInstance>();
  const [plaidToken, setPlaidToken] = useState<string>();
  const { connect } = useConnect();
  
  const { hideBottomSheet, showBottomSheet } = useShowBottomSheet();
  const { getToken } = usePlaidToken();

  useEffect(() => {
    if (plaidUpdateToken) {
      setPlaidToken(plaidUpdateToken);
    } else {
      (async () => {
        const response = await getToken({ provider: "Plaid" });
        setPlaidToken(response);
      })();
    }
  }, []);

  useEffect(() => {
    if (!Plaid || !plaidToken) return;

    if (!!plaid) plaid.exit({ force: true }, () => plaid.destroy());

    const onSuccessfulComplete = async (public_token: string) => {
      showBottomSheet("connected_account_loading_sheet");
      hideBottomSheet("connect_account_sheet");

      try {
        const connected = await connect({ token: public_token, provider: "Plaid" });

        if (!connected) {
          showNotice("There was a problem connecting to Plaid.", { error: true });
        }
      } catch (error) {
        showNotice("There was a problem connecting to Plaid.", { error: true });
      }

      hideBottomSheet("connected_account_loading_sheet");
      if (!!onSuccess) onSuccess();
    };

    const plaidClient = Plaid.create({
      token: plaidToken,
      onSuccess: onSuccessfulComplete,
      onLoad: () => console.log("Plaid has been initialized"),
      onExit: () => {
        hideBottomSheet("connect_account_sheet");
        setPlaid(null);
      }
    });

    setPlaid(plaidClient);

    return () => plaidClient?.exit({ force: true }, () => plaidClient.destroy());
  }, [plaidToken]);

  return { plaid: plaid! };
};

export default usePlaid;
