/* eslint react-hooks/exhaustive-deps: "off" */
import React from "react";
import { AnimatePresence } from "framer-motion";
import { useParams, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { atom, useSetRecoilState, useRecoilState } from "recoil";
import { TabViewContainer } from "src/components/nav-bars/Subnav.styles";

export const defaultSubnavOptions: string[] = [];
export const defaultSelectedSubnavTab = 0;

export const coreSubnavState = atom({
  key: "coreSubnavState",
  default: defaultSubnavOptions
});

export const coreSelectedSubnavTab = atom({
  key: "coreSelectedSubnavTab",
  default: defaultSelectedSubnavTab
});

interface SubnavOptions {
  selectedTab?: number;
}

export const useSubnav = (
  data: string[],
  options?: SubnavOptions
): { selected: number; selectTab: (id: number) => void } => {
  const setSubnav = useSetRecoilState(coreSubnavState);
  const [selected, setSelected] = useRecoilState(coreSelectedSubnavTab);
  const location = useLocation();
  const { section } = useParams();

  const selectTab = (id: number) => {
    const path = location.pathname.replace(/\/$/, "").replace(`/${section}`, "");
    if (path && data[id].length > 0) {
      const newSection = data[id].replace(/\s+/g, "-");
      window.history.replaceState(null, "", `${path}/${newSection}`);
    }
    return setSelected(id);
  };

  useEffect(() => {
    setTimeout(() => {
      // required to ensure tabs stay put when navigating between pages that both have tabs
      const mergedOptions = [...defaultSubnavOptions, ...data];
      setSubnav(mergedOptions);
      if (!!options?.selectedTab) selectTab(options?.selectedTab);
    }, 0);
  }, []);

  return { selected, selectTab };
};

export const TabRouter = ({ children }: { children: string | JSX.Element | JSX.Element[] }) => {
  return <AnimatePresence initial={false} children={children} />;
};

export const TabView = ({ show, children }: { show: boolean; children: string | JSX.Element | JSX.Element[] }) => {
  return (
    <>
      {!!show && (
        <TabViewContainer
          initial={{
            opacity: 0,
            y: 50
          }}
          animate={{
            opacity: 1,
            y: 0
          }}
          exit={{
            opacity: 0,
            y: 50
          }}
          transition={{
            duration: 0.35
          }}
        >
          {children}
        </TabViewContainer>
      )}
    </>
  );
};
