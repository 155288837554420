import styled from "styled-components";
import { ReactComponent as Menu } from "src/assets/icons/menu.svg";
import { ReactComponent as Close } from "src/assets/icons/close.svg";
import { ReactComponent as Logo } from "src/assets/filled-logo.svg";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

import { StyledNavBar, navIconStyles } from "src/components/nav-bars/NavBars.styles";
import { TIMING_EASE_OUT_CUBIC } from "src/theme/globalStyles";

export const MenuNavBar = styled(StyledNavBar)``;

export const AppMenuButton = styled.div`
  padding: 16px;
  margin: -16px;
  cursor: pointer;
`;

export const MenuIcon = styled(Menu)`
  cursor: pointer;
  opacity: 1;
  transform: scale3d(1, 1, 1) rotate(0);
  transition: transform 0.2s ease-out;
  will-change: transform, opacity;

  @media (min-width: 900px) {
    display: none;
  }

  &:active {
    transform: scale3d(0.8, 0.8, 0.8);
    opacity: 0.8;
  }

  ${navIconStyles}
`;

export const CloseIcon = styled(Close)`
  opacity: 1;
  transform: scale3d(1, 1, 1) rotate(0);
  transition: transform 0.2s ease-out;
  will-change: transform, opacity;

  @media (min-width: 900px) {
    display: none;
  }

  &:active {
    transform: scale3d(0.8, 0.8, 0.8);
    opacity: 0.8;
  }

  ${navIconStyles}
`;

export const LogoIcon = styled(Logo)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  ${navIconStyles}
`;

export const HeaderNames = styled.div`
  flex: 1 1 auto;
`;

export const HeaderName = styled.p<{ orgName?: boolean }>`
  ${fonts.PARAGRAPH_S}
  text-align: right;
  color: ${({ orgName }): string => (orgName ? colors[ColorNames.GRAY5].hex : colors[ColorNames.MIDNIGHT].hex)};
`;

export const SubNavBar = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 42px;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    display: block;
    position: relative;
    flex: 1 1 0px;
    flex-wrap: nowrap;
    ${fonts.PARAGRAPH_S}
    line-height: 42px;
    text-align: center;
    color: ${colors[ColorNames.GRAY5].hex};
    cursor: pointer;

    &:first-child:nth-last-child(n + 4),
    &:first-child:nth-last-child(n + 4) ~ * {
      min-width: 29%;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 2px;
      background: ${colors[ColorNames.MIDNIGHT].hex};
      transform-origin: left center;
      transform: scaleX(0);
      transition: transform 0.25s ${TIMING_EASE_OUT_CUBIC};
    }

    &.active {
      color: ${colors[ColorNames.MIDNIGHT].hex};
      &:after {
        transform: scaleX(1);
      }
    }
  }
`;
