import React, { useEffect, useState } from "react";
import { generatePath, useFetcher, useRouteError } from "react-router-dom";

import { useNavbar } from "src/util/useNavbar";
import useNavigate from "src/util/useNavigate";
import { Deal } from "src/generated/client";

import { NavRoutes } from "src/routes/navRoutes";
import { ColorNames } from "src/theme/theme";

import ErrorElement from "src/ErrorElement";
import Layout from "src/components/core-layout/Layout";
import { SegmentedControl } from "src/components/segmented-control/SegmentedControl";
import { ListButtonContainer } from "src/components/lists/Lists.styles";
import { ListButton } from "src/components/lists/ListButton";
import { Avatar } from "src/components/avatar/Avatar";
import { BottomSheet } from "src/components/bottom-sheets/BottomSheet";
import DealTransactionDetail from "./DealTransactionDetail";
import { useShowBottomSheet } from "src/util/useBottomSheet";

import {
  StyledBrowseContent
} from "./MyDeals.styles";

export const ListingsErrorElement = () => {
  return <ErrorElement redirect={NavRoutes.BANK_DASHBOARD} alert={true} />;
};



    //// TEMPORARY TO TEST
    // const tempDeal: Deal = {
    //   __typename:"Deal",
    //   id:"01G7Q61T8MMVMB03QB3F2MFHTK",
    //   type: DealTypes.SECONDARY,
    //   committedAmount:{
    //     display:"",
    //     amount:0
    //   },
    //   targetAmount:{
    //     display:"10,000,000",
    //     amount:1000000000
    //   },
    //   minInvestment:{
    //     display:"25,000",
    //     amount:2500000
    //   },
    //   name:"Chime",
    //   createdAt:"Thu Aug 04 2022 12:10:28 GMT-0700 (PDT)",
    //   targetedTotalReturn:{
    //     amount:15,
    //     display:"15.00"
    //   },
    //   termLength:"5 years",
    //   caption:"A diversified commercial real estate debt fund.",
    //   description:"We've partnered with WelcomeFund to bring you private CRE deals no one else has access to.<br /><br />WelcomeFund lends money to real estate developers to build and acquire commercial real estate. They have a proven track record of deploying capital to successful development ventures.",
    //   details:"Targeted returns are 15% annually for an approximate 5 year hold. Investors receive the first 8% of returns and subsequent profits are split with the fund's partners to achieve the 15% IRR.<br /><br />This exclusive investment will be executed via a Letter managed SPV. Letter will manage the process of your investment and provide support along the way.",
    //   closeDate:"Sep. 30th, 2022",
    //   infoLink:"https://letter.welcomelend.com/methodology",
    //   distributions:"Quarterly",
    //   letterFee:{
    //     amount:0.5,
    //     display:"0.5"
    //   },
    //   logoImage:"",
    //   headerImage:"",
    //   buyCommittedAmount: {
    //     display:"172,459",
    //     amount:17245900
    //   },
    //   sellCommittedAmount: {
    //     display:"54,848",
    //     amount:5484800
    //   },
    //   discount: {
    //     display:"23.34",
    //     amount:23.34
    //   },
    //   impliedValuation: {
    //     display:"6.38B",
    //     amount:638000000000
    //   },
    //   lastRound: "July 2021",
    //   lastValuation: {
    //     display:"7.04B",
    //     amount:704000000000
    //   },
    //   minBuy: {
    //     display:"25,000",
    //     amount:2500000
    //   },
    //   minSell: {
    //     display:"25,000",
    //     amount:2500000
    //   },
    //   sharePrice: {
    //     display:"4.28",
    //     amount:428
    //   },
    //   structure: "direct",
    //   buyThreshold: {
    //     display:"250,000",
    //     amount:25000000
    //   },
    //   sellThreshold: {
    //     display:"250,000",
    //     amount:25000000
    //   }
    // };
    // const tempTransaction: DealTransaction = {
    //   __typename:"DealTransaction",
    //   id:"ABJDHG838374837",
    //   amount:{
    //     display:"34,593.87",
    //     amount:3459387
    //   },
    //   deal: {
    //     id: "abc345",
    //     name: "Stripe",
    //     type: DealTypes.SECONDARY,
    //     createdAt: "",
    //     description: "",
    //     infoLink: ""
    //   },
    //   dealId: "abc345",
    //   createdAt:"Thu Aug 04 2022 12:10:28 GMT-0700 (PDT)",
    //   committedAmount: {
    //     display:"25,000",
    //     amount:2500000
    //   },
    //   entityQuantity: "348",
    //   entityType: DealEntityTypes.Share,
    //   formattedDate: "Jun 14",
    //   fundingAccount: {
    //     id: "bbb",
    //     organizationId: "eee",
    //     last4: "0993",
    //     ref: AccountRef.Checking,
    //     balance: {amount: 2500, display: "25.00"},
    //     availableBalance: {amount: 2500, display: "25.00"},
    //     totalBalance: {amount: 2500, display: "25.00"},
    //     createdAt: "",
    //     externalAccountNumber: "",
    //     externalRoutingNumber: "",
    //     type: AccountType.UnitDeposit,
    //     status: AccountStatus.Open
    //   },
    //   fundingAccountId: "0349KHJH398347",
    //   lastUpdatedAt:"Thu Aug 04 2022 12:10:28 GMT-0700 (PDT)",
    //   organizationId: "HEB98980349KHJH39833471",
    //   status: DealTransactionStatuses.COMPLETED,
    //   transactionDate: "Thu Aug 04 2022 12:10:28 GMT-0700 (PDT)",
    //   transactionType: DealTransactionTypes.BUY
    // };
  ////



const MyDeals = () => {
  const { data: deals, state: fetcherState, ...fetcher } = useFetcher<Deal[]>();
  const [selectedTransaction, setSelectedTransaction] = useState<any | undefined>();
  const { showBottomSheet } = useShowBottomSheet();
  const navigate = useNavigate();

  useNavbar({ title: "My Deals" });

  useEffect(() => {
    if (!deals && fetcherState === "idle") fetcher.load(NavRoutes.API_DEAL);
  }, [deals, fetcherState, fetcher]);

  const [dealLifecycle, setDealLifecycle] = useState("in-progress");

  return (
    <Layout loading={fetcherState === "loading"}>
      <StyledBrowseContent>
        <SegmentedControl
          options={[
            {
              label: "In Progress",
              value: "in-progress",
              count: 2
            },
            {
              label: "Completed",
              value: "completed",
              count: 3
            }
          ]}
          color={ColorNames.MIST}
          active={dealLifecycle}
          onClick={(value) => setDealLifecycle(value as string)}
        />
        <ListButtonContainer>
          {dealLifecycle === "in-progress" ? (
            <>
            <ListButton
              key={1}
              label={"WelcomeFund"}
              sublabel={"Invest – $40,000"}
              avatar={<Avatar name={"WelcomeFund"} image={`/marketplace/tile-logos/welcomefund.png`} fitImage size={40} textColor={ColorNames.GRAY5} color={ColorNames.GRAY1} />}
              onClick={() => {
                navigate(
                  generatePath(NavRoutes.MARKETPLACE_LISTING_DETAIL, {
                    listingId: "01G7Q61T8MMVMB03QB3F2MFHTK",
                    section: "overview"
                  })
                );
              }}
            />
            <ListButton
              key={2}
              label={"Stripe"}
              sublabel={"Buy – $15,002.34"}
              avatar={<Avatar name={"Stripe"} image={`/marketplace/tile-logos/stripe.png`} fitImage size={40} textColor={ColorNames.GRAY5} color={ColorNames.GRAY1} />}
              onClick={() => {
                navigate(
                  generatePath(NavRoutes.MARKETPLACE_LISTING_DETAIL, {
                    listingId: "01G7Q61T8MMVMB03QB3F2MFHTK",
                    section: "buy"
                  })
                );
              }}
            />
            </>
          ) : (
            <>
            <ListButton
              key={3}
              label={"Chime"}
              sublabel={"Buy – $10,000"}
              avatar={<Avatar name={"Chime"} image={`/marketplace/tile-logos/chime.png`} fitImage size={40} textColor={ColorNames.GRAY5} color={ColorNames.GRAY1} />}
              rightLabel={"Jun 02"}
              onClick={() => {
                setSelectedTransaction({});
                setTimeout(() => {
                  showBottomSheet("deal_transaction_item_sheet");
                }, 0);
              }}
            />
            <ListButton
              key={4}
              label={"Discord"}
              sublabel={"Sell – $300,000"}
              avatar={<Avatar name={"Discord"} image={`/marketplace/tile-logos/discord.png`} fitImage size={40} textColor={ColorNames.GRAY5} color={ColorNames.GRAY1} />}
              rightLabel={"Jun 14"}
              onClick={() => {
                setSelectedTransaction({});
                setTimeout(() => {
                  showBottomSheet("deal_transaction_item_sheet");
                }, 0);
              }}
            />
            </>
          )}
        </ListButtonContainer>

        <BottomSheet noPadding closeOnOutsideClick={true} id="deal_transaction_item_sheet">
          <DealTransactionDetail transaction={selectedTransaction} />
        </BottomSheet>

      </StyledBrowseContent>
    </Layout>
  );
};

export default MyDeals;
