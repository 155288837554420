import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useRecoilValue } from "recoil";
import { detailPickerState, IDetailPickerState } from "src/store/detailPickerState";
import { useSetDetailPicker, useShowDetailPicker } from "src/util/useDetailPicker";
import { ColorNames } from "src/theme/theme";
import { BalancesTimeframe } from "src/generated/client";

import {
  TimeRangePickerContainer,
  IconExpand,
  DetailPickerOptionCheckmarkHolder,
  DetailPickerOptionCheckmark
} from "src/components/timerange-picker/TimeRangePicker.styles";
import { DetailPickerOptionButton } from "../detail-picker/DetailPicker.styles";
import { storedUserDataKey } from "src/util/localStorage";

export interface TimeRangePickerOption {
  id: BalancesTimeframe;
  label: string;
}

export interface TimeRangePickerProps {
  pickerId: string;
  label: string;
  data?: TimeRangePickerOption[];
  value: BalancesTimeframe;
  onChange: (newValue: BalancesTimeframe) => void;
}

const defaultTimeRanges: TimeRangePickerOption[] = [
  { id: BalancesTimeframe.LastSeven, label: "Last 7 days" },
  { id: BalancesTimeframe.LastThirty, label: "Last 30 days" },
  { id: BalancesTimeframe.ThisYear, label: "Year to date" },
  { id: BalancesTimeframe.AllTime, label: "All time" }
];

export const TimeRangePicker = (timeRangePickerProps: TimeRangePickerProps): JSX.Element => {
  const { pickerId, label, data = defaultTimeRanges, value, onChange } = timeRangePickerProps;
  const setDetailPicker = useSetDetailPicker();
  const { showDetailPicker, hideDetailPicker } = useShowDetailPicker();
  const [selectedOption, setSelectedOption] = useState<TimeRangePickerOption | null>(null);

  useEffect(() => {
    const matches = data.filter((d) => d.id === value);
    setSelectedOption(matches.length > 0 ? matches[0] : null);
  }, [value, data]);

  const didSetValue = (newValue: BalancesTimeframe) => {
    onChange(newValue);
    localStorage.setItem(storedUserDataKey.TIMERANGE, newValue);
  };

  const openDetailPicker = () => {
    showDetailPicker(pickerId);
  };

  useEffect(() => {
    setDetailPicker({
      id: pickerId,
      displayLabel: label,
      color: ColorNames.PINE,
      hasValue: !!value,
      options: (
        <>
          {data.map((d) => (
            <DetailPickerOptionButton
              key={d.id}
              onClick={() => {
                didSetValue(d.id);
                setTimeout(() => {
                  hideDetailPicker(pickerId);
                }, 50);
              }}
            >
              <div>
                <div>{d.label}</div>
              </div>
              {d.id === selectedOption?.id && (
                <DetailPickerOptionCheckmarkHolder children={<DetailPickerOptionCheckmark />} />
              )}
            </DetailPickerOptionButton>
          ))}
        </>
      )
    });
  }, [hideDetailPicker, label, value, data, setDetailPicker]);

  return (
    <TimeRangePickerContainer onClick={openDetailPicker} className={classnames({ selected: showDetailPicker })}>
      <span className="timerange-label">{selectedOption?.label}</span>
      <IconExpand />
    </TimeRangePickerContainer>
  );
};
