import { atom } from "recoil";
import { ConnectedAccount } from "src/generated/client";

export const isCAConnectingState = atom<boolean>({
  key: "isCAConnecting",
  default: false,
});

export const expectedNumberOfConnectedAccountsState = atom<number>({
  key: "expectedNumberOfConnectedAccounts",
  default: 0,
});

export const expectedNewConnectedAccountsDataState = atom<ConnectedAccount[]>({
  key: "expectedNewConnectedAccountsData",
  default: [],
});
