import styled from "styled-components";
import { LayoutFullPage } from "src/components/core-layout/CoreLayout.styles";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

export const AppPage = styled(LayoutFullPage)<{ gray?: boolean }>`
  width: 100%;
  display: flex;
  flex-flow: column;
`;

export const AppContentContainer = styled.div<{
  topPadding?: boolean;
}>`
  width: 100%;
  position: relative;
  flex: 1;
  padding-top: ${({ topPadding }): string => (topPadding ? "20px" : "0")};
`;

export const ParagraphLarge = styled.p`
  ${fonts.PARAGRAPH_L}
`;

export const ParagraphMedium = styled.p`
  ${fonts.PARAGRAPH_M}
`;

export const ParagraphSmall = styled.p`
  ${fonts.PARAGRAPH_S}
`;

export const Heading5 = styled.p`
  ${fonts.HEADING_5}
`;
