import { ApolloClient, InMemoryCache, NormalizedCacheObject, from, ApolloLink } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "apollo-link-error";
import ApolloLinkTimeout from "apollo-link-timeout";
import { captureException } from "src/util/useLogger";
import { typeDefs, resolvers } from "src/util/resolvers";
import { needsLogoutVar } from "src/store/currentUserState";
import { storedUserDataKey } from "./util/localStorage";

export const SESSION_ERROR_CODES = ["UNAUTHENTICATED"];
export const EXPECTED_ERROR_CODES = ["FORBIDDEN", "BAD_USER_INPUT", ...SESSION_ERROR_CODES];
export const ALLOWED_UNAUTHENTICATED_PATHS = ["validSession", "recaptchaSiteVerify", "logout"];

const cache = new InMemoryCache({
  typePolicies: {
    VerifiedStatus: {
      keyFields: ["_userId"]
    }
  }
});

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_GQL_DOMAIN}/graphql`
});

const timeoutLink = new ApolloLinkTimeout(60000);

const authMiddleware = new ApolloLink((operation, forward) => {
  const session: string | null = localStorage.getItem(storedUserDataKey.SESSION);
  if (session)
    operation.setContext(({ headers = {} }: { headers: { [key: string]: string } }) => ({
      headers: {
        ...headers,
        Authorization: `Bearer ${session}`
      }
    }));

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors }) => {
  if (!!graphQLErrors) {
    graphQLErrors.forEach(({ message, path, extensions }) => {
      const code = !!extensions?.code ? extensions.code : null;
      if (!EXPECTED_ERROR_CODES.includes(code)) {
        captureException(`[GraphQL error]: Message: ${message}; Code: ${code}; Path: ${path}}`);
      }
    });

    if (
      graphQLErrors.some((value) => {
        const code = !!value.extensions?.code ? value.extensions.code : null;
        const isInvalidSession = SESSION_ERROR_CODES.includes(code);
        const isValidPath = value.path?.some((path) => ALLOWED_UNAUTHENTICATED_PATHS.includes(path.toString()));
        return !isValidPath && isInvalidSession;
      })
    ) {
      needsLogoutVar({ ...needsLogoutVar(), logout: true });
    }
  }
});

const link = from([errorLink as unknown as ApolloLink, authMiddleware, timeoutLink, httpLink as unknown as ApolloLink]);

const graphqlClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link,
  typeDefs,
  resolvers
});

export default graphqlClient;
