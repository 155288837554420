import styled from "styled-components";
import { colorPalette as colors, ColorNames, fonts, borderRadius } from "src/theme/theme";
import { NumberText } from "src/components/text";

export const StyledDashboard = styled.div`
  min-height: calc(100vh - var(--navbarHeight) - var(--layoutTopPadding));
  @supports (-webkit-touch-callout: none) {
    min-height: calc(-webkit-fill-available - var(--navbarHeight) - var(--layoutTopPadding));
  }
  height: 100%;
  width: 100%;
  background: white;
  position: relative;
  z-index: 1;
`;

export const DashboardBalanceCardContainer = styled.div`
  flex: 1 1 0%;
  margin-bottom: 30px;
`;

export const DashboardContent = styled.div`
  padding: 0 30px;
  margin-bottom: 30px;
`;

export const DashboardInfo = styled.div`
  padding: 0 40px;
  margin: 30px 0 30px;
`;

export const DashboardInfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  ${fonts.PARAGRAPH_M}
  color: ${colors[ColorNames.GRAY5].hex};

  .amount {
    ${fonts.NUMBER_M}
    color: ${colors[ColorNames.MIDNIGHT].hex};
  }
`;

export const DashboardCharity = styled.div`
  margin: 30px;
  padding: 20px;
  background: ${colors[ColorNames.SAND].hex};
  border-radius: ${borderRadius};
  color: ${colors[ColorNames.GOLD].hex};
  display: flex;
  flex-direciton: row;
  align-items: center;

  .info-list {
    flex: 1 1 0%;
    margin-left: 20px;
  }
`;

export const DashboardDonations = styled(NumberText)`
  ${fonts.PARAGRAPH_L}
  color: ${colors[ColorNames.CINNAMON].hex};
  margin-bottom: 8px;
`;

export const DashboardCharities = styled.p`
  ${fonts.PARAGRAPH_S}
`;

export const DashboardRegulationInfo = styled.div`
  ${fonts.PARAGRAPH_XS}
  color: ${colors[ColorNames.GRAY5].hex};
  padding: 0 30px;

  p {
    margin-top: 16px;
  }
`;
