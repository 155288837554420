import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useReactiveVar } from "@apollo/client";
import { AnimatePresence, motion } from "framer-motion";

import { showNotice } from "src/store/alertState";
import useSetOrganization from "src/util/useSetOrganization";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { showOrgSwitcherState } from "src/store/orgSwitcherState";
import { useCurrentUser } from "src/services/users";
import { useCurrentOrg } from "src/services/organizations";
import { BottomSheet } from "src/components/bottom-sheets/BottomSheet";
import NewBusinessOrganization from "src/pages/organization/NewBusinessOrganization";
import { clearOnboardingTermsProgress } from "src/util/localStorage";
import { colorPalette as colors, ColorNames } from "src/theme/theme";
import { DataTestIds } from "src/util/testing-util/test-utils";
import { ActionBottomSheet } from "../bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import { ActionSheetCenteredContainer } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet.styles";
import { useOpenAccount } from "src/services/users";
import { useAddBusinessInfo } from "src/services/organizations";

import { Organization, OrgType } from "src/generated/client";

import { Avatar } from "../avatar/Avatar";
import {
  OrganizationRow,
  OrgSwitcherActions,
  OrgSwitcherContainer,
  OrgSwitcherDropdown,
  OrgSwitcherDropdownContent,
  OrgSwitcherHeader,
  OrgSwitcherList,
  Overlay,
} from "./OrgSwitcher.styles";

import { ActionableButton } from "../actionable-button/ActionableButton";
import { ReactComponent as CollapseIcon } from "src/assets/icons/arrows_collapse.svg";


const MENU_ANIMATION_LENGTH = 200;

export const OrgSwitcher = (): JSX.Element => {
  const showSwitcher = useReactiveVar(showOrgSwitcherState);
  const currentUser = useCurrentUser();
  const currentOrg = useCurrentOrg();
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();
  const {
    openAccount,
    loading: openAccountLoading,
    error: openAccountError,
  } = useOpenAccount();
  const {
    setOrganization,
    loading: setOrganizationLoading,
    error: setOrganizationError,
  } = useSetOrganization();
  const [newOrg, setNewOrg] = useState<Organization | null>(null);
  const [hasPersonal, setHasPersonal] = useState<boolean>(false);
  const {
    addBusinessInfo,
    loading: addBusinessInfoLoading,
    error: addBusinessInfoError,
  } = useAddBusinessInfo();

  useEffect(() => {
    if (setOrganizationError) {
      showNotice("There was a problem changing organizations.", {
        error: true,
      });
    }
  }, [setOrganizationError]);

  useEffect(() => {
    if (!!currentUser && currentUser.organizations?.length) {
      setHasPersonal(
        !!currentUser.organizations.find((o) => o?.orgType === "Individual")
      );
    }
  }, [currentUser]);

  const selectOrg = async (org: Organization) => {
    if (org.id === currentOrg?.id) return;

    const data = await setOrganization(org);
    showOrgSwitcherState(false);

    if (!!data) {
      // close menu
      showOrgSwitcherState(false);

      // fire off full refresh to ensure active page gets correct data
      setTimeout(() => {
        window.location.reload();
      }, MENU_ANIMATION_LENGTH);
    }
  };

  const handleOpenOrgAccount = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    hideBottomSheet();
    clearOnboardingTermsProgress();
    setTimeout(() => {
      showBottomSheet("new_business_org_sheet");
    }, 0);
  };

  return (
    <>
      <OrgSwitcherContainer>
        <div
          className="tap-target"
          style={{ cursor: "pointer" }}
          onClick={() => showOrgSwitcherState(true)}
          title="Organizations"
        >
          <Avatar
            outline
            size={32}
            name={currentOrg?.name || "Personal"}
            color={ColorNames.LAKE}
            image={""}
          />
        </div>
        <OrgSwitcherDropdown
          className={classnames({ show: showSwitcher })}
          orgCount={currentUser?.organizations?.length || 0}
          data-testid={DataTestIds.ORG_SWITCHER_DROPDOWN}
        >
          <OrgSwitcherDropdownContent>
            <OrgSwitcherHeader>
              <span>Switch Organizations</span>
              <button
                className="org-switcher-collapse-icon"
                onClick={() => showOrgSwitcherState(false)}
                data-testid={DataTestIds.ORG_SWITCHER_COLLAPSE}
              >
                <CollapseIcon />
              </button>
            </OrgSwitcherHeader>
            <OrgSwitcherActions>
              <ActionableButton
                label="Add Organization"
                iconRight={false}
                color={ColorNames.MIDNIGHT}
                onClick={() => {
                  showOrgSwitcherState(false);
                  clearOnboardingTermsProgress();
                  showBottomSheet("new_business_org_sheet");
                }}
              />
              {!hasPersonal && (
                <ActionableButton
                  label="Open a Personal Account"
                  iconRight={false}
                  color={ColorNames.PINE}
                  onClick={() => {
                    showBottomSheet("open_personal_account");
                  }}
                />
              )}
            </OrgSwitcherActions>
            <OrgSwitcherList
              className={classnames({ show: showSwitcher })}
              data-testid={DataTestIds.ORG_SWITCHER_LIST}
            >
              <div>
                {(currentUser?.organizations &&
                currentUser?.organizations.length
                  ? currentUser?.organizations
                  : []
                ).map((org, index) => (
                  <AnimatePresence key={org.id}>
                    {showSwitcher && (
                      <motion.div
                        initial={listAnimationExit}
                        animate={listAnimationEnter}
                        exit={listAnimationExit}
                        transition={{ delay: 0.08 + 0.01 * index }}
                      >
                        <OrganizationRow
                          className={
                            org.id === currentOrg?.id ? "selected" : ""
                          }
                          onClick={() => selectOrg(org)}
                        >
                          <Avatar
                            size={40}
                            shadow
                            name={org.name || "Personal"}
                            // color={org.color}
                            // image={org.image}
                          />
                          <span className="org-name">
                            {org.name || "Personal"}
                          </span>
                        </OrganizationRow>
                      </motion.div>
                    )}
                  </AnimatePresence>
                ))}
              </div>
            </OrgSwitcherList>
          </OrgSwitcherDropdownContent>
        </OrgSwitcherDropdown>
      </OrgSwitcherContainer>
      {showSwitcher && (
        <Overlay
          onClick={() => showOrgSwitcherState(false)}
          data-testid={DataTestIds.ORG_SWITCHER_OVERLAY}
        />
      )}

      <BottomSheet
        noPadding
        closeOnOutsideClick={false}
        id="new_business_org_sheet"
      >
        <NewBusinessOrganization />
      </BottomSheet>
      <ActionBottomSheet
        id="open_personal_account"
        title="Open a Personal Account"
        actionText="Open Personal Account"
        actionColor={ColorNames.LAKE}
        actionLoading={addBusinessInfoLoading || openAccountLoading}
        onAction={async () => {
          const orgData = await addBusinessInfo({
            values: { orgType: OrgType.Individual },
          });
          if (!orgData) {
            showNotice("There was a problem creating your account.", {
              error: true,
            });
            return false;
          }
          setNewOrg(orgData);
          const accountData = await openAccount();
          if (!accountData) {
            showNotice("There was a problem creating your account.", {
              error: true,
            });
            return false;
          }
          hideBottomSheet();
          showBottomSheet("personal_account_created");
        }}
        secondaryActionText="Cancel"
        onSecondaryAction={(): void => hideBottomSheet()}
        children={
          <ActionSheetCenteredContainer>
            With one click you can open a new, and separate Personal Letter
            account, an account made just for you.{" "}
            <a
              href="https://help.letter.co/help/personal-and-business-accounts"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
            <br />
            <br />
            <small>
              Or,{" "}
              <a
                href="void(0)"
                onClick={(e: React.MouseEvent<HTMLElement>) =>
                  handleOpenOrgAccount(e)
                }
              >
                open an account for an organization
              </a>{" "}
              and invite your whole crew.
            </small>
          </ActionSheetCenteredContainer>
        }
      />
      <ActionBottomSheet
        id="personal_account_created"
        success={true}
        title="Account Created!"
        actionText="Switch to Personal account"
        actionColor={ColorNames.PINE}
        actionLoading={setOrganizationLoading}
        onAction={async () => {
          if (!newOrg || newOrg.id === currentOrg?.id) return;
          const data = await setOrganization(newOrg);
          if (!!data) {
            setTimeout(() => {
              window.location.reload();
            }, 200);
          }
          hideBottomSheet();
        }}
        onClose={() => hideBottomSheet()}
        secondaryActionText="Close and switch later"
        onSecondaryAction={(): void => hideBottomSheet()}
        children={
          <ActionSheetCenteredContainer>
            Your new Personal Letter account has been created. You can switch to
            the new account now to set it up and review, or you can switch to it
            later.
          </ActionSheetCenteredContainer>
        }
      />
    </>
  );
};

const listAnimationEnter = {
  opacity: 1,
  y: 0,
};
const listAnimationExit = {
  opacity: 0,
  y: -12,
};
