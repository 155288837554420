import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useIsSessionValid, ValidSession } from "src/util/useIsSessionValid";
import { useAuthRedirect } from "src/services/auth";
import { useFetchCurrentUser } from "src/services/users";
import useNavigate from "src/util/useNavigate";
import { showNotice } from "src/store/alertState";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { useNavbar } from "src/util/useNavbar";
import { useCookies } from "react-cookie";
import { NavRoutes } from "src/routes/navRoutes";
import { NavigationDirection } from "src/store/navigationState";

import { UserInput } from "src/generated/client";
import { useEditUser } from "src/services/users";
import { AboutBottomSheet } from "src/components/bottom-sheets/about-bottom-sheet/AboutBottomSheet";
import { AuthPageWrapper, AuthTitle, AuthCaption } from "src/pages/auth/Auth.styles";
import { StyledLandingPage } from "../landing/Landing.styles";
import { InfoIcon } from "src/components/icons/actionIcons";
import { ReactComponent as IconHelp } from "src/assets/icons/help.svg";

import AccountInfo from "src/pages/auth/account-info/AccountInfo";
import { CypressTestIds } from "src/util/testing-util/test-utils";
import { storedCookieDataKey } from "src/util/localStorage";
import Layout from "src/components/core-layout/Layout";

const SignUp = (): JSX.Element => {
  const [inviteCode, setInviteCode] = useState<string>();
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();

  useNavbar({
    back: true,
    backAction: () => navigate(NavRoutes.LANDING, { direction: NavigationDirection.BACKWARD }),
    orgSwitcher: false
  });

  const { validSession, loading: sessionLoading } = useIsSessionValid();
  const { fetchCurrentUser, userLoading } = useFetchCurrentUser();
  const didLoad = useRef<boolean>(false);
  const { edit: createUser, error } = useEditUser(true);
  const [cookies, setCookie] = useCookies([
    storedCookieDataKey.PARTNERSHIP_PREFIX,
    storedCookieDataKey.INVITE_CODE_PREFIX
  ]);
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  

  useAuthRedirect({ invite: cookies[storedCookieDataKey.INVITE_CODE_PREFIX] });

  useEffect(() => {
    if (sessionLoading) return;
    if (validSession === ValidSession.VALID) fetchCurrentUser({ fromNetwork: true });
  }, [validSession, sessionLoading, fetchCurrentUser]);

  useEffect(() => {
    if (sessionLoading || didLoad.current) return;
    if (!cookies[storedCookieDataKey.INVITE_CODE_PREFIX] && validSession !== ValidSession.INVALID) return;

    const inviteCookie = cookies[storedCookieDataKey.INVITE_CODE_PREFIX];

    if (inviteCookie) {
      setInviteCode(inviteCookie);
    }

    didLoad.current = true;
  }, [sessionLoading, validSession, navigate, cookies, queryParams, setCookie, showBottomSheet]);

  useEffect(() => {
    if (error) {
      showNotice(error?.message || "There was a problem signing up.", { error: true });
    }
  }, [error]);

  // const signUpAction = async (values?: UserInput, setSubmitting?: (submitting: boolean) => void) => {
  //   const payload: UserInput = {
  //     ...(values || ({} as UserInput)),
  //     inviteCode
  //   }
  //   if (!!cookies[storedCookieDataKey.PARTNERSHIP_PREFIX])
  //     payload.partnershipType = cookies[storedCookieDataKey.PARTNERSHIP_PREFIX];

  //   const user = await createUser(payload);
  //   !!setSubmitting && setSubmitting(false);
  //   hideBottomSheet();
  //   if (user) fetchCurrentUser({ fromNetwork: true });
  // };

  return (
    <Layout loading={userLoading} noTopPadding>
      <StyledLandingPage>
        <AuthPageWrapper>
          <AuthTitle children="Sign Ups have been closed." />
          <AuthCaption>Thank you for your interest in Letter. Unfortunately Letter is shutting down, and will no longer be available after March 13th 2023. Because of this, we are no longer open to new customers. </AuthCaption>
        </AuthPageWrapper>
      </StyledLandingPage>
    </Layout>
  );
};

export default SignUp;
