import { ActionFunction, LoaderFunction } from "react-router-dom";
import { getOrganizationById, requestAccreditation } from "src/services/organizations";
import { RouteActionResponse } from "src";

export const organizationLoader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const orgId = url.searchParams.get("organizationId");

  try {
    if (!!orgId) {
      return await getOrganizationById(orgId);
    } else {
      throw Error("Could not find your organization.");
    }
  } catch (e) {
    throw e;
  }
};

export const organizationAction: ActionFunction = async ({ request }) => {
  const data = Object.fromEntries(await request.formData());

  if (data._action === "editOrganization") {
  } else if (data._action === "requestAccreditation") {
    try {
      const response = await requestAccreditation();
      return { response };
    } catch (error) {
      const response: RouteActionResponse<unknown> = {};
      response.error = error;

      return response;
    }
  }
};
