import React, { useEffect, useMemo } from "react";
import { useSetBottomSheet } from "src/util/useBottomSheet";

export interface BottomSheetProps {
  id: string;
  children: JSX.Element | string;
  fullHeight?: boolean;
  noPadding?: boolean;
  hasHeader?: boolean;
  closeOnOutsideClick?: boolean;
}

export const BottomSheet = ({
  id,
  closeOnOutsideClick = true,
  children,
  noPadding = false,
  fullHeight = false,
  hasHeader = false
}: BottomSheetProps): JSX.Element => {
  const setBottomSheet = useSetBottomSheet();

  const bottomSheetChildren = useMemo(() => {
    return <>{children}</>;
  }, [children]);

  useEffect(() => {
    setBottomSheet({ id, closeOnOutsideClick, fullHeight, noPadding, hasHeader, children: bottomSheetChildren });
  }, [bottomSheetChildren, id, setBottomSheet, closeOnOutsideClick, fullHeight, noPadding, hasHeader]);

  return <></>;
};
