import { ActionFunction } from "react-router-dom";
import * as Yup from "yup";

import { createCard, updateCard, closeCard } from "src/services/cards";

import { convertStringNumberToCents } from "src/util/stringUtils";
import { getFormValidationErrors } from "src/util/forms";
import { RouteActionResponse } from "src";
import { CardLimitBehaviourType, CardType, Role } from "src/generated/client";

export const cardAction: ActionFunction = async ({ request }) => {
  const data = Object.fromEntries(await request.formData());

  if (request.method === "POST") {
    const schema: Yup.ObjectSchema<object> = Yup.object().shape({
      cardHolderUserId: Yup.string().required("Please choose a card holder."),
      type: Yup.string().required("Please choose a card type."),
      limit: Yup.string().when("limitEnabled", {
        is: "on",
        then: Yup.string()
          .notOneOf(["0.00", "", "0"], "You must specify a limit amount.")
          .required("You must specify a limit amount."),
      }),
    });

    try {
      await schema.validate(data, { abortEarly: false });
      const response = await createCard({
        accountId: data.accountId as string,
        cardHolderUserId: data.cardHolderUserId as string,
        cardHolderFirstName: data.cardHolderFirstName as string,
        cardHolderLastName: data.cardHolderLastName as string,
        cardHolderEmail: data.cardHolderEmail as string,
        type: data.type as CardType,
        role: data.role as Role,
        design: (data.design as string) || "",
        limit: convertStringNumberToCents((data.limit as string) || ""),
        limitBehaviour: data.limitBehaviour as CardLimitBehaviourType,
      });
      return { response };
    } catch (error) {
      const response: RouteActionResponse<unknown> = {};
      if (error instanceof Yup.ValidationError) {
        response.validationErrors = getFormValidationErrors(error);
      } else {
        response.error = error;
      }

      return response;
    }
  } else if (request.method === "PUT") {
    const schema: Yup.ObjectSchema<object> = Yup.object().shape({
      limit: Yup.string().when("limitEnabled", {
        is: "on",
        then: Yup.string()
          .notOneOf(["0.00", "", "0"], "You must specify a limit amount.")
          .required("You must specify a limit amount."),
      }),
    });

    try {
      await schema.validate(data, { abortEarly: false });
      const limitEnabled = (data.limitEnabled as string) === "on";
      const response = await updateCard({
        cardId: data.cardId as string,
        limit: limitEnabled
          ? convertStringNumberToCents((data.limit as string) || "")
          : 0,
        limitBehaviour: data.limitBehaviour as string,
        limitEnabled: limitEnabled,
      });
      return { response };
    } catch (error) {
      const response: RouteActionResponse<unknown> = {};
      if (error instanceof Yup.ValidationError) {
        response.validationErrors = getFormValidationErrors(error);
      } else {
        response.error = error;
      }

      return response;
    }
  } else if (request.method === "DELETE") {
    try {
      const response = await closeCard(data.cardId as string);
      return { response };
    } catch (error) {
      const response: RouteActionResponse<unknown> = {};
      response.error = error;
      return response;
    }
  }
};
