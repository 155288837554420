import React from "react";
import { StyledLogoSpinner } from "src/components/logo-spinner/LogoSpinner.styles";
import { BrandColor, ColorNames, DefaultTheme } from "src/theme/theme";
import { DataTestIds } from "src/util/testing-util/test-utils";

export interface LogoSpinnerProps {
  theme?: DefaultTheme;
  color?: BrandColor;
  height?: string | number | null;
}

export const LogoSpinner = (props: LogoSpinnerProps): JSX.Element => {
  const { color = ColorNames.MIDNIGHT, height = null } = props;

  return (
    <StyledLogoSpinner color={color} height={height} data-testid={DataTestIds.LOGO_SPINNER}>
      <svg>
        <use href="#LogoSpinner" />
      </svg>
    </StyledLogoSpinner>
  );
};
