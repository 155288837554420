import styled from "styled-components";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

import { ActionableButtonGroup, ActionableButtonRow } from "src/components/actionable-button/ActionableButton.styles";
import { BannerNoticeContainer } from "src/components/alert/Alerts.styles";

export const MemberDetailContent = styled.div`
  padding: 0 30px 30px;
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${ActionableButtonGroup} {
    margin-bottom: 0;
  }

  ${ActionableButtonRow} {
    margin-top: 10px;
    width: 100%;
    gap: 10px;
  }

  ${BannerNoticeContainer} {
    margin-top: 20px;
  }
`;

export const MemberDetailInfo = styled.div`
  margin: 10px 0 30px;
  ${fonts.PARAGRAPH_M}
  text-align: center;

  .role {
    margin-top: 6px;
    color: ${colors[ColorNames.GRAY5].hex};
  }
`;

export const MemberDetailActions = styled.div`
  width: 100%;
`;
