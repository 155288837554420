import styled, { keyframes } from "styled-components";
import { colorPalette as colors, ColorNames, borderRadius } from "src/theme/theme";

const GradientSlide = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
`;

export const Skeleton = styled.span`
  display: block;
  position: relative;
  background-color: ${colors[ColorNames.GRAY1].hex};
  border-radius: ${borderRadius};
  margin: 4px;
  overflow: hidden;

  &.transparent {
    background-color: rgba(0, 0, 0, 0.02);
  }

  &.invert {
    background-color: rgba(255, 255, 255, 0.02);
  }

  &.noMargin {
    margin: 0 auto;
  }
`;

export const SkeletonAnimatedGradient = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 40%,
      rgba(255, 255, 255, 1) 60%,
      rgba(255, 255, 255, 0)
    )
    100%;
  transform: translateX(-100%);
  animation: 0.9s ${GradientSlide} ease-out infinite;

  &.transparent {
    background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.06) 40%,
        rgba(0, 0, 0, 0.06) 60%,
        rgba(0, 0, 0, 0)
      )
      100%;
  }

  &.invert {
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.06) 40%,
        rgba(255, 255, 255, 0.06) 60%,
        rgba(255, 255, 255, 0)
      )
      100%;
  }
`;
