import React from "react";
import { Container, Initials } from "./Avatar.styles";
import { DataTestIds } from "src/util/testing-util/test-utils";
import { ThemeColor } from "src/theme/theme";

const MAX_INITIALS_LENGTH = 5;

export type AvatarProps = {
  size: number;
  shadow?: boolean | false;
  textColor?: ThemeColor;
  color?: ThemeColor;
  image?: string | undefined;
  fitImage?: boolean | false;
  name?: string | undefined;
  outline?: boolean | false;
  onClick?: () => void;
  title?: string | undefined;
};

export type InitialsProps = {
  textColor?: ThemeColor;
  color?: ThemeColor;
  outline?: boolean | false;
};

function createInitials(name: string): string {
  const text = name.replace(/(\s(jr|sr|iii|ii|iv)[\W]?)/gi, "");
  const nameInitials = text.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), "");

  return nameInitials;
}

export function Avatar(props: AvatarProps): JSX.Element {
  const userInitials = createInitials(props.name || "");
  const initialsLength = userInitials.length > MAX_INITIALS_LENGTH ? MAX_INITIALS_LENGTH : userInitials.length;
  const dynamicFontSize = `min(calc((${props.size} / ${initialsLength}) * 0.065rem), calc(${props.size} * 0.025rem))`;

  if (props.image) {
    return (
      <Container size={props.size} shadow={props.shadow} color={props.color} fitImage={props.fitImage} onClick={props.onClick} data-testid={DataTestIds.AVATAR}>
        <img src={props.image} alt={props.name} data-testid={DataTestIds.AVATAR_IMAGE} />
      </Container>
    );
  }

  return (
    <Container
      size={props.size}
      shadow={props.shadow}
      color={props.color}
      outline={props.outline}
      onClick={props.onClick}
      data-testid={DataTestIds.AVATAR}
      title={props.title}
    >
      <Initials style={{ fontSize: dynamicFontSize }} color={props.color} outline={props.outline} textColor={props.textColor}>
        {userInitials.substr(0, MAX_INITIALS_LENGTH)}
      </Initials>
    </Container>
  );
}
