import styled from "styled-components";

import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

export const TermsSheetDescription = styled.div`
  color: ${colors[ColorNames.MIDNIGHT].hex};
  ${fonts.PARAGRAPH_S}
  letter-spacing: 0.02em;
  font-feature-settings: "tnum" on, "lnum" on;

  h1 {
    font-size: 26px;
    line-height: 120%;
  }

  h2 {
    font-size: 20px;
    margin: 30px 0 10px 0;
    line-height: 120%;
  }

  ol {
    list-style-position: inside;
    margin: 0;
    padding: 0;
  }

  ul {
    list-style-type: square;
    margin: 15px;
  }

  p,
  li {
    margin-bottom: 10px;
  }

  hr {
    margin: 30px 0;
    border: 0.5px solid ${colors[ColorNames.GRAY2].hex};
  }

  .terms-divider {
    margin: 30px 0;
    border: 3px solid ${colors[ColorNames.GRAY1].hex};
  }

  button {
    margin-top: 50px;
  }
`;

export const ScrollText = styled.div`
  position: sticky;
  top: -35px;
  margin: -35px -30px 0;
  padding: 30px;
  background-color: rgba(${colors[ColorNames.WHITE].rgb}, 0.9);
  backdrop-filter: blur(8px);
  color: ${colors[ColorNames.GRAY5].hex};
  ${fonts.PARAGRAPH_M}
  text-align: center;
  border-top-left-radius: 40px;
`;
