import styled, { css, keyframes, FlattenSimpleInterpolation } from "styled-components";
import { ReactComponent as Circle } from "src/assets/circle.svg";
import { ReactComponent as Triangle } from "src/assets/triangle.svg";
import { ReactComponent as Square } from "src/assets/square.svg";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";
import { InfoGroup } from "src/routes/app/bank/accounts/Accounts.styles";

export const StyledWelcomeContent = styled.div`
  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
  flex: 1 1 0%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledInviteWelcomeContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledWelcomeMessage = styled.div`
  flex: 1 1 0%;
  width: 100%;
  position: relative;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${fonts.HEADING_2}

  .gray {
    color: ${colors[ColorNames.GRAY5].hex};
    ${fonts.HEADING_2}
  }
`;

export const StyledInviteWelcomeMessage = styled(StyledWelcomeMessage)`
  padding: 100px 40px;
`;

export const StyledWelcomeActionSheet = styled.div`
  position: relative;
  width: 100%;
  max-height: 100%;
  padding: 35px 30px;
  position: relative;
  background-color: rgba(${colors[ColorNames.GRAY1].rgb}, 0.75);
  border-top-left-radius: 40px;
  z-index: 3;

  @media (min-width: 768px) {
    border-top-right-radius: 40px;
  }

  ${InfoGroup} {
    background: transparent;
    border-radius: 0;
    padding: 0;
    margin: 0 -10px;
  }
`;

export const move1 = keyframes`
  from {
		transform: rotate(10deg) translate(-30px) rotate(0deg);
	}
	to {
		transform: rotate(-350deg) translate(-30px) rotate(-360deg);
	}
`;

export const move2 = keyframes`
  from {
		transform: rotate(0deg) translate(-60px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translate(-60px) rotate(-360deg);
	}
`;

export const move3 = keyframes`
  from {
		transform: rotate(-15deg) translate(-60px) rotate(0deg);
	}
	to {
		transform: rotate(-15deg) translate(-60px) rotate(360deg);
	}
`;

export const move4 = keyframes`
  from {
		transform: rotate(45deg) translate(-20px) rotate(0deg);
	}
	to {
		transform: rotate(-315deg) translate(-20px) rotate(360deg);
	}
`;

interface BackgroundImageProps {
  size: "small" | "large";
}

export const StyledWelcomeShapes = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  filter: blur(14px);
  opacity: 0.8;
  z-index: -1;
`;

export const StyledCircle = styled(Circle)<BackgroundImageProps>`
  position: absolute;

  ${({ size }): FlattenSimpleInterpolation | undefined => {
    switch (size) {
      case "large":
        return css`
          width: 118px;
          height: 118px;
          animation: ${move1} 14s linear infinite;
          circle {
            stroke: ${colors[ColorNames.PINE].hex};
            stroke-width: 0.5px;
            fill: transparent;
          }
        `;
      case "small":
        return css`
          width: 70px;
          height: 70px;
          animation: ${move2} 18s linear infinite;
          circle {
            fill: ${colors[ColorNames.PINE].hex};
            fill-opacity: 1;
          }
        `;
    }
  }}
`;

export const StyledTriangle = styled(Triangle)<BackgroundImageProps>`
  position: absolute;

  ${({ size }): FlattenSimpleInterpolation | undefined => {
    switch (size) {
      case "large":
        return css`
          width: 107px;
          height: 107px;
          animation: ${move3} 22s linear infinite;
          path {
            stroke: ${colors[ColorNames.LAKE].hex};
            stroke-width: 0.5px;
            fill: transparent;
          }
        `;
      case "small":
        return css`
          width: 54px;
          height: 54px;
          animation: ${move4} 16s linear infinite;
          path {
            fill: ${colors[ColorNames.LAKE].hex};
            fill-opacity: 1;
          }
        `;
    }
  }}
`;

export const StyledSquare = styled(Square)<BackgroundImageProps>`
  position: absolute;

  ${({ size }): FlattenSimpleInterpolation | undefined => {
    switch (size) {
      case "large":
        return css`
          width: 78px;
          height: 78px;
          animation: ${move1} 28s linear infinite;
          rect {
            stroke: ${colors[ColorNames.BUBBLE_GUM].hex};
            stroke-width: 0.5px;
            fill: transparent;
          }
        `;
      case "small":
        return css`
          width: 35px;
          height: 35px;
          animation: ${move4} 16s linear infinite;
          rect {
            fill: ${colors[ColorNames.BUBBLE_GUM].hex};
            fill-opacity: 1;
          }
        `;
    }
  }}
`;
