import React, { useEffect, useRef } from "react";
import classnames from "classnames";
import { useReactiveVar } from "@apollo/client";
import { useRecoilValue } from "recoil";
import { showMenuState } from "src/store/menuState";
import { navigationState } from "src/store/navigationState";
import { coreNavbarState } from "src/util/useNavbar";
import { coreLayoutBodyBackgroundColor, coreLayoutScrollYPosition } from "src/store/layoutState";
import Navbar from "../nav-bars/Navbar";
import Subnav from "../nav-bars/Subnav";
import { CoreLayoutNav, CoreLayoutNavInner } from "./CoreLayout.styles";

const CoreNav = () => {
  const nav = useRecoilValue(coreNavbarState);
  const menu = useRecoilValue(showMenuState);
  const coreBodyLayoutBG = useReactiveVar(coreLayoutBodyBackgroundColor);
  const coreScrollY = useReactiveVar(coreLayoutScrollYPosition);
  const scrollTimeout = useRef<number | null>(null);
  const notAtTop = coreScrollY >= 20;
  const navigation = useReactiveVar(navigationState);

  useEffect(() => {
    const handleScroll = () => {
      if (!scrollTimeout.current) {
        scrollTimeout.current = window.setTimeout(() => {
          coreLayoutScrollYPosition(window.scrollY);
          !!scrollTimeout.current && clearTimeout(scrollTimeout.current);
          scrollTimeout.current = null;
        }, 50);
      }
    };

    window.addEventListener("scroll", handleScroll, true);
    return () => window.removeEventListener("scroll", handleScroll, true);
  }, []);

  return (
    <CoreLayoutNav className={classnames({ menu })}>
      <CoreLayoutNavInner backgroundColor={coreBodyLayoutBG} className={classnames({ notAtTop, isTransitioning: navigation.transitioning, disableNav: nav.hidden, transparent: nav.transparent })}>
        <Navbar />
        <Subnav />
      </CoreLayoutNavInner>
    </CoreLayoutNav>
  );
};

export default CoreNav;
