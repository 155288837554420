import styled from "styled-components";
import { colorPalette as colors, ColorNames } from "src/theme/theme";
import { StyledAuthContainer } from "src/pages/auth/Auth.styles";
import { TermsCopy } from "src/routes/app/onboarding/Onboarding.styles";

export const BusinessOwnersList = styled.div`
  padding: 0 30px;
`;

export const BusinessTermsCopy = styled(TermsCopy)`
  color: ${colors[ColorNames.MIDNIGHT].hex};
  margin: 10px 40px 30px;
  text-align: center;
`;

export const AdditionalBusinessOwnersRows = styled.div`
  padding-left: 20px;
  margin-bottom: 30px;
`;

export const BusinessOwnersFormSection = styled.div`
  background: linear-gradient(
    to bottom,
    rgb(${colors[ColorNames.GRAY2].rgb}, 0.5),
    rgba(${colors[ColorNames.GRAY2].rgb}, 0) 70px
  );
  min-height: 70px;
  padding: 0 30px 30px;

  fieldset {
    margin-bottom: 40px;
  }
`;

export const AddBusinessOwnerPage = styled.div`
  padding: 0 30px 30px;

  fieldset {
    margin-top: 0;
  }
`;
