import React from "react";
import { AccountBalancesGrouped } from "src/generated/client";
import { decorateCurrencyValue } from "src/util/stringUtils";

type Props = {
  availableBalance: string | null;
  currentBalance?: AccountBalancesGrouped | null;
  prefix?: string;
};

export const BalanceAmount = ({ availableBalance, currentBalance, prefix }: Props) => {
  const decorated = currentBalance
    ? decorateCurrencyValue(currentBalance?.balance.display)
    : decorateCurrencyValue(availableBalance ?? undefined);

  const [balanceDollars, balanceCents] = decorated.split(".");

  return (
    <>
      {prefix}
      {`${balanceDollars}.`}
      <sup className="cents-sup">{balanceCents}</sup>
    </>
  );
};
