import { ActionFunction, LoaderFunction } from "react-router-dom";
import * as Yup from "yup";
import { addEmailToAuctionWaitlist, getUpcomingTetonRidgeAuctions } from "src/services/lines";
import { getFormValidationErrors } from "src/util/forms";
import { RouteActionResponse } from "src";

const AUCTION_DAY_THRESHOLD = 28;

export const tetonRidgeAuctionsLoader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const includePast = url.searchParams.has("includePast");
  try {
    return await getUpcomingTetonRidgeAuctions(includePast);
  } catch (e) {
    throw e;
  }
};

export const tetonRidgeAuctionsAction: ActionFunction = async ({ request }) => {
  const data = Object.fromEntries(await request.formData());

  if (request.method === "PUT") {
    const startDate = data.auctionStartDate as string;
    const endDate = data.auctionEndDate as string;
    const cutOffDate = new Date(data.auctionCutOffDate as string);

    const daysAway = Math.round((new Date(startDate).getTime() - new Date().getTime()) / 86400000);

    const response: RouteActionResponse<unknown> = {};
    if (daysAway > AUCTION_DAY_THRESHOLD) {
      response.validationErrors = {
        auctionDate: "early"
      };
    } else if (new Date() > cutOffDate) {
      response.validationErrors = {
        cutOffDate: "expired"
      };
    } else {
      response.response = {
        success: true,
        startDate: startDate,
        endDate: endDate
      };
    }

    return response;
  } else {
    const schema: Yup.ObjectSchema<object> = Yup.object().shape({
      email: Yup.string().email("Please use a valid email address.").required("Please enter an email address.")
    });

    try {
      await schema.validate(data, { abortEarly: false });
      const response = await addEmailToAuctionWaitlist({
        email: data.email as string,
        upcomingAuctions: [
          {
            date: {
              start: data.auctionStartDate as string,
              end: data.auctionEndDate as string
            },
            location: data.auctionLocation as string
          }
        ]
      });
      return { response };
    } catch (error) {
      const response: RouteActionResponse<unknown> = {};
      if (error instanceof Yup.ValidationError) {
        response.validationErrors = getFormValidationErrors(error);
      } else {
        response.error = error;
      }

      return response;
    }
  }
};
