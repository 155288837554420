import React, { useEffect, useState, useRef, useCallback } from "react";
import { createPortal } from "react-dom";
import useTeller from "src/util/useTeller";
import usePlaid from "src/util/usePlaid";

export type Provider = "Teller" | "Plaid";

type onReadyCallback = ({ teller, plaid }: { teller: boolean; plaid: boolean }) => void;

const Providers = ({
  mainWindow,
  selectedProvider,
  onReady,
  onSuccess,
  plaidUpdateToken
}: {
  mainWindow: Window;
  selectedProvider?: Provider;
  onReady: onReadyCallback;
  onSuccess: () => void;
  plaidUpdateToken?: string;
}) => {
  const { teller } = useTeller({ TellerConnect: mainWindow.TellerConnect, onSuccess });
  const { plaid } = usePlaid({ Plaid: mainWindow.Plaid, onSuccess, plaidUpdateToken });

  const openPlaid = useCallback(() => {
    plaid.open();
  }, [plaid]);

  const openTeller = useCallback(() => {
    !!plaid && plaid.exit({ force: true });
    teller.open();
  }, [plaid, teller]);

  useEffect(() => {
    onReady({ teller: !!teller, plaid: !!plaid });

    if (selectedProvider === "Plaid") {
      if (!!plaid) openPlaid();
    } else if (selectedProvider === "Teller") {
      if (!!teller) openTeller();
    }
  }, [teller, plaid, selectedProvider, openPlaid, openTeller, onReady]);

  return <></>;
};

const scriptUrls = [
  "https://cdn.teller.io/connect/connect.js",
  "https://cdn.plaid.com/link/v2/stable/link-initialize.js"
];

const ProvidersIframe = ({
  provider,
  onReady,
  onSuccess,
  plaidUpdateToken
}: {
  provider?: Provider;
  onReady: onReadyCallback;
  onSuccess: () => void;
  plaidUpdateToken?: string;
}) => {
  const didLoad = useRef<boolean>(false);
  const contentRef = useRef<HTMLIFrameElement>(null);
  const [scriptsLoaded, setScriptsLoaded] = useState(false);

  useEffect(() => {
    const iframe = contentRef.current;
    iframe?.addEventListener("load", loadProviders);

    return () => iframe?.removeEventListener("load", loadProviders);
  }, []);

  const loadProviders = () => {
    if (didLoad.current) return;
    const mountNode = contentRef.current?.contentWindow?.document;

    if (!!mountNode) {
      let loaded = 0;
      scriptUrls.forEach((url) => {
        const script: HTMLScriptElement = mountNode.createElement("script");
        script.src = url;
        script.async = true;
        script.addEventListener("load", () => {
          loaded += 1;
          if (loaded === scriptUrls.length) {
            setScriptsLoaded(true);
          }
        });

        mountNode.body.appendChild(script);
      });

      didLoad.current = true;
    }
  };

  return (
    <iframe
      width="100%"
      height="100%"
      style={{ border: "none", flexGrow: "1" }}
      id="providers-iframe"
      title="providers-iframe"
      src="/iframe.html"
      ref={contentRef}
    >
      {scriptsLoaded &&
        contentRef.current &&
        contentRef.current.contentWindow &&
        createPortal(
          <Providers
            mainWindow={contentRef.current.contentWindow}
            selectedProvider={provider}
            onReady={onReady}
            onSuccess={onSuccess}
            plaidUpdateToken={plaidUpdateToken}
          />,
          contentRef.current.contentWindow.document.body
        )}
    </iframe>
  );
};

export default ProvidersIframe;
