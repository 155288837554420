import styled from "styled-components";

import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

export const AboutSheetLogo = styled.img`
  width: 72px;
  height: 72px;
  object-fit: cover;
  display: block;
  margin: 0 auto 32px;
`;

export const AboutSheetTitle = styled.p`
  width: 100%;
  ${fonts.HEADING_4}
  color: ${colors[ColorNames.MIDNIGHT].hex};
  margin-bottom: 32px;
  text-align: center;
`;

export const AboutSheetDescription = styled.p`
  width: 100%;
  ${fonts.PARAGRAPH_M}
  color: ${colors[ColorNames.MIDNIGHT].hex};
  margin-bottom: 48px;
  white-space: pre-wrap;
  text-align: center;
`;
