import styled, { keyframes, css, FlattenSimpleInterpolation } from "styled-components/macro";
import { colorPalette as colors, ColorNames, rgbGradientColors, dropShadows, ShadowNames, borderRadiusOuter, fonts, BackgroundImageSize, borderRadius } from "src/theme/theme";

import { StyledBadge } from "src/components/badge/Badge.styles";
import { BalanceLabel } from "src/routes/app/bank/accounts/Accounts.styles";
import { StyledRingCallout } from "src/components/ring-callout/RingCallout.styles";
import { LoadingBox } from "src/components/loading-container/LoadingContainer.styles";
import { BannerNoticeContainer } from "src/components/alert/Alerts.styles";
import { ReactComponent as Triangle } from "src/assets/triangle.svg";

export const GenericWrapper = styled.div`
  padding: 0 30px;
`;

export const DashboardStatCardAmount = styled.div`
  text-align: left;
  padding: 0 10px;
  margin-bottom: 30px;

  ${BalanceLabel} {
    opacity: 0.6;
  }
`;

export const WelcomeContent = styled.div`
  flex: 1 1 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const WelcomeMessage = styled.div`
  flex: 1 1 0%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 40px;
  text-align: center;
  color: ${colors[ColorNames.OCEAN].hex};
  ${fonts.PARAGRAPH_L}

  .body {
    margin-top: 24px;
    color: ${colors[ColorNames.MIDNIGHT].hex};
    ${fonts.PARAGRAPH_S}
  }
`;

export const WelcomeActionSheet = styled.div`
  position: relative;
  width: 100%;
  max-height: 100%;
  padding: 35px 30px;
  position: relative;
  background-color: rgba(${colors[ColorNames.GRAY1].rgb}, 0.75);
  border-top-left-radius: 40px;
  z-index: 3;
  ${fonts.PARAGRAPH_S}
  text-align: center;

  @media (min-width: 768px) {
    border-top-right-radius: 40px;
  }

  .large {
    ${fonts.PARAGRAPH_L}
    margin-bottom: 24px;
    text-align: center;
  }

  button {
    margin-top: 40px;
  }
`;

export const WelcomeShapes = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  filter: blur(40px);
  opacity: 0.7;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
`;

const floatUp = keyframes`
  from {
    top: 100%;
    transform: translateY(0%);
  }
  to { 
    top: 0%;
    transform: translateY(-100%);
  }
`;

export const StyledTriangle = styled(Triangle)<{ size: BackgroundImageSize }>`
  position: absolute;
  path {
    fill: ${colors[ColorNames.OCEAN].hex};
    fill-opacity: 1;
  }

  ${({ size }): FlattenSimpleInterpolation | undefined => {
    switch (size) {
      case BackgroundImageSize.LARGE:
        return css`
          width: 448px;
          height: 448px;
          right: -20%;
          animation: ${floatUp} 25s linear -12s infinite;

          @media (max-width: 768px) {
            width: 70vw;
            height: 70vw;
          }
        `;
      case BackgroundImageSize.SMALL:
        return css`
          width: 250px;
          height: 250px;
          left: -10%;
          animation: ${floatUp} 18s linear infinite;

          @media (max-width: 768px) {
            width: 40vw;
            height: 40vw;
          }
        `;
    }
  }}
`;

export const QualifyContent = styled(GenericWrapper)`
  flex: 1 1 0px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;

  .description {
    ${fonts.PARAGRAPH_S}
    text-align: center;
  }

  .modal-container {
    margin: 40px 0;

    & > * {
      margin-bottom: 30px;
    }
  }

  button {
    margin-top: auto;
  }

  ${StyledRingCallout}, ${BannerNoticeContainer} {
    margin-top: auto;

    & + button {
      margin-top: 30px;
    }
  }
`;

export const QualifyCaption = styled.p<{ gray?: boolean; large?: boolean }>`
  text-align: center;
  color: ${({ gray }): string => gray ? colors[ColorNames.GRAY5].hex : colors[ColorNames.MIDNIGHT].hex};
  margin-bottom: 2em;

  ${({ large }): FlattenSimpleInterpolation | undefined => !!large ? fonts.PARAGRAPH_L : fonts.PARAGRAPH_M}

  &:last-child {
    margin-bottom: 0;
  }
`;

export const BorrowCardWrapper = styled.div`
  padding: 26px 20px 20px;
  background-color: ${colors[ColorNames.WHITE].hex};
  border-radius: ${borderRadiusOuter};
  box-shadow: ${dropShadows[ShadowNames.EXTRA_LIGHT]};
  margin-bottom: 30px;

  button {
    margin-top: 20px;
  }
`;

export const BorrowCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;

  ${StyledBadge} {
    flex: 0 0 auto;
  }
`;

export const BorrowCardTitle = styled.div`
  flex: 1 1 0px;
  ${fonts.HEADING_5}
`;

export const BorrowCardDescription = styled.p`
  color: ${colors[ColorNames.GRAY5].hex};
  ${fonts.PARAGRAPH_S}
  text-align: center;
  margin-bottom: 20px;
`;

export const LinesEmptyMessage = styled.p`
  text-align: center;
  margin-top: 20px;
  ${fonts.PARAGRAPH_S}
`;

export const InsuranceSheetTitle = styled.h3`
  display: block;
  text-align: center;
  ${fonts.HEADING_4}
  color: ${colors[ColorNames.MIDNIGHT].hex};
  margin-bottom: 1em;
`;

export const InsuranceSheetDescription = styled.p`
  ${fonts.PARAGRAPH_S}
  text-align: center;
  margin-bottom: 40px;
`;

export const InsuranceTypeButton = styled.button`
  width: 100%;
  border: none;
  outline: none;
  background: ${colors[ColorNames.WHITE].hex};
  border-radius: ${borderRadius};
  box-shadow: ${dropShadows[ShadowNames.LIGHT]};
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 66px;
  align-items: center;
  text-align: left;
  margin-bottom: 20px;
  transition: 0.15s ease all;
  position: relative;
  cursor: pointer;

  &:active {
    transform: scale(0.98);
  }

  .body-text-area {
    padding: 20px 25px;
  }

  h4 {
    ${fonts.PARAGRAPH_M}
    margin-bottom: 6px;
    color: ${colors[ColorNames.MIDNIGHT].hex};
  }

  p {
    ${fonts.PARAGRAPH_S}
    color: ${colors[ColorNames.GRAY5].hex};
  }

  .icon-area {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }

  .teton-ridge {
    background-image: linear-gradient(
      180deg,
      rgb(${rgbGradientColors.lake[0]}) 0%,
      rgb(${rgbGradientColors.lake[1]}) 100%
    );
  }

  .upload {
    background-image: linear-gradient(
      180deg,
      rgb(${rgbGradientColors.midnight[0]}) 0%,
      rgb(${rgbGradientColors.midnight[1]}) 100%
    );
  }
`;

export const LoanDocSheetContainer = styled.div`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  padding-top: 64px;
  overflow: hidden;
`;

export const LoanDocSheetHeader = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  flex: 0 0 auto;
  height: 64px;
  border-bottom: 1px solid ${colors[ColorNames.GRAY2].hex};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  ${fonts.PARAGRAPH_M}

  svg {
    cursor: pointer;
  }
`;

export const FinalizeContainer = styled.div`
  flex: 1 1 0%;
  width: 100%;
  padding: 0 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  form, .form {
    justify-content: flex-start;
    gap: 20px;

    &.form {
      flex: 2;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  button[type="submit"], button.submit, ${LoadingBox} {
    margin-top: auto;

    &${LoadingBox} {
      flex: 0 0 auto;
    }
  }
`;