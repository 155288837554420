import React from "react";
import { useReactiveVar } from "@apollo/client";
import { useRecoilValue, useRecoilState } from "recoil";
import { motion, AnimatePresence } from "framer-motion";
import useNavigate from "src/util/useNavigate";
import { showMenuState } from "src/store/menuState";
import { coreNavbarState } from "src/util/useNavbar";
import { coreSubnavState } from "src/util/useSubnav";
import { coreLayoutScrollYPosition } from "src/store/layoutState";
import { OrgSwitcher } from "../org-switcher/OrgSwitcher";
import { NavBarStepper } from "src/components/nav-bars/nav-bar-stepper/NavBarStepper";
import { CypressTestIds, DataTestIds } from "src/util/testing-util/test-utils";

import { ReactComponent as MenuIcon } from "src/assets/icons/menu.svg";
import { ReactComponent as CloseIcon } from "src/assets/icons/close.svg";
import { ReactComponent as LogoIcon } from "src/assets/filled-logo.svg";
import { ReactComponent as BackIcon } from "src/assets/icons/arrow_left.svg";
import classnames from "classnames";
import {
  NavbarWrapper,
  NavbarBox,
  NavbarLeft,
  BackButton,
  MenuButton,
  NavbarCenter,
  NavbarRight,
  NavbarRightAction,
  NavTitle
} from "./Navbar.styles";
import { ThemeColor } from "src/theme/theme";

type NavbarBoxProps = {
  backgroundColor?: ThemeColor;
};

export default function Navbar({ backgroundColor }: NavbarBoxProps) {
  const nav = useRecoilValue(coreNavbarState);
  const subnav = useRecoilValue(coreSubnavState);
  const coreScrollY = useReactiveVar(coreLayoutScrollYPosition);
  const hasTabs = subnav.length > 0;
  const hasMenu = nav.menu && !nav.back && !nav.backAction && !nav.persistedBackAction;
  const hasBack = nav.back || !!nav.backAction || !!nav.persistedBackAction;
  const hasTitle = nav.title !== "";
  const bgColor = nav.backgroundColor ? nav.backgroundColor : backgroundColor;
  const hasStepper = nav.stepper;
  const hasLogo = nav.logo;
  const disableNav = nav.hidden;
  const notAtTop = coreScrollY >= 20;
  const [menu, setMenu] = useRecoilState(showMenuState);
  const navigate = useNavigate();

  return (
    <>
      <NavbarWrapper className={classnames({ hasTabs, "invert-colors": nav.invertColors, hasBack })}>
        <NavbarBox
          backgroundColor={bgColor}
          className={classnames({ disableNav, notAtTop })}
        >
          <NavbarLeft>
            {hasBack && (
              <BackButton
                onClick={() =>
                  !!nav.persistedBackAction
                    ? nav.persistedBackAction()
                    : !!nav.backAction
                    ? nav.backAction()
                    : navigate("back")
                }
                initial={{
                  opacity: 0,
                  scale: 0.25
                }}
                animate={{
                  opacity: 1,
                  scale: 1
                }}
              >
                <BackIcon />
              </BackButton>
            )}
            {hasMenu && (
              <MenuButton
                data-testid={DataTestIds.MENU_NAV_MENU}
                data-cy={CypressTestIds.Q_NAV_MENU_BTN}
                onClick={() => {
                  setMenu(!menu);
                }}
                initial={{
                  opacity: 0,
                  scale: 0.25
                }}
                animate={{
                  opacity: 1,
                  scale: 1
                }}
              >
                {menu ? <CloseIcon height="20" width="20" /> : <MenuIcon height="20" width="20" />}
              </MenuButton>
            )}
          </NavbarLeft>
          <NavbarCenter>
            <AnimatePresence initial={false}>
              {hasTitle && !hasLogo && !hasStepper && (
                <NavTitle
                  key={nav.title}
                  initial={{
                    opacity: 0,
                    x: 20
                  }}
                  animate={{
                    opacity: 1,
                    x: 0
                  }}
                  exit={{
                    opacity: 0,
                    x: -20
                  }}
                  transition={{
                    duration: 0.25
                  }}
                >
                  <span>{nav.title}</span>
                </NavTitle>
              )}
              {hasLogo && (
                <motion.div
                  initial={{
                    opacity: 0,
                    scale: 0.25
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1
                  }}
                >
                  <LogoIcon height="27" width="24" data-testid={DataTestIds.MENU_NAV_LOGO} />
                </motion.div>
              )}
              {hasStepper && <NavBarStepper step={nav.step || 0} totalSteps={nav.totalSteps || 0} />}
            </AnimatePresence>
          </NavbarCenter>
          <NavbarRight>
            {nav.orgSwitcher && !disableNav && <OrgSwitcher />}
            {!nav.orgSwitcher && !disableNav && !!nav.rightIcon && (
              <NavbarRightAction onClick={nav.rightAction}>{nav.rightIcon}</NavbarRightAction>
            )}
          </NavbarRight>
        </NavbarBox>
      </NavbarWrapper>
    </>
  );
}
