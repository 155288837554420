import React, { useMemo } from "react";
import { motion, Variants } from "framer-motion";
import { useCurrentUser } from "src/services/users";
import { colorPalette as colors, ColorNames } from "src/theme/theme";
import { Button } from "src/components/button/Button";
import {
  Row,
  RowContent,
  RowLabel,
  SuccessHeader,
  TransferContentContainer,
  TransferSuccessPage,
  TransferSummary,
  TwoPartyHeader
} from "./Transfer.styles";
import { Avatar } from "src/components/avatar/Avatar";
import { AccountType } from "src/generated/client";
import { capitalizeTransferType, lastFour } from "../../../../util/stringUtils";

interface TransferAccountType {
  nickname: string;
  balance?: { amount: number; display: string };
  externalAccountNumber?: string;
  externalRoutingNumber?: string;
  formattedBalance?: string;
  last4?: string;
  type?: string;
}

type TransferSuccessProps = {
  date: string;
  amount: string;
  buttonLabel: string;
  onDismiss: () => void;
  sourceAccount?: TransferAccountType;
  targetAccount?: TransferAccountType;
  recipient?: any;
  variants: Variants;
  custom: number;
};

const TransferSuccess = (props: TransferSuccessProps): JSX.Element => {
  const currentUser = useCurrentUser();
  const { date, amount, buttonLabel, onDismiss, sourceAccount, targetAccount, recipient, variants, custom } = props;
  const internalTransfer =
    sourceAccount?.type === AccountType.UnitDeposit && targetAccount?.type === AccountType.UnitDeposit;
  const internalTransferType = internalTransfer ? "Letter Transfer" : "ACH";
  const hasSelectedRecipient = recipient?.accountHolderName;
  const fundsAvailable = internalTransfer
    ? "within few minutes"
    : targetAccount?.type && targetAccount?.type.toLowerCase() === "wire"
    ? "1 Business Day"
    : "3-4 Business Days";

  const renderHeader = useMemo(() => {
    const currentOrg = currentUser?.organizations.find((item) => item.currentContext === true);
    const senderName =
      currentOrg?.name && currentOrg?.name.length > 0
        ? currentOrg.name
        : `${currentUser?.firstName} ${currentUser?.lastName}`;

    if (hasSelectedRecipient) {
      return (
        <SuccessHeader>
          <TwoPartyHeader>
            <Avatar size={30} name={senderName} />
            <Dots />
            <CheckIcon size={60} />
            <Dots />
            <Avatar size={30} name={recipient?.nickname} />
          </TwoPartyHeader>
          <h2>
            You sent ${amount} to {recipient?.nickname}
          </h2>
        </SuccessHeader>
      );
    } else {
      return (
        <SuccessHeader>
          <CheckIcon size={60} />
          <h2>
            Transfer initiated
            <br />${amount}
          </h2>
        </SuccessHeader>
      );
    }
  }, [hasSelectedRecipient, currentUser, recipient, amount]);

  return (
    <TransferSuccessPage variants={variants} custom={custom} initial="enter" animate="center" exit="exit">
      <TransferContentContainer className="success">
        {renderHeader}

        <TransferSummary>
          <Row>
            <RowLabel>Sent to</RowLabel>
            {hasSelectedRecipient ? (
              <RowContent>
                {recipient?.nickname}
                <small>•••• {lastFour(recipient?.accountNumber)}</small>
              </RowContent>
            ) : (
              <RowContent>
                {targetAccount?.nickname}
                <small>•••• {targetAccount?.last4}</small>
              </RowContent>
            )}
          </Row>
          <Row>
            <RowLabel>Funded from</RowLabel>

            <RowContent>
              {sourceAccount?.nickname}
              <small>•••• {sourceAccount?.last4}</small>
            </RowContent>
          </Row>
          <Row>
            <RowLabel>Amount</RowLabel>
            <RowContent>${amount}</RowContent>
          </Row>
          <Row>
            <RowLabel>Transfer date</RowLabel>
            <RowContent>{date}</RowContent>
          </Row>
          <Row>
            <RowLabel>Sent via</RowLabel>
            <RowContent>
              {!hasSelectedRecipient && internalTransferType}
              {hasSelectedRecipient && capitalizeTransferType(recipient?.transferType)}
            </RowContent>
          </Row>
          <Row>
            <RowLabel>Funds available</RowLabel>
            <RowContent>{fundsAvailable}</RowContent>
          </Row>
        </TransferSummary>

        <Button raised type="submit" color={ColorNames.MIDNIGHT} onClick={onDismiss}>
          {buttonLabel}
        </Button>
      </TransferContentContainer>
    </TransferSuccessPage>
  );
};

export default TransferSuccess;

const CheckIcon = (props: { size: number }) => (
  <motion.div
    initial={{
      opacity: 0,
      scale: 0.5
    }}
    animate={{
      opacity: 1,
      scale: [0.5, 1.25, 1],
      transition: {
        delay: 0.5,
        duration: 0.35
      }
    }}
  >
    <svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size}>
      <circle cx="30" cy="30" r="30" fill={colors[ColorNames.MINT].hex} />
      <path fill={colors[ColorNames.MINT].hex} d="M14 14h32v32H14z" />
      <path
        d="M44.4 18.8L24.398 41.2 15.6 31.33"
        stroke={colors[ColorNames.GRASS].hex}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </motion.div>
);

const Dots = () => (
  <svg viewBox="0 0 26 2" fill="none" xmlns="http://www.w3.org/2000/svg" width={26} height={2}>
    <circle cx="1" cy="1" r="1" fill={colors[ColorNames.GRASS].hex} />
    <circle cx="13" cy="1" r="1" fill={colors[ColorNames.GRASS].hex} />
    <circle cx="25" cy="1" r="1" fill={colors[ColorNames.GRASS].hex} />
  </svg>
);
