import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { StyledForm, StyledFormInputContainer } from "src/components/forms";
import { Input } from "src/components/forms/input/Input";
import { decorateCurrencyValue } from "src/util/stringUtils";

import {
  AccountChart,
  AccountDetailsList,
  AmountDollars,
  BalanceAmountHeader,
  BalanceLabel
} from "src/routes/app/bank/accounts/Accounts.styles";
import {
  useGetConnectedAccountBalancesById,
  useGetConnectedAccountById,
  useUpdateConnectedAccount,
  useDeleteConnectedAccount,
  useGetConnectedAccounts
} from "src/services/connected-accounts";
import { AccountBalancesGrouped, BalancesTimeframe } from "src/generated/client";
import { useBalances } from "src/services/organizations";

import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { ActionableButtonGroup } from "src/components/actionable-button/ActionableButton.styles";
import { BalanceGraph } from "src/components/balance-graph/BalanceGraph";
import { colorPalette as colors, ColorNames } from "src/theme/theme";

import { ReactComponent as IconTransfer } from "src/assets/icons/transfer.svg";
import { ReactComponent as IconFiles } from "src/assets/icons/files.svg";
import { DetailRows, DetailRow } from "src/components/detail-row/DetailRow";
import { TimeRangePicker } from "src/components/timerange-picker/TimeRangePicker";
import { BalanceAmount } from "src/components/balance-amount/BalanceAmount";

import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { showNotice } from "src/store/alertState";
import useNavigate from "src/util/useNavigate";
import { NavRoutes } from "src/routes/navRoutes";
import { formatBalancePeriod } from "src/util/stringUtils";
import { useNavbar } from "src/util/useNavbar";
import Layout from "src/components/core-layout/Layout";
import { useSetRecoilState } from "recoil";
import { expectedNewConnectedAccountsDataState } from "src/store/connectedAccountsState";

type FormFields = { accountName: string };

const UpdateAccountSchema: Yup.ObjectSchema<FormFields> = Yup.object().shape({
  accountName: Yup.string()
    .min(2, "Please use a longer name.")
    .max(64, "Please use a shorter name.")
    .required("Please add a nickname for this account.")
});

const ConnectedAccountDetail = (): JSX.Element => {
  const { updateNavbar } = useNavbar({ back: true });
  const [reveal, setReveal] = useState(false);
  const updateAccountSubmitRef = useRef<HTMLInputElement>(null);
  const [timeRange, setTimeRange] = useState(BalancesTimeframe.LastThirty);
  const { accountId = "" } = useParams();
  const { getBalances, accountBalances, error: accountBalancesError } = useGetConnectedAccountBalancesById();
  const { account, loading, error, refetchAccount } = useGetConnectedAccountById(accountId);
  const { refetchConnectedAccounts } = useGetConnectedAccounts();
  const { refetchBalance } = useBalances();
  const setExpectedNewConnectedAccountsData = useSetRecoilState(expectedNewConnectedAccountsDataState);
  const {
    updateConnectedAccount,
    loading: updateAccountLoading,
    error: updateAccountError
  } = useUpdateConnectedAccount();
  const {
    deleteConnectedAccount,
    loading: loadingDeleteAccount,
    error: errorDeleteAccount
  } = useDeleteConnectedAccount();
  const [currentBalance, onUpdateCurrrentBalance] = useState<AccountBalancesGrouped | null>(null);

  const navigate = useNavigate();
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();

  let kindOfAccountText: string | undefined;
  switch (true) {
    case account && account.type === "credit":
      kindOfAccountText = "Credit";
      break;
    case account && account.type === "depository":
      kindOfAccountText = "Bank";
      if (account?.subType === "checking") {
        kindOfAccountText += " – Checking";
      } else if (account?.subType === "savings") {
        kindOfAccountText += " – Savings";
      }
      break;
  }

  useEffect(() => {
    updateNavbar({ title: account?.nickname || "", back: true });
  }, [account?.nickname, updateNavbar]);

  useEffect(() => {
    if (!accountId) {
      return;
    }

    getBalances(accountId, timeRange);
  }, [getBalances, accountId, timeRange]);

  useEffect(() => {
    if (error) {
      showNotice(error.message || "There was a problem loading this account.", {
        error: true
      });
    }
    if (errorDeleteAccount) {
      hideBottomSheet();
      showNotice(errorDeleteAccount.message || "There was a problem disconnecting this account.", { error: true });
    }
  }, [error]);

  useEffect(() => {
    if (accountBalancesError) {
      showNotice(accountBalancesError.message || "There was a problem loading this accounts balances.", {
        error: true
      });
    }
  }, [accountBalancesError]);

  useEffect(() => {
    if (updateAccountError) {
      showNotice(updateAccountError.message || "There was a problem updating this account.", { error: true });
    }
  }, [updateAccountError]);

  const onUpdate = async (values: FormFields) => {
    const update = await updateConnectedAccount({
      accountId,
      nickname: values.accountName
    });

    if (update) {
      refetchAccount();
      hideBottomSheet();
      showNotice(`Account was updated.`);
    }
  };

  const onDisconnectAccount = async (accountId: string) => {
    try {
      const deleted = await deleteConnectedAccount({ accountId });

      if (deleted) {
        setExpectedNewConnectedAccountsData([]);
        refetchBalance();
        refetchConnectedAccounts();
        hideBottomSheet();
        setTimeout(() => {
          navigate("back");
        }, 1000);
      }
    } catch (error) {
      hideBottomSheet();
      showNotice(error.message || "There was a problem disconnecting this account.", { error: true });
    }
  };

  return (
    <Layout loading={!account || loading}>
      {!!account && (
        <>
          <BalanceAmountHeader>
            <AmountDollars>
              <BalanceAmount
                availableBalance={decorateCurrencyValue(
                  account.availableBalance?.display || account.totalBalance.display
                )}
                currentBalance={currentBalance}
              />
            </AmountDollars>
            <BalanceLabel>
              <div className="label">
                {currentBalance ? formatBalancePeriod(currentBalance.period) : "Available Balance"}
              </div>
              <TimeRangePicker pickerId="time_picker" label="Time Range" value={timeRange} onChange={setTimeRange} />
            </BalanceLabel>
          </BalanceAmountHeader>

          <AccountChart>
            <BalanceGraph
              playheadColor={colors[ColorNames.SEAFOAM].hex}
              lineColor={colors[ColorNames.SEAFOAM].hex}
              data={accountBalances}
              onUpdateCurrrentBalance={onUpdateCurrrentBalance}
            />
          </AccountChart>

          <AccountDetailsList>
            <DetailRows>
              <DetailRow
                label={account.type === "credit" ? "Outstanding Balance" : "Total Balance"}
                data={decorateCurrencyValue(account.totalBalance.display)}
                large
              />
              <DetailRow
                action="edit"
                label="Nickname"
                data={account.nickname || ""}
                large
                onClick={() => showBottomSheet("edit_nickname_sheet")}
              />
              <>
                {account.type !== "credit" && (
                  <>
                    <DetailRow
                      label="Account Number"
                      data={`•••••${account.last4}`}
                      fullData={account.accountNumber || ""}
                      action="copy"
                      reveal={reveal}
                      large
                      onClick={() => setReveal(true)}
                    />
                    <DetailRow
                      label="Routing Number"
                      data={account.routingNumber ? `•••••${account.routingNumber.slice(-4)}` : ""}
                      fullData={account.routingNumber || ""}
                      action="copy"
                      reveal={reveal}
                      large
                      onClick={() => setReveal(true)}
                    />
                  </>
                )}
                {!!kindOfAccountText && <DetailRow label="Account Type" data={kindOfAccountText} large />}
                {!!account.connectedInstitutionName && (
                  <DetailRow label="Institution Name" data={account.connectedInstitutionName} large />
                )}
              </>
            </DetailRows>
          </AccountDetailsList>

          <ActionableButtonGroup>
            <ActionableButton
              label="Transfer funds"
              color={ColorNames.PINE}
              iconLeft={<IconTransfer />}
              onClick={() => navigate(`${NavRoutes.BANK_TRANSFER_SEND}/${account.id}`)}
            />
            {/* <ActionableButton
              label="Transaction history"
              color={ColorNames.MINT}
              iconLeft={<IconFiles />}
              onClick={() => navigate(NavRoutes.BANK_TRANSACTIONS)}
            /> */}
            <ActionableButton
              destructive
              iconRight={false}
              label="Disconnect and Remove Account"
              onClick={() => showBottomSheet("delete_account_sheet")}
            />
          </ActionableButtonGroup>

          <ActionBottomSheet
            id="delete_account_sheet"
            title="Are you sure?"
            description={`You can always reconnect this account at a later time.`}
            actionText="Disconnect Account"
            actionColor={ColorNames.CARDINAL}
            actionLoading={loadingDeleteAccount}
            onAction={() => onDisconnectAccount(account.id)}
            secondaryActionText="Cancel"
            onSecondaryAction={(): void => hideBottomSheet()}
          />
          <ActionBottomSheet
            id="edit_nickname_sheet"
            title="Update Account"
            actionText="Update Account"
            actionColor={ColorNames.LAKE}
            actionLoading={updateAccountLoading}
            onAction={() => {
              updateAccountSubmitRef.current?.click();
            }}
            secondaryActionText="Cancel"
            onSecondaryAction={() => hideBottomSheet()}
          >
            <Formik
              initialValues={{ accountName: account.nickname || "" }}
              enableReinitialize={true}
              validationSchema={UpdateAccountSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={onUpdate}
            >
              <StyledForm
                style={{
                  marginBottom: 44
                }}
                autoComplete="off"
              >
                <StyledFormInputContainer>
                  <Input name="accountName" type="text" label="Account Name" autoComplete="off" />
                </StyledFormInputContainer>
                {/*
                  hacky: explicit submit with ref here because Formik
                  cannot have the ActionBottomSheet as a child
                */}
                <input type="submit" hidden ref={updateAccountSubmitRef} />
              </StyledForm>
            </Formik>
          </ActionBottomSheet>
        </>
      )}
    </Layout>
  );
};

export default ConnectedAccountDetail;
