import React, { useRef, useState, useEffect, useCallback } from "react";
import classnames from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import { useRecoilValue } from "recoil";
import {
  detailPickerSelector,
  detailPickerState,
  IDetailPicker,
  IDetailPickerState
} from "src/store/detailPickerState";
import { useShowDetailPicker } from "src/util/useDetailPicker";
import { DataTestIds } from "src/util/testing-util/test-utils";

import {
  DetailPickerWrapper,
  DetailPickerContainer,
  DetailPickerHeader,
  DetailPickerLabel,
  IconCollapse,
  DetailPickerTabs,
  DetailPickerContent,
  DetailPickerActions,
  DetailPickerScrollable,
  DetailPickerSearchContainer,
  DetailPickerSearchInput
} from "src/components/detail-picker/DetailPicker.styles";
import { Overlay } from "src/components/bottom-sheets/BottomSheet.styles";
import { colorPalette as colors, ColorNames, dropShadows, ShadowNames } from "src/theme/theme";

import { ReactComponent as ArrowsCollapse } from "src/assets/icons/arrows_collapse.svg";
import { ReactComponent as IconSearch } from "src/assets/icons/search.svg";
import { IconExpand } from "src/components/forms/detail-select/DetailSelect.styles";

const spring = {
  type: "spring",
  stiffness: 380,
  damping: 36
};

export const DetailPickerController = (): JSX.Element => {
  const detailPickers = useRecoilValue<IDetailPicker[]>(detailPickerSelector);
  const detailPickerIds = useRecoilValue<IDetailPickerState>(detailPickerState);
  const { hideDetailPicker } = useShowDetailPicker();

  const [showSearchBar, setShowSearchBar] = useState(false);
  const searchBarRef = useRef<any>(null);

  const toggleSearchBar = () => {
    if (showSearchBar) {
      setShowSearchBar(false);
    } else {
      setShowSearchBar(true);
      setTimeout(() => {
        // clear css transition before focusing
        searchBarRef.current.focus();
      }, 150);
    }
  };

  const onOverlayClick = () => {
    const selectedDetailPicker = detailPickers.length > 0 ? detailPickers[detailPickers.length - 1] : undefined;
    if (!selectedDetailPicker) {
      hideDetailPicker();
    } else {
      !!selectedDetailPicker.onDismiss && selectedDetailPicker.onDismiss();
      hideDetailPicker(selectedDetailPicker.id);
    }
  };

  useEffect(() => {
    if (detailPickers.length > 0) {
      // lock body scroll when picker is open
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }
  }, [detailPickers]);

  // useEffect(() => {
  //   console.log(detailPickerIds.showIds);
  // }, [detailPickerIds]);

  return (
    <>
      <Overlay show={detailPickers.length > 0} onClick={onOverlayClick} />
      <AnimatePresence>
        {detailPickers.map((detailPicker: IDetailPicker) => {
          let offsetY = 0;
          if (!!detailPicker.origin) {
            const centerY = window.innerHeight / 2;
            offsetY = detailPicker.origin.y + detailPicker.origin.height / 2 - centerY;
          } else {
            offsetY = -1;
          }

          return (
            <DetailPickerWrapper key={detailPicker.id}>
              <DetailPickerContainer
                initial={{
                  maxHeight: detailPicker.origin?.height || 0,
                  maxWidth: detailPicker.origin?.width || 2000,
                  y: offsetY,
                  boxShadow: `0 60px 70px rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0), 0 40px 45px rgba(${
                    colors[ColorNames.MIDNIGHT].rgb
                  }, 0), 0 23px 29px rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0), 0 8px 19px rgba(${
                    colors[ColorNames.MIDNIGHT].rgb
                  }, 0), 0 1px 1px rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0)`,
                  clipPath: !detailPicker.origin ? "polygon(0 0, 100% 0, 100% 36px, 0 36px)" : "none"
                }}
                animate={{
                  maxHeight: detailPicker.tall ? 2000 : 500,
                  maxWidth: 2000,
                  y: 0,
                  boxShadow: dropShadows[ShadowNames.LIGHT],
                  pointerEvents: "all",
                  visibility: "visible",
                  clipPath: !detailPicker.origin ? "polygon(-50% -50%, 150% -50%, 150% 200%, -50% 200%)" : "none"
                }}
                exit={{
                  maxHeight: detailPicker.origin?.height || 0,
                  maxWidth: detailPicker.origin?.width || 2000,
                  y: offsetY,
                  boxShadow: `0 60px 70px rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0), 0 40px 45px rgba(${
                    colors[ColorNames.MIDNIGHT].rgb
                  }, 0), 0 23px 29px rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0), 0 8px 19px rgba(${
                    colors[ColorNames.MIDNIGHT].rgb
                  }, 0), 0 1px 1px rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0)`,
                  visibility: "hidden"
                }}
                transition={{
                  clipPath: { type: "none" },
                  visibility: { type: "none" },
                  default: spring
                }}
                className={classnames({ searching: showSearchBar })}
                basic={!detailPicker.origin}
                color={detailPicker.color || ColorNames.POWDER}
                hasValue={detailPicker.hasValue}
              >
                <DetailPickerHeader
                  initial={{
                    y: -18
                  }}
                  animate={{
                    y: origin ? 0 : -18
                  }}
                  exit={{
                    y: -18
                  }}
                  transition={spring}
                  data-override-origin={!origin} // overrides title animation
                >
                  {detailPicker.search && (
                    <DetailPickerSearchContainer
                      initial={{
                        opacity: 0
                      }}
                      animate={{
                        opacity: 1
                      }}
                      exit={{
                        opacity: 0
                      }}
                    >
                      <IconSearch onClick={toggleSearchBar} />
                      <DetailPickerSearchInput
                        ref={searchBarRef}
                        placeholder="Search..."
                        value={detailPicker.searchTerm}
                        onChange={detailPicker.handleSearch}
                        className={classnames({ show: showSearchBar })}
                      />
                    </DetailPickerSearchContainer>
                  )}
                  <DetailPickerLabel
                    initial={{
                      flex: "0 0 0px",
                      marginLeft: 20
                    }}
                    animate={{
                      flex: detailPicker.search ? "1 1 0px" : "0 0 0px",
                      marginLeft: detailPicker.search ? 52 : 20
                    }}
                    exit={{
                      flex: "0 0 0px",
                      marginLeft: 20
                    }}
                    transition={{
                      flex: { type: "none" },
                      default: spring
                    }}
                  >
                    <motion.div
                      initial={{
                        // don’t animate initial font size when origin is false
                        fontSize: !detailPicker.origin ? 16 : 13,
                        scale: 1
                      }}
                      animate={{
                        scale: !detailPicker.origin ? 1 : 1.25
                      }}
                      exit={{
                        scale: 1
                      }}
                    >
                      {detailPicker.displayLabel}
                    </motion.div>
                  </DetailPickerLabel>
                  <IconCollapse onClick={() => hideDetailPicker()}>
                    <ArrowsCollapse />
                    {/* : <IconExpand />*/}
                  </IconCollapse>
                </DetailPickerHeader>

                {!!detailPicker.tabs && (
                  <DetailPickerTabs
                    initial={{
                      opacity: 0
                    }}
                    animate={{
                      opacity: 1
                    }}
                    exit={{
                      opacity: 0
                    }}
                  >
                    {detailPicker.tabs}
                  </DetailPickerTabs>
                )}

                <DetailPickerContent
                  initial={{
                    opacity: 0,
                    pointerEvents: "none"
                  }}
                  animate={{
                    opacity: 1,
                    pointerEvents: "auto"
                  }}
                  exit={{
                    opacity: 0,
                    pointerEvents: "none"
                  }}
                  transition={{
                    duration: 0.15
                  }}
                >
                  {detailPicker.actions && <DetailPickerActions>{detailPicker.actions}</DetailPickerActions>}
                  {detailPicker.options && (
                    <DetailPickerScrollable tall={detailPicker.tall}>{detailPicker.options}</DetailPickerScrollable>
                  )}
                  {detailPicker.children}
                </DetailPickerContent>
              </DetailPickerContainer>
            </DetailPickerWrapper>
          );
        })}
      </AnimatePresence>
    </>
  );
};
