import React, { useEffect, useState, useMemo } from "react";
import { useParams, useFetcher, useRevalidator } from "react-router-dom";
import classnames from "classnames";

import { useShowBottomSheet } from "src/util/useBottomSheet";
import { showNotice } from "src/store/alertState";
import useNavigate from "src/util/useNavigate";
import { useNavbar } from "src/util/useNavbar";
import {
  useFreezeCard,
  useUnfreezeCard,
  useGetWeeklyCardActivity,
  useGetCardTransactions,
} from "src/services/cards";

import { NavRoutes } from "src/routes/navRoutes";
import { colorPalette as colors, ColorNames } from "src/theme/theme";
import { decorateCurrencyValue } from "src/util/stringUtils";
import {
  BackgroundInfo,
  cardBackgroundMap,
  CardStatusDisplayMap,
} from "src/types/card";
import { getCardDesign } from "src/util/accountUtils";
import { NavigationDirection } from "src/store/navigationState";

import {
  CardDetailsContainer,
  CardDetailsDropShadow,
  StyledCardDetails,
  CardHeader,
  CardStatusHolder,
  CardNumber,
  CardHolderInfo,
  CardName,
  CardDetailRows,
  CardActions,
  CardGraphHolder,
  CardGraph,
  CardGraphLabel,
  CardGraphRow,
  CardTransactions,
  CardTransactionsTitle,
  FrozenCardOverlay,
} from "./CardDetail.styles";
import { TransactionItems } from "src/components/transaction-item/TransactionItem.styles";
import { AppPage, AppContentContainer } from "src/routes/app/App.styles";

import { TransactionItem } from "src/components/transaction-item/TransactionItem";
import { CardTokenBottomSheet } from "src/components/cards/CardTokenBottomSheet";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import {
  ActionableButtonRow,
  ActionableButtonGroup,
} from "src/components/actionable-button/ActionableButton.styles";
import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import { Avatar } from "src/components/avatar/Avatar";
import { DetailRow } from "src/components/detail-row/DetailRow";
import { CardInfoSheet } from "src/components/cards/CardInfoSheet";
import { ReactComponent as IconFreeze } from "src/assets/icons/snowflake.svg";
import { ReactComponent as IconUnfreeze } from "src/assets/icons/play.svg";
import { ReactComponent as IconPopup } from "src/assets/icons/popup.svg";
import { ReactComponent as IconRight } from "src/assets/icons/chevron_right.svg";
import { LoadingContainer } from "src/components/loading-container/LoadingContainer";
import Layout from "src/components/core-layout/Layout";
import ErrorElement from "src/ErrorElement";
import { Card, CardStatusType, CardType } from "src/generated/client";

const WEEKDAY_NAMES = ["", "Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

export const CardDetailErrorElement = () => {
  return <ErrorElement redirect={NavRoutes.BANK_ACCOUNTS} alert={true} />;
};

const CardDetail = (): JSX.Element => {
  const { accountId, cardId } = useParams();
  const { data: card, state: loadingState, load } = useFetcher<Card>();
  const revalidator = useRevalidator();

  const {
    activity,
    error: errorActivity,
    loading: loadingActivity,
  } = useGetWeeklyCardActivity({ cardId: cardId || "" });
  const { transactions } = useGetCardTransactions({
    cardId: cardId || "",
    accountId: accountId || "",
  });
  const [maxWeeklySpendAmount, setMaxWeeklySpendAmount] = useState(0);
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();

  const navigate = useNavigate();
  const {
    freezeCard,
    loading: loadingFreezeCard,
    error: errorFreezeCard,
  } = useFreezeCard();
  const {
    unfreezeCard,
    loading: loadingUnfreezeCard,
    error: errorUnfreezeCard,
  } = useUnfreezeCard();

  useNavbar({
    title: "Card Details",
    backAction: () => {
      navigate(`${NavRoutes.BANK_ACCOUNTS}/${accountId}/cards`, {
        direction: NavigationDirection.BACKWARD,
      });
    },
  });

  useEffect(() => {
    if (!card && loadingState === "idle")
      load(`${NavRoutes.API_CARD}?cardId=${cardId}`);
  }, [card, cardId, loadingState, load]);

  const design: BackgroundInfo = useMemo(() => {
    return !!card ? getCardDesign(card) : cardBackgroundMap.default;
  }, [card]);

  const toggleFreezeCard = async () => {
    if (card?.status === CardStatusType.Active) {
      await freezeCard(card.id);
    } else if (card?.status === CardStatusType.Frozen) {
      await unfreezeCard(card.id);
    }
    revalidator.revalidate();
    hideBottomSheet();
  };

  const addToApplePay = () => undefined;

  useEffect(() => {
    if (!!activity) {
      const maxSpend = activity.days.reduce((previous, current) => {
        return previous.spendAmount.amount > current.spendAmount.amount
          ? previous
          : current;
      });
      setMaxWeeklySpendAmount(maxSpend.spendAmount.amount);
    }
  }, [activity]);

  useEffect(() => {
    if (errorFreezeCard) {
      showNotice(
        errorFreezeCard.message ||
          "There was a problem freezing this card. Please contact support.",
        {
          error: true,
        }
      );
    }
    if (errorUnfreezeCard) {
      showNotice(
        errorUnfreezeCard.message ||
          "There was a problem unfreezing this card. Please contact support.",
        {
          error: true,
        }
      );
    }
  }, [errorFreezeCard, errorUnfreezeCard]);

  return (
    <Layout loading={loadingState === "loading"} whiteFade={true}>
      <AppPage>
        <AppContentContainer topPadding>
          {loadingState !== "loading" && !!card && (
            <>
              <CardDetailsContainer>
                <CardDetailsDropShadow />
                <StyledCardDetails
                  background={`/card-backgrounds/${design.backgroundFileName}`}
                >
                  <FrozenCardOverlay
                    className={classnames({
                      frozen: card.status === CardStatusType.Frozen,
                    })}
                  />
                  <CardHeader>
                    <CardStatusHolder
                      children={CardStatusDisplayMap[card.status]}
                    />
                    <CardNumber
                      children={card.last4}
                      color={design.textColor}
                    />
                  </CardHeader>
                  <CardHolderInfo>
                    <Avatar
                      size={40}
                      name={`${card.cardHolderFirstName} ${card.cardHolderLastName}`}
                      color={ColorNames.GRAY5}
                    />
                    <CardName
                      children={`${card.cardHolderFirstName} ${card.cardHolderLastName}`}
                      color={design.textColor}
                    />
                  </CardHolderInfo>
                  <CardDetailRows color={design.textColor}>
                    {card.limit.amount > 0 && (
                      <DetailRow
                        label="Spend Limit"
                        data={decorateCurrencyValue(card.limit.display)}
                      />
                    )}
                    <DetailRow
                      label="Spent Today"
                      data={decorateCurrencyValue(card.spentToday.display)}
                    />
                    <DetailRow
                      label="Spent This Month"
                      data={decorateCurrencyValue(card.spentThisMonth.display)}
                    />
                    <DetailRow
                      label="Card Type"
                      data={card.type || CardType.Virtual}
                    />
                  </CardDetailRows>
                  <CardActions>
                    <ActionableButtonRow>
                      {(card.status === CardStatusType.Active ||
                        card.status === CardStatusType.Frozen) && (
                        <ActionableButton
                          label={
                            card.status === CardStatusType.Active
                              ? "Freeze"
                              : "Unfreeze"
                          }
                          iconRight={
                            card.status === CardStatusType.Active ? (
                              <IconFreeze />
                            ) : (
                              <IconUnfreeze />
                            )
                          }
                          onClick={() =>
                            showBottomSheet("confirm_freeze_card_sheet")
                          }
                        />
                      )}
                      <div>
                        <ActionableButton
                          label="Details"
                          iconRight={<IconPopup />}
                          onClick={() => showBottomSheet("card_details_sheet")}
                        />
                      </div>
                    </ActionableButtonRow>
                    {card.status !== CardStatusType.ClosedByCustomer && (
                      <>
                        <ActionableButtonGroup>
                          {/* <ActionableButton
                        label="Add to Apple Pay"
                        iconRight={<IconAdd />}
                        onClick={() => addToApplePay()}
                      /> */}
                          <ActionableButton
                            label="Settings"
                            iconRight={<IconRight />}
                            onClick={() =>
                              navigate(
                                `${NavRoutes.BANK_ACCOUNTS}/${accountId}/cards/${cardId}/settings`
                              )
                            }
                          />
                        </ActionableButtonGroup>
                      </>
                    )}
                  </CardActions>
                </StyledCardDetails>
              </CardDetailsContainer>

              {!errorActivity && (
                <CardGraphHolder>
                  <CardGraphLabel>This Week's Activity</CardGraphLabel>
                  {loadingActivity && (
                    <LoadingContainer
                      height="112px"
                      backgroundColor={ColorNames.TRANSPARENT}
                    />
                  )}
                  {!!activity && !loadingActivity && (
                    <CardGraph>
                      {activity.days.map((day) => {
                        const height =
                          maxWeeklySpendAmount === 0
                            ? 0
                            : (day.spendAmount.amount / maxWeeklySpendAmount) *
                              100;
                        return (
                          <CardGraphRow
                            key={day.day}
                            current={day.day === activity.currentDay}
                            future={day.day > activity.currentDay}
                          >
                            <div className="track">
                              {height < 100 && <div className="track-bar" />}
                              {height > 0 && (
                                <div
                                  className="track-bar spent"
                                  style={{ height: height + "%" }}
                                ></div>
                              )}
                            </div>
                            <div className="label">
                              {WEEKDAY_NAMES[day.day]}
                            </div>
                          </CardGraphRow>
                        );
                      })}
                    </CardGraph>
                  )}
                </CardGraphHolder>
              )}

              {!!transactions && transactions.length > 0 && (
                <CardTransactions>
                  <CardTransactionsTitle>
                    <span>Recent Transactions</span>
                    <IconRight />
                  </CardTransactionsTitle>
                  <TransactionItems>
                    {transactions.map((transaction) => (
                      <TransactionItem
                        key={transaction.id}
                        transaction={transaction}
                      />
                    ))}
                  </TransactionItems>

                  <ActionableButtonRow>
                    <ActionableButton
                      label="View All Transactions"
                      color={ColorNames.TRANSPARENT}
                      iconRight={false}
                      onClick={() => {
                        navigate(NavRoutes.BANK_TRANSACTIONS);
                      }}
                    />
                  </ActionableButtonRow>
                </CardTransactions>
              )}
            </>
          )}
        </AppContentContainer>

        <ActionBottomSheet
          id="confirm_freeze_card_sheet"
          title={`${
            card?.status === CardStatusType.Frozen ? "Unfreeze" : "Freeze"
          } Card`}
          description={`Are you sure you want to ${
            card?.status === CardStatusType.Frozen ? "unfreeze" : "freeze"
          } this card?`}
          actionText={`${
            card?.status === CardStatusType.Frozen ? "Unfreeze" : "Freeze"
          } Card`}
          actionLoading={loadingFreezeCard || loadingUnfreezeCard}
          secondaryActionText="Cancel"
          onAction={toggleFreezeCard}
          onSecondaryAction={() => hideBottomSheet()}
        />

        <CardTokenBottomSheet
          id="card_details_sheet"
          title="Card Details"
          card={card}
          children={({ token, externalCardId }) => (
            <CardInfoSheet
              token={token}
              externalCardId={externalCardId}
              card={card}
            />
          )}
        />
      </AppPage>
    </Layout>
  );
};

export default CardDetail;
