import React, { ReactNode } from "react";

import {
  PickableListItemContainer,
  ListItemTitle,
  InfoListItemValueContainer,
  ListItemValue,
  InfoListItemLeftContainer,
  InfoListItemRightContainer,
  PickableListItemCheckbox
} from "src/components/info-list-item/InfoListItem.styles";

import { DataTestIds } from "src/util/testing-util/test-utils";

export interface PickableListItemProps {
  title: string;
  subtitle?: string;
  size?: string;
  children?: ReactNode | string;
  pickable?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => unknown;
  inputValue: string;
  checked?: boolean;
}

export const PickableListItem = ({
  title,
  subtitle,
  children,
  onChange,
  inputValue,
  checked
}: PickableListItemProps): JSX.Element => {
  return (
    <PickableListItemContainer>
      <InfoListItemLeftContainer>
        <ListItemTitle>{title}</ListItemTitle>
        <InfoListItemValueContainer>
          <ListItemValue>{!!children ? children : subtitle}</ListItemValue>
        </InfoListItemValueContainer>
      </InfoListItemLeftContainer>
      <InfoListItemRightContainer>
        <PickableListItemCheckbox>
          <input type="checkbox" onChange={onChange && ((e) => onChange(e))} value={inputValue} checked={checked} />
        </PickableListItemCheckbox>
      </InfoListItemRightContainer>
    </PickableListItemContainer>
  );
};
