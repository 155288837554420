import React, { ReactNode } from "react";
import classnames from "classnames";

import useNavigate from "src/util/useNavigate";

import {
  StyledNavBar,
  StyledNavBarContent,
  BackArrow,
  CloseIcon,
  NavTitle,
  ActionIconContainer
} from "src/components/nav-bars/NavBars.styles";
import { SubNavBar } from "src/components/nav-bars/menu-nav/MenuNav.styles";
import { subNavConfig } from "src/components/nav-bars/menu-nav/MenuNav";
import { OrgSwitcher } from "src/components/org-switcher/OrgSwitcher";
import { DataTestIds } from "src/util/testing-util/test-utils";

export interface BackNavProps {
  onBackClick?: () => unknown | void;
  closeBackIcon?: boolean;
  title?: string;
  orgSwitcher?: boolean;
  actionIcon?: ReactNode;
  onActionClick?: () => unknown | void;
  id?: string;
  subNav?: subNavConfig;
}

export const BackNav = ({
  onBackClick,
  closeBackIcon,
  title,
  orgSwitcher,
  actionIcon,
  onActionClick,
  id = "",
  subNav
}: BackNavProps): JSX.Element => {
  const navigate = useNavigate();
  const subNavOptionClick = (event: React.MouseEvent<HTMLElement>, identifier: string) => {
    event.currentTarget.scrollIntoView({ behavior: "smooth" });
    subNav!.onChange(identifier);
  };

  return (
    <StyledNavBar className={classnames({ subnav: !!subNav })}>
      <StyledNavBarContent>
        {closeBackIcon ? (
          <CloseIcon
            onClick={() => (!!onBackClick ? onBackClick() : navigate("back"))}
            id={id}
            data-testid={DataTestIds.BACK_NAV_BACK}
          />
        ) : (
          <BackArrow
            onClick={() => (!!onBackClick ? onBackClick() : navigate("back"))}
            id={id}
            data-testid={DataTestIds.BACK_NAV_BACK}
          />
        )}

        {!!title && <NavTitle>{title}</NavTitle>}
        {!!actionIcon && (
          <ActionIconContainer onClick={onActionClick} data-testid={DataTestIds.ACTION_ICON}>
            {actionIcon}
          </ActionIconContainer>
        )}

        {orgSwitcher && <OrgSwitcher />}
      </StyledNavBarContent>

      {!!subNav && (
        <SubNavBar>
          {subNav.options.map((o) => (
            <div
              key={o.identifier}
              className={classnames({ active: subNav.active === o.identifier })}
              onClick={(e: React.MouseEvent<HTMLElement>) => subNavOptionClick(e, o.identifier)}
            >
              {o.title}
            </div>
          ))}
        </SubNavBar>
      )}
    </StyledNavBar>
  );
};
