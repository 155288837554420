import React, { useEffect } from "react";

import { PostMessageEventName } from "src/PostMessageHandler";

import Layout from "src/components/core-layout/Layout";
import { LoadingContainer } from "src/components/loading-container/LoadingContainer";

const DocusignComplete = () => {
  useEffect(() => {
    window.top?.postMessage(JSON.stringify({
      type: PostMessageEventName.DocuSignComplete
    }), "*");
  }, []);

  return <Layout>
    <LoadingContainer />
  </Layout>
}

export default DocusignComplete;