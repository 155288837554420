import styled, { createGlobalStyle } from "styled-components";
import { StyledFormInputContainer, StyledFieldContainer } from "src/components/forms";
import { TabViewContainer } from "src/components/nav-bars/Subnav.styles";

export const PageContent = styled.div`
  padding: 0 30px;
  display: flex;
  align-items: stretch;
  flex: 1 1 100%;
  margin-bottom: 48px;

  ${StyledFormInputContainer} {
    margin-bottom: 30px;
  }

  .input-container-for-toggle ${StyledFieldContainer} {
    display: flex;
    align-items: center;
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;

  & button:last-of-type {
    margin-top: 30px;
  }
`;

export const TabViewContainerOverride = createGlobalStyle`
${TabViewContainer} {
  display: flex;
  flex: 1;
}
`;
