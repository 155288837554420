import styled from "styled-components/macro";
import { colorPalette as colors, ColorNames, ThemeColor, fonts, borderRadius } from "src/theme/theme";
import { ActionableButtonProps } from "./ActionableButton";

type buttonTheme = {
  [key: string]: { background: string; text: string; extraLabel: string; };
};

const buttonStyle: buttonTheme = {
  [ColorNames.GRAY1]: {
    background: colors[ColorNames.GRAY1].hex,
    text: colors[ColorNames.MIDNIGHT].hex,
    extraLabel: colors[ColorNames.GRAY5].hex
  },
  [ColorNames.GRAY2]: {
    background: colors[ColorNames.GRAY2].hex,
    text: colors[ColorNames.MIDNIGHT].hex,
    extraLabel: colors[ColorNames.GRAY5].hex
  },
  PINEONGRAY: {
    background: colors[ColorNames.GRAY1].hex,
    text: colors[ColorNames.PINE].hex,
    extraLabel: colors[ColorNames.TEA].hex
  },
  [ColorNames.MIDNIGHT]: {
    background: colors[ColorNames.MIDNIGHT].hex,
    text: colors[ColorNames.WHITE].hex,
    extraLabel: colors[ColorNames.GRAY5].hex
  },
  [ColorNames.PINE]: {
    background: colors[ColorNames.PINE].hex,
    text: colors[ColorNames.WHITE].hex,
    extraLabel: colors[ColorNames.TEA].hex
  },
  [ColorNames.TEA]: {
    background: colors[ColorNames.TEA].hex,
    text: colors[ColorNames.WHITE].hex,
    extraLabel: colors[ColorNames.MINT].hex
  },
  [ColorNames.MINT]: {
    background: colors[ColorNames.MINT].hex,
    text: colors[ColorNames.PINE].hex,
    extraLabel: colors[ColorNames.TEA].hex
  },
  [ColorNames.MIST]: {
    background: colors[ColorNames.MIST].hex,
    text: colors[ColorNames.COBALT].hex,
    extraLabel: colors[ColorNames.BABY].hex
  },
  [ColorNames.POWDER]: {
    background: colors[ColorNames.POWDER].hex,
    text: colors[ColorNames.LAKE].hex,
    extraLabel: colors[ColorNames.BABY].hex
  },
  [ColorNames.EGGPLANT]: {
    background: colors[ColorNames.EGGPLANT].hex,
    text: colors[ColorNames.WHITE].hex,
    extraLabel: colors[ColorNames.LAVENDER].hex
  },
  [ColorNames.LAKE]: {
    background: colors[ColorNames.LAKE].hex,
    text: colors[ColorNames.WHITE].hex,
    extraLabel: colors[ColorNames.MIST].hex
  },
  [ColorNames.COBALT]: {
    background: colors[ColorNames.COBALT].hex,
    text: colors[ColorNames.WHITE].hex,
    extraLabel: colors[ColorNames.MIST].hex
  },
  [ColorNames.OCEAN]: {
    background: colors[ColorNames.OCEAN].hex,
    text: colors[ColorNames.WHITE].hex,
    extraLabel: colors[ColorNames.LAGOON].hex
  },
  [ColorNames.BREEZE]: {
    background: colors[ColorNames.BREEZE].hex,
    text: colors[ColorNames.OCEAN].hex,
    extraLabel: colors[ColorNames.LAGOON].hex
  },
  [ColorNames.TRANSPARENT]: {
    background: "transparent",
    text: colors[ColorNames.MIDNIGHT].hex,
    extraLabel: colors[ColorNames.GRAY5].hex
  }
};

export const ActionableButtonGroup = styled.div<{
  noTopMargin?: boolean;
  fullWidth?: boolean;
  backgroundColor?: ThemeColor;
}>`
  display: grid;
  grid-gap: 10px;
  width: ${({ fullWidth }): string => (fullWidth ? "100%" : "auto")};
  margin: ${({ noTopMargin, fullWidth, backgroundColor }): string => {
    const topMargin = noTopMargin ? "0px" : "16px";
    const bottomMargin = "16px";
    const horizontalMargin = fullWidth ? "0px" : !!backgroundColor ? "20px" : "30px";
    return `${topMargin} ${horizontalMargin} ${bottomMargin}`;
  }};
  padding: ${({ backgroundColor }): string => (!!backgroundColor ? "10px" : "0px")};
  background-color: ${({ backgroundColor }): string =>
    !!backgroundColor ? colors[backgroundColor].hex : "transparent"};
  border-radius: ${({ backgroundColor }): string => (!!backgroundColor ? borderRadius : "0px")};
`;

export const ActionableButtonGroupLabel = styled.div`
  ${fonts.PARAGRAPH_M}
  text-align: center;
  height: 42px;
  line-height: 42px;
`;

export const StyledActionableButton = styled.button<ActionableButtonProps>`
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
  touch-action: manipulation;
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  border: none;
  outline: none;
  display: block;
  width: 100%;
  padding: 12px 14px;
  border-radius: ${borderRadius};
  background-color: ${(props: ActionableButtonProps) =>
    props.color ? buttonStyle[props.color].background : buttonStyle[ColorNames.GRAY1].background};
  color: ${(props: ActionableButtonProps) =>
    props.destructive
      ? colors[ColorNames.CARDINAL].hex
      : props.color
      ? buttonStyle[props.color].text
      : buttonStyle[ColorNames.GRAY1].text};
  transition: 0.15s ease transform;

  svg {
    color: currentColor;
  }

  &:active {
    transform: scale(0.98);
  }

  &.destructive {
    background-color: ${({ keepBackground, color }) =>
      keepBackground
        ? color
          ? buttonStyle[color].background
          : buttonStyle[ColorNames.GRAY1].background
        : "transparent"};
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.25;
  }
`;

export const ActionableButtonRow = styled.div`
  display: flex;
  margin: 20px 30px;

  ${StyledActionableButton} {
    height: 100%;
  }

  &.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const ActionableFlex = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ActionableLabel = styled.div`
  ${fonts.PARAGRAPH_S}
  text-align: left;
  flex: 0 1 100%;

  &.centered {
    text-align: center;
  }
`;

export const ActionableExtraLabel = styled.div<{ color: string }>`
  ${fonts.PARAGRAPH_S}
  flex: 0 1 100%;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 160px;
  overflow: hidden;
  color: ${({ color }) =>  buttonStyle[color].extraLabel};
`;

export const ActionableIconLeft = styled.div`
  display: flex;
  align-items: center;
  padding-right: 12px;
`;

export const ActionableIconRight = styled.div`
  display: flex;
  align-items: center;
  padding-left: 12px;
`;
