import React from "react";

import { StyledFailedCreditCheckPage, LandingTitle, LandingCaption } from "./Landing.styles";

import Layout from "src/components/core-layout/Layout";

const FailedCreditCheck = () => {
  return <Layout noTopPadding noNavBar>
    <StyledFailedCreditCheckPage>
      <LandingTitle children="We’re sorry, but we’re unable to qualify you." />
      <LandingCaption children="We’ve cancelled your application and closed your Letter account." />
      <LandingCaption gray children="Based on your credit factors we are unable to qualify you for lending at this time. Thank you for considering Letter." />
      <LandingCaption children={<>If you feel this result is in error, please contact us directly: <a href="mailto:help@letter.co">help@letter.co</a></>} />
    </StyledFailedCreditCheckPage>
  </Layout>
}

export default FailedCreditCheck;