import styled from "styled-components";
import { motion } from "framer-motion";
import { LayoutFullPage } from "src/components/core-layout/CoreLayout.styles";
import { ActionableButtonGroup } from "src/components/actionable-button/ActionableButton.styles";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

export const SIGN_UP_COMPONENT_HEIGHT = "calc(100% - 16vh)";

export const StyledAuthPage = styled(LayoutFullPage)`
  width: 100%;
`;

export const AuthPageWrapper = styled(LayoutFullPage)`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
`;

export const BasicAuthContainer = styled(LayoutFullPage)`
  width: 100%;
  padding: 0 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  fieldset {
    margin: 40px 0;
  }

  form {
    &:before {
      content: "";
    }
  }
`;

export const StyledAuthContainer = styled(motion.div)<{ noPadding?: boolean }>`
  flex: 1 1 0%;
  width: 100%;
  padding: ${({ noPadding }): string => (noPadding ? "0" : "0 30px 30px 30px")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  fieldset {
    margin: 40px 0 auto;
    padding-bottom: 20px;
  }

  form {
    &:before {
      content: "";
    }
  }

  button {
    margin-top: 20px;
  }

  ${ActionableButtonGroup} button {
    margin-top: 0;
  }
`;

export const AuthTitle = styled.p`
  ${fonts.HEADING_4}
  text-align: center;
  color: ${colors[ColorNames.MIDNIGHT].hex};
`;

export const AuthCaption = styled.p<{ gray?: boolean }>`
  ${fonts.PARAGRAPH_L}
  text-align: center;
  color: ${({ gray }): string => gray ? colors[ColorNames.GRAY5].hex : colors[ColorNames.MIDNIGHT].hex};
  margin: 30px 0 10px;
`;

export const AuthParagraph = styled.p`
  margin: 20px;
  ${fonts.PARAGRAPH_S}
  text-align: center;
  color: ${colors[ColorNames.GRAY5].hex};
`;
