import { ActionFunction, LoaderFunction } from "react-router-dom";
import { getBankAccounts, getBankAccountById } from "src/services/accounts";

export const accountLoader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const accountId = url.searchParams.get("accountId");

  try {
    if (!!accountId) {
      return await getBankAccountById(accountId);
    } else {
      return await getBankAccounts();
    }
  } catch (e) {
    throw e;
  }
};

export const accountAction: ActionFunction = async ({ request }) => {
  //
};
