import styled from "styled-components/macro";
import { motion } from "framer-motion";
import { Container } from "src/components/avatar/Avatar.styles";
import { colorPalette as colors, ColorNames, fonts, borderRadiusOuter } from "src/theme/theme";

export const AccountCardWrapper = styled(motion.div)``;

export const AccountCardContainer = styled.div`
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: ${borderRadiusOuter};
  padding: 20px;
  margin-bottom: 20px;
  background-color: ${colors[ColorNames.MINT].hex};
  transition: 0.15s ease transform;
  user-select: none;

  &:active {
    transform: scale(0.98);
  }
`;

export const AccountCardGraphContainer = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  bottom: 10px;
  left: 0;
  border-radius: ${borderRadiusOuter};
  overflow: hidden;
  touch-action: none;
  pointer-events: none;
  opacity: 0.25;
`;

export const AccountCardTitles = styled.div`
  margin-bottom: 20px;
`;

export const AccountCardName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colors[ColorNames.PINE].hex};
  ${fonts.PARAGRAPH_L}
`;

export const AccountCardSubtitle = styled.div`
  color: ${colors[ColorNames.TEA].hex};
  ${fonts.PARAGRAPH_S}
  margin-top: 3px;
`;

export const AccountCardDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AccountCardNumber = styled.div`
  ${fonts.NUMBER_M}
  color: ${colors[ColorNames.GRASS].hex};
  display: flex;
  align-items: center;
  position: relative;

  ${Container} {
    margin-right: 10px;
  }
`;

export const AccountCardFunds = styled.div`
  ${fonts.NUMBER_M}
  color: ${colors[ColorNames.PINE].hex};
  text-align: right;
`;

export const AccountCardTypeIcon = styled.div`
  margin-right: 10px;
  height: 20px;
  width: 20px;

  svg {
    color: ${colors[ColorNames.GRASS].hex};
  }
`;