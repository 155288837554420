import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { useConnect } from "src/services/connected-accounts";
import { showNotice } from "src/store/alertState";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { expectedNewConnectedAccountsDataState } from "src/store/connectedAccountsState";

type Props = { teller: TellerInstance };

const useTeller = ({ TellerConnect, onSuccess }: { TellerConnect: TellerConnect; onSuccess: () => void }): Props => {
  const [tellerConnect, setTellerConnect] = useState<TellerInstance>();
  const { connect } = useConnect();

  const { hideBottomSheet, showBottomSheet } = useShowBottomSheet();
  const { REACT_APP_TELLER_APPLICATION_ID, REACT_APP_TELLER_ENV } = process.env;
  const setExpectedNewConnectedAccountsData = useSetRecoilState(expectedNewConnectedAccountsDataState);

  useEffect(() => {
    if (!TellerConnect || !REACT_APP_TELLER_APPLICATION_ID) {
      return;
    }

    const params: TellerOptions = {
      applicationId: REACT_APP_TELLER_APPLICATION_ID,
      onInit: () => console.log("Teller Connect has initialized"),
      environment: REACT_APP_TELLER_ENV,
      onSuccess: async ({ accessToken, enrollment: { id: enrollmentId } }) => {
        showBottomSheet("connected_account_loading_sheet");
        hideBottomSheet("connect_account_sheet");
        let showLastStep = false;

        try {
          setExpectedNewConnectedAccountsData([]);
          const connected = await connect({ token: accessToken, enrollmentId, provider: "Teller" });
          if (!connected) {
            showNotice("There was a problem connecting to Teller.", { error: true });
          } else if (typeof connected !== "boolean") {
            setExpectedNewConnectedAccountsData(connected);
            showLastStep = true;
          }
        } catch (error) {
          showNotice("There was a problem connecting to Teller.", { error: true });
        }

        hideBottomSheet("connected_account_loading_sheet");
        if (showLastStep) showBottomSheet("connected_account_select_accounts");
        // if (!!onSuccess) onSuccess();
      },
      onExit: () => hideBottomSheet("connect_account_sheet")
    };

    const tellerConnect = TellerConnect.setup(params);

    setTellerConnect(tellerConnect);
  }, [REACT_APP_TELLER_APPLICATION_ID]);

  return { teller: tellerConnect! };
};

export default useTeller;
