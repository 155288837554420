import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useParams, useFetcher } from "react-router-dom";

import { useShowBottomSheet } from "src/util/useBottomSheet";
import { showNotice } from "src/store/alertState";
import useNavigate from "src/util/useNavigate";
import { useNavbar } from "src/util/useNavbar";

import { NavRoutes } from "src/routes/navRoutes";
import { NavigationDirection } from "src/store/navigationState";

import { AppPage, AppContentContainer } from "src/routes/app/App.styles";
import {
  CardsPageContent,
  CardSettingsActions,
} from "src/routes/app/bank/cards/Cards.styles";

import { Button } from "src/components/button/Button";
import { CurrencyInput } from "src/components/forms/currency-input/CurrencyInput";
import { Select } from "src/components/forms/select/Select";
import { Toggle } from "src/components/toggle/Toggle";
import { ColorNames } from "src/theme/theme";
import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { ActionableButtonRow } from "src/components/actionable-button/ActionableButton.styles";
import { StyledFormCollapsableGroup } from "src/components/forms";

import { ReactComponent as IconRecycleHorizontal } from "src/assets/icons/recycle_horizontal.svg";
import Layout from "src/components/core-layout/Layout";
import ErrorElement from "src/ErrorElement";
import { Card, CardLimitBehaviourType, CardType } from "src/generated/client";

const limitBehaviors = [
  {
    label: "Monthly Limit",
    value: CardLimitBehaviourType.Monthly,
  },
  {
    label: "One-time Balance",
    value: CardLimitBehaviourType.Refill,
  },
];

export const CardSettingsErrorElement = () => {
  return <ErrorElement redirect={NavRoutes.BANK_ACCOUNTS} alert={true} />;
};

const CardSettings = () => {
  const { accountId, cardId } = useParams();
  const { data: card, state: loadingState, load } = useFetcher<Card>();
  const { data, state: fetcherState, Form, ...fetcher } = useFetcher();

  const [limitEnabled, setLimitEnabled] = useState(false);
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();
  const navigate = useNavigate();

  useNavbar({ title: "Card Settings", back: true });

  useEffect(() => {
    if (!card && loadingState === "idle") {
      load(`${NavRoutes.API_CARD}?cardId=${cardId}`);
    }
  }, [card, cardId, loadingState, load]);

  useEffect(() => {
    setLimitEnabled((!!card?.limit?.amount && card.limit.amount > 0) || false);
  }, [card?.limit]);

  useEffect(() => {
    if (
      !!data?.validationErrors &&
      Object.keys(data.validationErrors).length > 0
    ) {
      const firstError = Object.values(data.validationErrors)[0] as string;
      showNotice(firstError, { error: true });
    }
  }, [data?.validationErrors]);

  useEffect(() => {
    if (!!data?.error) {
      const errorMessage =
        data.error.message || "There was a problem updating this card.";
      showNotice(errorMessage, { error: true });
    }
  }, [data?.error]);

  useEffect(() => {
    if (!!data?.response?.changeCardLimit) {
      showNotice("Your card has been updated.");
    } else if (!!data?.response?.closeCard) {
      hideBottomSheet("close_card_sheet");
      showNotice("Your card has been cancelled.");
      navigate(`${NavRoutes.BANK_ACCOUNTS}/${accountId}/cards`, {
        direction: NavigationDirection.BACKWARD,
      });
    }
  }, [data?.response]);

  const replaceCard = () => undefined;

  const cancelCard = async () => {
    fetcher.submit({ cardId: cardId || "" }, { method: "delete" });
  };

  return (
    <Layout>
      <AppPage>
        <AppContentContainer>
          <CardsPageContent>
            <Form method="put">
              <>
                {/* <StyledFormInputContainer>
                <Select name="accessType" label="Card Holder Access" options={cardRoles} defaultValue={} />
                <CurrencyInput name="transactionLimitSingle" type="text" label="Single Transaction Limit" />
                <CurrencyInput name="transactionLimitDaily" type="text" label="Daily Transaction Limit" />
              </StyledFormInputContainer> */}

                <StyledFormCollapsableGroup>
                  <Toggle
                    align="left"
                    displayLabel="Limit Available Balance"
                    name="limitEnabled"
                    checked={limitEnabled}
                    onChange={(event) =>
                      setLimitEnabled(event.currentTarget.checked)
                    }
                  />
                  <div
                    className={classnames("content", { open: limitEnabled })}
                  >
                    <CurrencyInput
                      name="limit"
                      defaultValue={card?.limit?.amount}
                      error={!!data?.validationErrors?.limit}
                      type="text"
                      label="Spend Limit"
                    />
                    <Select
                      name="limitBehaviour"
                      defaultValue={
                        card?.limitBehaviour || CardLimitBehaviourType.Monthly
                      }
                      error={!!data?.validationErrors?.limitBehaviour}
                      label="Spend Limit Behavior"
                      options={limitBehaviors}
                    />
                  </div>
                </StyledFormCollapsableGroup>

                <Button
                  color={ColorNames.LAKE}
                  raised
                  type="submit"
                  name="cardId"
                  value={cardId}
                  loading={fetcherState === "submitting"}
                >
                  Save Card
                </Button>

                <CardSettingsActions>
                  <ActionableButtonRow>
                    {card?.type === CardType.Physical && (
                      <ActionableButton
                        classname="replace"
                        label="Replace Card"
                        iconRight={<IconRecycleHorizontal />}
                        onClick={() => replaceCard()}
                      />
                    )}
                    <ActionableButton
                      label="Cancel Card"
                      destructive
                      iconRight={false}
                      onClick={() => showBottomSheet("close_card_sheet")}
                    />
                  </ActionableButtonRow>
                </CardSettingsActions>
              </>
            </Form>
          </CardsPageContent>
        </AppContentContainer>

        <ActionBottomSheet
          id="close_card_sheet"
          title="Cancel Card"
          actionText="Yes, Cancel This Card"
          actionColor={ColorNames.CARDINAL}
          onAction={cancelCard}
          actionLoading={fetcherState === "submitting"}
          secondaryActionText="Keep Card"
          onSecondaryAction={(): void => hideBottomSheet()}
          description="Are you sure you want to cancel this card? This change is immediate and cannot be undone."
        />
      </AppPage>
    </Layout>
  );
};

export default CardSettings;
