import { makeVar } from "@apollo/client";

interface LogoutState {
  logout: boolean;
  supressAlert?: boolean;
  persistCookies?: boolean;
  disableRedirect?: boolean;
}

export const needsLogoutVar = makeVar<LogoutState>({
  logout: false,
  supressAlert: false,
  persistCookies: false
});
