import styled from "styled-components";

import { colorPalette as colors, ColorNames, ThemeColor, fonts } from "src/theme/theme";

export const ActionSheetIcon = styled.div<{
  backgroundColor?: ThemeColor;
  color?: ThemeColor;
}>`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  margin: 0 auto 20px;
  background-color: ${({ backgroundColor }): string =>
    !!backgroundColor ? colors[backgroundColor].hex : colors[ColorNames.MIDNIGHT].hex};
  color: ${({ color }): string => (!!color ? colors[color].hex : colors[ColorNames.WHITE].hex)};
`;

export const ActionSheetTitle = styled.p<{ color?: ThemeColor }>`
  width: 100%;
  ${fonts.HEADING_4}
  color: ${({ color }): string => (!!color ? colors[color].hex : colors[ColorNames.MIDNIGHT].hex)};
  margin-bottom: 32px;
  text-align: center;
`;

export const ActionSheetDescription = styled.p<{ size?: string; noMargin?: boolean }>`
  ${({ size }): string => {
    if (size === "small") {
      return fonts.PARAGRAPH_S;
    } else {
      return fonts.PARAGRAPH_M;
    }
  }}
  ${({ size }): string => {
    if (size === "small") {
      return `margin-bottom: 48px;`;
    } else {
      return fonts.PARAGRAPH_M;
    }
  }}
  margin-bottom: ${({ noMargin }): string => (!!noMargin ? "0" : "48px")};
  width: 100%;
  color: ${colors[ColorNames.MIDNIGHT].hex};
  white-space: pre-wrap;
  text-align: center;

  a {
    color: ${colors[ColorNames.MIDNIGHT].hex};
  }

  small {
    color: ${colors[ColorNames.GRAY5].hex};
    a {
      color: ${colors[ColorNames.GRAY5].hex};
    }
  }
`;

export const ActionSheetCenteredContainer = styled.div`
  width: 100%;
  ${fonts.PARAGRAPH_M}
  color: ${colors[ColorNames.MIDNIGHT].hex};
  white-space: pre-wrap;
  text-align: center;

  a {
    color: ${colors[ColorNames.MIDNIGHT].hex};
  }

  small {
    color: ${colors[ColorNames.GRAY5].hex};
    a {
      color: ${colors[ColorNames.GRAY5].hex};
    }
  }
`;

export const ButtonsContainer = styled.div`
  margin-top: 48px;
`;

export const SecondaryButtonContainer = styled.div`
  margin-top: 8px;
  width: 100%;
`;
