import { client } from "src";
import { useCallback, useState, useEffect } from "react";
import {
  useMutation,
  ApolloError,
  useQuery,
  ApolloQueryResult,
  useLazyQuery,
} from "@apollo/client";
import { useFetcher } from "react-router-dom";

import { NavRoutes } from "src/routes/navRoutes";
import { decorateCurrencyValue } from "src/util/stringUtils";
import { useLogger } from "src/util/useLogger";
import { useSetSession } from "src/services/auth";
import {
  User,
  Role,
  BeneficialOwner,
  AddBusinessInfoMutationVariables,
  Transaction,
  AccountBalancesGrouped,
  OrganizationUsersQuery,
  useOrganizationUsersQuery,
  useOrganizationByIdQuery,
  useOrganizationByIdBalancesLazyQuery,
  BalancesTimeframe,
  AllocateRepaymentAccountIdDocument,
  Organization,
  OrgType
} from "src/generated/client";
import { useCurrentUser } from "src/services/users";

import {
  ADD_BUSINESS_INFO,
  ADD_OWNER_INFO,
  REISSUE_TOKEN,
  GET_OWNERS_FOR_ORG,
  GET_TRANSACTIONS_FOR_ORG,
  GET_ORGANIZATION_BALANCES,
  GET_ORGANIZATION,
  GET_ORGANIZATION_USERS,
  REVOKE_ORGANIZATION_INVITE,
  REMOVE_ORGANIZATION_USER,
  FREEZE_ORGANIZATION_USER,
  THAW_ORGANIZATION_USER,
  EDIT_ORGANIZATION_USER,
  DELETE_ORGANIZATION,
  GET_ORGANIZATION_INVITE_TOKEN,
  REQUEST_ACCREDITATION,
} from "src/services/gql/_organizations";

export interface AddTrustInfoData {
  organizationId?: string;
  orgType: OrgType;
  orgName?: string;
  stateOfJurisdiction?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export interface AddBusinessInfoData {
  organizationId?: string;
  orgType: OrgType;
  orgName?: string;
  legalStructure?: string;
  taxStatus?: string;
  taxId?: string;
  stateOfIncorporation?: string;
  businessPhoneNumber?: string;
  category?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zip?: string;
  businessOrgData?: AddBusinessInfoMutationVariables;
  beneficialOwners?: Array<BeneficialOwner>;
}

export type InviteTokenVariables = {
  permissionLevel: string;
  email: string;
  sendEmail: boolean;
};

export type OrganizationInviteToken = {
  inviteUserToOrg: {
    token: string;
    userId: string;
  };
};

interface CurrentOrgProps {
  fullFetch: boolean
}

export const useCurrentOrg = (props?: CurrentOrgProps): Organization => {
  const currentUser = useCurrentUser();
  const { load } = useFetcher();
  const [fetchOrg, { data }] = useLazyQuery(GET_ORGANIZATION, {
    errorPolicy: "all",
    fetchPolicy: "cache-only",
  });

  useEffect(() => {
    if (!currentUser) return;

    const orgId = currentUser.organizations?.find((o) => o.currentContext)?.id;

    if (!orgId) return;
    // load current org from cache, regardless of if it's a full org
    fetchOrg({ variables: { orgId } });

    if (props?.fullFetch) {
      // fetch full org as a fetcher to ensure revalidation on actions
      load(`${NavRoutes.API_ORGANIZATION}?organizationId=${orgId}`);
    }
  }, [currentUser, fetchOrg]);

  return (
    data?.organization ||
    currentUser?.organizations?.find((o) => o.currentContext)
  );
};

export const getOrganizationById = async (orgId: string) => {
  const res = await client.query({
    query: GET_ORGANIZATION,
    errorPolicy: "all",
    fetchPolicy: "network-only",
    variables: { orgId },
  });

  if (!!res.data?.organization) {
    return { organization: res.data.organization };
  } else {
    throw res.errors;
  }
};

export const requestAccreditation = async () => {
  try {
    const res = await client.mutate({
      mutation: REQUEST_ACCREDITATION,
      errorPolicy: "all",
    });

    if (!!res.data?.requestAccreditation?.success) {
      return { requestAccreditation: true };
    } else {
      let errorMessage = "There was a problem requesting accreditation.";
      if (!!res.errors && res.errors.length > 0) {
        errorMessage = res.errors[0].message;
      }
      throw Error(errorMessage);
    }
  } catch (err) {
    throw err;
  }
};

export const allocateRepaymentAccount = async (accountId: string) => {
  try {
    const res = await client.mutate({
      mutation: AllocateRepaymentAccountIdDocument,
      errorPolicy: "all",
      variables: { accountId }
    });

    if (!!res.data?.allocateRepaymentAccountId) {
      return { allocateRepaymentAccount: true };
    } else {
      let errorMessage = "There was a problem setting your repayment account.";
      if (!!res.errors && res.errors.length > 0) {
        errorMessage = res.errors[0].message;
      }
      throw Error(errorMessage);
    }
  } catch (err) {
    throw err;
  }
};

export const useGetInviteToken = (): {
  getToken: (
    variables: InviteTokenVariables
  ) => Promise<{ token: string; userId: string } | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [getInviteToken, { loading, error }] = useMutation<
    OrganizationInviteToken,
    InviteTokenVariables
  >(GET_ORGANIZATION_INVITE_TOKEN, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const getToken = useCallback(
    async (
      variables: InviteTokenVariables
    ): Promise<{ token: string; userId: string } | undefined> => {
      try {
        const { data } = await getInviteToken({ variables });

        return data?.inviteUserToOrg;
      } catch (err) {
        captureException(err);
      }
    },
    [getInviteToken, captureException]
  );

  return { getToken, loading, error };
};

export type InviteUserData = {
  emailInviteToOrgUser: {
    success: boolean;
  };
};

export type InviteUserVariables = {
  email: string;
  permissionLevel: string;
};

export const useInviteUser = (): {
  inviteUser: (
    variables: InviteUserVariables
  ) => Promise<{ userId: string; token: string } | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  // const [, { loading, error }] = useMutation<InviteUserData, InviteUserVariables>(EMAIL_INVITE, { errorPolicy: "all" });
  const { captureException } = useLogger();
  const { getToken, loading, error } = useGetInviteToken();

  const inviteUser = useCallback(
    async (
      variables: InviteUserVariables
    ): Promise<{ token: string; userId: string } | undefined> => {
      try {
        const { permissionLevel, email } = variables;

        const res = await getToken({
          permissionLevel,
          email,
          sendEmail: true,
        });
        return res;
      } catch (err) {
        captureException(err);
      }
    },
    [getToken, captureException]
  );

  return { inviteUser, loading, error };
};

export const hofundRoutes = () => {
  return {
    [NavRoutes.REISSUE_TOKEN]: REISSUE_TOKEN,
  };
};

export const useAddBusinessInfo = (): {
  addBusinessInfo: ({
    values,
    inviteCode,
  }: {
    values: AddBusinessInfoData;
    inviteCode?: string | null;
  }) => Promise<Organization | undefined>;
  addOwnerInfo: ({
    values,
  }: {
    values: AddBusinessInfoData;
  }) => Promise<boolean | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [
    addBusinessInfoGQL,
    { loading: businessLoading, error: businessError },
  ] = useMutation(ADD_BUSINESS_INFO, {
    errorPolicy: "all",
  });
  const [addOwnerInfoGQL, { loading: ownersLoading, error: ownerError }] =
    useMutation(ADD_OWNER_INFO, {
      errorPolicy: "all",
    });
  const { captureException } = useLogger();
  const setSession = useSetSession();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ApolloError | undefined>();

  useEffect(() => {
    if (businessLoading || ownersLoading) setLoading(true);
    else if (!businessLoading && !ownersLoading) setLoading(false);
  }, [businessLoading, ownersLoading]);

  useEffect(() => {
    if (businessError || ownerError) setError(businessError || ownerError);
    else if (!businessError && !ownerError) setError(undefined);
  }, [businessError, ownerError]);

  const addBusinessInfo = useCallback(
    async ({
      values,
      inviteCode,
    }: {
      values: AddBusinessInfoData;
      inviteCode?: string | null;
    }): Promise<Organization | undefined> => {
      try {
        const { data } = await addBusinessInfoGQL({ variables: values });
        if (!inviteCode && data?.createOrUpdateOrganization?.session)
          setSession(data?.createOrUpdateOrganization?.session);
        return !!data?.createOrUpdateOrganization
          ? data.createOrUpdateOrganization
          : undefined;
      } catch (err) {
        captureException(err);
      }
    },
    [addBusinessInfoGQL, captureException, setSession]
  );

  const addOwnerInfo = useCallback(
    async ({ values }: { values: any }): Promise<boolean | undefined> => {
      const cleanedOwners = values.beneficialOwners.map((o: any) => {
        const { __typename, ...rest } = o;
        return rest;
      });
      const newValues = { ...values, beneficialOwners: cleanedOwners };
      try {
        const { data } = await addOwnerInfoGQL({ variables: newValues });
        return data?.updateOwners ? data.updateOwners.success : undefined;
      } catch (err) {
        captureException(err);
      }
    },
    [addOwnerInfoGQL, captureException]
  );

  return { addBusinessInfo, addOwnerInfo, loading, error };
};

export const useAddTrustInfo = (): {
  addTrustInfo: ({
    values,
    inviteCode,
  }: {
    values: AddTrustInfoData;
    inviteCode?: string;
  }) => Promise<Organization | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [addTrustInfoGQL, { loading: trustLoading, error: trustError }] =
    useMutation(ADD_BUSINESS_INFO, {
      errorPolicy: "all",
    });
  const { captureException } = useLogger();
  const setSession = useSetSession();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ApolloError | undefined>();

  useEffect(() => {
    if (trustLoading) setLoading(true);
  }, [trustLoading]);

  useEffect(() => {
    if (trustError) setError(trustError);
    else if (!trustError) setError(undefined);
  }, [trustError]);

  const addTrustInfo = useCallback(
    async ({
      values,
      inviteCode,
    }: {
      values: AddTrustInfoData;
      inviteCode?: string;
    }): Promise<Organization | undefined> => {
      try {
        const { data } = await addTrustInfoGQL({ variables: values });
        if (!inviteCode && data?.createOrUpdateOrganization?.session)
          setSession(data?.createOrUpdateOrganization?.session);
        return !!data?.createOrUpdateOrganization
          ? data.createOrUpdateOrganization
          : undefined;
      } catch (err) {
        captureException(err);
      }
    },
    [addTrustInfoGQL, captureException, setSession]
  );

  return { addTrustInfo, loading, error };
};

type UseOrganization = {
  data: Organization | undefined;
  error: ApolloError | undefined;
  loading: boolean;
  refetch: () => void;
};

type Balance = {
  display: string;
  amount: number;
};

export type OrganizationVariables = {
  orgId?: string | null;
  selectedUserId?: string | null;
  timeFrame?: string;
};

export const useOrganization = () => {
  const currentOrg = useCurrentOrg();
  const { data, error, loading, refetch } = useOrganizationByIdQuery({
    errorPolicy: "all",
    variables: { orgId: currentOrg?.id },
  });

  return {
    data,
    error,
    loading,
    refetch,
  };
};

export const useOrganizationUsers = ({ userId }: { userId?: string }) => {
  const currentOrg = useCurrentOrg();
  const { data, error, loading, refetch } = useOrganizationUsersQuery({
    errorPolicy: "all",
    variables: { orgId: currentOrg.id, selectedUserId: userId },
  });

  return {
    users: data?.organization?.users,
    currentUserRole: data?.organization?.role,
    error,
    loading,
    refetch,
  };
};

export const useEditOrgUser = (): {
  edit: ({
    userId,
    role,
  }: {
    userId: string;
    role: string;
  }) => Promise<boolean>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [editAction, { loading, error }] = useMutation(EDIT_ORGANIZATION_USER, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const edit = useCallback(
    async ({
      userId,
      role,
    }: {
      userId: string;
      role: string;
    }): Promise<boolean> => {
      try {
        const { data } = await editAction({ variables: { userId, role } });
        return Boolean(data?.editOrgUserAccess?.success);
      } catch (err) {
        captureException(err);
        return false;
      }
    },
    [editAction, captureException]
  );

  return { edit, loading, error };
};

export const useRemoveOrgUser = (): {
  removeMember: ({ userId }: { userId: string }) => Promise<boolean>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [remove, { loading, error }] = useMutation(REMOVE_ORGANIZATION_USER, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const removeMember = useCallback(
    async ({ userId }: { userId: string }): Promise<boolean> => {
      try {
        const { data } = await remove({ variables: { userId } });
        return Boolean(data?.removeOrgUser?.success);
      } catch (err) {
        captureException(err);
        return false;
      }
    },
    [remove, captureException]
  );

  return { removeMember, loading, error };
};

export const useRevokeOrgInvite = (): {
  revokeInvite: ({ userId }: { userId: string }) => Promise<boolean>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [revoke, { loading, error }] = useMutation(REVOKE_ORGANIZATION_INVITE, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const revokeInvite = useCallback(
    async ({ userId }: { userId: string }): Promise<boolean> => {
      try {
        const { data } = await revoke({ variables: { userId } });
        return Boolean(data?.revokeInviteToOrg?.success);
      } catch (err) {
        captureException(err);
        return false;
      }
    },
    [revoke, captureException]
  );

  return { revokeInvite, loading, error };
};

export const useToggleFreezeOrgUser = (): {
  freeze: ({ userId }: { userId: string }) => Promise<boolean>;
  thaw: ({ userId }: { userId: string }) => Promise<boolean>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [freezeAction, { loading: freezeLoading, error: freezeError }] =
    useMutation(FREEZE_ORGANIZATION_USER, {
      errorPolicy: "all",
    });
  const [thawAction, { loading: thawLoading, error: thawError }] = useMutation(
    THAW_ORGANIZATION_USER,
    {
      errorPolicy: "all",
    }
  );
  const { captureException } = useLogger();

  const freeze = useCallback(
    async ({ userId }: { userId: string }): Promise<boolean> => {
      try {
        const { data } = await freezeAction({ variables: { userId } });
        return Boolean(data?.freezeOrgUser?.success);
      } catch (err) {
        captureException(err);
        return false;
      }
    },
    [freezeAction, captureException]
  );

  const thaw = useCallback(
    async ({ userId }: { userId: string }): Promise<boolean> => {
      try {
        const { data } = await thawAction({ variables: { userId } });
        return Boolean(data?.thawOrgUser?.success);
      } catch (err) {
        captureException(err);
        return false;
      }
    },
    [thawAction, captureException]
  );

  return {
    freeze,
    thaw,
    loading: freezeLoading || thawLoading,
    error: freezeError || thawError,
  };
};

export const useOrganizationOwners = () => {
  const currentOrg = useCurrentOrg();
  const { data, error, loading } = useQuery(GET_OWNERS_FOR_ORG, {
    errorPolicy: "all",
    variables: { id: currentOrg?.id },
  });

  return {
    owners: data?.organization?.beneficialOwnersList || [],
    error,
    loading,
  };
};

export const useHistoricalBalances = (): {
  getHistoricalBalances: (timeframe: BalancesTimeframe) => void;
  historicalBalance: AccountBalancesGrouped[];
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const currentOrg = useCurrentOrg();
  const [balances, { data, error, loading }] =
    useOrganizationByIdBalancesLazyQuery({
      errorPolicy: "all",
    });

  const historicalBalance = data?.organization?.balances ?? [];
  const getHistoricalBalances = useCallback(
    (timeframe: BalancesTimeframe) => {
      balances({ variables: { timeframe, orgId: currentOrg?.id } });
    },
    [balances, currentOrg]
  );

  return { getHistoricalBalances, historicalBalance, loading, error };
};

type BalanceOrNull = Balance | null;

export const useBalances = (): {
  checkingBalance: BalanceOrNull;
  externalBalance: BalanceOrNull;
  availableBalance: BalanceOrNull;
  deposits: BalanceOrNull;
  spent: BalanceOrNull;
  error: ApolloError | undefined;
  refetchBalance: () => void;
} => {
  const [checkingBalance, setCheckingBalance] = useState<BalanceOrNull>(null);
  const [externalBalance, setExternalBalance] = useState<BalanceOrNull>(null);
  const [availableBalance, setAvailableBalance] = useState<BalanceOrNull>(null);
  const [deposits, setDeposits] = useState<BalanceOrNull>(null);
  const [spent, setSpent] = useState<BalanceOrNull>(null);

  const { data, error, refetch } = useOrganization();

  useEffect(() => {
    if (error) {
      setCheckingBalance(null);
      setExternalBalance(null);
      setAvailableBalance(null);
      setDeposits(null);
      setSpent(null);
    }
  }, [error]);

  useEffect(() => {
    const hasBalance =
      data?.organization?.totalBalance &&
      Math.abs(data.organization.totalBalance.amount) >= 0;
    const hasExternalBalance =
      data?.organization?.externalBalance &&
      Math.abs(data.organization.externalBalance.amount);
    if (hasBalance || hasExternalBalance) {
      const {
        totalBalance,
        externalBalance,
        deposits,
        spent,
        availableBalance,
      } = data.organization;

      setCheckingBalance(totalBalance);
      setExternalBalance(externalBalance);
      setAvailableBalance(availableBalance);
      setDeposits(deposits);
      setSpent(spent);
    }
  }, [data]);

  return {
    checkingBalance,
    externalBalance,
    availableBalance,
    deposits,
    spent,
    error,
    refetchBalance: refetch,
  };
};

export const useGetTransactionsForOrg = (): {
  transactions: Transaction[];
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const { data, loading, error } = useQuery(GET_TRANSACTIONS_FOR_ORG, {
    errorPolicy: "all",
  });

  const transactions = data?.transactionsForOrg?.items?.map(
    (transaction: { [key: string]: any }) => {
      const date = Intl.DateTimeFormat(navigator.language, {
        month: "short",
        day: "numeric",
        year: "numeric",
      }).format(new Date(transaction.transactionDate));
      const time = Intl.DateTimeFormat(navigator.language, {
        hour: "numeric",
        minute: "2-digit",
      }).format(new Date(transaction.transactionDate));

      return {
        ...transaction,
        formattedAmount: decorateCurrencyValue(transaction.amount.display),
        formattedDate: `${time} – ${date}`,
      };
    }
  );

  return { transactions, loading, error };
};
