import React from "react";

import {
  ToggleContainer,
  ToggleRow,
  StyledToggle,
  StyledLabel,
  StyledTrack,
  StyledSwitch
} from "src/components/toggle/Toggle.styles";

import { BrandColor, ColorNames } from "src/theme/theme";
import { DataTestIds } from "src/util/testing-util/test-utils";

export interface ToggleProps {
  displayLabel?: string | JSX.Element;
  name: string;
  align?: "left" | "right";
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  color?: BrandColor;
  cy?: string;
  [key: string]: unknown;
}

export const Toggle = (toggleProps: ToggleProps): JSX.Element => {
  const {
    displayLabel,
    name,
    align = "right",
    checked = false,
    onChange,
    disabled = false,
    color = ColorNames.LAKE,
    cy = "",
    ...props
  } = toggleProps;

  return (
    <ToggleContainer>
      <ToggleRow align={align}>
        {!!displayLabel && (
          <label className="displayLabel" htmlFor={name}>
            {displayLabel}
          </label>
        )}
        <StyledToggle
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          color={color}
          name={name}
          id={name}
          type="checkbox"
          data-testid={DataTestIds.TOGGLE}
          {...props}
        />
        <StyledLabel htmlFor={name}>
          <StyledTrack color={color} disabled={disabled} />
          <StyledSwitch disabled={disabled} data-cy={cy} data-testid={DataTestIds.TOGGLE_SWITCH} />
        </StyledLabel>
      </ToggleRow>
    </ToggleContainer>
  );
};
