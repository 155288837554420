import React, { useEffect, useState } from "react";
import { Form, useFetcher, useActionData } from "react-router-dom";
import { useCookies } from "react-cookie";

import { useNavbar } from "src/util/useNavbar";
import { showNotice } from "src/store/alertState";
import useNavigate from "src/util/useNavigate";
import { NavRoutes } from "src/routes/navRoutes";
import { NavigationDirection } from "src/store/navigationState";
import { ColorNames } from "src/theme/theme";
import { RouteActionResponse } from "src";
import { formatDateRange } from "src/util/stringUtils";
import { storedCookieDataKey } from "src/util/localStorage";
import { UpcomingAuction } from "src/generated/client";
import States from "src/data/states.json";

import { AuthTitle, AuthCaption } from "src/pages/auth/Auth.styles";
import { StyledLandingPage, TetonRidgeAuctionsContainer } from "../Landing.styles";

import Layout from "src/components/core-layout/Layout";
import { Button } from "src/components/button/Button";
import { ListButton } from "src/components/lists/ListButton";
import { Select } from "src/components/forms/select/Select";

const TetonRidgeAuctions = () => {
  const [selectedAuction, setSelectedAuction] = useState<UpcomingAuction>();
  const { data: auctions, state: loadingState, load } = useFetcher<UpcomingAuction[]>();
  const actionData = useActionData() as RouteActionResponse<{ success: boolean; startDate: string; endDate: string }>;
  const [_, setCookie] = useCookies([
    storedCookieDataKey.TETON_RIDGE_AUCTION_START_DATE,
    storedCookieDataKey.TETON_RIDGE_AUCTION_END_DATE,
    storedCookieDataKey.TETON_RIDGE_AUCTION_LOCATION
  ]);
  const navigate = useNavigate();
  const [passedStateCheck, setPassedStateCheck] = useState(false);

  useNavbar({
    backAction: () => navigate(NavRoutes.LANDING, { direction: NavigationDirection.BACKWARD }),
    orgSwitcher: false
  });

  useEffect(() => {
    if (!auctions && loadingState === "idle") load(NavRoutes.API_ONELINE_AUCTION);
  }, [auctions, loadingState, load]);

  useEffect(() => {
    if (!!actionData?.validationErrors) {
      if (!!actionData.validationErrors.auctionDate) {
        setCookie(storedCookieDataKey.TETON_RIDGE_AUCTION_START_DATE, selectedAuction?.date.start);
        setCookie(storedCookieDataKey.TETON_RIDGE_AUCTION_END_DATE, selectedAuction?.date.end);
        setCookie(storedCookieDataKey.TETON_RIDGE_AUCTION_LOCATION, selectedAuction?.location);

        navigate(NavRoutes.TETON_RIDGE_AUCTION_EARLY);
      } else if (!!actionData.validationErrors.cutOffDate) {
        navigate(NavRoutes.TETON_RIDGE_AUCTION_LATE);
      }
    }
  }, [actionData?.validationErrors, navigate]);

  useEffect(() => {
    if (!!actionData?.response && actionData.response.success) {
      setCookie(storedCookieDataKey.TETON_RIDGE_AUCTION_START_DATE, actionData.response.startDate);
      setCookie(storedCookieDataKey.TETON_RIDGE_AUCTION_END_DATE, actionData.response.endDate);
      navigate(NavRoutes.SIGN_UP);
    }
  }, [actionData?.response, navigate]);

  return (
    <Layout loading={loadingState === "loading"}>
      {!passedStateCheck ? (
        <TetonRidgeLocationCheck complete={() => setPassedStateCheck(true)} />
      ) : (
        <StyledLandingPage>
          <AuthTitle children="Auction Date" />
          <AuthCaption gray>Choose the auction you would like to get lending for:</AuthCaption>

          <TetonRidgeAuctionsContainer>
            {auctions?.map((auction) => (
              <ListButton
                key={auction.description}
                label={auction.description || ""}
                sublabel={formatDateRange(auction.date)}
                onClick={() => {
                  if (auction !== selectedAuction) setSelectedAuction(auction);
                  else setSelectedAuction(undefined);
                }}
                selectable={true}
                isSelected={auction === selectedAuction}
              />
            ))}
          </TetonRidgeAuctionsContainer>

          <Form method="put">
            <input type="hidden" name="auctionStartDate" defaultValue={selectedAuction?.date.start} />
            <input type="hidden" name="auctionEndDate" defaultValue={selectedAuction?.date.end} />
            <input type="hidden" name="auctionCutOffDate" defaultValue={selectedAuction?.cutOffDate} />

            <Button
              type="submit"
              onClick={() => undefined}
              disabled={!selectedAuction}
              color={ColorNames.MIDNIGHT}
              raised={true}
              children="Continue"
            />
          </Form>
        </StyledLandingPage>
      )}
    </Layout>
  );
};

const TetonRidgeLocationCheck = ({ complete }: { complete: () => void }) => {
  const [state, setState] = useState("");

  const statesForDropdown = [
    {
      label: "Choose state",
      value: ""
    },
    ...States
  ];

  const checkAction = () => {
    if (["TX", "OK"].includes(state)) {
      complete();
    } else {
      showNotice("You must be a resident of Texas or Oklahoma in order to qualify for a horse loan from Teton Ridge.", {
        error: true
      });
    }
  };

  return (
    <StyledLandingPage>
      <AuthTitle children="Get Started" />
      <AuthCaption gray>
        We are excited to work with you on your upoming horse purchase.
        <br />
        <br />
        Please let us know what state you reside in.
      </AuthCaption>

      <fieldset style={{ flex: "1 1 0px", marginTop: "32px" }}>
        <Select
          name="state"
          label="State"
          options={statesForDropdown}
          onChange={(event) => setState(event.currentTarget.value)}
        />
      </fieldset>

      <Button onClick={checkAction} color={ColorNames.MIDNIGHT} raised={true} children="Continue" />
    </StyledLandingPage>
  );
};

export default TetonRidgeAuctions;
