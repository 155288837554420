import React from "react";
import styled from "styled-components";
import { colorPalette as colors, ColorNames } from "src/theme/theme";

const BankingAgreementHolder = styled.div`
  div > ol > li::marker {
    font-size: 1.5em;
  }

  ol {
    counter-reset: my-counter 0;
    list-style-type: none;
    padding-inline-start: 20px;
  }

  ol > li::marker {
    content: counters(my-counter, ".") " ";
    font-weight: bold;
  }

  ol > li {
    counter-increment: my-counter;
    margin-bottom: 10px;
  }

  table {
    margin: 20px 0;
  }

  td {
    border: 1px solid black;
    vertical-align: top;
  }
  a {
    color: ${colors[ColorNames.MIDNIGHT].hex};
  }
`;

export default (
  <BankingAgreementHolder>
    <h1>Letter Technology, Inc. Consumer Deposit Account Agreement</h1>

    <p>
      Please read this Letter Technology, Inc. Consumer Deposit Account Agreement (the &ldquo;Agreement&rdquo;)
      carefully and retain it for your future reference. This Agreement contains the general terms, conditions and
      disclosures related to the demand deposit account (the &ldquo;Account&rdquo;) made available to eligible consumers
      by Letter Technology, Inc. (&ldquo;Program Partner&rdquo;), the program partner responsible for managing the
      Account program, in partnership with Blue Ridge Bank, N.A. (the &ldquo;Bank&rdquo;).
    </p>
    <p>
      When you see the words &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo; in this Agreement, it refers to
      the Bank, the Program Partner acting as agent for the Bank, and any of the Bank&rsquo;s affiliates, successors,
      assignees, agents or service providers. When you see the words &ldquo;you&rdquo; or &ldquo;your,&rdquo; it refers
      to you, the owner of the Account, as well as your Authorized Users, personal representatives, executors,
      administrators, and successors.
    </p>
    <p>
      By opening or continuing to hold an account with us, you agree to be bound by this Agreement as well as any other
      agreement or document we may provide to you from time to time in connection with the Account.
    </p>
    <p>
      <strong>
        IMPORTANT NOTE: THIS AGREEMENT IS SUBJECT TO BINDING ARBITRATION AND A WAIVER OF CLASS ACTIONS AND YOUR RIGHT TO
        A JURY. THE TERMS OF ARBITRATION AND THE WAIVER APPEAR IN SECTION 8 OF THIS AGREEMENT.
      </strong>
    </p>

    <hr />
    <br />

    <ol>
      <li>
        <h2>Account Overview</h2>

        <ol>
          <li>
            <strong>How to Contact Us</strong>
            <p>
              You may contact us with any questions or concerns regarding your Account. All communication between you
              and us will be handled by the Program Partner. The best way to contact us is through the Program
              Partner&rsquo;s mobile application. You may also contact us by phone at (855) 954-1888, 8:00 AM to 5:00 PM
              Pacific Time, Monday to Friday, excluding holidays. You can also contact us via email at{" "}
              <a href="mailto:help@letter.co">help@letter.co</a>.
            </p>
          </li>

          <li>
            <strong>Type of Account and Eligibility</strong>
            <p>
              The Account is a demand deposit account used to hold your deposits and make certain types of payments and
              transfers. The Account is available to United States citizens or lawful permanent residents within the
              United States or its territories who are at least 18 years of age, have a U.S. physical address or with
              military addresses (APO or FPO), and have a valid Social Security Number or Tax Identification Number. The
              Account is only available to individuals for personal, family or household purposes and may not be opened
              by a business in any form or used for business purposes.
            </p>
            <p>
              We may decline to open an Account for any reason at the sole discretion of the Bank or Program Partner. We
              are not liable for any damages or liabilities resulting from refusal of an Account relationship.
            </p>
          </li>

          <li>
            <strong>&ldquo;Basic&rdquo; and &ldquo;Premium&rdquo; Account Plans</strong>
            <p>
              The Program Partner offers two packages of features with your Account: A &ldquo;Basic Plan&rdquo; or a
              &ldquo;Premium Plan.&rdquo;
            </p>
            <p>The Basic Plan provides you with access to your Account, as well as the following features:</p>
            <ul>
              <li>Up to 3 free physical debit cards.</li>
              <li>Up to 4 active virtual debit cards.</li>
              <li>Up to 4 authorized users.</li>
              <li>Ability to open up to 3 Accounts.</li>
            </ul>
            <p>There is no monthly service fee for the Basic Plan.</p>
            <p>The Premium Plan provides you with access to enhanced features from the Program Partner, including:</p>
            <ul>
              <li>Fee-free domestic wires (see Section 6 below).</li>
              <li>Up to 10 free physical debit cards.</li>
              <li>Up to 20 active virtual debit cards.</li>
              <li>Account use by up to 20 authorized users.</li>
              <li>Ability to open up to 10 Accounts.</li>
              <li>Additional features in Letter&rsquo;s Digital Family Office.</li>
            </ul>
            <p>There is a monthly service fee for participating in the Premium Plan (see Section 3.13).</p>
            <p>
              The full set of features for each package will be described in the Program Partner&rsquo;s Terms of Use
              when you sign up.
            </p>
          </li>

          <li>
            <strong>Online or Mobile Access To Your Account</strong>
            <p>
              When you open an Account, you will also be enrolled in online banking services through a mobile
              application (the &ldquo;Letter App) offered by the Program Partner that you can use to access your
              Account, view information about your Account and conduct certain transactions. You may also be given
              access to various features through the Letter App separate from your Account.
            </p>
            <p>
              Unless otherwise specified in this Agreement, the Letter App and any of its features are governed by the
              Program Partner&rsquo;s Terms of Use and Privacy Policy available at{" "}
              <a href="http://www.letter.co/terms">www.letter.co/terms</a>. Please review these documents carefully
              because by using the Letter App, you agree to be bound by them. These services are not provided or
              controlled by the Bank or this Agreement, and you agree not to hold the Bank liable for issues that may
              arise from your use of the services or features.
            </p>
          </li>

          <li>
            <strong>Paperless Account</strong>
            <p>
              To open an Account, you must agree to go &ldquo;paperless.&rdquo; This means that you must (1) provide us
              with and continue to maintain a valid email address and (2) accept electronic delivery of all
              communications that we need or decide to send you in connection with your Account by agreeing to our
              Letter ESIGN Consent located at <a href="http://www.letter.co/terms">www.letter.co/terms</a>.
            </p>
          </li>

          <li>
            <strong>Titling and Ownership of Accounts</strong>
            <p>
              The Account may only be owned in the name of one person who may make deposits and transfer or withdraw
              funds. The Account cannot be owned or titled as a joint account, trust account, a Uniform Transfers to
              Minors (UTMA) account or a Payable-On-Death (POD) account.
            </p>
          </li>

          <li>
            <strong>Authorized Users</strong>
            <p>
              You may be given the ability to designate additional individuals (&ldquo;Authorized Users&rdquo;) whom you
              authorize to access your Account and/or conduct transactions through your Account. By establishing an
              Authorized User, you understand that any transaction authorization, instruction or any other action
              concerning your Account that your Authorized User engages in shall be deemed authorized by you and valid
              and we are under no obligation to investigate the authorization, instruction or activity. You also agree
              that you will not hold us liable for acting upon any such authorization, instruction or activity. We will
              continue to treat all actions taken by your Authorized User as authorized by you until you revoke the
              Authorized User&rsquo;s access to your Account by notifying us by email at{" "}
              <a href="mailto:help@letter.co">help@letter.co</a>. We also reserve the right to terminate your Authorized
              Users&rsquo; access to the Account for any reason and without advance notice.
            </p>
          </li>

          <li>
            <strong>How To Open an Account</strong>
            <p>
              You may open an Account by downloading the Letter App application from the Google Play Store or Apple App
              Store and following the instructions within the application.
            </p>
            <p>
              <strong>Important information about procedures for opening a new Account:</strong> To help the government
              fight the funding of terrorism and money laundering activities, federal law requires all financial
              institutions to obtain, verify and record information identifying each person who opens an Account,
              including any Authorized Users who may access an Account. This means that when you open an Account, we
              will ask for your name, street address, Social Security Number or Tax Identification Number, date of birth
              and other information that will allow us to identify you. We may also ask to see identifying documents,
              such as a driver&rsquo;s license.
            </p>
          </li>

          <li>
            <strong>Minimum Deposits and Balances</strong>
            <p>
              You must deposit $10,000 to open this account. You must maintain a minimum balance of $2,500 in your
              Account.
            </p>
            <p>
              There is no fee for failing to maintain the minimum Account balance, but if your balance remains below the
              minimum for thirty (30) consecutive days, we may close your Account. See Section 3.11 for information
              concerning Account closures.
            </p>
          </li>

          <li>
            <strong>Interest Disclosures</strong>
            <p>This Account is not interest-bearing. No interest will be paid on this Account.</p>
          </li>

          <li>
            <strong>Power of Attorney and Attorneys-In-Fact</strong>
            <p>
              We may allow you to give another person (known as an &ldquo;attorney-in-fact&rdquo;) power of attorney to
              act on your behalf for your Account. You must obtain written approval from us before we will honor any
              power of attorney. Email us at <a href="mailto:help@letter.co">help@letter.co</a> for approval if you plan
              to create a power of attorney. Please be aware that it may take up to one week for us to review your
              request. If approved, we will honor orders and instructions from your attorney-in-fact until (1) we
              receive a written revocation from you; (2) we are notified that you or your attorney-in-fact have died or
              become incapacitated; or (3) we terminate our acceptance of the power of attorney. We may terminate our
              acceptance at any time, for any reason and without notice to you, and you agree not to hold us liable for
              any damages that may result from such action. You also agree to hold us harmless for any actions we take
              based on the instructions of your attorney-in-fact, including if it is later determined that your power of
              attorney was invalid or improperly executed.
            </p>
          </li>

          <li>
            <strong>Death or Incapacitation</strong>
            <p>
              You agree that if we receive notification or if we have reason to believe that you have died or become
              legally incapacitated, we may place a hold on your Account and refuse all transactions until we know and
              have verified the identify of your successor. Until we receive notice and any required proof of death or
              incapacitation, we may continue to accept deposits and process transactions to your Account. Your estate
              will be responsible for repaying us for any tax liability resulting from payment of your account balance
              to your estate. You agree to hold us harmless for any actions we take based on our belief that you have
              died or become incapacitated. If certain payments originating from government entities are deposited into
              your Account after your death, we may be required to return those payments to the originator upon notice.
            </p>
          </li>

          <li>
            <strong>Our Relationship With You</strong>
            <p>
              By opening an Account, we are establishing an Account relationship with you and committing to act in good
              faith and to the exercise of ordinary care in our dealings with you as defined by the Uniform Commercial
              Code as adopted by the State of Virginia. This Agreement and the Account relationship do not create a
              fiduciary relationship or any other special relationship between you and us.
            </p>
          </li>

          <li>
            <strong>Third-Party Service Providers</strong>
            <p>
              We work with one or more third-party service providers, including Unit Finance Inc. (&ldquo;Unit&rdquo;),
              in connection with your Account. In particular, Unit may assist us in processing transactions, handing
              account operations, and providing technological connection to the Program Partner and the Bank. By using
              your Account, you license and authorize us to access information maintained by Unit and/or other third
              parties, on your behalf as your agent, and you expressly authorize such third parties to disclose your
              information to us. In particular, you agree that Unit may perform any functions on your Account that we
              are otherwise authorized to perform and that Unit&rsquo;s access and use of your information will be
              governed by the terms of Unit&rsquo;s Privacy Policy, available at{" "}
              <a href="https://www.unit.co/clients-privacy-policy">https://www.unit.co/clients-privacy-policy</a>.
            </p>
          </li>

          <li>
            <strong>Confidentiality and Our Privacy Policy</strong>
            <p>
              Your privacy is very important to us. Under this Agreement, we will generally only disclose information to
              third parties about your Account or the transactions you make:
            </p>
            <ul>
              <li>
                To verify your identity and determine if we should open an Account for you using services provided to us
                by third parties.
              </li>
              <li>Where it is necessary for completing a transaction.</li>
              <li>
                To verify the existence and condition of your Account for a third party such as a credit bureau or
                merchant.
              </li>
              <li>To comply with government agency or court orders.</li>
              <li>As disclosed in our Privacy Policy.</li>
              <li>If you give us your written permission to share your information.</li>
              <li>
                Please refer to our Privacy Policy at{" "}
                <a href="https://www.mybrb.com/privacy-policy.html">https://www.mybrb.com/privacy-policy.html</a> for
                more information about our commitment to you, your privacy rights and who we share data and information
                with.
              </li>
            </ul>
            <p>
              In addition, we disclose your information to Unit in order to facilitate the Account and for other
              ancillary purposes. You agree to Unit&rsquo;s use of your information in accordance with the terms of
              Unit&rsquo;s Privacy Policy{" "}
              <a href="https://www.unit.co/clients-privacy-policy">https://www.unit.co/clients-privacy-policy</a>.
            </p>
          </li>

          <li>
            <strong>Cell Phone Communications</strong>
            <p>
              By providing us with your telephone number for a cellular phone or other wireless device, you are
              expressly consenting to receiving non-marketing communications at that number&mdash;including but not
              limited to prerecorded or artificial voice message calls, text messages, and calls made by an
              auto-dialer&mdash;from us and our affiliates and agents. This consent applies to all telephone numbers you
              provide to us now or in the future. Your telephone or mobile service provider may charge you for these
              calls or messages. You also agree that we may record or monitor any communications for quality control and
              training purposes.
            </p>
          </li>

          <li>
            <strong>Our Business Days</strong>
            <p>Our business days are Monday through Friday, excluding federal holidays.</p>
          </li>
        </ol>
      </li>

      <li>
        <h2>Your Account Responsibilities</h2>
        <p>
          We strive to keep your Account secure and provide you with tools and services to help you manage your Account.
          However, there are certain things you should do to protect your Account and your funds.
        </p>

        <ol>
          <li>
            <strong>Notify Us If Your Information Changes</strong>
            <p>
              You must notify us immediately if there is a change to your name, telephone number, mailing address, email
              address or any other information you have provided us so that we can continue to provide you with
              statements and important notices concerning your Account.
            </p>
          </li>

          <li>
            <strong>Keep Track of Your Transactions and Available Balance</strong>
            <p>
              It is very important that you keep track of your transactions and the funds in your Account that are
              available for you to use (&ldquo;Available Balance&rdquo;) by reviewing your transaction history. It is
              also important to understand that your Available Balance may not reflect transactions you have authorized
              that have not yet been presented to us for payment.
            </p>
            <p>
              You are also responsible for reviewing your Account statements as they are made available to you for
              errors or unauthorized activity. If you identify an error or unauthorized activity, you must notify us
              promptly to avoid losing your money. Please refer to Sections 5.7 and 6.10 for information concerning
              errors and unauthorized activity.
            </p>
          </li>

          <li>
            <strong>Protect Your Account Information</strong>
            <p>
              It is very important that you protect your Account information to prevent unauthorized transactions and
              fraud. Keep your Account number, debit card, ATM card and statements secure at all times, and be careful
              about who you share this information with. If the Program Partner provides you with access to the Letter
              App, make sure to also keep your computer or mobile device secure at all times and avoid accessing the
              Letter App when others can see your screen.
            </p>
            <p>
              If your Account number, debit card, ATM card, mobile device or the Letter App login credentials are lost
              or stolen, notify us immediately to limit your liability for unauthorized transactions that may occur.
              Please refer to Section 5.6 for information and applicable deadlines for notifying us of losses or theft.
            </p>
          </li>
        </ol>
      </li>

      <li>
        <h2>General Rules Governing Your Account</h2>
        <p>
          You understand that any payment instruction or activity performed using the Letter App provided to you by the
          Program Partner shall be deemed authorized by you and valid and we are under no obligation to investigate the
          instruction or activity.
        </p>

        <ol>
          <li>
            <strong>Deposits Into Your Account</strong>
            <p>
              You may make deposits into your Account using any of these methods described below. We do not charge you
              any fees for making deposits.
            </p>
            <table cellSpacing="0" cellPadding="10px">
              <tbody>
                <tr>
                  <td>
                    <p>
                      <strong>Deposit Method</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>Limitations for Your Account</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Direct Deposit*</p>
                  </td>
                  <td>
                    <p>No limits</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Internal transfers between eligible accounts managed by the Program Partner</p>
                  </td>
                  <td>
                    <p>No limits</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>ACH transfers to your Account from a linked bank account with another institution**</p>
                  </td>
                  <td>
                    <p>$25,000/day</p>
                    <p>$50,000/month</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Domestic wire</p>
                  </td>
                  <td>
                    <p>No limits</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Cash deposit at in-network ATM</p>
                  </td>
                  <td>
                    <p>$1,000/transaction</p>
                    <p>$2,000/day</p>
                    <p>&nbsp;</p>
                    <p>
                      You can use your Letter debit card to make deposits at in-network ATMs. You can identify an
                      in-network ATM by looking for the Allpoint logo on or near the ATM. You cannot make cash deposits
                      at out-of-network ATMs.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <p>
                      * The recipient&rsquo;s name on any direct deposit or ACH transfer must match the name on the
                      Account or the deposit may be returned to the originator.
                    </p>
                    <p>
                      ** You may transfer funds between your Account and another bank account. The linked bank account
                      must be located in the United States.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <strong>We do not accept deposits by mail, or</strong>{" "}
              <strong> paper check or foreign currency deposits:</strong> We are not liable for deposits of any kind
              that you mail to us, including if it is lost in transit, lost in the mail, or otherwise not received by
              us.
            </p>
            <ul>
              <li>
                <strong>Cash:</strong> We do not accept cash deposits made by mail. If you mail us a cash deposit, we
                will send the cash back to you.
              </li>
              <li>
                <strong>Paper Checks:</strong> If you mail a paper check to us, including personal checks, money orders
                or cashier&rsquo;s checks, we may apply the check to any negative balance you may have on your Account
                or send the check back to you.
              </li>
              <li>
                <strong>Foreign Currency:</strong> We do not accept any deposits in foreign currency. Any deposits
                received in foreign currency, whether in the form of cash or check, will be sent back to you.
              </li>
            </ul>
            <p>
              We will send all items back to the address we have for you on file and we are not liable if you do not
              receive the items.
            </p>
            <p>
              You may only deposit with us funds that are immediately available, which under applicable law are
              irreversible and are not subject to any lien, claim or encumbrance.
            </p>
            <p>
              For more information about deposits and when funds from a deposit will be made available to you, please
              refer to Section 3.2.
            </p>
          </li>

          <li>
            <strong>Our Funds Availability Policy</strong>
            <p>
              It is our policy to make deposits made to your Account each business day available for withdrawal
              according to the table below.The end of the business day is referred to as the "deposit cut-off time". If
              you make a deposit before our cut-off time on a business day that we are open, we will consider that day
              to be the day of your deposit. However, if you make a deposit after our cut-off time or on a day we are
              not open, we will consider the deposit to be made on the next business day that we are open.
            </p>
            <table cellSpacing="0" cellPadding="10px">
              <tbody>
                <tr>
                  <td>
                    <p>If you make a deposit via&hellip;</p>
                  </td>
                  <td>
                    <p>The cut-off time is&hellip;</p>
                  </td>
                  <td>
                    <p>And those funds are available&hellip;</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Internal transfer between two Accounts you have with us</p>
                  </td>
                  <td>
                    <p>N/A</p>
                  </td>
                  <td>
                    <p>Immediately</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Direct deposit</p>
                  </td>
                  <td>
                    <p>3:00 PM ET</p>
                  </td>
                  <td>
                    <p>Same business day</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Domestic wire</p>
                  </td>
                  <td>
                    <p>3:00 PM ET</p>
                  </td>
                  <td>
                    <p>Same business day</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Cash deposit at in-network ATM</p>
                  </td>
                  <td>
                    <p>3:00 PM ET</p>
                  </td>
                  <td>
                    <p>Next business day</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              The availability of funds transferred to your Account from a linked bank account held by another financial
              institution follow different rules. Please refer to Section 3.6 for more information.
            </p>
          </li>

          <li>
            <strong>Problems that Could Occur With Deposits</strong>
            <p>
              If a deposit or transfer to your Account is returned or rejected by the paying financial institution for
              any reason, or if there is an error or mistake involving a deposit or transfer, we may deduct the amount
              of the deposit, transfer, or error without prior notice to you. If there are insufficient funds in your
              Account at the time, your Account may become overdrawn. Please refer to Section 3.10 concerning overdrafts
              for more information.
            </p>
          </li>

          <li>
            <strong>Transfers To or From Two Accounts You Have With Us</strong>
            <p>
              You may transfer funds from one Account in your name to another account in your name held by us that is
              managed by the Program Partner (an &ldquo;internal transfer&rdquo;). There are no limits on the number of
              internal transfers you may make, and internal transfers are immediately available.
            </p>
          </li>

          <li>
            <strong>Withdrawals From Your Account</strong>
            <p>
              You may withdraw funds up to the amount of your Available Balance in your Account using any of the
              following methods.
            </p>
            <table cellSpacing="0" cellPadding="10px">
              <tbody>
                <tr>
                  <td>
                    <p>
                      <strong>Withdrawal Method</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>Limitations for Your Account</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Internal transfers between eligible accounts managed by the Program Partner</p>
                  </td>
                  <td>
                    <p>No limits</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>ACH transfers from your Account to a linked bank account at another institution*</p>
                  </td>
                  <td>
                    <p>$25,000/day</p>
                    <p>$50,000/month</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      ACH transfer to a merchant or other third party you authorize to withdraw from your Account using
                      your routing number and account number
                    </p>
                  </td>
                  <td>
                    <p>&nbsp;</p>
                    <p>No Limits</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Purchases or other POS transactions using your Letter debit card at merchants that have agreed to
                      accept that card
                    </p>
                  </td>
                  <td>
                    <p>$10,000/day</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>ATM withdrawals using your Letter debit card</p>
                  </td>
                  <td>
                    <p>$2,500/day</p>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <p>
                      * You may transfer funds between your Account and another bank account. The linked bank account
                      must be located in the United States.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              If you have been issued a debit card or ATM card for your Account, there may also be limitations that
              apply to your use of the card. Please refer to the applicable cardholder agreement provided to you with
              your card for information on any applicable limitations and other terms.
            </p>
            <p>You cannot withdraw funds by check, and we do not permit you to create checks drawn on your Account.</p>
            <p>
              Before permitting a withdrawal or other transaction, we may request that you provide us with additional
              information or documentation that we deem necessary to confirm your identity or to prevent illegal
              activity. We may refuse the transaction if you do not comply with our request.
            </p>
          </li>

          <li>
            <strong>Transfers To or From Linked Bank Accounts</strong>
            <p>
              You may be provided with the ability to link another bank account you have with another financial
              institution (&ldquo;linked bank account&rdquo;) to your Account with us in order to make inbound and
              outbound ACH transfers between the linked bank account and your Account through the Letter App. An
              &ldquo;inbound transfer&rdquo; moves funds into your Account from a linked bank account. An
              &ldquo;outbound transfer&rdquo; moves funds from your Account to a linked bank account.
            </p>
            <p>
              For inbound transfers, we will debit your linked bank account and credit your Account with us on the next
              business day after you initiate the transfer. The funds you transfer to your Account will be made
              available to you by the fifth (5<sup>th</sup>) business day after the funds have been credited to your
              Account.
            </p>
            <p>
              For outbound transfers, we will debit your Account with us and credit your linked bank account on the next
              business day after you initiate the transfer. The financial institution that holds your linked bank
              account determines when those funds will be made available to you in your linked bank account.
            </p>
            <p>
              The cut-off time for both inbound and outbound transfers is 3:00 PM ET. Transfer requests that you make on
              a business day before the cut-off time will be considered initiated on that day. Transfer requests that
              you make after the cut-off time, or on a day that is not a business day, will be considered initiated the
              following business day.
            </p>
            <p>
              There are limits to the dollar amount of both inbound and outbound transfers you can make. Please see
              Sections 3.1 and 3.5 for relevant limitations.
            </p>
            <p>
              You agree that you will only attempt to link a bank account for which you have the authority to transfer
              funds. You also agree to indemnify and hold us harmless from any claims by any person related to the
              linked bank account, including any other owner of the linked bank account. You may only link a bank
              account that is a deposit account, such as a checking, savings or money market account.
            </p>
          </li>

          <li>
            <strong>No Illegal Activity, Internet Gambling and Right to Refuse Transactions</strong>
            <p>
              You must not use your Account for any illegal purposes or, regardless of whether it is legal or illegal,
              for internet gambling. We may deny any transaction or refuse to accept any deposit that we believe is
              related to illegal activity, online gambling or for any other reason at our discretion.
            </p>
          </li>

          <li>
            <p>
              <strong>How We Post Transactions To Your Account and Determine Your Available Balance</strong>
            </p>
            <ol>
              <li>
                <strong>Posting Overview</strong>
                <p>
                  To understand how we post transactions to your Account, it is important to first understand the
                  difference between your Available Balance and your ledger balance. Your Available Balance is the
                  amount of money you have in your Account at any given time that is available for you to use. Your
                  ledger balance is the balance in your Account at the beginning of the day after we have posted all
                  transactions to your Account from the day before. We use your Available Balance to authorize your
                  transactions throughout the day and determine whether you have sufficient funds to pay your
                  transactions. Here are some additional terms that are helpful to understand:
                </p>
                <ul>
                  <li>Credit and debit &ndash; A credit increases your balance and a debit decreases your balance.</li>
                  <li>
                    Post or posted &ndash; Transactions that are paid from or deposited to your Account. Posted
                    transactions will either increase or decrease both your Available Balance and your ledger balance.
                  </li>
                  <li>
                    Pending &ndash; Transactions that we receive notice of and are scheduled to post to your Account.
                    Pending transactions affect your Available Balance, but not your ledger balance.
                  </li>
                  <li>
                    Card authorization and settlement &ndash; When you use a debit card to make a purchase, the
                    transaction occurs in two steps: card authorization and settlement. Card authorizations reduce your
                    Available Balance, but not your ledger balance. Settlement reduces both your Available Balance and
                    your ledger balance. Card authorizations are removed when settlement occurs or after a certain
                    number of days have passed, whichever is sooner. Please see Section 3.8.4 for more information about
                    card authorizations and settlement.
                  </li>
                </ul>
              </li>

              <li>
                <strong>Posting Order</strong>
                <p>
                  We receive transactions throughout the day and post them to your Account as they are received and in
                  the order that they are received. Note that for debit card transactions, we consider the transaction
                  received when the merchant requests settlement, which may occur several days after you authorize the
                  transaction. See Section 3.8.4 for additional information concerning how debit card transactions are
                  processed.
                </p>
                <p>
                  We may change the order in which we post transactions to your Account at our discretion and without
                  notice to you. It is therefore important for you to keep track of the deposits you make and the
                  transactions you authorize to make sure there are sufficient funds in your Account to cover all
                  transactions and any applicable fees.
                </p>
              </li>

              <li>
                <strong>Determining Your Available Balance</strong>
                <p>
                  To determine your Available Balance, we start with your ledger balance at the beginning of the
                  business day, add any pending credits or deposits that we make available to you, and subtract any card
                  authorizations and pending debits. All transactions are debited or credited from your Available
                  Balance in the order received.
                </p>
                <p>
                  You can check your available balance at any time through the Letter App. Keep in mind that your
                  Available Balance may not reflect every transaction you have initiated or previously authorized. For
                  example, your Available Balance may not include transactions you have authorized that we have not
                  received.
                </p>
              </li>

              <li>
                <strong>Debit Card Authorizations</strong>
                <p>
                  If you are issued a debit card with your Account, you will be able to use your card to pay for goods
                  or services or conduct other transactions with a merchant. When you engage in a transaction with a
                  merchant using your debit card, the merchant will request preauthorization (&ldquo;card
                  authorization&rdquo;) for the transaction. If there are sufficient available funds in your Account, we
                  will approve the request and reduce your Available Balance for as long as the card authorization
                  remains on your Account.
                </p>
                <p>Your Available Balance will generally be reduced by the amount of the card authorization.</p>
                <p>
                  The card authorization will remain on your Account until the merchant sends us the final amount of the
                  transaction and requests payment (&ldquo;settlement&rdquo;). In most cases, if the merchant does not
                  request settlement, or the merchant is delayed in requesting settlement, the card authorization will
                  automatically be removed after 3 days. However, certain merchants such as hotels may hold for a period
                  of up to 7 days.
                </p>
                <p>
                  It is important to understand that the merchant controls the timing of card authorizations or
                  settlement. A merchant may request settlement after the card authorization has been removed from your
                  Account. This means that if you use the funds in your Account after the card authorization has been
                  removed and the merchant later requests settlement, your Account may become overdrawn. A merchant may
                  also request settlement for an amount that is different than the card authorization. Therefore, it is
                  important that you keep track of your transactions and your balance. Once we have approved a card
                  authorization, we cannot stop the transaction and you will be responsible for repaying any negative
                  balance that may occur.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <strong>Statements</strong>
            <p>
              Statements will periodically be provided to you via the Letter App.as long as your Account is not
              inactive. You will receive a statement monthly as long as you have transactions on your Account during the
              statement period. If there were no transactions on your Account, we will provide you statements at least
              quarterly. You will not receive paper statements.
            </p>
          </li>

          <li>
            <strong>Overdrafts, Nonsufficient Funds and Negative Balances</strong>
            <p>
              We do not permit you to overdraw your Account. If the Available Balance in your Account is not sufficient
              to cover any transaction you have authorized, we may return the transaction or refuse to process the
              transaction. However, there may be instances where your Account can still go into the negative, such as if
              a deposit you make is returned. You must make a deposit immediately to cover any negative balance.
            </p>
            <p>
              If your Account has a negative balance for ten (10) calendar days or more, we may suspect your Account.
              Account suspension will result in disabling your access to the Account through the Letter App. The Program
              Partner may also disable your access to other Letter App features if the Account is suspended. If your
              Account is suspended, you will need to contact us by phone at (855) 954-1888 or email at{" "}
              <a href="mailto:help@letter.co">help@letter.co</a> to regain access to the Account.
            </p>
            <p>
              If your Account has a negative balance for sixty (60) calendar days or more, we may close your Account.
            </p>
          </li>

          <li>
            <strong>Closing Your Account and Account Suspensions</strong>
            <p>
              You can close your Account at any time and for any reason by e mail at{" "}
              <a href="mailto:help@letter.co">help@letter.co</a>.We reserve the right to refuse your request if you have
              a negative balance on your Account. We recommend that you transfer or withdraw any funds you may have in
              the Account prior to submitting a request to close the Account to avoid delays in receiving your funds.
            </p>
            <p>
              We may also suspend or close your account at our discretion with or without notice. This includes if we
              believe you are using your Account for fraudulent or illegal purposes or in violation of law or
              regulation, this Agreement, any other agreement you may have with us or Unit, or if you otherwise present
              undue risk to us or Unit. We may also close your Account if you have not made any deposits or withdrawals
              from your Account in over twelve (12) months. In addition, cancelling your enrollment in the Letter App
              with the Program Partner will result in our closure of the Account and our return of your funds. We are
              not responsible to you for any damages you may suffer as a result of the closure or suspension of your
              Account.
            </p>
            <p>
              If your Account is closed with a balance greater than $1.00, we will return any funds you may have in the
              Account in excess of $1.00 to you by ACH transfer to another bank account or by paper check. We reserve
              the right not to return the funds to you if the balance on your account is $1.00 or less. The closure of
              your Account or termination of this Agreement does not impact any right or obligation that arose prior to
              closure or termination, or any right or obligation that, by its nature, should survive termination
              (including, but not limited to, any indemnification obligation by you, our limitations of liability, and
              any terms governing arbitration).
            </p>
          </li>

          <li>
            <strong>Dormancy, Inactivity and Unclaimed Property</strong>
            <p>
              State law and our policy govern when your Account is considered dormant. Your Account is usually
              considered dormant if you have not accessed your Account, communicated to us about your Account or
              otherwise shown an interest in your Account within the period of time specified under applicable law. Each
              state has varying laws as to when an account becomes dormant, and we may be required to send the balance
              in your Account if it becomes dormant to the state of your last known address. We will make reasonable
              efforts to contact you if required by applicable law before transferring the remaining balance of your
              Account to the applicable state. After we surrender the funds to the state, you must apply to the
              appropriate state agency to reclaim your funds. You can avoid the surrender of your funds by simply
              conducting transactions, contacting us about your Account or replying to any abandoned property notices we
              may provide to you.
            </p>
            <p>
              We may also place your Account in an inactive status if you have not had any transaction for at least six
              (6) months. If your Account becomes inactive, you may not receive statements or be able to conduct certain
              transactions. Inactive Accounts must be reactivated. Contact us by Email at{" "}
              <a href="mailto:help@letter.co">help@letter.co</a> to reactivate your Account.
            </p>
          </li>

          <li>
            <strong>Account Fees and Fee Schedule</strong>
            <p>
              You agree to pay all fees and charges applicable to your Account. All fee amounts will be withdrawn from
              your Account and will be assessed regardless of whether you have sufficient funds in your Account, except
              where prohibited by law. Fees are subject to change at any time. We will provide you advance notice of any
              changes where required by law.
            </p>
            <table cellSpacing="0" cellPadding="10px">
              <tbody>
                <tr>
                  <td>
                    <p>
                      <strong>Fee Description</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>Fee Amount and Frequency</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>Additional Details</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Monthly service fee</p>
                  </td>
                  <td>
                    <p>Basic Plan: $0</p>
                    <p>Premium Plan: $99</p>
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    <p>Additional physical card fee</p>
                  </td>
                  <td>
                    <p>Basic Plan: $45/card</p>
                    <p>Premium Plan: $30/card</p>
                  </td>
                  <td>
                    <p>
                      See Section 1.3 for details on Basic and Premium Plans, including the respectively number of
                      fee-free physical cards issued per Account
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
        </ol>
      </li>

      <li>
        <h2>Using Your Letter Debit Card</h2>

        <ol>
          <li>
            <p>
              <strong>Activating the Card and Setting a PIN</strong>
            </p>

            <ol>
              <li>
                <strong>Card Agreement Acceptance and Card Activation</strong>
                <p>
                  This Card requires activation before you may use it. You may activate the Card by following the
                  directions provided with your new Card. Your activation and use of the Card means that you agree to
                  the terms and conditions described in this Agreement.
                </p>
                <p>If you do not want to activate the Card, you should cut the Card in half and dispose of it.</p>
              </li>

              <li>
                <strong>Personal Identification Number (PIN)</strong>
                <p>
                  You will be required to select a 4-digit personal identification number (PIN) prior to using the Card
                  for the first time. The 4-digit numerical PIN issued to you is for security purposes. The PIN
                  represents your signature authorizing transactions made using your Card(s), identifies the bearer of
                  the Card, and serves to validate and authenticate the directions given to complete the Card
                  transactions.
                </p>
                <p>
                  YOU ARE RESPONSIBLE FOR SAFEKEEPING YOUR PIN. YOU AGREE TO TAKE ALL REASONABLE PRECAUTIONS SO NO ONE
                  ELSE LEARNS YOUR PIN AND YOU AGREE NOT TO DISCLOSE OR OTHERWISE MAKE YOUR PIN AVAILABLE TO ANYONE NOT
                  AUTHORIZED TO SIGN ON YOUR DEPOSIT AND/OR LOAN ACCOUNT(S).
                </p>
                <p>
                  Your PIN should NOT be written on the Card or anywhere it is available to others for use by lawful
                  and/or unlawful means.
                </p>
              </li>

              <li>
                <strong>Permissible Uses of Your Card</strong>
                <p>
                  You agree that you will use the Card only for personal, household or family purposes and it will not
                  be used for any business purposes.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <p>
              <strong>Use of the Card</strong>
            </p>

            <ol>
              <li>
                <strong>Use of Cards by Third Parties</strong>
                <p>
                  Each Card issued for your Account is solely for use by the individual named on the Card. If you or an
                  authorized user gives anyone else access to your Card(s), we will treat any transaction they initiate
                  as authorized by you, and you will be responsible for all transactions and fees that occur.
                </p>
              </li>

              <li>
                <strong>ATM and Point-of-Sale PIN Withdrawals</strong>
                <p>
                  With your PIN, you can use your Card to obtain cash from your Account up to your Available Balance at
                  any ATM or any Point-of-Sale (&ldquo;POS&rdquo;) device in the United States that displays the Visa,
                  VisaATM, Plus, Interlink, or Accel acceptance marks. Not all merchants will permit you to get cash
                  back through their POS devices.
                </p>
                <p>
                  If you use an ATM, including a balance inquiry, you may be charged a fee by the ATM operator even if
                  you do not complete a withdrawal. This ATM fee is a third-party fee assessed by the individual ATM
                  operator only and is not assessed or controlled by us. This ATM fee amount will be charged to your
                  Account in addition to any applicable fees disclosed in this Agreement.
                </p>
              </li>

              <li>
                <strong>Retail Card Purchases</strong>
                <p>
                  You may use your Card to make purchases using your PIN at any merchant with a POS device anywhere that
                  displays the Visa, VisaATM, Plus, Interlink, or Accel acceptance marks. You may also use your Card to
                  make purchases without your PIN anywhere that Visa debit cards are accepted.
                </p>
              </li>

              <li>
                <strong>Split Transactions</strong>
                <p>
                  You can instruct a merchant to charge your Card for part of a purchase and pay any remaining amount
                  with cash or another card. This is called a &ldquo;split transaction.&rdquo; Some merchants do not
                  permit split transactions. If you wish to conduct a split transaction, you must tell the merchant the
                  exact amount you would like charged to your Card. If you fail to inform the merchant you would like to
                  complete a split transaction and you do not have sufficient available funds in your Account to cover
                  the entire purchase amount, your Card is likely to be declined.
                </p>
              </li>

              <li>
                <strong>Card-Not-Present Transactions</strong>
                <p>
                  You may make retail card purchases without presenting your Card by providing information from your
                  Card, such as transactions done by internet or telephone. Conducting transactions in this way has the
                  same legal effect as if you used the plastic Card itself. For security reasons, the amount or number
                  of such transactions you may make may be limited.
                </p>
              </li>

              <li>
                <strong>Virtual Card Use</strong>
                <p>
                  When you open an Account, you will also receive the ability to generate virtual cards (&ldquo;Virtual
                  Cards&rdquo;). Accounts opened under Letter&rsquo;s Basic Plan may generate up to four Virtual Cards
                  at a time. Accounts opened under Letter&rsquo;s Premium Plan may generate up to twenty Virtual Cards
                  at a time. (For more details on the Basic and Premium Plans, see Section 1.3.)
                </p>
                <p>
                  Each Virtual Card is represented by a 16-digit card number, a 3-digit card verification value
                  (&ldquo;CVV&rdquo;) number, and an expiration date. The Virtual Card is separate from your physical
                  Cards and can be managed through the Letter App. We will issue you a replacement Virtual Card when it
                  expires. The Virtual Card accesses the available funds in your Account to make card-not-present
                  transactions (see Section 4.2.5) anywhere Visa debit cards are accepted in the same way as your
                  physical Card. The Virtual Card can also be added to a digital wallet (see Section 4.2.7) in the same
                  way as your physical Card. Your use of the Virtual Card is subject to the terms of this Agreement,
                  including transaction limitations, to the same extent as your physical Card.
                </p>
              </li>

              <li>
                <strong>Use of the Card with Digital Wallets</strong>
                <p>
                  A digital wallet (&ldquo;Wallet&rdquo;) provides another way for you to make purchases with your Card.
                  A digital wallet is a service provided by another company (such as Apple Pay, Android Pay, Samsung
                  Pay, etc.), that allows you to use your Card to enter into transactions where the Wallet is accepted.
                  A Wallet may not be accepted at all places where your Card is otherwise accepted, and your Card may
                  not be eligible for all the features and functionalities of the Wallet. Any use of your Card in or
                  through the Wallet continues to be subject to all terms and conditions of this Agreement. We may
                  terminate your access to or use of your Card with a Wallet at any time and for any reason, including
                  if you violate any of the terms or conditions of this Agreement.
                </p>
                <p>
                  You can add or remove your Card from a Wallet by following the instructions provided by the Wallet
                  provider. By doing this, you agree to allow us to share your Card information with the Wallet
                  provider. You may be required to take additional steps to authenticate yourself before your Card is
                  added to the Wallet. We do not charge you any additional fees for adding your Card to a Wallet or for
                  using your Card in the Wallet. However, any fees and charges that would apply when you use your Card
                  outside the Wallet will also apply when you use a Wallet to make purchases with your Card or otherwise
                  access your Card. The Wallet provider and other third parties such as wireless companies or data
                  service providers may charge you fees. The digital version of your Card in a Wallet may, in our sole
                  discretion, be automatically updated or upgraded without notice to you.
                </p>
                <p>
                  We are not the provider of the Wallet, and we are not responsible for providing the Wallet service to
                  you or for ensuring that your Card is compatible with any Wallet service. We are only responsible for
                  supplying information to the Wallet provider to allow usage of your Card in the Wallet as you have
                  requested. We are not responsible for any failure of the Wallet, any mobile device you use with the
                  Wallet, or the inability to use the Wallet for any transaction. We are not responsible for how the
                  Wallet provider performs its services or any other third parties regarding any agreement you enter
                  into with the Wallet provider or other third party. We do not control the privacy and security of any
                  of your information that may be held by the Wallet provider. Any information held by the Wallet
                  provider is governed by the privacy policy given to you by the Wallet provider. If you have location
                  services enabled on your mobile device, the location of your mobile device may be collected and shared
                  by the Wallet provider in accordance with their respective data and privacy policies, including with
                  us. If you request to add your Card to a Wallet, you authorize us to collect, transmit, store, use and
                  share information about you, your mobile device, and your use of the Card in accordance with our
                  privacy policy, as amended from time to time, available at https://www.mybrb.com/privacy-policy.
                </p>
                <p>
                  TO THE MAXIMUM EXTENT PERMITTED BY LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY ARE WE LIABLE
                  FOR ANY LOSSES OR DAMAGES RESULTING FROM YOUR USE OF A WALLET, YOUR USE OF THE CARD OR VIRTUAL CARD IN
                  CONNECTION WITH A WALLET, OR A MOBILE DEVICE.
                </p>
                <p>
                  If you use a Wallet, you should protect your Wallet and your mobile device as you would your Card. If
                  your Wallet or mobile device is compromised, lost or stolen, you should also consider your Card lost
                  or stolen and notify us immediately. Please see Section 5.7 for instructions for reporting a lost or
                  stolen Card.
                </p>
                <p>
                  If you have any questions, disputes, or complaints about a Wallet, contact the Wallet provider using
                  the information given to you by the provider.
                </p>
              </li>

              <li>
                <strong>Foreign Transactions</strong>
                <p>
                  Charges from foreign merchants and financial institutions may be made in a foreign currency. We will
                  bill you in U.S. dollars based on the exchange rate on the day we settle the transaction, plus any
                  special currency exchanges charges that may be imposed by us, the Visa network and/or by any
                  third-party used to complete the transaction.
                </p>
                <p>
                  The exchange rate between the transaction currency and the billing currency used for processing
                  international transactions is a rate selected by Visa from the range of rates available in wholesale
                  currency markets for the applicable central processing date, which may vary from the rate Visa itself
                  receives, or the government-mandated rate in effect for the applicable central processing date. The
                  conversion rate may be different from the rate in effect on the date of your transaction and the date
                  it is posted to your Account.
                </p>
                <p>
                  Regardless of whether the transaction is made in foreign currency or in US dollars, additional fees
                  may apply to all international transactions using the Card. Please refer to our current fee schedule
                  for more information about our fees.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <strong>Returns and Refunds</strong>
            <p>
              Please be aware of the merchant&rsquo;s return policies before using your Card to make a purchase. Neither
              the Bank nor the Program Partner is responsible for the delivery, quality, safety, legality or any other
              aspect of the goods or services you purchase using your Card. If you have a problem with a purchase that
              you made with your Card or if you have a dispute with the merchant, you must handle it directly with the
              merchant. If you are entitled to a refund for any reason for goods or services obtained using your Card,
              you agree to accept credits to your Card for the refund and agree to the refund policy of the merchant.
              The amounts credited to your Card for refunds may not be available for up to five (5) days from the date
              the refund transaction occurs.
            </p>
          </li>

          <li>
            <strong>Additional Benefits from Visa and Fraud Alerts Program</strong>
            <p>
              Your Card may also be eligible for certain benefits provided by Visa. Please see the Visa Debit Card
              Benefits Guide for a full description of all additional benefits to which you may be eligible. You are
              also eligible for Visa&rsquo;s Fraud Alerts Program.
            </p>

            <ol>
              <li>
                <strong>Visa Zero Liability Policy</strong>
                <p>
                  Visa's Zero Liability Policy* is our guarantee that you won't be held responsible for unauthorized
                  charges made with your account or account information. You're protected if your Visa credit or debit
                  card is lost, stolen or fraudulently used, online or offline. Full information about Visa&rsquo;s Zero
                  Liability Policy is available at
                  https://usa.visa.com/pay-with-visa/visa-chip-technology-consumers/zero-liability-policy.html.
                </p>
                <p>
                  * Visa&rsquo;s Zero Liability Policy does not apply to certain commercial card and anonymous prepaid
                  card transactions or transactions not processed by Visa. Cardholders must use care in protecting their
                  card and notify their issuing financial institution immediately of any unauthorized use. Contact us at
                  help@letter.co for more detail.
                </p>
              </li>

              <li>
                <strong>Fraud Alerts Program Overview</strong>
                <p>
                  Messaging frequency depends on account activity. For more information, text HELP to 23618. To cancel
                  fraud text messaging services at any time, reply STOP to any alert from your mobile device.
                </p>
                <p>
                  For Fraud alerts&nbsp;support, call us at (855) 954-1888, 8:00 AM to 5:00 PM Pacific Time, Monday to
                  Friday, excluding holidays. You can also contact us via email at help@letter.co. If we are not
                  immediately available, you can also contact Visa at 1-833-333-0389.
                </p>
                <p>
                  By giving&nbsp;us your mobile number, you agree that Fraud&nbsp;Alerts text messaging is authorized to
                  notify you of suspected incidents of financial or identity fraud. HELP instructions: Text HELP to
                  23618 for help.&nbsp;
                </p>

                <ol>
                  <li>
                    <strong>Stop Instructions</strong>
                    <p>Text STOP to 23618 to cancel.</p>
                  </li>

                  <li>
                    <strong>Release of Liability</strong>
                    <p>
                      Alerts sent via SMS may not be delivered to you if your phone is not in the range of a
                      transmission site, or if sufficient network capacity is not available at a particular
                      time.&nbsp;Even within coverage, factors beyond the control of wireless carriers may interfere
                      with messages delivery for which the carrier is not responsible. Carriers do not guarantee that
                      alerts will be delivered.
                    </p>
                  </li>
                </ol>
              </li>

              <li>
                <strong>Reporting Lost or Stolen Cards</strong>
                <p>
                  If you believe your card has been lost or stolen, please contact us immediately at (855) 954-1888,
                  8:00 AM to 5:00 PM Pacific Time, Monday to Friday, excluding holidays. You can also contact us via
                  email at <a href="mailto:fraud@letter.co">fraud@letter.co</a>.&nbsp;For more information about
                  reporting lost or stolen cards, see Sections 5.6 and 5.7 below.
                </p>
              </li>

              <li>
                <strong>Visa Secure Online Shopping</strong>
                <p>
                  You can further protect your online Card purchases with Visa Secure (formerly Verified by Visa). This
                  free, simple-to-use service provided by Visa validates your identity when you make an online purchase
                  at participating merchants. Participating online merchants will automatically trigger the Visa Secure
                  identity verification process when you check out. There is no need to download anything or install any
                  software, and there is no service charge for this benefit. Your Card will continue to work as usual
                  for online purchases at merchants not yet participating in Visa Secure. You can contact Visa at
                  1-833-333-0370 for assistance with any issues you have with Visa Secure purchases
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </li>

      <li>
        <h2>Electronic Fund Transfers</h2>
        <p>
          Your Account allows you to withdraw funds up to the Available Balance or make deposits through Electronic Fund
          Transfers (&ldquo;EFTs&rdquo;). EFTs are transactions that are processed by electronic means and include,
          among others, ACH transfers, debit or ATM card transactions and direct deposits. This Section provides you
          with information and important disclosures and terms about the EFTs that are permitted on your Account. You
          may also receive additional services through the Letter App provided by the Program Partner, that allow you to
          initiate EFTs to and from your Account that are not described in this Agreement. You will be provided separate
          agreements and disclosures applicable to those services by the Program Partner.
        </p>

        <ol>
          <li>
            <strong>Types of EFTs Supported by Your Account</strong>
            <p>Your Account allows the following types of EFTs:</p>
            <ul>
              <li>Direct deposits from your employer or other source of income</li>
              <li>Transfers to or from your Account to or from a linked bank account (see Section 3.6)</li>
              <li>
                Transfers to or from your Account to a merchant or other third party by providing the third party with
                your Account routing number and account number
              </li>
              <li>Purchases or other transactions using your debit card</li>
              <li>ATM cash deposits and withdrawals using your debit card or ATM card</li>
            </ul>
          </li>

          <li>
            <strong>Limitations on EFTs</strong>
            <p>
              There are limitations on the frequency and amount of transactions you can make to or from your Account.
              These limits are different for each type of transaction. For limits that apply to transactions that credit
              or deposit funds into your Account, please refer to Section 3.1 concerning deposits. For limits that apply
              to transactions that debit or withdraw from your Account, please refer to Section 3.5 concerning
              withdrawals. For limits that apply to transfers to or from your Account from a linked bank account, please
              refer to Section 3.6. These security-related limits may change from time to time.
            </p>
            <p>
              If you have been issued a debit card or ATM card for your Account, there may also be transaction
              limitations that apply to your use of the card. Please refer to the applicable cardholder agreement
              provided to you with your card for information on any limitations and other terms.
            </p>
          </li>

          <li>
            <strong>Your Right to Receive Information and Documentation of Your Transactions</strong>
            <p>
              You will receive information and documentation concerning any EFTs that debit or credit your Account in
              the following ways:
            </p>
            <ul>
              <li>
                Statements: We will provide you information about each transaction that debits or credits your Account
                on your statements. Please refer to Section 3.9 for information about statements. You can also access
                your transaction history via the Letter App.
              </li>
              <li>
                Direct Deposits: If you receive a direct deposit into your Account at least once every sixty (60) days
                from your employer or other person or company, you can check if the deposit has been made by emailing{" "}
                <a href="mailto:help@letter.co">help@letter.co</a> or accessing your transaction history in the Letter
                App].
              </li>
              <li>
                Receipts: You can get a receipt at the time you make a withdrawal at an ATM or a purchase at a
                point-of-sale terminal using your debit card or ATM card.
              </li>
            </ul>
          </li>

          <li>
            <strong>Preauthorized Transfers, Your Right to Stop Payment and Notices of Varying Amounts</strong>
            <p>
              If you authorize us or another company or person to withdraw from your Account on a regular basis (such as
              when you sign up for &ldquo;autopay&rdquo; to pay a monthly bill), it is called a Preauthorized Transfer.
              You have the right to place a stop payment on Preauthorized Transfers, as well as get notification when
              the amount of a particular withdrawal will be different from the amount of the last withdrawal.
            </p>

            <ol>
              <li>
                <strong>Your Right to Stop Payment</strong>
                <p>
                  If you authorize a company or person to take payments from your Account on a regular basis through a
                  Preauthorized Transfer, you can stop any of these payments by contacting us by Email at{" "}
                  <a href="mailto:help@letter.co">help@letter.co</a> at least three (3) business days before the next
                  payment is scheduled to be made.
                </p>
                <p>
                  Make sure to provide us with (1) your name, (2) your Account number, (3) the company or person taking
                  the payments, and (4) the date and amount of the scheduled payment you wish to stop. If you want all
                  future payments from that company or person stopped, be sure to tell us that as well. If you do not
                  provide us with the correct information, such as the correct payee or the correct amount of the
                  payment you wish to stop, we may not be able to stop the payment.
                </p>
              </li>

              <li>
                <strong>Our Liability if We Fail To Stop a Preauthorized Transfer</strong>
                <p>
                  If you order us to stop one of these payments three (3) business days or more before the payment is
                  scheduled and provide us with all information requested, and we do not do so, we will be liable for
                  your losses or damages proximately caused by the failure. However, we will not be liable if the
                  company or person initiating the payments changes the dollar amount of the payment or makes other
                  changes that cause us not to recognize it as the payment you requested be stopped.
                </p>
              </li>

              <li>
                <strong>Notices of Varying Amounts</strong>
                <p>
                  If these regular payments vary in amount, the person or company you are paying will tell you ten (10)
                  days before each payment when it will be made and how much it will be. You may be given the option to
                  only get this notice when the payment will differ by more than a certain amount from the previous
                  payment, or when the amount will fall outside certain limits that you set.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <strong>Our Liability for a Failure To Complete A Transaction</strong>
            <p>
              If we fail to complete a transaction on time or in the correct amount when properly instructed by you in
              accordance with this Agreement, we will be liable for damages proximately caused by the failure or error.
              However, there are some exceptions. We are not liable, for instance:
            </p>
            <ul>
              <li>
                If the Available Balance in your Account is not sufficient to complete the transaction through no fault
                of ours.
              </li>
              <li>If the ATM you use does not have enough cash.</li>
              <li>
                If the failure is due to an equipment or system breakdown, such as a problem with the Letter App
                provided by the Program Partner, that you knew about before you began a transaction.
              </li>
              <li>
                The failure was caused by an Act of God, fire or other catastrophe, or any other cause beyond our
                control despite reasonable precautions we have taken.
              </li>
              <li>If your funds are not available due to a hold or if your funds are subject to legal process.</li>
              <li>
                If we do not complete a transaction because we or Unit has reason to believe the transaction is
                unauthorized or illegal.
              </li>
              <li>If your Account is closed or inactive.</li>
              <li>
                There may be additional exceptions stated in our or Unit&rsquo;s agreements with you or permitted by
                law.
              </li>
            </ul>
          </li>

          <li>
            <strong>Your Liability for Unauthorized EFTs</strong>
            <p>
              Contact us immediately if you believe that an unauthorized EFT has occurred or may occur concerning your
              Account, or if your debit card, ATM card, PIN, or the Letter App login credentials have been lost, stolen
              or compromised. Calling us at (855) 954-1888 is the best way of keeping your losses to a minimum. You can
              also email us at <a href="mailto:fraud@letter.co">fraud@letter.co</a>.
            </p>
            <p>
              You could lose all the money in your Account (and any bank accounts with other institutions you have
              linked to your Account) if you take no action to notify us of the unauthorized EFT or the loss or theft of
              your debit card, ATM card or the Letter App login credentials.
            </p>
            <p>For unauthorized EFTs, your liability will be as follows if you notify us of the loss:</p>
            <ul>
              <li>
                If you tell us within two (2) business days after learning of the loss, theft or compromise of your
                Letter App login credentials, you can lose no more than $50.
              </li>
              <li>
                If you do NOT tell us within two (2) business days and we can prove that we could have prevented the
                loss had you contacted us, you could lose as much as $500.00.
              </li>
              <li>
                If your statement shows EFTs that you did not make and you do NOT contact us within sixty (60) days
                after the statement was made available to you, you may not get back any money lost after the sixty (60)
                days if we can prove that your contacting us would have prevent those losses.
              </li>
            </ul>
            <p>
              We can extend these time periods if extenuating circumstances (such as a long trip or hospital stay) kept
              you from notifying us.
            </p>
            <p>
              If you need to report your Card as lost or stolen, report an unauthorized transaction, or replace your
              Card for any reason, please contact us at <a href="mailto:fraud@letter.co">fraud@letter.co</a> (855)
              954-1888, 8:00 AM to 5:00 PM Pacific Time, Monday to Friday, excluding holidays. Outside of these hours,
              you can also contact 1-833-333-0389 seven days a week, 24 hours a day. You will be required to provide
              personal information so that we may verify your identity before we will issue you a replacement Card.
            </p>
          </li>

          <li>
            <strong>In Case of Errors or Questions About Your EFTs</strong>
            <p>
              Contact us at (855) 954-1888 or email at <a href="mailto:fraud@letter.co">fraud@letter.co</a> as soon as
              you can if you think your statement or receipt is wrong or if you need more information about a transfer
              listed on a statement or receipt. We must hear from you no later than sixty (60) days after we sent the
              FIRST statement on which the problem or error appeared. You must provide us with the following
              information:
            </p>
            <ul>
              <li>Tell us your name and Account number.</li>
              <li>
                Describe the error or the EFT you are unsure about and explain as clearly as you can why you believe it
                is an error or why you need more information.
              </li>
              <li>Tell us the dollar amount of the suspected error.</li>
              <li>
                If you tell us orally, we may require that you send us your complaint or question in writing by email
                within ten (10) business days.
              </li>
            </ul>
            <p>
              We will determine whether an error occurred within ten (10) business days after we hear from you and will
              tell you the results and correct any error promptly. If we need more time, we may take up to forty-five
              (45) days&mdash;or ninety (90) days for Accounts open less than thirty (30) days or foreign-initiated
              transactions &mdash; to investigate your complaint or question. If we decide to do this, we will credit
              your Account within ten (10) business days for the amount you think is in error, so that you will have the
              use of the money during the time it takes us to complete our investigation. If we ask you to put your
              complaint or question in writing and we do not receive it within ten (10) business days, we may not credit
              your account.
            </p>
            <p>
              For Accounts open less than thirty (30) days, we may take up to twenty (20) business days to credit your
              Account for the amount you think is in error. For errors involving new accounts, point-of-sale, or
              foreign-initiated transactions, we may take up to 90 days to investigate your complaint or question.
            </p>
            <p>
              We will tell you the results within three (3) business days after completing our investigation. If we
              decide that there was no error, we will send you a written explanation by email. You may ask for copies of
              the documents that we used in our investigation.
            </p>
          </li>
        </ol>
      </li>

      <li>
        <h2>Wire Transfers</h2>
        <p>
          The Account may permit domestic wire transfers through the Letter App provided to you by the Program Partner.
        </p>

        <ol>
          <li>
            <strong>Types of Wire Transfers Supported by Your Account</strong>
            <p>
              Your Account may support domestic wire transfers. If wire services are made available to you, your Account
              will be able to both receive and initiate wire transfers.
            </p>
            <p>
              We may select any means for the transmission of funds that we consider suitable. We may make use of
              correspondents, agents, subagents, and funds transfer and communication systems. Such third parties shall
              be deemed your agents, and we shall not be liable for any errors, delay, misdelivery, or failure of
              delivery by any of them unless applicable law says otherwise.
            </p>
            <p>
              We will not be liable for our inability to perform our obligations under this Agreement when such
              inability arises out of causes beyond our control, including but not limited to, any act of God, accident,
              labor disputes, power failures, system failure, equipment malfunction, suspension of payment by another
              bank, refusal or delay by another bank to accept the wire transfer, war, emergency conditions, fire,
              earthquake, or the failure of any third party to provide any electronic or telecommunication service used
              in connection with the execution or cancellation of a wire transfer.
            </p>
          </li>

          <li>
            <strong>Limitations on Wire Transfers</strong>
            <p>
              There are limitations on the frequency and amount of transactions you can make to or from your Account.
              These limits are different for each type of transaction. For limits that apply to transactions that credit
              or deposit funds into your Account, please refer to Section 3.1 concerning deposits. For limits that apply
              to transactions that debit or withdraw from your Account, please refer to Section 3.5 concerning
              withdrawals. There may be limits on the beneficiaries who can receive a wire transfer you initiate from
              your Account. These security-related limits may change from time to time.
            </p>
          </li>

          <li>
            <strong>Cut-Off Times for Wire Transfers</strong>
            <p>
              We have cut-off hours for processing wire transfers. If a wire request is received by 3:00 PM ET on a
              Business Day and verified through our security procedures outlined in the section below titled
              &ldquo;Security Procedures,&rdquo; funds will be processed the same business day. If it is received after
              3:00 PM ET or on a non-Business Day and verified through our security procedures, funds may be processed
              the next business day.
            </p>
            <p>
              We may treat any wire transfer request received at or after our cut-off time as if it was received that
              business day, or we may treat it as if it were received at the opening of the next business day.
            </p>
          </li>

          <li>
            <strong>Security Procedures</strong>
            <p>
              You agree and consent to the use of certain security procedures by us to confirm the validity of the wire
              transfer request made pursuant to this Agreement. You understand the security procedures are not designed
              to detect errors in the content of the wire transfer request or to prevent duplicate transfers. Some
              elements of the procedures will vary, depending upon the method used to initiate a wire transfer. You
              hereby agree that your utilization of any security procedure established hereunder shall constitute your
              agreement to its use and affirmative acknowledgment of its commercial reasonableness.
            </p>
            <p>
              You further agree that any wire transfer request that is acted upon in good faith by us in compliance with
              these security procedures, whether or not in fact authorized by you, shall constitute an authorized wire
              transfer.
            </p>
          </li>

          <li>
            <strong>Liability for Incorrect Wire Transfers</strong>
            <p>
              You are responsible for providing us with the accurate name and account number for wire transfers, and we
              are responsible for transmitting money to the account number you provided.
            </p>
            <p>
              When you provide us with a name and account number in order for us to process a wire transfer, we may make
              payment solely on the basis of the account number, even if the account number identifies a person
              different from the beneficiary so named. We or an intermediary bank may send a wire transfer to an
              intermediary bank or beneficiary's/designated recipient's bank based solely on the bank identifying
              number, even if the payment order indicates a different name. You should be very careful when providing
              the account number for a wire transfer.
            </p>
            <p>
              If you have provided us with the wrong account number, there may be no way to correct the error or
              retrieve the funds. You agree that you will pay the amount of a wire transfer even in such circumstances.
              You are responsible for any losses resulting from an incorrect account number or your misidentification of
              the beneficiary/designated recipient.
            </p>
            <p>
              If you think a wire transfer is wrong or if you need more information about a wire transfer, you must
              contact us by email at <a href="mailto:fraud@letter.co">fraud@letter.co</a> as soon as possible and no
              later than fourteen (14) days after you receive your monthly statement. If you don&rsquo;t tell us about
              an error, we will not have any obligation to pay interest on the amount of an unauthorized or erroneous
              wire transfer for which we are liable, and you will also be liable to us for any damages or losses we may
              incur.
            </p>
          </li>

          <li>
            <strong>Payment</strong>
            <p>
              You must pay us the amount of the wire transfer, plus any applicable fees, before we will execute the wire
              transfer request. Please see Section 3.13 of this Agreement for fees applicable to wire transfers.
            </p>
          </li>

          <li>
            <strong>Execution of Wire Transfers</strong>
            <p>
              A wire transfer request is considered accepted by us when we execute it. Please refer to Section 6.3
              concerning cut-off times for more details. You can verify whether your wire transfer request has been
              executed by reviewing your transaction history via the Letter App.
            </p>
            <p>
              We have no responsibility to accept any incoming wire transfer(s) for your benefit. Likewise, we have a
              right to reject any wire transfer request(s) for an outgoing wire transfer for reasons including, but not
              limited to, insufficient or uncollected funds in the account specified in the wire transfer request, a
              request that fails any requires security procedures, our inability to execute the wire transfer for the
              reasons set out in the section of this agreement entitled method used to make the wire transfer above, or
              if we are unable to verify the authenticity of the wire transfer request. If we stop a domestic outgoing
              wire transfer, we will refund the fee.
            </p>
            <p>
              If we determine, in our sole discretion, not to honor, execute, or accept a wire transfer request, we will
              make reasonable efforts to notify you. We will also make reasonable efforts to notify you promptly if a
              wire transfer is returned to us after its execution. In either case, we shall have no liability by reason
              of our delay or failure to provide you with notice, and we shall have no obligation to resend a wire
              transfer if we complied with the original wire transfer request and such wire transfer was returned to us.
            </p>
          </li>

          <li>
            <strong>Cancellation or Amendment of Wire Transfer</strong>
            <p>
              Once we receive a wire transfer request, it may not be able to be canceled or amended. However, at our
              discretion, we may use reasonable efforts to act on any request for cancellation or amendment, provided
              that the method by which we are notified of a request for cancellation or amendment complies with our
              security procedures. However, we shall have no liability if such cancellation or amendment is not
              affected. You agree to indemnify and hold us harmless from any and all liabilities, claims, damages,
              costs, and expenses we may incur in attempting to cancel or amend the wire transfer. any cancellation or
              amendment of a wire transfer by us shall relieve us of any obligation to act on the original wire transfer
              request.
            </p>
          </li>

          <li>
            <strong>Our Liability for Failure to Complete a Wire Transfer</strong>
            <p>
              We shall be responsible only for performing the wire transfer service expressly provided for in this
              Agreement; provided however, we shall be liable only for our own gross negligence or willful misconduct in
              performing such service. We shall not be liable to any third party or for any act or omission of yours or
              any third party, including, but not limited to, third parties used by us in executing a wire transfer or
              performing a related act and no such third party shall be deemed to be our agent. IN NO EVENT SHALL WE BE
              LIABLE FOR ANY DAMAGES OF ANY KIND INCLUDING WITHOUT LIMITATION DIRECT, INDIRECT, PUNITIVE, SPECIAL,
              INCIDENTAL OR CONSEQUENTIAL DAMAGES, LOST PROFITS, LOSSES OR EXPENSES ARISING OUT OF OR IN CONNECTION WITH
              THE WIRE TRANSFER SERVICE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SAME. Except as otherwise
              provided by applicable law, the maximum period for which we shall be liable for interest on any amount to
              be refunded or paid to you with respect to an unauthorized, erroneous, or other wire transfer request is
              thirty days.
            </p>
          </li>

          <li>
            <strong>Your Liability for Unauthorized Wire Transfers</strong>
            <p>
              Except as otherwise provided by law, you shall be liable for any loss or damage to which your negligence
              contributed or which resulted in unauthorized, fraudulent, or dishonest acts by your current and/or former
              authorized representatives. Such liability includes instances when a current or former authorized
              representative effects one or more wire transfers or improper use of telephone security procedures to
              effect a wire transfer to your detriment.
            </p>
          </li>

          <li>
            <strong>
              Compliance with Anti-Money Laundering and Exchange Control Regulations and OFAC Enforced Sanctions
            </strong>
            <p>
              You agree to observe all Anti-Money Laundering and Exchange Control laws and regulations including
              economic and trade sanctions promulgated by the Office of Foreign Assets Control of the U.S. Department of
              Treasury in relation to any wire transfer, and you will use all reasonable endeavors to assist us to do
              likewise. In particular, you agree that the information given to us by you is accurate. We may disclose
              any information given to us that we in our sole discretion think necessary or desirable to disclose;
              except we will only disclose confidential information if required by law, a court, or legal, regulatory,
              or governmental authority, or as permitted by law in order to combat, prevent, or investigate issues
              arising under anti-money laundering laws, economic sanctions, or criminal law.
            </p>
            <p>
              Sometimes legal, regulatory, or governmental authorities require additional information, either in respect
              of individuals, entities, or particular transactions. You agree to promptly supply all such information,
              which any such authority may require, and/or which we may be required to supply, in relation to the
              individual, entity, or particular transaction.
            </p>
            <p>
              If you, or your authorized representative, breach any such laws or regulations, you irrevocably agree that
              we may retain any monies or funds transmitted to us pursuant to this Agreement and/or not fulfill any wire
              transfer request if we are required to take or refrain from such action by any legal, regulatory, or
              governmental authority or if we reasonably believe that such action may violate any laws or regulations
              described herein, and such monies shall not bear interest against us. You further agree that we may pay
              such monies to the appropriate legal, regulatory, or governmental authority, if and when required by law.
            </p>
          </li>
        </ol>
      </li>

      <li>
        <h2>Other Legal Terms and Conditions</h2>

        <ol>
          <li>
            <strong>No Assignments</strong>
            <p>
              Your Account and your obligations under this Agreement are not transferable and not assignable as
              collateral for a loan or for any other purpose. We may transfer our rights under this Agreement.
            </p>
          </li>

          <li>
            <strong>Legal Processes and Claims Affecting Your Account</strong>
            <p>
              If we receive a levy, attachment, or other legal process against you (collectively, &ldquo;Legal
              Process&rdquo;), we may refuse to permit withdrawals or transfers from your Account until the Legal
              Process is dismissed or satisfied. Any Legal Process will be subject to our right of set-off and security
              interest. You are responsible for any losses, costs or expenses we incur as a result of any dispute or
              legal proceeding involving your Account. If we receive a claim against the funds in your Account, or if we
              know of or believe that there is a dispute as to the ownership or control of funds in your Account, we
              may, in our discretion: (1) place a hold on your Account and refuse to pay out any funds until we are
              satisfied that the dispute is settled; (2) close your Account and send the balance to the named account
              holder; (3) require a court order to act; or (4) take any other action we feel is necessary to protect us.
              We will not be liable to you for taking any such action.
            </p>
          </li>

          <li>
            <strong>Limitation of Liability and Disclaimer of Warranty</strong>
            <p>
              EXCEPT AS REQUIRED BY LAW, WE ARE NOT LIABLE FOR ANY CLAIMS, COSTS, LOSSES, OR DAMAGES RESULTING DIRECTLY
              OR INDIRECTLY FROM OUR FAILURE TO ACT, OR ANY DELAY BEYOND TIME LIMITS PRESCRIBED BY LAW OR PERMITTED BY
              THIS AGREEMENT IF SUCH FAILURE OR DELAY IS CAUSED BY MAINTENANCE OR INTERRUPTION OR MALFUNCTION OF
              EQUIPMENT OR COMMUNICATION FACILITIES, UNUSUAL TRANSACTION VOLUME, SUSPENSION OF PAYMENTS BY ANOTHER
              FINANCIAL INSTITUTION, FIRE, NATURAL DISASTERS, ELEMENTS OF NATURE, GOVERNMENT ACTION, ACTS OF WAR,
              TERRORISM OR CIVIL STRIFE, EMERGENCY CONDITIONS, OR OTHER CIRCUMSTANCES BEYOND THE REASONABLE CONTROL OF
              BLUE RIDGE BANK, N.A. EXCEPT AS REQUIRED BY LAW, OUR LIABILITY TO YOU FOR A CLAIM IS LIMITED TO THE FACE
              VALUE OF THE ITEM OR TRANSACTION, OR THE ACTUAL VALUE OF ANY FUNDS NOT PROPERLY CREDITED OR DEBITED. IN NO
              EVENT WILL WE OR UNIT BE LIABLE FOR ANY CONSEQUENTIAL, INDIRECT, SPECIAL OR PUNITIVE DAMAGES EVEN IF YOU
              ADVISE US OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
            <p>
              EXCEPT AS REQUIRED BY LAW AND NOTWITHSTANDING ANYTHING TO THE CONTRARY, WE SHALL ONLY BE RESPONSIBLE AND
              LIABLE FOR OUR OWN GROSS NEGLIGENCE OR WILLFUL MISCONDUCT IN PERFORMING OUR OBLIGATIONS UNDER THIS
              AGREEMENT. WE SHALL NOT BE LIABLE TO ANY THIRD PARTY OR FOR ANY ACT OR OMISSION OF YOURS OR ANY THIRD
              PARTY, INCLUDING, BUT NOT LIMITED TO, THIRD PARTIES USED BY US IN EXECUTING ANY TRANSACTION CONTEMPLATED
              BY THIS AGREEMENT OR PERFORMING A RELATED ACT AND NO SUCH THIRD PARTY SHALL BE DEEMED TO BE OUR AGENT.
            </p>
            <p>
              IN ADDITION, EXCEPT AS REQUIRED BY LAW, BLUE RIDGE BANK, N.A. ARE NOT LIABLE OR RESPONSIBLE FOR ANY
              SERVICES OR FEATURES OF ANY ONLINE BANKING SERVICES PROVIDED TO YOU BY LETTER TECHNOLOGY, INC. WE ARE ALSO
              NOT LIABLE FOR ANY UNAUTHORIZED ACCESS OF YOUR INFORMATION OR DATA BY A THIRD PARTY DUE TO YOUR USE OF
              THIRD-PARTY COMMUNICATION CHANNELS NOT OFFERED BY US.
            </p>
            <p>
              ALL BANK SERVICES AND ACCOUNT FEATURES ARE PROVIDED &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo;
              WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT WARRANT THE BANK&rsquo;S
              SERVICES OR ACCOUNT FEATURES WILL MEET YOUR REQUIREMENTS, BE CONTINUOUS, UNINTERRUPTED, SECURE, TIMELY, OR
              ERROR-FREE, OR THAT DEFECTS WILL BE CORRECTED.
            </p>
          </li>

          <li>
            <strong>Indemnification</strong>
            <p>
              You agree to indemnify and hold us and our officers, directors, shareholders, employees, successors,
              predecessors, representatives, principals, agents, assigns, parents, subsidiaries and/or insurers harmless
              for any losses, damages, suits and expenses, including reasonable attorneys&rsquo; fees, that we may
              incur, without regard to the merit or lack thereof, arising out of, or related in any way to (1) the
              matters set forth herein; (2) our taking any action or not taking any action that we are entitled to take
              pursuant to this Agreement; (3) any action or omission by you; or (4) our action or inaction in reliance
              upon oral, written or electronic instructions or information from you.
            </p>
            <p>
              Equally, you agree to indemnify and hold Unit and its officers, directors, shareholders, employees,
              successors, predecessors, representatives, principals, agents, assigns, parents, subsidiaries and/or
              insurers harmless for any losses, damages, suits and expenses, including reasonable attorneys&rsquo; fees,
              that we may incur, without regard to the merit or lack thereof, arising out of, or related in any way to
              (1) the matters set forth herein; (2) our taking any action or not taking any action that we are entitled
              to take pursuant to this Agreement; (3) any action or omission by you; or (4) our action or inaction in
              reliance upon oral, written or electronic instructions or information from you.
            </p>
          </li>

          <li>
            <strong>Our Right of Set-Off and Security Interest</strong>
            <p>
              We have the right to set-off any liability, direct or contingent, past, present or future that you owe
              against any account you have with us. This means that we can take any funds in your Account or any other
              account you have with us to pay any debt or liability you owe us. You also agree to grant us a security
              interest in your Account to secure payment of any money that you owe to us or will owe us arising under
              this Agreement or any other agreements with us. You acknowledge that this security interest is consensual
              and in addition to any right of set-off. We may exercise our security interest or right of set-off without
              regard to the source of the funds in your Account or prior recourse to other sources of repayment or
              collateral, even if it causes you to incur penalties or suffer any other consequence. You waive any
              conditions or limits to our right of set-off to the maximum extent permitted by law. We will notify you if
              we exercise our right to set-off if required by law.
            </p>
            <p>
              If we take any action to collect debt incurred by you or other amounts you owe us under this Agreement or
              defend ourselves in a lawsuit brought by you where we are the prevailing party, you agree to reimburse us
              for our losses, including, without limitation, reasonable attorneys&rsquo; fees, to the extent permitted
              by applicable law. We may charge your Account for our losses without prior notice to you.
            </p>
          </li>

          <li>
            <strong>Changes in Terms and Additional Services</strong>
            <p>
              We may change this Agreement, or any fees and features of your Account, at any time by posting an amended
              Agreement on the Program Partner&rsquo;s website, www.letter.co/terms, and any such amendment shall be
              effective upon posting. We will give you advance notice of any change where required by law. We may
              provide such notice to you with your statement, electronically, or by mail. Any notice we provide to you
              will be binding and sent to the last (postal or electronic) address in our records. We may change your
              address if we receive an address change notice from the U.S. Postal Service. We may change or terminate
              this Agreement without notice at our discretion or to comply with any appropriate federal or state law or
              regulation.
            </p>
            <p>
              If we make any of our other banking services available to you in connection with your Account, we may
              provide certain terms and conditions for those additional services to you in a separate agreement or
              disclosure.
            </p>
          </li>

          <li>
            <strong>No Waiver of Rights</strong>
            <p>
              We may waive or decline to enforce any of our rights under this Agreement without obligating ourselves to
              waive such rights in the future or on any other occasion. We may release any other person obligated under
              this Agreement without affecting your responsibilities under this Agreement.
            </p>
          </li>

          <li>
            <strong>Conflicts and Section Headings</strong>
            <p>
              If there is a conflict between this Agreement and any other document or statement made to you concerning
              the Account, this Agreement will govern. If there is a conflict between this Agreement and any other
              document or statement made to you concerning any services or products other than the Account, the separate
              terms and conditions applicable to that service or product will govern. Section headings that appear in
              this Agreement are for convenience purposes only and are intended to help you find information. They
              should not be construed as affecting the meaning of the Agreement.
            </p>
          </li>

          <li>
            <strong>Severability</strong>
            <p>
              In the event that any court or tribunal of competent jurisdiction determines that any provision of this
              Agreement is illegal, invalid or unenforceable, the remainder of this Agreement shall not be affected. To
              the extent permitted by law, the parties waive any provision of law which prohibits or renders
              unenforceable any provisions of this Agreement, and to the extent that such waiver is not permitted by
              law, you and us agree that such provision will be interpreted as modified to the minimum extent necessary
              to render the provisions enforceable.
            </p>
          </li>

          <li>
            <strong>Governing Law, Forum and Time Limits</strong>
            <p>
              All actions relating to your Account and this Agreement will be governed by the laws and regulations of
              the United States and the State of Virginia where your Account will be opened, irrespective of conflict of
              law principles. You agree that any dispute arising under this Agreement or relating in any way to your
              relationship with us that is not arbitrated will be resolved in a federal or state court located in
              Virginia and that you will be subject to such court&rsquo;s jurisdiction.
            </p>
            <p>
              Except where prohibited by law, you agree that you must file any lawsuit or arbitration against us within
              two (2) years after the claim arises unless federal or Virginia law, or another agreement you have with
              us, provides for a shorter time. If federal or Virginia law requires a longer time period than the time
              periods in this Agreement, you agree to the shortest time period permitted under the law.
            </p>
          </li>
        </ol>
      </li>

      <li>
        <h2>Arbitration and Waivers</h2>
        <p>
          BE SURE THAT YOU HAVE READ THIS PROVISION CAREFULLY AND UNDERSTAND THAT IT LIMITS YOUR RIGHTS IN THE EVENT OF
          A DISPUTE BETWEEN YOU AND US.
        </p>

        <ol>
          <li>
            <strong>Election to Arbitrate.</strong>
            <p>
              You, Letter Technology, Inc. and the Bank agree that the sole and exclusive forum and remedy for
              resolution of a Claim be final and binding arbitration pursuant to this Section 8 (the &ldquo;Arbitration
              Provision&rdquo;). As used in this Arbitration Provision, &ldquo;Claim&rdquo; shall include any past,
              present, or future claim, dispute, or controversy involving you (or persons claiming through or connected
              with you), on the one hand, and us on the other hand, relating to or arising out of this Agreement, and/or
              the activities or relationships that involve, lead to, or result from this Agreement, including the
              validity or enforceability of this Arbitration Provision, any part thereof, or the entire Agreement.
              Claims are subject to arbitration regardless of whether they arise from contract; tort (intentional or
              otherwise); a constitution, statute, common law, or principles of equity; or otherwise. Claims include
              matters arising as initial claims, counter‐claims, cross-claims, third-party claims, or otherwise. Please
              note that you may continue to assert Claims in small claims court, if your Claims qualify and so long as
              the matter remains in such court and advances only on an individual (non-class, non-representative) basis.
              The scope of this Arbitration Provision is to be given the broadest possible interpretation that is
              enforceable.
            </p>
          </li>

          <li>
            <strong>Applicability of the Federal Arbitration Act; Arbitrator&rsquo;s Powers.</strong>
            <p>
              This Arbitration Provision is made pursuant to a transaction involving interstate commerce and shall be
              governed by and enforceable under the Federal Arbitration Act (the &ldquo;FAA&rdquo;). The arbitrator will
              apply substantive law consistent with the FAA and applicable statutes of limitations. The arbitrator may
              award damages or other types of relief permitted by applicable substantive law, subject to the limitations
              set forth in this Arbitration Provision. The arbitrator will not be bound by judicial rules of procedure
              and evidence that would apply in a court. The arbitrator shall take steps to reasonably protect
              confidential information.
            </p>
          </li>

          <li>
            <strong>Informal Dispute Resolution.</strong>
            <p>
              If a Claim arises, our goal is to address your concerns and, if we are unable to do so, to provide you
              with a neutral and cost effective means of resolving the dispute quickly. You agree that before filing any
              claim in arbitration, you will first submit your Claim to us by email at help@letter.co and provide us
              with the opportunity to resolve your concern prior to initiating arbitration.
            </p>
          </li>

          <li>
            <strong>Arbitration Procedures.</strong>
            <p>
              The party initiating arbitration shall do so with the American Arbitration Association (the
              &ldquo;AAA&rdquo;) or Judicial Alternatives and Mediation Services (&ldquo;JAMS&rdquo;). The arbitration
              shall be conducted according to, and the location of the arbitration shall be determined in accordance
              with, the rules and policies of the administrator selected, except to the extent the rules conflict with
              this Arbitration Provision or any countervailing law. If you have any questions concerning the AAA or
              would like to obtain a copy of the AAA arbitration rules, you may call 1(800) 778-7879 or visit the AAA's
              web site at: www.adr.org. If you have any questions concerning JAMS or would like to obtain a copy of the
              JAMS arbitration rules, you may call 1(800) 352-5267 or visit their web site at: www.jamsadr.com. In the
              case of a conflict between the rules and policies of the administrator and this Arbitration Provision,
              this Arbitration Provision shall control, subject to countervailing law, unless all parties to the
              arbitration consent to have the rules and policies of the administrator apply. The arbitration will be
              held in the United States county where you live or work, or any other location we agree to.
            </p>
          </li>

          <li>
            <strong>Arbitration Fees.</strong>
            <p>
              If we elect arbitration, we shall pay all the administrator's filing costs and administrative fees (other
              than hearing fees). If you elect arbitration, filing costs and administrative fees (other than hearing
              fees) shall be paid in accordance with the rules of the administrator selected, or in accordance with
              countervailing law if contrary to the administrator's rules. We shall pay the administrator's hearing fees
              for one full day of arbitration hearings. Fees for hearings that exceed one day will be paid by the party
              requesting the hearing, unless the administrator's rules or applicable law require otherwise, or you
              request that we pay them and we agree to do so. Each party shall bear the expense of its own attorneys'
              fees, except as otherwise provided by law. If a statute gives you the right to recover any of these fees,
              these statutory rights shall apply in the arbitration notwithstanding anything to the contrary herein.
            </p>
          </li>

          <li>
            <strong>Appeals.</strong>
            <p>
              Within 30 days of a final award by the arbitrator, any party may appeal the award for reconsideration by a
              three-arbitrator panel selected according to the rules of the arbitrator administrator. In the event of
              such an appeal, any opposing party may cross-appeal within 30 days after notice of the appeal. The panel
              will reconsider de novo all aspects of the initial award that are appealed. Costs and conduct of any
              appeal shall be governed by this Arbitration Provision and the administrator's rules, in the same way as
              the initial arbitration proceeding. Any award by the individual arbitrator that is not subject to appeal,
              and any panel award on appeal, shall be final and binding, except for any appeal right under the Federal
              Arbitration Act (&ldquo;FAA&rdquo;), and may be entered as a judgment in any court of competent
              jurisdiction.
            </p>
          </li>

          <li>
            <strong>No Class Actions.</strong>
            <p>
              NO ARBITRATION SHALL PROCEED ON A CLASS, REPRESENTATIVE, OR COLLECTIVE BASIS (INCLUDING AS PRIVATE
              ATTORNEY GENERAL ON BEHALF OF OTHERS), EVEN IF THE CLAIM OR CLAIMS THAT ARE THE SUBJECT OF THE ARBITRATION
              HAD PREVIOUSLY BEEN ASSERTED (OR COULD HAVE BEEN ASSERTED) IN A COURT AS CLASS REPRESENTATIVE, OR
              COLLECTIVE ACTIONS IN A COURT. Unless consented to in writing by all parties to the arbitration, no party
              to the arbitration may join, consolidate, or otherwise bring claims for or on behalf of two or more
              individuals or unrelated corporate entities in the same arbitration unless those persons are parties to a
              single transaction. Unless consented to in writing by all parties to the arbitration, an award in
              arbitration shall determine the rights and obligations of the named parties only, and only with respect to
              the claims in arbitration, and shall not (a) determine the rights, obligations, or interests of anyone
              other than a named party, or resolve any Claim of anyone other than a named party; nor (b) make an award
              for the benefit of, or against, anyone other than a named party. No administrator or arbitrator shall have
              the power or authority to waive, modify, or fail to enforce this Section 8.7, and any attempt to do so,
              whether by rule, policy, arbitration decision or otherwise, shall be invalid and unenforceable. Any
              challenge to the validity of this Section 8.7 shall be determined exclusively by a court and not by the
              administrator or any arbitrator.
            </p>
          </li>

          <li>
            <strong>Survival and Severability of Arbitration Provision.</strong>
            <p>
              This Arbitration Provision shall survive the termination of this Agreement. If any portion of this
              Arbitration Provision other than Section 8.7 is deemed invalid or unenforceable, the remaining portions of
              this Arbitration Provision shall nevertheless remain valid and in force. If there is a final judicial
              determination that applicable law precludes enforcement of this Arbitration Provision&rsquo;s limitations
              as to a particular claim for relief or particular term, then that claim (and only that claim) or that term
              (and only that term) must be severed from the Arbitration Provision and may be brought in court. If an
              arbitration is brought on a class, representative, or collective basis, and the limitations on such
              proceedings in Section 8.7 are finally adjudicated pursuant to the last sentence of Section 8.7 to be
              unenforceable, then no arbitration shall be had. In no event shall any invalidation be deemed to authorize
              an arbitrator to determine Claims or make awards beyond those authorized in this Arbitration Provision.
            </p>
          </li>

          <li>
            <strong>Judicial Forum for Claims.</strong>
            <p>
              Except as otherwise required by applicable law, in the event that this Arbitration Provision is found not
              to apply to you or your Claim, you and the Bank agree that any judicial proceeding (other than small
              claims actions) will be brought in the federal or state courts of Virginia. Both you and the Bank consent
              to venue and personal jurisdiction there. We both agree to waive our right to a jury trial.
            </p>
          </li>

          <li>
            <strong>WAIVER OF RIGHT TO LITIGATE.</strong>
            <p>
              THE PARTIES ACKNOWLEDGE THAT THEY HAVE A RIGHT TO LITIGATE CLAIMS THROUGH A COURT BEFORE A JUDGE OR JURY,
              BUT WILL NOT HAVE THAT RIGHT IF ANY PARTY ELECTS ARBITRATION PURSUANT TO THIS ARBITRATION PROVISION. THE
              PARTIES HEREBY KNOWINGLY AND VOLUNTARILY WAIVE THEIR RIGHTS TO LITIGATE SUCH CLAIMS IN A COURT BEFORE A
              JUDGE OR JURY UPON ELECTION OF ARBITRATION BY ANY PARTY.
            </p>
          </li>
        </ol>
      </li>
    </ol>
  </BankingAgreementHolder>
);
