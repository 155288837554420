import styled from "styled-components";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

export const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ListHeader = styled.div`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderText = styled.p`
  ${fonts.PARAGRAPH_L}
  font-weight: 500;
  letter-spacing: 0.02em;
  color: ${colors[ColorNames.MIDNIGHT].hex};
`;

export const ListItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors[ColorNames.GRAY2].hex};
  padding: 15px 16px;

  &:last-child {
    border: none;
  }
`;

export const ListItemTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ListItemTitle = styled.p`
  ${fonts.PARAGRAPH_M}
  color: ${colors[ColorNames.MIDNIGHT].hex};
`;

export const ListItemDescription = styled.p`
  ${fonts.PARAGRAPH_S}
  color: ${colors[ColorNames.GRAY5].hex};
`;

export const NoItemText = styled.p`
  ${fonts.PARAGRAPH_S}
  color: ${colors[ColorNames.GRAY5].hex};
  margin-top: 13px;
`;

export const ListButtonContainer = styled.div`
  margin: 10px 0;
  padding-left: 20px;
`;
export const StyledListButton = styled.div`
  border-bottom: 1px solid ${colors[ColorNames.GRAY2].hex};
  padding: 18px 10px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

export const ListButtonLabelGroup = styled.div`
  flex: 0 1 100%;
`;

export const ListButtonLabel = styled.div`
  ${fonts.PARAGRAPH_M}
`;
export const ListButtonSublabel = styled.div`
  ${fonts.NUMBER_S}
  color: ${colors[ColorNames.GRAY5].hex};
  margin-top: 2px;
`;

export const ListButtonRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex: 0 1 40%;
  ${fonts.NUMBER_S}
  text-align: right;
  height: 20px;
`;

export const ListButtonCheckbox = styled.div<{ selected: boolean; }>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: ${({ selected }): string => selected ? colors[ColorNames.MIDNIGHT].hex : colors[ColorNames.GRAY2].hex};

  svg {
    width: 12px;
    height: 12px;
    opacity: ${({ selected }): string => selected ? "1" : "0"};

    path {
      stroke: ${colors[ColorNames.WHITE].hex};
    }
  }
`;