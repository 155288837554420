import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { ButtonProps } from "src/components/button/Button";
import { ReactComponent as Check } from "src/assets/icons/check.svg";
import { ReactComponent as RightArrow } from "src/assets/icons/arrow_right.svg";
import {
  colorPalette as colors,
  rgbGradientColors,
  ColorNames,
  borderRadius,
  BrandColor,
  fonts
} from "src/theme/theme";

type GradientColorOption = "midnight" | "lake" | "white" | "cardinal";

const buttonGradients: { [key in GradientColorOption]: string } = {
  midnight: `linear-gradient(180deg, rgb(${rgbGradientColors.midnight[0]}) 0%, rgb(${rgbGradientColors.midnight[1]}) 100%)`,
  lake: `linear-gradient(180deg, rgb(${rgbGradientColors.lake[0]}) 0%, rgb(${rgbGradientColors.lake[1]}) 100%)`,
  white: `linear-gradient(180deg, rgb(${rgbGradientColors.white[0]}) 28.65%, rgb(${rgbGradientColors.white[1]}) 100%)`,
  cardinal: `linear-gradient(180deg, rgb(${rgbGradientColors.cardinal[0]}) 0%, rgb(${rgbGradientColors.cardinal[1]}) 100%)`
};

const buttonShadows: { [key in GradientColorOption]: string } = {
  midnight: `0px 0.976046px 2.84191px rgba(${colors.midnight.rgb}, 0.26), 0px 3.04646px 6.57965px rgba(${colors.midnight.rgb}, 0.179395), 0px 7.19824px 13.1424px rgba(${colors.midnight.rgb}, 0.144636), 0px 16.4909px 29.7083px rgba(${colors.midnight.rgb}, 0.115364), 0px 54px 121px rgba(${colors.midnight.rgb}, 0.0806048), inset 0px -1px 1px rgba(0, 0, 0, 0.25)`,
  lake: `0px 0.976046px 2.84191px rgba(${colors.lake.rgb}, 0.26), 0px 3.04646px 6.57965px rgba(${colors.lake.rgb}, 0.179395), 0px 7.19824px 13.1424px rgba(${colors.lake.rgb}, 0.144636), 0px 16.4909px 29.7083px rgba(${colors.lake.rgb}, 0.115364), 0px 54px 121px rgba(${colors.lake.rgb}, 0.0806048), inset 0px -1px 1px rgba(0, 0, 0, 0.1)`,
  white: `0px 2.57429px 5.28341px rgba(${colors.midnight.rgb}, 0.1), 0px 5.98938px 11.4915px rgba(${colors.midnight.rgb}, 0.05), 0px 11.049px 19.1489px rgba(${colors.midnight.rgb}, 0.05), 0px 19.5744px 29.3708px rgba(${colors.midnight.rgb}, 0.04), 0px 37.1071px 45.2711px rgba(${colors.midnight.rgb}, 0.03), 0px 121px 83px rgba(${colors.midnight.rgb}, 0.030926)`,
  cardinal: `0px 0.976046px 2.84191px rgba(${colors.cardinal.rgb}, 0.26), 0px 3.04646px 6.57965px rgba(${colors.cardinal.rgb}, 0.179395), 0px 7.19824px 13.1424px rgba(${colors.cardinal.rgb}, 0.144636), 0px 16.4909px 29.7083px rgba(${colors.cardinal.rgb}, 0.115364), 0px 54px 121px rgba(${colors.cardinal.rgb}, 0.0806048), inset 0px -1px 1px rgba(0, 0, 0, 0.1);`
};

const buttonPressedShadows: { [key in GradientColorOption]: string } = {
  midnight: `0px 0.976046px 2.84191px rgba(${colors.midnight.rgb}, 0.3), 0px 3.04646px 6.57965px rgba(${colors.midnight.rgb}, 0.2), 0px 7.19824px 13.1424px rgba(${colors.midnight.rgb}, 0.08), 0px 16.4909px 29.7083px rgba(${colors.midnight.rgb}, 0.11), 0px 34px 90px rgba(${colors.midnight.rgb}, 0.08), inset 0px -1px 1px rgba(0, 0, 0, 0.25)`,
  lake: `0px 0.976046px 2.84191px rgba(${colors.lake.rgb}, 0.3), 0px 3.04646px 6.57965px rgba(${colors.lake.rgb}, 0.2), 0px 7.19824px 13.1424px rgba(${colors.lake.rgb}, 0.08), 0px 16.4909px 29.7083px rgba(${colors.lake.rgb}, 0.11), 0px 34px 90px rgba(${colors.lake.rgb}, 0.08), inset 0px -1px 1px rgba(0, 0, 0, 0.1)`,
  white: `0px 2.57429px 5.28341px rgba(${colors.midnight.rgb}, 0.18), 0px 5.98938px 11.4915px rgba(${colors.midnight.rgb}, 0.04), 0px 11.049px 19.1489px rgba(${colors.midnight.rgb}, 0.02), 0px 14px 20px rgba(${colors.midnight.rgb}, 0.02), 0px 24px 35px rgba(${colors.midnight.rgb}, 0.02), 0px 70px 60px rgba(${colors.midnight.rgb}, 0.01)`,
  cardinal: `0px 0.976046px 2.84191px rgba(${colors.cardinal.rgb}, 0.3), 0px 3.04646px 6.57965px rgba(${colors.cardinal.rgb}, 0.2), 0px 7.19824px 13.1424px rgba(${colors.cardinal.rgb}, 0.08), 0px 16.4909px 29.7083px rgba(${colors.cardinal.rgb}, 0.11), 0px 34px 90px rgba(${colors.cardinal.rgb}, 0.08), inset 0px -1px 1px rgba(0, 0, 0, 0.1)`
};

export const CompleteIconContainer = styled.div`
  background-color: ${colors.white.hex};
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CheckIcon = styled(Check)`
  width: 20px;
  height: 20px;
`;

export const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default styled.button<ButtonProps & { multipleChildren: string }>`
  outline: 0;
  border: none;
  border-radius: ${borderRadius};
  padding: 0 30px;
  width: 100%;
  height: 62px;
  min-height: 62px;
  ${fonts.PARAGRAPH_M}
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ multipleChildren }): string => (multipleChildren ? "space-between" : "center")};
  text-align: ${({ multipleChildren }): string => (multipleChildren ? "left" : "center")};
  visibility: ${({ hidden }): string => (hidden ? "hidden" : "visible")};
  transform: scale3d(1, 1, 1);
  transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
  will-change: transform, box-shadow;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;

  @media (max-width: 330px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  svg {
    transform: translateX(0);
    transition: transform 0.2s ease-out;
    will-change: transform;
  }

  ${({ disabled, theme, color, raised, loading, complete }: ButtonProps): FlattenSimpleInterpolation | undefined => {
    const activeCss = ({ style }: any): FlattenSimpleInterpolation => {
      if (loading)
        return css`
          svg {
            transition: none;
          }
        `;
      if (complete || disabled) return css``;
      return css`
        &:hover {
          cursor: pointer;

          svg {
            transform: translate(4px);
          }
        }
        &:active {
          transform: scale3d(0.97, 0.97, 0.97);
          ${style}
        }
      `;
    };
    if (color === ColorNames.TRANSPARENT) {
      return css`
        padding: 0;
        color: ${theme?.colors.midnight};
        background-color: transparent;
        ${CompleteIconContainer} {
          background-color: ${colors.midnight.hex};
        }
        ${CheckIcon} {
          path {
            vector-effect: non-scaling-stroke;
            stroke: ${colors.white.hex};
          }
        }
        ${activeCss({})}
      `;
    }

    let colorStyle = theme?.colors.white;
    let backgroundColorStyle = theme?.colors[color || ColorNames.MIDNIGHT];

    if (disabled) {
      colorStyle = theme?.colors.midnight;
      backgroundColorStyle = theme?.colors.gray3;
    } else if (color && [ColorNames.WHITE, ColorNames.CREAM, ColorNames.ROSE].includes(color)) {
      colorStyle = theme?.colors.midnight;
      backgroundColorStyle = theme?.colors[color];
    }

    const iconStyles = css`
      ${CompleteIconContainer} {
        background-color: ${colorStyle};
      }
      ${CheckIcon} {
        path {
          vector-effect: non-scaling-stroke;
          stroke: ${backgroundColorStyle};
        }
      }
    `;

    if (
      raised &&
      !disabled &&
      color &&
      [ColorNames.MIDNIGHT, ColorNames.LAKE, ColorNames.WHITE, ColorNames.CARDINAL].includes(color)
    ) {
      return css`
        color: ${colorStyle};
        background-image: ${buttonGradients[color as GradientColorOption]};
        box-shadow: ${buttonShadows[color as GradientColorOption]};
        ${iconStyles}
        ${activeCss({
          style: `box-shadow: ${buttonPressedShadows[color as GradientColorOption]};`
        })}
      `;
    }

    return css`
      color: ${colorStyle};
      background-color: ${backgroundColorStyle};
      ${iconStyles}
      ${activeCss({})}
    `;
  }}
`;

export const Arrow = styled(RightArrow)<{
  color: BrandColor | ColorNames.TRANSPARENT;
}>`
  width: 22px;
  height: 18px;
  transform: translateX(0);
  transition: transform 0.2s ease-out;
  will-change: transform;

  path {
    vector-effect: non-scaling-stroke;
    stroke: ${({ color }): string =>
      [ColorNames.WHITE, ColorNames.CREAM, ColorNames.ROSE, ColorNames.TRANSPARENT].includes(color)
        ? colors[ColorNames.MIDNIGHT].hex
        : colors[ColorNames.WHITE].hex};
  }
`;
