import React, { useEffect, useState } from "react";

import { useParams, useFetcher } from "react-router-dom";

import ErrorElement from "src/ErrorElement";
import { NavRoutes } from "src/routes/navRoutes";
import Layout from "src/components/core-layout/Layout";
import { useNavbar } from "src/util/useNavbar";
import { User } from "src/generated/client";
import useNavigate from "src/util/useNavigate";
import { showNotice } from "src/store/alertState";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { ReactComponent as ChevronRight } from "src/assets/icons/chevron_right.svg";
import { ColorNames } from "src/theme/theme";
import { MemberDetailContent } from "./Accounts.styles";
import { Button } from "src/components/button/Button";
import { RouteActionResponse } from "src";
import {
  StyledFormInputContainer,
  StyledFieldContainer,
} from "src/components/forms";
import { Toggle } from "src/components/toggle/Toggle";

export const AccountMemberErrorElement = () => {
  return <ErrorElement redirect={NavRoutes.BANK_ACCOUNTS} alert={true} />;
};

const Members = () => {
  const { memberId, accountId } = useParams();
  const { updateNavbar } = useNavbar({ back: true });
  const [user, setUser] = useState<Partial<User>>();
  const navigate = useNavigate();
  const [isAccountOwner, setIsAccountOwner] = useState(false);
  const [selectedAction, setSelectedAction] = useState<string>("addOwner");
  const {
    data,
    state: fetcherState,
    Form,
    ...fetcher
  } = useFetcher<RouteActionResponse<{ success: string }>>();
  const { data: member, state: loaderState, ...loader } = useFetcher<User>();

  useEffect(() => {
    if (!member && loaderState === "idle") {
      loader.load(
        `${NavRoutes.API_OWNER}?accountId=${accountId}&userId=${memberId}`
      );
    }
  }, [member]);

  useEffect(() => {
    if (!member) return;

    updateNavbar({ title: `${member.firstName} ${member.lastName}`, back: true });
    if (member.isAccountOwner !== undefined) {
      setIsAccountOwner(member.isAccountOwner);
    }
    setUser(member);
  }, [member, updateNavbar]);

  useEffect(() => {
    if (!data) return;

    if (data.response?.success) {
      if (selectedAction === "addOwner") {
        showNotice("User has been added as an account owner.");
      } else {
        showNotice("User has been removed as an account owner.");
      }
      navigate("back");
    }

    if (data.error) {
      showNotice(data.error.message, { error: true });
      setIsAccountOwner(!isAccountOwner);
    }
  }, [data]);

  useEffect(() => {
    setSelectedAction(isAccountOwner ? "addOwner" : "removeOwner");
  }, [isAccountOwner]);

  const onSubmit = () => {
    if (!user?.id || !accountId || fetcherState === "submitting") {
      return;
    }
    fetcher.submit(
      { accountId, userId: user.id, action: selectedAction },
      { method: "post", action: NavRoutes.API_OWNER }
    );
  };

  return (
    <Layout loading={!user}>
      <MemberDetailContent>
        <ActionableButton
          label="View Full Member Profile"
          color={ColorNames.MINT}
          iconRight={<ChevronRight />}
          onClick={() => navigate(`${NavRoutes.MEMBERS}/${memberId}`)}
        />
        <Form style={{ marginTop: "20px" }}>
          <StyledFormInputContainer>
            <StyledFieldContainer
              style={{ display: "flex", alignItems: "center" }}
            >
              <Toggle
                disabled={user?.isThisMe}
                displayLabel="Account Owner"
                name="notifications.receiveSMS"
                checked={isAccountOwner}
                align="left"
                onChange={() => setIsAccountOwner(!isAccountOwner)}
              />
            </StyledFieldContainer>
          </StyledFormInputContainer>
          {!user?.isThisMe && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                flex: 2,
                justifyContent: "flex-end",
              }}
            >
              <Button
                raised
                color={ColorNames.LAKE}
                loading={fetcherState === "submitting"}
                onClick={() => onSubmit()}
              >
                Save
              </Button>
            </div>
          )}
        </Form>
      </MemberDetailContent>
    </Layout>
  );
};

export default Members;
