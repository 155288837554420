import React from "react";
import { TermsPage, Logo } from "src/pages/terms/Terms.styles";

const Security = () => {
  return (
    <TermsPage>
      <Logo />
      <div>
        <p>If you have identified a vulnerability, please follow our vulnerability disclosure process by:</p>

        <ul>
          <li>
            Emailing <a href="mailto:security@letter.co">security@letter.co</a> with the Subject "Vulnerability
            Disclosure" and encrypting the details in a text only format with{" "}
            <a href="/security_public_key.txt" target="_blank">
              this key
            </a>
            .
          </li>

          <li>Provide sufficient details to reproduce the vulnerability.</li>
          <li>Do not provide the output of automated scan reports.</li>
          <li>Include and sign with your public key.</li>
          <li>We will reach out to you if any follow up is necessary.</li>
        </ul>

        <p>
          We currently have no plans to incorporate Hackerone or other bug bounties into our overall security program.
        </p>

        <p>
          If you are interested in joining the Letter.co team, please apply at{" "}
          <a href="https://letter.co/careers">https://letter.co/careers</a>.
        </p>
      </div>
    </TermsPage>
  );
};

export default Security;
