import { colorPalette as colors, ColorNames, bezierMenu, dropShadows, ShadowNames, ThemeColor } from "src/theme/theme";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";

export const CoreBody = styled.div`
  position: relative;
  overflow-x: hidden;
  transition: background-color 0.2s linear;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: var(--sideMenuWidth) 1fr;
  }
`;

export const CoreLayoutContent = styled.div<{ backgroundColor?: ThemeColor }>`
  position: relative;
  grid-column: 2 / -1;
  width: 100%;
  height: 100%;
  transition: 0.3s ease transform, 0.3s ease max-width, 0.3s ease padding;
  overflow-x: hidden;
  min-height: 100vh;
  background-color: ${({ backgroundColor }): string =>
    !!backgroundColor ? colors[backgroundColor].hex : colors[ColorNames.WHITE].hex};

  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
`;

export const CoreLayoutNav = styled.div<{ backgroundColor?: ThemeColor; whiteFade?: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  z-index: var(--zIndex-nav);
  transition: transform 0.4s ${bezierMenu};

  &.menu {
    @media (max-width: 767px) {
      transform: translateX(var(--sideMenuWidth));
  }
`;

export const CoreLayoutNavInner = styled.div<{ backgroundColor?: ThemeColor; whiteFade?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: var(--zIndex-nav);
  width: 100%;
  transition: background-color 0.3s linear;
  will-change: background-color;
  background-color: ${({ backgroundColor }): string =>
    !!backgroundColor ? `rgba(${colors[backgroundColor].rgb}, 1)` : "transparent"};

  &.notAtTop {
    background-color: ${({ backgroundColor }): string =>
      !!backgroundColor ? `rgba(${colors[backgroundColor].rgb}, 0.9)` : `rgba(${colors[ColorNames.WHITE].rgb}, 0.9)`};
    backdrop-filter: blur(5px);
  }

  &.isTransitioning, &.transparent {
    background-color: transparent;
  }

  &.disableNav {
    opacity: 0;
    pointer-events: none;
  }
`;

export const LayoutWrapper = styled(motion.div)<{ backgroundColor?: ThemeColor }>`
  --layoutTopPadding: 32px;
  position: relative;
  left: 0;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-x: hidden;
  padding-top: calc(var(--extraDeviceTopPadding) + var(--subnavHeight) + var(--navbarHeight));
  background-color: ${({ backgroundColor }): string =>
    !!backgroundColor ? colors[backgroundColor].hex : colors[ColorNames.WHITE].hex};
  transition: padding-top 0.3s ease, left 0.4s ${bezierMenu}, z-index 0.4s ${bezierMenu};
  will-change: left, padding-top, transform, opacity;

  @supports (-webkit-touch-callout: none) {
    box-sizing: content-box;
    min-height: -webkit-fill-available;
  }

  @media (min-width: 900px) {
    grid-column: 2 / -1;
  }

  &.no-top-padding {
    --layoutTopPadding: 0px;
  }

  &.no-navbar {
    --navbarHeight: 0px;
  }

  & + & {
    position: absolute;
    left: auto;
    top: 0;
  }

  &.show-menu {
    @media (max-width: 767px) {
      left: var(--sideMenuWidth);
      position: fixed;
      overflow-y: hidden;
    }
  }

  &.has-open-modal {
    z-index: calc(var(--zIndex-nav) + 1);
  }

  @media (max-width: 767px) {
    box-shadow: ${dropShadows[ShadowNames.LIGHT]};
  }
`;

export const LayoutInsideWrap = styled.div<{ whiteFade?: boolean }>`
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding-top: var(--layoutTopPadding);
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 0%;

  ${({ whiteFade }) =>
    whiteFade &&
    `background: linear-gradient(to bottom, rgb(${colors[ColorNames.GRAY1].rgb}), rgb(${
      colors[ColorNames.WHITE].rgb
    }) 120px);`}
`;

export const LayoutInnerWidthWrap = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: var(--maxContentWidth);
  }
`;

const LayoutInnerContentWithoutWidthWrapCss = css`
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 0%;
`;

export const LayoutInnerContentWithoutWidthWrap = styled.div`
  ${LayoutInnerContentWithoutWidthWrapCss}
`;

export const LayoutInnerContent = styled(LayoutInnerWidthWrap)`
  ${LayoutInnerContentWithoutWidthWrapCss}
`;

export const LayoutFullPage = styled.div`
  flex: 1 1 0%;
`;
