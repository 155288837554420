// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3C%3Fxml version%3D%221.0%22 encoding%3D%22UTF-8%22 standalone%3D%22no%22%3F%3E%3Csvg width%3D%2236px%22 height%3D%2236px%22 viewBox%3D%220 0 36 36%22 version%3D%221.1%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E    %3C%21-- Generator%3A Sketch 46.2 %2844496%29 - http%3A%2F%2Fwww.bohemiancoding.com%2Fsketch --%3E    %3Ctitle%3Ecomplete-tick-small%3C%2Ftitle%3E    %3Cdesc%3ECreated with Sketch.%3C%2Fdesc%3E    %3Cdefs%3E%3C%2Fdefs%3E    %3Cg id%3D%22Symbols%22 stroke%3D%22none%22 stroke-width%3D%221%22 fill%3D%22none%22 fill-rule%3D%22evenodd%22%3E        %3Cg id%3D%22icons%2Fcomplete-tick%2Fmedium%22 transform%3D%22translate%28-6.000000%2C -6.000000%29%22%3E            %3Cg id%3D%22complete-tick-medium%22%3E                %3Cg id%3D%22complete-tick-small%22 transform%3D%22translate%286.000000%2C 6.000000%29%22%3E                    %3Ccircle id%3D%22Oval%22 fill%3D%22%23353FF4%22 cx%3D%2218%22 cy%3D%2218%22 r%3D%2218%22%3E%3C%2Fcircle%3E                    %3Cpath d%3D%22M24.250633%2C10.5256681 L15.4492545%2C19.8654105 L13.249367%2C17.5304749 C12.2771252%2C16.5025957 10.7008135%2C16.5025957 9.72918132%2C17.5304749 C8.75693956%2C18.5646991 8.75693956%2C20.2397616 9.72918132%2C21.2676408 L13.6894664%2C25.4743319 C14.6617082%2C26.508556 16.2374103%2C26.508556 17.2096521%2C25.4743319 L27.7708187%2C14.2628341 C28.7430604%2C13.2286099 28.7430604%2C11.5535474 27.7708187%2C10.5256681 C26.7985769%2C9.49144395 25.2228748%2C9.49144395 24.250633%2C10.5256681 Z%22 id%3D%22checkmark%22 fill%3D%22%23FFFFFF%22%3E%3C%2Fpath%3E                %3C%2Fg%3E            %3C%2Fg%3E        %3C%2Fg%3E    %3C%2Fg%3E%3C%2Fsvg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-crossDevice-CrossDeviceSubmit-icon{height:2.25em;width:2.25em;margin-right:.875em;float:left;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain}.onfido-sdk-ui-crossDevice-CrossDeviceSubmit-uploadList{display:inline-block;padding-left:0;margin-top:3em;margin-bottom:3.5em}.onfido-sdk-ui-crossDevice-CrossDeviceSubmit-uploadListItem{margin-bottom:1.5em;list-style:none;text-align:left}.onfido-sdk-ui-crossDevice-CrossDeviceSubmit-listText{line-height:2.25}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"icon": "onfido-sdk-ui-crossDevice-CrossDeviceSubmit-icon",
	"uploadList": "onfido-sdk-ui-crossDevice-CrossDeviceSubmit-uploadList",
	"uploadListItem": "onfido-sdk-ui-crossDevice-CrossDeviceSubmit-uploadListItem",
	"listText": "onfido-sdk-ui-crossDevice-CrossDeviceSubmit-listText"
};
export default ___CSS_LOADER_EXPORT___;
