import React, { useEffect, useState, useRef } from "react";

import { useShowBottomSheet } from "src/util/useBottomSheet";
import { showNotice } from "src/store/alertState";

import { Button } from "src/components/button/Button";

import {
  CardSheetDetails,
  CardSheetInstructions,
  CardSheetActivateInputsHolder,
  CardSheetActivateInput,
} from "./CardSheet.styles";
import { StyledFieldContainer, InputRow } from "src/components/forms";
import { StyledLabelContainer, StyledLabel } from "src/components/text";
import { colorPalette as colors, ColorNames } from "src/theme/theme";
import { NeufileGroteskMedium } from "src/fonts/encoded-fonts";
import { Card } from "src/generated/client";

const VGS_FRAME_STYLES = {
  "@font-face": {
    "font-family": "NeufileGrotesk",
    "font-style": "normal",
    "font-weight": "500",
    "font-display": "swap",
    src: `url(${NeufileGroteskMedium})`,
  },
  "font-family": "NeufileGrotesk",
  color: colors[ColorNames.MIDNIGHT].hex,
  "font-size": "16px",
  "font-weight": 500,
  "font-feature-settings": '"tnum" on, "lnum" on',
  display: "block",
  "::placeholder": {
    color: colors[ColorNames.GRAY3].hex,
  },
};

type VGS_ERROR_KEYS = "data.attributes.oldPin" | "data.attributes.newPin";
const VGS_MAPPED_ERROR_KEYS: Record<VGS_ERROR_KEYS, string> = {
  "data.attributes.oldPin": "Current PIN",
  "data.attributes.newPin": "New PIN",
};
const VGS_ERROR_PIN_MISSING = "PIN must exist";

enum PIN_MODE {
  EDIT = "edit",
  SET = "set",
}

export const CardPinSheet = ({
  sheetId,
  edit = false,
  card,
  token,
  externalCardId,
  onSet,
}: {
  sheetId?: string;
  edit?: boolean;
  card: Card | undefined;
  token: string | undefined;
  externalCardId: string | undefined;
  onSet?: () => void;
}) => {
  const didLoadVGSRef = useRef<boolean>(false);
  const [mode, setMode] = useState<PIN_MODE>(
    edit ? PIN_MODE.EDIT : PIN_MODE.SET
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [VGSForm, setVGSForm] = useState<any>();
  const { hideBottomSheet } = useShowBottomSheet();

  useEffect(() => {
    if (!!token && !didLoadVGSRef.current) {
      didLoadVGSRef.current = true;
      const script = document.createElement("script");
      const body = document.getElementsByTagName("body")[0];
      script.src =
        "https://js.verygoodvault.com/vgs-collect/2.11.0/vgs-collect.js";
      body.appendChild(script);
      script.addEventListener("load", () => {
        const form = (window as any).VGSCollect.create(
          process.env.REACT_APP_UNIT_VAULT_ID,
          process.env.REACT_APP_UNIT_VAULT_ID === "tntazhyknp1"
            ? "sandbox"
            : "live",
          () => null
        );
        setVGSForm(form);

        if (mode === PIN_MODE.EDIT) {
          form.field("#cc-old-pin", {
            type: "text",
            name: "data.attributes.oldPin",
            successColor: colors[ColorNames.MIDNIGHT].hex,
            errorColor: colors[ColorNames.MIDNIGHT].hex,
            placeholder: "1234",
            maxLength: 6,
            validations: ["required", "/^([0-9]{4,6})$/"],
            css: VGS_FRAME_STYLES,
          });
        }

        form.field("#cc-new-pin", {
          type: "text",
          name:
            mode === PIN_MODE.EDIT
              ? "data.attributes.newPin"
              : "data.attributes.pin",
          successColor: colors[ColorNames.MIDNIGHT].hex,
          errorColor: colors[ColorNames.MIDNIGHT].hex,
          placeholder: "1234",
          maxLength: 6,
          validations: ["required", "/^([0-9]{4,6})$/"],
          css: VGS_FRAME_STYLES,
        });
      });
    }
  }, [token, mode]);

  const savePIN = () => {
    setIsSubmitting(true);
    VGSForm.submit(
      `/cards/${externalCardId}/secure-data/pin`,
      {
        method: mode === PIN_MODE.EDIT ? "PUT" : "POST",
        mapDotToObject: "true",
        headers: {
          "Content-Type": "application/vnd.api+json",
          Authorization: "Bearer " + token,
        },
      },
      (status: number, data: any) => {
        if (!!data.errors) {
          setIsSubmitting(false);

          if (
            data.errors.some((error: any) =>
              error.title.includes(VGS_ERROR_PIN_MISSING)
            )
          ) {
            try {
              VGSForm.unmount();
            } catch (e) {}
            showNotice(
              `A PIN for your card was never created. Please set one below.`,
              { error: true }
            );
            didLoadVGSRef.current = false;
            setMode(PIN_MODE.SET);
          } else {
            showNotice(
              `There was a problem setting your PIN.${
                mode === PIN_MODE.EDIT
                  ? " Double check your current PIN and try again."
                  : ""
              }`,
              {
                error: true,
              }
            );
          }
        } else {
          showNotice(
            `Your PIN has been successfully ${
              mode === PIN_MODE.EDIT ? "changed" : "added to your card"
            }.`,
            {
              error: false,
            }
          );
          hideBottomSheet(sheetId);
          !!onSet && onSet();
        }
      },
      (errors: any) => {
        const errorKey = Object.keys(errors)[0];
        const mappedErrorKey =
          VGS_MAPPED_ERROR_KEYS[errorKey as VGS_ERROR_KEYS] || errorKey;
        const message = errors[errorKey!].errorMessages[0];
        showNotice(`${mappedErrorKey} ${message}`, { error: true });
        setIsSubmitting(false);
      }
    );
  };

  return (
    <CardSheetDetails>
      <CardSheetInstructions>
        {mode === PIN_MODE.EDIT
          ? "Change your card's PIN."
          : "Create a PIN for your new card."}
      </CardSheetInstructions>
      <CardSheetActivateInputsHolder>
        {mode === PIN_MODE.EDIT && (
          <InputRow>
            <StyledFieldContainer>
              <StyledLabelContainer>
                <StyledLabel htmlFor="">Current PIN</StyledLabel>
                <CardSheetActivateInput>
                  <div id="cc-old-pin"></div>
                </CardSheetActivateInput>
              </StyledLabelContainer>
            </StyledFieldContainer>
          </InputRow>
        )}
        <InputRow>
          <StyledFieldContainer>
            <StyledLabelContainer>
              <StyledLabel htmlFor="">
                {mode === PIN_MODE.EDIT ? "New " : ""}PIN
              </StyledLabel>
              <CardSheetActivateInput>
                <div id="cc-new-pin"></div>
              </CardSheetActivateInput>
            </StyledLabelContainer>
          </StyledFieldContainer>
        </InputRow>
      </CardSheetActivateInputsHolder>

      <Button
        color={ColorNames.LAKE}
        raised
        onClick={savePIN}
        loading={isSubmitting}
        children={mode === PIN_MODE.EDIT ? "Change PIN" : "Add PIN to Card"}
      />
      <Button
        color={ColorNames.TRANSPARENT}
        onClick={() => hideBottomSheet(sheetId)}
        disabled={isSubmitting}
        children="Cancel"
      />
    </CardSheetDetails>
  );
};
