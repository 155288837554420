export enum LineTypeTitle {
  Horses = "Horses",
  AutoVest = "AutoVest",
  Crypto = "CryptoLine"
}

export enum OneLineAuctionNotificationType {
  Collateral = "Collateral",
  PurchaseComplete = "PurchaseComplete",
  Retrieve = "Retrieve"
}

export enum CheckStatus {
  NotStarted = "NotStarted",
  Passed = "Passed",
  Failed = "Failed"
}
