import styled from "styled-components";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

export const TransactionItems = styled.div`
  padding-left: 30px;
`;

export const EmptyTransactionInfo = styled.div`
  padding: 10px 50px 40px;
  ${fonts.PARAGRAPH_S}
  text-align: center;
`;

export const EmptyTransactionInfoTitle = styled.div`
  ${fonts.PARAGRAPH_L}
  padding-bottom: 20px;
`;

export const EmptyTransactionItems = styled(TransactionItems)`
  margin-top: 60px;
`;

export const TransactionItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 30px 16px 10px;
  border-bottom: 1px solid ${colors[ColorNames.GRAY2].hex};

  &.clickable {
    cursor: pointer;
  }

  &.clickable:hover {
    background-image: linear-gradient(
      90deg,
      rgba(${colors[ColorNames.GRAY5].rgb}, 0) 0%,
      rgba(${colors[ColorNames.GRAY5].rgb}, 0.06) 100%
    );
  }
`;

export const TransactionItemLeftContainer = styled.div`
  ${fonts.PARAGRAPH_M}
`;

export const TransactionItemRightContainer = styled.div`
  margin-left: 10px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  svg {
    color: ${colors[ColorNames.GRAY5].hex};
  }
`;

export const TransactionItemTitleContainer = styled.div``;

export const TransactionItemSubtitle = styled.div`
  margin-top: 2px;
  ${fonts.PARAGRAPH_S}
  color: ${colors[ColorNames.GRAY5].hex};
`;

export const TransactionItemAmountContainer = styled.div`
  ${fonts.NUMBER_M}

  &.debit {
    color: ${colors[ColorNames.CARDINAL].hex};
  }
  &.strike {
    text-decoration: line-through;
    color: ${colors[ColorNames.GRAY5].hex};
  }
`;

export const EmptyTransactionItem = styled(TransactionItemContainer)`
  ${TransactionItemSubtitle} {
    height: 8px;
    width: 134px;
    border-radius: 4px;
    background: ${colors[ColorNames.GRAY3].hex};
    margin-top: 10px;
  }

  ${TransactionItemAmountContainer} {
    height: 14px;
    width: 54px;
    border-radius: 7px;
    background: ${colors[ColorNames.GRAY5].hex};
  }
`;

export const EmptyTransactionItemTitle = styled.div`
  height: 14px;
  width: 104px;
  border-radius: 7px;
  background: ${colors[ColorNames.GRAY5].hex};
`;
