import React from "react";
import { LoadingContainer, LoadingContainerProps } from "./LoadingContainer";
import Layout from "src/components/core-layout/Layout";

export const LoadingPage = (props: LoadingContainerProps) => {
  return (
    <Layout noTopPadding>
      <LoadingContainer {...props} />
    </Layout>
  );
};
