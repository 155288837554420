import { makeVar } from "@apollo/client";

interface INoticeOptions {
  error?: boolean;
  persist?: boolean;
  displayInterval?: number;
}

export interface INoticeState extends INoticeOptions {
  children: JSX.Element | string | null;
}

export interface IAlertState {
  children?: JSX.Element | string | null;
  description: string;
  actionText: string;
  loading: boolean;
  onAction: (...args: any[]) => any;
}

interface IAlertStates {
  [key: string]: IAlertState;
}

export const noticeVar = makeVar<INoticeState>({
  children: null,
  error: false,
  persist: false,
  displayInterval: 3000
});

export const alertVar = makeVar<IAlertStates>({});


// convenience setters

export const showNotice = (children: JSX.Element | string | null, options?: INoticeOptions) => {
  noticeVar({
    children,
    error: options?.error || false,
    persist: options?.persist || false,
    displayInterval: options?.displayInterval || 3000
  });
}

export const hideNotice = () => {
  noticeVar({
    ...noticeVar(),
    children: null
  });

  setTimeout(() => {
    noticeVar({
      children: null,
      error: false,
      persist: false,
      displayInterval: 3000
    });
  }, 300);
}

export const addAlert = (id: string, options: IAlertState) => {
  alertVar({
    ...alertVar(),
    [id]: options
  });
}

export const removeAlert = (id?: string) => {
  if (!!id) {
    const alerts = alertVar();
    delete alerts[id];
    alertVar({
      ...alerts
    });
  } else {
    alertVar({});
  }
}