import React, { useEffect, useState, useRef } from "react";
import { useReactiveVar } from "@apollo/client";
import { hideNotice, noticeVar } from "src/store/alertState";

import { NoticeContainer, NoticeText } from "src/components/alert/Alerts.styles";

import { CypressTestIds, DataTestIds } from "src/util/testing-util/test-utils";

export const NoticeController = (): JSX.Element => {
  const notice = useReactiveVar(noticeVar);
  const [pauseTimeout, setPauseTimeout] = useState(false);
  const hideTimeout = useRef<number | null>(null);

  useEffect(() => {
    if (!notice.persist && notice.children) {
      if (!!hideTimeout.current) {
        clearTimeout(hideTimeout.current);
        hideTimeout.current = null;
      }

      if (!pauseTimeout) {
        hideTimeout.current = window.setTimeout(hideNotice, notice.displayInterval);
      }
    }

    return () => {
      if (!!hideTimeout.current) {
        clearTimeout(hideTimeout.current);
        hideTimeout.current = null;
      }
    };
  }, [notice, pauseTimeout]);

  return (
    <NoticeContainer
      error={notice.error}
      show={!!notice.children}
      onClick={() => {
        if (!window.getSelection()?.toString()) hideNotice();
      }}
      onMouseEnter={() => {
        setPauseTimeout(true);
      }}
      onMouseLeave={() => {
        setPauseTimeout(false);
      }}
      data-testid={DataTestIds.ALERT}
      data-cy={CypressTestIds.ERROR_ALERT}
    >
      {!!notice.children && <NoticeText>{notice.children}</NoticeText>}
    </NoticeContainer>
  );
};
