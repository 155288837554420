import React, { useRef, useEffect } from "react";
import { useField } from "formik";
import { showNotice } from "src/store/alertState";
import classnames from "classnames";

import { StyledInput, FileContainer, PreviewContainer, ImagePreview } from "src/components/forms/input/Input.styles";
import { StyledFieldContainer } from "src/components/forms";
import { StyledLabelContainer, StyledLabel, StyledPlaceholderLabel } from "src/components/text";
import { DataTestIds } from "src/util/testing-util/test-utils";
import { ReactComponent as Upload } from "src/assets/icons/upload.svg";
import { ReactComponent as Check } from "src/assets/icons/check.svg";

export interface InputProps {
  label?: string;
  placeholderLabel?: string;
  name: string;
  disabled?: boolean;
  fileName?: string;
  previewUrl?: string;
  selected?: boolean;
  type: string;
  style?: object;
  doNotCapture?: boolean;
  [key: string]: unknown;
}

export const Input = ({ label, placeholderLabel, name, disabled, ...props }: InputProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>();
  const [field, meta, helpers] = useField(name);
  

  useEffect(() => {
    if (meta.touched && meta.error) {
      showNotice(meta.error as string, { error: true });
    }
  }, [meta.touched, meta.error]);

  return (
    <StyledFieldContainer
      onClick={(): void => inputRef?.current?.click()}
      error={(meta.touched && meta.error) as boolean}
      data-testid={DataTestIds.FIELD_CONTAINER}
      type={props.type}
      style={props.style}
    >
      <StyledLabelContainer>
        {label && (
          <StyledLabel htmlFor={name} error={(meta.touched && meta.error) as boolean} data-testid={DataTestIds.LABEL}>
            {label}
          </StyledLabel>
        )}
        {props.type === "file" && placeholderLabel && (
          <StyledPlaceholderLabel isPlaceholder={!props.fileName || props.fileName === ""}>
            {props.fileName ? props.fileName.replace(/C:\\fakepath\\/, "") : placeholderLabel}
          </StyledPlaceholderLabel>
        )}
      </StyledLabelContainer>

      <StyledInput
        ref={inputRef}
        data-testid={DataTestIds.INPUT}
        disabled={disabled}
        {...field}
        {...props}
        className={classnames({ "do-not-capture": props.doNotCapture })}
        onChange={(e: React.ChangeEvent<any>) => {
          helpers.setError(undefined);
          field.onChange(e);
          if (!!props.onChange) (props.onChange as any)(e);
        }}
      />

      {props.type === "file" && (
        <FileContainer>
          <PreviewContainer>{props.previewUrl && <ImagePreview src={props.previewUrl} />}</PreviewContainer>
          <PreviewContainer>{props.selected ? <Check /> : <Upload />}</PreviewContainer>
        </FileContainer>
      )}
    </StyledFieldContainer>
  );
};
