export const log = async (message: string): Promise<void> => {
  if (process.env.NODE_ENV === "development") {
    console.log(message);
  }
};

export const captureException = async (error: Error | string): Promise<void> => {
  if (typeof Sentry !== "undefined") {
    Sentry.captureException(error);
  } else if (process.env.NODE_ENV === "development") {
    console.error(error);
  }
};

export const useLogger = (): {
  log: (message: string) => Promise<void>;
  captureException: (error: Error | string) => Promise<void>;
} => {
  return { log, captureException };
};
