import styled from "styled-components";
import { colorPalette as colors, ColorNames, ThemeColor, fonts } from "src/theme/theme";
import { ReactComponent as ChevronRight } from "src/assets/icons/chevron_right.svg";
import Checkmark from "src/assets/icons/check.svg";

export const InfoListItemsWrapper = styled.div<{ tight?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ tight }): string => tight ? "12px" : "20px"};
`;

export const InfoListItemContainer = styled.div<{ size?: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ size }): string => (size === "small" ? fonts.PARAGRAPH_S : fonts.PARAGRAPH_M)}
`;

export const PickableListItemContainer = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 15px;
  cursor: pointer;
  ${fonts.PARAGRAPH_M}
  border-bottom: 1px solid ${colors[ColorNames.GRAY2].hex};
`;

export const InfoListItemValueContainer = styled.div<{ color?: ThemeColor }>`
  display: flex;
  align-items: flex-end;
  color: ${({ color }) => color ? colors[color].hex : colors[ColorNames.MIDNIGHT].hex};
`;

export const ListItemTitle = styled.p<{ color?: ThemeColor }>`
  color: ${({ color }) => color ? colors[color].hex : colors[ColorNames.GRAY5].hex};
`;

export const ListItemValue = styled.p<{ size?: string; type?: string }>`
  text-align: right;
  ${({ size, type }): string =>
    size === "small"
      ? type === "number"
        ? fonts.NUMBER_S
        : fonts.PARAGRAPH_S
      : type === "number"
      ? fonts.NUMBER_M
      : fonts.PARAGRAPH_M}
`;

export const ChevronIcon = styled(ChevronRight)`
  width: 10px;
  height: 18px;
  margin-left: 16px;
  cursor: pointer;

  path {
    vector-effect: non-scaling-stroke;
    stroke-width: 2px;
  }
`;

export const PickableListItemCheckbox = styled.div`
  width: 24px;
  height: 24px;

  input {
    display: grid;
    place-content: center;
    width: 24px;
    height: 24px;
    background-color: ${colors[ColorNames.GRAY2].hex};
    border-radius: 50px;
    transition: 0.1s background-color ease-in;
    -webkit-appearance: none;
    appearance: none;

    &:before {
      content: "";
      width: 12px;
      height: 12px;
      transform: scale(0);
      transition: 0.2s transform ease-in;
      background-color: ${colors[ColorNames.WHITE].hex};
      mask: url(${Checkmark}) no-repeat 50% 50%;
      -webkit-mask: url(${Checkmark}) no-repeat 50% 50%;
      mask-size: cover;
      -webkit-mask-size: cover;
    }

    &:checked {
      background-color: ${colors[ColorNames.MIDNIGHT].hex};

      &:before {
        transform: scale(1);
      }
    }
  }
`;

export const InfoListItemLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${ListItemTitle} {
    color: ${colors[ColorNames.MIDNIGHT].hex};
  }
  ${ListItemValue} {
    color: ${colors[ColorNames.GRAY5].hex};
    ${fonts.PARAGRAPH_S}
  }
`;

export const InfoListItemRightContainer = styled.div``;
