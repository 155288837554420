import React, { useEffect, useRef } from "react";
import classnames from "classnames";
import { showNotice } from "src/store/alertState";
import useCopyToClipboard from "src/util/useCopyToClipboard";

import { ThemeColor } from "src/theme/theme";
import { DetailRowData, DetailRowLabel, StyledDetailRow, StyledDetailRows } from "./DetailRow.styles";
import { ReactComponent as IconEye } from "src/assets/icons/eye.svg";
import { ReactComponent as IconCopy } from "src/assets/icons/copy.svg";
import { DataTestIds } from "src/util/testing-util/test-utils";
import { ReactComponent as IconEdit } from "src/assets/icons/edit.svg";

export interface DetailRowProps {
  label: string;
  data?: string;
  dataColor?: ThemeColor;
  fullData?: string;
  reveal?: boolean;
  large?: boolean;
  dividerTop?: boolean;
  onClick?: () => unknown;
  action?: "copy" | "edit";
}

export const DetailRow = ({
  label = "Label",
  data = "Row Data",
  dataColor,
  fullData = "",
  reveal = false,
  large = false,
  dividerTop = false,
  action,
  onClick = () => undefined
}: DetailRowProps): JSX.Element => {
  const { copied, setCopied, handleCopyValue } = useCopyToClipboard();
  const copyTimeout = useRef<number | null>(null);
  

  useEffect(() => {
    if (!!copyTimeout) {
      clearTimeout(copyTimeout.current!);
      copyTimeout.current = null;
    }
    if (copied) {
      copyTimeout.current = window.setTimeout(() => {
        setCopied(false);
      }, 2500);
    }
  }, [copied, setCopied]);

  const handleClick = async () => {
    onClick();

    if (action === "copy" && reveal) {
      handleCopyValue(fullData);
      showNotice(`${label} copied to clipboard.`);
    }
  };

  return (
    <StyledDetailRow data-testid={DataTestIds.DETAIL_ROW} className={classnames({ dividerTop })}>
      <DetailRowLabel className={classnames({ large })}>{label}</DetailRowLabel>
      <DetailRowData onClick={handleClick} className={classnames({ large })} color={dataColor}>
        <span>{reveal ? fullData : data}</span>
        {action === "copy" && <span className="action-icon">{reveal ? <IconCopy /> : <IconEye />}</span>}
        {action === "edit" && (
          <span className="action-icon">
            <IconEdit />
          </span>
        )}
      </DetailRowData>
    </StyledDetailRow>
  );
};

export const DetailRows = ({ children }: { children: JSX.Element[] | JSX.Element | string }) => {
  return <StyledDetailRows children={children} />;
};
