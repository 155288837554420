import React, { useEffect } from "react";
import { generatePath, useFetcher } from "react-router-dom";

import { useCurrentOrg } from "src/services/organizations";
import { useCurrentUser } from "src/services/users";
import { useNavbar } from "src/util/useNavbar";
import useNavigate from "src/util/useNavigate";
import { BackgroundImageSize, ColorNames } from "src/theme/theme";
import { NavRoutes } from "src/routes/navRoutes";
import { decorateCurrencyValue, formatMonthYear } from "src/util/stringUtils";
import {
  CheckStatus,
  LineOriginationStatus,
  Organization,
  PartnershipType,
  LineType,
  CollateralType,
} from "src/generated/client";

import {
  GenericWrapper,
  DashboardStatCardAmount,
  WelcomeContent,
  WelcomeMessage,
  WelcomeActionSheet,
  WelcomeShapes,
  StyledTriangle,
} from "../OneLine.styles";
import {
  AmountDollars,
  BalanceAmountHeader,
  BalanceLabel,
} from "src/routes/app/bank/accounts/Accounts.styles";
import { ActionSheetTitle } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet.styles";
import { StyledRingCallout } from "src/components/ring-callout/RingCallout.styles";
import { InfoListItemsWrapper } from "src/components/info-list-item/InfoListItem.styles";

import Layout from "src/components/core-layout/Layout";
import { BalanceAmount } from "src/components/balance-amount/BalanceAmount";
import {
  FeatureCard,
  FeatureCardType,
} from "src/components/feature-card/FeatureCard";
import { BannerNotice } from "src/components/alert/BannerNotice";
import { Button } from "src/components/button/Button";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { RingProgress } from "src/components/ring-progress/RingProgress";
import { InfoListItem } from "src/components/info-list-item/InfoListItem";
import { ReactComponent as IconInfo } from "src/assets/icons/info.svg";
import { ReactComponent as IconConnect } from "src/assets/icons/connect.svg";
import { ReactComponent as IconEdit } from "src/assets/icons/edit.svg";

const OneLineDashboard = () => {
  const currentOrg = useCurrentOrg({ fullFetch: true });

  useNavbar({ title: "OneLine" });

  return (
    <Layout loading={!currentOrg.oneLine}>
      {currentOrg.oneLine?.lines.length ? (
        <Dashboard currentOrg={currentOrg} />
      ) : (
        <Welcome />
      )}
    </Layout>
  );
};

export default OneLineDashboard;

const Welcome = () => {
  const {
    data: lineTypes,
    state: loadingState,
    load,
  } = useFetcher<LineType[]>();
  const currentOrg = useCurrentOrg();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  useNavbar({ transparent: true, title: "OneLine" });

  useEffect(() => {
    if (!lineTypes && loadingState === "idle") {
      load(`${NavRoutes.API_LINE}?get=lineTypes`);
    }
  }, [lineTypes, loadingState, load]);

  const getStartedAction = () => {
    if (
      currentOrg.creditCheck === CheckStatus.Passed &&
      currentOrg.oneLine.eligibleForQualification &&
      (currentOrg.oneLineAvailableToBorrow?.amount || 0) > 0
    ) {
      if (currentUser?.partnershipType === PartnershipType.TetonRidge) {
        const lineType = lineTypes?.find(
          ({ collateralType }) => collateralType === CollateralType.Horse
        );

        if (!!lineType)
          navigate(
            generatePath(NavRoutes.ONELINE_CREATE_LINE, {
              lineTypeId: lineType.id,
            })
          );
        else navigate(NavRoutes.ONELINE_BORROW);
      } else {
        navigate(NavRoutes.ONELINE_BORROW);
      }
    } else {
      navigate(NavRoutes.ONELINE_QUALIFY);
    }
  };

  return (
    <WelcomeContent>
      <WelcomeShapes>
        <StyledTriangle size={BackgroundImageSize.LARGE} />
        <StyledTriangle size={BackgroundImageSize.SMALL} />
      </WelcomeShapes>
      <WelcomeMessage>
        One line of credit for everything
        <div className="body">
          The easiest way to get lending. Multiple lines and types of credit,
          all rolled up into one monthly payment and balance.
        </div>
      </WelcomeMessage>
      <WelcomeActionSheet>
        <ActionSheetTitle
          children={
            currentUser?.partnershipType === PartnershipType.TetonRidge
              ? "Get Horse Lending"
              : "Get Started"
          }
        />
        <div className="large">No applications. No waiting. It's easy.</div>
        To get started, connect your financial accounts (bank, crypto, stocks,
        etc). You'll then see a dollar amount we can lend to you.
        <br />
        <br />
        The more wealth you connect, the clearer financial picture and lending
        capacity we'll have for you. So connect as much wealth as you can.
        <Button
          raised
          loading={!currentOrg.externalBalance}
          children="Get Started"
          onClick={getStartedAction}
        />
      </WelcomeActionSheet>
    </WelcomeContent>
  );
};

const Dashboard = ({ currentOrg }: { currentOrg: Organization }) => {
  const navigate = useNavigate();

  if (
    currentOrg.oneLine?.lines.length === 1 &&
    !currentOrg.oneLine.lines[0].finalized
  ) {
    return (
      <GenericWrapper>
        <BannerNotice
          title="New Line In Progress"
          children={
            [
              LineOriginationStatus.LoanDocsSigned,
              LineOriginationStatus.PurchaseComplete,
              LineOriginationStatus.LoanFunded
            ].includes(currentOrg.oneLine.lines[0].originationStatus)
              ? "Sign pledge agreement"
              : "Finish setting it up"
          }
          iconLeft={<IconEdit />}
          backgroundColor={ColorNames.OCEAN}
          textColor={ColorNames.WHITE}
          iconColor={ColorNames.WHITE}
          onClick={() => {
            const path = [
              LineOriginationStatus.LoanDocsSigned,
              LineOriginationStatus.PurchaseComplete,
              LineOriginationStatus.LoanFunded
            ].includes(currentOrg.oneLine.lines[0].originationStatus)
              ? NavRoutes.ONELINE_FINALIZE_LINE
              : NavRoutes.ONELINE_EDIT_LINE;

            navigate(
              generatePath(path, { lineId: currentOrg.oneLine.lines[0].id })
            );
          }}
        />
      </GenericWrapper>
    );
  }

  return (
    <>
      <BalanceAmountHeader>
        <AmountDollars color={ColorNames.MIDNIGHT}>
          <BalanceAmount
            availableBalance={
              currentOrg.oneLine?.totals.outstandingBalance.display
            }
          />
        </AmountDollars>
        <BalanceLabel color={ColorNames.OCEAN}>
          <div className="label">Outstanding Balance</div>
        </BalanceLabel>
      </BalanceAmountHeader>

      <FeatureCard type={FeatureCardType.LINE}>
        <>
          {!currentOrg.oneLine ? (
            <></>
          ) : (
            <BannerNotice
              children={
                currentOrg.oneLine.totals.availableToBorrow.amount > 0
                  ? "Need cash? Open a new line."
                  : "You do not qualify for additional lending at this time."
              }
              iconLeft={<IconInfo />}
              translucent
              textColor={ColorNames.WHITE}
              iconColor={ColorNames.WHITE}
            />
          )}
          <DashboardStatCardAmount>
            <BalanceLabel color={ColorNames.WHITE}>
              <div className="label">Available to Borrow</div>
            </BalanceLabel>
            <AmountDollars color={ColorNames.WHITE}>
              <BalanceAmount
                prefix={
                  currentOrg.oneLine?.totals.availableToBorrow.amount > 0
                    ? "+"
                    : ""
                }
                availableBalance={
                  currentOrg.oneLine?.totals.availableToBorrow.display
                }
              />
            </AmountDollars>
          </DashboardStatCardAmount>

          {currentOrg.oneLine?.totals.availableToBorrow.amount > 0 ? (
            <Button
              raised
              color={ColorNames.WHITE}
              onClick={() => navigate(NavRoutes.ONELINE_BORROW)}
              children="Borrow Now"
            />
          ) : (
            <ActionableButton
              color={ColorNames.BREEZE}
              label="Connect Wealth to Qualify"
              iconRight={<IconConnect />}
              onClick={() => navigate(NavRoutes.BANK_CONNECTED_ACCOUNTS)}
            />
          )}
        </>
      </FeatureCard>

      <GenericWrapper>
        <InfoListItemsWrapper style={{ margin: "40px 0" }}>
          {currentOrg.oneLine?.totals.totalMonthlyPayment.amount > 0 && (
            <InfoListItem
              title="Monthly Payment"
              value={decorateCurrencyValue(
                currentOrg.oneLine?.totals.totalMonthlyPayment.display || "0"
              )}
              valueType="number"
              size="normal"
            />
          )}
          <InfoListItem
            title="Total Borrowed"
            value={decorateCurrencyValue(
              currentOrg.oneLine?.totals.totalAmountBorrowed.display || "0"
            )}
            valueType="number"
            size="small"
            valueColor={ColorNames.GRAY5}
          />
          <InfoListItem
            title="Total Repaid"
            value={decorateCurrencyValue(
              currentOrg.oneLine?.totals.totalAmountPaidOff.display || "0"
            )}
            valueType="number"
            size="small"
            valueColor={ColorNames.GRAY5}
          />
        </InfoListItemsWrapper>

        <ActionableButton
          color={ColorNames.BREEZE}
          label="Open Lines"
          extraLabel={currentOrg.oneLine?.totals.openLines}
          onClick={() => navigate(NavRoutes.ONELINE_LINES)}
        />

        {currentOrg.oneLine?.totals.monthsRemaining > 0 && (
          <StyledRingCallout>
            <div className="heading">
              <div className="title">
                {currentOrg.oneLine?.totals.monthsRemaining || 0} Months
                Remaining
              </div>
              <RingProgress
                value={(currentOrg.oneLine?.totals.monthsTotal - currentOrg.oneLine?.totals.monthsRemaining) || 0}
                total={currentOrg.oneLine?.totals.monthsTotal || 1}
                color={ColorNames.MIDNIGHT}
                size={26}
                strokeWidth={4}
                trackStrokeWidth={2}
                showText={false}
              />
            </div>
            <InfoListItem
              title="Approx. Payoff Date"
              value={formatMonthYear(
                currentOrg.oneLine?.totals.approxPayOffDate || ""
              )}
              valueType="number"
              size="small"
            />
          </StyledRingCallout>
        )}
      </GenericWrapper>
    </>
  );
};
