import React from "react";
import {
  StyledNotFoundPage,
  Logo,
  NotFoundBanner,
  NotFoundCode,
  NotFoundDescription
} from "src/pages/not-found/NotFound.styles";

const NotFound = (): JSX.Element => (
  <StyledNotFoundPage>
    <Logo />
    <NotFoundCode>404</NotFoundCode>
    <NotFoundBanner>Sorry, there’s nothing here.</NotFoundBanner>
    <NotFoundDescription>
      The page you’re trying to visit doesn’t exist. Be sure you’ve entered the correct web address.
    </NotFoundDescription>
  </StyledNotFoundPage>
);

export default NotFound;
