/* eslint-disable  @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
import { motion, AnimatePresence, PanInfo } from "framer-motion";
import { useRecoilState } from "recoil";

import useNavigate from "src/util/useNavigate";

import { needsLogoutVar } from "src/store/currentUserState";
import { useCurrentUser } from "src/services/users";
import { useCurrentOrg } from "src/services/organizations";
import { showNotice } from "src/store/alertState";
import { showMenuState, selectedMenuGroup } from "src/store/menuState";
import { CardType, CardStatusType, OrgType, PartnershipType } from "src/generated/client";
import { NavRoutes } from "src/routes/navRoutes";
import { CypressTestIds, DataTestIds } from "src/util/testing-util/test-utils";

import {
  Overlay,
  AppMenuContainer,
  MenuTabs,
  MenuUserActions,
  AppMenuLinks,
  AppMenuLinksInner,
  MenuTabButton,
  MenuLink,
  AppMenuTabBar,
  MenuLinkGroupLabel,
  DisableAppMenu,
  AppMenuOrgSwitcher,
  AppMenuLinksTop,
  AppMenuLinksBottom,
} from "./AppMenu.styles";

import { ReactComponent as Logo } from "src/assets/filled-logo.svg";
import { ReactComponent as IconBank } from "src/assets/icons/bank.svg";
import { ReactComponent as IconOneLine } from "src/assets/icons/oneline.svg";
import { ReactComponent as IconMarketplace } from "src/assets/icons/marketplace.svg";
import { ReactComponent as IconSettings } from "src/assets/icons/settings.svg";
import { ReactComponent as IconHelp } from "src/assets/icons/help.svg";
import { ReactComponent as IconLogout } from "src/assets/icons/logout.svg";
import { ReactComponent as IconExpand } from "src/assets/icons/arrows_expand.svg";
import { Avatar } from "../avatar/Avatar";
import { AppMenuSwipeHandler } from "./AppMenuSwipeHandler";
import { NavigationDirection } from "src/store/navigationState";
import { ActionBottomSheet } from "../bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import { ColorNames } from "src/theme/theme";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { showOrgSwitcherState } from "src/store/orgSwitcherState";

// have to brute force these types until @types/styled-components is updated with react 18 support
const DisableAppMenuFixed = DisableAppMenu as unknown as React.FC<
  React.PropsWithChildren<Record<string, never>>
>;

// const menuTreeQueue = [
//   {
//     id: "bank", // needs to be the same as menuState.ts:10
//     label: "Queue",
//     icon: <IconBank />,
//     testId: CypressTestIds.MENU_TAB_BANK,
//     links: [
//       { label: "Dashboard", route: NavRoutes.QUEUE_DASHBOARD, testId: CypressTestIds.Q_NAV_DASHBOARD_BTN },
//       { label: "Account", route: NavRoutes.QUEUE_ACCOUNT, testId: CypressTestIds.Q_NAV_ACCOUNT_BTN }
//     ]
//   }
// ];

function getMenuLinks(tree: any[], group: string) {
  const result = tree?.filter((obj) => {
    return obj.id === group;
  });

  return result;
}

type PanEndEvent = MouseEvent | TouchEvent | PointerEvent;

const enum MenuTreeIds {
  BANK = "bank",
  ONELINE = "oneline",
  MARKETPLACE = "marketplace",
  SETTINGS = "org-settings",
}

const baseMenuTreeApp = [
  {
    id: MenuTreeIds.BANK, // needs to be the same as menuState.ts:10
    label: "Banking",
    icon: <IconBank />,
    testId: CypressTestIds.MENU_TAB_BANK,
    theme: ColorNames.PINE,
    links: [
      {
        label: "Dashboard",
        route: NavRoutes.BANK_DASHBOARD,
        testId: CypressTestIds.MENU_LINK_BANK_DASHBOARD,
      },
      {
        label: "Checking",
        route: NavRoutes.BANK_ACCOUNTS,
        testId: CypressTestIds.MENU_LINK_BANK_CHECKING,
      },
      {
        label: "Connected",
        route: NavRoutes.BANK_CONNECTED_ACCOUNTS,
        testId: CypressTestIds.MENU_LINK_BANK_CONNECTED,
      },
      {
        label: "Recipients",
        route: NavRoutes.BANK_RECIPIENTS,
        testId: CypressTestIds.MENU_LINK_BANK_RECIPIENTS,
      },
      {
        label: "Move Money",
        route: NavRoutes.BANK_TRANSFER_SEND,
        testId: CypressTestIds.MENU_LINK_BANK_MOVEMONEY,
      },
      // { label: "Request Money", route: NavRoutes.BANK_TRANSFER_SEND_REQUEST, testId: CypressTestIds.Q_NAV_DASHBOARD_BTN },
      // {
      //   label: "Incoming Requests",
      //   route: NavRoutes.TRANSFER_HANDLE_REQUEST,
      //   testId: CypressTestIds.Q_NAV_DASHBOARD_BTN
      // },
      // { label: "Deposit", route: NavRoutes.TRANSFER_DEPOSIT, testId: CypressTestIds.Q_NAV_DASHBOARD_BTN },
      {
        label: "Transactions",
        route: NavRoutes.BANK_TRANSACTIONS,
        testId: CypressTestIds.MENU_LINK_BANK_TRANSACTIONS,
      },
      // ,{
      //   label: "Deposit Checks",
      //   route: NavRoutes.BANK_DEPOSIT_CHECKS,
      //   testId: CypressTestIds.MENU_LINK_BANK_DEPOSIT_CHECKS
      // }
      // { label: "Help", route: NavRoutes.SIGN_IN, testId: CypressTestIds.Q_NAV_DASHBOARD_BTN }
    ],
  },
  // {
  //   id: "office",
  //   label: "Office",
  //   icon: <IconOffice />,
  //   testId: CypressTestIds.MENU_TAB_DIGITAL_OFFICE,
  //   theme: ColorNames.CINNAMON,
  //   links: [
  //     { label: "Dashboard", route: NavRoutes.QUEUE_DASHBOARD, testId: CypressTestIds.Q_NAV_DASHBOARD_BTN },
  //     { label: "Net Worth", route: NavRoutes.QUEUE_ACCOUNT, testId: CypressTestIds.Q_NAV_DASHBOARD_BTN },
  //     { label: "Services", route: NavRoutes.QUEUE_EARN, testId: CypressTestIds.Q_NAV_DASHBOARD_BTN },
  //     { label: "Treasury Management", route: NavRoutes.QUEUE_EARN, testId: CypressTestIds.Q_NAV_DASHBOARD_BTN },
  //     { label: "Settings", route: NavRoutes.QUEUE_EARN, testId: CypressTestIds.Q_NAV_DASHBOARD_BTN },
  //     { label: "Help", route: NavRoutes.SIGN_IN, testId: CypressTestIds.Q_NAV_DASHBOARD_BTN }
  //   ]
  // },
  // {
  //   id: "invest",
  //   label: "Invest",
  //   icon: <IconInvest />,
  //   testId: CypressTestIds.MENU_TAB_INVEST,
  //   theme: ColorNames.GRAPE,
  //   links: [
  //     { label: "Dashboard", route: NavRoutes.QUEUE_DASHBOARD, testId: CypressTestIds.Q_NAV_DASHBOARD_BTN },
  //     { label: "Autovest", route: NavRoutes.ACCOUNT_NO_PREFIX, testId: CypressTestIds.Q_NAV_DASHBOARD_BTN },
  //     { label: "Private Lending", route: NavRoutes.LANDING, testId: CypressTestIds.Q_NAV_DASHBOARD_BTN },
  //     { label: "Trade", route: NavRoutes.SEND_MONEY, testId: CypressTestIds.Q_NAV_DASHBOARD_BTN },
  //     { label: "Settings", route: NavRoutes.CREATE_CARD, testId: CypressTestIds.Q_NAV_DASHBOARD_BTN },
  //     { label: "Help", route: NavRoutes.SIGN_IN, testId: CypressTestIds.Q_NAV_DASHBOARD_BTN }
  //   ]
  // },
  {
    id: MenuTreeIds.ONELINE,
    label: "OneLine",
    icon: <IconOneLine />,
    testId: CypressTestIds.MENU_TAB_ONELINE,
    theme: ColorNames.OCEAN,
    links: [
      {
        label: "Dashboard",
        route: NavRoutes.ONELINE_DASHBOARD,
      },
      {
        label: "Lines",
        route: NavRoutes.ONELINE_LINES,
      },
    ],
  },
  {
    id: MenuTreeIds.MARKETPLACE,
    label: "Marketplace",
    icon: <IconMarketplace />,
    testId: CypressTestIds.MENU_TAB_MARKETPLACE,
    theme: ColorNames.COBALT,
    links: [
      {
        label: "Browse",
        route: NavRoutes.MARKETPLACE_LISTINGS,
        //testId: CypressTestIds.Q_NAV_DASHBOARD_BTN
      },
      {
        label: "My Deals",
        route: NavRoutes.MARKETPLACE_MY_DEALS
        //testId: CypressTestIds.Q_NAV_DASHBOARD_BTN
      }
    ],
  },
  {
    id: MenuTreeIds.SETTINGS,
    label: "Settings",
    icon: <IconSettings />,
    testId: CypressTestIds.MENU_TAB_ORGSETTINGS,
    theme: ColorNames.MIDNIGHT,
    links: [
      {
        label: "Organization",
        route: NavRoutes.EDIT_ORGANIZATION,
        testId: CypressTestIds.MENU_LINK_SETTINGS_ORGANIZATION,
      },
      {
        label: "Members",
        route: NavRoutes.MEMBERS,
        testId: CypressTestIds.MENU_LINK_SETTINGS_MEMBERS,
      },
      {
        label: "My Account",
        route: NavRoutes.USER_ACCOUNT_SETTINGS,
        testId: CypressTestIds.Q_NAV_ACCOUNT_BTN,
      },
      // { label: "Security", route: NavRoutes.LANDING, testId: CypressTestIds.Q_NAV_DASHBOARD_BTN },
      // { label: "Activity", route: NavRoutes.SEND_MONEY, testId: CypressTestIds.Q_NAV_DASHBOARD_BTN }
    ],
  },
];


export const AppMenu = (): JSX.Element => {
  const currentUser = useCurrentUser();
  const currentOrg = useCurrentOrg();
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();
  const [menuData, setMenuData] = useState<any>(null);
  const [show, setShow] = useRecoilState(showMenuState);
  const [selectedGroup, setSelectedGroup] = useRecoilState(selectedMenuGroup);
  const [selectedGroupLinks, setSelectedGroupLinks] = useState<any>(null);
  const [selectedGroupId, setSelectedGroupId] = useState<any>(null);
  const [selectedGroupLabel, setSelectedGroupLabel] = useState<any>(null);
  const [selectedGroupTheme, setSelectedGroupTheme] = useState<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isTRUser = !!currentUser && currentUser.partnershipType && currentUser.partnershipType === PartnershipType.TetonRidge;

  useEffect(() => {
    if (!currentOrg) return;

    let menuTreeApp = [ ...baseMenuTreeApp ];

    if (
      currentOrg.cards?.some(
        (card) =>
          card.type === CardType.Physical && card.status === CardStatusType.Inactive
      )
    ) {
      const bank = menuTreeApp.find((item) => item.id === "bank");
      if (!bank?.links.find((item) => item.route === NavRoutes.BANK_ACTIVATE)) {
        bank?.links.push({
          label: "Activate Cards",
          route: NavRoutes.BANK_ACTIVATE,
          testId: CypressTestIds.MENU_LINK_ACTIVATE_CARDS
        });
      }
    }
    
    if (currentOrg.orgType === OrgType.Individual) {
      const settings = menuTreeApp.find((item) => item.id === "org-settings");
      if (!!settings) {
        settings.links = settings.links.filter(
          (link) => link.route !== NavRoutes.EDIT_ORGANIZATION
        );
      }
    }

    if (!!currentUser) {
      if (!isTRUser) {
        menuTreeApp = menuTreeApp.filter((item) => item.id !== MenuTreeIds.ONELINE);
      } else {
        if (!currentOrg.oneLine || (!!currentOrg.oneLine && !currentOrg.oneLine.availableToUnderwrite)) {
          menuTreeApp = menuTreeApp.filter((item) => [MenuTreeIds.ONELINE, MenuTreeIds.SETTINGS].includes(item.id));
        }
      }
    }

    if (!!currentOrg.oneLine && currentOrg.oneLine.lines.length === 0) {
      const oneLine = menuTreeApp.find((item) => item.id === MenuTreeIds.ONELINE);
      if (!!oneLine) {
        oneLine.links = oneLine.links.filter(
          (link) => link.route !== NavRoutes.ONELINE_LINES
        );
      }
    }

    setMenuData(menuTreeApp);
  }, [currentOrg, currentUser]);

  useEffect(() => {
    const path = location.pathname;

    let group = MenuTreeIds.BANK;
    switch (true) {
      case path.includes("a/settings"): {
        group = MenuTreeIds.SETTINGS;
        break;
      }
      case path.includes("a/oneline"): {
        group = MenuTreeIds.ONELINE;
        break;
      }
      case path.includes("a/marketplace"): {
        group = MenuTreeIds.MARKETPLACE;
        break;
      }
    }
    setSelectedGroup(group);
  }, [location.pathname, setSelectedGroup]);

  useEffect(() => {
    if (!!menuData) {
      const theLinks = getMenuLinks(menuData, selectedGroup);
      if (theLinks.length > 0) {
        setSelectedGroupId(
          menuData.findIndex((obj: any) => obj.id === selectedGroup)
        );
        setSelectedGroupLabel(menuData[selectedGroupId]?.label);
        setSelectedGroupTheme(menuData[selectedGroupId]?.theme);
        setSelectedGroupLinks(getMenuLinks(menuData, selectedGroup)[0].links);
      }
    }
  }, [menuData, selectedGroup, selectedGroupId]);

  const handleClick = (route: string): void => {
    setShow(false);
    setTimeout(() => {
      if (route !== location.pathname) {
        navigate(route, { direction: NavigationDirection.FADE });
      }
    }, 200);
  };

  const handleEdgeSwipe = (e: PanEndEvent, pointInfo: PanInfo) => {
    if (pointInfo.velocity.x > 0 && !show) {
      setShow(true);
    }
    if (pointInfo.velocity.x < 0 && show) {
      setShow(false);
    }

    e.preventDefault();
  };

  if (!currentUser) {
    return (
      <>
        <DisableAppMenuFixed />
      </>
    );
  }

  return (
    <>
      <AppMenuContainer data-testid={DataTestIds.SIDE_MENU}>
        <AppMenuTabBar className={classnames({ show: show })}>
          <MenuTabs>
            <Logo height="27" width="24" />
            {menuData?.map((tab: any) => (
              <MenuTabButton
                key={tab.id}
                className={classnames({ active: selectedGroup === tab.id })}
                data-cy={tab.testId}
                color={tab.theme}
                onClick={(): void => setSelectedGroup(tab.id)}
                title={tab.label}
              >
                {tab.icon}
              </MenuTabButton>
            ))}
          </MenuTabs>
          <MenuUserActions>
            <MenuTabButton
              onClick={() =>
                navigate(NavRoutes.HELP, {
                  direction: NavigationDirection.FADE,
                })
              }
              title="Help"
            >
              <IconHelp />
            </MenuTabButton>
            <MenuTabButton
              data-testid={DataTestIds.MENU_SIGN_OUT}
              onClick={(): void => {
                showBottomSheet("log_out_sheet");
              }}
              title={"Sign Out"}
            >
              <IconLogout />
            </MenuTabButton>
            {!!currentUser && (
              <Avatar
                size={40}
                shadow
                name={`${currentUser?.firstName} ${currentUser?.lastName}`}
                // image={""}
                onClick={() => {
                  navigate(NavRoutes.USER_ACCOUNT_SETTINGS, { direction: NavigationDirection.FADE });
                  setSelectedGroup(MenuTreeIds.SETTINGS);
                }}
                title="My Account"
              />
            )}
          </MenuUserActions>
        </AppMenuTabBar>
        <AppMenuLinks
          className={classnames({ show: show })}
          onPanEnd={handleEdgeSwipe}
        >
          <AppMenuLinksInner>
            <AppMenuLinksTop>
              <AnimatePresence key={selectedGroupLabel}>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.35 }}
                >
                  <MenuLinkGroupLabel>{selectedGroupLabel}</MenuLinkGroupLabel>
                </motion.div>
              </AnimatePresence>
              {selectedGroupLinks?.map(
                (
                  item: { label: string; route: string; testId: string },
                  index: number
                ): JSX.Element => (
                  <AnimatePresence key={item.label + item.route + index}>
                    <motion.div
                      initial={listAnimationExit}
                      animate={listAnimationEnter}
                      exit={listAnimationExit}
                      transition={{ delay: 0.0125 * index }}
                    >
                      <MenuLink
                        className={classnames({
                          active: location.pathname.includes(item.route),
                        })}
                        data-cy={item.testId}
                        onClick={(): void => handleClick(item.route)}
                        color={selectedGroupTheme}
                      >
                        {item.label}
                      </MenuLink>
                    </motion.div>
                  </AnimatePresence>
                )
              )}
            </AppMenuLinksTop>
            <AppMenuLinksBottom>
              <AppMenuOrgSwitcher
                onClick={(): void => {
                  showOrgSwitcherState(true);
                }}
              >
                {currentOrg?.name || "Personal"}
                <IconExpand />
              </AppMenuOrgSwitcher>
            </AppMenuLinksBottom>
          </AppMenuLinksInner>
        </AppMenuLinks>
      </AppMenuContainer>
      <AppMenuSwipeHandler onPanEnd={handleEdgeSwipe} />
      {show && (
        <Overlay
          onPanEnd={handleEdgeSwipe}
          onClick={() => setShow(false)}
          data-testid={DataTestIds.SIDE_MENU_OVERLAY}
        />
      )}
      <ActionBottomSheet
        id="log_out_sheet"
        title="Sign out"
        description="Are you sure you want to sign out of this account?"
        actionText="Yes, Sign Me Out"
        actionColor={ColorNames.CARDINAL}
        onAction={(): void => {
          setShow(false);
          hideBottomSheet();
          needsLogoutVar({ logout: true, supressAlert: true });
          showNotice("You have successfully signed out.");
        }}
        secondaryActionText="Close"
        onSecondaryAction={(): void => hideBottomSheet()}
      />
    </>
  );
};

const listAnimationEnter = {
  opacity: 1,
  x: 0,
};
const listAnimationExit = {
  opacity: 0,
  x: -20,
};
