import React, { useEffect, useState, useRef } from "react";
import { useSearchParams, useFetcher } from "react-router-dom";
import { useCookies } from "react-cookie";

import { useNavbar } from "src/util/useNavbar";
import useNavigate from "src/util/useNavigate";
import { showNotice } from "src/store/alertState";
import useScript from "src/util/useScript";
import { useFetchCurrentUser, useCurrentUser } from "src/services/users";
import { useIsSessionValid, ValidSession } from "src/util/useIsSessionValid";
import { useAuthRedirect, LoginResult, useRecaptchaSiteVerify } from "src/services/auth";
import { useShowBottomSheet } from "src/util/useBottomSheet";

import { NavRoutes } from "src/routes/navRoutes";
import { NavigationDirection } from "src/store/navigationState";
import { ColorNames } from "src/theme/theme";
import { CypressTestIds } from "src/util/testing-util/test-utils";
import { storedCookieDataKey } from "src/util/localStorage";
import { RouteActionResponse } from "src";
import { needsLogoutVar } from "src/store/currentUserState";

import { BasicAuthContainer } from "src/pages/auth/Auth.styles";
import { AuthPageWrapper } from "src/pages/auth/Auth.styles";
import { ButtonGroup } from "src/components/button/Button.styles";

import Layout from "src/components/core-layout/Layout";
import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import { LabelInput } from "src/components/forms/input/LabelInput";
import { BannerNotice } from "src/components/alert/BannerNotice";
import { Button } from "src/components/button/Button";
import { ReactComponent as IconInfo } from "src/assets/icons/info.svg";

const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

const SignIn = (): JSX.Element => {
  const { data, state: fetcherState, Form, ...fetcher } = useFetcher<RouteActionResponse<LoginResult>>();

  const { updateNavbar } = useNavbar({
    back: true,
    backAction: () => navigate(NavRoutes.LANDING, { direction: NavigationDirection.BACKWARD }),
    title: "Sign In",
    orgSwitcher: false
  });
  const { validSession, loading: sessionLoading } = useIsSessionValid();
  const currentUser = useCurrentUser();
  const { fetchCurrentUser, userLoading } = useFetchCurrentUser();
  const { verify } = useRecaptchaSiteVerify();
  const [hasSignedIn, setHasSignedIn] = useState(false);
  const [queryParams] = useSearchParams();
  const [inviteCode, setInviteCode] = useState<string>();
  const [recaptchaScore, setRecaptchaScore] = useState<number>();
  const [cookies, , removeCookie] = useCookies([
    storedCookieDataKey.ACCEPTED_INVITE_CODE_PREFIX,
    storedCookieDataKey.INVITE_CODE_PREFIX
  ]);
  const navigate = useNavigate();
  
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();
  const didLoad = useRef<boolean>(false);
  const status = useScript(`https://www.google.com/recaptcha/api.js?render=${REACT_APP_RECAPTCHA_SITE_KEY}`);
  const { updateAuthInviteCode } = useAuthRedirect({
    queryParams,
    invite: cookies[storedCookieDataKey.INVITE_CODE_PREFIX]
  });

  useEffect(() => {
    if (!!data?.validationErrors && Object.keys(data.validationErrors).length > 0) {
      const firstError = Object.values(data.validationErrors)[0] as string;
      showNotice(firstError, { error: true });
    }
  }, [data?.validationErrors]);

  useEffect(() => {
    if (!!data?.error) {
      let errorMessage = "There was a problem logging in.";
      if (hasSignedIn) {
        errorMessage = "There was a problem verifying your code.";
        hideBottomSheet();
        setHasSignedIn(false);
      } else {
        if (data.error.message === "Incorrect email used for invite" || data.error.message === "Invalid invite code") {
          errorMessage = "Your invite code does not appear to be valid, please try again.";
          removeCookie(storedCookieDataKey.INVITE_CODE_PREFIX);
          updateAuthInviteCode();
          setInviteCode(undefined);
        }
      }
      showNotice(errorMessage, { error: true });
    }
  }, [data?.error]);

  useEffect(() => {
    if (!!data?.response && data.response.session) {
      if (hasSignedIn) {
        fetchCurrentUser({ fromNetwork: true });
        updateNavbar({ title: "", back: false, backAction: undefined });
      } else {
        setHasSignedIn(true);
        if (data.response.mfaEnabled) {
          showBottomSheet("signin_mfa_sheet");
        } else {
          updateNavbar({ title: "", back: false, backAction: undefined });
          fetchCurrentUser({ fromNetwork: true });
        }
      }
    }
  }, [data?.response]);

  useEffect(() => {
    if (!window.grecaptcha || !REACT_APP_RECAPTCHA_SITE_KEY) {
      return;
    }
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(REACT_APP_RECAPTCHA_SITE_KEY, { action: "submit" }).then(async (token) => {
        const response = await verify(token);

        setRecaptchaScore(response?.score);
      });
    });
  }, [status]);

  useEffect(() => {
    if (sessionLoading) return;
    if (validSession === ValidSession.VALID) {
      updateNavbar({ title: "", back: false, backAction: undefined });
      fetchCurrentUser({ fromNetwork: true });
    }
  }, [validSession, sessionLoading, fetchCurrentUser]);

  useEffect(() => {
    if (sessionLoading || didLoad.current) return;
    if (!cookies[storedCookieDataKey.INVITE_CODE_PREFIX] && validSession !== ValidSession.INVALID) return;

    const inviteCookie = cookies[storedCookieDataKey.INVITE_CODE_PREFIX];
    if (inviteCookie) {
      setInviteCode(inviteCookie);
    }

    didLoad.current = true;
  }, [sessionLoading, validSession, cookies]);

  const handleVerifyMfa = (token: string) => {
    if (fetcherState === "submitting") return;
    fetcher.submit({ token }, { method: "post", action: NavRoutes.API_MFA });
  };

  return (
    <Layout loading={userLoading || !!currentUser}>
      <AuthPageWrapper>
        <BasicAuthContainer>
          <Form method="post">
            <fieldset>
              {inviteCode && (
                <>
                  <BannerNotice iconLeft={<IconInfo />} textColor={ColorNames.CINNAMON} iconColor={ColorNames.GOLD}>
                    <>Sign in using the email address where you received your invite.</>
                  </BannerNotice>
                  <input type="hidden" name="inviteCode" defaultValue={inviteCode} />
                </>
              )}

              <LabelInput
                name="email"
                type="email"
                label="Email Address"
                autoComplete="email"
                autoFocus
                error={!!data?.validationErrors?.email}
                data-cy={CypressTestIds.SIGN_IN_EMAIL}
              />
              <LabelInput
                name="password"
                type="password"
                label="Password"
                autoComplete="current-password"
                error={!!data?.validationErrors?.password}
                data-cy={CypressTestIds.SIGN_IN_PASSWORD}
              />
              <input type="hidden" name="recaptchaScore" defaultValue={recaptchaScore} />
            </fieldset>

            <ButtonGroup>
              <Button raised type="submit" data-cy={CypressTestIds.SIGN_IN_BTN} loading={fetcherState === "submitting"}>
                Sign In
              </Button>
              <Button
                onClick={(): void => navigate(NavRoutes.FORGOT_PASSWORD)}
                color={ColorNames.TRANSPARENT}
                raised={false}
                data-cy={CypressTestIds.FORGOT_PASSWORD_BTN}
              >
                Forgot Password
              </Button>
            </ButtonGroup>
          </Form>

          <ActionBottomSheet
            dataCy={CypressTestIds.SIGN_IN_MFA_TOKEN_INPUT_FIELD}
            id="signin_mfa_sheet"
            title="Authenticate Account"
            description="Enter the MFA code generated by your authenticator app."
            actionText="Sign In"
            secondaryActionText="Cancel"
            onAction={(code: string) => handleVerifyMfa(code)}
            onSecondaryAction={(): void => {
              hideBottomSheet();
              needsLogoutVar({ logout: true, supressAlert: true, disableRedirect: true });
            }}
            actionLoading={fetcherState === "submitting"}
            onClose={() => {
              setHasSignedIn(false);
              needsLogoutVar({ logout: true, supressAlert: true, disableRedirect: true });
            }}
            mfa
          />
        </BasicAuthContainer>
      </AuthPageWrapper>
    </Layout>
  );
};

export default SignIn;
