export enum BackgroundImageSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large"
}

export enum ColorNames {
  MIDNIGHT = "midnight",
  WHITE = "white",
  GRAY1 = "gray1",
  GRAY2 = "gray2",
  GRAY3 = "gray3",
  GRAY4 = "gray4",
  GRAY5 = "gray5",
  TRANSPARENT = "transparent",

  // greens
  PINE = "pine",
  TEA = "tea",
  GRASS = "grass",
  SEAFOAM = "seafoam",
  MINT = "mint",
  LIGHT_LIME = "light_lime",

  // reds
  CRIMSON = "crimson",
  CARDINAL = "cardinal",
  BUBBLE_GUM = "bubble_gum",
  ROSE = "rose",
  CREAM = "cream",

  // blues
  COBALT = "cobalt",
  LAKE = "lake",
  BABY = "baby",
  MIST = "mist",
  POWDER = "powder",

  // teals
  DEEP_WATER = "deep_water",
  OCEAN = "ocean",
  LAGOON = "lagoon",
  BREEZE = "breeze",

  // yellows
  SAFFRON = "saffron",
  MELLOW = "mellow",
  SUNSHINE = "sunshine",
  NOTE = "note",

  // browns
  CINNAMON = "cinnamon",
  GOLD = "gold",
  PEANUT = "peanut",
  DUST = "dust",
  SAND = "sand",

  // purples
  EGGPLANT = "eggplant",
  GRAPE = "grape",
  LAVENDER = "lavender",
  PERIWINKLE = "periwinkle",
  LILAC = "lilac"
}

export type BrandColor =
  | ColorNames.MIDNIGHT
  | ColorNames.PINE
  | ColorNames.MINT
  | ColorNames.CARDINAL
  | ColorNames.CREAM
  | ColorNames.LAKE
  | ColorNames.WHITE;
export type GrayColor = ColorNames.GRAY1 | ColorNames.GRAY2 | ColorNames.GRAY3 | ColorNames.GRAY4 | ColorNames.GRAY5;
export type GreenColor =
  | ColorNames.PINE
  | ColorNames.TEA
  | ColorNames.GRASS
  | ColorNames.SEAFOAM
  | ColorNames.MINT
  | ColorNames.LIGHT_LIME;
export type RedColor =
  | ColorNames.CRIMSON
  | ColorNames.CARDINAL
  | ColorNames.BUBBLE_GUM
  | ColorNames.ROSE
  | ColorNames.CREAM;
export type BlueColor = ColorNames.COBALT | ColorNames.LAKE | ColorNames.BABY | ColorNames.MIST | ColorNames.POWDER;
export type TealColor = ColorNames.DEEP_WATER | ColorNames.OCEAN | ColorNames.LAGOON | ColorNames.BREEZE;
export type YellowColor = ColorNames.SAFFRON | ColorNames.MELLOW | ColorNames.SUNSHINE | ColorNames.NOTE;
export type BrownColor = ColorNames.CINNAMON | ColorNames.GOLD | ColorNames.PEANUT | ColorNames.DUST | ColorNames.SAND;
export type PurpleColor =
  | ColorNames.EGGPLANT
  | ColorNames.GRAPE
  | ColorNames.LAVENDER
  | ColorNames.PERIWINKLE
  | ColorNames.LILAC;

export type GradientColor =
  | ColorNames.MIDNIGHT
  | ColorNames.TEA
  | ColorNames.MINT
  | ColorNames.CARDINAL
  | ColorNames.ROSE
  | ColorNames.CREAM
  | ColorNames.LAKE
  | ColorNames.MIST
  | ColorNames.NOTE
  | ColorNames.WHITE
  | ColorNames.DEEP_WATER;

export type ThemeColor =
  | BrandColor
  | GrayColor
  | GreenColor
  | RedColor
  | BlueColor
  | TealColor
  | YellowColor
  | BrownColor
  | PurpleColor;

export interface ColorType {
  hex: string;
  rgb: string;
}

export const colorPalette: { [key in ThemeColor]: ColorType } = {
  [ColorNames.MIDNIGHT]: {
    hex: "#191B1F",
    rgb: "25,27,31"
  },
  [ColorNames.WHITE]: {
    hex: "#FFFFFF",
    rgb: "255,255,255"
  },
  [ColorNames.GRAY1]: {
    hex: "#F6F9F9",
    rgb: "246,249,249"
  },
  [ColorNames.GRAY2]: {
    hex: "#E6EBEC",
    rgb: "230,235,236"
  },
  [ColorNames.GRAY3]: {
    hex: "#D5DBDC",
    rgb: "213,219,220"
  },
  [ColorNames.GRAY4]: {
    hex: "#BFC7C9",
    rgb: "191,199,201"
  },
  [ColorNames.GRAY5]: {
    hex: "#9FABAD",
    rgb: "159,171,173"
  },

  // greens
  [ColorNames.PINE]: {
    hex: "#186F64",
    rgb: "24,111,100"
  },
  [ColorNames.TEA]: {
    hex: "#74B4AB",
    rgb: "116,180,171"
  },
  [ColorNames.GRASS]: {
    hex: "#0BB58B",
    rgb: "11,181,139"
  },
  [ColorNames.SEAFOAM]: {
    hex: "#58DAC2",
    rgb: "88,218,194"
  },
  [ColorNames.MINT]: {
    hex: "#E1F1F0",
    rgb: "225,241,240"
  },
  [ColorNames.LIGHT_LIME]: {
    hex: "#EEFCEF",
    rgb: "238,252,239"
  },

  // reds
  [ColorNames.CRIMSON]: {
    hex: "#EC3D08",
    rgb: "236,61,8"
  },
  [ColorNames.CARDINAL]: {
    hex: "#FC5963",
    rgb: "252,89,99"
  },
  [ColorNames.BUBBLE_GUM]: {
    hex: "#FD95A2",
    rgb: "253,149,162"
  },
  [ColorNames.ROSE]: {
    hex: "#FFCCCB",
    rgb: "255,204,203"
  },
  [ColorNames.CREAM]: {
    hex: "#FAEEE4",
    rgb: "250,238,228"
  },

  // blues
  [ColorNames.COBALT]: {
    hex: "#154EA5",
    rgb: "21,78,165"
  },
  [ColorNames.LAKE]: {
    hex: "#536EFF",
    rgb: "83,110,255"
  },
  [ColorNames.BABY]: {
    hex: "#73A3EB",
    rgb: "115,163,235"
  },
  [ColorNames.MIST]: {
    hex: "#DAEAFD",
    rgb: "218,234,253"
  },
  [ColorNames.POWDER]: {
    hex: "#E5F2FA",
    rgb: "229,242,250"
  },

  // teals
  [ColorNames.DEEP_WATER]: {
    hex: "#005D72",
    rgb: "0,93,114"
  },
  [ColorNames.OCEAN]: {
    hex: "#099FCD",
    rgb: "9,159,205"
  },
  [ColorNames.LAGOON]: {
    hex: "#80CEE5",
    rgb: "128,206,229"
  },
  [ColorNames.BREEZE]: {
    hex: "#E1F6FB",
    rgb: "225,246,251"
  },

  // yellows
  [ColorNames.SAFFRON]: {
    hex: "#F8AC53",
    rgb: "248,172,83"
  },
  [ColorNames.MELLOW]: {
    hex: "#EDD376",
    rgb: "237,211,118"
  },
  [ColorNames.SUNSHINE]: {
    hex: "#F9EA65",
    rgb: "249,234,101"
  },
  [ColorNames.NOTE]: {
    hex: "#FDF5DA",
    rgb: "253,245,218"
  },

  // browns
  [ColorNames.CINNAMON]: {
    hex: "#C37D59",
    rgb: "195,125,89"
  },
  [ColorNames.GOLD]: {
    hex: "#D6A872",
    rgb: "214,168,114"
  },
  [ColorNames.PEANUT]: {
    hex: "#E5C59D",
    rgb: "229,197,157"
  },
  [ColorNames.DUST]: {
    hex: "#F0E9DD",
    rgb: "240,233,221"
  },
  [ColorNames.SAND]: {
    hex: "#F8F5EF",
    rgb: "248,245,239"
  },

  // purples
  [ColorNames.EGGPLANT]: {
    hex: "#303974",
    rgb: "48,57,116"
  },
  [ColorNames.GRAPE]: {
    hex: "#644BC4",
    rgb: "100,75,196"
  },
  [ColorNames.LAVENDER]: {
    hex: "#B196FF",
    rgb: "177,150,255"
  },
  [ColorNames.PERIWINKLE]: {
    hex: "#E2B7E0",
    rgb: "226,183,224"
  },
  [ColorNames.LILAC]: {
    hex: "#E6DEF0",
    rgb: "230,222,240"
  }
};

export const rgbGradientColors: { [key in GradientColor]: Array<string> } = {
  [ColorNames.MIDNIGHT]: ["56,58,65", colorPalette.midnight.rgb],
  [ColorNames.TEA]: ["141,200,192", colorPalette.tea.rgb],
  [ColorNames.MINT]: ["245,250,250", colorPalette.mint.rgb],
  [ColorNames.CARDINAL]: [colorPalette.cardinal.rgb, "251,70,81"],
  [ColorNames.ROSE]: ["255,229,228", colorPalette.rose.rgb],
  [ColorNames.CREAM]: ["255,248,242", colorPalette.cream.rgb],
  [ColorNames.LAKE]: [colorPalette.lake.rgb, "60,98,232"],
  [ColorNames.MIST]: ["238,246,255", colorPalette.mist.rgb],
  [ColorNames.NOTE]: ["255,252,241", colorPalette.note.rgb],
  [ColorNames.WHITE]: [colorPalette.white.rgb, colorPalette.gray2.rgb],
  [ColorNames.DEEP_WATER]: [],
};

export const colors = (Object.keys(colorPalette) as Array<keyof typeof colorPalette>).reduce(
  (o, key) => ({ ...o, [key]: colorPalette[key].hex }),
  {}
);

export const borderRadius = "6px";
export const borderRadiusOuter = "12px";

export const bezierMenu = "cubic-bezier(0.215, 0.61, 0.355, 1)";
export const bezierMenuExpo = "cubic-bezier(0.38, 0.5, 0.35, 1)";

export enum ShadowNames {
  EXTRA_LIGHT = "extra_light",
  LIGHT = "light",
  DARK = "dark",
  AVATAR = "avatar"
}

export const dropShadows: { [key in ShadowNames]: string } = {
  [ShadowNames.EXTRA_LIGHT]:
    "0px 35px 40px rgba(25, 27, 31, 0.03), 0px 16px 16px rgba(25, 27, 31, 0.03), 0px 3px 6px rgba(25, 27, 31, 0.025), 0px 1px 1px rgba(25, 27, 31, 0.04), inset 0px -1px 2px rgba(25, 27, 31, 0.02), inset 0px 2px 1px rgba(255, 255, 255, 0.3)",
  [ShadowNames.LIGHT]:
    "0 60px 70px rgba(25, 27, 31, 0.03), 0 40px 45px rgba(25, 27, 31, 0.03), 0 23px 29px rgba(25, 27, 31, 0.04), 0 8px 19px rgba(25, 27, 31, 0.05), 0 1px 1px rgba(25, 27, 31, 0.05)",
  [ShadowNames.DARK]:
    "0 120px 83px rgba(25, 27, 31, 0.03), 0 40px 45px rgba(25, 27, 31, 0.03), 0 23px 29px rgba(25, 27, 31, 0.12), 0 8px 19px rgba(25, 27, 31, 0.15), 0 1px 2px rgba(25, 27, 31, 0.15)",
  [ShadowNames.AVATAR]:
    "0 17px 25px rgba(25, 27, 31, 0.05), 0 1px 19px rgba(25, 27, 31, 0.05), 0 6px 11px rgba(25, 27, 31, 0.2), 0 4px 10px rgba(25, 27, 31, 0.1), 0 1px 4px rgba(25, 27, 31, 0.06)"
};

const FAMILY_HEADING = "AlbraSans";
const FAMILY_NUMBER = "NeufileGrotesk";
const FAMILY_PARAGRAPH = "NeufileGrotesk";

const fontSettings: object = {
  HEADING_1: {
    family: FAMILY_HEADING,
    size: 54,
    lineHeight: 120,
    weight: 400
  },
  HEADING_2: {
    family: FAMILY_HEADING,
    size: 46,
    lineHeight: 120,
    weight: 600
  },
  HEADING_3: {
    family: FAMILY_HEADING,
    size: 40,
    lineHeight: 115,
    weight: 400
  },
  HEADING_4: {
    family: FAMILY_HEADING,
    size: 32,
    lineHeight: 120,
    weight: 500
  },
  HEADING_5: {
    family: FAMILY_HEADING,
    size: 22,
    lineHeight: 120,
    weight: 600
  },
  NUMBER_S: {
    family: FAMILY_NUMBER,
    size: 13,
    lineHeight: 130,
    weight: 500,
    letterSpacing: "0.02em",
    ligatures: true
  },
  NUMBER_M: {
    family: FAMILY_NUMBER,
    size: 16,
    lineHeight: 130,
    weight: 500,
    letterSpacing: "0.02em",
    ligatures: true
  },
  NUMBER_L: {
    family: FAMILY_NUMBER,
    size: 20,
    lineHeight: 130,
    weight: 400,
    letterSpacing: "0.02em",
    ligatures: true
  },
  NUMBER_XL: {
    family: FAMILY_NUMBER,
    size: 28,
    lineHeight: 130,
    weight: 400,
    letterSpacing: "0.02em",
    ligatures: true
  },
  NUMBER_XXL: {
    family: FAMILY_NUMBER,
    size: 40,
    lineHeight: 130,
    weight: 400,
    letterSpacing: "0.02em",
    ligatures: true
  },
  NUMBER_XXXL: {
    family: FAMILY_NUMBER,
    size: 48,
    lineHeight: 130,
    weight: 400,
    letterSpacing: "0.02em",
    ligatures: true
  },
  PARAGRAPH_XS: {
    family: FAMILY_PARAGRAPH,
    size: 11,
    lineHeight: 130,
    weight: 500
  },
  PARAGRAPH_S: {
    family: FAMILY_PARAGRAPH,
    size: 13,
    lineHeight: 130,
    weight: 500
  },
  PARAGRAPH_M: {
    family: FAMILY_PARAGRAPH,
    size: 16,
    lineHeight: 130,
    weight: 500
  },
  PARAGRAPH_L: {
    family: FAMILY_PARAGRAPH,
    size: 18,
    lineHeight: 130,
    weight: 500
  },
  PARAGRAPH_XL: {
    family: FAMILY_PARAGRAPH,
    size: 20,
    lineHeight: 140,
    weight: 400
  },
  PARAGRAPH_XXL: {
    family: FAMILY_PARAGRAPH,
    size: 28,
    lineHeight: 140,
    weight: 400
  },
  SECTION_S: {
    family: FAMILY_PARAGRAPH,
    size: 8,
    lineHeight: 130,
    weight: 600,
    letterSpacing: "0.03em",
    uppercase: true
  },
  SECTION_M: {
    family: FAMILY_PARAGRAPH,
    size: 10,
    lineHeight: 130,
    weight: 600,
    letterSpacing: "0.03em",
    uppercase: true
  },
  SECTION_L: {
    family: FAMILY_PARAGRAPH,
    size: 13,
    lineHeight: 130,
    weight: 600,
    letterSpacing: "0.03em",
    uppercase: true
  },
  TAG: {
    family: FAMILY_PARAGRAPH,
    size: 10,
    lineHeight: 100,
    weight: 600
  }
};

const computedCss = (
  family: string,
  size: number,
  lineHeight: number,
  weight?: number,
  letterSpacing?: string,
  ligatures?: boolean,
  uppercase?: boolean
) => {
  let baseString = `font: ${size}px/${lineHeight}% "${family}";`;
  let computedString = baseString;

  if (weight) {
    baseString = `font: ${weight} ${size}px/${lineHeight}% "${family}";`;
    computedString = baseString;
  }

  if (letterSpacing) {
    computedString = baseString + `letter-spacing: ${letterSpacing};`;
  }

  if (ligatures) {
    computedString = computedString + `font-feature-settings: 'tnum' on, 'lnum' on;`;
  }

  if (uppercase) {
    computedString = computedString + `text-transform: uppercase;`;
  }

  return computedString;
};

const computeFonts = () => {
  let computed = {};

  for (const [key, value] of Object.entries(fontSettings)) {
    computed = {
      ...computed,
      [key]: computedCss(
        value.family,
        value.size,
        value.lineHeight,
        value.weight,
        value.letterSpacing,
        value.ligatures,
        value.uppercase
      )
    };
  }

  return computed;
};

export const fonts: any = computeFonts();

export interface DefaultTheme {
  colors: { [key: string]: string };
  fonts: { [key: string]: string };
}

export default {
  colors,
  fonts
};
