import React from "react";

import { AuthContainer } from "src/pages/auth/Auth";
import { AuthTitle } from "src/pages/auth/Auth.styles";
import { ModalContainer } from "src/routes/app/onboarding/Onboarding.styles";
import { NextModal } from "src/components/cta/next-modal/NextModal";
import { CypressTestIds } from "src/util/testing-util/test-utils";

interface AccountTypeProps {
  loadingPersonal: boolean;
  handleTrustAccount: () => void;
  handleBusinessAccount: () => void;
  handlePersonalAccount: () => void;
  stepDirection: number;
}

const AccountType = ({
  loadingPersonal,
  handleTrustAccount,
  handleBusinessAccount,
  handlePersonalAccount,
  stepDirection
}: AccountTypeProps): JSX.Element => {
  return (
    <AuthContainer stepDirection={stepDirection}>
      <AuthTitle>Account Type</AuthTitle>

      <ModalContainer>
        {/* Hiding Trusts for now, until we can complete the Notarize integration */}
        {/* <NextModal
          title="Trust"
          description="Set up a revocable trust account for your whole crew."
          loading={loading}
          disabled={loading}
          onClick={handleTrustAccount}
          cy={CypressTestIds.ONBOARD_TRUST_ACCOUNT}
        /> */}
        <NextModal
          title="Business"
          description="Set up an account for your entire corporation, LLC, etc."
          onClick={handleBusinessAccount}
          cy={CypressTestIds.ONBOARD_BUSINESS_ACCOUNT}
        />
        <NextModal
          title="Personal"
          description="Set up an account for yourself and invite up to two people."
          loading={loadingPersonal}
          disabled={loadingPersonal}
          onClick={handlePersonalAccount}
          cy={CypressTestIds.ONBOARD_INDIVIDUAL_ACCOUNT}
        />
      </ModalContainer>
      <div />
    </AuthContainer>
  );
};

export default AccountType;
