import React, { ReactNode } from "react";

import { DataTestIds } from "src/util/testing-util/test-utils";
import { NavBarStepper } from "src/components/nav-bars/nav-bar-stepper/NavBarStepper";
import {
  StyledNavBar,
  StyledNavBarContent,
  BackArrow,
  NavSpacer,
  CloseIcon,
  ActionIconContainer
} from "src/components/nav-bars/NavBars.styles";

export interface StepperNavProps {
  onBackClick: () => unknown | void;
  step: number;
  totalSteps: number;
  hideBackButton?: boolean;
  actionIcon?: ReactNode;
  onActionClick?: () => unknown | void;
}

export const StepperNav = ({
  onBackClick,
  step,
  totalSteps,
  hideBackButton = false,
  actionIcon,
  onActionClick
}: StepperNavProps): JSX.Element => {
  return (
    <StyledNavBar>
      <StyledNavBarContent>
        {hideBackButton ? (
          <NavSpacer />
        ) : step === 0 ? (
          <CloseIcon onClick={onBackClick} data-testid={DataTestIds.ONBOARDING_CLOSE} />
        ) : (
          <BackArrow onClick={onBackClick} data-testid={DataTestIds.ONBOARDING_NAV_BACK} />
        )}
        <NavBarStepper step={step} totalSteps={totalSteps} />
        {actionIcon ? (
          <ActionIconContainer onClick={onActionClick} data-testid={DataTestIds.ACTION_ICON}>
            {actionIcon}
          </ActionIconContainer>
        ) : (
          <NavSpacer />
        )}
      </StyledNavBarContent>
    </StyledNavBar>
  );
};
