import React, { useState, useEffect, useMemo } from "react";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";

import { AuthContainer } from "src/pages/auth/Auth";
import { AuthTitle } from "src/pages/auth/Auth.styles";
import States from "src/data/states.json";
import { Button } from "src/components/button/Button";
import { Input } from "src/components/forms/input/Input";
import { Select } from "src/components/forms/select/FormikSelect";
import { Form } from "src/components/forms/Form";
import { Toggle } from "src/components/toggle/Toggle";
import {
  StyledFormInputContainer,
  InputRow,
  StyledFieldContainer,
} from "src/components/forms";
import { AddTrustInfoData } from "src/services/organizations";
import { zipCodeFormatRegex } from "src/util/stringUtils";
import { showNotice } from "src/store/alertState";
import { Organization } from "src/generated/client";

const TrustInfoSchema: Yup.ObjectSchema<object> = Yup.object().shape({
  orgName: Yup.string().required("Please add a trust name."),
  stateOfJurisdiction: Yup.string().required(
    "Please add a state of jurisdiction."
  ),
  streetAddress: Yup.string().required("Please add an address."),
  city: Yup.string().required("Please add a city."),
  state: Yup.string().required("Please add a state."),
  zip: Yup.string()
    .matches(zipCodeFormatRegex, "Please use a valid zip code.")
    .required("Please add a zip code."),
});

type FormValues = {
  city: string;
  orgName: string;
  stateOfJurisdiction: string;
  state: string;
  streetAddress: string;
  zip: string;
};

const TrustInfo = ({
  nextStep,
  stepDirection,
  orgData,
}: {
  nextStep: (
    values: Omit<AddTrustInfoData, "orgType">,
    setSubmitting: (submitting: boolean) => void
  ) => void;
  stepDirection: number;
  orgData?: Partial<Organization>;
}): JSX.Element => {
  const [trusteeConfirmation, setTrusteeConfirmation] = useState(false);

  const ResetForm = () => {
    const { setFieldValue, values } = useFormikContext();

    useEffect(() => {
      return () => {
        if ((values as FormValues).streetAddress === "null")
          setFieldValue("streetAddress", "");
        if ((values as FormValues).city === "null") setFieldValue("city", "");
        if ((values as FormValues).zip === "null") setFieldValue("zip", "");
      };
    }, [values, setFieldValue]);
    return null;
  };

  const statesForDropdown = [...States];
  statesForDropdown.unshift({
    label: "Choose state",
    value: "",
  });

  const initialFormValues = useMemo(() => {
    return {
      orgName: orgData?.name || "",
      stateOfIncorporation: orgData?.stateOfIncorporation || "",
      streetAddress: orgData?.streetAddress || "",
      city: orgData?.city || "",
      state: orgData?.state || "",
      zip: orgData?.zip || "",
    };
  }, [orgData]);

  return (
    <AuthContainer stepDirection={stepDirection}>
      <AuthTitle>Trust Information</AuthTitle>

      <Formik
        initialValues={initialFormValues}
        enableReinitialize={true}
        validationSchema={TrustInfoSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { setSubmitting }): void => {
          if (!trusteeConfirmation) {
            showNotice("You must be a trustee to continue.", { error: true });
            setSubmitting(false);
            return;
          }
          nextStep(values, setSubmitting);
        }}
      >
        {({
          isSubmitting,
          handleChange,
          setFieldValue,
          values,
        }): JSX.Element => (
          <Form>
            <>
              <StyledFormInputContainer>
                <ResetForm />
                <Input name="orgName" type="text" label="Trust Name" />
                <Select
                  name="stateOfJurisdiction"
                  label="State of Jurisdiction"
                  options={statesForDropdown}
                  autocomplete="region"
                />
                <Input
                  name="streetAddress"
                  type="text"
                  label="Trust Street Address"
                  autocomplete="shipping street-address"
                />
                <Input
                  name="city"
                  type="test"
                  label="City"
                  autocomplete="shipping locality"
                />
                <InputRow>
                  <Select
                    name="state"
                    label="State"
                    options={statesForDropdown}
                    autocomplete="shipping region"
                  />
                  <Input
                    name="zip"
                    type="test"
                    label="ZIP Code"
                    autocomplete="shipping postal-code"
                  />
                </InputRow>
                <StyledFieldContainer type="toggle">
                  <Toggle
                    displayLabel="I am a trustee of the above trust."
                    name="trusteeConfirmation"
                    align="left"
                    checked={trusteeConfirmation}
                    onChange={(): void => {
                      setTrusteeConfirmation(!trusteeConfirmation);
                    }}
                  />
                </StyledFieldContainer>
              </StyledFormInputContainer>
              <Button raised type="submit" loading={isSubmitting}>
                Continue
              </Button>
            </>
          </Form>
        )}
      </Formik>
    </AuthContainer>
  );
};

export default TrustInfo;
