import styled, { css } from "styled-components";
import { ReactComponent as Copy } from "src/assets/icons/copy.svg";
import { ReactComponent as Check } from "src/assets/icons/check.svg";
import { colorPalette as colors, ColorNames } from "src/theme/theme";

import { ReadOnlyInputProps } from "src/components/forms/read-only-input/ReadOnlyInput";

import { fieldStyle } from "src/components/forms";

const iconStyles = css`
  width: 20px;
  height: 20px;
  margin-left: 10px;
  position: relative;
  top: -8px;

  path {
    stroke: ${colors[ColorNames.GRAY3].hex};
  }
`;

export const StyledReadOnlyInput = styled.input<ReadOnlyInputProps>`
  ${fieldStyle}
`;

export const CopyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CheckIcon = styled(Check)`
  ${iconStyles}
`;

export const CopyIcon = styled(Copy)`
  ${iconStyles}
  cursor: pointer;
`;
