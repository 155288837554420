import React, { useEffect } from "react";
import { useFetcher, generatePath } from "react-router-dom";

import { useCurrentOrg } from "src/services/organizations";
import { useNavbar } from "src/util/useNavbar";
import useNavigate from "src/util/useNavigate";
import { ColorNames } from "src/theme/theme";
import { NavRoutes } from "src/routes/navRoutes";
import { coreLayoutBodyBackgroundColor } from "src/store/layoutState";
import { NavigationDirection } from "src/store/navigationState";
import { LineType } from "src/generated/client";
import { LineTypeTitle } from "src/types/line";
import {
  decorateCurrencyValue,
  formatPercentageRange,
} from "src/util/stringUtils";

import {
  GenericWrapper,
  BorrowCardWrapper,
  BorrowCardHeader,
  BorrowCardTitle,
  BorrowCardDescription,
} from "../OneLine.styles";
import {
  AmountDollars,
  BalanceAmountHeader,
  BalanceLabel,
} from "src/routes/app/bank/accounts/Accounts.styles";
import { InfoListItemsWrapper } from "src/components/info-list-item/InfoListItem.styles";

import Layout from "src/components/core-layout/Layout";
import { BalanceAmount } from "src/components/balance-amount/BalanceAmount";
import { Badge } from "src/components/badge/Badge";
import { Button } from "src/components/button/Button";
import { InfoListItem } from "src/components/info-list-item/InfoListItem";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { ReactComponent as IconConnect } from "src/assets/icons/connect.svg";

const Borrow = () => {
  const {
    data: lineTypes,
    state: fetcherState,
    ...fetcher
  } = useFetcher<LineType[]>();

  const currentOrg = useCurrentOrg({ fullFetch: true });
  const navigate = useNavigate();

  useNavbar({
    title: "Borrow Now",
    backAction: () => {
      navigate(NavRoutes.ONELINE_DASHBOARD, {
        direction: NavigationDirection.BACKWARD,
      });
    },
  });

  useEffect(() => {
    setTimeout(() => coreLayoutBodyBackgroundColor(ColorNames.GRAY1), 0);
  }, []);

  useEffect(() => {
    if (!lineTypes && fetcherState === "idle")
      fetcher.load(`${NavRoutes.API_LINE}?get=lineTypes`);
  }, [lineTypes, fetcherState, fetcher]);

  return (
    <Layout loading={!lineTypes} backgroundColor={ColorNames.GRAY1}>
      <BalanceAmountHeader>
        <AmountDollars color={ColorNames.MIDNIGHT}>
          <BalanceAmount
            availableBalance={
              currentOrg.oneLine?.totals.availableToBorrow.display
            }
          />
        </AmountDollars>
        <BalanceLabel color={ColorNames.OCEAN}>
          <div className="label">Available to Borrow</div>
        </BalanceLabel>
      </BalanceAmountHeader>

      <GenericWrapper>
        {lineTypes?.map((lineType) => (
          <BorrowCard
            key={lineType.id}
            title={lineType.title}
            description={lineType.description}
            canBorrow={currentOrg.oneLine?.totals.availableToBorrow.amount > 0}
            onClick={() =>
              navigate(
                generatePath(NavRoutes.ONELINE_CREATE_LINE, {
                  lineTypeId: lineType.id,
                })
              )
            }
          >
            <InfoListItemsWrapper tight>
              <InfoListItem
                title="Get up to"
                value={decorateCurrencyValue(
                  lineType.maxOfferingAmount.display
                )}
                valueType="number"
                titleColor={ColorNames.MIDNIGHT}
              />
              <InfoListItem
                title="APY"
                value={formatPercentageRange(lineType.apyRange)}
                valueType="number"
                valueColor={ColorNames.GRAY5}
              />
              <InfoListItem
                title="LTV"
                value={formatPercentageRange(lineType.ltvRange)}
                valueType="number"
                valueColor={ColorNames.GRAY5}
              />
            </InfoListItemsWrapper>
          </BorrowCard>
        ))}
      </GenericWrapper>
    </Layout>
  );
};

export default Borrow;

interface BorrowCardProps {
  children: JSX.Element | string;
  title: string;
  description?: string;
  canBorrow?: boolean;
  onClick: () => void;
}

const BorrowCard = ({
  children,
  title,
  description,
  canBorrow,
  onClick,
}: BorrowCardProps) => {
  const navigate = useNavigate();

  return (
    <BorrowCardWrapper>
      <BorrowCardHeader>
        <Badge type={title as LineTypeTitle} />
        <BorrowCardTitle children={title} />
      </BorrowCardHeader>
      {!!description && <BorrowCardDescription children={description} />}
      {children}
      {canBorrow ? (
        <Button raised onClick={onClick} children="Open a New Line" />
      ) : (
        <ActionableButton
          color={ColorNames.BREEZE}
          label="Connect Wealth to Qualify"
          iconRight={<IconConnect />}
          onClick={() => navigate(NavRoutes.BANK_CONNECTED_ACCOUNTS)}
        />
      )}
    </BorrowCardWrapper>
  );
};
