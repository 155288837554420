/* eslint react-hooks/exhaustive-deps: "off" */
import { useEffect } from "react";
import { atom, useRecoilCallback } from "recoil";
import { ThemeColor } from "src/theme/theme";

type NavbarOptions = {
  back?: boolean;
  backAction?: () => void;
  persistedBackAction?: () => void;
  title?: string;
  menu?: boolean;
  hidden?: boolean;
  stepper?: boolean;
  step?: number;
  totalSteps?: number;
  logo?: boolean;
  orgSwitcher?: boolean;
  rightIcon?: JSX.Element;
  rightAction?: () => void;
  transparent?: boolean;
  invertColors?: boolean;
  backgroundColor?: ThemeColor;
};

export const defaultNavbarOptions = {
  menu: false,
  title: "",
  orgSwitcher: false,
  stepper: false
};

export const coreNavbarState = atom<NavbarOptions>({
  key: "coreNavbarState",
  default: defaultNavbarOptions
});

export const useNavbar = (data?: NavbarOptions) => {
  const updateNavbar = useRecoilCallback(
    ({ set, snapshot }) =>
      (lazyData?: NavbarOptions) => {
        const currentState = snapshot.getLoadable(coreNavbarState).contents as NavbarOptions;

        const mergedOptions = {
          ...defaultNavbarOptions,
          persistedBackAction: currentState.persistedBackAction,
          menu: true,
          orgSwitcher: true,
          ...data,
          ...lazyData
        };
        if (
          !data?.persistedBackAction &&
          !lazyData?.persistedBackAction &&
          (!!data?.back || !!data?.backAction || !!lazyData?.back || !!lazyData?.backAction)
        ) {
          delete mergedOptions.persistedBackAction;
        }
        set(coreNavbarState, mergedOptions);
      },
    []
  );

  useEffect(updateNavbar, []);

  return { updateNavbar };
};
