import React from "react";

import {
  StyledBadge,
  StyledBadgeInner,
  HorseIcon,
  LiquidIcon,
  SpendIcon,
  BoostIcon,
  StyledTriangle,
  StyledCircle,
  StyledRectangle,
  StyledLine,
  SvgGradients
} from "src/components/badge/Badge.styles";
import { BackgroundImageSize } from "src/theme/theme";

import { LineTypeTitle } from "src/types/line";
import { DataTestIds } from "src/util/testing-util/test-utils";

export type BadgeType = LineTypeTitle;

export interface BadgeProps {
  type: BadgeType;
}

export const Badge = (props: BadgeProps): JSX.Element => {
  const { type } = props;

  let accountIcon;
  let backgroundShapes;
  switch (type) {
    case LineTypeTitle.Horses:
      accountIcon = <HorseIcon />;
      backgroundShapes = (
        <>
          <StyledTriangle size={BackgroundImageSize.MEDIUM} />
          <StyledTriangle size={BackgroundImageSize.SMALL} />
        </>
      );
      break;
    case LineTypeTitle.AutoVest:
      accountIcon = <LiquidIcon />;
      backgroundShapes = (
        <>
          <StyledCircle size={BackgroundImageSize.MEDIUM} />
          <StyledCircle size={BackgroundImageSize.SMALL} />
        </>
      );
      break;
    case LineTypeTitle.Crypto:
      accountIcon = <SpendIcon />;
      backgroundShapes = (
        <>
          <StyledRectangle size={BackgroundImageSize.MEDIUM} />
          <StyledRectangle size={BackgroundImageSize.SMALL} />
        </>
      );
      break;
    default:
      accountIcon = <BoostIcon />;
      backgroundShapes = (
        <>
          <StyledLine size={BackgroundImageSize.LARGE} />
          <StyledLine size={BackgroundImageSize.MEDIUM} />
          <StyledLine size={BackgroundImageSize.SMALL} />
        </>
      );
      break;
  }

  return (
    <StyledBadge type={type}>
      <SvgGradients />
      <StyledBadgeInner type={type} data-testid={DataTestIds.BADGE}>
        {backgroundShapes}
        {accountIcon}
      </StyledBadgeInner>
    </StyledBadge>
  );
};
