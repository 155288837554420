import React, { useEffect } from "react";
import { useRecoilState } from "recoil";

import { deviceState, docusignSigningCompleted } from "src/store/deviceState";
import useNavigate from "src/util/useNavigate";

export enum PostMessageEventName {
  PushTokenRequest = "get_push_token",
  PushTokenResponse = "push_token",
  PushNotification = "push_notification",
  DocuSignComplete = "docusign_complete"
}

const PostMessageHandler = () => {
  const [device, setDevice] = useRecoilState(deviceState);
  const navigate = useNavigate();

  // eslint-disable-next-line
  const handleMessage = (event: any) => {
    // console.log(event);
    try {
      const message = JSON.parse(event.data);

      console.log(`document got message: `, message);

      switch (message.type) {
        case PostMessageEventName.PushTokenResponse:
          setDevice({ ...device, deviceToken: message.token });
          break;
        case PostMessageEventName.PushNotification:
          if (message.data.url) {
            const url = new URL(message.data.url);
            const redirect = `${url.pathname}${url.search}`; // strip off hostname
            navigate(redirect);
          }
          break;
        case PostMessageEventName.DocuSignComplete:
          docusignSigningCompleted(true);
          break; 
        default:
          console.log(`unknown message type: `, message.type);
      }
    } catch (err) {
      // alert(`error: ` + err.message);
      // console.log(`document message error: `, err);
    }
  };

  useEffect(() => {
    let elem: Window | Document = window;

    if ("ReactNativeWebView" in window && navigator.appVersion.includes("Android")) {
      elem = document;
    }

    elem.addEventListener("message", handleMessage);

    return () => elem.removeEventListener("message", handleMessage);
  }, [handleMessage]);

  useEffect(() => {
    if ("ReactNativeWebView" in window) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: PostMessageEventName.PushTokenRequest
        })
      );
    }
  }, []);

  return <></>;
};

export default PostMessageHandler;
