import React from "react";
import classnames from "classnames";

import { ColorNames } from "src/theme/theme";
import {
  ActionableExtraLabel,
  ActionableFlex,
  ActionableIconLeft,
  ActionableIconRight,
  ActionableLabel,
  StyledActionableButton
} from "./ActionableButton.styles";

import { ReactComponent as ChevronRight } from "src/assets/icons/chevron_right.svg";
import { DataTestIds } from "src/util/testing-util/test-utils";

export interface ActionableButtonProps {
  onClick: () => unknown;
  color?: string;
  label?: string;
  extraLabel?: string | number | boolean;
  disabled?: boolean;
  destructive?: boolean;
  keepBackground?: boolean;
  classname?: string;
  iconLeft?: JSX.Element | boolean;
  iconRight?: JSX.Element | boolean;
  dataCy?: string;
}

export const ActionableButton = ({
  onClick = () => undefined,
  color = ColorNames.GRAY1,
  label = "Button",
  extraLabel = false,
  disabled = false,
  destructive = false,
  keepBackground = false,
  classname = "",
  iconLeft = false,
  iconRight = <ChevronRight />,
  dataCy = ""
}: ActionableButtonProps): JSX.Element => {
  return (
    <StyledActionableButton
      type="button"
      color={color}
      destructive={destructive}
      keepBackground={keepBackground}
      onClick={onClick}
      className={classnames(classname, { destructive, disabled })}
      extraLabel={extraLabel}
      data-testid={DataTestIds.ACTIONABLE_BUTTON}
      data-cy={dataCy}
    >
      <ActionableFlex>
        {iconLeft && <ActionableIconLeft>{iconLeft}</ActionableIconLeft>}
        <ActionableLabel className={classnames({ centered: !iconLeft && !iconRight })}>{label}</ActionableLabel>
        {extraLabel && <ActionableExtraLabel color={color}>{extraLabel}</ActionableExtraLabel>}
        {iconRight && <ActionableIconRight>{iconRight}</ActionableIconRight>}
      </ActionableFlex>
    </StyledActionableButton>
  );
};
