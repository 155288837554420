import React from "react";

import {
  AccountBalanceContainer,
  BalanceTitle,
  BalanceText,
} from "src/components/debit-card/debit-balance/DebitBalance.styles";

import { decorateCurrencyValue } from "src/util/stringUtils";
import { ColorNames } from "src/theme/theme";

export interface DebitBalanceProps {
  title: string;
  amount: string;
  color: ColorNames.MIDNIGHT | ColorNames.WHITE;
}

export const DebitBalance = ({ title, amount, color }: DebitBalanceProps): JSX.Element => {
  const formattedBalance: string = decorateCurrencyValue(amount);
  const [balanceDollars, balanceCents] = formattedBalance.split(".");

  return (
    <AccountBalanceContainer>
      <BalanceTitle color={color}>{title}</BalanceTitle>
      <BalanceText color={color}>
        {balanceDollars}.<span>{balanceCents}</span>
      </BalanceText>
    </AccountBalanceContainer>
  );
};
