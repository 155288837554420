import React, { useState, useMemo } from "react";
import classnames from "classnames";

import { useCurrentOrg } from "src/services/organizations";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import useNavigate from "src/util/useNavigate";
import { NavRoutes } from "src/routes/navRoutes";

import { CardsContainer, EmptyActivateCardsContainer, EmptyIcon, CardsWrapper } from "src/routes/app/bank/cards/Cards.styles";
import { ColorNames } from "src/theme/theme";

import { CardTokenBottomSheet } from "src/components/cards/CardTokenBottomSheet";
import { CardActivateSheet } from "src/components/cards/CardActivateSheet";
import { DebitCard } from "src/components/debit-card/DebitCard";

import { ReactComponent as IconCheck } from "src/assets/icons/check.svg";
import { useNavbar } from "src/util/useNavbar";
import Layout from "src/components/core-layout/Layout";
import {
  Card,
  CardStatusType,
  CardType,
  Organization,
} from "src/generated/client";

const ActivateCards = () => {
  useNavbar({ title: "Activate Cards" });
  const currentOrg: Organization | undefined = useCurrentOrg({ fullFetch: true });
  const [selectedCard, setSelectedCard] = useState<Card>();
  const [activatedCardIds, setActivatedCardIds] = useState<string[]>([]);
  const { showBottomSheet } = useShowBottomSheet();
  const navigate = useNavigate();

  const cards: Card[] = useMemo(() => {
    if (!!currentOrg && !!currentOrg.cards?.length) {
      return currentOrg.cards.filter(
        (card) =>
          card &&
          card.type === CardType.Physical &&
          card.status === CardStatusType.Inactive
      );
    }
    return [];
  }, [currentOrg]);

  const onActivate = (card: Card) => {
    setActivatedCardIds([...activatedCardIds, card.id]);
  };

  return (
    <Layout loading={!currentOrg?.accountHolder} noInnerContentWidth>
      <CardsWrapper>
        <CardsContainer className={classnames({ "more-than-two": cards.length > 2, "more-than-three": cards.length > 3})}>
          {cards.length === 0 && (
            <EmptyActivateCardsContainer>
              <EmptyIcon
                backgroundColor={ColorNames.GRASS}
                color={ColorNames.LIGHT_LIME}
              >
                <IconCheck width="32px" height="32px" />
              </EmptyIcon>
              You have activated all your physical cards.
            </EmptyActivateCardsContainer>
          )}
          {cards.map((card) => (
            <DebitCard
              key={card.id}
              card={card}
              hasBeenActivated={activatedCardIds.includes(card.id)}
              onClick={(): void => {
                if (activatedCardIds.includes(card.id)) {
                  // need to redirect to account somehow
                  navigate(`${NavRoutes.BANK_ACCOUNTS}`);
                } else {
                  setSelectedCard(card);
                  showBottomSheet("card_activate_sheet");
                }
              }}
            />
          ))}
        </CardsContainer>

        <CardTokenBottomSheet
          id="card_activate_sheet"
          title="Activate Card"
          card={selectedCard}
          children={({ token, externalCardId }) => (
            <CardActivateSheet
              card={selectedCard}
              token={token}
              externalCardId={externalCardId}
              onActivate={onActivate}
            />
          )}
        />
      </CardsWrapper>
    </Layout>
  );
};

export default ActivateCards;
