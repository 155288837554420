import React, { useEffect, useMemo } from "react";
import { useSetBottomSheet } from "src/util/useBottomSheet";
import { LoadingContainer } from "src/components/loading-container/LoadingContainer";

import {
  ProvidersContainer,
  ProvidersHeader,
  ProvidersContent
} from "src/components/bottom-sheets/connect-account-sheet/ConnectAccountSheet.styles";

type Props = {
  id: string;
};

export const ConnectedAccountsLoadingSheet = (props: Props) => {
  const setBottomSheet = useSetBottomSheet();

  const children = useMemo(
    () => (
      <ProvidersContainer>
        <ProvidersHeader>
          <span>Connecting to External Account</span>
        </ProvidersHeader>
        <ProvidersContent>
          <LoadingContainer />
        </ProvidersContent>
      </ProvidersContainer>
    ),
    []
  );

  useEffect(() => {
    setBottomSheet({
      id: props.id,
      children,
      fullHeight: true,
      noPadding: true,
      hasHeader: true,
      closeOnOutsideClick: false
    });
  }, [children, props]);

  return <></>;
};
