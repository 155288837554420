import styled from "styled-components";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";
import { SegmentedControlTheme } from "./SegmentedControl";

interface SegmentedControlOptionProps {
  active: boolean;
  theme: SegmentedControlTheme
}

export const StyledSegmentedControl = styled.div<{ condensed?: boolean }>`
  padding: 0 30px 10px;
  display: flex;
  gap: 16px;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ condensed }) => condensed ? "center" : "space-between"};
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 900px) {
    justify-content: center;
  }
`;

export const StyledSegmentedControlOption = styled.div<{ active: boolean, theme: SegmentedControlTheme }>`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  height: 30px;
  padding: 0 10px;
  border: 1px solid ${({ active, theme }: SegmentedControlOptionProps): string => (active ? colors[theme.background].hex : colors[ColorNames.GRAY2].hex)};
  border-radius: 4px;
  background: ${({ active, theme }: SegmentedControlOptionProps): string => (active ? colors[theme.background].hex : "transparent")};
  cursor: pointer;

  .label {
    ${fonts.PARAGRAPH_S}
    line-height: 28px;
    color: ${({ active, theme }: SegmentedControlOptionProps): string => (active ? colors[theme.primaryText].hex : colors[ColorNames.GRAY5].hex)};
  }

  .count {
    ${fonts.NUMBER_S}
    line-height: 28px;
    margin-left: 10px;
    color: ${({ active, theme }: SegmentedControlOptionProps): string => (active ? colors[theme.secondaryText].hex : colors[ColorNames.GRAY3].hex)};
  }
`;
