import styled, { css } from "styled-components/macro";
import { Form } from "formik";

import { colorPalette as colors, ColorNames, fonts, borderRadiusOuter } from "src/theme/theme";

export interface StyledFieldContainerProps {
  hidden?: boolean;
  error?: boolean;
  currency?: string;
  onClick?: () => void;
  type?: string;
}

export const fieldStyle = css`
  border: none;
  width: 100%;
  ${fonts.PARAGRAPH_M}
  display: flex;
  align-items: center;
  color: ${({ theme }): string => theme.colors.dark};
  height: 20px;
  margin-top: 5px;
  box-shadow: none;
  -webkit-appearance: none;
  background-color: transparent;
  transition: z-index 0.2s ease-out, border 0.2s ease-out, background-color 0.2s ease-out;
  will-change: border, background-color;

  &[inputmode="numeric"] {
    ${fonts.NUMBER_M}
  }

  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.6;
  }
`;

export const StyledFormInputContainer = styled.fieldset`
  width: 100%;
  border: none;
  padding: 0;
  margin: 0;
`;

export const StyledFieldContainer = styled.div<StyledFieldContainerProps>`
  position: relative;
  border-width: 1.5px;
  border-style: solid;
  border-color: ${({ error }): string => (error ? colors[ColorNames.CARDINAL].hex : colors[ColorNames.GRAY3].hex)};
  border-radius: 4px;
  padding: 8px 12px;
  min-height: 60px;
  width: 100%;
  margin-top: -1px;
  background-color: transparent;
  will-change: border-color;
  transition: border-color 0.2s ease, background-color 0.25s ease;
  z-index: ${({ error }): number => (error ? 1 : 0)};
  ${(props) =>
    props.type === "file" &&
    css`
      display: flex;
      justify-content: space-between;
    `}
  ${(props) =>
    props.type === "toggle" &&
    css`
      display: flex;
      align-items: center;
    `}

  ${(props) =>
    !!props.hidden &&
    css`
      display: none;
    `}

  &:after {
    content: "${({ currency }): string => currency || ""}";
    ${fonts.NUMBER_M}
    position: absolute;
    bottom: 8px;
    left: 12px;
    height: 20px;
    pointer-events: none;
  }

  &:focus-within {
    border-color: ${({ error }): string =>
      error ? colors[ColorNames.CARDINAL].hex : `${colors[ColorNames.LAKE].hex}`};
    background-color: ${colors[ColorNames.WHITE].hex};
    z-index: 2;
  }

  ${StyledFormInputContainer} &, fieldset & {
    &:not(:first-of-type):not(:last-of-type) {
      border-radius: 0;
    }
    &:first-of-type {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-of-type {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    &:first-of-type:last-of-type {
      border-radius: 4px;
    }
  }

  label {
    display: block;
  }
`;

export const StyledFormCollapsableGroup = styled(StyledFormInputContainer)`
  background: ${colors[ColorNames.GRAY1].hex};
  border-radius: ${borderRadiusOuter};
  padding: 15px;

  .content {
    visibility: none;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease;

    &.open {
      visibility: visible;
      max-height: 140px;
      padding-top: 15px;
    }
  }
`;

export const StyledForm = styled(Form)`
  flex: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const InputRow = styled.div`
  display: flex;

  ${StyledFieldContainer} {
    flex: 1;
  }

  &:not(:first-child):not(:last-child) {
    ${StyledFieldContainer} {
      &:not(:focus-within) {
        border-radius: 0;
        border-bottom-color: transparent;
      }
    }
  }

  &:first-child:not(:last-child) {
    ${StyledFieldContainer} {
      &:not(:focus-within) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-color: transparent;
      }
    }
  }

  &:last-child:not(:first-child) {
    ${StyledFieldContainer} {
      &:not(:focus-within) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  ${StyledFieldContainer} {
    &:not(:last-child):not(:focus-within) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-color: transparent;
    }
  }
`;

export const FormSectionHeader = styled.div`
  ${fonts.SECTION_M}
  color: ${colors[ColorNames.MIDNIGHT].hex};
  background-color: ${colors[ColorNames.GRAY3].hex};
  border-radius: 4px 4px 0 0;
  padding: 10px 15px;
`;

export const FormSectionHeaderTopMargin = styled(FormSectionHeader)`
  margin-top: 50px;
`;

export const FormSpacer = styled.div`
  height: 18px;
`;
