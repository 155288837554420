import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import {
  ThemeColor,
  colorPalette as colors,
  ColorNames,
  fonts,
  borderRadius,
  borderRadiusOuter,
  dropShadows,
  ShadowNames
} from "src/theme/theme";
import { TIMING_EASE_OUT_CUBIC } from "src/theme/globalStyles";

interface NoticeContainerProps {
  error?: boolean;
  show: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: () => void;
}

export const NoticeContainer = styled.dialog<NoticeContainerProps>`
  visibility: hidden;
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  background-color: ${({ error }): string =>
    error ? `rgba(${colors[ColorNames.CARDINAL].rgb}, 0.95)` : `rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0.95)`};
  backdrop-filter: blur(5px);
  width: 100vw;
  min-height: 100px;
  border: none;
  border-radius: 0px;
  text-align: center;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translate3d(0, -100%, 0);
  transition: transform 0.2s ease-out, visibility 0.2s ease-out;
  z-index: var(--zIndex-alert);
  will-change: transform;

  ${({ show }): FlattenSimpleInterpolation | undefined => {
    if (show) {
      return css`
        visibility: visible;
        transform: translate3d(0, 0, 0);

        @media (min-width: 900px) {
          transform: translate3d(0, 0, 0);
        }
      `;
    }
  }}
`;

export const NoticeText = styled.p`
  color: ${colors[ColorNames.WHITE].hex};
  ${fonts.PARAGRAPH_M}
  padding: 30px;
  @media (max-width: 350px) {
    font-size: 14px;
  }
`;

interface BannerNoticeContainerProps {
  translucent?: boolean;
  backgroundColor?: ThemeColor;
  textColor?: ThemeColor;
  titleColor?: ThemeColor;
  iconColor?: ThemeColor;
  hasAction?: boolean;
}

export const BannerNoticeContainer = styled.div<BannerNoticeContainerProps>`
  width: 100%;
  padding: 15px;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: ${({ hasAction }): string => (hasAction ? "20px 1fr 20px" : "20px 1fr")};
  grid-gap: 15px;
  align-items: center;
  ${fonts.PARAGRAPH_S}
  border-radius: ${borderRadius};
  background-color: ${({ translucent, backgroundColor }): string => translucent ? `rgba(${colors[ColorNames.WHITE].rgb}, 0.1)` : colors[backgroundColor || ColorNames.NOTE].hex};
  color: ${({ textColor }): string => colors[textColor || ColorNames.PINE].hex};
  cursor: ${({ onClick }): string => (onClick ? "pointer" : "default")};

  svg {
    color: ${({ iconColor }): string => colors[iconColor || ColorNames.TEA].hex};
  }

  .title {
    ${fonts.PARAGRAPH_M}
    color: ${({ titleColor }): string => (titleColor ? colors[titleColor].hex : "inherit")};
  }

  .body {
    margin-top: 3px;
  }
`;

export const AlertIcon = styled.div`
  position: absolute;
  top: -16px;
  right: -16px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors[ColorNames.WHITE].hex};
  cursor: pointer;
  transition: top 0.3s ${TIMING_EASE_OUT_CUBIC}, right 0.3s ${TIMING_EASE_OUT_CUBIC};

  &:before {
    content: "";
    position: absolute;
    top: 9px;
    left: 9px;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background-color: ${colors[ColorNames.MIDNIGHT].hex};
    box-shadow: ${dropShadows[ShadowNames.AVATAR]};
    transition: background-color 0.2s ease-out;
    z-index: -1;
  }

  @media (max-width: 768px) {
    top: -13px;
  }
`;

export const AlertContainer = styled.div<{ open: boolean }>`
  position: fixed;
  top: calc((var(--extraDeviceTopPadding) + var(--navbarHeight) - 44px) / 2);
  right: 10px;
  z-index: var(--zIndex-verifiedAlert);
  width: 65vw;
  max-width: 320px;
  pointer-events: auto;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    background: ${colors[ColorNames.WHITE].hex};
    box-shadow: ${dropShadows[ShadowNames.DARK]};
    border-radius: ${borderRadiusOuter};
    z-index: -1;
    transition: max-height 0.25s ${TIMING_EASE_OUT_CUBIC}, width 0.25s ${TIMING_EASE_OUT_CUBIC};
  }

  .content {
    padding: 20px 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    transition: opacity 0.2s ease-out;
    transition-delay: 0.2s;
    pointer-events: auto;
  }

  .icon {
    color: ${colors[ColorNames.CARDINAL].hex};
  }

  .description {
    padding: 10px 10px 20px;
    ${fonts.PARAGRAPH_S}
    text-align: center;
  }

  ${({ open }): FlattenSimpleInterpolation | undefined => {
    if (!open) {
      return css`
        pointer-events: none;

        &:before {
          width: 44px;
          max-height: 44px;
        }

        .content {
          transition-delay: 0s;
          opacity: 0;
          pointer-events: none;
        }

        ${AlertIcon} {
          top: 0;
          right: 0;
          pointer-events: auto;

          &:before {
            background-color: ${colors[ColorNames.CARDINAL].hex};
          }
        }
      `;
    }
  }}
`;
