import { useState, Dispatch, SetStateAction } from "react";

interface CopyToClipboardReturn {
  copied: boolean;
  setCopied: Dispatch<SetStateAction<boolean>>;
  handleCopyValue: (value: string) => void;
}

const useCopyToClipboard = (): CopyToClipboardReturn => {
  const [copied, setCopied] = useState(false);

  const handleCopyValue = (value: string): void => {
    if (!!navigator.clipboard) {
      navigator.clipboard.writeText(value);
      setCopied(true);
    }
  };

  return { copied, setCopied, handleCopyValue };
};

export default useCopyToClipboard;
