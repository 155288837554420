import React from "react";
import { AnimatedRoot } from "src/LetterRoot";
import ErrorElement from "src/ErrorElement";

export const BankErrorElement = () => {
  return <ErrorElement />;
};

const BankRoot = (): JSX.Element => {
  return <AnimatedRoot />;
};

export default BankRoot;
