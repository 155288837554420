import styled from "styled-components";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px -20px;
  min-height: 76px;
`;

export const Sign = styled.div`
  font-size: 30px;
  color: ${colors[ColorNames.GRAY4].hex};
`;

export const CustomInput = styled.input`
  ${fonts.NUMBER_L}
  font-size: 52px;
  -webkit-appearance: none;
  outline: none;
  background: transparent;
  box-shadow: none;
  border: none;
  caret-color: ${colors[ColorNames.LAKE].hex};
  padding-left: 4px;

  &[data-inactive="true"],
  ::placeholder {
    color: ${colors[ColorNames.GRAY4].hex};
    pointer-events: none;
  }

  &:not(:focus) {
    pointer-events: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    display: none;
  }
`;
