import styled, { createGlobalStyle } from "styled-components";
import { motion } from "framer-motion";
import { colorPalette as colors, ColorNames, fonts, bezierMenu, bezierMenuExpo, ThemeColor } from "src/theme/theme";
import { StyledMenuContent } from "./MenuContent.styles";
import { CoreLayoutContent, LayoutWrapper } from "../core-layout/CoreLayout.styles";
import { NavbarBox } from "../nav-bars/Navbar.styles";
import { SubnavWrapper } from "../nav-bars/Subnav.styles";

export const Overlay = styled(motion.div)`
  width: calc(100% - 300px);
  height: 100vh;
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
  position: fixed;
  top: 0;
  right: 0;
  z-index: var(--zIndex-sideMenuOverlay);
  cursor: w-resize;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const AppMenuSwipeHandlerContainer = styled(motion.div)`
  width: 20px;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: var(--zIndex-sideMenuOverlay);
  user-select: none;
`;

export const AppMenuContainer = styled.div`
  max-width: 300px;
  height: 100vh;
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 84px 1fr;
  user-select: none;
  z-index: 1;
  color: ${colors[ColorNames.MIDNIGHT].hex};

  @media (min-width: 768px) {
    z-index: var(--zIndex-sideMenuContainer);
    left: auto;
  }
`;

export const AppMenuTabBar = styled.div<any>`
  background-color: ${colors[ColorNames.WHITE].hex};
  padding: 46px 0 20px 0;
  padding-top: var(--extraDeviceTopPaddingSidebar);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  position: relative;
  z-index: 999;
  transition: transform 0.3s 0.13s ${bezierMenu};
  @supports (-webkit-touch-callout: none) {
    transition: transform 0.3s 0.13s ${bezierMenuExpo};
  }
  transform: translateX(-100%);

  @media (min-width: 768px) {
    transform: translateX(0);
  }

  &.show {
    transform: translateX(0);
    transition: transform 0.3s ${bezierMenu};
    @supports (-webkit-touch-callout: none) {
      transition: transform 0.3s ${bezierMenuExpo};
    }
  }
`;

export const AppMenuLinks = styled(motion.div)`
  position: fixed;
  background-color: ${colors[ColorNames.GRAY2].hex};
  padding: 0;
  top: 0;
  bottom: 0;
  left: 84px;
  width: 216px;
  height: 100vh;
  overflow-y: auto;

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }

  transform: translateX(calc(-100% - 84px));
  transition: transform 0.3s ${bezierMenu};

  @supports (-webkit-touch-callout: none) {
    transition: transform 0.3s ${bezierMenuExpo};
  }

  @media (min-width: 768px) {
    transform: translateX(0);
    position: relative;
    left: 0;
  }

  &.show {
    transform: translateX(0);
  }
`;

export const AppMenuLinksInner = styled.div`
  margin-top: calc(var(--extraDeviceTopPaddingSidebar) - 6px);
  height: calc(100vh - (var(--extraDeviceTopPaddingSidebar) - 6px));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  position: relative;
`;

export const AppMenuLinksTop = styled.div`
  padding: 0 20px;
  width: 100%;
`;

export const AppMenuLinksBottom = styled.div`
  width: 100%;
`;

export const AppMenuBottomBanner = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 16px;
  padding: 10px 15px;
  cursor: pointer;
  text-align: center;
  background-color: ${colors[ColorNames.GRAY3].hex};
  color: ${colors[ColorNames.PINE].hex};
  border-radius: 4px;
  ${fonts.PARAGRAPH_XS}
`;

export const AppMenuOrgSwitcher = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  padding: 30px 20px;
  cursor: pointer;
  background-color: rgba(${colors[ColorNames.WHITE].rgb}, 0.5);
  color: rgba(${colors[ColorNames.MIDNIGHT].rgb}, 1);
  ${fonts.PARAGRAPH_S}
  transition: color 0.2s ease-out;
  will-change: color;

  &:active {
    color: rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0.5);
  }

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const MenuLinkGroupLabel = styled.div`
  ${fonts.HEADING_5}
  display: block;
  width: 100%;
  text-align: left;
  padding: 8px 12px 10px;
  margin-bottom: 1rem;
`;

export const MenuLink = styled.button<{ color?: ThemeColor; }>`
  -webkit-appearance: none;
  border: none;
  outline: none;
  display: block;
  width: 100%;
  text-align: left;
  ${fonts.PARAGRAPH_M}
  background-color: transparent;
  border-radius: 4px;
  padding: 10px 12px;
  margin-bottom: 1em;
  transition: 0.15s ease all;
  color: ${colors[ColorNames.MIDNIGHT].hex};

  @media (pointer: fine) {
    &:hover,
    &:focus {
      background-color: ${colors[ColorNames.GRAY3].hex};
      cursor: pointer;
    }
  }

  &:active {
    transform: scale(0.98);
  }

  &.active {
    color: ${colors[ColorNames.WHITE].hex};
    background-color: ${({ color }): string => !!color ? colors[color].hex : colors[ColorNames.PINE].hex};
  }
`;

export const MenuTabs = styled.div`
  display: grid;
  gap: 1.5rem;
  place-items: center;
`;

export const MenuTabButton = styled.div<{ color?: ThemeColor; }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 48px;
  height: 48px;
  transition: 0.15s ease all;

  @media (pointer: fine) {
    &:hover,
    &:focus {
      background-color: ${colors[ColorNames.GRAY1].hex};
      cursor: pointer;
    }
  }

  &:active {
    transform: scale(0.96);
  }

  &.active {
    color: ${({ color }): string => !!color ? colors[color].hex : colors[ColorNames.PINE].hex};
    background-color: ${colors[ColorNames.GRAY2].hex};
  }
`;

export const MenuUserActions = styled.div`
  display: grid;
  gap: 1.5rem;
  place-items: center;
`;

export const DisableAppMenu = createGlobalStyle`
${AppMenuContainer}, ${AppMenuSwipeHandlerContainer} {
  display: none;
}

${LayoutWrapper} {
  & + & {
    position: fixed;
  }
}

${StyledMenuContent}, ${CoreLayoutContent} {
  grid-column: 1 / -1;
}

${NavbarBox}, ${SubnavWrapper} {
  padding-left: 0 !important;
}
`;
