import styled, { css, keyframes, FlattenSimpleInterpolation } from "styled-components/macro";
import { motion } from "framer-motion";
import { ReactComponent as Gradients } from "src/assets/gradients.svg";
import { ReactComponent as Circle } from "src/assets/circle.svg";
import { ReactComponent as Triangle } from "src/assets/triangle.svg";
import { ReactComponent as Line } from "src/assets/line.svg";
import { Container } from "src/components/avatar/Avatar.styles";
import { colorPalette as colors, ColorNames, fonts, borderRadiusOuter, BackgroundImageSize } from "src/theme/theme";
import { TimeRangePickerContainer } from "../timerange-picker/TimeRangePicker.styles";
import { BannerNoticeContainer } from "src/components/alert/Alerts.styles";
import { FeatureCardType } from "./FeatureCard";

export const FeatureCardWrapper = styled(motion.div)``;

export const FeatureCardContainer = styled.div<{ type: FeatureCardType }>`
  position: relative;
  margin: 0 20px 20px;
  border-radius: ${borderRadiusOuter};
  padding: ${({ type }): string => type === FeatureCardType.BANK ? "40px 20px 20px" : "20px"};
  box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.15), inset 0px 2px 1px rgba(255, 255, 255, 0.2);
  user-select: none;
  touch-action: none;

  background: ${({ type }): string => {
    switch (type) {
      case FeatureCardType.BANK:
        return "linear-gradient(326.08deg, #069a6e 0%, #0ecc93 38.68%, #0dc6bb 89.47%);";
      case FeatureCardType.LINE:
        return `linear-gradient(to bottom, rgb(${colors[ColorNames.OCEAN].rgb}) 9%, #047194 120%)`;
    }
  }};

  ${BannerNoticeContainer} {
    border-radius: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
    backdrop-filter: blur(4px);
  }

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 32px;
    left: 10px;
    right: 10px;
    bottom: 6px;
    border-radius: ${borderRadiusOuter};

    box-shadow: ${({ type }): string => {
      switch (type) {
        case FeatureCardType.BANK:
          return "0px 42px 105px rgba(24, 111, 100, 0.185807), 0px 19.8593px 44.1181px rgba(24, 111, 100, 0.237332), 0px 13.7561px 31.3541px rgba(24, 111, 100, 0.258287), 0px 10.0261px 22.9981px rgba(24, 111, 100, 0.278676), 0px 6.73572px 15.575px rgba(24, 111, 100, 0.330257), 0px 3.4383px 8.04275px rgba(24, 111, 100, 0.58);";
        case FeatureCardType.LINE:
          return `0px 42px 105px rgba(${colors[ColorNames.DEEP_WATER].rgb}, 0.185807), 0px 19.8593px 44.1181px rgba(${colors[ColorNames.DEEP_WATER].rgb}, 0.237332), 0px 13.7561px 31.3541px rgba(${colors[ColorNames.DEEP_WATER].rgb}, 0.258287), 0px 10.0261px 22.9981px rgba(${colors[ColorNames.DEEP_WATER].rgb}, 0.278676), 0px 6.73572px 15.575px rgba(${colors[ColorNames.DEEP_WATER].rgb}, 0.330257), 0px 3.4383px 8.04275px rgba(${colors[ColorNames.DEEP_WATER].rgb}, 0.58);`;
      }
    }};
  }
`;

export const FeatureCardShapes = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: ${borderRadiusOuter};
  overflow: hidden;
  pointer-events: none;
`;

export const FeatureCardGraphContainer = styled.div`
  height: 140px;
  margin: 0 -20px;

  svg {
    fill: transparent;
  }
`;

export const FeatureCardTitles = styled.div`
  margin-bottom: 20px;
`;

export const FeatureCardName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colors[ColorNames.PINE].hex};
  ${fonts.PARAGRAPH_L}
`;

export const FeatureCardSubtitle = styled.div`
  color: ${colors[ColorNames.TEA].hex};
  ${fonts.PARAGRAPH_S}
  margin-top: 3px;
`;

export const FeatureCardBalance = styled.div`
  ${fonts.NUMBER_XXL}
  line-height: 1em;
  color: white;

  .cents-sup {
    ${fonts.NUMBER_XL}
    vertical-align: baseline;
    position: relative;
    top: -9px;
  }

  .label {
    ${fonts.HEADING_5}
    margin-top: 10px;
  }
`;

export const FeatureCardDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;

  .timerange-label {
    color: ${colors[ColorNames.WHITE].hex};
  }

  ${TimeRangePickerContainer} svg {
    color: ${colors[ColorNames.WHITE].hex};
  }
`;

export const FeatureCardTypeIcon = styled.div`
  margin-right: 10px;
  height: 20px;
  width: 20px;

  svg {
    color: ${colors[ColorNames.GRASS].hex};
  }
`;

export const FeatureCardNumber = styled.div`
  ${fonts.NUMBER_M}
  color: ${colors[ColorNames.GRASS].hex};
  display: flex;
  align-items: center;
  position: relative;

  ${Container} {
    margin-right: 10px;
  }
`;

export const FeatureCardFunds = styled.div`
  ${fonts.NUMBER_M}
  color: ${colors[ColorNames.PINE].hex};
  text-align: right;
`;

export const FeatureCardAvailable = styled.div`
  color: ${colors[ColorNames.WHITE].hex};
  ${fonts.NUMBER_M}

  .label {
    ${fonts.PARAGRAPH_XS}
  }
`;

export const FeatureCardTimeRange = styled.div``;

export const circle = keyframes`
  from {
		transform: rotate(0deg) translate(-30px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translate(-30px)  rotate(-360deg);
	}
`;

export const circleBack = keyframes`
  from {
		transform: rotate(0deg) translate(-20px) rotate(0deg);
	}
	to {
		transform: rotate(-360deg) translate(-20px)  rotate(360deg);
	}
`;

export const floatUp = keyframes`
  from {
    transform: translateY(103%);
  }
  to { 
    transform: translateY(-103%);
  }
`;

export const floatUpRight = keyframes`
  from {
    transform: translateX(0px) translateY(0px);
  }
  to { 
    transform: translateX(350px) translateY(-350px);
  }
`;

export const SvgGradients = styled(Gradients)`
  width: 0;
  height: 0;
  position: absolute;
`;

export const StyledCircle = styled(Circle)<{ size: BackgroundImageSize }>`
  position: absolute;

  ${({ size }): FlattenSimpleInterpolation | undefined => {
    switch (size) {
      case "large":
        return css`
          top: 20%;
          right: -80px;
          width: 127px;
          height: 127px;
          animation: ${circle} 14s linear infinite;
          circle {
            fill: url(#white_transparent_170deg);
          }
        `;
      case "medium":
        return css`
          bottom: 5%;
          left: -20px;
          width: 78px;
          height: 78px;
          animation: ${circle} 16s linear infinite;
          circle {
            fill: url(#white_transparent_140deg);
          }
        `;
      case "small":
        return css`
          top: 25%;
          left: 80px;
          width: 22px;
          height: 22px;
          animation: ${circleBack} 18s linear infinite;
          circle {
            fill: url(#white_transparent_150deg);
          }
        `;
    }
  }}
`;

export const StyledTriangleWrapper = styled.div<{ size: BackgroundImageSize }>`
  position: absolute;

  ${({ size }): FlattenSimpleInterpolation | undefined => {
    switch (size) {
      case BackgroundImageSize.LARGE:
        return css`
          right: -5%;
          top: 0%;
          bottom: 0%;
          animation: ${floatUp} 25s linear -4s infinite;
        `;
      case BackgroundImageSize.MEDIUM:
        return css`
          left: 0%;
          top: 0%;
          bottom: 0%;
          animation: ${floatUp} 22s linear -1s infinite;
        `;
      case BackgroundImageSize.SMALL:
        return css`
          left: 60%;
          top: 0%;
          bottom: 0%;
          animation: ${floatUp} 18s linear -1s infinite;
        `;
    }
  }}
`;

export const StyledTriangle = styled(Triangle)<{ size: BackgroundImageSize }>`
  ${({ size }): FlattenSimpleInterpolation | undefined => {
    switch (size) {
      case BackgroundImageSize.LARGE:
        return css`
          width: 108px;
          height: 87px;
          path {
            fill: url(#white_transparent_170deg);
          }
        `;
      case BackgroundImageSize.MEDIUM:
        return css`
          width: 75px;
          height: 70px;
          path {
            fill: url(#white_transparent_150deg);
          }
        `;
      case BackgroundImageSize.SMALL:
        return css`
          width: 50px;
          height: 47px;
          path {
            fill: url(#white_transparent_140deg);
          }
        `;
    }
  }}
`;

export const StyledLine = styled(Line)<{ size: BackgroundImageSize }>`
  position: absolute;

  ${({ size }): FlattenSimpleInterpolation | undefined => {
    switch (size) {
      case BackgroundImageSize.LARGE:
        return css`
          top: 110%;
          left: -160px;
          width: 160px;
          height: 160px;
          animation: ${floatUpRight} 10s linear infinite;
          rect {
            fill: url(#white_transparent_100deg);
          }
        `;
      case BackgroundImageSize.MEDIUM:
        return css`
          top: 50%;
          left: -100px;
          width: 100px;
          height: 100px;
          animation: ${floatUpRight} 14s linear 3s infinite;
          rect {
            fill: url(#white_transparent_10deg);
          }
        `;
      case BackgroundImageSize.SMALL:
        return css`
          top: 120%;
          left: -20px;
          width: 70px;
          height: 70px;
          animation: ${floatUpRight} 18s linear 2s infinite;
          rect {
            fill: url(#white_transparent_170deg);
          }
        `;
    }
  }}
`;
