import styled from "styled-components";
import { motion } from "framer-motion";

import { ReactComponent as MasterCardLogo } from "src/assets/mastercard.svg";

import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

export const DebitCardContainer = styled(motion.div)`
  cursor: pointer;
  position: relative;
  width: 217px;
  height: 350px;
  border-radius: 10px;
`;

export const DebitCardDropShadow = styled.div`
  position: absolute;
  left: 4.25%;
  right: 4.25%;
  top: 8%;
  bottom: 1.6%;
  height: 318px;
  width: 199px;
  display: inline-block;
  background: transparent;
  box-shadow: 0px 2.10311px 2.21381px rgba(163, 179, 174, 0.0562291),
    0px 5.05408px 5.32008px rgba(163, 178, 174, 0.0807786), 0px 9.51638px 10.0172px rgba(163, 178, 174, 0.1),
    0px 16.9756px 17.869px rgba(163, 178, 174, 0.119221), 0px 31.751px 33.4221px rgba(163, 178, 174, 0.143771),
    0px 76px 80px rgba(163, 178, 174, 0.2);
  border-radius: 10px;
`;

export const StyledDebitCard = styled.div<{ background: string }>`
  position: relative;
  width: 220px;
  height: 350px;
  border-radius: 10px;
  padding: 20px;
  background-image: ${({ background }): string => `url(${background})`};
  background-repeat: none;
  background-size: cover;
  box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const CardNumberContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 18px;
`;

export const CardLogo = styled(MasterCardLogo)`
  width: 36px;
  height: 22px;
`;

export const CardNumber = styled.p<{
  color: ColorNames.MIDNIGHT | ColorNames.WHITE;
}>`
  ${fonts.NUMBER_XL}
  color: ${({ color }): string => colors[color].hex};
`;

export const CardNameHolder = styled.div<{
  color: ColorNames.MIDNIGHT | ColorNames.WHITE | ColorNames.GRAY4;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${({ color }): string => colors[color].hex};

  svg {
    flex: 0 0 auto;
    margin-left: 10px;
  }
`;

export const CardName = styled.p`
  ${fonts.PARAGRAPH_XL}
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CardStatusHolder = styled.div<{ hasBeenActivated?: boolean }>`
  align-self: flex-start;
  ${fonts.TAG}
  line-height: 18px;
  height: 17px;
  padding: 0 10px;
  margin-top: 20px;
  background: ${({ hasBeenActivated }): string =>
    hasBeenActivated ? colors[ColorNames.SEAFOAM].hex : "rgba(0, 0, 0, 0.25)"};
  backdrop-filter: blur(5px);
  border-radius: 9px;
  color: white;
  text-transform: uppercase;
`;

export const BalanceInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: 20px;
  }
`;
