import React, { useEffect, useState, useRef } from "react";
import classnames from "classnames";
import { useFieldSafe } from "src/util/useFieldSafe";
import { showNotice } from "src/store/alertState";

import { StyledMultiInput, StyledMultiInputSeparator, StyledInput } from "src/components/forms/input/Input.styles";
import { StyledFieldContainer } from "src/components/forms";
import { StyledLabel } from "src/components/text";
import { DataTestIds } from "src/util/testing-util/test-utils";

export interface MultiInputField {
  charsCount: number;
  type: string;
  placeholder: string;
}

export interface MultiInputProps {
  label?: string;
  name: string;
  inputs: MultiInputField[];
  formatTo: string;
  separator: string;
  disabled?: boolean;
  style?: object;
  doNotCapture?: boolean;
  [key: string]: unknown;
}

interface HandleOnChangeProps {
  event: React.ChangeEvent<HTMLInputElement>;
  input: MultiInputField;
  index: number;
}

interface HandleOnKeyProps {
  event: React.KeyboardEvent<HTMLInputElement>;
  input: MultiInputField;
}

const getSiblingInput = ({
  element,
  next = true
}: {
  element: HTMLElement;
  next?: boolean;
}): HTMLInputElement | null => {
  let currentElement: Element | null | undefined = next ? element.nextElementSibling : element.previousElementSibling;
  if (!currentElement) return null;

  do {
    currentElement = next ? currentElement?.nextElementSibling : currentElement?.previousElementSibling;
  } while (currentElement?.nodeName !== "INPUT");

  return currentElement as HTMLInputElement;
};

export const MultiInput = ({
  label,
  name,
  inputs,
  formatTo,
  separator,
  disabled,
  ...props
}: MultiInputProps): JSX.Element => {
  const inputsRef = useRef<Array<HTMLInputElement | null>>([]);
  const [rawValue, setRawValue] = useState<string>();
  const [inputValues, setInputValues] = useState<string[]>([]);
  const { value, setValue, error } = useFieldSafe<string>(name);
  

  useEffect(() => {
    if (error) {
      showNotice(error as string, { error: true });
    }
  }, [error]);

  useEffect(() => {
    if (!!rawValue) return;
    setRawValue(value);
  }, [value, rawValue]);

  useEffect(() => {
    const indexes = formatTo.match(/(\d+)/g);
    const matches = rawValue?.match(/([a-zA-Z0-9]+)/g);

    const values: Array<string> = Array(indexes?.length);
    indexes?.forEach((value, idx) => (values[parseInt(value)] = !!matches ? matches[idx] : ""));

    setInputValues(values);
  }, [rawValue, formatTo]);

  useEffect(() => {
    if (inputValues.length === 0) return;

    let newValue = formatTo;
    const indexes = formatTo.match(/\$(\d+)/g);
    indexes?.forEach((value) => (newValue = newValue.replace(value, inputValues[parseInt(value.replace("$", ""))])));

    setValue(newValue);
  }, [inputValues, formatTo, setValue]);

  const focusOnNextInput = () => {
    if (!inputsRef.current) return;

    inputsRef.current.some((input, idx) => {
      input?.focus();
      return (input?.value.length || 0) < inputs[idx].charsCount;
    });
  };

  const handleOnChange = ({ event, input, index }: HandleOnChangeProps): void => {
    const newValue = input.type === "number" ? event.target.value.replace(/[^\d]/g, "") : event.target.value;
    const newValues = [...inputValues];
    newValues[index] = newValue;

    setInputValues(newValues);

    if (newValue.length === input.charsCount) {
      const nextInput = getSiblingInput({ element: event?.target });
      if (!!nextInput) nextInput.focus();
    }
  };

  const handleKeyUp = ({ event, input }: HandleOnKeyProps): void => {
    const inputElement = event?.target as HTMLInputElement;

    if ((event.keyCode === 8 || event.key === "Backspace") && inputElement.value.length === 0) {
      const prevInput = getSiblingInput({ element: inputElement, next: false });
      if (!!prevInput) prevInput.focus();
    } else if (inputElement.value.length === input.charsCount) {
      const nextInput = getSiblingInput({ element: inputElement });
      if (!!nextInput) {
        nextInput.focus();
        if (!["Tab", "Backspace", "Meta"].includes(event.key)) nextInput.value = event.key;
      }
    }
  };

  const handlePaste = (
    event: React.ClipboardEvent<HTMLInputElement>
  ): void => {
    event.preventDefault();
    const pastedString = event.clipboardData.getData("Text");
    if (!!pastedString) {
      // if there are any number types, strip out non-alphanumeric
      const paste = (!!inputs.find(i => i.type === "number")) ? pastedString.replace(/[^a-zA-Z0-9]/g, "") : pastedString;

      let lastNumber = 0;
      const values: Array<string> = [];
      inputs.forEach(value => {
        const startNumber = lastNumber;
        lastNumber = value.charsCount + startNumber;
        const chunk = paste.substring(startNumber, lastNumber);
        values.push(chunk);
      });

      setInputValues(values);
    }
  };

  return (
    <StyledFieldContainer
      onClick={focusOnNextInput}
      error={!!error}
      data-testid={DataTestIds.FIELD_CONTAINER}
      type="text"
      style={props.style}
    >
      <StyledLabel htmlFor={name} error={!!error} data-testid={DataTestIds.LABEL}>
        {label}
      </StyledLabel>

      <StyledMultiInput>
        {!!inputs && inputValues.length > 0 && (
          <>
            {inputs.map((input, idx) => (
              <React.Fragment key={idx}>
                <StyledInput
                  id={idx === 0 ? name : undefined}
                  ref={(el: HTMLInputElement) => (inputsRef.current[idx] = el)}
                  className={classnames({ "do-not-capture": props.doNotCapture, placeholderShrink: input.placeholder.replace(/[^\d]/g, "") === "" })}
                  value={inputValues[idx]}
                  name={`multiinput-${idx}`}
                  data-testid={DataTestIds.INPUT}
                  inputMode={input.type === "number" ? "numeric" : "text"}
                  style={{ width: `${input.charsCount}.1ch` }}
                  maxLength={input.charsCount}
                  placeholder={input.placeholder}
                  disabled={disabled}
                  onClick={(event: React.MouseEvent) => event.stopPropagation()}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                    handleOnChange({ event, input, index: idx });
                  }}
                  onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>): void => {
                    handleKeyUp({ event, input });
                  }}
                  onPaste={(event: any): void => handlePaste(event)}
                />
                {idx < inputs.length - 1 && <StyledMultiInputSeparator children={separator} />}
              </React.Fragment>
            ))}
          </>
        )}
      </StyledMultiInput>
    </StyledFieldContainer>
  );
};
