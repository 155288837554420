import { gql } from "@apollo/client";

export const ADD_EMAIL_TO_WAITLIST = gql`
  mutation AddEmailToWaitlist($email: String!) {
    addEmailToWaitlist(input: { email: $email }) {
      success
    }
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!, $inviteCode: String, $recaptchaScore: Float) {
    login(input: { email: $email, password: $password, inviteCode: $inviteCode, recaptchaScore: $recaptchaScore }) {
      session
      mfaEnabled
      organizationId
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout {
      success
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(input: { oldPassword: $oldPassword, newPassword: $newPassword }) {
      success
    }
  }
`;

export const GENERATE_MULTI_FACTOR = gql`
  mutation GenerateMultiFactor {
    generateMultiFactor {
      secret
      uri
      qr
    }
  }
`;

export const VERIFY_MULTI_FACTOR = gql`
  mutation VerifyMultiFactor($token: String!) {
    verifyMultiFactor(input: { token: $token }) {
      session
      mfaEnabled
      organizationId
    }
  }
`;

export const IS_SESSION_VALID = gql`
  query ValidSession {
    validSession {
      success
    }
  }
`;

export const IMPERSONATE_REQUEST_RESPONSE = gql`
  mutation RespondToImpersonationRequest($id: String!, $allow: Boolean!) {
    respondToImpersonationRequest(input: { id: $id, allow: $allow }) {
      success
    }
  }
`;

export const RECAPTCHA_SITE_VERIFY = gql`
  mutation RecaptchaSiteVerify($token: String!) {
    recaptchaSiteVerify(input: { token: $token }) {
      success
      score
      errorCodesList
    }
  }
`;

export const CREATE_ONFIDO_APPLICATION = gql`
  mutation CreateOnfidoApplication($input: OnfidoApplicationInput!) {
    createOnfidoApplication(input: $input) {
      sdkToken
      applicantId
    }
  }
`;

export const CREATE_ONFIDO_CHECK = gql`
  mutation CreateOnfidoCheck {
    createOnfidoCheck {
      success
    }
  }
`;