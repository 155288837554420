import React from "react";

import { DataTestIds } from "src/util/testing-util/test-utils";
import { NavBarStepperContainer, StepperPill } from "src/components/nav-bars/nav-bar-stepper/NavBarStepper.styles";

export interface NavBarStepperProps {
  step: number;
  totalSteps: number;
}

export const NavBarStepper = ({ step, totalSteps }: NavBarStepperProps): JSX.Element => {
  if (step < 0) step = 0;
  if (step > totalSteps - 1) step = totalSteps - 1;

  return (
    <NavBarStepperContainer>
      {Array(totalSteps)
        .fill(1)
        .map((_, index: number) => (
          <StepperPill key={index} active={index === step} data-testid={DataTestIds.ONBOARDING_STEPPER_PILL} />
        ))}
    </NavBarStepperContainer>
  );
};
