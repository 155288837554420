import styled from "styled-components";
import { fonts } from "src/theme/theme";

export const TransactionDetailContainer = styled.div`
  padding: 30px;
`;

export const TransactionTitle = styled.div`
  margin: 10px auto 30px auto;
  text-align: center;
  line-height: 1;
  ${fonts.HEADING_4}
`;
