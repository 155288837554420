import React from "react";
import styled from "styled-components";
import { colorPalette as colors, ColorNames } from "src/theme/theme";

const TermsHolder = styled.div`
  ol {
    padding-inline-start: 15px;
  }
  li {
    margin-bottom: 10px;
  }
  a {
    color: ${colors[ColorNames.MIDNIGHT].hex};
  }
`;

export default (
  <TermsHolder>
    <p>
      You understand and agree that you are providing instructions to Letter Money LLC under the Fair Credit Reporting
      Act (“FCRA”) to access information from your personal credit profile or other information from one or more
      consumer reporting agencies such as Equifax, Experian, or TransUnion. You authorize Letter Money LLC to obtain
      such information solely to conduct a pre-qualification for credit. By proceeding, you acknowledge we will initiate
      a “soft pull” of your credit and <strong>this will not affect your credit score</strong>.
    </p>
  </TermsHolder>
);
