import styled from "styled-components/macro";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

import { ReactComponent as ArrowsExpand } from "src/assets/icons/arrows_expand.svg";
import { ReactComponent as Checkmark } from "src/assets/icons/check.svg";

export const TimeRangePickerContainer = styled.div`
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: transparent;

  span {
    ${fonts.PARAGRAPH_S}
    color: ${colors[ColorNames.MIDNIGHT].hex};
    padding-right: 4px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
  }

  &:active {
    transform: scale(0.98);
  }
`;

export const IconExpand = styled(ArrowsExpand)`
  flex: 0 0 auto;
`;

export const DetailPickerOptionCheckmarkHolder = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: ${colors[ColorNames.PINE].hex};
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 15px;
`;

export const DetailPickerOptionCheckmark = styled(Checkmark)`
  height: 16px;
  width: 16px;

  path {
    stroke: ${colors[ColorNames.WHITE].hex};
  }
`;
