import styled from "styled-components";
import { colorPalette as colors, ColorNames, dropShadows, ShadowNames, fonts } from "src/theme/theme";
import { InfoListItemsWrapper } from "src/components/info-list-item/InfoListItem.styles";
import { ListItemValue } from "src/components/info-list-item/InfoListItem.styles";

export const StyledBrowseContent = styled.div`
  padding: 0 30px;
`;

export const StyledBrowseHeading = styled.div`
  margin-bottom: 60px;
  text-align: center;
  color: ${colors[ColorNames.GRAY5].hex};
  ${fonts.PARAGRAPH_S}

  h4 {
    margin-bottom: 8px;
    color: ${colors[ColorNames.MIDNIGHT].hex};
    ${fonts.PARAGRAPH_L}
  }
`;

export const StyledListingsWrapper = styled.div``;

export const StyledListing = styled.div`
  background-color: ${colors[ColorNames.WHITE].hex};
  border-radius: 12px;
  box-shadow: ${dropShadows[ShadowNames.LIGHT]};
`;

export const StyledListingHeader = styled.div<{ backgroundImage?: string }>`
  display: flex;
  justify-content: center;
  height: 120px;
  text-align: center;
  background-image: url(${({ backgroundImage }): string => (backgroundImage ? backgroundImage : "")});
  background-color: ${colors[ColorNames.GRAY2].hex};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  color: transparent;

  svg {
    align-self: center;
    width: 70%;
    height: 70%;
  }

  @media screen and (min-width: 500px) {
    svg {
      width: 50%;
      height: 50%;
    }
  }
`;

export const StyledListingDetails = styled.div`
  padding: 20px 25px;
  color: ${colors[ColorNames.MIDNIGHT].hex};
  ${fonts.PARAGRAPH_M}

  p {
    color: ${colors[ColorNames.GRAY5].hex};
  }

  ${InfoListItemsWrapper} {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  ${ListItemValue} {
    color: ${colors[ColorNames.MIDNIGHT].hex};
  }
`;

export const StyledListingDetailsHeading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 15px;
  color: ${colors[ColorNames.MIDNIGHT].hex};
  ${fonts.PARAGRAPH_XL}
`;
