import React, { Children } from "react";

import StyledButton, { CompleteIconContainer, CheckIcon, Arrow } from "src/components/button/Button.styles";
import { BrandColor, ColorNames, DefaultTheme } from "src/theme/theme";
import { DataTestIds } from "src/util/testing-util/test-utils";
import { GlobalSvgs } from "../global-svgs/GlobalSvgs";
import { LogoSpinner } from "../logo-spinner/LogoSpinner";

export interface ButtonProps {
  onClick?: () => unknown;
  theme?: DefaultTheme;
  children: JSX.Element[] | Element[] | JSX.Element | Element | string;
  disabled?: boolean;
  hidden?: boolean;
  loading?: boolean;
  complete?: boolean;
  color?: BrandColor | ColorNames.TRANSPARENT;
  raised?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  arrow?: boolean;
  dataCy?: string;
  [key: string]: unknown;
}

export const Button = (buttonProps: ButtonProps): JSX.Element => {
  const {
    children,
    onClick = null,
    disabled = false,
    hidden = false,
    loading = false,
    complete = false,
    color = ColorNames.MIDNIGHT,
    raised = false,
    type = "button",
    arrow = false,
    dataCy = "",
    ...props
  } = buttonProps;

  const spinnerColor =
    color === ColorNames.MIDNIGHT ||
    color === ColorNames.LAKE ||
    color === ColorNames.CARDINAL ||
    color === ColorNames.PINE
      ? ColorNames.WHITE
      : ColorNames.MIDNIGHT;

  return (
    <StyledButton
      onClick={loading || complete ? null : onClick}
      disabled={disabled}
      hidden={hidden}
      color={color}
      raised={raised}
      loading={loading ? 1 : 0} // Styled components bug: https://github.com/styled-components/styled-components/issues/1198
      complete={complete}
      type={type}
      multipleChildren={Children.count(children) > 1 || arrow}
      data-testid={DataTestIds.BUTTON}
      data-cy={dataCy}
      {...props}
    >
      {complete ? (
        <CompleteIconContainer>
          <CheckIcon data-testid={DataTestIds.CHECK_ICON} />
        </CompleteIconContainer>
      ) : loading ? (
        <>
          <GlobalSvgs />
          <LogoSpinner color={spinnerColor} data-testid={DataTestIds.LOGO_SPINNER} />
        </>
      ) : (
        <>
          {children}
          {arrow && <Arrow color={color} />}
        </>
      )}
    </StyledButton>
  );
};
