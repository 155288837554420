import { useCallback, useEffect, useState } from "react";
import { client } from "src";
import {
  useMutation,
  ApolloError,
  useQuery,
  useLazyQuery,
  ApolloQueryResult,
} from "@apollo/client";

import { decorateCurrencyValue } from "src/util/stringUtils";
import { useLogger } from "src/util/useLogger";

import {
  OPEN_CHECKING_ACCOUNT,
  CLOSE_CHECKING_ACCOUNT,
  UPDATE_CHECKING_ACCOUNT,
  GET_ACCOUNTS,
  GET_ACCOUNT_BY_ID,
  GET_ACCOUNT_STATEMENT_BY_ID,
  ADD_ACCOUNT_OWNER,
  REMOVE_ACCOUNT_OWNER,
  GET_ACCOUNT_OWNERS_BY_ID,
} from "src/services/gql/_accounts";
import {
  Account,
  AccountBalancesGrouped,
  AccountRef,
  AddOwnersToCheckingAccountMutation,
  BalancesTimeframe,
  BankAccountByIdQuery,
  BankAccountsQuery,
  BankAccountsQueryVariables,
  useBankAccountBalancesByIdLazyQuery,
  useBankAccountByIdLazyQuery,
  useBankAccountByIdQuery,
  useBankAccountsQuery,
  useBankAccountTransactionsByIdQuery,
} from "src/generated/client";

export interface CreateCheckingAccount {
  name: string;
}

export type RemoveOwner = {
  removeOwnersFromCheckingAccount: {
    success: boolean;
  };
};

export type AddOrRemoveOwnerVariables = {
  accountId: string;
  userId: string;
};

export const addOwner = async ({
  accountId,
  userId,
}: AddOrRemoveOwnerVariables) => {
  try {
    const response = await client.mutate<
      AddOwnersToCheckingAccountMutation,
      AddOrRemoveOwnerVariables
    >({
      mutation: ADD_ACCOUNT_OWNER,
      errorPolicy: "all",
      fetchPolicy: "network-only",
      variables: { accountId, userId },
    });

    if (response.data?.addOwnersToCheckingAccount?.id) {
      return true;
    }
    let errorMessage = "There was a problem adding owner to account.";
    if (response.errors && response.errors.length > 0) {
      errorMessage = response.errors[0].message;
    }
    throw Error(errorMessage);
  } catch (error) {
    throw error;
  }
};

export const removeOwner = async ({
  accountId,
  userId,
}: AddOrRemoveOwnerVariables) => {
  try {
    const response = await client.mutate<
      RemoveOwner,
      AddOrRemoveOwnerVariables
    >({
      mutation: REMOVE_ACCOUNT_OWNER,
      errorPolicy: "all",
      fetchPolicy: "network-only",
      variables: { accountId, userId },
    });

    if (response.data?.removeOwnersFromCheckingAccount?.success) {
      return true;
    }
    let errorMessage = "There was a problem removing owner from account.";
    if (response.errors && response.errors.length > 0) {
      errorMessage = response.errors[0].message;
    }
    throw Error(errorMessage);
  } catch (error) {
    throw error;
  }
};

export const getAccountMembers = async (
  accountId: string,
  selectedId: string
) => {
  try {
    const response = await client.query<
      { account: Account },
      { accountId: string; selectedId: string }
    >({
      query: GET_ACCOUNT_OWNERS_BY_ID,
      errorPolicy: "all",
      fetchPolicy: "network-only",
      variables: { accountId, selectedId },
    });

    if (response.data.account) {
      return response.data.account.owners;
    }

    let errorMessage = "There was a problem fetchng account members.";

    if (response.errors && response.errors.length > 0) {
      errorMessage = response.errors[0].message;
    }
    throw Error(errorMessage);
  } catch (error) {
    throw error;
  }
};

export const hofundRoutes = () => {
  return {};
};

export const getBankAccounts = async () => {
  const res = await client.query({
    query: GET_ACCOUNTS,
    errorPolicy: "all",
    fetchPolicy: "network-only",
    variables: { accountRef: AccountRef.Checking },
  });

  if (!!res.data?.bankAccounts) {
    const accounts =
      res.data?.bankAccounts?.accounts
        ?.filter(Boolean)
        .map((account: Account) => ({
          ...account,
          formattedBalance: decorateCurrencyValue(account.totalBalance.display),
        })) || [];

    return accounts;
  } else {
    throw res.errors;
  }
};

export type GetAccountsData = {
  bankAccounts: {
    accounts: Account[];
  };
};

export const useGetBankAccounts = (): {
  accounts: Account[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => Promise<ApolloQueryResult<GetAccountsData>>;
} => {
  const { data, loading, error, refetch } = useQuery<GetAccountsData>(
    GET_ACCOUNTS,
    {
      errorPolicy: "all",
      fetchPolicy: "cache-and-network",
      variables: { accountRef: AccountRef.Checking },
    }
  );

  const accounts = data?.bankAccounts?.accounts?.filter(Boolean) || [];

  return { accounts, loading, error, refetch };
};

export const useGetAccounts = (
  accountRef: AccountRef[],
  selectedId?: string
): {
  accounts: Partial<Account>[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (
    input?: BankAccountsQueryVariables
  ) => Promise<ApolloQueryResult<BankAccountsQuery>>;
} => {
  const { data, loading, error, refetch } = useBankAccountsQuery({
    errorPolicy: "all",
    fetchPolicy: "network-only",
    variables: { accountRef, selectedId },
  });

  const accounts = data?.bankAccounts?.accounts || [];

  type AccountOwners = Account["owners"];

  const normalizedAccounts = accounts.map((acc) => ({
    ...acc,
    owners: acc.owners as AccountOwners,
  }));

  return { accounts: normalizedAccounts, loading, error, refetch };
};

export const useGetFirstBankAccount = () => {
  const POLL_LIMIT = 15;
  const TWO_SECONDS = 2000;
  const [pollCount, setPollCount] = useState(1);
  const reachedPollLimit = pollCount === POLL_LIMIT;
  const [loading, setLoading] = useState(true);
  const {
    getAccount,
    accountData: account,
    error,
  } = useLazyGetBankAccountById();
  const { data, startPolling, stopPolling } = useBankAccountsQuery({
    errorPolicy: "all",
    fetchPolicy: "network-only",
    variables: { accountRef: AccountRef.Checking, selectedId: "" },
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPollCount(pollCount + 1);
    }, TWO_SECONDS);
    const accountFetched = data?.bankAccounts?.accounts?.length;

    if (accountFetched) {
      clearTimeout(timeout);
      stopPolling();
    }

    if (!accountFetched) {
      startPolling(TWO_SECONDS);
    }

    return () => {
      stopPolling();
      clearTimeout(timeout);
    };
  }, [startPolling, stopPolling, data, pollCount]);

  useEffect(() => {
    if (
      data?.bankAccounts?.accounts &&
      data?.bankAccounts?.accounts?.length > 0
    ) {
      getAccount(data.bankAccounts.accounts[0].id);
    }
  }, [getAccount, data]);

  useEffect(() => {
    setLoading(!account);
  }, [account]);

  return { account, loading, error, reachedPollLimit, pollCount };
};

export const getBankAccountById = async (accountId: string) => {
  const res = await client.query({
    query: GET_ACCOUNT_BY_ID,
    errorPolicy: "all",
    variables: { accountId },
  });

  if (res.data?.account) {
    return res.data.account;
  } else {
    throw res.errors;
  }
};

export const useGetBankAccountById = (accountId: string) => {
  const { data, loading, error, refetch } = useBankAccountByIdQuery({
    errorPolicy: "all",
    variables: { accountId },
  });
  const account = !!data?.account ? data.account : null;

  return { account, loading, error, refetchAccount: refetch };
};

export const useLazyGetBankAccountById = () => {
  const [account, { data, loading, error }] = useBankAccountByIdLazyQuery({
    errorPolicy: "all",
    fetchPolicy: "network-only",
  });
  const [accountData, setAccountData] = useState<
    BankAccountByIdQuery["account"] | undefined
  >();

  const getAccount = useCallback(
    (accountId: string) => {
      account({ variables: { accountId } });
    },
    [account]
  );

  useEffect(() => {
    if (!!data?.account) {
      setAccountData(data.account);
    }
  }, [data]);

  const normalizedAccountData = {
    ...accountData,
    owners: accountData?.owners as any,
  };

  return { getAccount, accountData: normalizedAccountData, loading, error };
};

export const useGetAccountBalancesById = (): {
  getBalances: (accountId: string, timeframe: BalancesTimeframe) => void;
  accountBalances: AccountBalancesGrouped[];
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [balances, { data, loading, error }] =
    useBankAccountBalancesByIdLazyQuery({ errorPolicy: "all" });
  const [accountBalances, setAccountBalances] = useState<
    AccountBalancesGrouped[]
  >([]);

  const getBalances = useCallback(
    (accountId: string, timeframe: BalancesTimeframe) => {
      balances({ variables: { accountId, timeframe } });
    },
    [balances]
  );

  useEffect(() => {
    if (data?.account?.balances) {
      setAccountBalances(data.account.balances);
    }
  }, [data]);

  return { getBalances, accountBalances, loading, error };
};

export const useGetTransactionsById = (accountId: string) => {
  const { data, loading, error } = useBankAccountTransactionsByIdQuery({
    errorPolicy: "all",
    variables: { accountId },
  });

  const transactions = data?.transactionsByAccountId?.items || [];

  return { transactions, loading, error };
};

export type OpenAccount = {
  openCheckingAccount: {
    id: string;
    totalBalance: string;
    availableBalance: string;
    externalAccountNumber: string;
    nickname: string;
  };
};

export type OpenAccountVariables = {
  name: string;
};

export const useOpenCheckingAccount = (): {
  openAccount: (values: CreateCheckingAccount) => Promise<boolean>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [account, { loading, error }] = useMutation<
    OpenAccount,
    OpenAccountVariables
  >(OPEN_CHECKING_ACCOUNT, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const openAccount = useCallback(
    async (values: CreateCheckingAccount): Promise<boolean> => {
      try {
        const { data } = await account({ variables: { name: values.name } });
        return !!data?.openCheckingAccount; // ? data.openCheckingAccount : undefined;
      } catch (err) {
        captureException(err);
        return false;
      }
    },
    [account, captureException]
  );

  return { openAccount, loading, error };
};

export type CloseAccount = {
  closeCheckingAccount: {
    success: boolean;
  };
};

export type CloseAccountVariables = {
  accountId: string;
};

export const useCloseCheckingAccount = (): {
  closeAccount: (id: string) => Promise<boolean>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [account, { loading, error }] = useMutation<
    CloseAccount,
    CloseAccountVariables
  >(CLOSE_CHECKING_ACCOUNT, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const closeAccount = useCallback(
    async (id: string): Promise<boolean> => {
      try {
        const { data } = await account({ variables: { accountId: id } });
        return Boolean(data?.closeCheckingAccount.success);
      } catch (err) {
        captureException(err);
        return false;
      }
    },
    [account, captureException]
  );

  return { closeAccount, loading, error };
};

export type UpdateAccount = {
  editCheckingAccount: {
    nickname: string;
  };
};

export type UpdateAccountVariables = {
  accountId: string;
  name: string;
};

export const useUpdateCheckingAccount = (): {
  updateAccount: (values: {
    accountId: string;
    name: string;
  }) => Promise<boolean>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [account, { loading, error }] = useMutation<
    UpdateAccount,
    UpdateAccountVariables
  >(UPDATE_CHECKING_ACCOUNT, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const updateAccount = useCallback(
    async (values: { accountId: string; name: string }): Promise<boolean> => {
      try {
        const { data } = await account({
          variables: { accountId: values.accountId, name: values.name },
        });
        return !!data?.editCheckingAccount; // ? data.editCheckingAccount : undefined;
      } catch (err) {
        captureException(err);
        return false;
      }
    },
    [account, captureException]
  );

  return { updateAccount, loading, error };
};

export const useLazyGetStatementById = (): {
  getStatement: ({
    statementId,
    accountId,
  }: {
    statementId: string;
    accountId: string;
  }) => Promise<string>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [statement, { loading, error }] = useLazyQuery<{
    statement: { url: string };
  }>(GET_ACCOUNT_STATEMENT_BY_ID, {
    errorPolicy: "all",
  });

  const getStatement = useCallback(
    async ({
      statementId,
      accountId,
    }: {
      statementId: string;
      accountId: string;
    }): Promise<string> => {
      try {
        const { data } = await statement({
          variables: { statementId, accountId },
        });
        return data?.statement.url || "";
      } catch (err) {
        return "";
      }
    },
    [statement]
  );

  return { getStatement, loading, error };
};
