import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { ReactComponent as Add } from "src/assets/icons/plus.svg";
import { ReactComponent as Check } from "src/assets/icons/check.svg";
import { colorPalette as colors, ColorNames, borderRadiusOuter } from "src/theme/theme";

type StyledAddModalType = {
  small?: boolean;
};

const icon = css`
  width: 20px;
  height: 20px;
`;

export const CheckIcon = styled(Check)`
  ${icon}
  path {
    stroke: ${colors[ColorNames.WHITE].hex};
  }
`;

export const AddIcon = styled(Add)`
  ${icon}
  path {
    vector-effect: non-scaling-stroke;
    stroke: ${colors[ColorNames.MIDNIGHT].hex};
  }

  transform: rotate(0);
  transition: transform 0.2s ease-out;
  will-change: transform;
`;

const iconContainer = css<StyledAddModalType>`
  width: ${({ small }): string => (small ? `36px;` : `60px`)};
  height: ${({ small }): string => (small ? `36px;` : `60px`)};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ small }): FlattenSimpleInterpolation | undefined => {
    if (small) {
      return css`
        ${AddIcon}, ${CheckIcon} {
          width: 14px;
          height: 14px;
        }
      `;
    }
  }}
`;

export const StyledAddModal = styled.div<{
  complete?: boolean;
  small?: boolean;
  disabled?: boolean;
  $loading?: boolean;
  reviewing?: boolean;
  onClick?: () => unknown | void;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: ${({ small }): string => (small ? `70px` : `100px`)};
  padding: 0 20px 0 25px;
  background: ${colors[ColorNames.WHITE].hex};
  box-shadow: 0px 4px 20px rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0.04),
    0px 30px 60px rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0.14);
  border-radius: ${borderRadiusOuter};
  transform: scale3d(1, 1, 1);
  transition: transform 0.2s ease-out, box-shadow 0.2s ease-out, opacity 0.3s ease;
  will-change: transform, box-shadow, opacity;
  -webkit-tap-highlight-color: transparent;

  ${({ disabled }): FlattenSimpleInterpolation | undefined => {
    if (disabled) {
      return css`
        opacity: 0.5;
        pointer-events: none;
      `;
    }
  }}

  ${({ $loading, reviewing }) =>
    ($loading || reviewing) &&
    `pointer-events: none;
  `}

  ${({ complete }): FlattenSimpleInterpolation | undefined => {
    if (!complete) {
      return css`
        &:hover {
          cursor: pointer;
        }

        &:active {
          transform: scale3d(0.96, 0.96, 0.96);
          box-shadow: 0px 2px 10px rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0.04),
            0px 20px 40px rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0.14);
        }

        &:active svg {
          transform: rotate(90deg);
        }
      `;
    }
  }}
`;

export const AddModalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  width: calc(100% - 80px);
`;

export const CheckIconContainer = styled.div<StyledAddModalType>`
  ${iconContainer}
  background: ${colors[ColorNames.MIDNIGHT].hex};
`;

export const IconContainer = styled.div<StyledAddModalType>`
  ${iconContainer}
  background: ${colors[ColorNames.GRAY1].hex};
`;
