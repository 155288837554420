import { makeVar } from "@apollo/client";

export enum NavigationDirection {
  FORWARD = "forward",
  BACKWARD = "backward",
  REPLACE = "replace",
  FADE = "fade"
}

export interface INavigationState {
  to: string | undefined;
  direction: NavigationDirection;
  transitioning: boolean;
}

export const navigationState = makeVar<INavigationState>({
  to: undefined,
  direction: NavigationDirection.FADE,
  transitioning: false
});
