import { atom, atomFamily, selector, SerializableParam } from "recoil";
import { ThemeColor } from "src/theme/theme";

export interface IDetailPicker {
  id: string;
  actions?: JSX.Element | boolean;
  color?: ThemeColor;
  displayLabel: string;
  onDismiss?: () => void;
  hasValue: boolean;
  options?: JSX.Element;
  origin?: any | null;
  search?: boolean | false;
  handleSearch?: (e: { target: { value: React.SetStateAction<string> } }) => void;
  searchTerm?: string;
  tabs?: JSX.Element;
  tall?: boolean;
  children?: React.ReactNode;
}

export interface IDetailPickerState {
  showIds: string[];
}

export const detailPickers = atomFamily<IDetailPicker, SerializableParam>({
  key: "detailPickers",
  default: {
    id: "",
    displayLabel: "",
    hasValue: false
  }
});

export const detailPickerState = atom<IDetailPickerState>({
  key: "detailPickerState",
  default: {
    showIds: []
  }
});

export const detailPickerSelector = selector({
  key: "detailPickerSelector",
  get: ({ get }) => {
    const pickerState = get(detailPickerState);
    return pickerState.showIds.map((pickerId) => get(detailPickers(pickerId)));
  },

  set: ({ set, get }, newValue: any) => {
    const pickerState = get(detailPickerState);

    if (newValue.hide) {
      if (newValue.pickerId) {
        set(detailPickerState, {
          showIds: pickerState.showIds.filter((pickerId) => pickerId !== newValue.pickerId)
        });
      } else {
        set(detailPickerState, { showIds: [] });
      }
    }

    if (!newValue.hide && !pickerState.showIds.includes(newValue.pickerId)) {
      set(detailPickerState, {
        showIds: [...pickerState.showIds, newValue.pickerId]
      });
    }
  }
});
