export enum AccountTransactionStatuses {
  PENDING = "Pending",
  DECLINED = "Declined",
  CANCELED = "Canceled",
  SETTLED = "Settled",
  SENT = "Sent",
  INITIATED = "Initiated",
  REJECTED = "Rejected",
  CLEARING = "Clearing",
  RETURNED = "Returned",
  HELD = "Held",
  DENIED = "Denied",
}
