import React from "react";
import { generatePath } from "react-router-dom";
import { useShowBottomSheet } from "src/util/useBottomSheet";

import useNavigate from "src/util/useNavigate";
import { NavRoutes } from "src/routes/navRoutes";
import { Row, RowContent, RowLabel, TransferSummary } from "src/routes/app/bank/transfer/Transfer.styles";
import { TransactionDetailContainer, TransactionTitle } from "./DealTransactionDetail.styles";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { ActionableButtonGroup } from "src/components/actionable-button/ActionableButton.styles";
import { ColorNames } from "src/theme/theme";
import { SecondaryButtonContainer } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet.styles";
import { Button } from "src/components/button/Button";
import { decorateCurrencyValue } from "src/util/stringUtils";
import { DealTransaction, DealTransactionStatuses, DealTransactionTypes, DealEntityTypes } from "src/types/deal";
import { DealListingType } from "src/generated/client";

const DealTransactionDetail = ({ transaction }: { transaction?: DealTransaction | undefined }): JSX.Element => {
  const { hideBottomSheet } = useShowBottomSheet();
  const navigate = useNavigate();

  const transactionStatus = transaction?.status;
  let transactionStatusText;
  switch (true) {
    case transactionStatus === DealTransactionStatuses.COMPLETED:
      transactionStatusText = "Completed";
      break;
    case transactionStatus === DealTransactionStatuses.PENDING ||
      transactionStatus === DealTransactionStatuses.CLEARING ||
      transactionStatus === DealTransactionStatuses.HELD ||
      transactionStatus === DealTransactionStatuses.SENT:
      transactionStatusText = "Pending";
      break;
    case transactionStatus === DealTransactionStatuses.COMMITTED:
      transactionStatusText = "Committed";
      break;
    case transactionStatus === DealTransactionStatuses.REJECTED:
      transactionStatusText = "Rejected";
      break;
    case transactionStatus === DealTransactionStatuses.CANCELED:
      transactionStatusText = "Canceled";
      break;
  }

  return (
    <TransactionDetailContainer>
      <TransactionTitle>{transaction?.deal.name}</TransactionTitle>
      <TransferSummary>
        <Row>
          <RowLabel>Deal Type</RowLabel>
          <RowContent>{transaction?.deal.listingType === DealListingType.Investment ? "Investment" : "Secondary"}</RowContent>
        </Row>
        <Row>
          <RowLabel>Transaction Type</RowLabel>
          <RowContent>{transaction?.transactionType === DealTransactionTypes.SELL ? "Sell" : "Buy"}</RowContent>
        </Row>
        <Row>
          <RowLabel>{transaction?.entityType === DealEntityTypes.Share ? "Shares" : "Quantity"}</RowLabel>
          <RowContent>{transaction?.entityQuantity}</RowContent>
        </Row>
        <Row>
          <RowLabel>Amount</RowLabel>
          <RowContent>{decorateCurrencyValue(transaction?.amount?.display)}</RowContent>
        </Row>
        {transactionStatusText && (
          <Row>
            <RowLabel>Status</RowLabel>
            <RowContent>{transactionStatusText}</RowContent>
          </Row>
        )}
        <Row>
          <RowLabel>Time</RowLabel>
          <RowContent>{transaction?.formattedDate}</RowContent>
        </Row>
        <Row>
          <RowLabel>Funded From</RowLabel>
          <RowContent>{transaction?.fundingAccount.last4}</RowContent>
        </Row>
      </TransferSummary>
      <ActionableButtonGroup fullWidth>
        <ActionableButton
          label="Documents"
          color={ColorNames.COBALT}
          onClick={() => {
            //
          }}
        />
        <ActionableButton
          label="View Deal Listing"
          color={ColorNames.GRAY2}
          onClick={() => {
            navigate(
              generatePath(NavRoutes.MARKETPLACE_LISTING_DETAIL, {
                listingId: transaction?.id || "",
                section: "overview"
              })
            );
          }}
        />
      </ActionableButtonGroup>
      <SecondaryButtonContainer>
        <Button
          color={ColorNames.TRANSPARENT}
          onClick={() => {
            hideBottomSheet("deal_transaction_item_sheet");
          }}
          loading={false}
        >
          Close
        </Button>
      </SecondaryButtonContainer>
    </TransactionDetailContainer>
  );
};

export default DealTransactionDetail;
