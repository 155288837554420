import React, { useState, useEffect } from "react";
import { useParams, useRouteError, useFetcher } from "react-router-dom";

import { showNotice } from "src/store/alertState";
import { useFetchCurrentUser } from "src/services/users";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { TabRouter, TabView, useSubnav } from "src/util/useSubnav";

import { Deal, AccountRef, Account } from "src/generated/client";
import { ListingDetailComponentProps } from "../ListingDetail";
import { decorateCurrencyValue, formatCentsToString } from "src/util/stringUtils";
import { NavRoutes } from "src/routes/navRoutes";
import { ColorNames } from "src/theme/theme";
import { RingProgress } from "src/components/ring-progress/RingProgress";

import {
  ListingDetailSectionWrapper,
  ListingDetailSectionContent,
  ListingDetailContentContainer,
  ListingDetailBodyCopy,
  ListingDetailButtonsWrapper
} from "../ListingDetail.styles";

import { StyledRingCallout } from "src/components/ring-callout/RingCallout.styles";

import {
  TransferAccountSelectionContainer,
  TransferActionTitle,
  TransferButtonGroup,
  TransferFormInput
} from "src/routes/app/bank/transfer/Transfer.styles";

import ErrorElement from "src/ErrorElement";
import { Button } from "src/components/button/Button";
import { LargeCurrencyInput, LargeCurrencyInputType } from "src/components/forms/currency-input/LargeCurrencyInput";
import { CurrencyInput } from "src/components/forms/currency-input/CurrencyInput";
import { DetailSelectAccount } from "src/components/forms/detail-select/DetailSelectAccount";
import { ReactComponent as IconInfo } from "src/assets/icons/info.svg";
import { BannerNotice } from "src/components/alert/BannerNotice";
import { StyledFormInputContainer } from "src/components/forms";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { ActionableButtonGroup } from "src/components/actionable-button/ActionableButton.styles";
import { InfoListItem } from "src/components/info-list-item/InfoListItem";
import { InfoListItemsWrapper } from "src/components/info-list-item/InfoListItem.styles";
import { ReactComponent as IconLinkOut } from "src/assets/icons/link_out.svg";
import { AccreditedInvestorSheet } from "src/components/bottom-sheets/accredited-investor-sheet/AccreditedInvestorSheet";
import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import Layout from "src/components/core-layout/Layout";
import { SegmentedControl } from "src/components/segmented-control/SegmentedControl";

export const ListingDetailErrorElement = () => {
  return <ErrorElement redirect={NavRoutes.MARKETPLACE_LISTINGS} alert={true} />;
};

enum AmountType {
  DOLLARS = "Dollar",
  SHARES = "Shares"
}
const SUBNAV_TABS = ["overview", "buy", "sell"];

const InvestmentDetail = (props: ListingDetailComponentProps): JSX.Element => {
  const { deal, orgDeal, accounts, refetchAccounts } = props;

  const { data, state: fetcherState, Form, ...fetcher } = useFetcher();
  const { listingId, section = "overview" } = useParams();
  const { fetchCurrentUser, userLoading } = useFetchCurrentUser();
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();
  const currentOrg = props.currentOrg;

  const [amountInvesting, setAmountInvesting] = useState(0);
  const [accountSelected, setAccountSelected] = useState(false);
  const [updatedAmountInvesting, setUpdatedAmountInvesting] = useState(0);
  const [amountType, setAmountType] = useState<AmountType>(AmountType.DOLLARS);
  const [approxSharesValue, setApproxSharesValue] = useState(0);

  const { selected, selectTab } = useSubnav(SUBNAV_TABS, {
    selectedTab: Math.max(SUBNAV_TABS.indexOf(section), 0)
  });

  useEffect(() => {
    if (!orgDeal) return;
    refetchAccounts({ accountRef: [AccountRef.Checking], selectedId: orgDeal.fundingAccountId });
    orgDeal.committedAmount && setUpdatedAmountInvesting((orgDeal.committedAmount.amount || 0) / 100);
  }, [orgDeal]);

  useEffect(() => {
    if (!data?.response) return;

    let message = "";
    if (data.response.commitToDeal) {
      message = "You have successfully committed to buy.";
    } else if (data.response.updateDealCommitment) {
      message = "You have updated your commitment to buy.";
    } else if (data.response.deleteDealCommitment) {
      message = "You have cancelled your commitment to buy.";
    }

    showNotice(message);
    fetchCurrentUser({ fromNetwork: true });
  }, [data?.response]);

  const handleSellSharesChange = (shares: number, price: number) => {
    setApproxSharesValue(Math.floor(shares * price));
  };

  const didSelectAccount = (account: any) => {
    // returns an array when account is deselected. Bug? What's the expected behaviour?
    if (Array.isArray(account) && account.length === 0) {
      setAccountSelected(false);
    } else {
      setAccountSelected(true);
      // Let's hold off for now on requiring account balances to have the commitment amount, we'll add it back later
      // if (account.totalBalance.amount < (deal?.minInvestment.amount || Infinity)) {
      //   showNotice("Please select an account that has enough funds.", { error: true });
      // }
    }
  };

  const filterAccounts = (deal?: Deal, accounts?: Partial<Account>[]) => {
    if (!accounts || !deal) {
      return [];
    }
    return accounts.filter(
      ({ availableBalance, selected }) =>
        (deal.minBuy && availableBalance && availableBalance.amount >= deal.minBuy.amount) || selected
    );
  };

  const dealStructureText = (structure: string | undefined | null) => {
    switch (true) {
      case structure === "forward":
        return "Forward";
      case structure === "direct":
        return "Direct";
      default:
        return "";
    }
  };

  return (
    <Layout backgroundColor={selected !== 0 ? ColorNames.GRAY1 : undefined} loading={!deal || userLoading}>
      <TabRouter>
        <TabView key={0} show={selected === 0}>
          <ListingDetailSectionWrapper>
            <ListingDetailSectionContent>
              <img
                style={{ maxWidth: "150px" }}
                src={`/marketplace/secondary-logos/${deal?.name.toLowerCase()}.png`}
                onLoad={(e) => {
                  // for retina
                  e.currentTarget.width = e.currentTarget.width / 2;
                }}
              />
              <ListingDetailBodyCopy dangerouslySetInnerHTML={{ __html: deal?.description || "" }} />
              <ActionableButtonGroup fullWidth>
                <ActionableButton
                  label={`Buy ${deal?.name} Shares`}
                  color={ColorNames.COBALT}
                  iconRight={false}
                  onClick={() => selectTab(SUBNAV_TABS.indexOf("buy"))}
                />
                <ActionableButton
                  label={`Sell ${deal?.name} Shares`}
                  color={ColorNames.GRAY2}
                  iconRight={false}
                  onClick={() => selectTab(SUBNAV_TABS.indexOf("sell"))}
                />
              </ActionableButtonGroup>
              <InfoListItemsWrapper>
                <InfoListItem
                  title="Share Price"
                  value={decorateCurrencyValue(deal?.sharePrice?.display)}
                  valueType="number"
                  size="small"
                />
                <InfoListItem
                  title="Targeted Total Return"
                  value={`-${deal?.discount?.display}%`}
                  valueType="number"
                  size="small"
                />
                <InfoListItem
                  title="Implied Valuation"
                  value={decorateCurrencyValue(deal?.impliedValuation?.display)}
                  valueType="number"
                  size="small"
                />
                <InfoListItem
                  title="Minimum Buy"
                  value={decorateCurrencyValue(deal?.minBuy?.display)}
                  valueType="number"
                  size="small"
                />
                <InfoListItem title="Structure" value={dealStructureText(deal?.structure)} size="small" />
                <InfoListItem
                  title="Last Valuation"
                  value={decorateCurrencyValue(deal?.lastValuation?.display)}
                  valueType="number"
                  size="small"
                />
                <InfoListItem title="Last Round" value={deal?.lastRound || ""} valueType="number" size="small" />
              </InfoListItemsWrapper>
              <ActionableButtonGroup fullWidth>
                <ActionableButton
                  label={`${deal?.name} Website`}
                  color={ColorNames.MIST}
                  iconRight={<IconLinkOut />}
                  onClick={() => {
                    window.open(deal?.infoLink, "_blank");
                  }}
                />
              </ActionableButtonGroup>
            </ListingDetailSectionContent>
          </ListingDetailSectionWrapper>
        </TabView>
        <TabView key={1} show={selected === 1}>
          {!orgDeal ? (
            <ListingDetailSectionWrapper className="has-segmented-control">
              <Form method="post">
                <ListingDetailContentContainer>
                  <TransferFormInput>
                    <div>
                      <TransferActionTitle>How much do you want to buy?</TransferActionTitle>
                      <SegmentedControl
                        options={[
                          {
                            label: "Dollars",
                            value: AmountType.DOLLARS
                          },
                          {
                            label: "Shares",
                            value: AmountType.SHARES
                          }
                        ]}
                        color={ColorNames.MIST}
                        active={amountType}
                        onClick={(value) => setAmountType(value as AmountType)}
                      />
                      {amountType === AmountType.SHARES ? (
                        <LargeCurrencyInput
                          type={LargeCurrencyInputType.SHARES}
                          name="committedAmountShares"
                          onChange={(value) => setAmountInvesting(parseFloat(value.replace(/,/g, "")))}
                        />
                      ) : (
                        <LargeCurrencyInput
                          name="committedAmountDollars"
                          onChange={(value) => setAmountInvesting(parseFloat(value.replace(/,/g, "")))}
                        />
                      )}
                    </div>
                  </TransferFormInput>

                  <InfoListItemsWrapper>
                    <InfoListItem
                      title="Share Price"
                      value={decorateCurrencyValue(deal?.sharePrice?.display)}
                      valueType="number"
                      size="small"
                    />
                    <InfoListItem
                      title="Min. Buy Amount"
                      value={decorateCurrencyValue(deal?.minBuy?.display)}
                      valueType="number"
                      size="small"
                    />
                  </InfoListItemsWrapper>

                  <TransferAccountSelectionContainer>
                    <DetailSelectAccount
                      pickerId="funding_account_id"
                      name="fundingAccountId"
                      bankAccounts={accounts || []}
                      label="Funding Account"
                      placeholder="Choose account..."
                      error={!!data?.validationErrors?.fundingAccountId}
                      search
                      shouldHideNonCheckingTabs
                      didSelectAccount={didSelectAccount}
                    />
                  </TransferAccountSelectionContainer>

                  <StyledRingCallout>
                    <div className="heading">
                      <div className="title">
                        {decorateCurrencyValue(deal?.committedAmountBought?.display)} Committed
                      </div>
                      <RingProgress
                        value={deal?.committedAmountBought?.amount || 0}
                        total={deal?.buyThreshold?.amount || 100}
                        color={ColorNames.MIDNIGHT}
                        size={26}
                        strokeWidth={4}
                        trackStrokeWidth={2}
                        showText={false}
                      />
                    </div>
                    <InfoListItem
                      title="Purchase Threshold"
                      value={decorateCurrencyValue(deal?.buyThreshold?.display)}
                      valueType="number"
                      size="small"
                    />
                    <p className="description">
                      Shares are purchased each time member commitments reach{" "}
                      {decorateCurrencyValue(deal?.buyThreshold?.display)}.
                    </p>
                  </StyledRingCallout>

                  <BannerNotice iconLeft={<IconInfo />} textColor={ColorNames.CINNAMON} iconColor={ColorNames.GOLD}>
                    <>Funds must be in your account by the Purchase Threshold to qualify.</>
                  </BannerNotice>

                  <input type="hidden" name="accredited" value={(currentOrg?.accredited || false).toString()} />
                  <TransferButtonGroup data-enabled={true}>
                    <Button
                      raised
                      type="submit"
                      color={ColorNames.MIDNIGHT}
                      loading={fetcherState === "submitting"}
                      disabled={
                        !deal || (deal.minBuy && amountInvesting * 100 < deal.minBuy.amount) || !accountSelected
                      }
                    >
                      {currentOrg?.accredited ? "Commit to Buy" : "Continue"}
                    </Button>
                  </TransferButtonGroup>
                </ListingDetailContentContainer>
              </Form>
            </ListingDetailSectionWrapper>
          ) : (
            <ListingDetailSectionWrapper>
              <ListingDetailSectionContent>
                <BannerNotice iconLeft={<IconInfo />} textColor={ColorNames.CINNAMON} iconColor={ColorNames.GOLD}>
                  <>We'll be in touch as the Purchase Threshold approaches to finalize your purchase.</>
                </BannerNotice>

                <Form method="put">
                  <StyledFormInputContainer>
                    <CurrencyInput
                      name="committedAmount"
                      type="text"
                      label="Purchase Amount"
                      defaultValue={orgDeal.committedAmount?.amount}
                      error={!!data?.validationErrors?.committedAmount}
                      onChange={(value) => setUpdatedAmountInvesting(parseFloat(value.replace(/,/g, "")))}
                    />
                  </StyledFormInputContainer>

                  <DetailSelectAccount
                    pickerId="funding_account_id"
                    name="fundingAccountId"
                    bankAccounts={filterAccounts(deal, accounts)}
                    defaultValue={accounts?.find((a) => a.selected)?.id}
                    label="Funding Account"
                    placeholder="Choose account..."
                    search
                    error={!!data?.validationErrors?.fundingAccountId}
                    shouldHideNonCheckingTabs={true}
                  />

                  <ListingDetailButtonsWrapper>
                    <Button
                      raised
                      color={ColorNames.LAKE}
                      type="submit"
                      loading={fetcherState === "submitting"}
                      disabled={!deal || !!(deal.minBuy && updatedAmountInvesting * 100 < deal.minBuy.amount)}
                      //data-cy={CypressTestIds.Q_ACCOUNT_SAVE}
                    >
                      Update Purchase
                    </Button>

                    <ActionableButton
                      label="Cancel Purchase"
                      destructive
                      iconRight={false}
                      //data-cy={CypressTestIds.CANCEL_ACCOUNT}
                      onClick={(): void => showBottomSheet("cancel_buy_sheet")}
                    />
                  </ListingDetailButtonsWrapper>
                </Form>
              </ListingDetailSectionContent>
            </ListingDetailSectionWrapper>
          )}
        </TabView>
        <TabView key={2} show={selected === 2}>
          {!orgDeal ? (
            <ListingDetailSectionWrapper className="no-padding-currency-input">
              <Form method="post">
                <ListingDetailContentContainer>
                  <TransferFormInput>
                    <div>
                      <TransferActionTitle>How many {deal?.name} shares do you want to sell?</TransferActionTitle>
                      <LargeCurrencyInput
                        type={LargeCurrencyInputType.SHARES}
                        name="sellAmount"
                        onChange={(value) => {
                          const numberOfShares = parseFloat(value.replace(/,/g, ""));
                          setAmountInvesting(numberOfShares);
                          handleSellSharesChange(numberOfShares, deal?.sharePrice?.amount || 0);
                        }}
                      />
                    </div>
                  </TransferFormInput>
                  <InfoListItemsWrapper>
                    <InfoListItem
                      title="Approx. Value"
                      value={decorateCurrencyValue(formatCentsToString(approxSharesValue))}
                      valueType="number"
                      size="small"
                    />
                    <InfoListItem
                      title="Share Price"
                      value={decorateCurrencyValue(deal?.sharePrice?.display)}
                      valueType="number"
                      size="small"
                    />
                    <InfoListItem
                      title="Min. Sell Amount"
                      value={decorateCurrencyValue(deal?.minSell?.display)}
                      valueType="number"
                      size="small"
                    />
                  </InfoListItemsWrapper>

                  <TransferAccountSelectionContainer>
                    <DetailSelectAccount
                      pickerId="funding_account_id"
                      name="fundingAccountId"
                      bankAccounts={accounts || []}
                      label="Funding Account"
                      placeholder="Choose account..."
                      error={!!data?.validationErrors?.fundingAccountId}
                      search
                      shouldHideNonCheckingTabs
                      didSelectAccount={didSelectAccount}
                    />
                  </TransferAccountSelectionContainer>

                  <StyledRingCallout>
                    <div className="heading">
                      <div className="title">{decorateCurrencyValue(deal?.committedAmountSold?.display)} Committed</div>
                      <RingProgress
                        value={deal?.committedAmountSold?.amount || 0}
                        total={deal?.sellThreshold?.amount || 100}
                        color={ColorNames.MIDNIGHT}
                        size={26}
                        strokeWidth={4}
                        trackStrokeWidth={2}
                        showText={false}
                      />
                    </div>
                    <InfoListItem
                      title="Sell Threshold"
                      value={decorateCurrencyValue(deal?.sellThreshold?.display)}
                      valueType="number"
                      size="small"
                    />
                    <p className="description">
                      Shares are sold each time member commitments reach{" "}
                      {decorateCurrencyValue(deal?.sellThreshold?.display)}.
                    </p>
                  </StyledRingCallout>

                  <input type="hidden" name="accredited" value={(currentOrg?.accredited || false).toString()} />
                  <TransferButtonGroup data-enabled={true}>
                    <Button
                      raised
                      type="submit"
                      color={ColorNames.MIDNIGHT}
                      loading={fetcherState === "submitting"}
                      disabled={
                        !deal || (deal.minSell && amountInvesting * 100 < deal.minSell.amount) || !accountSelected
                      }
                    >
                      {currentOrg?.accredited ? "Commit to Sell" : "Continue"}
                    </Button>
                  </TransferButtonGroup>
                </ListingDetailContentContainer>
              </Form>
            </ListingDetailSectionWrapper>
          ) : (
            <ListingDetailSectionWrapper>
              <ListingDetailSectionContent>
                <BannerNotice iconLeft={<IconInfo />} textColor={ColorNames.CINNAMON} iconColor={ColorNames.GOLD}>
                  <>We'll be in touch as the Sell Threshold approaches to finalize your sell.</>
                </BannerNotice>

                <Form method="put">
                  <StyledFormInputContainer>
                    <CurrencyInput
                      name="committedAmount"
                      type="text"
                      label="Sell Amount"
                      defaultValue={orgDeal.committedAmount?.amount}
                      error={!!data?.validationErrors?.committedAmount}
                      onChange={(value) => setUpdatedAmountInvesting(parseFloat(value.replace(/,/g, "")))}
                    />
                  </StyledFormInputContainer>

                  <DetailSelectAccount
                    pickerId="funding_account_id"
                    name="fundingAccountId"
                    bankAccounts={filterAccounts(deal, accounts)}
                    defaultValue={accounts?.find((a) => a.selected)?.id}
                    label="Funding Account"
                    placeholder="Choose account..."
                    search
                    error={!!data?.validationErrors?.fundingAccountId}
                    shouldHideNonCheckingTabs={true}
                  />

                  <ListingDetailButtonsWrapper>
                    <Button
                      raised
                      color={ColorNames.LAKE}
                      type="submit"
                      loading={fetcherState === "submitting"}
                      disabled={!deal || !!(deal.minSell && updatedAmountInvesting * 100 < deal.minSell.amount)}
                      //data-cy={CypressTestIds.Q_ACCOUNT_SAVE}
                    >
                      Update Sell
                    </Button>

                    <ActionableButton
                      label="Cancel Sell"
                      destructive
                      iconRight={false}
                      //data-cy={CypressTestIds.CANCEL_ACCOUNT}
                      onClick={(): void => showBottomSheet("cancel_sell_sheet")}
                    />
                  </ListingDetailButtonsWrapper>
                </Form>
              </ListingDetailSectionContent>
            </ListingDetailSectionWrapper>
          )}
        </TabView>
      </TabRouter>

      <AccreditedInvestorSheet id="accredited_investor_sheet" />

      <ActionBottomSheet
        id="cancel_buy_sheet"
        title="Are you sure?"
        actionText="Cancel Purchase"
        actionColor={ColorNames.CARDINAL}
        actionLoading={fetcherState === "submitting"}
        onAction={() => {
          if (fetcherState === "submitting") return;
          fetcher.submit({}, { method: "delete" });
        }}
        secondaryActionText="Close"
        onSecondaryAction={(): void => hideBottomSheet()}
      />
      <ActionBottomSheet
        id="cancel_sell_sheet"
        title="Are you sure?"
        actionText="Cancel Sell"
        actionColor={ColorNames.CARDINAL}
        actionLoading={fetcherState === "submitting"}
        onAction={() => {
          if (fetcherState === "submitting") return;
          fetcher.submit({}, { method: "delete" });
        }}
        secondaryActionText="Close"
        onSecondaryAction={(): void => hideBottomSheet()}
      />
    </Layout>
  );
};

export default InvestmentDetail;
