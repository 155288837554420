import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { motion } from "framer-motion";

import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

import { ActionableButtonGroup } from "src/components/actionable-button/ActionableButton.styles";
import { InfoListItemsWrapper } from "src/components/info-list-item/InfoListItem.styles";
import { LoadingBox } from "src/components/loading-container/LoadingContainer.styles";


export const StyledStepsContainer = styled(motion.div)<{ noPadding?: boolean }>`
  flex: 1 1 0%;
  width: 100%;
  padding: ${({ noPadding }): string => (noPadding ? "0" : "0 30px 30px 30px")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  form, .form {
    justify-content: flex-start;
    gap: 20px;

    &.form {
      flex: 2;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  button[type="submit"], button.submit, ${LoadingBox} {
    margin-top: auto;

    &${LoadingBox} {
      flex: 0 0 auto;
    }
  }

  ${InfoListItemsWrapper} {
    margin: 10px 0;
  }

  ${ActionableButtonGroup} button {
    margin-top: 0;
  }
`;

export const StepHeader = styled.p`
  ${fonts.HEADING_4}
  text-align: center;
  margin-bottom: 40px;
`;

export const StepTitle = styled.p`
  ${fonts.PARAGRAPH_L}
  text-align: center;
`;

export const StepParagraph = styled.p<{ highlight?: boolean; gray?: boolean; }>`
  margin-bottom: 40px;
  ${fonts.PARAGRAPH_S}
  text-align: center;
  color: ${({ gray }) => gray ? colors[ColorNames.GRAY5].hex : colors[ColorNames.MIDNIGHT].hex};

  ${({ highlight }): FlattenSimpleInterpolation | undefined => {
    if (highlight) {
      return css`
        ${fonts.PARAGRAPH_L}
        color: ${colors[ColorNames.OCEAN].hex};
      `;
    }
  }}

  a {
    color: ${colors[ColorNames.MIDNIGHT].hex};
  }
`;

export const StepDownPaymentHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: stretch;
  justify-content: center;
  padding-bottom: 20px;
  position: relative;

  & > * {
    margin: 0;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -120px;
    left: -30px;
    right: -30px;
    height: 120px;
    z-index: -1;
    pointer-events: none;
    background: linear-gradient(to bottom, rgb(${colors[ColorNames.GRAY1].rgb}), rgb(${colors[ColorNames.WHITE].rgb}) 120px);
  }
`;