import React from "react";
import { useFormikContext } from "formik";
import { StyledForm } from "src/components/forms";

export const Form = ({ children }: { children: JSX.Element | string }): JSX.Element => {
  const { handleSubmit, setErrors } = useFormikContext();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setErrors({});
    setTimeout(handleSubmit, 0);
  };

  return <StyledForm onSubmit={onSubmit}>{children}</StyledForm>;
};
