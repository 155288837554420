import { useCallback } from "react";
import { useSetSession } from "src/services/auth";
import { useHoFund } from "src/util/useHoFund";
import { useLogger } from "src/util/useLogger";
import { NavRoutes } from "src/routes/navRoutes";
import { Organization } from "src/generated/client";

type RequestType = { organizationId: string };
type ReturnType = { session: string; organizationId: string };

const useSetOrganization = () => {
  const setSession = useSetSession();
  const { captureException } = useLogger();
  const {
    action: reissueOrg,
    loading,
    error,
  } = useHoFund<ReturnType, RequestType>(NavRoutes.REISSUE_TOKEN, true);

  const setOrganization = useCallback(
    async (organization: Organization): Promise<ReturnType | undefined> => {
      try {
        const data = await reissueOrg({ organizationId: organization.id });
        if (!!data?.session) {
          setSession(data.session);
        }
        return data;
      } catch (err) {
        captureException(err);
      }
    },
    [reissueOrg, captureException]
  );

  return { setOrganization, loading, error };
};

export default useSetOrganization;
