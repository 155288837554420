import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useLocation } from "react-router-dom";
import useNavigate from "src/util/useNavigate";

import { CancelButtonContainer } from "src/pages/approval-request/ApprovalRequest.styles";
import { BasicAuthContainer, StyledAuthPage, AuthPageWrapper } from "src/pages/auth/Auth.styles";

import { Button } from "src/components/button/Button";
import { ButtonGroup } from "src/components/button/Button.styles";

import { NavRoutes } from "src/routes/navRoutes";
import { getQueryParam } from "src/util/stringUtils";
// eslint-disable-next-line
import { StyledForm, StyledFormInputContainer } from "src/components/forms";
// eslint-disable-next-line
import { useLogin, LoginData, useVerifyMultiFactor, useImpersonationResponse } from "src/services/auth";
import { ColorNames } from "src/theme/theme";
import Layout from "src/components/core-layout/Layout";
import { useNavbar } from "src/util/useNavbar";

const REDIRECT_KEY = "redirect";
const ID_KEY = "id";

const ApprovalRequest = (): JSX.Element => {
  useNavbar({ title: "Account Access Request", back: true });
  const [gqlError, setGqlError] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>();
  const [impersonationEventId, setImpersonationEventId] = useState<string | undefined>();
  const [impersonationDecision, setImpersonationDecision] = useState<boolean | undefined>();
  // eslint-disable-next-line
  const { login, loading, error } = useLogin();
  const {
    respondToImpersonationRequest,
    error: respondToImpersonationRequestError,
    // eslint-disable-next-line
    loading: respondToImpersonationRequestLoading
  } = useImpersonationResponse();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const redirect = getQueryParam(REDIRECT_KEY, location.search);
    if (redirect) {
      setRedirectUrl(decodeURIComponent(redirect));
    }

    const id = getQueryParam(ID_KEY, location.search);
    if (id) {
      setImpersonationEventId(id);
    }

    if (error || respondToImpersonationRequestError) {
      setGqlError(true);
    }

    return (): void => {
      setGqlError(false);
    };
  }, [error, respondToImpersonationRequestError, location.search, redirectUrl, impersonationEventId]);

  const operator = new URLSearchParams(location.search).get("operator");

  return (
    <Layout>
      <StyledAuthPage>
        <AuthPageWrapper>
          <BasicAuthContainer>
            <Formik
              initialValues={{}}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values, { setSubmitting }): void => {
                if (impersonationEventId && impersonationDecision !== undefined) {
                  respondToImpersonationRequest({ id: impersonationEventId, allow: impersonationDecision });
                  navigate(NavRoutes.BANK_DASHBOARD);
                }
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }): JSX.Element => (
                <StyledForm>
                  <div>
                    <strong>{operator}</strong> from the <strong>Letter Customer Support Team</strong> would like
                    temporary access to your account, to help you with your support request.
                  </div>

                  <ButtonGroup>
                    <Button
                      raised
                      type="submit"
                      data-cy="sign-in-btn"
                      onClick={(): void => setImpersonationDecision(true)}
                      loading={isSubmitting || loading}
                    >
                      Allow
                    </Button>

                    <CancelButtonContainer>
                      <Button
                        raised
                        type="submit"
                        color={ColorNames.CARDINAL}
                        onClick={(): void => setImpersonationDecision(false)}
                        loading={isSubmitting || loading}
                      >
                        Reject
                      </Button>
                    </CancelButtonContainer>
                  </ButtonGroup>
                </StyledForm>
              )}
            </Formik>
          </BasicAuthContainer>
        </AuthPageWrapper>
      </StyledAuthPage>
    </Layout>
  );
};

export default ApprovalRequest;
