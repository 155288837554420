import React, { useEffect, useState } from "react";
import { capitalizeTransferType } from "src/util/stringUtils";

import useNavigate from "src/util/useNavigate";
import { NavRoutes } from "src/routes/navRoutes";
import { ColorNames } from "src/theme/theme";
import { RecipientsContainer } from "./Recipients.styles";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";

import { ReactComponent as IconTransfer } from "src/assets/icons/transfer.svg";
import { DetailRows, DetailRow } from "src/components/detail-row/DetailRow";
import { useParams } from "react-router-dom";
import { useGetRecipientById, useDeleteRecipient } from "src/services/recipients";
import { useNavbar } from "src/util/useNavbar";
import { showNotice } from "src/store/alertState";
import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import Layout from "src/components/core-layout/Layout";


const RecipientDetail = (): JSX.Element => {
  const { updateNavbar } = useNavbar({ back: true, title: "" });
  const navigate = useNavigate();
  const { recipientId } = useParams();
  const [detailsReveal, setDetailsReveal] = useState(false);
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();
  const { recipient, loading } = useGetRecipientById(recipientId || "");
  const { deleteRecipient, loading: loadingRecipient, error: errorDeleteRecipient } = useDeleteRecipient();
  

  useEffect(() => {
    updateNavbar({ title: recipient?.nickname });
  }, [recipient?.nickname]);

  const removeRecipient = async (recipientId: string | undefined) => {
    if (!recipientId) return;
    try {
      const deleted = await deleteRecipient({ recipientId });

      if (deleted) {
        showNotice("Recipient sucessfully removed.");
        setTimeout(() => {
          navigate("back");
        }, 1000);
      } else {
        showNotice("There was a problem removing this recipient.", { error: true });
      }
    } catch (error) {
      showNotice(error.message || "There was a problem removing this recipient.", { error: true });
    }
  };

  return (
    <Layout loading={loading}>
      {!loading && !!recipient && (
        <>
          <RecipientsContainer>
            <ActionableButton
              label="Transfer Funds"
              iconLeft={<IconTransfer />}
              color={ColorNames.PINE}
              onClick={() => {
                navigate(NavRoutes.BANK_TRANSFER_SEND);
              }}
            />

            <DetailRows>
              <DetailRow label="Nickname" data={recipient.nickname} large />
              <DetailRow label="Transfer Type" data={capitalizeTransferType(recipient.transferType)} large />
              <>
                {recipient.transferType === "Wire" && !!recipient.wireDescription && (
                  <DetailRow label="Message to recipient bank" data={recipient.wireDescription} large />
                )}
              </>
              {/* <DetailRow label="Total Sent" data="$xxx" /> */}
              {/* <DetailRow label="Total Received" data="$xxx" /> */}
              <DetailRow
                label="Account Number"
                data={`••••${recipient.accountNumber.slice(-4)}`}
                action="copy"
                fullData={recipient.accountNumber}
                reveal={detailsReveal}
                large
                onClick={() => {
                  setDetailsReveal(true);
                }}
              />
              <DetailRow
                label="Routing Number"
                data={`••••${recipient.routingNumber.slice(-4)}`}
                action="copy"
                fullData={recipient.routingNumber}
                reveal={detailsReveal}
                large
                onClick={() => {
                  setDetailsReveal(true);
                }}
              />
              <DetailRow label="Account Holder" data={recipient.accountHolderName} large />
            </DetailRows>

            <ActionableButton
              destructive
              label="Remove Recipient"
              iconRight={false}
              onClick={() => showBottomSheet("delete_recipient")}
            />
          </RecipientsContainer>

          <ActionBottomSheet
            id="delete_recipient"
            title="Are you sure?"
            description={``}
            actionText="Remove Recipient"
            actionColor={ColorNames.CARDINAL}
            actionLoading={loadingRecipient}
            onAction={() => removeRecipient(recipient.id)}
            secondaryActionText="Close"
            onSecondaryAction={(): void => hideBottomSheet()}
          />
        </>
      )}
    </Layout>
  );
};

export default RecipientDetail;
