import React, { useMemo } from "react";
import { Variants } from "framer-motion";

import { Card, CardStatusType, CardType } from "src/generated/client";


import {
  StyledDebitCard,
  DebitCardContainer,
  DebitCardDropShadow,
  CardInfoContainer,
  BalanceInfoContainer,
  CardNumberContainer,
  CardNumber,
  CardNameHolder,
  CardName,
  CardStatusHolder,
} from "src/components/debit-card/DebitCard.styles";
import { colorPalette as colors, ColorNames } from "src/theme/theme";

import { ReactComponent as ChevronRight } from "src/assets/icons/chevron_right.svg";
import { CardStatusDisplayMap, BackgroundInfo } from "src/types/card";
import { getCardDesign } from "src/util/accountUtils";
import { Avatar } from "src/components/avatar/Avatar";
import { DebitBalance } from "./debit-balance/DebitBalance";
import { Button } from "../button/Button";

const variants: Variants = {
  enter: {
    opacity: 0,
    translateY: 60,
  },
  center: {
    opacity: 1,
    translateY: 0,
    transition: {
      duration: 0.25,
      ease: [0.215, 0.61, 0.355, 1.0],
    },
  },
  exit: {
    opacity: 0,
    translateY: 60,
    transition: {
      duration: 0.25,
      ease: [0.215, 0.61, 0.355, 1.0],
    },
  },
};

export interface DebitCardProps {
  card: Card;
  viewOnly?: boolean;
  hasBeenActivated?: boolean;
  onClick?: () => unknown;
}

export const DebitCard = ({
  card,
  hasBeenActivated = false,
  viewOnly = false,
  onClick,
}: DebitCardProps): JSX.Element => {
  const design: BackgroundInfo = useMemo(() => {
    return getCardDesign(card);
  }, [card]);

  return (
    <DebitCardContainer
      onClick={onClick}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
    >
      <DebitCardDropShadow />
      <StyledDebitCard
        background={`/card-backgrounds/${design.backgroundFileName}`}
      >
        <CardInfoContainer>
          <CardNumberContainer>
            <Avatar
              size={28}
              name={`${card.cardHolderFirstName} ${card.cardHolderLastName}`}
              color={ColorNames.GRAY5}
            />
            <CardNumber color={design.textColor}>{card.last4}</CardNumber>
          </CardNumberContainer>
          <CardNameHolder color={design.textColor}>
            <CardName>
              {card.cardHolderFirstName}
              <br />
              {card.cardHolderLastName}
            </CardName>
            {!(
              card.type === CardType.Physical &&
              card.status === CardStatusType.Inactive
            ) && <ChevronRight />}
          </CardNameHolder>
          <CardStatusHolder hasBeenActivated={hasBeenActivated}>
            {hasBeenActivated
              ? "ACTIVATED!"
              : CardStatusDisplayMap[card.status]}
          </CardStatusHolder>
        </CardInfoContainer>

        {viewOnly ||
        (card.type === CardType.Physical &&
          card.status === CardStatusType.Inactive) ? (
          <Button
            children={
              viewOnly || hasBeenActivated ? "View Card" : "Activate Card"
            }
            arrow={viewOnly || hasBeenActivated}
          />
        ) : (
          <BalanceInfoContainer>
            {card.limit.amount > 0 && (
              <DebitBalance
                title="Spend Limit"
                amount={card.limit.display}
                color={design.textColor}
              />
            )}
            <DebitBalance
              title="Spent This Month"
              amount={card.spentThisMonth.display}
              color={design.textColor}
            />
          </BalanceInfoContainer>
        )}
      </StyledDebitCard>
    </DebitCardContainer>
  );
};
