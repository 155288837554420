import styled from "styled-components";
import { colorPalette as colors, ColorNames, fonts, borderRadius } from "src/theme/theme";

export const AmortizationSheetContainer = styled.div`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  padding: 35px 0 20px;

  --table-gap: 12px;
  --table-row-gap: 16px;
  --table-padding: 8px;

  button {
    margin: 0 20px;
    width: auto;
  }
`;

export const AmortizationSheetTitle = styled.p`
  ${fonts.HEADING_4}
  color: ${colors[ColorNames.MIDNIGHT].hex};
  text-align: center;
  margin: 0 20px;
`;

export const AmortizationSheetTable = styled.div`
  flex: 1 1 0px;
  margin: 30px 0;
  overflow: scroll;
  overscroll-behavior: contain;
  display: grid;
  margin-left: 20px;
  padding-right: 20px;
  grid-template-columns: repeat(5, minmax(min-content, 1fr));
  grid-auto-rows: min-content;
  column-gap: var(--table-gap);
  row-gap: var(--table-row-gap);

  &:after {
    content: none;
    grid-column: 1 / -1;
    position: sticky;
    bottom: 0;
    width: 100%;
    height: 20px;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0.15) 100%);
    pointer-events: none;
  }
`;

export const AmortizationSheetTableHeader = styled.div`
  display: contents;
  ${fonts.PARAGRAPH_S}
  color: ${colors[ColorNames.OCEAN].hex};

  div {
    position: sticky;
    top: 0;
    z-index: 2;
    padding: 10px var(--table-padding) 10px calc(var(--table-gap) + var(--table-padding));
    margin-left: calc(var(--table-gap) * -1);
    background: rgba(229, 246, 250, 0.8);
    backdrop-filter: blur(8px);
    &:first-child {
      z-index: 3;
      left: 0;
      border-top-left-radius: ${borderRadius};
      border-bottom-left-radius: ${borderRadius};
      margin-left: 0;
      padding-left: var(--table-padding);
    }
    &:last-child {
      border-top-right-radius: ${borderRadius};
      border-bottom-right-radius: ${borderRadius};
    }
  }
`;

export const AmortizationSheetTableCell = styled.div`
  padding: 0 var(--table-padding);
  ${fonts.NUMBER_S}
  white-space: nowrap;

  &.date {
    position: sticky;
    left: 0;
    background-color: white;
    color: ${colors[ColorNames.GRAY5].hex};
  }
`;