import styled from "styled-components/macro";
import { colorPalette as colors, ColorNames, fonts, borderRadiusOuter, ThemeColor } from "src/theme/theme";
import { DetailRowData, DetailRowLabel, StyledDetailRow } from "src/components/detail-row/DetailRow.styles";
import {
  ActionableButtonGroup,
  StyledActionableButton
} from "src/components/actionable-button/ActionableButton.styles";
import { StyledNavBarContent } from "src/components/nav-bars/NavBars.styles";
import { motion } from "framer-motion";
import { Overlay } from "src/components/bottom-sheets/BottomSheet.styles";

export const AccountsPage = styled.div<{ gray?: boolean }>`
  min-height: calc(100vh - var(--navbarHeight) - var(--layoutTopPadding));
  @supports (-webkit-touch-callout: none) {
    min-height: calc(-webkit-fill-available - var(--navbarHeight) - var(--layoutTopPadding));
  }
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ gray }): string =>
    gray ? `${colors[ColorNames.GRAY1].hex}` : `${colors[ColorNames.WHITE].hex}`};
  @media (min-width: 900px) {
    max-width: 600px;
  }
`;

export const MemberDetailContent = styled.div`
  padding: 0 30px 30px;
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AccountsPageContainer = styled.div`
  padding: 0 30px;
`;

export const BalanceAmountHeader = styled.div`
  padding: 0 30px;
  margin-bottom: 40px;
`;

export const DepositAlert = styled.div`
  margin: 0 30px 20px;
`;

export const AmountDollars = styled.div<{ color?: ThemeColor }>`
  ${fonts.NUMBER_XXL}
  color: ${({ color }): string => color ? colors[color].hex : colors[ColorNames.PINE].hex };

  .cents-sup {
    ${fonts.NUMBER_XL}
    vertical-align: baseline;
    position: relative;
    top: -9px;
  }
`;

export const BalanceLabel = styled.div<{ color?: ThemeColor }>`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  ${fonts.PARAGRAPH_M}
  padding-top: 4px;

  .label {
    color: ${({ color }): string => color ? colors[color].hex : colors[ColorNames.TEA].hex };
  }
`;

export const AccountsList = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 20px 30px 0;

  svg {
    fill: transparent;
  }
`;

export const AccountChart = styled.div`
  position: relative;
  height: 100px;
  width: 100%;
  touch-action: none;

  &.tall {
    height: 160px;
  }

  svg {
    fill: transparent;
  }
`;

export const AccountDetailsList = styled.div`
  position: relative;
  padding: 20px 30px;
`;

export const AccountDetailsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  ${fonts.PARAGRAPH_M}
  color: ${colors[ColorNames.GRAY5].hex};

  .amount {
    ${fonts.NUMBER_M}
    color: ${colors[ColorNames.MIDNIGHT].hex};
  }
`;

export const BalanceDetails = styled.div`
  ${StyledDetailRow} {
    padding: 10px 0;

    &.dividerTop {
      padding-top: 20px;
      margin-top: 10px;
    }
  }
`;

export const InfoGroup = styled.div<{ background?: ThemeColor, labelColor?: ThemeColor, dataColor?: ThemeColor }>`
  background-color: ${({ background }): string => background ? colors[background].hex : colors[ColorNames.SAND].hex };
  border-radius: ${borderRadiusOuter};
  padding: 10px;
  margin: 30px -10px;

  ${StyledDetailRow} {
    padding: 10px 13px;
  }

  ${DetailRowLabel} {
    color: ${({ labelColor }): string => labelColor ? colors[labelColor].hex : colors[ColorNames.MIDNIGHT].hex };
  }

  ${DetailRowData} {
    color: ${({ dataColor }): string => dataColor ? colors[dataColor].hex : colors[ColorNames.PINE].hex };
  }

  ${StyledActionableButton} {
    margin-top: 10px;
  }
`;

export const AccountTransactions = styled.div`
  flex: 1 1 auto;
  background: ${colors[ColorNames.GRAY1].hex};
  padding-top: 30px;
  padding-bottom: 20px;
  margin-top: 30px;
  padding: 30px 0;

  @media (min-width: 961px) {
    border-radius: 4px;
  }
`;

export const AccountTransactionsTitle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${fonts.PARAGRAPH_L}
  padding: 0 30px;
  margin-bottom: 15px;
`;

export const AccountTransactionsRows = styled.div`
  padding-left: 30px;
`;

export const AccountTransactionsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 30px 16px 10px;
  border-bottom: 1px solid ${colors[ColorNames.GRAY2].hex};
`;

export const AccountTransactionRowInfo = styled.div`
  ${fonts.PARAGRAPH_S}

  .date {
    margin-top: 2px;
    color: ${colors[ColorNames.GRAY5].hex};
  }
`;

export const AccountTransactionRowAmount = styled.div`
  ${fonts.NUMBER_M}
  text-align: right;
`;

export const AccountsPageEmpty = styled(motion.div)`
  flex: 1 1 0%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-column: 1 / -1;

  ${StyledNavBarContent} {
    color: white;
  }

  ${Overlay} {
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

export const FeatureExplanation = styled(motion.div)`
  padding: 30px;
  padding-bottom: 64px;
  text-align: center;

  h2 {
    margin-bottom: 8px;
    color: ${colors[ColorNames.WHITE].hex};
    ${fonts.PARAGRAPH_L}
  }

  p {
    max-width: 300px;
    margin: 0 auto 40px;
    color: ${colors[ColorNames.GRAY5].hex};
    ${fonts.PARAGRAPH_S};
  }
`;

export const AccountsPageEmptyParagraph = styled.div`
  text-align: center;
`;
