import { useEffect, useCallback } from "react";
import { useFormikContext } from "formik";

export const useFieldSafe = <T>(
  fieldName: string
): {
  setValue: (value: T) => void;
  setTouched: (touched: boolean) => void;
  error: string | undefined;
  value: T;
  isSubmitting: boolean;
} => {
  const { setFieldValue, errors, values, setFieldTouched, registerField, isSubmitting } = useFormikContext();

  useEffect(() => {
    registerField(fieldName, {});
  }, [fieldName, registerField]);

  return {
    setValue: useCallback((value: T) => setFieldValue(fieldName, value), [fieldName, setFieldValue]),
    setTouched: useCallback(
      (touched: boolean) => {
        setFieldTouched(fieldName, touched);
      },
      [setFieldTouched, fieldName]
    ),
    error: errors[fieldName as keyof typeof errors],
    value: (values as Record<string, unknown>)[fieldName] as T,
    isSubmitting
  };
};
