import { client } from "src";

import {
  LineTypesDocument,
  LineTypeDocument,
  GetLineDocument,
  AddEmailToLineAuctionWaitlistDocument,
  AddEmailToLineAuctionWaitlistInput,
  RunCreditCheckDocument,
  CheckStatus,
  CreateLineInput,
  CreateLineDocument,
  UpdateAndResetLineDocument,
  CancelLineDocument,
  AcceptLineTermsDocument,
  InitiateLineDownPaymentDocument,
  AllocateLineDownPaymentAccountIdDocument,
  GenerateLineDocumentUrlDocument,
  DocumentSigningCompletedDocument,
  LineAuctionDatesDocument,
  UploadProofOfInsuranceForLineDocument,
  DocumentSigningCompletedQuery,
  DocumentSigningCompletedQueryVariables,
  AmortizationCalculationInput,
  CalculateAmortizationDocument,
  LineAuctionDatesQuery,
  LineAuctionDatesQueryVariables,
  QueryGetLineDocumentsArgs,
  GetLineDocumentsDocument,
  GetLineDocumentsQuery,
  GetLineDocumentsQueryVariables
} from "src/generated/client";

export const getUpcomingTetonRidgeAuctions = async (includePast: boolean) => {
  const res = await client.query<LineAuctionDatesQuery, LineAuctionDatesQueryVariables>({
    query: LineAuctionDatesDocument,
    errorPolicy: "all",
    variables: {
      includePast
    }
  });

  if (res.data?.lineAuctionDates) {
    return res.data.lineAuctionDates;
  } else {
    throw res.errors;
  }
};

export const getLineTypes = async () => {
  const res = await client.query({
    query: LineTypesDocument,
    errorPolicy: "all"
  });

  if (res.data?.lineTypes) {
    return res.data.lineTypes;
  } else {
    throw res.errors;
  }
};

export const getLineType = async (lineTypeId: string) => {
  const res = await client.query({
    query: LineTypeDocument,
    errorPolicy: "all",
    variables: {
      lineTypeId
    }
  });

  if (res.data?.lineType) {
    return res.data.lineType;
  } else {
    throw res.errors;
  }
};

export const getLine = async (lineId: string) => {
  const res = await client.query({
    query: GetLineDocument,
    errorPolicy: "all",
    fetchPolicy: "network-only",
    variables: {
      lineId
    }
  });

  if (res.data?.line) {
    return res.data.line;
  } else {
    throw res.errors;
  }
};

export const addEmailToAuctionWaitlist = async (values: AddEmailToLineAuctionWaitlistInput) => {
  try {
    const res = await client.mutate({
      mutation: AddEmailToLineAuctionWaitlistDocument,
      errorPolicy: "all",
      variables: { input: { ...values } }
    });

    if (!!res.data?.addEmailToLineAuctionWaitlist?.success) {
      return { success: true };
    } else {
      let errorMessage = "There was a problem adding your email.";
      if (!!res.errors && res.errors.length > 0) {
        errorMessage = res.errors[0].message;
      }
      throw Error(errorMessage);
    }
  } catch (err) {
    throw err;
  }
};

export const runCreditCheck = async () => {
  try {
    const res = await client.mutate({
      mutation: RunCreditCheckDocument,
      errorPolicy: "all"
    });

    if (res.data?.runCreditCheck?.creditCheck === CheckStatus.Passed) {
      return { success: true };
    } else {
      let errorMessage = "There was a problem running your credit check.";
      if (!!res.errors && res.errors.length > 0) {
        errorMessage = res.errors[0].message;
      }
      throw Error(errorMessage);
    }
  } catch (err) {
    throw err;
  }
};

export const createLine = async (values: CreateLineInput) => {
  const res = await client.mutate({
    mutation: CreateLineDocument,
    errorPolicy: "all",
    variables: { input: values }
  });

  if (!!res.data?.createLine) {
    return res.data.createLine;
  } else {
    throw res.errors;
  }
};

export const updateLine = async (lineId: string, values: CreateLineInput) => {
  const res = await client.mutate({
    mutation: UpdateAndResetLineDocument,
    errorPolicy: "all",
    variables: { lineId, input: values }
  });

  if (!!res.data?.updateAndResetLine) {
    return res.data.updateAndResetLine;
  } else {
    throw res.errors;
  }
};

export const cancelLine = async (lineId: string) => {
  const res = await client.mutate({
    mutation: CancelLineDocument,
    errorPolicy: "all",
    variables: { lineId }
  });

  if (!!res.data?.cancelLine) {
    return res.data.cancelLine;
  } else {
    throw res.errors;
  }
};

export const acceptLineTerms = async (lineId: string) => {
  const res = await client.mutate({
    mutation: AcceptLineTermsDocument,
    errorPolicy: "all",
    variables: { lineId }
  });

  if (!!res.data?.acceptLineTerms) {
    return res.data.acceptLineTerms;
  } else {
    throw res.errors;
  }
};

export const initiateLineDownPayment = async (lineId: string) => {
  const res = await client.mutate({
    mutation: InitiateLineDownPaymentDocument,
    errorPolicy: "all",
    variables: { lineId }
  });

  if (!!res.data?.initiateLineDownPayment) {
    return res.data.initiateLineDownPayment;
  } else {
    throw res.errors;
  }
};

export const allocateLineDownPayment = async (lineId: string, accountId: string) => {
  const res = await client.mutate({
    mutation: AllocateLineDownPaymentAccountIdDocument,
    errorPolicy: "all",
    variables: { lineId, accountId }
  });

  if (!!res.data?.allocateLineDownPaymentAccountId) {
    return res.data.allocateLineDownPaymentAccountId;
  } else {
    throw res.errors;
  }
};

export const generateLineDocumentUrl = async (lineId: string, documentType: string) => {
  const res = await client.query({
    query: GenerateLineDocumentUrlDocument,
    errorPolicy: "all",
    variables: { lineId, documentType }
  });

  if (res.data?.generateLineDocumentUrl) {
    return res.data.generateLineDocumentUrl;
  } else {
    throw res.errors;
  }
};

export const completeDocumentSigning = async (lineId: string, documentId: string) => {
  const res = await client.query<DocumentSigningCompletedQuery, DocumentSigningCompletedQueryVariables>({
    query: DocumentSigningCompletedDocument,
    errorPolicy: "all",
    variables: { lineId, documentId }
  });

  if (res.data?.documentSigningCompleted) {
    return res.data.documentSigningCompleted;
  } else {
    throw res.errors;
  }
};

export const getLineDocuments = async (lineId: string) => {
  const response = await client.query<GetLineDocumentsQuery, GetLineDocumentsQueryVariables>({
    query: GetLineDocumentsDocument,
    errorPolicy: "all",
    variables: { lineId }
  })

  if (response.data?.getLineDocuments?.documents) {
    return response.data.getLineDocuments.documents;
  } else {
    throw response.errors;
  }
}

export const uploadLineInsurance = async ({
  lineId,
  insuranceProof
}: {
  lineId: string;
  insuranceProof: Blob | string;
}) => {
  try {
    const res = await client.mutate({
      mutation: UploadProofOfInsuranceForLineDocument,
      errorPolicy: "all",
      variables: { lineId, insuranceProof }
    });

    if (!!res.data?.uploadProofOfInsuranceForLine) {
      return res.data.uploadProofOfInsuranceForLine;
    } else {
      let errorMessage = "There was a problem uploading your insurance proof.";
      if (!!res.errors && res.errors.length > 0) {
        errorMessage = res.errors[0].message;
      }
      throw Error(errorMessage);
    }
  } catch (err) {
    throw err;
  }
};

export const calculateAmortizationSchedule = async (values: AmortizationCalculationInput) => {
  const res = await client.mutate({
    mutation: CalculateAmortizationDocument,
    errorPolicy: "all",
    variables: { input: values }
  });

  if (!!res.data?.calculateAmortization) {
    return res.data.calculateAmortization;
  } else {
    throw res.errors;
  }
};
