import React, { useEffect, useState } from "react";
import { useSetDetailPicker, useShowDetailPicker } from "src/util/useDetailPicker";
import {
  DetailPickerImageOptionsHolder,
  DetailPickerImageOption,
  DetailPickerImageOptionThumbnail
} from "src/components/detail-picker/DetailPicker.styles";
import { ColorNames } from "src/theme/theme";

import {
  StyledImageSelect,
  StyledImageThumbnail,
  ImageSelectOptionCheckmarkHolder,
  ImageSelectOptionCheckmark
} from "src/components/forms/select/Select.styles";
import { StyledFieldContainer } from "src/components/forms";
import { StyledLabel } from "src/components/text";
import { DataTestIds } from "src/util/testing-util/test-utils";

export interface ImageSelectOptions {
  [id: string]: {
    label: string;
    fileName: string;
  };
}
export interface SelectProps {
  pickerId: string;
  label: string;
  name: string;
  defaultValue?: string | number;
  options: ImageSelectOptions;
  disabled?: boolean;
  [key: string]: unknown;
}

export const ImageSelect = ({
  pickerId,
  label,
  name,
  defaultValue,
  options,
  disabled,
  ...props
}: SelectProps): JSX.Element => {
  const [value, setValue] = useState(defaultValue || "");
  const setDetailPicker = useSetDetailPicker();
  const { showDetailPicker, hideDetailPicker } = useShowDetailPicker();

  useEffect(() => {
    setValue(defaultValue || "");
  }, [defaultValue]);

  useEffect(() => {
    setDetailPicker({
      id: pickerId,
      displayLabel: "Card Artwork",
      color: ColorNames.POWDER,
      tall: true,
      hasValue: !!value,
      options: (
        <DetailPickerImageOptionsHolder>
          {Object.keys(options).map((o: string) => (
            <DetailPickerImageOption key={o}>
              <DetailPickerImageOptionThumbnail
                background={options[o].fileName}
                selected={o === value}
                onClick={() => {
                  setValue(o);
                  setTimeout(() => {
                    hideDetailPicker(pickerId);
                  }, 50);
                }}
              >
                {o === value && <ImageSelectOptionCheckmarkHolder children={<ImageSelectOptionCheckmark />} />}
              </DetailPickerImageOptionThumbnail>
              {options[o].label}
            </DetailPickerImageOption>
          ))}
        </DetailPickerImageOptionsHolder>
      )
    });
  }, [showDetailPicker, hideDetailPicker, value, options, setDetailPicker]);

  return (
    <>
      <StyledFieldContainer data-testid={DataTestIds.FIELD_CONTAINER}>
        <StyledLabel
          htmlFor={(props.id as string) || name}
          data-testid={DataTestIds.LABEL}
        >
          {label}
        </StyledLabel>

        <StyledImageSelect onClick={() => showDetailPicker(pickerId)}>
          <div className="displayLabel">{options[value]?.label}</div>
          <StyledImageThumbnail background={options[value]?.fileName} />
          <input type="hidden" name={name} value={value} disabled={disabled} data-testid={DataTestIds.SELECT} {...props} />
        </StyledImageSelect>
      </StyledFieldContainer>
    </>
  );
};
