import React, { useEffect } from "react";
import { useFetcher } from "react-router-dom";

import { useCurrentUser } from "src/services/users";
import { useCurrentOrg } from "src/services/organizations";
import { useLazyGetBankAccountById } from "src/services/accounts";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { useNavbar } from "src/util/useNavbar";

import { NavRoutes } from "src/routes/navRoutes";
import { ColorNames } from "src/theme/theme";
import { Account } from "src/generated/client";

import ConnectAccountSheet from "src/components/bottom-sheets/connect-account-sheet/ConnectAccountSheet";
import BankAccountDetailsSheet from "src/components/bottom-sheets/bank-account-details-sheet/BankAccountDetailsSheet";
import { LoadingSkeleton } from "src/components/loading-skeleton/LoadingSkeleton";
import { InfoListItem } from "src/components/info-list-item/InfoListItem";
import { InfoListItemsWrapper } from "src/components/info-list-item/InfoListItem.styles";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { ActionableButtonGroup } from "src/components/actionable-button/ActionableButton.styles";
import { ReactComponent as IconPopup } from "src/assets/icons/popup.svg";
import { ReactComponent as IconConnect } from "src/assets/icons/connect.svg";

import {
  StyledWelcomeContent,
  StyledWelcomeMessage,
  StyledWelcomeShapes,
  StyledWelcomeActionSheet,
  StyledCircle,
  StyledTriangle,
  StyledSquare
} from "./Welcome.styles";
import {
  ActionSheetTitle,
  ActionSheetDescription
} from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet.styles";


const Welcome = () => {
  useNavbar({ title: "" });
  const currentUser = useCurrentUser();
  const currentOrg = useCurrentOrg({ fullFetch: true });

  const { data: accounts, state: fetcherState, ...fetcher } = useFetcher<Account[]>();
  const { getAccount, accountData: account, error } = useLazyGetBankAccountById();
  const { showBottomSheet } = useShowBottomSheet();

  useEffect(() => {
    if (!accounts && fetcherState === "idle") {
      fetcher.load(NavRoutes.API_ACCOUNT);
    }
  }, [accounts, fetcherState, fetcher]);

  useEffect(() => {
    if (!!accounts && accounts.length > 0) {
      getAccount(accounts[0].id);
    }
  }, [accounts]);

  return (
    <>
      <StyledWelcomeContent>
        <StyledWelcomeShapes>
          <StyledCircle size={"large"} style={{ top: "30%", right: "15%" }} />
          <StyledCircle size={"small"} style={{ top: "50%", left: "15%", animationDuration: "20s" }} />
          <StyledCircle size={"large"} style={{ bottom: "5%", right: "10%", animationDuration: "20s" }} />
          <StyledCircle size={"small"} style={{ bottom: "10%", left: "25%" }} />

          <StyledTriangle size={"large"} style={{ top: "10%", left: "55%" }} />
          <StyledTriangle size={"large"} style={{ top: "50%", left: "45%", animationDuration: "18s" }} />
          <StyledTriangle size={"small"} style={{ top: "40%", right: "15%" }} />
          <StyledTriangle size={"small"} style={{ top: "75%", right: "35%", animationDuration: "20s" }} />

          <StyledSquare size={"large"} style={{ top: "26%", left: "15%" }} />
          <StyledSquare size={"large"} style={{ bottom: "16%", left: "22%", animationDuration: "18s" }} />
          <StyledSquare size={"small"} style={{ top: "14%", left: "18%" }} />
          <StyledSquare size={"small"} style={{ top: "60%", left: "24%" }} />
        </StyledWelcomeShapes>

        <StyledWelcomeMessage>
          <div className="gray">Welcome,</div>
          {currentUser?.firstName || "You"}
        </StyledWelcomeMessage>
        <StyledWelcomeActionSheet>
          {!accounts || accounts.length === 0 || error ? (
            <>
              <ActionSheetTitle children="Almost Ready" />
              <ActionSheetDescription
                children={
                  <>
                    We're almost finished setting up your new letter account.
                    <br />
                    <br />
                    We'll automatically update this page once your account is ready, or you can check back here in a few
                    minutes.
                  </>
                }
              />
            </>
          ) : (
            <>
              <ActionSheetTitle children="Deposit Funds" />
              <ActionSheetDescription
                noMargin
                children={
                  <>
                    To get started, send a wire to your new Letter account:
                    <br />
                    <br />
                  </>
                }
              />
              <ActionableButtonGroup fullWidth>
                {!account ? (
                  <LoadingSkeleton height={44} transparent noMargin />
                ) : (
                  <ActionableButton
                    label="Account & Wire Details"
                    color={ColorNames.PINE}
                    iconRight={<IconPopup />}
                    onClick={() => showBottomSheet("welcome_bank_account_details_sheet")}
                  />
                )}
                <ActionableButton
                  label="Connect to Bank and Transfer"
                  color={ColorNames.MINT}
                  iconRight={<IconConnect />}
                  onClick={() => showBottomSheet("connect_account_sheet")}
                />
              </ActionableButtonGroup>
              <ActionSheetDescription
                size="small"
                children={<>Or, you can connect to your existing bank to transfer in money.</>}
              />
              <InfoListItemsWrapper>
                <InfoListItem title="Send a Wire" value="Next Business Day" size="small" />
                <InfoListItem title="Connected Bank Transfer" value="3-4 Business Days" size="small" />
              </InfoListItemsWrapper>
            </>
          )}
        </StyledWelcomeActionSheet>
      </StyledWelcomeContent>

      <ConnectAccountSheet id="connect_account_sheet" />
      <BankAccountDetailsSheet
        id="welcome_bank_account_details_sheet"
        account={account}
        accountHolder={currentOrg?.accountHolder}
      />
    </>
  );
};

export default Welcome;
