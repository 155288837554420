import React, { useEffect } from "react";
import { Form, useActionData, useNavigation } from "react-router-dom";
import { useCookies } from "react-cookie";

import { useNavbar } from "src/util/useNavbar";
import useNavigate from "src/util/useNavigate";
import { showNotice } from "src/store/alertState";
import { NavRoutes } from "src/routes/navRoutes";
import { NavigationDirection } from "src/store/navigationState";
import { RouteActionResponse } from "src";
import { storedCookieDataKey } from "src/util/localStorage";

import { AuthTitle, AuthCaption } from "src/pages/auth/Auth.styles";
import { StyledLandingPage } from "../Landing.styles";

import Layout from "src/components/core-layout/Layout";
import { LabelInput } from "src/components/forms/input/LabelInput";
import { Button } from "src/components/button/Button";


const TetonRidgeAuctionsEarly = () => {
  const actionData = useActionData() as RouteActionResponse<{ success: boolean }>;
  const { state: actionState } = useNavigation();
  const navigate = useNavigate();
  const [cookies, , removeCookie] = useCookies([storedCookieDataKey.TETON_RIDGE_AUCTION_START_DATE, storedCookieDataKey.TETON_RIDGE_AUCTION_END_DATE, storedCookieDataKey.TETON_RIDGE_AUCTION_LOCATION]);

  useNavbar({
    backAction: () => navigate(NavRoutes.TETON_RIDGE_AUCTIONS, { direction: NavigationDirection.BACKWARD }),
    orgSwitcher: false
  });

  useEffect(() => {
    if (!!actionData?.validationErrors && Object.keys(actionData.validationErrors).length > 0) {
      const firstError = Object.values(actionData.validationErrors)[0] as string;
      showNotice(firstError, { error: true });
    }
  }, [actionData?.validationErrors]);

  useEffect(() => {
    if (!!actionData?.error) {
      showNotice(actionData.error.message || "There was a problem adding your email.", { error: true });
    }
  }, [actionData?.error]);

  useEffect(() => {
    if (!!actionData?.response && actionData.response.success) {
      showNotice("Your email has been added! We'll send you a reminder 4 weeks before the auction.");
      navigate(NavRoutes.LANDING, { direction: NavigationDirection.BACKWARD });
    }
  }, [actionData?.response, navigate]);

  return <Layout>
    <StyledLandingPage>
      <AuthTitle children="Check Back Soon" />
      <AuthCaption>The auction date is too far out to apply for lending at this time.</AuthCaption>
      <AuthCaption gray>Add your email below, and we’ll send you a reminder 4 weeks from the auction date to complete your lending application.</AuthCaption>

      <Form method="post" className="auction-early">
        <LabelInput
          name="email"
          type="email"
          label="Email Address"
          autoComplete="email"
          autoFocus
          error={!!actionData?.validationErrors?.email}
        />

        <input type="hidden" name="auctionStartDate" defaultValue={cookies[storedCookieDataKey.TETON_RIDGE_AUCTION_START_DATE]} />
        <input type="hidden" name="auctionEndDate" defaultValue={cookies[storedCookieDataKey.TETON_RIDGE_AUCTION_END_DATE]} />
        <input type="hidden" name="auctionLocation" defaultValue={cookies[storedCookieDataKey.TETON_RIDGE_AUCTION_LOCATION]} />

        <Button type="submit" raised loading={actionState === "submitting"} children="Remind Me Via Email" />
      </Form>
    </StyledLandingPage>
  </Layout>;
}

export default TetonRidgeAuctionsEarly;