import React, { useEffect, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import { useInviteUser } from "src/services/organizations";
import { useCurrentUser } from "src/services/users";
import { useCurrentOrg } from "src/services/organizations";

import { StyledForm, StyledFormInputContainer } from "src/components/forms";
import { Input } from "src/components/forms/input/Input";
import { Select, SelectOption } from "src/components/forms/select/FormikSelect";

import { useGetBankAccounts } from "src/services/accounts";
import { ActionableButtonGroup } from "src/components/actionable-button/ActionableButton.styles";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import { ListButton } from "src/components/lists/ListButton";
import { ColorNames } from "src/theme/theme";
import { useOrganizationUsers } from "src/services/organizations";
import { OrgType } from "src/generated/client";
import { ListButtonContainer } from "src/components/lists/Lists.styles";
import { Avatar } from "src/components/avatar/Avatar";
import { NavRoutes } from "src/routes/navRoutes";
import useNavigate from "src/util/useNavigate";
import { showNotice } from "src/store/alertState";

import {
  useSetDetailPicker,
  useShowDetailPicker,
} from "src/util/useDetailPicker";

import { useShowBottomSheet } from "src/util/useBottomSheet";
import { DetailPickerOptionButton } from "src/components/detail-picker/DetailPicker.styles";
import {
  DetailPickerOptionCheckmarkHolder,
  DetailPickerOptionCheckmark,
  DetailSelectEmptyState,
} from "src/components/forms/detail-select/DetailSelect.styles";

import { Role } from "src/generated/client";
import { AccountSelectSheet } from "src/components/bottom-sheets/account-select-sheet/AccountSelectSheet";


import Layout from "src/components/core-layout/Layout";
import { useNavbar } from "src/util/useNavbar";

type FormFields = {
  email: string;
  permissionLevel: string;
  accounts?: string[];
  accessAccounts?: string[];
};

type FormSchema = { email: string };

const validationText = "Please use a valid email address.";
const EmailSchema: Yup.ObjectSchema<FormSchema> = Yup.object().shape({
  email: Yup.string().email(validationText).required(validationText),
});

const Members = (): JSX.Element => {
  useNavbar({ title: "Members" });
  const currentUser = useCurrentUser();
  const currentOrg = useCurrentOrg();

  const {
    accounts,
    loading: accountsLoading,
    error: accountsError,
  } = useGetBankAccounts();
  const { users, error, loading, refetch } = useOrganizationUsers({});
  const {
    inviteUser,
    error: inviteError,
    loading: inviteLoading,
  } = useInviteUser();
  const navigate = useNavigate();

  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();
  const inviteMemberSubmitRef = useRef<any>(null);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (inviteError) {
      showNotice("There was a problem inviting user.", { error: true });
    }
  }, [inviteError]);

  const onSubmit = async (values: FormFields) => {
    const res = await inviteUser(values);

    if (res) {
      const { token, userId } = res;
      /**
       @drew 
       if (accessAccounts.length) {
         call this Mutation:
         addUserToAccounts(input: AccountUserIdInput!): GenericResult
         input AccountUserIdInput {
          userId: String!
          accountIds: [String!]
        }
        ie.
        {
          userId,
          accountIds: accessAccounts
        }
       }
       */
      showNotice("Invitation email has been sent");
    } else {
      showNotice(
        "There was a problem inviting this member, please try again later.",
        { error: true }
      );
    }
    hideBottomSheet();
    refetch();
  };

  const createPlaceholder = (ids: string[]) => {
    let placeholder = "Select Account(s)";
    if (ids?.length > 0) {
      const names = ids.map((i) => {
        const pick = accounts?.find((acct) => acct.id === i);
        if (pick) return pick.nickname;
      });
      if (names.length > 0) placeholder = names.join(", ");
    }
    return placeholder;
  };

  const perimssionLevelOptions: SelectOption[] = [
    {
      value: Role.Admin.toUpperCase(),
      label: "Admin",
    },
    {
      value: Role.Member.toUpperCase(),
      label: "Member",
    },
  ];

  const initialFormValues: FormFields = {
    email: "",
    permissionLevel: Role.Admin.toUpperCase(),
    accessAccounts: [],
  };

  // useEffect(() => {
  //   const onOpenInvitationForm = () => {
  //     setShowContactList(false);
  //     showBottomSheet("invite_user_sheet");
  //   };

  //   setDetailPicker({
  //     search: true,
  //     show: showContactList,
  //     onDismiss: () => setShowContactList(false),
  //     displayLabel: "Invite Member",
  //     color: ColorNames.PINE,
  //     hasValue: true,
  //     actions: (
  //       <ActionableButton
  //         label="Add New Contact"
  //         onClick={() => onOpenInvitationForm()}
  //         color={ColorNames.MIDNIGHT}
  //         iconRight={false}
  //       />
  //     ),
  //     options: (
  //       <>
  //         {members.length ? (
  //           members.map(({ id, label, secondaryLabel }) => (
  //             <DetailPickerOptionButton
  //               style={{ paddingRight: "20px" }}
  //               key={id}
  //               onClick={() => {
  //                 // didSetValue(d.id);
  //                 setTimeout(() => {
  //                   setShowContactList(false);
  //                 }, 50);
  //               }}
  //             >
  //               <div className="detail-picker-option-button-with-avatar">
  //                 <Avatar name={label} size={28} />
  //                 <div>
  //                   <div>{label}</div>
  //                   <div className="secondary-label">{secondaryLabel}</div>
  //                 </div>
  //               </div>
  //               {id === selectedOption?.id && (
  //                 <DetailPickerOptionCheckmarkHolder>
  //                   <DetailPickerOptionCheckmark />
  //                 </DetailPickerOptionCheckmarkHolder>
  //               )}
  //             </DetailPickerOptionButton>
  //           ))
  //         ) : (
  //           <DetailSelectEmptyState>You don’t have any contacts.</DetailSelectEmptyState>
  //         )}
  //       </>
  //     )
  //   });
  // }, [showContactList, setShowContactList, members, selectedOption, setDetailPicker, showBottomSheet]);

  return (
    <>
      <Layout loading={loading || !currentUser}>
        {(currentOrg?.orgType !== OrgType.Individual ||
          (currentOrg?.orgType === OrgType.Individual &&
            (users?.length || 0) < 3)) && (
          <ActionableButtonGroup noTopMargin>
            <ActionableButton
              label="Invite a New Member"
              color={ColorNames.MIDNIGHT}
              iconRight={false}
              onClick={() => showBottomSheet("invite_user_sheet")}
            />
          </ActionableButtonGroup>
        )}

        {!error && !loading && users?.length && (
          <ListButtonContainer>
            {users
              // .filter(({ orgRole }) => orgRole !== OrganizationRole.OWNER)
              .map(
                (
                  { id, firstName, lastName, email, orgRole, status },
                  index
                ) => {
                  let name = `${firstName} ${lastName}`;
                  if (name === " ") name = email;
                  const subLabel = `${orgRole}`;

                  return (
                    <ListButton
                      key={index}
                      label={name}
                      sublabel={subLabel}
                      avatar={<Avatar name={name} size={40} />}
                      onClick={() => navigate(`${NavRoutes.MEMBERS}/${id}`)}
                    />
                  );
                }
              )}
          </ListButtonContainer>
        )}
      </Layout>

      <ActionBottomSheet
        id="invite_user_sheet"
        title="Invite a New Member"
        actionText="Send Invite"
        actionColor={ColorNames.LAKE}
        actionLoading={inviteLoading}
        onAction={(): void => {
          inviteMemberSubmitRef.current.click();
        }}
        secondaryActionText="Cancel"
        onSecondaryAction={(): void => hideBottomSheet()}
      >
        <Formik
          validationSchema={EmailSchema}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialFormValues}
          onSubmit={onSubmit}
        >
          {({ handleChange, values, setFieldValue }) => (
            <>
              <StyledForm>
                <StyledFormInputContainer>
                  <Input
                    value={values.email}
                    name="email"
                    type="text"
                    placeholder="Email Address"
                    onChange={handleChange}
                    style={{
                      marginTop: 10,
                    }}
                  />
                  <Select
                    style={{ paddingTop: "unset" }}
                    value={values.permissionLevel}
                    name="permissionLevel"
                    options={perimssionLevelOptions}
                    onChange={handleChange}
                  />
                  {/* {values.permissionLevel === OrganizationRole.MEMBER && (
                    <Multiselect
                      label="Account Access"
                      style={{ paddingTop: "unset" }}
                      value={values.accessAccounts}
                      name="accessAccounts"
                      placeholder={createPlaceholder(values.accessAccounts || [])}
                      selectedNumber={values.accessAccounts?.length}
                      onChange={handleChange}
                      handleClick={() => showBottomSheet("invite_member_select_accounts")}
                    />
                  )} */}
                </StyledFormInputContainer>
                <input type="submit" hidden ref={inviteMemberSubmitRef} />
              </StyledForm>

              {/* <AccountSelectSheet
                id="invite_member_select_accounts"
                accounts={accounts}
                title="Account Access"
                onComplete={(selections: string[]) => {
                  setFieldValue("accessAccounts", selections);
                }}
                onClose={() => hideBottomSheet("invite_member_select_accounts")}
              /> */}
            </>
          )}
        </Formik>
      </ActionBottomSheet>
    </>
  );
};

export default Members;
