import * as Yup from "yup";

import { commitToDeal, updateDealCommitment, deleteDealCommitment } from "src/services/deals";
import { CommitToDealInput } from "src/generated/client";
import { getFormValidationErrors } from "src/util/forms";
import { convertStringNumberToCents } from "src/util/stringUtils";
import { RouteActionResponse } from "src";
import { ActionInternalProps } from "../_actions";

export const investmentAction = async ({ request, params, formData }: ActionInternalProps) => {
  const data: CommitToDealInput = {
    fundingAccountId: (formData.fundingAccountId as string) || "",
    committedAmount: convertStringNumberToCents((formData.committedAmount as string) || ""),
    dealId: params.listingId || ""
  };

  const schema: Yup.ObjectSchema<object> = Yup.object().shape({
    fundingAccountId: Yup.string().required("Please choose a Funding Account."),
    committedAmount: Yup.string()
      .notOneOf(["0.00", "0"], "Please set an Investment Amount.")
      .required("Please set an Investment Amount."),
    dealId: Yup.string().required("You must commit to a valid deal.")
  });

  if (request.method === "POST") {
    if ((formData.accredited as string) !== "true") {
      return {
        validationErrors: {
          accredited: "You must be an accredited investor."
        }
      };
    }

    try {
      await schema.validate(data, { abortEarly: false });
      const response = await commitToDeal(data);
      return { response };
    } catch (error) {
      const response: RouteActionResponse<unknown> = {};
      if (error instanceof Yup.ValidationError) {
        response.validationErrors = getFormValidationErrors(error);
      } else {
        response.error = error;
      }

      return response;
    }
  } else if (request.method === "PUT") {
    try {
      await schema.validate(data, { abortEarly: false });
      const response = await updateDealCommitment(data);
      return { response };
    } catch (error) {
      const response: RouteActionResponse<unknown> = {};
      if (error instanceof Yup.ValidationError) {
        response.validationErrors = getFormValidationErrors(error);
      } else {
        response.error = error;
      }

      return response;
    }
  } else if (request.method === "DELETE") {
    try {
      const response = await deleteDealCommitment({ dealId: data.dealId });
      return { response };
    } catch (error) {
      const response: RouteActionResponse<unknown> = {};
      if (error instanceof Yup.ValidationError) {
        response.validationErrors = getFormValidationErrors(error);
      } else {
        response.error = error;
      }

      return response;
    }
  }
};
