import React, { useEffect } from "react";
import { motion } from "framer-motion";

import useNavigate from "src/util/useNavigate";
import { NavRoutes } from "src/routes/navRoutes";
import { colorPalette as colors, ColorNames } from "src/theme/theme";
import {
  RecipientsEmptyContainer,
  RecipientsEmptyStateIllustration,
  RecipientsExplainer,
  RSBackground,
  RSPlaceholderArrow,
  RSPlaceholderText,
  RecipientCards,
  StyledRecipientCard
} from "./Recipients.styles";
import { Button } from "src/components/button/Button";

import { ReactComponent as IconRight } from "src/assets/icons/chevron_right.svg";
import { ReactComponent as IconTransfer } from "src/assets/icons/transfer.svg";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { ActionableButtonGroup } from "src/components/actionable-button/ActionableButton.styles";
import { useGetRecipients } from "src/services/recipients";
import { Recipient } from "src/generated/client";
import { capitalizeTransferType, lastFour } from "../../util/stringUtils";
import { showNotice } from "src/store/alertState";
import { useNavbar } from "src/util/useNavbar";
import Layout from "src/components/core-layout/Layout";

const Recipients = (): JSX.Element => {
  useNavbar({ title: "Recipients" });
  const navigate = useNavigate();
  
  const { recipients, loading, error, refetch } = useGetRecipients();
  const hasRecipients = recipients && recipients.length > 0;

  useEffect(() => {
    if (error) {
      showNotice(error.message || "There was a problem loading recipients.", { error: true });
    }
  }, [error]);

  return (
    <Layout loading={loading}>
      {!loading && hasRecipients && (
        <>
          <ActionableButtonGroup noTopMargin>
            <ActionableButton
              label="Add New Recipient"
              color={ColorNames.PINE}
              iconRight={false}
              onClick={(): void => {
                navigate(NavRoutes.BANK_RECIPIENTS_NEW);
              }}
            />
          </ActionableButtonGroup>
          <RecipientCards>
            {recipients.map((recipient: Recipient) => (
              <RecipientCard
                key={recipient.accountHolderName + recipient.accountNumber}
                title={recipient.nickname || ""}
                last4={lastFour(recipient.accountNumber)}
                transferType={recipient.transferType}
                handleClick={() => navigate(`${NavRoutes.BANK_RECIPIENTS}/${recipient.id}`)}
              />
            ))}
          </RecipientCards>
        </>
      )}

      {!loading && !hasRecipients && <EmptyState />}
    </Layout>
  );
};

export default Recipients;

const RecipientCard = (props: { handleClick: () => void; title: string; last4: string; transferType: string }) => {
  return (
    <StyledRecipientCard onClick={props.handleClick}>
      <span className="rc-title-row">
        <span className="rc-title">{props.title}</span>
        <span className="rc-icon">
          <IconRight />
        </span>
      </span>
      <span className="rc-info-row">
        <div className="rc-last4">{props.last4}</div>
        <div className="rc-transfer-type">
          <span className="rc-transfer-type-icon">
            <IconTransfer />
          </span>
          <span>{capitalizeTransferType(props.transferType)}</span>
        </div>
      </span>
    </StyledRecipientCard>
  );
};

const EmptyState = () => {
  const navigate = useNavigate();

  return (
    <RecipientsEmptyContainer>
      <RecipientsExplainer>
        <h2>Easily manage sending money</h2>
        <p>
          Add external accounts to which you frequently send money to. Set up Wire and ACH recipients, then send money
          with a tap. You’ll also see recent transfers to and from these accounts.
        </p>
      </RecipientsExplainer>
      <RecipientsEmptyStateIllustration>
        <RecipientSkeleton index={1} />
        <RecipientSkeleton index={2} />
        <RecipientSkeleton index={3} />
        <RecipientSkeleton index={4} />
        <RecipientSkeleton index={5} />
        <RecipientSkeleton index={6} />
      </RecipientsEmptyStateIllustration>
      <Button 
        raised
        color={ColorNames.MIDNIGHT}
        onClick={() => {
          navigate(NavRoutes.BANK_RECIPIENTS_NEW);
        }}
      >
        Add a recipient
      </Button>
    </RecipientsEmptyContainer>
  );
};

const RecipientSkeleton = (props: { index: number }) => {
  const middleChild = props.index === 3;

  return (
    <motion.div {...skeletonMotion}>
      <RSBackground
        initial={{
          scale: 1
        }}
        animate={{
          scale: middleChild ? [1, 0.98, 1] : 1
        }}
        transition={{
          delay: 1,
          repeatDelay: 2.75,
          duration: 0.25,
          ease: "linear",
          repeat: Infinity
        }}
      >
        <RSPlaceholderText
          style={{
            gridColumn: "1 / 3"
          }}
        />
        <RSPlaceholderArrow>
          <IconRight />
        </RSPlaceholderArrow>
        <RSPlaceholderText />
        <span
          style={{
            textAlign: "right",
            position: "relative",
            top: 2,
            right: -8,
            color: colors[ColorNames.TEA].hex
          }}
        >
          <IconTransfer />
        </span>
        <RSPlaceholderText
          style={{
            gridColumn: "3 / -1"
          }}
        />
      </RSBackground>
    </motion.div>
  );
};

const skeletonMotion = {
  initial: {
    y: 0
  },
  animate: {
    y: "calc(-100% - 16px)"
  },
  transition: {
    duration: 3,
    ease: "linear",
    repeat: Infinity
  }
};
