import { bezierMenu, bezierMenuExpo } from "src/theme/theme";
import styled from "styled-components";

export const StyledMenuContent = styled.div`
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
  background: white;
  position: relative;
  z-index: 2;
  transform: translateX(0);
  transition: transform 0.4s ${bezierMenu};
  @supports (-webkit-touch-callout: none) {
    transition: transform 0.4s ${bezierMenuExpo};
  }
  will-change: transform;
  @media (min-width: 900px) {
    grid-column: 2 / -1;
  }

  &.show-menu {
    @media (max-width: 767px) {
      transform: translateX(300px);
      position: fixed;
      overflow-y: hidden;
    }
  }
`;
