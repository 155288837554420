import React, { useEffect, useMemo } from "react";
import { useSetBottomSheet } from "src/util/useBottomSheet";

import {
  ActionSheetIcon,
  ActionSheetTitle,
  ActionSheetDescription,
  ButtonsContainer,
  SecondaryButtonContainer
} from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet.styles";
import { Button } from "src/components/button/Button";
import { CodeInput } from "src/components/forms/code-input/CodeInput";
import { BrandColor, ColorNames } from "src/theme/theme";

import { ReactComponent as IconCheck } from "src/assets/icons/check.svg";

export interface ActionBottomSheetProps {
  id: string;
  success?: boolean;
  title?: string;
  description?: JSX.Element | string;
  actionText: string;
  actionColor?: BrandColor | ColorNames.TRANSPARENT;
  onAction: (...args: any[]) => unknown;
  secondaryActionText?: string;
  onSecondaryAction?: (...args: any[]) => unknown;
  codeInput?: boolean;
  dataCy?: string;
  mfa?: boolean;
  autoSubmit?: boolean;
  actionLoading?: boolean;
  secondaryActionLoading?: boolean;
  children?: JSX.Element;
  inline?: boolean;
  primaryActionTag?: string | undefined;
  secondaryActionTag?: string | undefined;
  onClose?: () => void;
  closeOnOutsideClick?: boolean;
}

export const ActionBottomSheet = ({
  id,
  success = false,
  title,
  description,
  actionText,
  actionColor,
  secondaryActionText,
  onAction,
  onSecondaryAction,
  codeInput,
  mfa,
  dataCy,
  autoSubmit,
  actionLoading,
  secondaryActionLoading,
  children,
  inline = false,
  primaryActionTag = undefined,
  secondaryActionTag = undefined,
  onClose,
  closeOnOutsideClick = true
}: ActionBottomSheetProps): JSX.Element => {
  const setBottomSheet = useSetBottomSheet();

  const bottomSheetChildren: JSX.Element = useMemo(() => {
    return (
      <>
        {success && (
          <ActionSheetIcon backgroundColor={ColorNames.GRASS} color={ColorNames.LIGHT_LIME}>
            <IconCheck width="32px" height="32px" />
          </ActionSheetIcon>
        )}
        {!!title && (
          <ActionSheetTitle color={success ? ColorNames.GRASS : undefined}>{title}</ActionSheetTitle>
        )}
        {!!description && <ActionSheetDescription>{description}</ActionSheetDescription>}

        {(codeInput || mfa) && (
          <CodeInput
            onSubmit={onAction}
            actionText={actionText}
            mfa={mfa}
            dataCy={dataCy}
            autoSubmit={autoSubmit}
            actionLoading={actionLoading}
            secondaryActionText={secondaryActionText}
            onSecondaryAction={onSecondaryAction}
            secondaryActionLoading={secondaryActionLoading}
            secondaryActionTag={secondaryActionTag}
          />
        )}

        {children && <>{children}</>}

        {!codeInput && !mfa && (
          <ButtonsContainer>
            <Button
              raised
              color={actionColor || ColorNames.MIDNIGHT}
              data-cy={primaryActionTag}
              onClick={onAction}
              loading={!!actionLoading}
            >
              {actionText}
            </Button>
            {!!onSecondaryAction && !!secondaryActionText && (
              <SecondaryButtonContainer>
                <Button
                  color={ColorNames.TRANSPARENT}
                  data-cy={secondaryActionTag}
                  onClick={onSecondaryAction}
                  loading={!!secondaryActionLoading}
                >
                  {secondaryActionText}
                </Button>
              </SecondaryButtonContainer>
            )}
          </ButtonsContainer>
        )}
      </>
    );
    // eslint-disable-next-line
  }, [title, description, children, actionLoading, secondaryActionLoading, actionText, secondaryActionText]);

  useEffect(() => {
    setBottomSheet({
      id,
      children: bottomSheetChildren,
      inline,
      backgroundColor: success ? ColorNames.LIGHT_LIME : undefined,
      onClose,
      closeOnOutsideClick
    });
  }, [bottomSheetChildren, id, inline, setBottomSheet, onClose, success, closeOnOutsideClick]);

  return <></>;
};
