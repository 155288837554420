import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useMotionValue, PanInfo } from "framer-motion";

import { SliderInputContainer, SliderContainer, SliderTrackContainer, SliderTrack, SliderHandle } from "./SliderCurrencyInput.styles";

import { CurrencyInput, CurrencyInputProps } from "./CurrencyInput";

interface SliderInputProps extends CurrencyInputProps {
  startingAmount: number;
  minimumAmount: number;
  maximumAmount: number;
  onAmountChange: (amount: number) => void;
  increment?: number;
}

export const SliderCurrencyInput = ({
  startingAmount,
  minimumAmount,
  maximumAmount,
  onAmountChange,
  increment = 50000,
  ...rest
}: SliderInputProps) => {
  const [amount, setAmount] = useState(startingAmount);

  const [trackWidth, setTrackWidth] = useState(0);
  const x = useMotionValue(0);
  const [isDragging, setIsDragging] = useState(false);
  const trackRef = useRef<any>();
  const dragConstraintsRef = useRef(null);

  useLayoutEffect(() => {
    if (!!trackRef.current) {
      setTrackWidth(trackRef.current.clientWidth);
      
      const ratio = Math.max(0, Math.min((amount - minimumAmount) / (maximumAmount - minimumAmount), 1));

      x.set(trackRef.current.clientWidth * ratio);
    }
  }, []);

  useEffect(() => {
    if (!isDragging)
      onAmountChange(amount);
  }, [amount, isDragging]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { left } = trackRef.current.getBoundingClientRect();
    const position = event.clientX - left;
    
    x.set(Math.max(0, Math.min(trackWidth, position)));
    updateAmountFromPosition(position);
  }

  const handleDrag = (_: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    const { left } = trackRef.current.getBoundingClientRect();
    const position = info.point.x - left;

    updateAmountFromPosition(position);
  }

  const updateAmountFromPosition = (position: number) => {
    const ratio = Math.max(0, Math.min(1, position / trackWidth));
    const newAmount = ((maximumAmount - minimumAmount) * ratio) + minimumAmount;
    const roundedAmount = Math.ceil(newAmount / increment) * increment;

    setAmount(roundedAmount);
  }

  const onChange = (value: string) => {
    const newAmount = parseFloat(value.replace(/,/g, "")) * 100;
    const ratio = Math.max(0, Math.min((newAmount - minimumAmount) / (maximumAmount - minimumAmount), 1));

    x.set(trackWidth * ratio);

    if (!isDragging)
      onAmountChange(newAmount);
  }

  return <SliderInputContainer>
    <CurrencyInput {...rest} defaultValue={amount} onChange={onChange} />
    <SliderContainer>
      <SliderTrackContainer ref={dragConstraintsRef} onClick={handleClick}>
        <SliderTrack ref={trackRef} />
        <SliderHandle
          drag="x"
          dragConstraints={dragConstraintsRef}
          dragElastic={0}
          dragMomentum={false}
          onDrag={handleDrag}
          onDragStart={() => setIsDragging(true)}
          onDragEnd={() => setIsDragging(false)}
          style={{ x }}
        />
      </SliderTrackContainer>
    </SliderContainer>
  </SliderInputContainer>;
}