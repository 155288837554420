import styled from "styled-components";
import { Field } from "formik";

import { colorPalette as colors, ColorNames } from "src/theme/theme";
import { fieldStyle } from "src/components/forms";

export const StyledCodeInput = styled(Field)`
  ${fieldStyle}
  width: 54px;
  height: 72px;
  flex-direction: row;
  border: 1.5px solid ${colors[ColorNames.GRAY3].hex};
  border-radius: 0;
  text-align: center;
  font-weight: bold;
  font-size: 50px;
  line-height: 140%;
  color: transparent;
  text-shadow: 0 0 0 ${colors[ColorNames.MIDNIGHT].hex};
  border-right-color: transparent;
  transform: scaleX(1.03);

  /* Remove arrows from number input field */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  &:focus-within {
    border: 1.5px solid ${colors[ColorNames.LAKE].hex};
    z-index: 1;
  }

  &::placeholder {
    color: ${colors[ColorNames.GRAY3].hex};
    text-shadow: none;
  }

  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1.5px solid ${colors[ColorNames.GRAY3].hex};

    &:focus-within {
      border: 1.5px solid ${colors[ColorNames.LAKE].hex};
    }
  }
`;

export const StyledCodeInputFormContainer = styled.div`
  display: flex;
  margin-bottom: 48px;
`;
