import React, { useState, useMemo } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useCurrentUser } from "src/services/users";

import {
  formatPercentage,
  formatPhoneNumber,
  zipCodeFormatRegex,
} from "src/util/stringUtils";
import { BeneficialOwner } from "src/generated/client";
import { OwnerTitle } from "src/types/organization";
import States from "src/data/states.json";

import { Button } from "src/components/button/Button";
import { BackNav } from "src/components/nav-bars/back-nav/BackNav";
import { Input } from "src/components/forms/input/Input";
import { MultiInput } from "src/components/forms/input/MultiInput";
import { Select } from "src/components/forms/select/FormikSelect";
import { Form } from "src/components/forms/Form";
import { StyledFormInputContainer, InputRow } from "src/components/forms";

import { colorPalette as colors, ColorNames } from "src/theme/theme";
import {
  AddBusinessOwnerPage,
  BusinessTermsCopy,
} from "src/pages/auth/onboarding/business-info/BusinessOwners.styles";

import { ReactComponent as IconTrash } from "src/assets/icons/trash.svg";

const BusinessOwnerSchema: Yup.ObjectSchema<object> = Yup.object().shape({
  firstName: Yup.string().required("Please add your first name."),
  lastName: Yup.string().required("Please add your last name."),
  ownershipPercentage: Yup.number()
    .transform((value, originalValue) =>
      parseInt(originalValue.replace(/[^\d]/g, ""))
    )
    .max(100, "Ownership cannot exceed 100%"),
  title: Yup.string().required("Please select a job title."),
  phone: Yup.string()
    .min(14, "Please use a valid phone number.")
    .required("Please add your phone."),
  email: Yup.string()
    .email("Please use a valid email address.")
    .required("Please add your email."),
  dob: Yup.string()
    .min(10, "Please use a valid date of birth (MM/DD/YYYY).")
    .required("Please add your date of birth."),
  ssn: Yup.string()
    .min(11, "Please use a valid SSN.")
    .required("Please add your SSN."),
  ssnConfirm: Yup.string().oneOf(
    [Yup.ref("ssn"), null],
    "Your SSNs do not match."
  ),
  streetAddress: Yup.string().required("Please add an address."),
  city: Yup.string().required("Please add a city."),
  state: Yup.string().required("Please add a state."),
  zip: Yup.string()
    .matches(zipCodeFormatRegex, "Please use a valid zip code.")
    .required("Please add a zip code."),
});

const AddBusinessOwner = ({
  owner,
  handleSaveOwner,
  handleDeleteOwner,
  dismiss,
}: {
  owner: BeneficialOwner | null;
  handleSaveOwner: (owner: BeneficialOwner) => void;
  handleDeleteOwner: (owner: BeneficialOwner) => void;
  dismiss: () => void;
}) => {
  const currentUser = useCurrentUser();
  const [prevOwnership, setPrevOwnership] = useState("");
  const [prevPhoneValue, setPrevPhoneValue] = useState("");

  const handleOwnershipPercentage = (
    event: React.ChangeEvent<HTMLInputElement>,
    forceFormatting?: boolean
  ): string => {
    const formattedNumber = formatPercentage(
      event.target.value,
      forceFormatting ? undefined : prevOwnership
    );
    setPrevOwnership(formattedNumber);
    return formattedNumber;
  };

  const handlePhoneNumber = (
    event: React.ChangeEvent<HTMLInputElement>
  ): string => {
    const formattedNumber = formatPhoneNumber(
      event.target.value,
      prevPhoneValue
    );
    setPrevPhoneValue(formattedNumber);
    return formattedNumber;
  };

  const ownerTitlesForDropdown = useMemo(() => {
    const titles = Object.values(OwnerTitle).map((title: string) => {
      return {
        label: title,
        value: title,
      };
    });
    titles.unshift({
      label: "Choose...",
      value: "",
    });
    return titles;
  }, []);

  const statesForDropdown = [
    {
      label: "Choose state",
      value: "",
    },
    ...States,
  ];

  const maxDOB = new Date();
  maxDOB.setFullYear(maxDOB.getFullYear() - 18);

  const initialFormValues: BeneficialOwner = useMemo(() => {
    return {
      id: owner?.id || "",
      firstName: owner?.firstName || "",
      lastName: owner?.lastName || "",
      email: owner?.email || "",
      phone: owner?.phone ? formatPhoneNumber(owner.phone) : "",
      dob: owner?.dob || "",
      ssn: owner?.ssn || "",
      ssnConfirm: owner?.ssn || "",
      streetAddress: owner?.streetAddress || "",
      city: owner?.city || "",
      state: owner?.state || "",
      zip: owner?.zip || "",
      ownershipPercentage: formatPercentage(owner?.ownershipPercentage || "0"),
      title: owner?.title || "",
      primaryOfficer: owner?.primaryOfficer || false,
      primaryContact: owner?.primaryContact || false,
    };
  }, [owner]);

  return (
    <>
      <BackNav
        title={
          !!owner?.title && owner?.title !== ""
            ? owner?.email === currentUser?.email
              ? "Edit Yourself"
              : "Edit Officer"
            : owner?.email === currentUser?.email
            ? "Add Yourself"
            : "New Officer"
        }
        onBackClick={dismiss}
        closeBackIcon={true} // TODO hiding the delete button for saved owners until fully supported server side
        actionIcon={
          owner?.title && !owner?.id ? (
            <IconTrash color={colors[ColorNames.CARDINAL].hex} />
          ) : undefined
        }
        onActionClick={
          owner?.title && !owner?.id
            ? () => handleDeleteOwner(owner)
            : undefined
        }
      />

      <AddBusinessOwnerPage>
        <Formik
          initialValues={initialFormValues}
          enableReinitialize={true}
          validationSchema={BusinessOwnerSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values): void => {
            "ssnConfirm" in values && delete (values as any)["ssnConfirm"];
            handleSaveOwner(values);
          }}
        >
          {({ handleChange, setFieldValue }): JSX.Element => (
            <Form>
              <>
                <StyledFormInputContainer>
                  {owner?.email === currentUser?.email ? (
                    <>
                      <Input
                        name="ownershipPercentage"
                        type="text"
                        label="Ownership Percentage"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                          handleChange(event);
                          setFieldValue(
                            "ownershipPercentage",
                            handleOwnershipPercentage(event)
                          );
                        }}
                        onBlur={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                          setFieldValue(
                            "ownershipPercentage",
                            handleOwnershipPercentage(event, true)
                          );
                        }}
                      />
                      <Select
                        name="title"
                        label="Job Title"
                        options={ownerTitlesForDropdown}
                      />
                    </>
                  ) : (
                    <>
                      <Input
                        name="firstName"
                        type="text"
                        label="First Name"
                        autoComplete="given-name"
                      />
                      <Input
                        name="lastName"
                        type="text"
                        label="Last Name"
                        autoComplete="family-name"
                      />
                      <Input
                        name="ownershipPercentage"
                        type="text"
                        label="Ownership Percentage"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                          handleChange(event);
                          setFieldValue(
                            "ownershipPercentage",
                            handleOwnershipPercentage(event)
                          );
                        }}
                        onBlur={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                          setFieldValue(
                            "ownershipPercentage",
                            handleOwnershipPercentage(event, true)
                          );
                        }}
                      />
                      <Select
                        name="title"
                        label="Job Title"
                        options={ownerTitlesForDropdown}
                      />
                      <Input
                        name="phone"
                        type="text"
                        label="Phone"
                        autoComplete="tel"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                          handleChange(event);
                          setFieldValue("phone", handlePhoneNumber(event));
                        }}
                      />
                      <Input
                        name="email"
                        type="email"
                        label="Email"
                        autoComplete="email"
                      />

                      <MultiInput
                        name="dob"
                        label="Date of Birth"
                        formatTo="$2-$0-$1"
                        separator="/"
                        inputs={[
                          { charsCount: 2, placeholder: "MM", type: "number" },
                          { charsCount: 2, placeholder: "DD", type: "number" },
                          {
                            charsCount: 4,
                            placeholder: "YYYY",
                            type: "number",
                          },
                        ]}
                      />
                      <MultiInput
                        name="ssn"
                        label="Social Security Number"
                        formatTo="$0-$1-$2"
                        separator="-"
                        doNotCapture
                        inputs={[
                          { charsCount: 3, placeholder: "000", type: "number" },
                          { charsCount: 2, placeholder: "00", type: "number" },
                          {
                            charsCount: 4,
                            placeholder: "0000",
                            type: "number",
                          },
                        ]}
                      />
                      <MultiInput
                        name="ssnConfirm"
                        label="Verify Social Security Number"
                        formatTo="$0-$1-$2"
                        doNotCapture
                        separator="-"
                        inputs={[
                          { charsCount: 3, placeholder: "000", type: "number" },
                          { charsCount: 2, placeholder: "00", type: "number" },
                          {
                            charsCount: 4,
                            placeholder: "0000",
                            type: "number",
                          },
                        ]}
                      />
                      <Input
                        name="streetAddress"
                        type="text"
                        label="Personal Street Address"
                        autocomplete="shipping street-address"
                      />
                      <Input
                        name="city"
                        type="text"
                        label="City"
                        autocomplete="shipping locality"
                      />
                      <InputRow>
                        <Select
                          name="state"
                          label="State"
                          options={statesForDropdown}
                          autocomplete="shipping region"
                        />
                        <Input
                          name="zip"
                          type="text"
                          label="ZIP Code"
                          autocomplete="shipping postal-code"
                        />
                      </InputRow>
                    </>
                  )}
                </StyledFormInputContainer>

                <BusinessTermsCopy>
                  By providing the information above, I hereby certify, to the
                  best of my knowledge, that the information provided above is
                  complete and correct.
                </BusinessTermsCopy>

                <Button
                  children={
                    !!owner?.title && owner?.title !== ""
                      ? "Save Officer"
                      : "Add Officer"
                  }
                  raised
                  type="submit"
                />
              </>
            </Form>
          )}
        </Formik>
      </AddBusinessOwnerPage>
    </>
  );
};

export default AddBusinessOwner;
