import styled from "styled-components/macro";
import { colorPalette as colors, ColorNames } from "src/theme/theme";
import { motion } from "framer-motion";

export const EmptyAnimationArea = styled.div`
  position: relative;

  @media (min-width: 700px) {
    transform: scale(1.6);
  }
`;

export const AnimationStage = styled(motion.div)`
  display: grid;
  grid-template-rows: 20px repeat(3, 51px) 20px;
  grid-gap: 20px 0;
  align-items: center;
  transform-origin: center;

  .animation-row {
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;
  }
`;

export const FloatingCardContainer = styled(motion.div)`
  padding: 10px 14px;
  margin: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  width: 180px;
  display: flex;
  align-items: center;
  opacity: 0.4;
  transition: 1s 0.35s ease opacity;
  will-change: opacity;

  &.empty,
  &.empty.visible {
    opacity: 0.2;
  }

  &.visible {
    opacity: 1;
  }
`;

export const FloatingCardBody = styled.div`
  display: grid;
  grid-template-columns: 32px 1fr 48px;
  grid-gap: 6px;
  padding-left: 10px;
  width: 100%;

  div {
    height: 8px;
    border-radius: 3px;
    background-color: ${colors[ColorNames.PINE].hex};
  }

  .main-title {
    width: 91px;
    grid-column: 1 / -1;
  }

  .sub-title {
    opacity: 0.5;
  }

  .sub-title:last-child {
    grid-column: 3 / -1;
  }
`;
