import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { colorPalette as colors, ColorNames } from "src/theme/theme";

export const NavBarStepperContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 13px;
`;

export const StepperPill = styled.div<{ active: boolean }>`
  background-color: ${colors[ColorNames.GRAY3].hex};
  height: 6px;
  transition: height 0.25s, background-color 0.25s;
  width: 5px;
  border-radius: 2.5px;
  margin: 0 4px;

  ${({ active }): FlattenSimpleInterpolation | undefined => {
    if (active) {
      return css`
        height: 13px;
        background-color: ${colors[ColorNames.MIDNIGHT].hex};
      `;
    }
  }};
`;
