import React from "react";
import { Variants } from "framer-motion";
import {
  AccountCardWrapper,
  AccountCardContainer,
  AccountCardDetails,
  AccountCardFunds,
  AccountCardTitles,
  AccountCardName,
  AccountCardSubtitle,
  AccountCardNumber,
  AccountCardGraphContainer,
  AccountCardTypeIcon
} from "./AccountCard.styles";
import { ReactComponent as IconRight } from "src/assets/icons/chevron_right.svg";
import { BalanceGraph } from "src/components/balance-graph/BalanceGraph";
import { colorPalette as colors, ColorNames } from "src/theme/theme";
import { ReactComponent as IconBank } from "src/assets/icons/external_bank.svg";
import { ReactComponent as IconCard } from "src/assets/icons/card.svg";
import { ReactComponent as IconStocks } from "src/assets/icons/stocks.svg";
import { AccountBalancesGrouped } from "src/generated/client";

const variants: Variants = {
  hidden: {
    opacity: 0,
    x: "50%"
  },
  show: {
    opacity: 1,
    x: "0"
  }
};

export interface AccountCardProps {
  name: string;
  subtitle?: string;
  amount: string;
  lastFour?: string;
  image?: string;
  color?: string;
  onClick?: () => void;
  balances: AccountBalancesGrouped[];
  accountType?: string;
}

export const AccountCard = ({
  name,
  subtitle,
  amount,
  lastFour,
  image,
  color,
  onClick,
  balances,
  accountType
}: AccountCardProps): JSX.Element => {
  let accountTypeIcon: JSX.Element | undefined;
  if (accountType) {
    switch (true) {
      case accountType === "depository":
        accountTypeIcon = <IconBank />;
        break;
      case accountType === "credit":
        accountTypeIcon = <IconCard />;
        break;
      case accountType === "market":
        accountTypeIcon = <IconStocks />;
        break;
    }
  }

  return (
    <AccountCardWrapper variants={variants}>
      <AccountCardContainer onClick={onClick}>
        <AccountCardGraphContainer>
          <BalanceGraph
            playheadColor={colors[ColorNames.SEAFOAM].hex}
            lineColor={colors[ColorNames.SEAFOAM].hex}
            data={balances}
          />
        </AccountCardGraphContainer>
        <AccountCardTitles>
          <AccountCardName>
            {name}
            <IconRight />
          </AccountCardName>
          {subtitle && <AccountCardSubtitle>{subtitle}</AccountCardSubtitle>}
        </AccountCardTitles>
        <AccountCardDetails>
          <AccountCardNumber>
            {/* {image && <Avatar name={name} image={image} color={color} size={40} shadow />} */}
            {accountTypeIcon && <AccountCardTypeIcon>{accountTypeIcon}</AccountCardTypeIcon>}
            <span className="last-four">{lastFour}</span>
          </AccountCardNumber>
          <AccountCardFunds>{amount}</AccountCardFunds>
        </AccountCardDetails>
      </AccountCardContainer>
    </AccountCardWrapper>
  );
};
