import { atom } from "recoil";
import { makeVar } from "@apollo/client";

export interface DeviceState {
  deviceToken: string | null;
}

export const deviceState = atom<DeviceState>({
  key: "deviceState",
  default: {
    deviceToken: null
  }
});

export const docusignSigningCompleted = makeVar(false);