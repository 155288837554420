import React, { useEffect, useMemo } from "react";
import { useSetBottomSheet } from "src/util/useBottomSheet";

import {
  TermsSheetDescription,
  ScrollText
} from "src/components/bottom-sheets/terms-bottom-sheet/TermsBottomSheet.styles";
import { CypressTestIds } from "src/util/testing-util/test-utils";
import { Button } from "src/components/button/Button";

export interface TermsBottomSheetProps {
  id: string;
  children: JSX.Element | string | null;
  actionText?: string;
  onAction: (...args: any[]) => unknown;
  loading?: boolean;
  hideScrollTip?: boolean;
  dataCy?: string;
}

export const TermsBottomSheet = ({
  id,
  children,
  actionText,
  onAction,
  loading,
  hideScrollTip = false,
  dataCy = CypressTestIds.SIGN_UP_TERMS_BTN
}: TermsBottomSheetProps): JSX.Element => {
  const setBottomSheet = useSetBottomSheet();

  const bottomSheetChildren: JSX.Element = useMemo(() => {
    return (
      <>
        {!hideScrollTip && <ScrollText>Please scroll down to read and agree to the terms.</ScrollText>}
        <TermsSheetDescription>
          {children}
          <Button raised data-cy={dataCy} onClick={onAction} loading={loading}>
            {actionText || "Agree and Continue"}
          </Button>
        </TermsSheetDescription>
      </>
    );
  }, [children, loading, onAction]);

  useEffect(() => {
    setBottomSheet({ id, children: bottomSheetChildren });
  }, [bottomSheetChildren, id, setBottomSheet]);

  return <></>;
};
