import React, { useEffect } from "react";
import { useFetcher } from "react-router-dom";

import { useNavbar } from "src/util/useNavbar";
import { NavRoutes } from "src/routes/navRoutes";
import { Deal } from "src/generated/client";

import ErrorElement from "src/ErrorElement";
import Layout from "src/components/core-layout/Layout";
import { SmallListing } from "src/components/listings/small-listing/SmallListing";
import { FeatureListing } from "src/components/listings/feature-listing/FeatureListing";
import { ReactComponent as IconInfo } from "src/assets/icons/info.svg";
import { ReactComponent as ChevronRight } from "src/assets/icons/chevron_right.svg";
import { BannerNotice } from "src/components/alert/BannerNotice";
import { ColorNames } from "src/theme/theme";

import {
  StyledBrowseContent,
  StyledBrowseHeading,
  ListingsWrapper
} from "./Listings.styles";

export const ListingsErrorElement = () => {
  return <ErrorElement redirect={NavRoutes.BANK_DASHBOARD} alert={true} />;
};

const Listings = () => {
  const { data: deals, state: fetcherState, ...fetcher } = useFetcher<Deal[]>();

  useNavbar({ title: "Browse" });

  useEffect(() => {
    if (!deals && fetcherState === "idle") fetcher.load(NavRoutes.API_DEAL);
  }, [deals, fetcherState, fetcher]);

  return (
    <Layout loading={fetcherState === "loading"}>
      <StyledBrowseContent>
        <StyledBrowseHeading>
          <h4>Featured Offerings</h4>
          <p>Exclusive opportunities brought directly to you.</p>
        </StyledBrowseHeading>

        <ListingsWrapper>
          {deals?.map((deal: Deal) => (
            <SmallListing
              id={deal.id}
              name={deal.name}
              caption={deal.caption || ""}
              logo={`/marketplace/tile-logos/${deal.name.toLowerCase()}.png`}
            />
          ))}
        </ListingsWrapper>

        <FeatureListing
          id="startups-id-here"
          name="Startup Private Equity"
          caption="Buy stock in your favorite startups, before they go public. Letter has access to private equity in the very best startups."
          listings={
            <>
            <SmallListing
              id="1"
              name="Chime"
              logo={`/marketplace/tile-logos/chime.png`}
              compact
            />
            <SmallListing
              id="2"
              name="Discord"
              logo={`/marketplace/tile-logos/discord.png`}
              compact
            />
            <SmallListing
              id="3"
              name="Impossible Foods"
              logo={`/marketplace/tile-logos/impossiblefoods.png`}
              compact
            />
            <SmallListing
              id="4"
              name="Brex"
              logo={`/marketplace/tile-logos/brex.png`}
              compact
            />
            <SmallListing
              id="5"
              name="Epic Games"
              logo={`/marketplace/tile-logos/epicgames.png`}
              compact
            />
            <SmallListing
              id="6"
              name="Airtable"
              logo={`/marketplace/tile-logos/airtable.png`}
              compact
            />
            <SmallListing
              id="7"
              name="Kraken"
              logo={`/marketplace/tile-logos/kraken.png`}
              compact
            />
            </>
          }
        />

        <BannerNotice 
          iconLeft={<IconInfo />} 
          iconRight={<ChevronRight />} 
          textColor={ColorNames.COBALT} 
          iconColor={ColorNames.BABY} 
          backgroundColor={ColorNames.GRAY1}
          onClick={() => window.location.href = "mailto:marketplace@letter.co?subject=I have a deal for the Marketplace"}
        >
          <>
            Have a deal you'd like to put in the Marketplace? Get in touch.
          </>
        </BannerNotice>
      </StyledBrowseContent>
    </Layout>
  );
};

export default Listings;

//
// Saving for later
//
// <LargeListing
// id={deal.id}
// name={deal.name}
// caption={deal.caption}
// logo={<WelcomefundLogo />}
// backgroundImage={WelcomefundBG}
// listItems={
//   <>
//     <InfoListItem
//       title="Total Raising"
//       value={decorateCurrencyValue(deal.targetAmount.display)}
//       valueType="number"
//       size="small"
//     />
//     <InfoListItem
//       title="Min. Investment"
//       value={decorateCurrencyValue(deal.minInvestment.display)}
//       valueType="number"
//       size="small"
//     />
//     <InfoListItem
//       title="Targeted Total Return"
//       value={`${deal.targetedTotalReturn.display}%`}
//       valueType="number"
//       size="small"
//     />
//     <InfoListItem title="Term Length" value={deal.termLength} valueType="number" size="small" />
//   </>
// }
// />