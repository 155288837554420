import React from "react";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { AccountTransactionStatuses } from "src/types/account";

import { Row, RowContent, RowLabel, TransferSummary } from "src/routes/app/bank/transfer/Transfer.styles";

import { TransactionDetailContainer, TransactionTitle } from "src/pages/transactions/TransactionDetail.styles";

import { ColorNames } from "src/theme/theme";
import { SecondaryButtonContainer } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet.styles";
import { Button } from "src/components/button/Button";
import { Transaction } from "src/generated/client";
import { decorateCurrencyValue, formatTransactionDate } from "src/util/stringUtils";

const TransactionDetail = ({ transaction }: { transaction?: Transaction }): JSX.Element => {
  const { hideBottomSheet } = useShowBottomSheet();
  

  const transactionType = transaction?.transactionType ? transaction.transactionType.toLocaleLowerCase() : "";
  let transactionViaText;
  switch (true) {
    case transactionType.includes("wire"):
      transactionViaText = "Wire";
      break;
    case transactionType.includes("card") ||
      transactionType.includes("purchase") ||
      transactionType.includes("atm") ||
      transactionType.includes("spend"):
      transactionViaText = "Card";
      break;
    case transactionType.includes("ach"):
      transactionViaText = "ACH";
      break;
    case transactionType.includes("book") || transactionType.includes("transfer"):
      transactionViaText = "Account Transfer";
      break;
    case transactionType.includes("feetransaction"):
      transactionViaText = "Fee";
      break;
  }

  const transactionStatus = transaction?.status;
  let transactionStatusText;
  switch (true) {
    case transactionStatus === AccountTransactionStatuses.SETTLED:
      transactionStatusText = "Completed";
      break;
    case transactionStatus === AccountTransactionStatuses.PENDING ||
      transactionStatus === AccountTransactionStatuses.CLEARING ||
      transactionStatus === AccountTransactionStatuses.INITIATED ||
      transactionStatus === AccountTransactionStatuses.HELD ||
      transactionStatus === AccountTransactionStatuses.SENT:
      transactionStatusText = "Pending";
      break;
    case transactionStatus === AccountTransactionStatuses.DECLINED ||
      transactionStatus === AccountTransactionStatuses.REJECTED ||
      transactionStatus === AccountTransactionStatuses.DENIED:
      transactionStatusText = "Declined";
      break;
    case transactionStatus === AccountTransactionStatuses.CANCELED:
      transactionStatusText = "Canceled";
      break;
  }

  // if (transactionViaText === 'Card' && transaction?.last4) {
  //   transactionViaText = `${transactionViaText} - ${transaction?.last4}`;
  // }

  return (
    <TransactionDetailContainer>
      <TransactionTitle>{transaction?.merchantDescription}</TransactionTitle>
      <TransferSummary>
        {transaction?.transactionType === "wire" && (
          <Row>
            <RowLabel>Direction</RowLabel>
            <RowContent>{transaction?.spendType?.toLocaleLowerCase() === "credit" ? "Received" : "Sent"}</RowContent>
          </Row>
        )}
        <Row>
          <RowLabel>Amount</RowLabel>
          <RowContent>{decorateCurrencyValue(transaction?.amount?.display)}</RowContent>
        </Row>
        {transactionViaText && (
          <Row>
            <RowLabel>Transaction via</RowLabel>
            <RowContent>{transactionViaText}</RowContent>
          </Row>
        )}
        {transactionStatusText && (
          <Row>
            <RowLabel>Status</RowLabel>
            <RowContent>{transactionStatusText}</RowContent>
          </Row>
        )}
        <Row>
          <RowLabel>Time</RowLabel>
          <RowContent>{formatTransactionDate(transaction?.transactionDate)}</RowContent>
        </Row>
        {transaction?.merchantDescription && (
          <Row>
            <RowLabel>Merchant</RowLabel>
            <RowContent>{transaction.merchantDescription}</RowContent>
          </Row>
        )}
        {/* {transaction?.wireDescription && (
          <Row>
            <RowLabel>Message to recipient bank</RowLabel>
            <RowContent>{transaction?.wireDescription}</RowContent>
          </Row>
        )} */}
      </TransferSummary>
      <SecondaryButtonContainer>
        <Button
          color={ColorNames.TRANSPARENT}
          onClick={() => {
            hideBottomSheet("transaction_item_sheet");
          }}
          loading={false}
        >
          Close
        </Button>
      </SecondaryButtonContainer>
    </TransactionDetailContainer>
  );
};

export default TransactionDetail;
