import styled from "styled-components";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

export const AccreditedSheetTitle = styled.p`
  width: 100%;
  ${fonts.HEADING_4}
  color: ${colors[ColorNames.MIDNIGHT].hex};
  margin-bottom: 32px;
  text-align: center;
`;

export const AccreditedSheetDescription = styled.p`
  ${fonts.PARAGRAPH_M};
  margin-bottom: 40px;
  width: 100%;
  color: ${colors[ColorNames.MIDNIGHT].hex};
  white-space: pre-wrap;
  text-align: center;

  a {
    color: ${colors[ColorNames.LAKE].hex};
    text-decoration: none;
  }
`;
