import React, { useEffect, useState, useMemo } from "react";
import { useReactiveVar } from "@apollo/client";
import { AnimatePresence } from "framer-motion";
import useNavigate from "src/util/useNavigate";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import classnames from "classnames";

import {
  CardsWrapper,
  CardsPageContent,
  EmptyCardsInfo,
  CardsInfoContainer,
  CardsInfoContent,
  CardInfo,
  CardsContainer,
  CardsPageActions,
} from "./Cards.styles";
import {
  ParagraphLarge,
  ParagraphSmall,
  Heading5,
} from "src/routes/app/App.styles";
import { ActionSheetCenteredContainer } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet.styles";

import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import { DebitCard } from "src/components/debit-card/DebitCard";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { Button } from "src/components/button/Button";
import {
  SegmentedControl,
  SegmentedControlOption,
} from "src/components/segmented-control/SegmentedControl";
import { NavRoutes } from "src/routes/navRoutes";
import { createdCardVar } from "src/store/cardsState";
import { ColorNames } from "src/theme/theme";
import { CardTokenBottomSheet } from "src/components/cards/CardTokenBottomSheet";
import { CardActivateSheet } from "src/components/cards/CardActivateSheet";
import {
  LayoutInnerWidthWrap,
  LayoutInnerContent,
} from "src/components/core-layout/CoreLayout.styles";
import { Card, CardStatusType, CardType } from "src/generated/client";

enum CardFilter {
  ALL = "All",
  IN_USE = "In Use",
  PHYSICAL = "Physical",
  VIRTUAL = "Virtual",
  UNACTIVATED = "Unactivated",
  CLOSED = "Closed",
}

const Cards = ({
  accountId,
  cards,
}: {
  accountId?: string;
  cards: Card[];
}): JSX.Element => {
  const [cardFilter, setCardFilter] = useState<CardFilter>(
    !accountId ? CardFilter.ALL : CardFilter.IN_USE
  );
  const [selectedCard, setSelectedCard] = useState<Card>();
  const createdCard = useReactiveVar(createdCardVar);
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();
  const navigate = useNavigate();

  const cardFilterOptions: SegmentedControlOption[] = useMemo(() => {
    return [
      {
        label: "In Use",
        count: cards.filter(
          (card: Card) =>
            card.status !== CardStatusType.ClosedByCustomer &&
            card.status !== CardStatusType.Inactive
        ).length,
        value: CardFilter.IN_USE,
      },
      {
        label: "Physical",
        count: cards.filter((card: Card) => card.type === CardType.Physical)
          .length,
        value: CardFilter.PHYSICAL,
      },
      {
        label: "Virtual",
        count: cards.filter((card: Card) => card.type === CardType.Virtual)
          .length,
        value: CardFilter.VIRTUAL,
      },
      {
        label: "Unactivated",
        count: cards.filter(
          (card: Card) => card.status === CardStatusType.Inactive
        ).length,
        value: CardFilter.UNACTIVATED,
      },
      {
        label: "Closed",
        count: cards.filter(
          (card: Card) => card.status === CardStatusType.ClosedByCustomer
        ).length,
        value: CardFilter.CLOSED,
      },
    ];
  }, [cards]);

  useEffect(() => {
    if (createdCard.newCard) showBottomSheet("created_card_sheet");
  }, [createdCard, showBottomSheet]);

  useEffect(() => {
    if (!accountId) return;
    const option: SegmentedControlOption | undefined = cardFilterOptions.find(
      (o) => o.count && o.count > 0
    );
    if (!!option) setCardFilter(option.value as CardFilter);
  }, [cardFilterOptions]);

  const filteredCards: Card[] = useMemo(() => {
    return cards.filter((card: Card) => {
      if (
        cardFilter === CardFilter.IN_USE &&
        (card.status === CardStatusType.ClosedByCustomer || card.status === CardStatusType.Inactive)
      ) return null;
      else if (cardFilter === CardFilter.CLOSED && card.status !== CardStatusType.ClosedByCustomer) return null;
      else if (cardFilter === CardFilter.UNACTIVATED && card.status !== CardStatusType.Inactive) return null;
      else if (
        (cardFilter === CardFilter.PHYSICAL || cardFilter === CardFilter.VIRTUAL) &&
        card.type?.toString() !== cardFilter.toString()
      ) return null;

      return card;
    }) 
  }, [cards, cardFilter]);

  const onActivate = (card: Card) => {
    navigate(`${NavRoutes.BANK_ACCOUNTS}/${card.accountId}/cards/${card.id}`);
  };

  const createFirstCardView = (
    <CardsWrapper>
      <LayoutInnerContent>
        <CardsPageContent>
          <EmptyCardsInfo>
            <ParagraphLarge>Customizable Debit Cards</ParagraphLarge>
            <ParagraphSmall>
              Create up to 10 physical and up to 20 virtual cards. Each can have
              its own configurable limit, spending rules, and card holder.
            </ParagraphSmall>
          </EmptyCardsInfo>
        </CardsPageContent>
        <CardsInfoContainer>
          <CardsInfoContent>
            <CardInfo
              physical
              onClick={() =>
                navigate(
                  `${NavRoutes.BANK_ACCOUNTS}/${accountId}/cards/new?cardType=Physical`
                )
              }
            >
              <Heading5>Physical Cards</Heading5>
              <ParagraphSmall>
                After creating a physical card, we'll ship it directly to you.
                Use it anywhere Visa is accepted, and add it to Apple Pay and
                Google Pay.
              </ParagraphSmall>
            </CardInfo>
            <CardInfo
              onClick={() =>
                navigate(
                  `${NavRoutes.BANK_ACCOUNTS}/${accountId}/cards/new?cardType=Virtual`
                )
              }
            >
              <Heading5>Virtual Cards</Heading5>
              <ParagraphSmall>
                Use virtual cards anywhere Visa is accepted, and add them to
                Apple Pay and Google Pay. A virtual card is never shipped to
                you, but you'll have access to the card number from your Letter
                account.
              </ParagraphSmall>
            </CardInfo>
          </CardsInfoContent>
        </CardsInfoContainer>

        <CardsPageActions>
          <Button
            children={"Create a Card"}
            raised
            onClick={() =>
              navigate(`${NavRoutes.BANK_ACCOUNTS}/${accountId}/cards/new`)
            }
          />
        </CardsPageActions>
      </LayoutInnerContent>
    </CardsWrapper>
  );

  const cardsView = (
    <CardsWrapper>
      {!!accountId && (
        <LayoutInnerWidthWrap>
          <CardsPageActions>
            <ActionableButton
              color={ColorNames.PINE}
              label="Create New Card"
              onClick={() =>
                navigate(`${NavRoutes.BANK_ACCOUNTS}/${accountId}/cards/new`)
              }
              iconRight={false}
            />
          </CardsPageActions>

          <SegmentedControl
            options={cardFilterOptions}
            active={cardFilter}
            onClick={(value) => setCardFilter(value as CardFilter)}
          />
        </LayoutInnerWidthWrap>
      )}
      <CardsContainer className={classnames({ "more-than-two": filteredCards.length > 2, "more-than-three": filteredCards.length > 3})}>
        <AnimatePresence exitBeforeEnter initial={false}>
          {filteredCards.map((card) => (
              <DebitCard
                key={card.id}
                card={card}
                viewOnly={!accountId}
                onClick={(): void => {
                  if (
                    !!accountId &&
                    card.type === CardType.Physical &&
                    card.status === CardStatusType.Inactive
                  ) {
                    setSelectedCard(card);
                    showBottomSheet("card_activate_sheet");
                  } else {
                    navigate(
                      `${NavRoutes.BANK_ACCOUNTS}/${card.accountId}/cards/${card.id}`
                    );
                  }
                }}
              />
            )
          )}
        </AnimatePresence>
      </CardsContainer>

      <ActionBottomSheet
        id="created_card_sheet"
        success={true}
        title="Card Created"
        actionText="Close"
        actionColor={ColorNames.TRANSPARENT}
        onAction={(): void => {
          hideBottomSheet();
          createdCardVar({ newCard: false, cardType: CardType.Virtual });
        }}
        onClose={() => {
          hideBottomSheet();
          createdCardVar({ newCard: false, cardType: CardType.Virtual });
        }}
        children={
          <ActionSheetCenteredContainer>
            {createdCard.cardType === CardType.Physical ? (
              <>
                Your card has been created and is being shipped to your address.
                <br />
                <br />
                You should receive your card in 7-10 days. Once you receive your
                card you must activate it to begin using it.
              </>
            ) : (
              <>
                Your virtual card has been created and is ready for immediate
                use.
              </>
            )}
          </ActionSheetCenteredContainer>
        }
      />

      <CardTokenBottomSheet
        id="card_activate_sheet"
        title="Activate Card"
        card={selectedCard}
        children={({ token, externalCardId }) => (
          <CardActivateSheet
            card={selectedCard}
            token={token}
            externalCardId={externalCardId}
            onActivate={onActivate}
          />
        )}
      />
    </CardsWrapper>
  );

  return <>{cards.length > 0 ? cardsView : createFirstCardView}</>;
};

export default Cards;
