import React, { useRef, useEffect, useState, useCallback } from "react";
import classnames from "classnames";
import debounce from "lodash/debounce";
import { useField } from "formik";
import { showNotice } from "src/store/alertState";
import { MINIMUM_PASSWORD_STRENGTH_SCORE } from "src";

import { StyledInput } from "src/components/forms/input/Input.styles";
import { StyledFieldContainer } from "src/components/forms";
import { StyledLabel } from "src/components/text";
import { DataTestIds } from "src/util/testing-util/test-utils";
import { useFormikContext } from "formik";
import { NewPasswordInputElements, StrengthIndicator } from "./NewPasswordInput.styles";

export interface NewPasswordInputProps {
  label?: string;
  updateFormScore: (data: number) => void;
  name: string;
  disabled?: boolean;
  controlledAlerts?: boolean;
  [key: string]: unknown;
}

const requestPasswordScore = async (pw: string) => {
  try {
    const response = await fetch("https://pw.letter.co/check", {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({ pw: pw })
    });

    const data = await response.json();
    return data.score;
  } catch (_) {
    return MINIMUM_PASSWORD_STRENGTH_SCORE;
  }
};

export const NewPasswordInput = ({
  label,
  updateFormScore,
  name,
  disabled,
  controlledAlerts = true,
  ...props
}: NewPasswordInputProps): JSX.Element => {
  const { errors: formErrors, submitCount } = useFormikContext();
  const [field, meta, helpers] = useField(name);
  const [score, setScore] = useState(0);
  
  const inputRef = useRef<HTMLInputElement>();

  const calculateScore = () => {
    const inputValue = inputRef?.current?.value;

    if (inputValue) {
      requestPasswordScore(inputValue).then((score) => setScore(score));
    } else {
      setScore(0);
    }
  };

  const debounceScoreCalc = useCallback(debounce(calculateScore, 250), []);

  useEffect(() => {
    if (!controlledAlerts && meta.touched && meta.error) {
      showNotice(meta.error as string, { error: true });
    } else if (controlledAlerts && Object.keys(formErrors).pop() === name) {
      showNotice(meta.error as string, { error: true });
    }
  }, [meta.touched, meta.error, formErrors, name, controlledAlerts, submitCount]);

  useEffect(() => {
    updateFormScore(score);
  }, [updateFormScore, score]);

  return (
    <StyledFieldContainer
      onClick={(): void => inputRef?.current?.focus()}
      error={(meta.touched && meta.error) as boolean}
      data-testid={DataTestIds.FIELD_CONTAINER}
    >
      <NewPasswordInputElements>
        <StyledLabel htmlFor={name} error={(meta.touched && meta.error) as boolean} data-testid={DataTestIds.LABEL}>
          {label}
        </StyledLabel>

        <StyledInput
          ref={inputRef}
          data-testid={DataTestIds.INPUT}
          type="password"
          disabled={disabled}
          autoComplete="new-password"
          {...field}
          {...props}
          onBlur={calculateScore}
          onPaste={(e: Event): void => {
            calculateScore();
          }}
          onChange={(e: Event): void => {
            helpers.setError(undefined);
            field.onChange(e);
            if (!!props.onChange) (props.onChange as any)(e);
            debounceScoreCalc();
          }}
          onKeyPress={(e: any) => {
            e.key === "Enter" && !score && e.preventDefault();
          }}
        />

        <StrengthIndicator
          data-testid={DataTestIds.PASSWORD_STRENGTH_INDICATOR}
          className={classnames({
            bad: (score !== 0 && score < 2) || (field.value.length > 0 && field.value.length < 8) || meta.error,
            okay: score >= 2 && score < 4,
            best: score >= 4
          })}
        />
      </NewPasswordInputElements>
    </StyledFieldContainer>
  );
};
