import styled from "styled-components";
import { colorPalette as colors, ColorNames, dropShadows, ShadowNames, fonts } from "src/theme/theme";

export const StyledSmallListing = styled.div<{ compact: boolean }>`
  display: grid;
  text-align: left;
  width: ${({ compact }): string => (!compact ? "152px;" : "120px")};
  cursor: pointer;

  figure {
    display: grid;
    justify-items: center;
    align-items: center;
    width: ${({ compact }): string => (!compact ? "152px;" : "120px")};
    height: 80px;
    margin-bottom: 8px;
    padding: 0 24px;
    border-radius: 14px;
    background-color: ${({ compact }): string => (!compact ? colors[ColorNames.GRAY1].hex : colors[ColorNames.WHITE].hex)};
    box-shadow: 0 0 0 1px rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0) inset;
    transition: box-shadow .2s ease-in-out;
    will-change: box-shadow;

    .img-container {
      display: grid;
      justify-items: center;
      align-items: center;
      height: 42px;
      width: 100%;
    }

    img {
      display: block;
      max-width: 100%;
      max-height: 42px;
      margin: 0 auto;
    }
  }

  ${({ compact }): string => {
    return !compact ?
    `&:hover figure {
      box-shadow: 0 0 0 1.5px rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0.05) inset;
    }` : "";
  }}

  .details {
    padding: 0 10px;
    color: ${colors[ColorNames.MIDNIGHT].hex};
    ${({ compact }): string => (!compact ? fonts.PARAGRAPH_M : fonts.PARAGRAPH_S)}

    p {
      display: block;
      margin-top: 6px;
      ${fonts.PARAGRAPH_S}
      color: ${colors[ColorNames.GRAY5].hex};
    }
  }
`;