import { ActionFunction, LoaderFunction } from "react-router-dom";
import { getLine, getLineType, getLineTypes, generateLineDocumentUrl, calculateAmortizationSchedule, getLineDocuments } from "src/services/lines";

export const lineLoader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const get = url.searchParams.get("get");

  if (get === "lineType") {
    try {
      const lineTypeId = url.searchParams.get("lineTypeId") || "";
      return await getLineType(lineTypeId);
    } catch (e) {
      throw e;
    }
  } else if (get === "lineTypes") {
    try {
      return await getLineTypes();
    } catch (e) {
      throw e;
    }
  } else if (get === "lineDoc") {
    try {
      const lineId = url.searchParams.get("lineId") || "";
      const docType = url.searchParams.get("docType") || "";
      return await generateLineDocumentUrl(lineId, docType);
    } catch (e) {
      return { errors: e };
    }
  } else if (get === "lineDocuments") {
    try {
      const lineId = url.searchParams.get("lineId") || "";
      return await getLineDocuments(lineId);
    } catch (e) {
      return { errors: e };
    }
  } else if (get === "amortization") {
    const amount = parseInt(url.searchParams.get("amount") || "0");
    const termLengthInMonths = parseInt(url.searchParams.get("termLength") || "0");
    try {
      return await calculateAmortizationSchedule({ amount, termLengthInMonths });
    } catch (e) {
      throw e;
    }
  } else {
    try {
      const lineId = url.searchParams.get("lineId") || "";
      return await getLine(lineId);
    } catch (e) {
      throw e;
    }
  }
};

export const lineAction: ActionFunction = async ({ request, params }) => {
  const formData = Object.fromEntries(await request.formData());

  if (request.method === "POST") {
    
  } else if (request.method === "PUT") {
    
  } else if (request.method === "DELETE") {
    
  }

  return { response: { success: true }};
};