import styled from "styled-components";
import { ReactComponent as LetterLogo } from "src/assets/logo-word.svg";

import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

export const Logo = styled(LetterLogo)`
  margin: 83px 0 45px;
`;

export const TermsPage = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  padding: 0 38px 80px;
  max-width: 570px;
  margin: 0 auto;
  grid-column: 1 / -1;
  color: ${colors[ColorNames.MIDNIGHT].hex};
  ${fonts.NUMBER_S}
  line-height: 170%;

  h1 {
    ${fonts.HEADING_4}
  }

  h2 {
    ${fonts.HEADING_5}
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: square;
    margin-left: 20px;
  }

  p,
  li {
    margin-bottom: 10px;
  }

  hr {
    width: 100%;
    margin: 30px 0;
    border: 0.5px solid ${colors[ColorNames.GRAY2].hex};
  }

  strong {
    display: inline;
  }

  a {
    text-decoration: none;
  }

  a:active,
  a:visited {
    color: unset;
  }
`;
