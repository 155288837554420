import React, { ReactNode } from "react";

import {
  InfoListItemContainer,
  ListItemTitle,
  InfoListItemValueContainer,
  ListItemValue,
  ChevronIcon
} from "src/components/info-list-item/InfoListItem.styles";
import { ThemeColor } from "src/theme/theme";

import { DataTestIds } from "src/util/testing-util/test-utils";

export interface InfoListItemProps {
  title: string;
  titleColor?: ThemeColor;
  value?: string;
  valueType?: "number" | "text";
  valueColor?: ThemeColor;
  size?: "small" | "normal";
  children?: ReactNode | string;
  onAction?: () => unknown;
}

export const InfoListItem = ({ title, titleColor, value, valueType, valueColor, size, children, onAction }: InfoListItemProps): JSX.Element => {
  return (
    <InfoListItemContainer size={size} onClick={!!onAction ? onAction : (): null => null}>
      <ListItemTitle color={titleColor}>{title}</ListItemTitle>
      <InfoListItemValueContainer color={valueColor}>
        <ListItemValue size={size} type={valueType}>
          {!!children ? children : value}
        </ListItemValue>
        {!!onAction && <ChevronIcon data-testid={DataTestIds.CHEVRON_RIGHT_ICON} />}
      </InfoListItemValueContainer>
    </InfoListItemContainer>
  );
};
