import { client } from "src";
import { Alert, AlertType } from "src/generated/client";
import { getConnectedAccountUpdateToken } from "./connected-accounts";

import { GET_ALERTS } from "./gql/_alerts";

export const fetchAlerts = async () => {
  const res = await client.query({
    query: GET_ALERTS,
    errorPolicy: "all",
  });

  if (!!res.data?.alerts) {
    const alerts = res.data.alerts.alerts || [];

    // hardcoding a search for plaid enrollment for now, can loop and handle at some point
    const enrollment = alerts.find(
      (alert: Alert) => alert.alertType === AlertType.PlaidEnrollmentUpdate
    );

    if (enrollment) {
      const meta = JSON.parse(enrollment.meta);
      const updateToken = await getConnectedAccountUpdateToken({
        enrollmentId: meta.enrollmentId,
      });

      return [{ key: AlertType.PlaidEnrollmentUpdate, value: updateToken }];
    }
  } else {
    throw res.errors;
  }
};
