import React, { useMemo } from "react";

import {
  FeatureCardContainer,
  FeatureCardShapes,
  StyledCircle,
  StyledTriangleWrapper,
  StyledTriangle,
  SvgGradients
} from "./FeatureCard.styles";
import { BackgroundImageSize } from "src/theme/theme";


export enum FeatureCardType {
  BANK = "bank",
  LINE = "line",
}

export interface FeatureCardProps {
  type: FeatureCardType;
  children: JSX.Element;
  loading?: boolean;
  onClick?: () => void;
}

export const FeatureCard = ({ type, children, loading, onClick }: FeatureCardProps): JSX.Element => {
  const shapes = useMemo(() => {
    switch (type) {
      case FeatureCardType.BANK:
        return <>
          <StyledCircle size={BackgroundImageSize.LARGE} />
          <StyledCircle size={BackgroundImageSize.MEDIUM} />
          <StyledCircle size={BackgroundImageSize.SMALL} />
        </>;
      case FeatureCardType.LINE:
        return <>
          <StyledTriangleWrapper size={BackgroundImageSize.LARGE}>
            <StyledTriangle size={BackgroundImageSize.LARGE} />
          </StyledTriangleWrapper>
          <StyledTriangleWrapper size={BackgroundImageSize.MEDIUM}>
            <StyledTriangle size={BackgroundImageSize.MEDIUM} />
          </StyledTriangleWrapper>
          <StyledTriangleWrapper size={BackgroundImageSize.SMALL}>
            <StyledTriangle size={BackgroundImageSize.SMALL} />
          </StyledTriangleWrapper>
        </>;
      default:
        return <></>;
    }
  }, [type]);

  return (
    <FeatureCardContainer type={type} onClick={onClick}>
      <FeatureCardShapes>
        <SvgGradients />
        {shapes}
      </FeatureCardShapes>

      {children}
    </FeatureCardContainer>
  );
};
