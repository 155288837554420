import React from "react";
import { generatePath } from "react-router-dom";

import useNavigate from "src/util/useNavigate";
import { NavRoutes } from "src/routes/navRoutes";

import {
  StyledSmallListing
} from "./SmallListing.styles";

export interface SmallListingProps {
  id: string;
  name: string;
  caption?: string;
  logo: string;
  compact?: boolean;
}

export const SmallListing = ({ id, name, caption, logo, compact = false }: SmallListingProps): JSX.Element => {
  const navigate = useNavigate();
  
  return (
    <StyledSmallListing 
      key={id}
      compact={compact}
      onClick={(): void => {
        navigate(
          generatePath(NavRoutes.MARKETPLACE_LISTING_DETAIL, {
            listingId: id,
            section: "overview"
          })
        );
      }}
    >
      <figure>
        <div className="img-container">
          <img src={logo} />
        </div>
      </figure>
      <div className="details">
        <div>{name}</div>
        {!compact && !!caption && (<p>{caption}</p>)}
      </div>
    </StyledSmallListing>
  );
};
