import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useSetSession } from "src/services/auth";
import { storedUserDataKey } from "./localStorage";


// TODO: REPLACE WITH GENERATED GQL
import { IS_SESSION_VALID } from "src/services/gql/_auth";


export enum ValidSession {
  UNKNOWN = "unknown",
  VALID = "valid",
  INVALID = "invalid"
}

export const useIsSessionValid = () => {
  const [getIsSessionValid, { data, loading, error }] = useLazyQuery(IS_SESSION_VALID, {
    fetchPolicy: "no-cache",
    errorPolicy: "all"
  });
  const [validSession, setValidSession] = useState<ValidSession>(ValidSession.UNKNOWN);
  const setSession = useSetSession();

  useEffect(() => {
    const session: string | null = localStorage.getItem(storedUserDataKey.SESSION);
    if (session) {
      getIsSessionValid();
    } else {
      setValidSession(ValidSession.INVALID);
    }
  }, [getIsSessionValid]);

  useEffect(() => {
    if (loading) return;

    const invalidateSession = () => {
      setSession();
      setValidSession(ValidSession.INVALID);
    };
    
    if (!!data) {
      if (!data.validSession?.success) {
        invalidateSession();
      } else {
        setValidSession(ValidSession.VALID);
      }
    } else if (!!error) {
      invalidateSession();
    }

    // eslint-disable-next-line
  }, [data, error, loading]);

  return { validSession, loading };
};
