import styled from "styled-components";
import { colorPalette as colors, ColorNames, dropShadows, ShadowNames, fonts } from "src/theme/theme";

export const StyledFeatureListing = styled.div`
  display: grid;
  text-align: left;
  border-radius: 14px;
  padding: 20px 0;
  margin-bottom: 50px;
  background-color: ${colors[ColorNames.CREAM].hex};
  box-shadow: ${dropShadows[ShadowNames.LIGHT]};
  color: ${colors[ColorNames.MIDNIGHT].hex};

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    margin: 10px 0 15px 0;
    cursor: pointer;
    ${fonts.HEADING_4}
  }

  .caption { 
    display: block;
    padding: 0 24px;
    ${fonts.PARAGRAPH_S}
  }

  .listings {
    display: flex;
    width: 100%;
    margin-top: 30px;
    flex-direction: row;
    align-items: top;
    gap: 8px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-height: none;

    &::-webkit-scrollbar { 
      display: none; 
    }

    > *:first-of-type {
      margin-left: 24px;
    }
    > *:last-of-type {
      margin-right: 24px;
    }
  }
`;