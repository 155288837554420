import React, { useState, useEffect, useMemo } from "react";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";

import { AuthContainer } from "src/pages/auth/Auth";
import { AuthTitle } from "src/pages/auth/Auth.styles";
import BusinessCategories from "src/data/businessCategories.json";
import States from "src/data/states.json";
import { Button } from "src/components/button/Button";
import { Input } from "src/components/forms/input/Input";
import { Select } from "src/components/forms/select/FormikSelect";
import { Form } from "src/components/forms/Form";
import { StyledFormInputContainer, InputRow } from "src/components/forms";
import {
  formatEIN,
  lowerDashCase,
  zipCodeFormatRegex,
  formatPhoneNumber,
} from "src/util/stringUtils";
import {
  LegalStructure,
  TaxStatuses,
} from "src/types/organization";
import { Organization, CreateOrUpdateOrgInput } from "src/generated/client";

const BusinessInfoSchema: Yup.ObjectSchema<object> = Yup.object().shape({
  orgName: Yup.string().required("Please add a business name."),
  legalStructure: Yup.string().required("Please choose a legal structure."),
  taxStatus: Yup.string().when("legalStructure", {
    is: lowerDashCase(LegalStructure.LLC),
    then: Yup.string().required("Please choose a tax status."),
    otherwise: Yup.string(),
  }),
  stateOfIncorporation: Yup.string().required(
    "Please add a state of incorporation."
  ),
  businessPhoneNumber: Yup.string()
    .min(14, "Please use a valid phone number.")
    .required("Please add your phone."),
  taxId: Yup.string()
    .min(10, "Please add a valid tax ID.")
    .required("Please add a Tax ID."),
  category: Yup.number().min(0).required("Please choose a category."),
  streetAddress: Yup.string().required("Please add an address."),
  city: Yup.string().required("Please add a city."),
  state: Yup.string().required("Please add a state."),
  zip: Yup.string()
    .matches(zipCodeFormatRegex, "Please use a valid zip code.")
    .required("Please add a zip code."),
  incorporationCertificate: Yup.string().required(
    "Please upload a Certificate of Incorporation."
  ),
  einConfirmation: Yup.string().required(
    "Please upload a confirmation of the business EIN."
  ),
});

type FormValues = {
  category: string;
  city: string;
  legalStructure: string;
  orgName: string;
  businessPhoneNumber: string;
  stateOfIncorporation: string;
  state: string;
  streetAddress: string;
  taxId: string;
  taxStatus: string;
  zip: string;
};

const BusinessInfo = ({
  nextStep,
  stepDirection,
  orgData,
}: {
  nextStep: (
    values: Omit<CreateOrUpdateOrgInput, "orgType">,
    docs: {
      incorporationCertificate: Blob | undefined;
      einConfirmation: Blob | undefined;
    },
    setSubmitting: (submitting: boolean) => void
  ) => void;
  stepDirection: number;
  orgData: Partial<Organization> | undefined;
}): JSX.Element => {
  const [prevEIN, setPrevEIN] = useState("");
  const [prevPhoneValue, setPrevPhoneValue] = useState("");
  const [taxStatusOptions, setTaxStatusOptions] = useState<
    Array<{ label: string; value: string }>
  >([
    {
      label: "Choose Legal",
      value: "",
    },
  ]);
  const [docIncorporationCertificate, setDocIncorporationCertificate] =
    useState<Blob>();
  const [docEINConfirmation, setDocEINConfirmation] = useState<Blob>();

  const ResetForm = () => {
    const { setFieldValue, values } = useFormikContext();

    useEffect(() => {
      return () => {
        if ((values as FormValues).streetAddress === "null")
          setFieldValue("streetAddress", "");
        if ((values as FormValues).city === "null") setFieldValue("city", "");
        if ((values as FormValues).zip === "null") setFieldValue("zip", "");
      };
    }, [values, setFieldValue]);
    return null;
  };

  useEffect(() => {
    if (
      !!orgData &&
      !!orgData.legalStructure &&
      orgData.legalStructure !== "" &&
      orgData.legalStructure !== "null"
    ) {
      getTaxStatusesForLegalStructure(
        orgData.legalStructure as LegalStructure
      );
    }
  }, [orgData]);

  const handleEIN = (event: React.ChangeEvent<HTMLInputElement>): string => {
    const formattedNumber = formatEIN(event.target.value, prevEIN);
    setPrevEIN(formattedNumber);
    return formattedNumber;
  };

  const handleChooseLegalStructure = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean | undefined
    ) => void
  ): void => {
    setFieldValue("taxStatus", "");

    if (event.target.value === "") {
      setTaxStatusOptions([
        {
          label: "Choose Legal",
          value: "",
        },
      ]);
    } else {
      getTaxStatusesForLegalStructure(
        event.target.value as LegalStructure
      );
    }
  };

  const handlePhoneNumber = (
    event: React.ChangeEvent<HTMLInputElement>
  ): string => {
    const formattedNumber = formatPhoneNumber(
      event.target.value,
      prevPhoneValue
    );
    setPrevPhoneValue(formattedNumber);
    return formattedNumber;
  };

  const getTaxStatusesForLegalStructure = (
    value: LegalStructure
  ): void => {
    const statuses = TaxStatuses[value].map((status: string) => {
      return {
        label: status,
        value: lowerDashCase(status),
      };
    });
    statuses.unshift({
      label: "Choose...",
      value: "",
    });

    setTaxStatusOptions(statuses);
  };

  const legalStructuresForDropdown = Object.values(LegalStructure).map(
    (structure: string) => {
      return {
        label: structure,
        value: lowerDashCase(structure),
      };
    }
  );
  legalStructuresForDropdown.unshift({
    label: "Choose...",
    value: "",
  });

  const businessCategoriesForDropdown = [...BusinessCategories];
  businessCategoriesForDropdown.unshift({
    label: "Choose category",
    value: -1,
  });

  const statesForDropdown = [...States];
  statesForDropdown.unshift({
    label: "Choose state",
    value: "",
  });

  const initialFormValues = useMemo(() => {
    return {
      orgName: orgData?.name || "",
      legalStructure: orgData?.legalStructure || "",
      taxStatus: orgData?.taxStatus || "",
      taxId: orgData?.taxId ? formatEIN(orgData.taxId) : "",
      stateOfIncorporation: orgData?.stateOfIncorporation || "",
      businessPhoneNumber: orgData?.businessPhoneNumber
        ? formatPhoneNumber(orgData.businessPhoneNumber)
        : "",
      category: orgData?.category || "-1",
      streetAddress: orgData?.streetAddress || "",
      city: orgData?.city || "",
      state: orgData?.state || "",
      zip: orgData?.zip || "",
      incorporationCertificate: "",
      einConfirmation: "",
    };
  }, [orgData]);

  return (
    <AuthContainer stepDirection={stepDirection}>
      <AuthTitle>Business Information</AuthTitle>

      <Formik
        initialValues={initialFormValues}
        enableReinitialize={true}
        validationSchema={BusinessInfoSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { setSubmitting }): void => {
          const { incorporationCertificate, einConfirmation, ...rest } = values;
          nextStep(
            { ...rest },
            {
              incorporationCertificate: docIncorporationCertificate,
              einConfirmation: docEINConfirmation,
            },
            setSubmitting
          );
        }}
      >
        {({
          isSubmitting,
          handleChange,
          setFieldValue,
          values,
        }): JSX.Element => (
          <Form>
            <>
              <StyledFormInputContainer>
                <ResetForm />
                <Input name="orgName" type="text" label="Business Name" />
                <InputRow>
                  <Select
                    name="legalStructure"
                    label="Legal Structure"
                    options={legalStructuresForDropdown}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      handleChange(event);
                      handleChooseLegalStructure(event, setFieldValue);
                    }}
                  />
                  <Select
                    name="taxStatus"
                    label="Tax Status"
                    options={taxStatusOptions}
                  />
                </InputRow>
                <Input
                  name="taxId"
                  type="text"
                  label="EIN / Tax ID"
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ): void => {
                    handleChange(event);
                    setFieldValue("taxId", handleEIN(event));
                  }}
                />
                <Select
                  name="stateOfIncorporation"
                  label="State of Incorporation"
                  options={statesForDropdown}
                  autocomplete="region"
                />
                <Select
                  name="category"
                  label="Business Category"
                  options={businessCategoriesForDropdown}
                />
                <Input
                  name="businessPhoneNumber"
                  type="text"
                  label="Business Phone"
                  autoComplete="tel"
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ): void => {
                    handleChange(event);
                    setFieldValue(
                      "businessPhoneNumber",
                      handlePhoneNumber(event)
                    );
                  }}
                />
                <Input
                  name="streetAddress"
                  type="text"
                  label="Business Street Address"
                  autocomplete="shipping street-address"
                />
                <Input
                  name="city"
                  type="test"
                  label="City"
                  autocomplete="shipping locality"
                />
                <InputRow>
                  <Select
                    name="state"
                    label="State"
                    options={statesForDropdown}
                    autocomplete="shipping region"
                  />
                  <Input
                    name="zip"
                    type="test"
                    label="ZIP Code"
                    autocomplete="shipping postal-code"
                  />
                </InputRow>
                <Input
                  fileName={values.incorporationCertificate}
                  name="incorporationCertificate"
                  label="Certificate of Incorporation"
                  placeholderLabel="Choose a PDF"
                  type="file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ): void => {
                    handleChange(event);
                    setDocIncorporationCertificate(
                      event.target.files ? event.target.files[0] : undefined
                    );
                  }}
                  selected={!!values.incorporationCertificate}
                />
                <Input
                  fileName={values.einConfirmation}
                  name="einConfirmation"
                  label="EIN Confirmation"
                  placeholderLabel="Choose a PDF"
                  type="file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ): void => {
                    handleChange(event);
                    setDocEINConfirmation(
                      event.target.files ? event.target.files[0] : undefined
                    );
                  }}
                  selected={!!values.einConfirmation}
                />
              </StyledFormInputContainer>
              <Button raised type="submit" loading={isSubmitting}>
                Continue
              </Button>
            </>
          </Form>
        )}
      </Formik>
    </AuthContainer>
  );
};

export default BusinessInfo;
