import React from "react";

import { StyledSelect } from "src/components/forms/select/Select.styles";
import { StyledFieldContainer } from "src/components/forms";
import { StyledLabel } from "src/components/text";
import { DataTestIds } from "src/util/testing-util/test-utils";

export interface SelectOption {
  value: string | number;
  label: string;
}
export interface SelectProps {
  label?: string;
  name: string;
  defaultValue?: string | number;
  error?: boolean;
  options: SelectOption[];
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  handleError?: (error: string) => unknown;
  disabled?: boolean;
  [key: string]: unknown;
}

export const Select = ({ label, name, options, defaultValue, error, disabled, onChange, handleError, ...props }: SelectProps): JSX.Element => {

  return (
    <StyledFieldContainer error={error} data-testid={DataTestIds.FIELD_CONTAINER}>
      {label && (
        <StyledLabel
          htmlFor={(props.id as string) || name}
          error={error}
          data-testid={DataTestIds.LABEL}
        >
          {label}
        </StyledLabel>
      )}

      <StyledSelect disabled={disabled} name={name} data-testid={DataTestIds.SELECT} {...props} defaultValue={defaultValue} onChange={onChange}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
    </StyledFieldContainer>
  );
};
