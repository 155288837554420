import styled from "styled-components";
import { ReactComponent as Sprite } from "src/assets/logo-spin-sprite.svg";

export const Holder = styled.div`
  width: 0;
  height: 0;
  position: absolute;
`;

export const LogoSprite = styled(Sprite)``;
