import { useState, useEffect } from "react";
import { useRecoilCallback, useSetRecoilState } from "recoil";
import { bottomSheets, bottomSheetSelector, IBottomSheets } from "src/store/bottomSheetState";

// registers a bottom sheet
export const useSetBottomSheet = () => {
  const [sheetId, setSheetId] = useState<string>();

  const setBottomSheet = useRecoilCallback(
    ({ set }) =>
      (options: IBottomSheets) => {
        setSheetId(options.id);
        set(bottomSheets(options.id), {
          id: options.id,
          children: options.children || null,
          inline: options.inline || false,
          fullHeight: options.fullHeight || false,
          noPadding: options.noPadding || false,
          hasHeader: options.hasHeader || false,
          closeOnOutsideClick: options.closeOnOutsideClick ?? true,
          backgroundColor: options.backgroundColor || undefined,
          onClose: options.onClose || undefined
        });
      },
    []
  );

  const unsetBottomSheet = useRecoilCallback(
    ({ reset, set }) =>
      () => {
        if (!!sheetId) {
          reset(bottomSheets(sheetId));
          set(bottomSheetSelector, { sheetId, hide: true });
        }
      },
    [sheetId]
  );

  useEffect(() => {
    return unsetBottomSheet;
  }, [unsetBottomSheet]);

  return setBottomSheet;
};

// shows / hides a registered bottom sheet
export const useShowBottomSheet = () => {
  const setBottomSheets = useSetRecoilState(bottomSheetSelector);

  const showBottomSheet = (sheetId: string | null) => {
    setBottomSheets({ sheetId, hide: false });
  };

  // showId = null to hide all sheets
  const hideBottomSheet = (sheetId?: string) => {
    setBottomSheets({ sheetId, hide: true });
  };

  return { showBottomSheet, hideBottomSheet };
};
