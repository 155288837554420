import React from "react";
import { Navigate, RouteObject } from "react-router-dom";

import { NavRoutes } from "./navRoutes";

import LetterRoot from "src/LetterRoot";
import PublicRoot from "src/PublicRoot";

import { cardLoader } from "src/routes/api/card";
import { mfaAction } from "src/routes/api/mfa";
import { ownersAction, ownerLoader } from "src/routes/api/owners";
import { accountAction, accountLoader } from "src/routes/api/account";
import { connectedAccountAction, connectedAccountLoader } from "src/routes/api/connected-account";
import { dealLoader } from "src/routes/api/deal";
import { lineLoader, lineAction } from "src/routes/api/line";
import { organizationAction, organizationLoader } from "src/routes/api/organization";

import ErrorElement from "src/ErrorElement";

import Accounts, { AccountsErrorElement } from "src/routes/app/bank/accounts/Accounts";
import AccountDetail, { AccountDetailErrorElement } from "src/routes/app/bank/accounts/AccountDetail";
import Member, { AccountMemberErrorElement } from "src/routes/app/bank/accounts/Member";
import ActivateCards from "src/routes/app/bank/cards/ActivateCards";
import AppRoot, { AppGenericRoot } from "src/routes/app/AppRoot";
import BankRoot, { BankErrorElement } from "src/routes/app/bank/BankRoot";
import ConnectedAccounts from "src/routes/app/bank/connected-accounts/ConnectedAccounts";
import ConnectedAccountsEmpty from "src/routes/app/bank/connected-accounts/ConnectedAccountsEmpty";
import ConnectedAccountDetail from "src/routes/app/bank/connected-accounts/ConnectedAccountDetail";
import DashboardRoot from "src/routes/app/bank/dashboard/DashboardRoot";
import ApprovalRequest from "src/pages/approval-request/ApprovalRequest";
import CardDetail, { CardDetailErrorElement } from "src/routes/app/bank/cards/CardDetail";
import CardSettings from "src/routes/app/bank/cards/CardSettings";
import CreateCard, { CreateCardErrorElement } from "src/routes/app/bank/cards/CreateCard";
import { cardAction } from "src/routes/app/bank/cards/_actions";
import Disclosures from "src/pages/terms/Disclosures";
import EditOrganization from "src/pages/organization/EditOrganization";
import MyAccount from "src/pages/my-account/MyAccount";
import MyDeals from "src/routes/app/marketplace/my-deals/MyDeals";
import Help from "src/pages/settings/Help";
import MarketplaceRoot from "src/routes/app/marketplace/MarketplaceRoot";
import Members from "src/pages/members/Members";
import MemberDetails from "src/pages/members/MemberDetail";
import MemberCards from "src/pages/members/MemberCards";
import Landing from "src/routes/landing/Landing";
import Listings, { ListingsErrorElement } from "src/routes/app/marketplace/listings/Listings";
import ListingCategory from "src/routes/app/marketplace/listings/ListingCategory";
import ListingDetail from "src/routes/app/marketplace/listings/ListingDetail";
import { listingAction } from "src/routes/app/marketplace/listings/_actions";
import NotFound from "src/pages/not-found/NotFound";
import Onboarding from "src/routes/app/onboarding/Onboarding";
import PrivacyPolicy from "src/pages/privacy-policy/PrivacyPolicy";
import Recipients from "src/pages/recipients/Recipients";
import RecipientDetail from "src/pages/recipients/RecipientDetail";
import RecipientsNew from "src/pages/recipients/NewRecipient";
import Security from "src/pages/security/Security";
import SignIn from "src/routes/sign-in/SignIn";
import { signInAction } from "src/routes/sign-in/_actions";
import SignUp from "src/routes/sign-up/SignUp";
import SurveyRedirect from "src/pages/survey-redirect/SurveyRedirect";
import Terms from "src/pages/terms/Terms";
import AccountTerms from "src/pages/terms/AccountTerms";
import BusinessBankingDespositAgreement from "src/pages/terms/BusinessBankingDespositAgreement";
import PersonalBankingDespositAgreement from "src/pages/terms/PersonalBankingDespositAgreement";
import ElectronicDisclosure from "src/pages/terms/ElectronicDisclosure";
import Transaction from "src/pages/transactions/Transactions";
import TransferSend from "src/routes/app/bank/transfer/Send";
import { sendAction } from "src/routes/app/bank/transfer/_actions";
import ForgotPassword from "src/routes/forgot-password/ForgotPassword";
import ResetPassword from "src/routes/reset-password/ResetPassword";
import DepositChecks from "src/routes/app/bank/deposit-checks/Deposit";
import AuctionSales from "src/routes/auction-sales/AuctionSales";
import { registerAction } from "src/routes/auction-sales/_actions";

// oneline
import OneLineRoot, { OneLineErrorElement } from "src/routes/app/oneline/OneLineRoot";
import OneLineDashboard from "src/routes/app/oneline/dashboard/OneLineDashboard";
import Qualify, { qualifyAction } from "src/routes/app/oneline/dashboard/Qualify";
import Borrow from "src/routes/app/oneline/dashboard/Borrow";
import Lines from "src/routes/app/oneline/lines/Lines";
import LineDetail from "src/routes/app/oneline/lines/LineDetail";
import CreateLine from "src/routes/app/oneline/lines/CreateLine";
import FinalizeLine from "src/routes/app/oneline/lines/FinalizeLine";
import { createLineAction, finalizeLineAction } from "src/routes/app/oneline/_actions";

// teton ridge
import TetonRidgeAuctions from "src/routes/landing/TetonRidge/Auctions";
import TetonRidgeAuctionsEarly from "src/routes/landing/TetonRidge/Early";
import TetonRidgeAuctionsLate from "src/routes/landing/TetonRidge/Late";
import { tetonRidgeAuctionsAction, tetonRidgeAuctionsLoader } from "./landing/TetonRidge/_actions";

// redirects
import DocusignComplete from "src/routes/redirects/DocusignComplete";

export const routes: RouteObject[] = [
  {
    element: <LetterRoot />,
    id: NavRoutes.LETTER_ROOT,
    errorElement: <NotFound />,
    children: [
      {
        path: NavRoutes.API_CARD,
        id: NavRoutes.API_CARD,
        element: <Navigate to={NavRoutes.BANK_DASHBOARD} />,
        action: cardAction,
        loader: cardLoader
      },
      {
        path: NavRoutes.API_MFA,
        id: NavRoutes.API_MFA,
        element: <Navigate to={NavRoutes.BANK_DASHBOARD} />,
        action: mfaAction
      },
      {
        path: NavRoutes.API_ONELINE_AUCTION,
        id: NavRoutes.API_ONELINE_AUCTION,
        element: <Navigate to={NavRoutes.AUCTION_SALES} />,
        loader: tetonRidgeAuctionsLoader,
        action: registerAction
      },
      {
        path: NavRoutes.API_ACCOUNT,
        id: NavRoutes.API_ACCOUNT,
        element: <Navigate to={NavRoutes.BANK_DASHBOARD} />,
        action: accountAction,
        loader: accountLoader
      },
      {
        path: NavRoutes.API_CONNECTED_ACCOUNT,
        id: NavRoutes.API_CONNECTED_ACCOUNT,
        element: <Navigate to={NavRoutes.BANK_DASHBOARD} />,
        loader: connectedAccountLoader,
        action: connectedAccountAction
      },
      {
        path: NavRoutes.API_DEAL,
        id: NavRoutes.API_DEAL,
        element: <Navigate to={NavRoutes.BANK_DASHBOARD} />,
        loader: dealLoader
      },
      {
        path: NavRoutes.API_LINE,
        id: NavRoutes.API_LINE,
        element: <Navigate to={NavRoutes.BANK_DASHBOARD} />,
        loader: lineLoader,
        action: lineAction
      },
      {
        path: NavRoutes.API_ORGANIZATION,
        id: NavRoutes.API_ORGANIZATION,
        element: <Navigate to={NavRoutes.BANK_DASHBOARD} />,
        action: organizationAction,
        loader: organizationLoader
      },
      {
        path: NavRoutes.API_OWNER,
        id: NavRoutes.API_OWNER,
        element: <Navigate to={NavRoutes.BANK_DASHBOARD} />,
        action: ownersAction,
        loader: ownerLoader
      },

      {
        element: <PublicRoot />,
        id: NavRoutes.PUBLIC_ROOT,
        errorElement: <ErrorElement />,
        children: [
          { path: NavRoutes.LANDING, id: NavRoutes.LANDING, element: <Landing /> },
          { path: NavRoutes.SIGN_IN, id: NavRoutes.SIGN_IN, element: <SignIn />, action: signInAction },
          { path: NavRoutes.SIGN_UP, id: NavRoutes.SIGN_UP, element: <SignUp /> },
          { path: NavRoutes.FORGOT_PASSWORD, id: NavRoutes.FORGOT_PASSWORD, element: <ForgotPassword /> },
          { path: NavRoutes.RESET_PASSWORD, id: NavRoutes.RESET_PASSWORD, element: <ResetPassword /> },
          { path: NavRoutes.PRIVACY_POLICY, id: NavRoutes.PRIVACY_POLICY, element: <PrivacyPolicy /> },
          { path: NavRoutes.TERMS, id: NavRoutes.TERMS, element: <Terms /> },
          { path: NavRoutes.ACCOUNT_TERMS, id: NavRoutes.ACCOUNT_TERMS, element: <AccountTerms /> },
          {
            path: NavRoutes.BUSINESS_BANKING_DEPOSIT_AGREEMENT,
            id: NavRoutes.BUSINESS_BANKING_DEPOSIT_AGREEMENT,
            element: <BusinessBankingDespositAgreement />
          },
          {
            path: NavRoutes.PERSONAL_BANKING_DEPOSIT_AGREEMENT,
            id: NavRoutes.PERSONAL_BANKING_DEPOSIT_AGREEMENT,
            element: <PersonalBankingDespositAgreement />
          },
          {
            path: NavRoutes.ELECTRONIC_DISCLOSURE,
            id: NavRoutes.ELECTRONIC_DISCLOSURE,
            element: <ElectronicDisclosure />
          },
          { path: NavRoutes.DISCLOSURES, id: NavRoutes.DISCLOSURES, element: <Disclosures /> },
          { path: NavRoutes.SECURITY, id: NavRoutes.SECURITY, element: <Security /> },
          { path: NavRoutes.SURVEY_COMPLETE, id: NavRoutes.SURVEY_COMPLETE, element: <SurveyRedirect /> },
          { path: NavRoutes.APPROVAL_REQUEST, id: NavRoutes.APPROVAL_REQUEST, element: <ApprovalRequest /> },
          {
            path: NavRoutes.PUBLIC_ACTIVATE,
            id: NavRoutes.PUBLIC_ACTIVATE,
            element: <Navigate to={NavRoutes.BANK_ACTIVATE} />
          },
          {
            path: NavRoutes.TETON_RIDGE_AUCTIONS,
            id: NavRoutes.TETON_RIDGE_AUCTIONS,
            element: <TetonRidgeAuctions />,
            action: tetonRidgeAuctionsAction
          },
          {
            path: NavRoutes.TETON_RIDGE_AUCTION_EARLY,
            id: NavRoutes.TETON_RIDGE_AUCTION_EARLY,
            element: <TetonRidgeAuctionsEarly />,
            action: tetonRidgeAuctionsAction
          },
          {
            path: NavRoutes.TETON_RIDGE_AUCTION_LATE,
            id: NavRoutes.TETON_RIDGE_AUCTION_LATE,
            element: <TetonRidgeAuctionsLate />
          },
          {
            path: NavRoutes.AUCTION_SALES,
            id: NavRoutes.AUCTION_SALES,
            element: <AuctionSales />,
            action: registerAction
          },

          {
            path: NavRoutes.REDIRECTS_DOCUSIGN_COMPLETE,
            id: NavRoutes.REDIRECTS_DOCUSIGN_COMPLETE,
            element: <DocusignComplete />
          }
        ]
      },

      {
        path: NavRoutes.APP_ROOT,
        element: <AppRoot />,
        id: NavRoutes.APP_ROOT,
        // loader: appLoader,
        errorElement: <ErrorElement />,
        children: [
          { index: true, element: <Navigate to={NavRoutes.BANK_DASHBOARD} /> },

          {
            element: <AppGenericRoot />,
            children: [
              { path: NavRoutes.SETTINGS, element: <Navigate to={NavRoutes.USER_ACCOUNT_SETTINGS} /> },
              { path: NavRoutes.USER_ACCOUNT_SETTINGS, id: NavRoutes.USER_ACCOUNT_SETTINGS, element: <MyAccount /> },
              {
                path: NavRoutes.USER_ACCOUNT_SETTINGS_SECTION,
                id: NavRoutes.USER_ACCOUNT_SETTINGS_SECTION,
                element: <MyAccount />
              },
              { path: NavRoutes.EDIT_ORGANIZATION, id: NavRoutes.EDIT_ORGANIZATION, element: <EditOrganization /> },
              { path: NavRoutes.MEMBERS, id: NavRoutes.MEMBERS, element: <Members /> },
              { path: NavRoutes.MEMBERS_DETAIL, id: NavRoutes.MEMBERS_DETAIL, element: <MemberDetails /> },
              { path: NavRoutes.MEMBERS_CARDS, id: NavRoutes.MEMBERS_CARDS, element: <MemberCards /> },
              { path: NavRoutes.ONBOARD, id: NavRoutes.ONBOARD, element: <Onboarding /> },
              { path: NavRoutes.HELP, id: NavRoutes.HELP, element: <Help /> }
            ]
          },

          {
            path: NavRoutes.BANK_ROOT,
            element: <BankRoot />,
            id: NavRoutes.BANK_ROOT,
            // loader: bankLoader,
            errorElement: <BankErrorElement />,
            children: [
              { index: true, element: <Navigate to={NavRoutes.BANK_DASHBOARD} /> },
              { path: NavRoutes.BANK_ACTIVATE, id: NavRoutes.BANK_ACTIVATE, element: <ActivateCards /> },
              {
                path: NavRoutes.BANK_DASHBOARD,
                id: NavRoutes.BANK_DASHBOARD,
                element: <DashboardRoot />
              },
              {
                path: NavRoutes.BANK_ACCOUNTS,
                element: <Accounts />,
                id: NavRoutes.BANK_ACCOUNTS,
                errorElement: <AccountsErrorElement />
              },
              {
                path: NavRoutes.BANK_ACCOUNT_DETAIL,
                element: <AccountDetail />,
                id: NavRoutes.BANK_ACCOUNT_DETAIL,
                errorElement: <AccountDetailErrorElement />
              },
              {
                path: NavRoutes.BANK_ACCOUNT_DETAIL_MEMBER,
                element: <Member />,
                id: NavRoutes.BANK_ACCOUNT_DETAIL_MEMBER,
                errorElement: <AccountMemberErrorElement />
              },
              {
                path: NavRoutes.BANK_ACCOUNT_CREATE_CARD,
                id: NavRoutes.BANK_ACCOUNT_CREATE_CARD,
                element: <CreateCard />,
                errorElement: <CreateCardErrorElement />,
                action: cardAction
              },
              {
                path: NavRoutes.BANK_ACCOUNT_CARD_DETAIL,
                id: NavRoutes.BANK_ACCOUNT_CARD_DETAIL,
                element: <CardDetail />,
                errorElement: <CardDetailErrorElement />,
                action: cardAction
              },
              {
                path: NavRoutes.BANK_ACCOUNT_CARD_SETTINGS,
                id: NavRoutes.BANK_ACCOUNT_CARD_SETTINGS,
                element: <CardSettings />,
                action: cardAction
              },
              { path: NavRoutes.BANK_TRANSACTIONS, id: NavRoutes.BANK_TRANSACTIONS, element: <Transaction /> },
              //{ path: NavRoutes.BANK_DEPOSIT_CHECKS, id: NavRoutes.BANK_DEPOSIT_CHECKS, element: <DepositChecks /> },

              {
                path: NavRoutes.BANK_CONNECTED_ACCOUNTS,
                id: NavRoutes.BANK_CONNECTED_ACCOUNTS,
                element: <ConnectedAccounts />
              },
              {
                path: NavRoutes.BANK_CONNECTED_ACCOUNTS_DETAIL,
                id: NavRoutes.BANK_CONNECTED_ACCOUNTS_DETAIL,
                element: <ConnectedAccountDetail />
              },
              {
                path: NavRoutes.BANK_CONNECTED_ACCOUNTS_EMPTY,
                id: NavRoutes.BANK_CONNECTED_ACCOUNTS_EMPTY,
                element: <ConnectedAccountsEmpty />
              },
              {
                path: NavRoutes.BANK_TRANSFER_SEND,
                id: NavRoutes.BANK_TRANSFER_SEND,
                element: <TransferSend />,
                action: sendAction
              },
              {
                path: NavRoutes.BANK_TRANSFER_SEND_SOURCED,
                id: NavRoutes.BANK_TRANSFER_SEND_SOURCED,
                element: <TransferSend />,
                action: sendAction
              },
              { path: NavRoutes.BANK_RECIPIENTS, id: NavRoutes.BANK_RECIPIENTS, element: <Recipients /> },
              { path: NavRoutes.BANK_RECIPIENTS_NEW, id: NavRoutes.BANK_RECIPIENTS_NEW, element: <RecipientsNew /> },
              {
                path: NavRoutes.BANK_RECIPIENTS_DETAIL,
                id: NavRoutes.BANK_RECIPIENTS_DETAIL,
                element: <RecipientDetail />
              }
            ]
          },

          {
            path: NavRoutes.ONELINE_ROOT,
            element: <OneLineRoot />,
            id: NavRoutes.ONELINE_ROOT,
            children: [
              { index: true, element: <Navigate to={NavRoutes.ONELINE_DASHBOARD} /> },
              {
                path: NavRoutes.ONELINE_DASHBOARD,
                element: <OneLineDashboard />,
                id: NavRoutes.ONELINE_DASHBOARD
              },
              {
                path: NavRoutes.ONELINE_QUALIFY,
                element: <Qualify />,
                id: NavRoutes.ONELINE_QUALIFY,
                action: qualifyAction
              },
              {
                path: NavRoutes.ONELINE_BORROW,
                element: <Borrow />,
                id: NavRoutes.ONELINE_BORROW,
                errorElement: <OneLineErrorElement />
              },
              {
                path: NavRoutes.ONELINE_LINES,
                element: <Lines />,
                id: NavRoutes.ONELINE_LINES,
                errorElement: <OneLineErrorElement />
              },
              {
                path: NavRoutes.ONELINE_LINE_DETAIL,
                element: <LineDetail />,
                id: NavRoutes.ONELINE_LINE_DETAIL,
                errorElement: <OneLineErrorElement />,
                action: lineAction
              },
              {
                path: NavRoutes.ONELINE_CREATE_LINE,
                element: <CreateLine />,
                id: NavRoutes.ONELINE_CREATE_LINE,
                action: createLineAction,
                errorElement: <OneLineErrorElement />
              },
              {
                path: NavRoutes.ONELINE_EDIT_LINE,
                element: <CreateLine />,
                id: NavRoutes.ONELINE_EDIT_LINE,
                action: createLineAction
              },
              {
                path: NavRoutes.ONELINE_FINALIZE_LINE,
                element: <FinalizeLine />,
                id: NavRoutes.ONELINE_FINALIZE_LINE,
                action: finalizeLineAction
              }
            ]
          },

          {
            path: NavRoutes.MARKETPLACE_ROOT,
            element: <MarketplaceRoot />,
            id: NavRoutes.MARKETPLACE_ROOT,
            // loader: marketplaceLoader,
            children: [
              { index: true, element: <Navigate to={NavRoutes.MARKETPLACE_LISTINGS} /> },
              {
                path: NavRoutes.MARKETPLACE_LISTINGS,
                element: <Listings />,
                id: NavRoutes.MARKETPLACE_LISTINGS,
                errorElement: <ListingsErrorElement />
              },
              {
                path: NavRoutes.MARKETPLACE_LISTING_DETAIL,
                element: <ListingDetail />,
                id: NavRoutes.MARKETPLACE_LISTING_DETAIL,
                action: listingAction
              },
              {
                path: NavRoutes.MARKETPLACE_LISTING_CATEGORY,
                element: <ListingCategory />,
                id: NavRoutes.MARKETPLACE_LISTING_CATEGORY
              },
              {
                path: NavRoutes.MARKETPLACE_MY_DEALS,
                element: <MyDeals />,
                id: NavRoutes.MARKETPLACE_MY_DEALS
              }
            ]
          }
        ]
      }
    ]
  },
  { path: "*", element: <NotFound /> }
];
