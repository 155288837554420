import { faker } from "@faker-js/faker";

export enum DataTestIds {
  ACCOUNT_CARD = "accountCard",
  ACCOUNT_GAIN = "accountGain",
  ACCOUNT_TYPE = "accountType",
  ACTIONABLE_BUTTON = "actionableButton",
  ADD_MODAL = "addModal",
  ADD_ICON = "addIcon",
  ALERT = "alert",
  ARROW_ICON = "arrowIcon",
  ACTION_ICON = "actionIcon",
  AVATAR = "avatar",
  AVATAR_IMAGE = "avatarImage",
  BACK_NAV_BACK = "backNavBack",
  BADGE = "badge",
  BOTTOM_SHEET = "bottomSheet",
  BOTTOM_SHEET_OVERLAY = "bottomSheetOverlay",
  BUTTON = "button",
  CHECK_ICON = "checkIcon",
  CHEVRON_RIGHT_ICON = "chevronRightIcon",
  CODE_INPUT = "codeInput",
  COPY_ICON = "copyIcon",
  CURRENCY_INPUT = "LargeCurrencyInput",
  DETAIL_ROW = "detailRow",
  ERROR_MESSAGE = "errorMessage",
  FIELD_CONTAINER = "fieldContainer",
  INPUT = "input",
  LABEL = "label",
  LIST_ITEM = "listItem",
  LOADING_CONTAINER = "loadingContainer",
  LOGO_SPINNER = "logoSpinner",
  MENU_NAV_LOGO = "menuNavLogo",
  MENU_NAV_MENU = "menuNavMenu",
  MENU_SIGN_OUT = "menuSignOut",
  NEXT_MODAL = "nextModal",
  ONBOARDING_CLOSE = "onboardingClose",
  ONBOARDING_NAV_BACK = "onboardingNavBack",
  ONBOARDING_STEPPER_PILL = "onboardingStepperPill",
  ORG_SWITCHER_COLLAPSE = "orgSwitcherCollapse",
  ORG_SWITCHER_DROPDOWN = "orgSwitcherDropdown",
  ORG_SWITCHER_LIST = "orgSwitcherList",
  ORG_SWITCHER_OVERLAY = "orgSwitcherOverlay",
  PASSWORD_STRENGTH_INDICATOR = "pwdStrengthIndicator",
  PERSONAL_INFO = "personalInfo",
  READ_ONLY_INPUT = "readOnlyInput",
  RING_PROGRESS = "ringProgress",
  SEARCH_ICON = "searchIcon",
  SELECT = "select",
  SIDE_MENU = "sideMenu",
  SIDE_MENU_OVERLAY = "sideMenuOverlay",
  SIGN_UP = "signUp",
  TEST_FORM = "testForm",
  TOGGLE = "toggle",
  TOGGLE_SWITCH = "toggleSwitch"
}

export enum CypressTestIds {
  SIGN_UP_FIRST_NAME = "signUpFirst",
  SIGN_UP_LAST_NAME = "signUpLast",
  SIGN_UP_PHONE = "signUpPhone",
  SIGN_UP_PASSWORD = "signUpPassword",
  SIGN_UP_NET_WORTH = "signUpNetWorth",
  SIGN_UP_EMAIL = "signUpEmail",
  SIGN_UP_TERMS_BTN = "signUpTerms",
  SIGN_UP_STREET_ADDRESS = "streetAddress",
  SIGN_UP_CITY = "city",
  SIGN_UP_STATE = "state",
  SIGN_UP_ZIP = "zipCode",
  SIGN_UP_SUBMIT_PERSONAL_INFORMATION_BTN = "submitPersonalInformationButton",

  SIGN_UP_TERMS_ACCOUNT_TERMS_BTN = "accountTermsButton",
  SIGN_UP_TERMS_ACCOUNT_TERMS_CONFIRM_BTN = "accountTermsConfirmButton",
  SIGN_UP_TERMS_BANKING_AGREEMENT_BTN = "bankingAgreementButton",
  SIGN_UP_TERMS_BANKING_AGREEMENT_CONFIRM_BTN = "bankingAgreementConfirmButton",
  SIGN_UP_TERMS_ELECTRONIC_DISCLOSURE_BTN = "electronicDisclosureButton",
  SIGN_UP_TERMS_ELECTRONIC_DISCLOSURE_CONFIRM_BTN = "electronicDisclosureConfirmButton",
  SIGN_UP_TERMS_PRIVACY_POLICY_BTN = "privacyPolicyButton",
  SIGN_UP_TERMS_PRIVACY_POLICY_CONFIRM_BTN = "privacyPolicyConfirmButton",

  SIGN_UP_EMAIL_VERIFICATION_BTN = "emailVerificationButon",
  SIGN_UP_EMAIL_VERIFICATION_CODE_CONTAINER = "emailVerificationCodeContainer",

  SIGN_UP_PHONE_VERIFICATION_BTN = "phoneVerificationButon",
  SIGN_UP_PHONE_VERIFICATION_CODE_CONTAINER = "phoneVerificationCodeContainer",

  SIGN_UP_MFA_VERIFICATION_BTN = "mfaVerificationButon",
  SIGN_UP_ID_VERIFICATION_BTN = "idVerificationButon",
  SIGN_UP_MFA_VERIFICATION_CODE_CONTAINER = "mfaVerificationCodeContainer",
  SIGN_UP_MFA_CODE_COPY = "mfaCodeCopy",

  SIGN_UP_CONTNUE_TO_VERIFICATION_BTN = "continueToVerificationButton",
  SIGN_UP_CREATE_ORGANIZATION_BTN = "creatOrganizationButton",

  SIGN_UP_FINISH_AND_OPEN_ACCOUNT_BTN = "finishAndOpenAccount",

  SIGN_IN_EMAIL = "signInEmail",
  SIGN_IN_PASSWORD = "signInPassword",
  SIGN_IN_MFA_TOKEN_INPUT_FIELD = "signInMfaTokenInputField",
  READ_TERMS_SIGN_IN_BTN = "readTermsSignInBtn",
  CONTINUE_SIGN_IN_BTN = "continueSignInBtn",
  SIGN_IN_BTN = "signInBtn",
  FORGOT_PASSWORD_BTN = "forgotPasswordBtn",
  FORGOT_MY_PASSWORD_BTN = "forgotMyPasswordBtn",
  FORGOT_MY_PASSWORD_EMAIL = "forgotMyPasswordEmail",
  NEW_PASSWORD = "newPassword",
  SUBMIT_NEW_PASSWORD = "resetPassword",
  UPDATE_ORGANIZATION_NAME_BTN = "updateOrganizationNameBtn",
  ORGANIZATION_NAME = "organizationName",
  Q_NAV_MENU_BTN = "qNavMenu",
  Q_NAV_DASHBOARD_BTN = "qNavMenuDashboard",
  Q_NAV_ACCOUNT_BTN = "qNavMenuAccount",
  Q_NAV_SIGN_OUT_BTN = "qNavMenuSignOut",
  Q_ACCOUNT_SAVE = " qAccountSave",
  Q_EARN_POINTS_BTN = "qEarnPoints",
  Q_VERIFY_EMAIL = "qVerifyEmail",
  Q_INVITE_OTHERS_BTN = "qInviteOthers",
  Q_INVITE_COPY_LINK_BTN = "qInviteCopyBtn",
  Q_INVITE_SHARE_BTN = "qInviteShareBtn",
  Q_INVITE_COPY_MSG_BTN = "qInviteCopyMsgBtn",
  Q_VERIFY_PHONE = "qVerifyPhone",
  Q_VERIFY_MFA = "qVerifyMfa",
  Q_VERIFY_MFA_CONTINUE_BTN = "qVerifyMfaContinueBtn",
  ONBOARD_TRUST_ACCOUNT = "onboardTrustAccount",
  ONBOARD_BUSINESS_ACCOUNT = "onboardBusinessAccount",
  ONBOARD_INDIVIDUAL_ACCOUNT = "onboardIndividualAccount",
  TOGGLE_PUSH = "pushToggle",
  TOGGLE_SMS = "smsToggle",
  CANCEL_ACCOUNT = "cancelAccount",
  CONFIRM_CANCEL_ACCOUNT = "confirmCancelAccount",
  WELCOME_SHEET_BUTTON = "welcomeBottomSheet",
  WELCOME_SHEET_TRIGGER = "welcomeBottomSheetTrigger",

  // main menu tabs
  MENU_TAB_BANK = "menuTabBank",
  MENU_TAB_ONELINE = "menuTabOneLine",
  MENU_TAB_DIGITAL_OFFICE = "menuTabDigitalOffice",
  MENU_TAB_INVEST = "menuTabInvest",
  MENU_TAB_MARKETPLACE = "menuTabMarketplace",
  MENU_TAB_ORGSETTINGS = "menuTabOrgSettings",
  MENU_TAB_HELP = "menuTabHelp",
  MENU_TAB_SIGN_OUT = "menuTabSignOut",

  // menu links
  MENU_LINK_BANK_DASHBOARD = "menuLinkBankDashboard",
  MENU_LINK_BANK_CHECKING = "menuLinkBankChecking",
  MENU_LINK_BANK_CONNECTED = "menuLinkBankConnected",
  MENU_LINK_BANK_RECIPIENTS = "menuLinkBankRecipients",
  MENU_LINK_BANK_MOVEMONEY = "menuLinkBankMoveMoney",
  MENU_LINK_BANK_TRANSACTIONS = "menuLinkBankTransactions",
  MENU_LINK_BANK_DEPOSIT_CHECKS = "menuLinkBankDepositChecks",
  MENU_LINK_ACTIVATE_CARDS = "menuLinkActivateCards",
  MENU_LINK_SETTINGS_ORGANIZATION = "menuLinkSettingsOrganization",
  MENU_LINK_SETTINGS_MEMBERS = "menuLinkSettingsMembers",
  MENU_LINK_SETTINGS_REFERRALS = "menuLink",

  ERROR_ALERT = "errorAlert"
}

export const sleep = (time: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

const STAGING_SUFFIX = "letter-staging";

export const fakeEmail = (): string =>
  faker.internet
    .email(faker.name.firstName(), faker.name.lastName(), "letter.co")
    .replace(/@/, `_${STAGING_SUFFIX}@`)
    .toLowerCase();
