import React, { useEffect } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";

import { colorPalette as colors, ColorNames } from "src/theme/theme";
import { AccountsPageEmpty, FeatureExplanation } from "src/routes/app/bank/accounts/Accounts.styles";

import { Button } from "src/components/button/Button";
import AnimatedExternalAccountsIllustration from "src/components/animated-illustration/AnimatedExternalAccountsIllustration";
import ConnectAccountSheet from "src/components/bottom-sheets/connect-account-sheet/ConnectAccountSheet";
import { ConnectedAccountsLoadingSheet } from "src/components/bottom-sheets/connect-account-sheet/ConnectedAccountsLoadingSheet";
import { ConnectedAccountsSelectAccountsSheet } from "src/components/bottom-sheets/connect-account-sheet/ConnectedAccountsSelectAccountsSheet";
import { useShowBottomSheet } from "src/util/useBottomSheet";

import { coreLayoutBodyBackgroundColor } from "src/store/layoutState";
import { isCAConnectingState, expectedNewConnectedAccountsDataState } from "src/store/connectedAccountsState";

type ConnectedAccountsEmptyProps = {
  refetch?: () => void;
};

const ConnectedAccountsEmpty = (props: ConnectedAccountsEmptyProps): JSX.Element => {
  const { showBottomSheet } = useShowBottomSheet();
  const isCAConnecting = useRecoilValue(isCAConnectingState);
  const expectedNewConnectedAccountsData = useRecoilValue(expectedNewConnectedAccountsDataState);
  const onSuccess = () => {
    if (!!props.refetch) props.refetch();
  };

  useEffect(() => {
    setTimeout(() => {
      coreLayoutBodyBackgroundColor(ColorNames.MIDNIGHT);
    }, 0);
  }, []);

  return (
    <AccountsPageEmpty {...pageFadeInMotion}>
      <AnimatedExternalAccountsIllustration />

      <FeatureExplanation {...featureExplanationMotion}>
        {!!isCAConnecting ? (
          <>
            <h2>Connecting your accounts...</h2>
            <p>Your accounts are being connected. This may take a couple of minutes...</p>
          </>
        ) : (
          <>
            <h2>Connect your wealth</h2>
            <p>
              Securely connect your financial accounts (banks, stocks, etc.) to transfer money and get insights into
              your net-worth.
            </p>
            <Button
              raised
              color={ColorNames.WHITE}
              children={<span>Connect an Account</span>}
              onClick={() => showBottomSheet("connect_account_sheet")}
            />
          </>
        )}
      </FeatureExplanation>

      <ConnectedAccountsLoadingSheet id="connected_account_loading_sheet" />
      <ConnectedAccountsSelectAccountsSheet
        id="connected_account_select_accounts"
        accounts={expectedNewConnectedAccountsData}
        onSuccess={onSuccess}
      />
      <ConnectAccountSheet id="connect_account_sheet" onSuccess={onSuccess} />
    </AccountsPageEmpty>
  );
};

export default ConnectedAccountsEmpty;

const pageFadeInMotion = {
  initial: {
    backgroundColor: colors[ColorNames.WHITE].hex
  },
  animate: {
    backgroundColor: colors[ColorNames.MIDNIGHT].hex
  },
  transition: {
    duration: 0.35
  }
};

const featureExplanationMotion = {
  initial: {
    opacity: 0,
    y: 200
  },
  animate: {
    opacity: 1,
    y: 0
  },
  transition: {
    delay: 0.35,
    duration: 1,
    ease: [0.175, 0.8, 0.35, 1]
  }
};
