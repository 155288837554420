import styled, { css, keyframes, FlattenSimpleInterpolation } from "styled-components";

import { ReactComponent as Gradients } from "src/assets/gradients.svg";
import { ReactComponent as Triangle } from "src/assets/triangle.svg";
import { ReactComponent as Circle } from "src/assets/circle.svg";
import { ReactComponent as Rectangle } from "src/assets/rectangle.svg";
import { ReactComponent as Line } from "src/assets/line.svg";
import { ReactComponent as Horse } from "src/assets/icons/horse.svg";
import { ReactComponent as Liquid } from "src/assets/icons/liquid.svg";
import { ReactComponent as Spend } from "src/assets/icons/spend.svg";
import { ReactComponent as Boost } from "src/assets/icons/boost.svg";

import { BadgeProps, BadgeType } from "src/components/badge/Badge";
import { colorPalette as colors, ColorNames, BackgroundImageSize } from "src/theme/theme";

enum horseColors {
  LIGHT = "226,184,144",
  DARK = "130,94,68"
}

export const floatUp = keyframes`
  from {
    transform: translateY(0px);
  }
  to { 
    transform: translateY(-90px);
  }
`;

export const floatDownLeft = keyframes`
  from {
    transform: translateX(0px) translateY(0px);
  }
  to { 
    transform: translatex(-80px) translateY(60px);
  }
`;

export const floatUpRight = keyframes`
  from {
    transform: translateX(0px) translateY(0px);
  }
  to { 
    transform: translateX(150px) translateY(-150px);
  }
`;

export const circle = keyframes`
  from {
    transform: rotate(0deg) translate(-15px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translate(-15px)  rotate(-360deg);
  }
`;

export const circleBack = keyframes`
  from {
    transform: rotate(0deg) translate(-12px) rotate(0deg);
  }
  to {
    transform: rotate(-360deg) translate(-12px)  rotate(360deg);
  }
`;

const badgeThemedGradients: { [key in BadgeType]: string } = {
  Horses: `linear-gradient(210.52deg, rgb(${horseColors.LIGHT}) 11.53%, rgb(${horseColors.DARK}) 78.72%)`,
  AutoVest: `linear-gradient(165.52deg, rgb(${colors[ColorNames.GOLD].rgb}) 11.53%, rgb(${
    colors[ColorNames.GOLD].rgb
  }) 78.72%)`,
  CryptoLine: `linear-gradient(165.52deg, rgb(${colors[ColorNames.PINE].rgb}) 11.53%, rgb(${
    colors[ColorNames.PINE].rgb
  }) 78.72%)`,
  // boost: `linear-gradient(202.25deg, rgb(${colors[ColorNames.CRIMSON].rgb}) 9.29%, rgb(${
  //   colors[ColorNames.CRIMSON].rgb
  // }) 83.54%)`
};

const badgeThemedShadows: { [key in BadgeType]: string } = {
  Horses: `0px 1.88449px 2.01933px rgba(${horseColors.DARK}, 0.33), 0px 4.48464px 4.5105px rgba(${
    horseColors.DARK
  }, 0.227694), 0px 8.99142px 8.61663px rgba(${
    horseColors.DARK
  }, 0.183576), 0px 19.3128px 17.9007px rgba(${horseColors.DARK}, 0.146424), 0px 60px 69px rgba(${
    horseColors.DARK
  }, 0.102306);`,
  AutoVest: `0px 1.88449px 2.01933px rgba(${colors[ColorNames.GOLD].rgb}, 0.33), 0px 4.48464px 4.5105px rgba(${
    colors[ColorNames.GOLD].rgb
  }, 0.227694), 0px 8.99142px 8.61663px rgba(${colors[ColorNames.GOLD].rgb}, 0.183576), 0px 19.3128px 17.9007px rgba(${
    colors[ColorNames.GOLD].rgb
  }, 0.146424), 0px 60px 69px rgba(${colors[ColorNames.GOLD].rgb}, 0.102306);`,
  CryptoLine: `0px 1.88449px 2.01933px rgba(${colors[ColorNames.PINE].rgb}, 0.33), 0px 4.48464px 4.5105px rgba(${
    colors[ColorNames.PINE].rgb
  }, 0.227694), 0px 8.99142px 8.61663px rgba(${colors[ColorNames.PINE].rgb}, 0.183576), 0px 19.3128px 17.9007px rgba(${
    colors[ColorNames.PINE].rgb
  }, 0.146424), 0px 60px 69px rgba(${colors[ColorNames.PINE].rgb}, 0.102306);`,
  // boost: `0px 1.88449px 2.01933px rgba(${colors[ColorNames.CRIMSON].rgb}, 0.33), 0px 4.48464px 4.5105px rgba(${
  //   colors[ColorNames.CRIMSON].rgb
  // }, 0.227694), 0px 8.99142px 8.61663px rgba(${
  //   colors[ColorNames.CRIMSON].rgb
  // }, 0.183576), 0px 19.3128px 17.9007px rgba(${colors[ColorNames.CRIMSON].rgb}, 0.146424), 0px 60px 69px rgba(${
  //   colors[ColorNames.CRIMSON].rgb
  // }, 0.102306);`
};

const iconStyle = css`
  width: 36px;
  height: 36px;
  color: ${colors[ColorNames.WHITE].hex};
`;

export const StyledBadge = styled.div<BadgeProps>`
  width: 56px;
  height: 56px;
  border-radius: 16px;
  box-shadow: ${({ type }): string => badgeThemedShadows[type]};
`;

export const StyledBadgeInner = styled.div<BadgeProps>`
  position: relative;
  width: 56px;
  height: 56px;
  background: ${({ type }): string => badgeThemedGradients[type]};
  box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const StyledTriangle = styled(Triangle)<{ size: BackgroundImageSize }>`
  position: absolute;
  bottom: -50%;

  ${({ size }): FlattenSimpleInterpolation | undefined => {
    switch (size) {
      case BackgroundImageSize.MEDIUM:
        return css`
          width: 26px;
          height: 23px;
          left: -5px;
          animation: ${floatUp} 8s linear infinite;
          path {
            fill: url(#white_transparent_150deg);
          }
        `;
      case BackgroundImageSize.SMALL:
        return css`
          width: 16px;
          height: 14px;
          right: 2px;
          animation: ${floatUp} 10s linear infinite;
          path {
            fill: url(#white_transparent_100deg);
          }
        `;
    }
  }}
`;

export const StyledCircle = styled(Circle)<{ size: BackgroundImageSize }>`
  position: absolute;

  ${({ size }): FlattenSimpleInterpolation | undefined => {
    switch (size) {
      case BackgroundImageSize.MEDIUM:
        return css`
          bottom: 5%;
          left: -20px;
          width: 32px;
          height: 32px;
          animation: ${circle} 12s linear infinite;
          circle {
            fill: url(#white_transparent_140deg);
          }
        `;
      case BackgroundImageSize.SMALL:
        return css`
          top: 5px;
          right: 0;
          width: 16px;
          height: 16px;
          animation: ${circleBack} 14s linear infinite;
          circle {
            fill: url(#white_transparent_150deg);
          }
        `;
    }
  }}
`;

export const StyledRectangle = styled(Rectangle)<{ size: BackgroundImageSize }>`
  position: absolute;

  ${({ size }): FlattenSimpleInterpolation | undefined => {
    switch (size) {
      case BackgroundImageSize.MEDIUM:
        return css`
          width: 30px;
          height: 30px;
          right: -25px;
          top: -30px;
          animation: ${floatDownLeft} 8s linear infinite;
          rect {
            fill: url(#white_transparent_75deg);
          }
        `;
      case BackgroundImageSize.SMALL:
        return css`
          width: 33px;
          height: 13px;
          right: -40px;
          top: 5px;
          animation: ${floatDownLeft} 10s linear infinite;
          rect {
            fill: url(#white_transparent_150deg);
          }
        `;
    }
  }}
`;

export const StyledLine = styled(Line)<{ size: BackgroundImageSize }>`
  position: absolute;

  ${({ size }): FlattenSimpleInterpolation | undefined => {
    switch (size) {
      case BackgroundImageSize.LARGE:
        return css`
          top: 70px;
          left: -135px;
          width: 160px;
          height: 80px;
          animation: ${floatUpRight} 7s linear infinite;
          rect {
            fill: url(#white_transparent_100deg);
          }
        `;
      case BackgroundImageSize.MEDIUM:
        return css`
          top: 30px;
          left: -90px;
          width: 100px;
          height: 60px;
          animation: ${floatUpRight} 9s linear infinite;
          rect {
            fill: url(#white_transparent_10deg);
          }
        `;
      case BackgroundImageSize.SMALL:
        return css`
          top: 60px;
          left: -30px;
          width: 70px;
          height: 40px;
          animation: ${floatUpRight} 12s linear infinite;
          rect {
            fill: url(#white_transparent_170deg);
          }
        `;
    }
  }}
`;

export const HorseIcon = styled(Horse)`
  ${iconStyle}
`;

export const LiquidIcon = styled(Liquid)`
  ${iconStyle}
`;

export const SpendIcon = styled(Spend)`
  ${iconStyle}
`;

export const BoostIcon = styled(Boost)`
  ${iconStyle}
`;

export const SvgGradients = styled(Gradients)`
  width: 0;
  height: 0;
  position: absolute;
`;
