import styled, { css, FlattenSimpleInterpolation } from "styled-components";

export const RingTrack = styled.circle<{ color: string }>`
  fill: none;
  stroke: ${({ color }): string => color};
  opacity: 0.3;
`;

export const RingSpinnerWrapper = styled.g<{
  overlap?: boolean;
  singleColor?: boolean;
}>`
  transform: rotate3d(0, 0, 0, 0);
  transform-origin: 50% 50%;

  ${({ overlap, singleColor }): FlattenSimpleInterpolation | undefined => {
    if (overlap && singleColor) {
      return css`
        > circle:last-of-type {
          stroke: #fff;
          stroke-width: 1px;
        }
      `;
    }
  }}
`;

export const RingBarSpinner = styled.circle<{ color: string }>`
  fill: ${({ color }): string => color};
  stroke: none;
`;

export const RingProgressText = styled.text<{ color: string; size: number }>`
  font-size: ${({ size }): string => (size && size <= 100 ? "20px" : "30px")};
  line-height: 100%;
  letter-spacing: 0.02em;
  font-feature-settings: "tnum" on, "lnum" on;
  fill: ${({ color }): string => color};
`;
