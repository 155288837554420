import { LoaderFunction } from "react-router-dom";
import { getAllDeals } from "src/services/deals";

export const dealLoader: LoaderFunction = async ({ request }) => {
  // const url = new URL(request.url);
  // const dealId = url.searchParams.get("dealId");

  try {
    return await getAllDeals();
  } catch (e) {
    throw e;
  }
};
