import styled from "styled-components";
import { motion } from "framer-motion";
import { NumberText } from "src/components/text";
import { ParagraphLarge, Heading5 } from "src/routes/app/App.styles";
import { StyledFormInputContainer } from "src/components/forms";
import { colorPalette as colors, ColorNames, ThemeColor, fonts, dropShadows } from "src/theme/theme";

import { ActionableButtonRow } from "src/components/actionable-button/ActionableButton.styles";

import createCardPhysical from "src/assets/create-card-physical.jpg";
import createCardVirtual from "src/assets/create-card-virtual.jpg";
import { LayoutFullPage } from "src/components/core-layout/CoreLayout.styles";

export const CardsWrapper = styled(LayoutFullPage)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  min-height: calc(
    100vh - var(--extraDeviceTopPadding) - var(--subnavHeight) - var(--navbarHeight) - var(--layoutTopPadding)
  );
`;

export const CardsPageContent = styled.div`
  padding: 0 30px;

  ${StyledFormInputContainer} {
    margin-bottom: 30px;
  }
`;

export const CardsPageActions = styled(CardsPageContent)`
  margin-bottom: 30px;
`;

export const EmptyCardsInfo = styled.div`
  text-align: center;

  ${ParagraphLarge} {
    margin-bottom: 8px;
  }
`;

export const CardsInfoContainer = styled.div`
  align-self: flex-start;
  justify-content: start;
  align-items: center;
  margin: 50px 0 -40px 0;
  padding: 0 30px 120px 40px;
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-padding: 30px;

  @media (min-width: 768px) {
    justify-content: center;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  & > div {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const CardsInfoContent = styled.div`
  display: inline-flex;
  gap: 30px;
`;

export const CardInfo = styled.div<{ physical?: boolean }>`
  width: 260px;
  height: 320px;
  border-radius: 2px;
  scroll-snap-align: start;
  background-color: ${({ physical }): string =>
    physical ? colors[ColorNames.MIDNIGHT].hex : colors[ColorNames.GRAY4].hex};
  background-image: url(${({ physical }): string => (physical ? createCardPhysical : createCardVirtual)});
  background-size: cover;
  background-position: bottom center;
  color: ${({ physical }): string => (physical ? "white" : colors[ColorNames.MIDNIGHT].hex)};
  box-shadow: ${dropShadows.dark};
  text-align: left;
  padding: 40px 30px;
  cursor: pointer;

  ${Heading5} {
    margin-bottom: 10px;
  }
`;

export const BalanceInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CardsContainer = styled(motion.div)`
  flex: 1 1 0px;
  padding: 10px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  overflow-y: visible;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-padding: 30px;
  
  &::-webkit-scrollbar {
    display: none;
  }

  &.more-than-three {
    justify-content: start;
  }

  @media screen and (max-width: 1060px) {
    &.more-than-two {
      justify-content: start;
    }
  }

  @media screen and (max-width: 500px) {
    justify-content: start;
  }

  & > div {
    margin-right: 30px;
    scroll-snap-align: center;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const EmptyActivateCardsContainer = styled.div`
  text-align: center;
  color: ${colors[ColorNames.MIDNIGHT].hex};
  ${fonts.PARAGRAPH_M}
`;

export const EmptyIcon = styled.div<{ backgroundColor?: ThemeColor; color?: ThemeColor }>`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  margin: 0 auto 20px;
  background-color: ${({ backgroundColor }): string =>
    !!backgroundColor ? colors[backgroundColor].hex : colors[ColorNames.MIDNIGHT].hex};
  color: ${({ color }): string => (!!color ? colors[color].hex : colors[ColorNames.WHITE].hex)};
`;

export const CardSettingsActions = styled.div`
  width: 100%;
  margin: 30px 0;
  ${ActionableButtonRow} {
    padding: 0;
    gap: 20px;
    justify-content: center;

    & > * {
      flex: 0 1 auto;
      max-width: 50%;
    }

    button {
      margin: 0;
      color: ${colors[ColorNames.CRIMSON].hex};
      background: transparent;

      svg {
        color: ${colors[ColorNames.CARDINAL].hex};
      }

      &.replace {
        color: ${colors[ColorNames.PINE].hex};

        svg {
          color: ${colors[ColorNames.TEA].hex};
        }
      }
    }
  }
`;

export const BalanceText = styled.p`
  ${fonts.PARAGRAPH_M}
  color: ${colors[ColorNames.GRAY5].hex};
  text-align: center;
`;

export const BalanceNumber = styled(NumberText)`
  font-size: 48px;
  line-height: 58px;
`;

export const TranxContainer = styled.div`
  margin-top: 53px;
`;
