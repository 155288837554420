import React, { useEffect } from "react";
import classnames from "classnames";
import { useRecoilValue } from "recoil";
import { useReactiveVar } from "@apollo/client";
import { navigationState } from "src/store/navigationState";
import { coreNavbarState } from "src/util/useNavbar";
import { showMenuState } from "src/store/menuState";
import { coreLayoutWrapperHasOpenModal } from "src/store/layoutState";
import { variants } from "src/LetterRoot";
import { LoadingContainer } from "../loading-container/LoadingContainer";

import { LayoutWrapper, LayoutInsideWrap, LayoutInnerContent, LayoutInnerContentWithoutWidthWrap } from "./CoreLayout.styles";
import { ThemeColor } from "src/theme/theme";

type LayoutProps = {
  noTopPadding?: boolean;
  noNavBar?: boolean;
  noInnerContentWidth?: boolean;
  loading?: boolean;
  backgroundColor?: ThemeColor;
  whiteFade?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
};

const Layout = ({ noTopPadding, noNavBar, loading, backgroundColor, whiteFade, children, style, noInnerContentWidth }: LayoutProps) => {
  const navigation = useReactiveVar(navigationState);
  const { hidden } = useRecoilValue(coreNavbarState);
  const showMenu = useRecoilValue(showMenuState);
  const hasOpenModal = useReactiveVar(coreLayoutWrapperHasOpenModal);

  return (
    <LayoutWrapper
      className={classnames({
        "no-top-padding": noTopPadding,
        "no-navbar": noNavBar || hidden,
        "show-menu": showMenu,
        "has-open-modal": hasOpenModal
      })}
      backgroundColor={backgroundColor}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      custom={navigation.direction}
    >
      <LayoutInsideWrap whiteFade={whiteFade}>
        { !!noInnerContentWidth ? (
          <LayoutInnerContentWithoutWidthWrap style={style}>
            {loading && <LoadingContainer />}
            {!loading && <>{children}</>}
          </LayoutInnerContentWithoutWidthWrap>
        ) : (
          <LayoutInnerContent style={style}>
            {loading && <LoadingContainer />}
            {!loading && <>{children}</>}
          </LayoutInnerContent>
        )}
      </LayoutInsideWrap>
    </LayoutWrapper>
  );
};

export default Layout;
