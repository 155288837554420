import React from "react";
import { Holder, LogoSprite } from "src/components/global-svgs/GlobalSvgs.styles";

export const GlobalSvgs = (): JSX.Element => {
  return (
    <Holder>
      <LogoSprite />
    </Holder>
  );
};
