import styled from "styled-components/macro";
import { colorPalette as colors, ColorNames, fonts, ThemeColor } from "src/theme/theme";
import { ListItemTitle } from "src/components/info-list-item/InfoListItem.styles";

export const StyledRingCallout = styled.div<{ color?: ThemeColor }>`
  width: 100%;
  border-radius: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 18px 20px;
  background-color: ${({ color }) => !!color ? colors[color].hex : colors[ColorNames.CREAM].hex};
  color: ${colors[ColorNames.MIDNIGHT].hex};
  ${fonts.PARAGRAPH_S}

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    ${fonts.PARAGRAPH_L}
  }

  .description {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid rgba(${colors[ColorNames.MIDNIGHT].rgb}, .1);
    color: rgba(${colors[ColorNames.MIDNIGHT].rgb}, .4);
  }

  ${ListItemTitle} {
    color: rgba(${colors[ColorNames.MIDNIGHT].rgb}, .4);
  }
`;
