import React, { useState, useRef, useEffect } from "react";

import { StyledInput } from "src/components/forms/input/Input.styles";
import { StyledFieldContainer } from "src/components/forms";
import { StyledLabel } from "src/components/text";
import { DataTestIds } from "src/util/testing-util/test-utils";

export interface CurrencyInputProps {
  label?: string;
  name: string;
  defaultValue?: string | number;
  error?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
  [key: string]: unknown;
}

export const CurrencyInput = ({
  label,
  name,
  defaultValue,
  onChange,
  error = false,
  disabled,
  ...props
}: CurrencyInputProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>();
  const [value, setValue] = useState(toCurrency(defaultValue?.toString() || "0"));
  const [hasError, setHasError] = useState(error);

  useEffect(() => {
    setValue(toCurrency(defaultValue?.toString() || "0"));
  }, [defaultValue]);

  useEffect(() => {
    setHasError(error);
  }, [error]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valueAsCurrency = toCurrency(event.target.value);
    setValue(valueAsCurrency);

    if (onChange) {
      event.persist();
      onChange(valueAsCurrency);
    }

    setHasError(false);
  };

  return (
    <StyledFieldContainer
      onClick={() => {
        inputRef?.current?.focus();
      }}
      error={hasError}
      currency={"$"}
      data-testid={DataTestIds.FIELD_CONTAINER}
    >
      <StyledLabel htmlFor={name} error={hasError} data-testid={DataTestIds.LABEL}>
        {label}
      </StyledLabel>

      <StyledInput
        ref={inputRef}
        data-testid={DataTestIds.INPUT}
        disabled={disabled}
        currency={true}
        inputMode="numeric"
        step="any"
        name={name}
        value={value}
        placeholder="0.00"
        onChange={handleChange}
      />
    </StyledFieldContainer>
  );
};

const getDigitsFromValue = (value = "") => value.replace(/(-(?!\d))|[^0-9|-]/g, "") || "";

const padDigits = (digits: string) => {
  const desiredLength = 3;
  const actualLength = digits.length;

  if (actualLength >= desiredLength) {
    return digits;
  }

  const amountToAdd = desiredLength - actualLength;
  const padding = "0".repeat(amountToAdd);

  return padding + digits;
};

const removeLeadingZeros = (number: string) => number.replace(/^0+([0-9]+)/, "$1");

const addDecimalToNumber = (number: string) => {
  const separator = ".";
  const centsStartingPosition = number.length - 2;
  const dollars = removeLeadingZeros(number.substring(0, centsStartingPosition));
  const cents = number.substring(centsStartingPosition);

  if (dollars.length > 3) {
    return Number(dollars).toLocaleString("en-US") + separator + cents;
  }

  return dollars + separator + cents;
};

const toCurrency = (value: string | undefined) => {
  const digits = getDigitsFromValue(value);
  const digitsWithPadding = padDigits(digits);

  if (digits.length > 13) {
    return "";
  }

  return addDecimalToNumber(digitsWithPadding);
};
