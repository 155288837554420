import React, { useState, useEffect, useMemo, useCallback } from "react";

import { useCurrentUser } from "src/services/users";
import { PartnershipType } from "src/generated/client";
import { showNotice } from "src/store/alertState";
import { useSetBottomSheet, useShowBottomSheet } from "src/util/useBottomSheet";
import ProvidersIframe, { Provider } from "src/components/connected-accounts/Providers";
import { ColorNames } from "src/theme/theme";

import {
  ProvidersContainer,
  ProvidersHeader,
  ProvidersContent,
  ProvidersFooter,
  CAModalAccountTypeButton,
  CAModalTitle
} from "./ConnectAccountSheet.styles";

import { Button } from "src/components/button/Button";
import { ReactComponent as IconClose } from "src/assets/icons/close.svg";
import { ReactComponent as IconBank } from "src/assets/icons/external_bank.svg";
import { ReactComponent as IconCrypto } from "src/assets/icons/external_crypto.svg";


interface ComponentProps {
  id: string;
  plaidUpdateToken?: string;
  onSuccess?: () => void;
}

const ConnectAccountSheet = (props: ComponentProps): JSX.Element => {
  const currentUser = useCurrentUser();
  const setBottomSheet = useSetBottomSheet();
  const { hideBottomSheet } = useShowBottomSheet();
  
  const [provider, setProvider] = useState<Provider>("Plaid");
  // const [provider, setProvider] = useState<Provider>(props.plaidUpdateToken || currentUser?.partnershipType === PartnershipType.TetonRidge ? "Plaid" : "Teller");
  const [showWidget, setShowWidget] = useState(!!props.plaidUpdateToken);
  const [tellerReady, setTellerReady] = useState(false);
  const [plaidReady, setPlaidReady] = useState(false);

  useEffect(() => {
    if (currentUser?.partnershipType === PartnershipType.TetonRidge)
      setProvider("Plaid");
  }, [currentUser?.partnershipType]);

  const toggleProvider = () => {
    if (provider === "Plaid") {
      setProvider("Teller");
    } else if (provider === "Teller") {
      setProvider("Plaid");
    }
  };

  const onReady = ({ teller, plaid }: { teller: boolean; plaid: boolean }) => {
    setTellerReady(teller);
    setPlaidReady(plaid);
  };

  const onSuccess = () => {
    if (!!props.onSuccess) props.onSuccess();
  };

  const onClose = useCallback(() => {
    // setProvider(props.plaidUpdateToken || currentUser?.partnershipType === PartnershipType.TetonRidge ? "Plaid" : "Teller");
    setShowWidget(false);
  }, [setProvider, setShowWidget, currentUser]);

  const bottomSheetChildren = useMemo(
    () => (
      <>
        {showWidget ? (
          <ProvidersContainer>
            <ProvidersHeader>
              <span>Connect External Account</span>
              <IconClose
                onClick={() => {
                  hideBottomSheet();
                  onClose();
                }}
              />
            </ProvidersHeader>
            <ProvidersContent>
              <ProvidersIframe
                provider={provider}
                plaidUpdateToken={props.plaidUpdateToken}
                onReady={onReady}
                onSuccess={onSuccess}
              />
            </ProvidersContent>
            {/* {((provider === "Teller" && plaidReady) || (provider === "Plaid" && tellerReady)) &&
              !props.plaidUpdateToken && currentUser?.partnershipType !== PartnershipType.TetonRidge && (
                <ProvidersFooter>
                  <div>Can't find your institution?</div>
                  <div className="expanded" onClick={() => toggleProvider()}>
                    {provider === "Teller" ? "Expanded" : "Simplified"} Search
                  </div>
                </ProvidersFooter>
              )} */}
          </ProvidersContainer>
        ) : (
          <>
            <CAModalTitle>Choose an Account Type</CAModalTitle>
            <CAModalAccountTypeButton onClick={() => setShowWidget(true)}>
              <div className="body-text-area">
                <h4>Traditional</h4>
                <p>Connect to your bank, stock and financial accounts.</p>
              </div>
              <div className="icon-area traditional">
                <IconBank />
              </div>
            </CAModalAccountTypeButton>
            <CAModalAccountTypeButton
              onClick={() => {
                showNotice("Crypto accounts are currently unavailable.");
              }}
              className="disabled"
            >
              <div className="body-text-area">
                <h4>Crypto</h4>
                <p>Connect to your exchange or hard-wallet accounts.</p>
              </div>
              <div className="icon-area crypto">
                <IconCrypto />
              </div>
            </CAModalAccountTypeButton>
            <Button color={ColorNames.TRANSPARENT} children={<span>Close</span>} onClick={() => hideBottomSheet()} />
          </>
        )}
      </>
    ),
    [showWidget, setShowWidget, hideBottomSheet, provider, plaidReady, tellerReady, toggleProvider, onClose, currentUser?.partnershipType, props.plaidUpdateToken]
  );

  useEffect(() => {
    setBottomSheet({
      id: props.id,
      children: bottomSheetChildren,
      fullHeight: showWidget,
      noPadding: showWidget,
      onClose
    });
  }, [bottomSheetChildren, props, setBottomSheet, showWidget, onClose]);

  return <></>;
};

export default ConnectAccountSheet;
