import React from "react";
import { StyledStepsContainer } from "./StepsContainer.styles";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? "100%" : "-100%"
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    transition: {
      ease: "backOut",
      duration: 0.35
    }
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? "100%" : "-100%",
      transition: {
        ease: "backIn",
        duration: 0.3
      }
    };
  }
};

export const StepsContainer = ({
  children,
  stepDirection,
  noPadding
}: {
  children: JSX.Element[] | JSX.Element | string;
  stepDirection?: number;
  noPadding?: boolean;
}) => {
  return (
    <StyledStepsContainer
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      custom={stepDirection}
      noPadding={noPadding}
      children={children}
    />
  );
};
