import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider, useLocation, useNavigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { useRecoilState, useSetRecoilState } from "recoil";
import { coreLayoutBodyBackgroundColor } from "src/store/layoutState";
import { routes } from "src/routes";
import { LoadingPage } from "src/components/loading-container/LoadingPage";
import {
  coreSelectedSubnavTab,
  coreSubnavState,
  defaultSelectedSubnavTab,
  defaultSubnavOptions
} from "./util/useSubnav";
import { NavigationDirection, navigationState } from "src/store/navigationState";
import { showOrgSwitcherState } from "src/store/orgSwitcherState";
import { showMenuState } from "./store/menuState";
import { useShowDetailPicker } from "src/util/useDetailPicker";
import { TRANSITION_DURATION_PADDED } from "src/LetterRoot";
import { ColorNames } from "./theme/theme";


export const RouteWatcher = () => {
  const navigation = useReactiveVar(navigationState);
  const navigate = useNavigate();
  const location = useLocation();
  const [subnav, setSubnav] = useRecoilState(coreSubnavState);
  const setTab = useSetRecoilState(coreSelectedSubnavTab);
  const setMenu = useSetRecoilState(showMenuState);
  const { hideDetailPicker } = useShowDetailPicker();

  // NAVIGATION
  useEffect(() => {
    if (!!navigation.to) {
      if (navigation.to !== "back") {
        navigate(navigation.to, {
          replace: navigation.direction === NavigationDirection.REPLACE
        });
      } else {
        navigate(-1);
      }

      setTimeout(
        () => {
          navigationState({ to: undefined, direction: NavigationDirection.BACKWARD, transitioning: false });
        },
        navigation.direction === NavigationDirection.REPLACE ? 0 : TRANSITION_DURATION_PADDED
      );
    }
  }, [navigation]);

  // ROUTE CHANGE CLEANUPS
  useEffect(() => {
    if (subnav.length > 0) {
      setSubnav(defaultSubnavOptions);
      setTimeout(() => {
        setTab(defaultSelectedSubnavTab);
      }, TRANSITION_DURATION_PADDED);
    }
    showOrgSwitcherState(false);
    setMenu(false);
    hideDetailPicker();
    coreLayoutBodyBackgroundColor(ColorNames.WHITE);
    // eslint-disable-next-line
  }, [location.pathname]);

  return <></>;
};

const router = createBrowserRouter(routes);

export const Router = () => {
  return <RouterProvider fallbackElement={<LoadingPage />} router={router} />;
};
