import styled from "styled-components";
import { colorPalette as colors, ColorNames, DefaultTheme, fonts } from "src/theme/theme";

export interface StyledLabelProps {
  htmlFor: string;
  theme: DefaultTheme;
  error?: boolean;
}

export const NumberText = styled.p`
  letter-spacing: 0.02em;
  font-feature-settings: "tnum" on, "lnum" on;
`;

export const StyledLabelContainer = styled.div`
  flex: 1 1 0px;
  min-width: 0px;
  overflow: hidden;
`;

export const StyledLabel = styled.label<StyledLabelProps>`
  ${fonts.PARAGRAPH_S}
  display: flex;
  align-items: center;
  color: ${({ error }): string => (error ? colors[ColorNames.CARDINAL].hex : colors[ColorNames.GRAY5].hex)};
  transition: color 0.2s ease-out 0s;
`;

export const StyledPlaceholderLabel = styled.label<{ isPlaceholder?: boolean }>`
  ${fonts.PARAGRAPH_M}
  color: ${({ isPlaceholder }): string =>
    isPlaceholder ? colors[ColorNames.GRAY3].hex : colors[ColorNames.MIDNIGHT].hex};
  margin-top: 2px;
`;

export const CtaBanner = styled.p`
  color: ${colors[ColorNames.MIDNIGHT].hex};
  ${fonts.PARAGRAPH_XL}
  margin-bottom: 19px;
`;

export const CtaTitle = styled.p`
  color: ${colors[ColorNames.MIDNIGHT].hex};
  ${fonts.PARAGRAPH_M}
`;

export const CtaDescription = styled.p`
  color: ${colors[ColorNames.GRAY5].hex};
  ${fonts.PARAGRAPH_S}
  margin-top: 7px;
`;
