import React, { useEffect, useState, useCallback } from "react";
import { showNotice } from "src/store/alertState";

import { MfaContentContainer, MfaDescription, StepHeader, InputContainer } from "src/pages/mfa/mfa.styles";
import { CodeInput } from "src/components/forms/code-input/CodeInput";
import { ReadOnlyInput } from "src/components/forms/read-only-input/ReadOnlyInput";
import { LoadingContainer } from "src/components/loading-container/LoadingContainer";
import { useGenerateMultiFactor, useVerifyMultiFactor, MultiFactor } from "src/services/auth";
import { CypressTestIds } from "src/util/testing-util/test-utils";
import { LoadingSkeleton } from "src/components/loading-skeleton/LoadingSkeleton";

const VerifyMfa = ({ onSuccess }: { onSuccess: () => void }): JSX.Element => {
  const [multiFactorCode, setMultiFactorCode] = useState<string>("");
  const [multiFactorQr, setMultiFactorQrImage] = useState<string | null>(null);
  const { generateMultiFactor, error, loading } = useGenerateMultiFactor();
  const { verifyMultiFactor, error: verifyError } = useVerifyMultiFactor(true);
  const [mfaError, setMfaError] = useState(false);
  

  // Needed when calling generateMultiFactor from useEffect hook
  const generate = useCallback(generateMultiFactor, []);

  useEffect(() => {
    (async (): Promise<void> => {
      const multiFactorData: MultiFactor | void = await generate();
      if (!!multiFactorData) {
        setMultiFactorCode(multiFactorData.secret);
        setMultiFactorQrImage(multiFactorData.qr);
      }
    })();
  }, [generate]);

  useEffect(() => {
    if (error || verifyError || mfaError) {
      showNotice(!!error ? "There was a problem generating your code." : "There was a problem verifying your code.", {
        error: true
      });
    }
  }, [error, verifyError, mfaError]);

  const handleSubmit = async (token: string): Promise<void> => {
    setMfaError(false);

    if (!token) {
      setMfaError(true);
    } else {
      const authInfo = await verifyMultiFactor(token);
      if (authInfo && authInfo.session) {
        onSuccess();
      } else {
        setMfaError(true);
      }
    }
  };

  if (loading) return <LoadingContainer height={"50vh"} />;

  return (
    <MfaContentContainer>
      <div>
        <StepHeader>Step 2</StepHeader>
        <MfaDescription>
          Copy and paste the MFA Code below<span className="desktop-only">, or scan the QR code below,</span>into
          the authenticator app you installed in the previous step. Then enter the 6-digit verification code it
          generates below.
        </MfaDescription>

        <InputContainer>
          <ReadOnlyInput name="mfaCode" label="MFA Code" value={multiFactorCode} copy />
        </InputContainer>

        <div className="desktop-only">
          <div style={{ marginBottom: "40px" }}>
            {!!multiFactorQr ? 
              <img
                src={multiFactorQr}
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  width: "150px"
                }}
                alt={"QR Code"}
              />
            : <LoadingSkeleton width={150} height={150} noMargin />}
          </div>
        </div>
        <MfaDescription style={{ textAlign: "center" }}>6-digit verification code:</MfaDescription>
      </div>

      <CodeInput
        dataCy={CypressTestIds.SIGN_UP_MFA_VERIFICATION_CODE_CONTAINER}
        onSubmit={handleSubmit}
        actionText="Enable MFA"
        mfa
      />
    </MfaContentContainer>
  );
};

export default VerifyMfa;
