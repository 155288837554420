import React, { useEffect, useState } from "react";
import { useGetTransactionsForOrg } from "src/services/organizations";
import { showNotice } from "src/store/alertState";
import useNavigate from "src/util/useNavigate";
import { NavRoutes } from "src/routes/navRoutes";

import { AppContentContainer } from "src/routes/app/App.styles";
import {
  TransactionItems,
  EmptyTransactionInfo,
  EmptyTransactionInfoTitle,
  EmptyTransactionItems,
  EmptyTransactionItem,
  TransactionItemLeftContainer,
  EmptyTransactionItemTitle,
  TransactionItemSubtitle,
  TransactionItemAmountContainer
} from "src/components/transaction-item/TransactionItem.styles";
import TransactionDetail from "./TransactionDetail";

import { TransactionItem } from "src/components/transaction-item/TransactionItem";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { ActionableButtonGroup } from "src/components/actionable-button/ActionableButton.styles";
import { ColorNames } from "src/theme/theme";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { BottomSheet } from "src/components/bottom-sheets/BottomSheet";

import { ReactComponent as IconBank } from "src/assets/icons/external_bank.svg";
import { ReactComponent as IconTransfer } from "src/assets/icons/transfer.svg";
import { useNavbar } from "src/util/useNavbar";
import Layout from "src/components/core-layout/Layout";
import { Transaction } from "src/generated/client";

const Transactions = (): JSX.Element => {
  useNavbar({ title: "Transactions" });
  const { transactions, loading, error } = useGetTransactionsForOrg();
  
  const navigate = useNavigate();
  const { showBottomSheet } = useShowBottomSheet();
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | undefined>();

  useEffect(() => {
    if (error) {
      showNotice(error.message || "There was a problem loading your transactions.", { error: true });
    }
  }, [error]);

  return (
    <Layout loading={loading} whiteFade={!transactions || transactions.length > 0}>
      <AppContentContainer topPadding={!transactions || transactions.length > 0}>
        {!loading && (!transactions || transactions.length === 0) && (
          <>
            <EmptyTransactionInfo>
              <EmptyTransactionInfoTitle>No Transactions Yet</EmptyTransactionInfoTitle>
              You don't have any transactions yet. Move money to your Letter account and you'll start seeing your
              transactions here.
            </EmptyTransactionInfo>
            <ActionableButtonGroup>
              <ActionableButton
                onClick={() => navigate(NavRoutes.BANK_CONNECTED_ACCOUNTS)}
                color={ColorNames.PINE}
                label="Connect External Account"
                iconLeft={<IconBank />}
              />
              <ActionableButton
                onClick={() => navigate(NavRoutes.BANK_TRANSFER_SEND)}
                color={ColorNames.MINT}
                label="Move Money"
                iconLeft={<IconTransfer />}
              />
            </ActionableButtonGroup>
            <EmptyTransactionItems>
              {[...Array(4)].map((_, i) => (
                <EmptyTransactionItem key={i}>
                  <TransactionItemLeftContainer>
                    <EmptyTransactionItemTitle></EmptyTransactionItemTitle>
                    <TransactionItemSubtitle></TransactionItemSubtitle>
                  </TransactionItemLeftContainer>
                  <TransactionItemAmountContainer></TransactionItemAmountContainer>
                </EmptyTransactionItem>
              ))}
            </EmptyTransactionItems>
          </>
        )}

        {!loading && !!transactions && transactions.length > 0 && (
          <TransactionItems>
            {transactions.map((transaction) => (
              <TransactionItem
                key={transaction.id}
                transaction={transaction}
                clickHandler={() => {
                  setSelectedTransaction(transaction);
                  setTimeout(() => {
                    showBottomSheet("transaction_item_sheet");
                  }, 0);
                }}
              />
            ))}
          </TransactionItems>
        )}
        <BottomSheet noPadding closeOnOutsideClick={true} id="transaction_item_sheet">
          <TransactionDetail transaction={selectedTransaction} />
        </BottomSheet>
      </AppContentContainer>
    </Layout>
  );
};

export default Transactions;
