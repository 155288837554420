import React, { useEffect, useState } from "react";
import { useFetcher } from "react-router-dom";

import { useCurrentUser } from "src/services/users";
import { fetchAlerts } from "src/services/alerts";
import { addAlert, removeAlert, showNotice } from "src/store/alertState";
import {
  useTriggerVerification,
  useAddVerificationToUser,
  UserVerificationType,
} from "src/services/users";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { formatPhoneNumber } from "src/util/stringUtils";
import { NavRoutes } from "src/routes/navRoutes";

import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import ConnectAccountSheet from "src/components/bottom-sheets/connect-account-sheet/ConnectAccountSheet";
import { AlertType } from "src/generated/client";

const useAlertChecks = (): JSX.Element => {
  const currentUser = useCurrentUser();
  const { submit: connectedAccountsSubmit } = useFetcher();
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();

  const [needsPhoneVerification, setNeedsPhoneVerification] = useState(false);
  const [plaidEnrollmentUpdate, setPlaidEnrollmentUpdate] = useState<string>();

  const {
    trigger,
    loading: triggerLoading,
    error: triggerError,
  } = useTriggerVerification();
  const {
    addVerification,
    loading: verificationInProgress,
    error: verificationError,
  } = useAddVerificationToUser();

  useEffect(() => {
    if (!!needsPhoneVerification) {
      addAlert("alert_verified_phone", {
        description: "You must verify your phone number to continue.",
        actionText: "Verify",
        loading: triggerLoading,
        onAction: async () => {
          if (triggerLoading) return;
          const triggered = await trigger(UserVerificationType.Phone);
          if (triggered) {
            showBottomSheet("alerts_verify_phone_sheet");
          } else {
            showNotice(
              `There was a problem sending a verification code, please ensure your phone number is correct.`,
              { error: true }
            );
          }
        },
      });
    }
  }, [needsPhoneVerification, triggerLoading]);

  useEffect(() => {
    if (!!plaidEnrollmentUpdate) {
      addAlert("alert_plaid_enrollment_update", {
        description:
          "You have expired Connected Account connections. Some institutions require you to reconnect periodically.",
        actionText: "Reconnect",
        loading: false,
        onAction: () => showBottomSheet("alerts_reconnect_plaid_sheet"),
      });
    }
  }, [plaidEnrollmentUpdate]);

  useEffect(() => {
    if (!!triggerError) {
      showNotice(
        "There was a problem sending a verification code, please ensure your phone number is correct.",
        {
          error: true,
        }
      );
    } else if (!!verificationError) {
      showNotice("There was a problem verifying your code, please try again.", {
        error: true,
      });
    }
  }, [triggerError, verificationError]);

  useEffect(() => {
    (async () => {
      if (!!currentUser && currentUser.onboarded) {
        if (!currentUser.verified.phone) setNeedsPhoneVerification(true);

        try {
          const alerts = await fetchAlerts();
          if (!!alerts) {
            const plaidUpdateAlert = alerts.find(
              (alert) => alert.key === AlertType.PlaidEnrollmentUpdate
            );
            if (!!plaidUpdateAlert)
              setPlaidEnrollmentUpdate(plaidUpdateAlert.value);
          }
        } catch {}
      }
    })();
  }, [currentUser]);

  return (
    <>
      {needsPhoneVerification && (
        <ActionBottomSheet
          id="alerts_verify_phone_sheet"
          title="Verify your phone number to continue."
          description={`We’ve sent a verification code to your phone number ${formatPhoneNumber(
            currentUser?.phone || ""
          )}. Enter that code below.`}
          actionText="Verify"
          secondaryActionText="Resend Code"
          onAction={async (token: string) => {
            const user = await addVerification({
              verificationType: UserVerificationType.Phone,
              token,
              userId: currentUser?.id || "",
            });
            if (!!user) {
              hideBottomSheet();
              removeAlert("alert_verified_phone");
              showNotice("You have successfully verified your phone number.");
            }
          }}
          onSecondaryAction={() => trigger(UserVerificationType.Phone)}
          codeInput
          autoSubmit
          actionLoading={verificationInProgress}
          secondaryActionLoading={triggerLoading}
        />
      )}

      {plaidEnrollmentUpdate && (
        <ConnectAccountSheet
          id="alerts_reconnect_plaid_sheet"
          plaidUpdateToken={plaidEnrollmentUpdate}
          onSuccess={() => {
            hideBottomSheet();
            removeAlert("alert_plaid_enrollment_update");
            setPlaidEnrollmentUpdate(undefined);
            showNotice("Your accounts have been successfully reconnected.");
            connectedAccountsSubmit(
              {},
              { method: "put", action: NavRoutes.API_CONNECTED_ACCOUNT }
            );
          }}
        />
      )}
    </>
  );
};

export default useAlertChecks;
