import { useState, useEffect, useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { useMutation, useQuery, useLazyQuery, ApolloError, ApolloQueryResult } from "@apollo/client";
import { client } from "src";
import { useLogger } from "src/util/useLogger";
import {
  isCAConnectingState,
  expectedNumberOfConnectedAccountsState,
  expectedNewConnectedAccountsDataState
} from "src/store/connectedAccountsState";

import {
  CONNECT_CONNECTED_ACCOUNTS,
  GET_CONNECTED_TOKEN,
  REFRESH_CONNECTED_ACCOUNTS,
  REFRESH_CONNECTED_ACCOUNT,
  GET_UPDATE_TOKEN,
  UPDATE_CONNECTED_ACCOUNT,
  DISCONNECT_CONNECTED_ACCOUNT,
  GET_CONNECTED_ACCOUNTS,
  GET_CONNECTED_ACCOUNT_BALANCE_BY_ID,
  GET_CONNECTED_ACCOUNT_BY_ID
} from "src/services/gql/_connected-accounts";
import {
  Account,
  AccountBalancesGrouped,
  ConnectedAccount,
  ConnectedAccountsListQueryVariables
} from "src/generated/client";

export type Provider = "Teller" | "Plaid" | "Vezgo";

export type ConnectVariables = {
  input: {
    token: string;
    enrollmentId?: string;
    provider: Provider;
  };
};

export type ConnectProvider = {
  connectConnectedAccountProvider: {
    expectedNewAccountsNumber: number;
    expectedNewAccountsData?: ConnectedAccount[];
  };
};

export type UpdateConnectedAccount = {
  editConnectedAccount: {
    nickname: string;
  };
};

export type UpdateConnectedAccountVariables = {
  accountId: string;
  nickname: string;
};

export type ConnectedAccountData = {
  connectedAccountsList: {
    connectedAccounts: ConnectedAccount[];
  };
};

export type DisconnectConnectedAccount = {
  disconnectConnectedAccount: {
    success: boolean;
  };
};

export type DisconnectConnectedAccountVariables = {
  input: {
    accountId?: string;
    idList?: string[];
  };
};

export type ProviderTokenInput = {
  input: {
    accessToken?: string;
    provider: Provider;
  };
};

export type TokenResult = {
  getConnectedToken: {
    token?: string;
  };
};

export type RefreshAccountResult = {
  refreshConnectedAccount: ConnectedAccount;
};

type Result = {
  refreshConnectedAccounts: {
    success: boolean;
  };
};

export const useUpdateConnectedAccount = (): {
  updateConnectedAccount: (values: { accountId: string; nickname: string }) => Promise<boolean>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [account, { loading, error }] = useMutation<UpdateConnectedAccount, UpdateConnectedAccountVariables>(
    UPDATE_CONNECTED_ACCOUNT,
    {
      errorPolicy: "all"
    }
  );
  const { captureException } = useLogger();

  const updateConnectedAccount = useCallback(
    async ({ accountId, nickname }: { accountId: string; nickname: string }): Promise<boolean> => {
      try {
        const { data } = await account({ variables: { accountId, nickname } });
        return !!data?.editConnectedAccount;
      } catch (err) {
        captureException(err);
        return false;
      }
    },
    [account, captureException]
  );

  return { updateConnectedAccount, loading, error };
};

export const useDeleteConnectedAccount = (): {
  deleteConnectedAccount: ({ accountId, idList }: { accountId?: string; idList?: string[] }) => Promise<boolean>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [account, { loading, error }] = useMutation<DisconnectConnectedAccount, DisconnectConnectedAccountVariables>(
    DISCONNECT_CONNECTED_ACCOUNT,
    {
      errorPolicy: "all"
    }
  );
  const { captureException } = useLogger();

  const deleteConnectedAccount = useCallback(
    async ({ accountId, idList }: { accountId?: string; idList?: string[] }): Promise<boolean> => {
      try {
        let call;
        if (!!idList && idList.length > 0) {
          call = await account({ variables: { input: { idList } } });
        } else {
          call = await account({ variables: { input: { accountId } } });
        }
        return Boolean(call.data?.disconnectConnectedAccount?.success);
      } catch (err) {
        captureException(err);
        return false;
      }
    },
    [account, captureException]
  );

  return { deleteConnectedAccount, loading, error };
};

export const getConnectedAccounts = async ({
  fromNetwork,
  moveMoneyCapable,
  selectedId
}: {
  fromNetwork?: boolean;
  moveMoneyCapable?: boolean;
  selectedId?: string;
}) => {
  const res = await client.query({
    query: GET_CONNECTED_ACCOUNTS,
    errorPolicy: "all",
    fetchPolicy: fromNetwork ? "network-only" : "cache-first",
    variables: {
      moveMoneyCapable,
      selectedId
    }
  });

  if (!!res.data?.connectedAccountsList?.connectedAccounts) {
    return res.data.connectedAccountsList.connectedAccounts;
  } else {
    throw res.errors;
  }
};

export const useGetConnectedAccounts = (
  moveMoneyCapable?: boolean,
  selectedId?: string
): {
  accounts: ConnectedAccount[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetchConnectedAccounts: (
    input?: ConnectedAccountsListQueryVariables
  ) => Promise<ApolloQueryResult<ConnectedAccountData>>;
} => {
  const { data, loading, error, refetch } = useQuery<ConnectedAccountData>(GET_CONNECTED_ACCOUNTS, {
    errorPolicy: "all",
    variables: {
      moveMoneyCapable,
      selectedId
    }
  });

  const accounts = data?.connectedAccountsList?.connectedAccounts || [];

  return { accounts, refetchConnectedAccounts: refetch, loading, error };
};

export const useGetConnectedAccountBalancesById = (): {
  getBalances: (accountId: string, timeframe: string) => void;
  accountBalances: AccountBalancesGrouped[];
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [accountBalances, setAccountBalances] = useState([]);
  const [balances, { data, loading, error }] = useLazyQuery(GET_CONNECTED_ACCOUNT_BALANCE_BY_ID, {
    errorPolicy: "all"
  });

  const getBalances = useCallback(
    (accountId: string, timeframe: string) => {
      balances({ variables: { accountId, timeframe } });
    },
    [balances]
  );

  useEffect(() => {
    if (data?.connectedAccount?.balances) {
      setAccountBalances(data.connectedAccount.balances);
    }
  }, [data]);

  return { getBalances, accountBalances, loading, error };
};

export const useGetConnectedAccountById = (
  accountId: string
): {
  account: ConnectedAccount | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetchAccount: (variables?: Partial<{ accountId: string }> | undefined) => Promise<ApolloQueryResult<any>>;
} => {
  const [account, setAccount] = useState<ConnectedAccount>();
  const [loading, setLoading] = useState(false);

  const { data, error, refetch } = useQuery<{
    connectedAccount: ConnectedAccount;
  }>(GET_CONNECTED_ACCOUNT_BY_ID, {
    errorPolicy: "all",
    variables: { accountId }
  });

  useEffect(() => {
    if (data) {
      setAccount(data.connectedAccount);
    }
  }, [data, account]);

  return { account, loading, error, refetchAccount: refetch };
};

export const useLazyGetConnectedAccountById = (): {
  getAccount: (options?: any | undefined) => void;
  accountData: Account | undefined;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [account, { data, loading, error }] = useLazyQuery(GET_CONNECTED_ACCOUNT_BY_ID, { errorPolicy: "all" });
  const [accountData, setAccountData] = useState<Account>();

  const getAccount = useCallback(
    (accountId: string) => {
      account({ variables: { accountId } });
    },
    [account]
  );

  useEffect(() => {
    if (!!data?.connectedAccount) {
      setAccountData(data?.connectedAccount);
    }
  }, [data]);

  return { getAccount, accountData, loading, error };
};

export const useConnect = (): {
  connect: (values: ConnectVariables["input"]) => Promise<ConnectedAccount[] | boolean | void>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [connect, { loading, error }] = useMutation<ConnectProvider, ConnectVariables>(CONNECT_CONNECTED_ACCOUNTS, {
    errorPolicy: "all"
  });
  const { captureException } = useLogger();
  const { refetchConnectedAccounts } = useGetConnectedAccounts();
  const setIsCAConnecting = useSetRecoilState(isCAConnectingState);
  const setExpectedNumberOfConnectedAccounts = useSetRecoilState(expectedNumberOfConnectedAccountsState);
  const setExpectedNewConnectedAccountsDataState = useSetRecoilState(expectedNewConnectedAccountsDataState);

  const connectProvider = useCallback(
    async (input: ConnectVariables["input"]): Promise<ConnectedAccount[] | boolean | void> => {
      try {
        const { data } = await connect({
          variables: { input }
        });

        const expectedNewAccountsNumber = data?.connectConnectedAccountProvider?.expectedNewAccountsNumber;
        const expectedNewAccountsData = data?.connectConnectedAccountProvider?.expectedNewAccountsData;
        const connected = Boolean(expectedNewAccountsNumber);
        setIsCAConnecting(connected);

        if (connected) {
          setExpectedNumberOfConnectedAccounts(expectedNewAccountsNumber || 0);
          expectedNewAccountsData && setExpectedNewConnectedAccountsDataState(expectedNewAccountsData);
          // await refetchConnectedAccounts();
        }
        return expectedNewAccountsData || connected;
      } catch (e) {
        captureException(e);
        setIsCAConnecting(false);
        throw e;
      }
    },
    [connect, captureException, refetchConnectedAccounts, setIsCAConnecting]
  );

  return { connect: connectProvider, loading, error };
};

export const usePlaidToken = (): {
  getToken: (values: ProviderTokenInput["input"]) => Promise<string | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [getConnectedToken, { loading, error }] = useMutation<TokenResult, ProviderTokenInput>(GET_CONNECTED_TOKEN, {
    errorPolicy: "all"
  });
  const { captureException } = useLogger();

  const connectProvider = useCallback(
    async (input: ProviderTokenInput["input"]): Promise<string | undefined> => {
      try {
        const { data } = await getConnectedToken({
          variables: { input }
        });

        return data?.getConnectedToken?.token;
      } catch (error) {
        captureException(error);
      }
    },
    [getConnectedToken, captureException]
  );

  return { getToken: connectProvider, loading, error };
};

export const useRefreshConnectedAccount = () => {
  const [refreshConnectedAccount, { loading, error }] = useMutation<RefreshAccountResult, { accountId: string }>(
    REFRESH_CONNECTED_ACCOUNT,
    {
      errorPolicy: "all"
    }
  );
  const { captureException } = useLogger();

  const refresh = useCallback(
    async (accountId: string): Promise<ConnectedAccount | undefined> => {
      try {
        const { data } = await refreshConnectedAccount({
          variables: { accountId }
        });

        return data?.refreshConnectedAccount;
      } catch (error) {
        captureException(error);
      }
    },
    [refreshConnectedAccount, captureException]
  );

  return { refresh, loading, error };
};

export const refreshConnectedAccounts = async () => {
  const res = await client.mutate({
    mutation: REFRESH_CONNECTED_ACCOUNTS,
    errorPolicy: "all"
  });

  if (!!res.data?.refreshConnectedAccounts) {
    return res.data.refreshConnectedAccounts;
  } else {
    throw res.errors;
  }
};

export const useRefreshConnectedAccounts = () => {
  const [refreshConnectedAccounts, { loading, error }] = useMutation<Result>(REFRESH_CONNECTED_ACCOUNTS, {
    errorPolicy: "all"
  });
  const { captureException } = useLogger();

  const refreshAllAccounts = useCallback(async (): Promise<void> => {
    try {
      const { data } = await refreshConnectedAccounts();

      if (data?.refreshConnectedAccounts?.success) {
        getConnectedAccounts({ fromNetwork: true });
      }
    } catch (error) {
      captureException(error);
    }
  }, [refreshConnectedAccounts, captureException]);

  return { refreshAllAccounts, loading, error };
};

export const getConnectedAccountUpdateToken = async ({ enrollmentId }: { enrollmentId: string }) => {
  const res = await client.query({
    query: GET_UPDATE_TOKEN,
    errorPolicy: "all",
    fetchPolicy: "network-only",
    variables: { enrollmentId }
  });

  if (!!res.data?.connectedUpdateToken?.token) {
    return res.data.connectedUpdateToken.token;
  } else {
    throw res.errors;
  }
};
