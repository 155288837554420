import styled from "styled-components/macro";
import { colorPalette as colors, ColorNames, fonts, borderRadiusOuter, borderRadius } from "src/theme/theme";
import { motion } from "framer-motion";
import { LayoutFullPage } from "src/components/core-layout/CoreLayout.styles";
import { StyledDetailRows } from "src/components/detail-row/DetailRow.styles";

export const RecipientsPage = styled.div`
  min-height: calc(100vh - var(--navbarHeight) - var(--layoutTopPadding));
  @supports (-webkit-touch-callout: none) {
    min-height: calc(-webkit-fill-available - var(--navbarHeight) - var(--layoutTopPadding));
  }
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${colors[ColorNames.WHITE].hex};
`;

export const RecipientsContainer = styled.div`
  padding: 0 20px 20px;

  ${StyledDetailRows} {
    padding: 40px 10px 0;
    margin-bottom: 30px;
  }
`;

export const RecipientsEmptyContainer = styled(LayoutFullPage)`
  padding: 20px 20px 40px;
  display: flex;
  flex-direction: column;
`;

export const RecipientsExplainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  text-align: center;
  margin: 30px auto;

  h2 {
    ${fonts.PARAGRAPH_L}
  }

  p {
    margin-top: 10px;
    ${fonts.PARAGRAPH_S}
  }
`;

export const RecipientsEmptyStateIllustration = styled.div`
  flex: 1 0 0px;
  position: relative;
  display: grid;
  grid-gap: 16px;
  overflow: hidden;

  &::before {
    content: "";
    width: 100%;
    height: 64px;
    background: linear-gradient(180deg, ${colors[ColorNames.WHITE].hex}, rgba(${colors[ColorNames.WHITE].rgb}, 0));
    position: absolute;
    top: 0;
    z-index: 2;
  }

  & > div {
    will-change: transform;
  }

  &::after {
    content: "";
    width: 100%;
    height: 64px;
    background: linear-gradient(0deg, ${colors[ColorNames.WHITE].hex}, rgba(${colors[ColorNames.WHITE].rgb}, 0));
    position: absolute;
    bottom: 0;
    z-index: 2;
  }
`;

export const RSBackground = styled(motion.div)`
  color: ${colors[ColorNames.PINE].hex};
  background-color: ${colors[ColorNames.MINT].hex};
  border-radius: ${borderRadiusOuter};
  padding: 24px 20px;
  padding-top: 18px;
  display: grid;
  grid-template-columns: 1fr 1fr 48px;
  grid-gap: 16px;
  align-items: center;
`;
export const RSPlaceholderText = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${colors[ColorNames.TEA].hex};
  border-radius: ${borderRadiusOuter};
`;

export const RSPlaceholderArrow = styled.div`
  text-align: right;
`;

export const RecipientCards = styled.div`
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
`;

export const StyledRecipientCard = styled.div`
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  color: ${colors[ColorNames.PINE].hex};
  background-color: ${colors[ColorNames.MINT].hex};
  border-radius: ${borderRadiusOuter};
  padding: 20px;
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  transition: 0.15s ease transform;
  cursor: pointer;

  &:active {
    transform: scale(0.98);
  }

  .rc-title-row,
  .rc-info-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .rc-title {
    ${fonts.PARAGRAPH_L}
  }

  .rc-info-row {
    margin-top: 16px;
  }

  .rc-last4 {
    ${fonts.NUMBER_M}
    color: ${colors[ColorNames.GRASS].hex};
  }

  .rc-transfer-type {
    display: flex;
    align-items: center;
    ${fonts.NUMBER_M}
  }

  .rc-transfer-type-icon {
    color: ${colors[ColorNames.TEA].hex};

    svg {
      margin-right: 10px;
      position: relative;
      top: 2px;
    }
  }
`;

export const InstitutionInfo = styled.div`
  padding: 15px;
  margin-top: 16px;
  border-radius: ${borderRadius};
  width: 100%;
  background-color: ${colors[ColorNames.LIGHT_LIME].hex};
  color: ${colors[ColorNames.PINE].hex};

  &.error {
    display: grid;
    grid-template-columns: 36px 1fr;
    align-items: center;
    color: ${colors[ColorNames.CARDINAL].hex};
    background-color: ${colors[ColorNames.NOTE].hex};
  }

  .error {
    color: ${colors[ColorNames.CARDINAL].hex};
  }

  p {
    ${fonts.PARAGRAPH_S};
    margin: 0;
    line-height: 1.5;
  }

  .institution-name {
    text-transform: uppercase;
  }

  .institution-supported-payments {
    display: flex;
    ${fonts.PARAGRAPH_S};
    margin-top: 5px;

    div {
      display: flex;
      align-items: center;
      margin-right: 2em;
    }

    svg {
      width: 12px;
      margin-right: 0.35em;
    }
  }
`;
