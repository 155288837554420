import React from "react";

import { ReactComponent as IconRight } from "src/assets/icons/chevron_right.svg";
import { ReactComponent as IconCheck } from "src/assets/icons/check.svg";
import { GlobalSvgs } from "../global-svgs/GlobalSvgs";
import { LogoSpinner } from "src/components/logo-spinner/LogoSpinner";
import {
  StyledListButton,
  ListButtonLabel,
  ListButtonSublabel,
  ListButtonLabelGroup,
  ListButtonRight,
  ListButtonCheckbox
} from "src/components/lists/Lists.styles";
import { ColorNames } from "src/theme/theme";

export interface ListButtonProps {
  label: string;
  sublabel?: string | undefined;
  rightLabel?: string | undefined;
  onClick?: () => void;
  avatar?: JSX.Element | undefined;
  loading?: boolean;
  selectable?: boolean;
  isSelected?: boolean;
}

export const ListButton = ({ label, sublabel, rightLabel, onClick, avatar, loading, selectable, isSelected }: ListButtonProps): JSX.Element => {
  let rightContent = <></>;

  switch (true) {
    case !!loading:
      rightContent = <>
        <GlobalSvgs />
        <LogoSpinner color={ColorNames.MIDNIGHT} height={20} />
      </>;
      break;
    case !!rightLabel:
      rightContent = <>{rightLabel}</>;
      break;
    case selectable:
      rightContent = <ListButtonCheckbox selected={!!isSelected} children={<IconCheck />} />
      break;
    case !!onClick:
      rightContent = <IconRight />
      break;
  } 

  return (
    <StyledListButton onClick={onClick}>
      {!!avatar && avatar}
      <ListButtonLabelGroup>
        <ListButtonLabel>{label}</ListButtonLabel>
        {sublabel && <ListButtonSublabel>{sublabel}</ListButtonSublabel>}
      </ListButtonLabelGroup>
      <ListButtonRight>
        {rightContent}
      </ListButtonRight>
    </StyledListButton>
  );
};
