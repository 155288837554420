import { useCallback } from "react";
import {
  useMutation,
  useQuery,
  ApolloError,
  ApolloQueryResult,
} from "@apollo/client";
import { client } from "src";

import { useLogger } from "src/util/useLogger";
import { decorateCurrencyValue } from "src/util/stringUtils";

import {
  CREATE_CARD,
  GET_CARD,
  UPDATE_CARD_LIMIT,
  GET_WEEKLY_CARD_ACTIVITY,
  GET_CARD_TRANSACTIONS,
  CREATE_CARD_SESSION,
  COMPLETE_CARD_SESSION,
  FREEZE_CARD,
  UNFREEZE_CARD,
  CLOSE_CARD,
} from "src/services/gql/_cards";
import {
  Card,
  CreateCardMutationVariables,
  Transaction,
} from "src/generated/client";

export interface UpdateCardData {
  cardId: string;
  limit: number;
  limitBehaviour: string;
  limitEnabled: boolean;
}

export interface TransferBetweenCardsData {
  sourceCardId: string;
  targetCardId: string;
  amount: number;
}

export interface FreezeCardData {
  id: string;
}

export const hofundRoutes = () => {
  return {};
};

export const createCard = async (values: CreateCardMutationVariables) => {
  const res = await client.mutate({
    mutation: CREATE_CARD,
    errorPolicy: "all",
    variables: values,
  });

  if (!!res.data?.createCard) {
    return res.data.createCard;
  } else {
    throw res.errors;
  }
};

export const useCreateCard = (): {
  createCard: (
    values: CreateCardMutationVariables
  ) => Promise<Card | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [create, { loading, error }] = useMutation(CREATE_CARD, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const createCard = useCallback(
    async (values: CreateCardMutationVariables): Promise<Card | undefined> => {
      try {
        const { data } = await create({ variables: values });
        return !!data?.createCard ? data.createCard : undefined;
      } catch (err) {
        captureException(err);
      }
    },
    [create, captureException]
  );

  return { createCard, loading, error };
};

export const getCardById = async (cardId: string) => {
  const res = await client.query({
    query: GET_CARD,
    errorPolicy: "all",
    variables: { cardId },
  });

  if (!!res.data?.card) {
    return res.data.card;
  } else {
    throw res.errors;
  }
};

export const useGetCard = ({
  cardId,
}: {
  cardId: string;
}): {
  card: Card | null;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (
    variables?:
      | Partial<{
          cardId: string;
          organizationId: string;
        }>
      | undefined
  ) => Promise<ApolloQueryResult<any>>;
} => {
  const { data, error, loading, refetch } = useQuery(GET_CARD, {
    errorPolicy: "all",
    variables: { cardId },
  });

  return {
    card: data?.card || null,
    error,
    loading,
    refetch,
  };
};

export const useGetCardSession = (): {
  getCardSession: () => Promise<{ challenge: boolean } | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [createSession, { loading, error }] = useMutation(CREATE_CARD_SESSION, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const getCardSession = useCallback(async (): Promise<
    { challenge: boolean } | undefined
  > => {
    try {
      const { data } = await createSession();
      return !!data.createCardSession ? data.createCardSession : undefined;
    } catch (err) {
      captureException(err);
    }
  }, [createSession, captureException]);

  return { getCardSession, loading, error };
};

export const useCompleteCardSession = (): {
  completeCardSession: ({
    code,
    cardId,
  }: {
    code: string;
    cardId: string;
  }) => Promise<{ token: string; externalCardId: string } | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [completeSession, { loading, error }] = useMutation(
    COMPLETE_CARD_SESSION,
    { errorPolicy: "all" }
  );
  const { captureException } = useLogger();

  const completeCardSession = useCallback(
    async ({
      code,
      cardId,
    }: {
      code: string;
      cardId: string;
    }): Promise<{ token: string; externalCardId: string } | undefined> => {
      try {
        const { data } = await completeSession({ variables: { code, cardId } });
        return !!data.completeCardSession
          ? data.completeCardSession
          : undefined;
      } catch (err) {
        captureException(err);
      }
    },
    [completeSession, captureException]
  );

  return { completeCardSession, loading, error };
};

export const updateCard = async (values: UpdateCardData) => {
  const res = await client.mutate({
    mutation: UPDATE_CARD_LIMIT,
    errorPolicy: "all",
    variables: values,
  });

  if (!!res.data?.changeCardLimit) {
    return res.data;
  } else {
    throw res.errors;
  }
};

export const useUpdateCard = (): {
  updateCard: (values: UpdateCardData) => Promise<Card | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [update, { loading, error }] = useMutation(UPDATE_CARD_LIMIT, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const updateCard = useCallback(
    async (values: UpdateCardData): Promise<Card | undefined> => {
      try {
        const { data } = await update({ variables: values });
        return !!data?.changeCardLimit ? data.changeCardLimit : undefined;
      } catch (err) {
        captureException(err);
      }
    },
    [update, captureException]
  );

  return { updateCard, loading, error };
};

export const useGetWeeklyCardActivity = ({
  cardId,
}: {
  cardId: string;
}): {
  activity: {
    days: Array<{
      day: number;
      spendAmount: {
        display: string;
        amount: number;
      };
    }>;
    currentDay: number;
  } | null;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const { data, error, loading } = useQuery(GET_WEEKLY_CARD_ACTIVITY, {
    errorPolicy: "all",
    variables: { cardId },
  });

  return {
    activity: data?.weeklyActivityByAccountId
      ? {
          days: data.weeklyActivityByAccountId.activity || [],
          currentDay: data.weeklyActivityByAccountId.currentDay || 1,
        }
      : null,
    error,
    loading,
  };
};

export const useGetCardTransactions = ({
  accountId,
  cardId,
}: {
  accountId: string;
  cardId: string;
}): {
  transactions: Transaction[];
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const { data, loading, error } = useQuery(GET_CARD_TRANSACTIONS, {
    errorPolicy: "all",
    variables: { accountId, cardId },
  });

  const transactions = data?.transactionsByAccountId?.items?.map(
    (transaction: { [key: string]: any }) => {
      const date = Intl.DateTimeFormat(navigator.language, {
        month: "short",
        day: "numeric",
        year: "numeric",
      }).format(new Date(transaction.transactionDate));
      const time = Intl.DateTimeFormat(navigator.language, {
        hour: "numeric",
        minute: "2-digit",
      }).format(new Date(transaction.transactionDate));

      return {
        ...transaction,
        formattedAmount: decorateCurrencyValue(transaction.amount.display),
        formattedDate: `${time} – ${date}`,
      };
    }
  );

  return { transactions, loading, error };
};

export const useFreezeCard = (): {
  freezeCard: (id: string) => Promise<Card | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [freeze, { loading, error }] = useMutation(FREEZE_CARD, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const freezeCard = useCallback(
    async (id: string): Promise<Card | undefined> => {
      try {
        const { data } = await freeze({ variables: { cardId: id } });
        return !!data?.freezeCard ? data.freezeCard : undefined;
      } catch (err) {
        captureException(err);
      }
    },
    [freeze, captureException]
  );

  return { freezeCard, loading, error };
};

export const useUnfreezeCard = (): {
  unfreezeCard: (id: string) => Promise<Card | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [unfreeze, { loading, error }] = useMutation(UNFREEZE_CARD, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const unfreezeCard = useCallback(
    async (id: string): Promise<Card | undefined> => {
      try {
        const { data } = await unfreeze({ variables: { cardId: id } });
        return !!data?.unfreezeCard ? data.unfreezeCard : undefined;
      } catch (err) {
        captureException(err);
      }
    },
    [unfreeze, captureException]
  );

  return { unfreezeCard, loading, error };
};

export const closeCard = async (cardId: string) => {
  const res = await client.mutate({
    mutation: CLOSE_CARD,
    errorPolicy: "all",
    variables: { cardId },
  });

  if (!!res.data?.closeCard) {
    return res.data;
  } else {
    throw res.errors;
  }
};

export const useCloseCard = (): {
  closeCard: (id: string) => Promise<Card | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [close, { loading, error }] = useMutation(CLOSE_CARD, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const closeCard = useCallback(
    async (id: string): Promise<Card | undefined> => {
      try {
        const { data } = await close({ variables: { cardId: id } });
        return !!data?.closeCard ? data.closeCard : undefined;
      } catch (err) {
        captureException(err);
      }
    },
    [close, captureException]
  );

  return { closeCard, loading, error };
};
