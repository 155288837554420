import React from "react";

import { ThemeColor } from "src/theme/theme";
import { BannerNoticeContainer } from "src/components/alert/Alerts.styles";

export interface BannerNoticeProps {
  children?: JSX.Element | string;
  iconLeft: JSX.Element;
  translucent?: boolean;
  backgroundColor?: ThemeColor;
  textColor?: ThemeColor;
  titleColor?: ThemeColor;
  iconColor?: ThemeColor;
  iconRight?: JSX.Element;
  title?: string;
  onClick?: () => unknown;
  [key: string]: unknown;
}

export const BannerNotice = ({
  children,
  iconLeft,
  translucent,
  backgroundColor,
  textColor,
  iconColor,
  iconRight,
  title,
  titleColor,
  onClick,
  ...rest
}: BannerNoticeProps): JSX.Element => {
  return (
    <BannerNoticeContainer
      onClick={onClick}
      hasAction={!!iconRight}
      translucent={translucent}
      backgroundColor={backgroundColor}
      textColor={textColor}
      iconColor={iconColor}
      titleColor={titleColor}
      {...rest}
    >
      {iconLeft}
      <div>
        {title && <div className="title">{title}</div>}
        {!!children && (
          <div className="body">{children}</div>
        )}
      </div>
      {iconRight}
    </BannerNoticeContainer>
  );
};
