import React, { useEffect, useState, useRef, useCallback } from "react";
import { useFetcher } from "react-router-dom";
import { useGetRecoilValueInfo_UNSTABLE, useRecoilValue, useSetRecoilState } from "recoil";

import useNavigate from "src/util/useNavigate";
import { useNavbar } from "src/util/useNavbar";
import { useBalances } from "src/services/organizations";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { showNotice } from "src/store/alertState";

import { ColorNames } from "src/theme/theme";
import { NavRoutes } from "src/routes/navRoutes";
import { decorateCurrencyValue } from "src/util/stringUtils";
import { ConnectedAccount, ConnectedAccountProvider } from "src/generated/client";
import {
  isCAConnectingState,
  expectedNumberOfConnectedAccountsState,
  expectedNewConnectedAccountsDataState
} from "src/store/connectedAccountsState";

import {
  AccountsList,
  AmountDollars,
  BalanceAmountHeader,
  BalanceLabel,
  DepositAlert
} from "src/routes/app/bank/accounts/Accounts.styles";
import { ActionableButtonGroup } from "src/components/actionable-button/ActionableButton.styles";

import ConnectedAccountsEmpty from "src/routes/app/bank/connected-accounts/ConnectedAccountsEmpty";
import { AccountCard } from "src/components/account-card/AccountCard";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { BalanceAmount } from "src/components/balance-amount/BalanceAmount";
import { LoadingSkeleton } from "src/components/loading-skeleton/LoadingSkeleton";
import { BannerNotice } from "src/components/alert/BannerNotice";
import ConnectAccountSheet from "src/components/bottom-sheets/connect-account-sheet/ConnectAccountSheet";
import { ReactComponent as IconRecycleHorizontal } from "src/assets/icons/recycle_horizontal.svg";
import { ReactComponent as IconClock } from "src/assets/icons/clock.svg";
import { ConnectedAccountsLoadingSheet } from "src/components/bottom-sheets/connect-account-sheet/ConnectedAccountsLoadingSheet";
import { ConnectedAccountsSelectAccountsSheet } from "src/components/bottom-sheets/connect-account-sheet/ConnectedAccountsSelectAccountsSheet";
import Layout from "src/components/core-layout/Layout";
import { ReactComponent as IconInfo } from "src/assets/icons/info.svg";
import { ReactComponent as ChevronRight } from "src/assets/icons/chevron_right.svg";
import lodash from "lodash";

const ConnectedAccounts = (): JSX.Element => {
  const { updateNavbar } = useNavbar({ title: "Connected Accounts" });
  const { data: accounts, state: loaderState, load } = useFetcher<ConnectedAccount[]>();
  const { ...refresher } = useFetcher();

  const { externalBalance, refetchBalance, error: balanceError, checkingBalance } = useBalances();
  const navigate = useNavigate();
  const { showBottomSheet } = useShowBottomSheet();

  const [accountsNeedsRefresh, setAccountsNeedsRefresh] = useState(false);
  const setIsCAConnecting = useSetRecoilState(isCAConnectingState);
  const expectedNumberOfConnectedAccounts = useRecoilValue(expectedNumberOfConnectedAccountsState);
  const expectedNewConnectedAccountsData = useRecoilValue(expectedNewConnectedAccountsDataState);
  const setExpectedNumberOfConnectedAccounts = useSetRecoilState(expectedNumberOfConnectedAccountsState);
  // const setExpectedNewConnectedAccountsData = useSetRecoilState(expectedNewConnectedAccountsDataState);
  const getRecoilValueInfo = useGetRecoilValueInfo_UNSTABLE();
  const { isModified: newExpectedNumberOfConnectedAccountsStateChanged } = getRecoilValueInfo(
    expectedNumberOfConnectedAccountsState
  );
  // const refetchTimeout = useRef<number | null>(null);

  useEffect(() => {
    if (!accounts && loaderState === "idle") load(`${NavRoutes.API_CONNECTED_ACCOUNT}?fromNetwork=true`);
  }, [accounts, loaderState, load]);

  // useEffect(() => {
  //   let isTeller = false;
  //   console.log("emptying");
  //   console.log({ expectedNewConnectedAccountsData });
  //   if (Array.isArray(expectedNewConnectedAccountsData) && expectedNewConnectedAccountsData.length) {
  //     isTeller = !!expectedNewConnectedAccountsData.some((a) => a.provider === ConnectedAccountProvider.Teller);
  //   }
  //   console.log({ isTeller });
  //   !isTeller && setExpectedNewConnectedAccountsData([]);
  // }, [accounts, setExpectedNewConnectedAccountsData, newExpectedNumberOfConnectedAccountsStateChanged]);

  useEffect(() => {
    // const totalAccounts = accounts?.length || 0;
    // const totalExpectedPlusExisting = expectedNumberOfConnectedAccounts + totalAccounts;
    // if (
    //   expectedNumberOfConnectedAccounts > 0 &&
    //   totalAccounts > expectedNumberOfConnectedAccounts &&
    //   totalAccounts < totalExpectedPlusExisting &&
    //   newExpectedNumberOfConnectedAccountsStateChanged
    // ) {
    //   refetchTimeout.current = window.setTimeout(async () => {
    //     load(`${NavRoutes.API_CONNECTED_ACCOUNT}?fromNetwork=true`);
    //   }, 5000);
    // } else if (!!refetchTimeout.current) {
    //   clearTimeout(refetchTimeout.current);
    //   refetchTimeout.current = null;
    // }
    // if (totalExpectedPlusExisting === totalAccounts) {
    //   setExpectedNumberOfConnectedAccounts(0);
    // }
  }, [
    accounts,
    load,
    setExpectedNumberOfConnectedAccounts,
    expectedNumberOfConnectedAccounts,
    newExpectedNumberOfConnectedAccountsStateChanged
  ]);

  useEffect(() => {
    if (balanceError) {
      showNotice("There was an error fetching your balance.", { error: true });
    }
  }, [balanceError]);

  const isExpectedNewConnectedAccountsData = !!(
    Array.isArray(expectedNewConnectedAccountsData) && expectedNewConnectedAccountsData.length
  );

  const getAccountsToRender = (): ConnectedAccount[] => {
    const existingAccounts = accounts?.length ? accounts : [];
    const newAccounts = isExpectedNewConnectedAccountsData
      ? expectedNewConnectedAccountsData.filter((a) => a.provider !== ConnectedAccountProvider.Teller)
      : [];
    const resultAccounts = [...existingAccounts];
    for (const account of newAccounts) {
      const existsIndex = resultAccounts.findIndex(
        (r) => r.accountNumber === account.accountNumber && r.routingNumber === account.routingNumber
      );
      if (existsIndex === -1) {
        resultAccounts.push(account);
      } else {
        resultAccounts[existsIndex] = lodash.merge(resultAccounts[existsIndex], account);
      }
    }

    return resultAccounts;
  };

  const renderableAccounts = getAccountsToRender();

  useEffect(() => {
    const ONE_HOUR = 60 * 60 * 1000;
    const oneHourAgo = Date.now() - ONE_HOUR;

    updateNavbar({
      transparent: !!renderableAccounts.length,
      invertColors: !renderableAccounts.length
    });
    setAccountsNeedsRefresh(
      renderableAccounts.some(
        ({ updatedAt, balanceLastUpdatedAt }) =>
          (updatedAt
            ? new Date(updatedAt).getTime()
            : balanceLastUpdatedAt
            ? new Date(balanceLastUpdatedAt).getTime()
            : new Date().getTime()) < oneHourAgo
      )
    );

    if (renderableAccounts.length) setIsCAConnecting(false);
  }, [accounts, updateNavbar, setIsCAConnecting, expectedNewConnectedAccountsData, renderableAccounts]);

  useEffect(() => {
    if (refresher.data) showNotice("Your connected accounts have been refreshed.");
  }, [refresher.data]);

  const refreshAccounts = () => {
    if (refresher.state !== "submitting")
      refresher.submit({}, { method: "put", action: NavRoutes.API_CONNECTED_ACCOUNT });
  };

  const fetchBalanceAndAccounts = async () => {
    refetchBalance();
    load(`${NavRoutes.API_CONNECTED_ACCOUNT}?fromNetwork=true`);
  };

  const backgroundLoading =
    loaderState === "loading" &&
    !!(
      !isExpectedNewConnectedAccountsData ||
      (isExpectedNewConnectedAccountsData &&
        !expectedNewConnectedAccountsData.some((a) => a.provider === ConnectedAccountProvider.Teller))
    );

  return (
    <Layout loading={backgroundLoading} backgroundColor={!renderableAccounts.length ? ColorNames.MIDNIGHT : undefined}>
      {!renderableAccounts.length && <ConnectedAccountsEmpty refetch={fetchBalanceAndAccounts} />}
      {!!renderableAccounts.length && (
        <>
          <BalanceAmountHeader>
            <AmountDollars>
              {externalBalance?.display ? (
                <BalanceAmount availableBalance={decorateCurrencyValue(externalBalance?.display)} />
              ) : (
                <LoadingSkeleton width={150} height={44} />
              )}
            </AmountDollars>
            <BalanceLabel>
              <span className="label">Combined Connected Balance</span>
            </BalanceLabel>
          </BalanceAmountHeader>

          <ActionableButtonGroup>
            <ActionableButton
              label="Connect New Account"
              iconRight={false}
              color={ColorNames.PINE}
              onClick={() => showBottomSheet("connect_account_sheet")}
            />
          </ActionableButtonGroup>

          <AccountsList>
            {renderableAccounts.map((account) => (
              <AccountCard
                key={account.id}
                name={account.nickname ?? account.name ?? ""}
                balances={account.balances ?? []}
                lastFour={account.last4 ?? ""}
                subtitle={account.connectedInstitutionName || ""}
                amount={
                  account.type === "credit" && account.availableBalance
                    ? decorateCurrencyValue(account.availableBalance.display)
                    : decorateCurrencyValue(account.totalBalance.display)
                }
                accountType={account.type}
                onClick={() => navigate(`${NavRoutes.BANK_CONNECTED_ACCOUNTS}/${account.id}`)}
              />
            ))}
          </AccountsList>
          {accountsNeedsRefresh && (
            <ActionableButtonGroup>
              <ActionableButton
                label={
                  refresher.state === "submitting" ? "Working on refreshing your data" : "Refresh All Account Data"
                }
                iconRight={refresher.state === "submitting" ? <IconClock /> : <IconRecycleHorizontal />}
                onClick={refreshAccounts}
              />
            </ActionableButtonGroup>
          )}
          {checkingBalance?.amount === 0 && !!renderableAccounts.length && (
            <DepositAlert>
              <BannerNotice
                iconLeft={<IconInfo />}
                iconRight={<ChevronRight />}
                textColor={ColorNames.CINNAMON}
                iconColor={ColorNames.GOLD}
                onClick={() => navigate(`${NavRoutes.BANK_TRANSFER_SEND}/${renderableAccounts.pop()?.id}`)}
              >
                <>Next, deposit some money into your new Letter account.</>
              </BannerNotice>
            </DepositAlert>
          )}
          <ConnectedAccountsLoadingSheet id="connected_account_loading_sheet" />
          <ConnectedAccountsSelectAccountsSheet
            id="connected_account_select_accounts"
            accounts={expectedNewConnectedAccountsData}
            onSuccess={fetchBalanceAndAccounts}
          />
          <ConnectAccountSheet id="connect_account_sheet" onSuccess={fetchBalanceAndAccounts} />
        </>
      )}
    </Layout>
  );
};

export default ConnectedAccounts;
