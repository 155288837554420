import { gql } from "@apollo/client";

import {
  cardsFragment,
  accountTransactionFragment,
} from "src/services/gql/_fragments";

export const CREATE_CARD = gql`
  mutation CreateCard(
    $accountId: String!
    $limit: Float!
    $role: Role!
    $cardHolderFirstName: String!
    $cardHolderLastName: String!
    $cardHolderUserId: String!
    $cardHolderEmail: String!
    $design: String!
    $limitBehaviour: CardLimitBehaviourType
    $type: CardType
  ) {
    createCard(
      input: {
        accountId: $accountId
        limit: $limit
        role: $role
        cardHolderFirstName: $cardHolderFirstName
        cardHolderLastName: $cardHolderLastName
        cardHolderUserId: $cardHolderUserId
        cardHolderEmail: $cardHolderEmail
        design: $design
        limitBehaviour: $limitBehaviour
        type: $type
      }
    ) {
      ...CardData
    }
  }
  ${cardsFragment}
`;

export const GET_CARD = gql`
  query GetCard($cardId: String!) {
    card(cardId: $cardId) {
      id
      ...CardData
    }
  }
  ${cardsFragment}
`;

export const UPDATE_CARD_LIMIT = gql`
  mutation UpdateCardLimit(
    $cardId: String!
    $limit: Float!
    $limitBehaviour: CardLimitBehaviourType!
    $limitEnabled: Boolean!
  ) {
    changeCardLimit(
      input: {
        cardId: $cardId
        limit: $limit
        limitBehaviour: $limitBehaviour
        limitEnabled: $limitEnabled
      }
    ) {
      id
      status
      last4
      ...CardData
    }
  }
  ${cardsFragment}
`;

export const GET_WEEKLY_CARD_ACTIVITY = gql`
  query WeeklyCardActivity($cardId: String!) {
    weeklyActivityByAccountId(cardId: $cardId) {
      activity {
        day
        spendAmount {
          amount
          display
        }
      }
      currentDay
    }
  }
`;

export const GET_CARD_TRANSACTIONS = gql`
  query CardTransactions($accountId: String!, $cardId: String!) {
    transactionsByAccountId(accountId: $accountId, cardId: $cardId) {
      ...FullTransactionAccountData
    }
  }
  ${accountTransactionFragment}
`;

export const CREATE_CARD_SESSION = gql`
  mutation CreateCardSesssion {
    createCardSession {
      challenge
    }
  }
`;

export const COMPLETE_CARD_SESSION = gql`
  mutation CompleteCardSesssion($code: String!, $cardId: String!) {
    completeCardSession(input: { code: $code, cardId: $cardId }) {
      token
      externalCardId
    }
  }
`;

export const FREEZE_CARD = gql`
  mutation FreezeCard($cardId: String!) {
    freezeCard(input: { cardId: $cardId }) {
      id
      status
    }
  }
`;

export const UNFREEZE_CARD = gql`
  mutation UnfreezeCard($cardId: String!) {
    unfreezeCard(input: { cardId: $cardId }) {
      id
      status
    }
  }
`;

export const CLOSE_CARD = gql`
  mutation CloseCard($cardId: String!) {
    closeCard(input: { cardId: $cardId }) {
      id
      status
      last4
      ...CardData
    }
  }
  ${cardsFragment}
`;
