import { useCallback } from "react";
import {
  ApolloQueryResult,
  ApolloError,
} from "@apollo/client";

import { useLogger } from "src/util/useLogger";

import { 
  User,
  Recipient,
  useRecipientsQuery,
  useRecipientQuery,
  useCreateRecipientMutation,
  useDeleteRecipientMutation
} from "src/generated/client";


export const useGetRecipientById = (
  recipientId: string
): {
  recipient: Recipient | null;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const { data, loading, error } = useRecipientQuery({
    errorPolicy: "all",
    fetchPolicy: "network-only",
    variables: { recipientId },
  });

  let recipient = null;

  if (data?.recipient) {
    recipient = data?.recipient;
  }

  return { recipient, loading, error };
};

export const useGetRecipients = (): {
  recipients: Recipient[];
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => Promise<ApolloQueryResult<any>>;
} => {
  const { data, loading, error, refetch } = useRecipientsQuery({
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
  });

  const recipients = data?.recipients?.recipients || [];

  return { recipients, loading, error, refetch };
};

export const useCreateRecipient = (): {
  createRecipient: (
    values: Omit<Recipient, "id" | "organizationId">
  ) => Promise<Pick<User, "id"> | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [addRecipient, { loading, error }] = useCreateRecipientMutation({
    errorPolicy: "all"
  });
  const { captureException } = useLogger();

  const createRecipient = useCallback(
    async (values: Omit<Recipient, "id" | "organizationId">): Promise<Pick<User, "id"> | undefined> => {
      try {
        const { data } = await addRecipient({
          variables: { input: { ...values } },
        });
        return !!data?.createRecipient ? data.createRecipient : undefined;
      } catch (err) {
        captureException(err);
      }
    },
    [addRecipient, captureException]
  );

  return { createRecipient, loading, error };
};

export const useDeleteRecipient = (): {
  deleteRecipient: ({
    recipientId,
  }: {
    recipientId: string;
  }) => Promise<boolean>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [recipient, { loading, error }] = useDeleteRecipientMutation({
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const deleteRecipient = useCallback(
    async ({ recipientId }: { recipientId: string }): Promise<boolean> => {
      try {
        const { data } = await recipient({ variables: { recipientId } });
        return Boolean(data?.deleteRecipient?.success);
      } catch (err) {
        captureException(err);
        return false;
      }
    },
    [recipient, captureException]
  );

  return { deleteRecipient, loading, error };
};
