import React, { useState, useRef, useEffect } from "react";
import { Container, Sign, CustomInput } from "./LargeCurrencyInput.styles";
import { DataTestIds } from "src/util/testing-util/test-utils";
import { ReactComponent as SharesIcon } from "src/assets/icons/shares.svg";

export enum LargeCurrencyInputType {
  SHARES = "shares",
  USD = "usd"
}

interface LargeCurrencyInputProps {
  name: string;
  defaultValue?: string | number;
  disabled?: boolean;
  onChange?: (value: string) => void;
  type?: LargeCurrencyInputType;
}

export const LargeCurrencyInput = ({ name, defaultValue, onChange, type = LargeCurrencyInputType.USD, disabled = false }: LargeCurrencyInputProps) => {
  const isCurrency = type !== LargeCurrencyInputType.SHARES;
  const [value, setValue] = useState(defaultValue?.toString() || "0");
  const inputRef = useRef<HTMLInputElement>(null); // helper for focusing input when whole container is clicked
  const [amountLength, setAmountLength] = useState((!isCurrency) ? 1 : 3.5); // default length for 3 digits + 1 separator (0.00)
  const dynamicFontSizeCurrency = amountLength > 8 ? `min(calc(100vw / ${amountLength * 1.25}), 30px)` : `30px`;
  const dynamicFontSizeInput = amountLength > 8 ? `min(calc(100vw / ${amountLength * 0.8}), 52px)` : `52px`;
  

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valuetoUse = formatNumber(event.target.value, isCurrency);
    setValue(valuetoUse);

    if (onChange) {
      event.persist();
      onChange(valuetoUse);
    }
  };

  const handleInputWidth = (v: string) => {
    const numbersCount = v.match(/[0-9]/g)?.length || (!isCurrency ? 1 : 3); // default 3 zeros in 0.00
    const separatorsCount = v.match(/[,.]/g)?.length || (!isCurrency ? 0 : 1); // default 1 decimal in 0.00
    const computedStringLength = numbersCount + separatorsCount * 0.5; // divide separator width by 50%

    setAmountLength(computedStringLength);
  };

  useEffect(() => {
    handleInputWidth(value);
  }, [value]);

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    inputRef.current.disabled = disabled;
  }, [disabled]);

  return (
    <Container onClick={() => inputRef.current?.focus()}>
      <Sign
        style={{
          fontSize: dynamicFontSizeCurrency
        }}
      >
        {type === LargeCurrencyInputType.SHARES ? <SharesIcon style={{marginRight: "4px"}} /> : "$"}
      </Sign>
      <CustomInput
        name={name}
        ref={inputRef}
        type="text"
        inputMode="numeric"
        step="any"
        value={value === "0" ? ((!isCurrency) ? "" : "0.00") : value}
        data-inactive={value === "0" || value === "0.00"}
        placeholder={(!isCurrency) ? "0" : "0.00"}
        onChange={(event): void => {
          handleChange(event);
        }}
        style={{
          fontSize: dynamicFontSizeInput,
          width: `calc(${amountLength}ch + 6px)`,
          minWidth: `calc(${(!isCurrency) ? 1 : 3.5}ch + 6px)`
          // calc fixes small glitch when focusing at certain string lengths
        }}
        data-testid={DataTestIds.CURRENCY_INPUT}
      />
    </Container>
  );
};

const getDigitsFromValue = (v = "") => v.replace(/(-(?!\d))|[^0-9|-]/g, "") || "";

const padDigits = (digits: string) => {
  const desiredLength = 3;
  const actualLength = digits.length;

  if (actualLength >= desiredLength) {
    return digits;
  }

  const amountToAdd = desiredLength - actualLength;
  const padding = "0".repeat(amountToAdd);

  return padding + digits;
};

const removeLeadingZeros = (number: string) => number.replace(/^0+([0-9]+)/, "$1");

const addDecimalToNumber = (number: string) => {
  const separator = ".";
  const centsStartingPosition = number.length - 2;
  const dollars = removeLeadingZeros(number.substring(0, centsStartingPosition));
  const cents = number.substring(centsStartingPosition);

  if (dollars.length > 3) {
    return Number(dollars).toLocaleString("en-US") + separator + cents;
  }

  return dollars + separator + cents;
};

const wholeNumber = (number: string) => {
  return Number(removeLeadingZeros(number)).toLocaleString("en-US");
};

const formatNumber = (v: string | undefined, isCurrency: boolean) => {
  const digits = getDigitsFromValue(v).substring(0,12);
  return (!isCurrency) ? wholeNumber(digits) : addDecimalToNumber(padDigits(digits));
};
