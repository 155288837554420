import styled from "styled-components/macro";
import { motion } from "framer-motion";
import {
  colorPalette as colors,
  ColorNames,
  dropShadows,
  ShadowNames,
  borderRadius,
  borderRadiusOuter,
  fonts
} from "src/theme/theme";

import { ReactComponent as ArrowsExpand } from "src/assets/icons/arrows_expand.svg";
import { ReactComponent as Checkmark } from "src/assets/icons/check.svg";

export interface DetailSelectProps {
  error?: boolean;
}

export const DetailSelectContainer = styled(motion.div)`
  width: 100%;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  padding: 0 10px 10px;
  background-color: ${colors[ColorNames.WHITE].hex};
  border-radius: ${borderRadiusOuter};
  box-shadow: ${dropShadows[ShadowNames.LIGHT]};
  margin-bottom: 30px;
  transform: scale(1);
  transition: all 0.3s ease, transform 0.15s ease;
  will-change: transform, opacity, box-shadow;

  &:active {
    transform: scale(0.98);
  }

  &.open {
    background-color: transparent;
    box-shadow: 0 0 0 0 transparent;
    pointer-events: none;
    opacity: 0;
  }
`;

export const DetailSelectHeader = styled.div`
  height: 40px;
  padding: 0 6px 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${colors[ColorNames.MIDNIGHT].hex};
  ${fonts.PARAGRAPH_S}
`;

export const IconExpand = styled(ArrowsExpand)`
  flex: 0 0 auto;
`;

export const DetailSelectRow = styled(motion.div)<DetailSelectProps>`
  height: 40px;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ error }): string => (error ? colors[ColorNames.CREAM].hex : colors[ColorNames.POWDER].hex)};
  border-radius: ${borderRadius};
  font-weight: 500;
  color: ${({ error }): string => (error ? colors[ColorNames.CARDINAL].hex : colors[ColorNames.COBALT].hex)};

  &.empty {
    background-color: ${colors[ColorNames.GRAY1].hex};
    color: ${colors[ColorNames.GRAY5].hex};
  }

  .label {
    overflow: hidden;
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 0.5em;
    line-height: 1.25;
  }

  .tag {
    color: ${colors[ColorNames.BABY].hex};
    line-height: 1.25;
    ${fonts.NUMBER_M}
  }

  .contact {
    display: flex;
    align-items: center;
    gap: 6px;
    width: 100%;
  }
`;

export const DetailPickerOptionCheckmarkHolder = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: ${colors[ColorNames.POWDER].hex};
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
`;

export const DetailPickerOptionCheckmark = styled(Checkmark)`
  height: 16px;
  width: 16px;

  path {
    stroke: ${colors[ColorNames.COBALT].hex};
  }
`;

export const DetailSelectEmptyState = styled.div`
  height: 210px;
  width: 100%;
  padding-right: 20px;
  text-align: center;
  display: grid;
  place-items: center;
  ${fonts.PARAGRAPH_S}
  color: ${colors[ColorNames.GRAY5].hex};
`;
