import classnames from "classnames";
import React from "react";
import { Skeleton, SkeletonAnimatedGradient } from "./LoadingSkeleton.styles";

export interface LoadingSkeletonProps {
  width?: string | number;
  height?: number;
  transparent?: boolean;
  invert?: boolean;
  noMargin?: boolean;
}

export const LoadingSkeleton = ({
  width,
  height,
  transparent,
  invert,
  noMargin
}: LoadingSkeletonProps): JSX.Element => {
  return (
    <Skeleton
      style={{
        width: width,
        height: height || 16
      }}
      className={classnames({
        transparent,
        invert,
        noMargin
      })}
    >
      <SkeletonAnimatedGradient
        className={classnames({
          transparent,
          invert
        })}
      />
    </Skeleton>
  );
};
