import { LoaderFunction } from "react-router-dom";

import { getCardById } from "src/services/cards";


export const cardLoader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const cardId = url.searchParams.get("cardId");
  
  try {
    if (!!cardId) {
      return await getCardById(cardId);
    } else {
      throw Error("No matching card.");
    }
  } catch (e) {
    throw e;
  }
};