import React from "react";
import { useField } from "formik";

import { StyledSelect } from "src/components/forms/select/Select.styles";
import { StyledFieldContainer } from "src/components/forms";
import { StyledLabel } from "src/components/text";
import { DataTestIds } from "src/util/testing-util/test-utils";

export interface SelectOption {
  value: string | number;
  label: string;
}
export interface SelectProps {
  label?: string;
  name: string;
  options: SelectOption[];
  handleError?: (error: string) => unknown;
  hidden?: boolean;
  disabled?: boolean;
  [key: string]: unknown;
}

export const Select = ({ label, name, options, hidden, disabled, handleError, ...props }: SelectProps): JSX.Element => {
  const [field, meta] = useField(name);
  // const { setFieldTouched } = useFormikContext();

  if (meta.touched && meta.error && handleError) handleError(meta.error);

  return (
    <StyledFieldContainer error={(meta.touched && meta.error) as boolean} hidden={hidden} data-testid={DataTestIds.FIELD_CONTAINER}>
      {label && (
        <StyledLabel
          htmlFor={(props.id as string) || name}
          error={(meta.touched && meta.error) as boolean}
          data-testid={DataTestIds.LABEL}
        >
          {label}
        </StyledLabel>
      )}

      <StyledSelect disabled={disabled} data-testid={DataTestIds.SELECT} {...field} {...props}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
    </StyledFieldContainer>
  );
};
