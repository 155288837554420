import styled from "styled-components";
import { colorPalette as colors, ThemeColor, ColorNames } from "src/theme/theme";

export const LoadingBox = styled.div<{
  height: string | null;
  backgroundColor?: ThemeColor | ColorNames.TRANSPARENT | void;
}>`
  grid-column: 2 / -1;
  flex: 1 1 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: ${({ backgroundColor }): string =>
    backgroundColor
      ? backgroundColor === ColorNames.TRANSPARENT
        ? "transparent"
        : colors[backgroundColor].hex
      : "white"};
  height: ${({ height }): string => (height ? height : "auto")};
`;
