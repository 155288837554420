import { gql } from "@apollo/client";
import {
  OrgDataFragmentDoc,
  CardDataFragmentDoc,
  BusinessDataFragmentDoc,
  OwnerDataFragmentDoc,
  FullTransactionDataFragmentDoc
} from "src/generated/client";

export const ADD_BUSINESS_INFO = gql`
  mutation AddBusinessInfo(
    $organizationId: String
    $orgType: OrganizationType!
    $orgName: String
    $legalStructure: String
    $taxStatus: String
    $taxId: String
    $stateOfIncorporation: String
    $businessPhoneNumber: String
    $category: String
    $streetAddress: String
    $city: String
    $state: String
    $zip: String
  ) {
    createOrUpdateOrganization(
      input: {
        organizationId: $organizationId
        orgType: $orgType
        orgName: $orgName
        legalStructure: $legalStructure
        taxStatus: $taxStatus
        taxId: $taxId
        stateOfIncorporation: $stateOfIncorporation
        category: $category
        businessPhoneNumber: $businessPhoneNumber
        streetAddress: $streetAddress
        city: $city
        state: $state
        zip: $zip
      }
    ) {
      ...BusinessData
    }
  }
  ${BusinessDataFragmentDoc}
`;

export const ADD_OWNER_INFO = gql`
  mutation UpdateOwners($organizationId: String!, $beneficialOwners: [BeneficialOwnerInput!]!) {
    updateOwners(input: { organizationId: $organizationId, beneficialOwners: $beneficialOwners }) {
      success
    }
  }
`;

export const REISSUE_TOKEN = gql`
  mutation ReissueToken($organizationId: String!) {
    reIssueToken(input: { organizationId: $organizationId }) {
      session
    }
  }
`;

export const GET_OWNERS_FOR_ORG = gql`
  query OrganizationOwners($id: String!) {
    organization(orgId: $id) {
      ...OwnerData
    }
  }
  ${OwnerDataFragmentDoc}
`;

export const GET_TRANSACTIONS_FOR_ORG = gql`
  query TransactionsForOrg {
    transactionsForOrg {
      ...FullTransactionData
    }
  }
  ${FullTransactionDataFragmentDoc}
`;

export const GET_ORGANIZATION_BALANCES = gql`
  query OrganizationByIdBalances($orgId: String!, $timeframe: BalancesTimeframe) {
    organization(orgId: $orgId) {
      id
      balances(timeframe: $timeframe) {
        period
        balance {
          amount
          display
        }
      }
    }
  }
`;

export const GET_ORGANIZATION = gql`
  query OrganizationById($orgId: String!) {
    organization(orgId: $orgId) {
      ...OrgData
      ...OwnerData
      externalBalance {
        display
        amount
      }
      totalBalance {
        display
        amount
      }
      availableBalance {
        display
        amount
      }
      deposits {
        display
        amount
      }
      spent {
        display
        amount
      }
    }
  }
  ${OrgDataFragmentDoc}
  ${OwnerDataFragmentDoc}
`;

export const GET_ORGANIZATION_USERS = gql`
  query OrganizationUsers($orgId: String!, $selectedUserId: String) {
    organization(orgId: $orgId) {
      id
      role
      users(selectedId: $selectedUserId) {
        id
        email
        firstName
        lastName
        orgRole
        status
        selected
        isThisMe
        cards {
          ...CardData
        }
      }
    }
  }
  ${CardDataFragmentDoc}
`;

export const REVOKE_ORGANIZATION_INVITE = gql`
  mutation RevokeOrgInvite($userId: String!) {
    revokeInviteToOrg(input: { userId: $userId }) {
      success
    }
  }
`;

export const REMOVE_ORGANIZATION_USER = gql`
  mutation RemoveOrgUser($userId: String!) {
    removeOrgUser(userId: $userId) {
      success
    }
  }
`;

export const FREEZE_ORGANIZATION_USER = gql`
  mutation FreezeOrgUser($userId: String!) {
    freezeOrgUser(userId: $userId) {
      success
    }
  }
`;

export const THAW_ORGANIZATION_USER = gql`
  mutation ThawOrgUser($userId: String!) {
    thawOrgUser(userId: $userId) {
      success
    }
  }
`;

export const EDIT_ORGANIZATION_USER = gql`
  mutation EditOrgUser($userId: String!, $role: Role!) {
    editOrgUserAccess(input: { userId: $userId, role: $role }) {
      success
    }
  }
`;

export const DELETE_ORGANIZATION = gql`
  mutation DeleteOrg($id: String!) {
    deleteOrganization(input: { orgId: $id }) {
      success
    }
  }
`;

export const GET_ORGANIZATION_INVITE_TOKEN = gql`
  mutation InviteUserToOrg($permissionLevel: PermissionLevel!, $email: String!, $sendEmail: Boolean!) {
    inviteUserToOrg(input: { permissionLevel: $permissionLevel, email: $email, sendEmail: $sendEmail }) {
      token
      userId
    }
  }
`;

export const REQUEST_ACCREDITATION = gql`
  mutation RequetAccreditation {
    requestAccreditation {
      success
    }
  }
`;

export const ALLOCATE_REPAYMENT_ACCOUNT = gql`
  mutation allocateRepaymentAccountId($accountId: String!) {
    allocateRepaymentAccountId(accountId: $accountId) {
      ...OrgData
    }
  }
  ${OrgDataFragmentDoc}
`;
