import React from "react";

import {
  StyledNextModal,
  NextModalTextContainer,
  NextModalSmallTextContainer,
  ArrowContainer,
  Arrow
} from "src/components/cta/next-modal/NextModal.styles";
import { ColorNames } from "src/theme/theme";
import { GlobalSvgs } from "src/components/global-svgs/GlobalSvgs";
import { LogoSpinner } from "src/components/logo-spinner/LogoSpinner";
import { CtaBanner, CtaTitle, CtaDescription } from "src/components/text";

import { DataTestIds } from "src/util/testing-util/test-utils";

export interface NextModalProps {
  banner?: string;
  title: string;
  description?: string;
  loading?: boolean;
  disabled?: boolean;
  cy?: string;
  onClick?: () => unknown | void;
}

export const NextModal = ({
  banner,
  title,
  description,
  loading,
  disabled,
  cy = "",
  onClick = (): void => void 0
}: NextModalProps): JSX.Element => {
  const textContainer = () => {
    return banner ? (
      <NextModalTextContainer>
        {banner && <CtaBanner>{banner}</CtaBanner>}
        <CtaTitle>{title}</CtaTitle>
        {description && <CtaDescription>{description}</CtaDescription>}
      </NextModalTextContainer>
    ) : (
      <NextModalSmallTextContainer>
        <CtaTitle>{title}</CtaTitle>
        {description && <CtaDescription>{description}</CtaDescription>}
      </NextModalSmallTextContainer>
    );
  };
  return (
    <StyledNextModal data-cy={cy} disabled={disabled} onClick={onClick} data-testid={DataTestIds.NEXT_MODAL}>
      {textContainer()}
      <ArrowContainer>
        {loading ? (
          <>
            <GlobalSvgs />
            <LogoSpinner color={ColorNames.WHITE} data-testid={DataTestIds.LOGO_SPINNER} />
          </>
        ) : (
          <Arrow data-testid={DataTestIds.ARROW_ICON} />
        )}
      </ArrowContainer>
    </StyledNextModal>
  );
};
