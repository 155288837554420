import React from "react";
import styled from "styled-components";
import { colorPalette as colors, ColorNames } from "src/theme/theme";

const TermsHolder = styled.div`
  ol {
    padding-inline-start: 15px;
  }
  li {
    margin-bottom: 10px;
  }
  a {
    color: ${colors[ColorNames.MIDNIGHT].hex};
  }
`;

export default (
  <TermsHolder>
    <h1>Terms of Service</h1>
    <p>By accessing our Website or using our Service you are agreeing to the following Terms of Service.</p>
    <p>Effective as of: June 1, 2021.</p>
    <hr />
    <ol>
      <h2>
        <li>Acceptance of the Terms of Service</li>
      </h2>
      <p>
        These Terms of Service are entered into by and between you (&ldquo;You&rdquo; or &ldquo;Your&rdquo; or the
        &ldquo;User&rdquo;) and Letter Technologies, Inc. and its subsidiaries, affiliates and related entities
        (&ldquo;Company&rdquo; or &ldquo;We&rdquo; or &ldquo;Us&rdquo;). The following terms and conditions (these
        &ldquo;Terms&rdquo;) govern Your access to and use of the website use www.letter.co (&ldquo;Website&rdquo;) and
        Service (defined below) provided by Us, including any content, functionality, and services offered on or through
        our Website.
      </p>
      <p>
        Please read the Terms carefully before You start to use the Service. By clicking accept or agree to the Terms
        when this option is made available to You through the Website or the Letter Platform (defined below) or by using
        the Service, You accept and agree to be bound and abide by these Terms and our Privacy Policy. If You do not
        want to agree to these Terms and Privacy Policy, do not visit the Website, use the Letter Platform or use the
        Service.
      </p>
      <p>
        Our Service is not intended for children under 18 years of age. No one under age 18 may provide any information
        to the Service. We do not knowingly collect personal information from children under 18. If You are under 18, do
        not use or provide any information on this Service or on or through any of its features or provide any
        information about Yourself to Us. If You believe We might have any information from or about anyone under 18,
        please contact us at the e-mail at the bottom of these Terms.
      </p>
      <h2>
        <li>Changes to the Terms</li>
      </h2>
      <p>
        We may revise and update these Terms from time to time in our sole discretion. All changes are effective after
        30 days from the date they are posted on our Website.
      </p>
      <p>
        Your continued use of the Service following the posting of revised Terms means that You accept and agree to the
        changes. You are expected to check this page frequently, so You are aware of any changes, as they are binding on
        You.
      </p>
      <h2>
        <li>Description of the Service</li>
      </h2>
      <p>
        The services provided by Us are facilitated through the Website and the Company&rsquo;s mobile app or services
        offered thereon (&ldquo;Letter Platform&rdquo;, and together with the Website, collectively, the
        &ldquo;Service&rdquo;).
      </p>
      <h2>
        <li>Limitation of Services</li>
      </h2>
      <p>
        The information provided on this site is for informational purposes only. The content should not be construed as
        financial or investment advice on any other type of advice on any subject matter. The Company expressly
        disclaims all liability in respect to actions taken based on any or all of the information on this website. The
        Company takes steps to ensure the accuracy of information on the website, however, the Company does not
        guarantee the accuracy or completeness of information contained in these pages or that it will be current at all
        times.
      </p>
      <p>
        Company&rsquo;s Service is expressly limited to those activities as described in these Terms and/or any
        contemporaneous agreement as such may exist by and between Company and User.{" "}
        <strong>User agrees that should it pursue any strategy or investment, it does so at its own risk</strong>. User
        agrees it is solely responsible for its investment decisions and for ensuring that all activities related to the
        implementation of a strategy and/or investment are done in accordance with applicable laws. User agrees to hold
        Company harmless for, and Company will not be liable for, any losses or damages that User may incur as a result
        of any strategy and/or investment User pursues.
      </p>
      <p>
        Company shall not be required to expand its facilities, incur any capital expenditures, employ additional
        personnel, or maintain the employment of any specific persons, to provide any of the Services to User.
      </p>
      <p>Please reference Specific Disclaimers below for additional information on Limitations of Services.</p>
      <h2>
        <li>Accessing the Service and Account Security</li>
      </h2>
      <p>
        To use the Service, You must register for an account. After a successful registration, each user will have their
        own credentials which the user will be able to use to access their account (the &ldquo;Account&rdquo;) and the
        Service.
      </p>
      <p>
        You are responsible for (a) making all necessary arrangements to have access to the Service, which requires You
        do create an Account, among other things; (b) ensuring that all persons who access the Service through the
        Account are aware of these Terms and comply with them, and (c) You are responsible for ensuring that no
        unauthorized users are granted access to the Account.
      </p>
      <p>
        To access the Website or Service, You may be asked to provide certain registration details or other information.
        It is a condition of Your use of the Service that all the information You provide is correct, current, and
        complete. You agree that all information You provide to register is governed by our Privacy Policy, and You
        consent to all actions We take with respect to Your information consistent with our Privacy Policy.
      </p>
      <p>
        If You choose, or are provided with, a username, password, or any other piece of information as part of our
        security procedures, You must treat such information as confidential, and You must not disclose it to any other
        person or entity. You also acknowledge that the Account is created only for Your individual use. You agree to
        notify us immediately of any unauthorized access to Your Account or any other breach of security. You should use
        particular caution when accessing Your Account from a public or shared computer so that others are not able to
        view or record Your password or other personal information.
      </p>
      <p>
        You are responsible for maintaining any multifactor authentication (&ldquo;MFA&rdquo;) device(s) which may be
        associated with Your Account.You are also responsible for removing MFA devices which were lost or broken as well
        as reporting stolen MFA devices to Us immediately.It is Your responsibility to ensure a sufficient number of MFA
        devices are attached to Your Account to obtain and to maintain access to the Service.
      </p>
      <h2>
        <li>Usage Restrictions</li>
      </h2>
      <p>
        User shall not (a) modify, translate, adapt or otherwise create derivative works or improvements, whether or not
        patentable, of the Service; (b) reverse engineer, disassemble, decompile, decode or otherwise attempt to derive
        or gain access to the source code of the Service, or any part thereof; (c) remove, delete, alter or obscure any
        trademarks or any copyright, trademark, patent or other intellectual property or proprietary rights notices from
        the Service, including any copy thereof; (d) rent, lease, lend, sell, sublicense, assign, distribute, publish,
        transfer or otherwise make available the Service or any features or functionality of the Service, to any third
        party for any reason, including by making the Service available on a network where it is capable of being
        accessed by more than one device at any time; (e) remove, disable, circumvent or otherwise create or implement
        any workaround to any copy protection, rights management, or security features in or protecting the Service; (f)
        offer, advertise, or promote, in any manner, any services or products that may compete with those provided by us
        to users identified through the Service.
      </p>
      <a id="terms_letter_alerts_text"></a>
      <p>
        If You wish to make any use of material on the Service other than that set out in this section, please address
        Your request to the email provided at the bottom.
      </p>
      <h2>
        <li>Letter Alerts</li>
      </h2>
      Letter Alerts will notify you of activity on your account.
      <ul>
        <li>
          You can cancel the SMS service at any time. Just text "STOP" to the short code. After you send the SMS message
          "STOP" to us, we will send you an SMS message to confirm that you have been unsubscribed. After this, you will
          no longer receive SMS messages from us. If you want to join again, you can opt back in your account settings
          and we will start sending SMS messages to you again.
        </li>
        <li>
          If you are experiencing issues with the messaging program you can reply with the keyword HELP for more
          assistance, or you can get help directly at <a href="mailto:help@letter.co">help@letter.co</a> or give us a
          call at (855) 954-1888.
        </li>
        <li>Carriers are not liable for delayed or undelivered messages.</li>
        <li>
          As always, message and data rates may apply for any messages sent to you from us and to us from you. Message
          frequency will vary with account activity and triggered events. If you have any questions about your text plan
          or data plan, it is best to contact your wireless provider
        </li>
        <li>
          If you have any questions regarding privacy, please read our privacy policy:{" "}
          <a href="https://app.letter.co/privacy">https://app.letter.co/privacy</a>
        </li>
      </ul>
      <h2>
        <li>License; Reservation of Rights</li>
      </h2>
      <p>
        Subject to Your compliance with these Terms, We grant You a limited, non-exclusive, non-transferable,
        non-sublicensable license to access use of the Service. This license does not include any resale of any Service
        or its contents. All rights not expressly granted to You in these Terms are reserved and retained by us. No
        Service, nor any part of any Service, may be reproduced, duplicated, copied, sold, resold, visited, or otherwise
        exploited for any commercial purpose without our express written consent.
      </p>
      <p>
        You acknowledge and agree that the Service is provided under software as a service license, and not sold to You.
        You do not acquire any ownership interest in the Service under these Terms, or any other rights thereto other
        than to use the Service in accordance with the license granted, and subject to all terms, conditions, and
        restrictions under these Terms. Company and its licensors and service providers reserve and shall retain their
        entire right, title, and interest in and to the Service, including all copyrights, trademarks, and other
        intellectual property rights therein or relating thereto, except as expressly granted to You in these Terms.
      </p>
      <h2>
        <li>Intellectual Property Rights; Confidentiality</li>
      </h2>
      <p>
        The Service and its entire contents, except user generated content, features, and functionality including but
        not limited to all information, software, text, displays, images, video and audio, and the design, selection,
        and arrangement thereof, are owned by the Company, its licensors, or other providers of such material and are
        protected by U.S. and international copyright, trademark, patent, trade secret and other intellectual property
        or proprietary rights laws.
      </p>
      <p>
        You agree not to disclose any confidential information (such as passwords and login information), and not to use
        any confidential information for any other purposes not provided under these Terms.
      </p>
      <h2>
        <li>Trademarks</li>
      </h2>
      <p>
        The Company name, the Terms, the Company logo and all related names, logos, product and service names, designs,
        and slogans are trademarks of the Company. You must not use such marks without the prior written permission of
        the Company. All other names, logos, product and service names, designs, and slogans on this Service are the
        trademarks of their respective owners.
      </p>
      <h2>
        <li>Your Responsibilities</li>
      </h2>
      <p>
        You are solely responsible for (a) the operation, performance, and security of Your equipment, networks and
        other computing resources needed and used to connect to and make full use of the Service; (b) maintaining the
        confidentiality of Your accounts, passwords and personal identification numbers used in conjunction with the
        Service; (c) all uses of the Service by You; and (d) notifying us immediately of any unauthorized use of Your
        Account or any other breach of security. You agree to hold us harmless, and We will not be liable for any losses
        or damages that You may incur as a result of a third party using Your password or Account.
      </p>
      <h2>
        <li>Fees; Billing and Payment</li>
      </h2>
      <p>
        We charge fees for certain features of the Service. The fees applicable to You and the billing details shall be
        outlined on our Website, in an agreement relating to the Letter Platform, or in another written contract between
        You and the Company. Payments shall be made in accordance with the policies on the Website and/or the Letter
        Platform and in accordance with the applicable written agreement between You and the Company.
      </p>
      <p>
        The Company does not process payments. If You pay by credit card or certain other payment instruments, the
        Company provides a link to our payment processing agent. You agree to keep Your Account in good standing by
        making payments as required to the payment processing agent and promptly updating Your Account information with
        any changes that may occur.
      </p>
      <p>
        You agree to review the privacy policy, terms of service, and any other applicable policy of the payment
        processing agent hired by the Company for information on the storage and use of Your billing information and how
        it is used in the processing of Your payment.
      </p>
      <p>
        All bank and transaction fees associated with the payment for the Service, including, but not limited to
        services of a correspondent bank or transferring money, shall be paid by You.
      </p>
      <h2>
        <li>Prohibited Uses</li>
      </h2>
      <p>
        User may use the Service only for lawful purposes and in accordance with these Terms. You agree not to use the
        Service (a) in any way that violates any applicable law or regulation (including, without limitation, any laws
        regarding the export of data or software to and from the United States or other countries); (b) for the purpose
        of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate
        content, asking for personally identifiable information or otherwise; (c) to send, knowingly receive, upload,
        download, use or re-use any material which does not comply with the content standards set out in these Terms;
        (d) to transmit, or procure the sending of, any advertising or promotional material without our prior written
        consent, including any &ldquo;junk mail&rdquo;, &ldquo;chain letter&rdquo; or &ldquo;spam&rdquo; or any other
        similar solicitation; and (e) to impersonate or attempt to impersonate the Company, a Company employee, another
        user, or any other person or entity (including, without limitation, by using e-mail addresses or screen names
        associated with any of the foregoing).
      </p>
      <p>
        Additionally, You agree not to (a) use the Service in any manner that could disable, overburden, damage, or
        impair the site or interfere with any other party's use of the Service, including their ability to engage in
        real time activities through the Service; (b) use any robot, spider or other automatic device, process, or means
        to access the Service for any purpose, including monitoring or copying any of the material on the Service; (c)
        use any device, software, or routine that interferes with the proper working of the Service; (d) introduce any
        viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful; (e)
        attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Service, or any
        server, computer, or database connected to the Service; (f) attack the Service via a denial-of-service attack or
        a distributed denial-of-service attack; or (g) otherwise attempt to interfere with the proper working of the
        Service.
      </p>
      <h2>
        <li>Monitoring and Enforcement; Termination</li>
      </h2>
      <p>
        To the extent any User contribution of any type of content may be viewable on the Website or through the
        Service, We have the right to (a) remove or refuse to post any User contributions for any or no reason in our
        sole discretion; (b) take any action with respect to any User contribution that We deem necessary or appropriate
        in our sole discretion, including if We believe that such User contribution violates the Terms, including the
        content standards, infringes any intellectual property right or other right of any person or entity, threatens
        the personal safety of users of the Service or the public, or could create liability for the Company; (c)
        disclose Your identity or other information about You to any third party who claims that material posted by You
        violates their rights, including their intellectual property rights or their right to privacy; (d) take
        appropriate legal action, including without limitation, referral to law enforcement, for any illegal or
        unauthorized use of the Service; and/or (e) terminate or suspend Your access to all or part of the Service for
        any or no reason, including without limitation, any violation of these Terms.
      </p>
      <p>
        Without limiting the foregoing, We have the right to fully cooperate with any law enforcement authorities or
        court order requesting or directing us to disclose the identity or other information of anyone posting any
        materials on or through the Service. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND
        SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING OR AS A
        RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH
        PARTIES OR LAW ENFORCEMENT AUTHORITIES.
      </p>
      <p>
        We, however, provide no assurances that Company will review material before it is posted on the Service, and
        cannot ensure prompt removal of objectionable material after it has been posted. Accordingly,{" "}
        <strong>
          We assume no liability for any action or inaction regarding transmissions, communications, or content provided
          by any user or third party
        </strong>
        . We have no liability or responsibility to anyone for performance or nonperformance of the activities described
        in this section.
      </p>
      <h2>
        <li>Content Standards</li>
      </h2>
      <p>
        These content standards apply to any and all user contributions and use of interactive services. User
        contributions must in their entirety comply with all applicable federal, local, and international laws and
        regulations. Without limiting the foregoing, user contributions must not (a) contain any material which is
        defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise
        objectionable; (b) promote sexually explicit or pornographic material, violence, or discrimination based on
        race, sex, religion, nationality, disability, sexual orientation, or age; (c) infringe any patent, trademark,
        trade secret, copyright or other intellectual property, or other rights of any other person; (d) violate the
        legal rights (including the rights of publicity and privacy) of others or contain any material that could give
        rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in
        conflict with these Terms and our Privacy Policy; (e) be likely to deceive any person; (f) promote any illegal
        activity, or advocate, promote or assist any unlawful act; (g) cause annoyance, inconvenience, or needless
        anxiety, or be likely to upset, embarrass, alarm, or annoy any other person; (h) impersonate any person, or
        misrepresent Your identity or affiliation with any person or organization; (i) give the impression that they
        emanate from or are endorsed by Us or any other person or entity, if this is not the case; and/or (j) contain
        any material or information which may be false or misleading, notably as it relates to strategies and/or
        investments presented through the Services.
      </p>
      <h2>
        <li>Reliance on Information Posted</li>
      </h2>
      <p>
        Except as otherwise indicated, any information presented on or through the Service is made available solely for
        general educational and information purposes.{" "}
        <strong>We do not warrant the accuracy, completeness, or usefulness of this information</strong>. Any reliance
        You place on such information is strictly at Your own risk. WE DISCLAIM ALL LIABILITY AND RESPONSIBILITY ARISING
        FROM ANY RELIANCE PLACED ON SUCH MATERIALS BY YOU OR ANY OTHER USERS OF THE SERVICE, OR BY ANYONE WHO MAY BE
        INFORMED OF ANY OF ITS CONTENTS.
      </p>
      <h2>
        <li>Updates to the Service</li>
      </h2>
      <p>
        Company may from time to time in its sole discretion develop and provide Service updates, which may include
        upgrades, bug fixes, patches, and other error corrections and/or new features (collectively, including related
        documentation, &ldquo;Updates&rdquo;). Updates may also modify or delete in their entirety certain features and
        functionality. You agree that Company has no obligation to provide any Updates or to continue to provide or
        enable any particular features or functionality.
      </p>
      <p>
        You shall promptly download and install all Service Updates and acknowledge and agree that the Service or
        portions thereof may not properly operate if You fail to do so. You further agree that all Updates will be
        deemed part of the Service and be subject to all terms and conditions of these Terms. Any of the material on the
        Service may be out of date at any given time, and We are under no obligation to update such material.
      </p>
      <p>
        We reserve the right to withdraw or amend this Service in our sole discretion without notice. We will not be
        liable if for any reason all or any part of the Service is unavailable at any time or for any period. From time
        to time, We may restrict access to some parts of the Service, or the entire Service, to users, including
        registered users with a subscription.
      </p>
      <h2>
        <li>Information About You and Your Visits to the Service</li>
      </h2>
      <p>
        All information We collect on this Service is subject to our Privacy Policy. By using the Service, You consent
        to all actions taken by us with respect to Your information in compliance with the Privacy Policy.
      </p>
      <h2>
        <li>Use of Service Outside of the United States</li>
      </h2>
      <p>
        The Company makes not representations or claims that the Service is appropriate for or may be downloaded or
        accessed outside of the United States. If You access Service from outside the United States, You do so at your
        own risk and are responsible for compliance with the applicable laws of the country or jurisdiction where You
        may be located. You may not use or export any content of the Service in violation of U.S. export or other laws
        and regulations or any other U.S. or foreign federal, state or local statute, rule or regulation.The Company
        makes no representations that the information available through the Website or the Service is appropriate for
        use in all locations.
      </p>
      <h2>
        <li>Disclaimer of Warranties</li>
      </h2>
      <p>
        You understand that We cannot and do not guarantee or warrant that files available for downloading from the
        internet or the Service will be free of viruses or other destructive code. You are responsible for implementing
        sufficient procedures and checkpoints to satisfy Your particular requirements for anti-virus protection and
        accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of
        any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
        VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS,
        DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SERVICE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
        SERVICE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
      </p>
      <p>
        YOUR USE OF THE SERVICE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE IS AT YOUR OWN RISK.
        THE SERVICE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE ARE PROVIDED ON AN &ldquo;AS
        IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS AND WITH ALL FAULTS AND DEFECTS WITHOUT ANY WARRANTIES OF ANY
        KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY
        WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR
        AVAILABILITY OF THE SERVICE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE
        COMPANY REPRESENTS OR WARRANTS THAT THE SERVICE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
        SERVICE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE
        OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICE OR ANY
        SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
      </p>
      <p>
        THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE,
        INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR
        PURPOSE.
      </p>
      <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
      <h2>
        <li>Specific Disclaimers</li>
      </h2>
      <p>
        Nothing herein shall be construed to create an attorney-client relationship between You and the Company.You
        agree that the Company&rsquo;s obligations pursuant to these Terms are solely administrative in nature. The
        Company does not provide any brokerage services, tax advice, accounting advice, legal advice and/or legal
        services in connection with these Terms to You.You are advised to seek counsel of a legal advisor, accountant,
        and/or tax professional pertaining to any offering of units in a fund, pursuit of any strategy, and/or any
        purchase or sale of securities.
      </p>
      <p>
        You are aware that the Service provided by the Company is not prepared in regard to any particular User. The
        Company may have multiple users with similar or identical investment interests to Yours.
      </p>
      <p>
        You acknowledge that the Company's relationship with You under these Terms in no way establishes an exclusive
        relationship and does not preclude the Company from participating in any other business, investment, and/or
        strategy.Furthermore, the Company is free to distribute the informational and educational materials it uses in
        its Service as it sees fit and at its sole discretion.
      </p>
      <p>
        Any links on the Website or available through the Service to other websites are not intended as referrals or
        endorsements, but are merely provided to the users of the Website and/or Service for convenience and
        informational purposes. The Company does not endorse the content of such websites nor the products, services or
        other items offered through such websites.
      </p>
      <p>
        Company strongly advises all Users to read these Terms, the Privacy Policy, the Disclaimer, and any applicable
        agreements for complete details.
      </p>
      <h2>
        <li>Limitation on Liability</li>
      </h2>
      <p>
        IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS
        OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH
        YOUR USE, OR INABILITY TO USE, THE SERVICE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE SERVICE OR SUCH OTHER
        WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT,
        INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY,
        PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
        SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH
        OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
      </p>
      <p>THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
      <h2>
        <li>Indemnification</li>
      </h2>
      <p>
        You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors, and service providers,
        and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers,
        successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs,
        expenses or fees (including reasonable attorneys' fees) arising out of or relating to Your violation of these
        Terms or Your use of the Service, including, but not limited to, Your user contributions, any use of the
        Service's content, services, and products other than as expressly authorized in these Terms or Your use of any
        information obtained from the Service.
      </p>
      <h2>
        <li>Governing Law and Jurisdiction</li>
      </h2>
      <p>
        All matters relating to the Service and these Terms, and any dispute or claim arising therefrom or related
        thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in
        accordance with the laws of State of Texas, without giving effect to any choice or conflict of law provision or
        rule. The application of the United Nations Convention for the International Sale of Goods is expressly
        excluded.
      </p>
      <p>
        Any legal suit, action or proceeding arising out of, or related to, these Terms or the Service shall be
        instituted exclusively in Texas. You waive any and all objections to the exercise of jurisdiction over You by
        such courts and to venue in such courts.
      </p>
      <h2>
        <li>Limitation on Time to File Claims</li>
      </h2>
      <p>
        <strong>
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OR THE SERVICE MUST BE
          COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
          PERMANENTLY BARRED
        </strong>
        .
      </p>
      <h2>
        <li>Waiver and Severability</li>
      </h2>
      <p>
        No waiver by the Company of any term or condition set forth in these Terms shall be deemed a further or
        continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the
        Company to assert a right or provision under these Terms shall not constitute a waiver of such right or
        provision.
      </p>
      <p>
        If any provision of these Terms is held by a court or other tribunal of competent jurisdiction to be invalid,
        illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent
        such that the remaining provisions of the Terms will continue in full force and effect.
      </p>
      <h2>
        <li>Entire Agreement; Conflict</li>
      </h2>
      <p>
        The Terms, our Privacy Policy, Disclaimer, and any applicable agreements We enter with You constitute the sole
        and entire agreement between You and the Company with respect to the subject matter hereof and supersede all
        prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral,
        with respect to the Service. In the event of conflict, the provisions of these Terms shall prevail.
      </p>
      <h2>
        <li>Relationship of the Parties</li>
      </h2>
      <p>
        These Terms are not an agency, partnership, joint venture, employment, or other such business arrangement. You
        may not act as agent for, or on behalf of, the Company, or to represent the Company, or bind the Company in any
        manner.
      </p>
      <h2>
        <li>Assignment</li>
      </h2>
      <p>
        The Company may assign these Terms or any part of them, and the Company may delegate any of its obligations
        under these Terms. You may not assign these Terms or any part of them, nor transfer or sub-license Your rights
        under these Terms, to any third party.
      </p>
      <h2>
        <li>Your Comments and Concerns</li>
      </h2>
      <p>
        All other feedback, comments, requests for technical support and other communications relating to the Service
        should be directed to: <a href="mailto:help@letter.co">help@letter.co</a>
      </p>
    </ol>
  </TermsHolder>
);
