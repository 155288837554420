import { createGlobalStyle } from "styled-components";
import { colorPalette as colors, ColorNames, fonts } from "./theme";

export const TIMING_EASE_OUT_CUBIC = "cubic-bezier(0.215, 0.610, 0.355, 1.000)";

export const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    margin: 0;
    padding: 0;
    font-family: "NeufileGrotesk", -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    touch-action: manipulation;
    -webkit-touch-callout: none;
  }

  html, body, #root {
    /* height: 100%; have to remove this for scroll height check to work */
    overflow-x: hidden;
    min-height: 100vh;
    @supports (-webkit-touch-callout: none) {
      min-height: -webkit-fill-available;
    }
  }

  html {
    --extraDeviceTopPadding: 0px; /* px value required for this to work */
    --extraDeviceTopPaddingSidebar: 22px;
    /* use extraDeviceTopPadding inside expo to account for the iOS status bar */
    --navbarHeight: 74px;
    --subnavHeight: 0px;
    --sideMenuWidth: 300px;
    --maxContentWidth: 660px;

    /* layers */
    --zIndex-content: 1;
    --zIndex-sticky: 6;
    --zIndex-subnav: 11;
    --zIndex-nav: 12;
    --zIndex-sideMenuOverlay: 50;
    --zIndex-sideMenuContainer: 51;
    --zIndex-sideMenuLinks: 52;
    --zIndex-sideMenuTabs: 53;
    --zIndex-verifiedAlert: 94;
    --zIndex-overlay: 95;
    --zIndex-bottomSheet: 96;
    --zIndex-alert: 100;

    @media only screen and (max-device-width: 1024px) {
      --navbarHeight: 54px;
     /* --extraDeviceTopPadding: 26px; */
     /* --extraDeviceTopPaddingSidebar: 48px; */
    }
  }

  body {
    overflow-x: hidden;
  }

  .mobile-touch {
    -webkit-appearance: none;
    -webkit-touch-callout: none;
    touch-action: manipulation;
    -webkit-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
  }

  .visx-tooltip { z-index: 1000; }

  #plaid-link-iframe-1 {
    height: 95vh;
  }

  #teller-connect-window {
    height: 95vh;
    background-color: white;
  }

  fieldset {
    width: 100%;
    border: none;
    padding: 0;
    margin: 0;
  }

  form {
    flex: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
