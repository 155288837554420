import { ActionFunction } from "react-router-dom";
import * as Yup from "yup";

import { getFormValidationErrors } from "src/util/forms";
import { RouteActionResponse } from "src";
import { convertStringNumberToCents } from "src/util/stringUtils";
import { OneLineAuctionNotificationType } from "src/types/line";
import { UpcomingAuction } from "src/generated/client";

type Purchase = {
  horseVin: string;
  amount: number;
  description: string;
};

type FormData = {
  letterId: string;
  token: string;
  type: OneLineAuctionNotificationType;
  auctionDate: UpcomingAuction["date"];
};

type AddPurchaseData = FormData & { purchases: Purchase[]; type: OneLineAuctionNotificationType.Collateral };
type Retrieve = FormData & { type: OneLineAuctionNotificationType.Retrieve };
type PurchaseComplete = FormData & { type: OneLineAuctionNotificationType.PurchaseComplete };

export const registerAction: ActionFunction = async ({ request }) => {
  const { REACT_APP_BIFROST } = process.env;

  if (!REACT_APP_BIFROST) {
    return;
  }

  const { data } = Object.fromEntries(await request.formData());
  const formData: AddPurchaseData | Retrieve | PurchaseComplete = JSON.parse(data as string);

  const PurchaseSchema = Yup.object().shape<Purchase>({
    horseVin: Yup.string().required("Please enter a horse VIN."),
    description: Yup.string().required("Please enter a horse name."),
    amount: Yup.number().required().moreThan(0)
  });

  const commonSchemaProperties = {
    auctionDate: Yup.mixed<UpcomingAuction["date"]>().required("Please select an auction."),
    letterId: Yup.string().required("Please enter a Letter ID"),
    token: Yup.string().required("Token needed")
  };

  const AddPurchaseSchema = Yup.object().shape<Omit<AddPurchaseData, "type">>({
    ...commonSchemaProperties,
    purchases: Yup.array().of(PurchaseSchema)
  });

  const CloseCustomerSchema = Yup.object().shape<Omit<Retrieve | PurchaseComplete, "type">>(commonSchemaProperties);

  let options: RequestInit = {
    headers: {
      "Content-Type": "application/json"
    },
    method: "POST"
  };

  try {
    if (formData.type === OneLineAuctionNotificationType.Collateral) {
      const purchases = formData.purchases.map(({ amount, ...rest }) => ({
        ...rest,
        amount: convertStringNumberToCents(amount.toString())
      }));
      const purchaseData = { ...formData, purchases };

      await AddPurchaseSchema.validate(purchaseData, { abortEarly: false });

      options = { ...options, body: JSON.stringify(purchaseData) };
    }

    if (
      formData.type === OneLineAuctionNotificationType.PurchaseComplete ||
      formData.type === OneLineAuctionNotificationType.Retrieve
    ) {
      await CloseCustomerSchema.validate(formData, { abortEarly: false });

      options = { ...options, body: JSON.stringify(formData) };
    }

    const response = await fetch(`${REACT_APP_BIFROST}/oneline-auction`, options);

    const res = await response.json();

    const result: {
      success: boolean;
      data?: any;
      error?: string;
    } = {
      success: response.ok
    };

    if (!response.ok && res.error) {
      result.error = res.error;
    }

    if (response.ok && res) {
      result.data = res;
    }

    return { response: result };
  } catch (error) {
    const response: RouteActionResponse<unknown> = {};
    if (error instanceof Yup.ValidationError) {
      response.validationErrors = getFormValidationErrors(error);
    } else {
      response.error = error;
    }

    return response;
  }
};
