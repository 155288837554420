import React from "react";
import { useNavbar } from "src/util/useNavbar";

import Layout from "src/components/core-layout/Layout";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import {
  ActionableButtonGroup,
  ActionableButtonGroupLabel
} from "src/components/actionable-button/ActionableButton.styles";

import { ColorNames } from "src/theme/theme";
import { ReactComponent as IconChat } from "src/assets/icons/chat.svg";
import { ReactComponent as IconBook } from "src/assets/icons/book.svg";
import { ReactComponent as IconPhone } from "src/assets/icons/phone.svg";
import { ReactComponent as IconEmail } from "src/assets/icons/email.svg";

interface HelpLinks {
  label: string;
  url: string;
}

const quickHelpLinks: HelpLinks[] = [
  { label: "Lost/Stolen Card", url: "https://help.letter.co/help/how-do-i-report-a-lost-or-stolen-card" },
  { label: "Fraud", url: "https://help.letter.co/help/how-do-i-report-fraud-on-my-account" },
  { label: "Money Transfer Issue", url: "https://help.letter.co/help/money-transfer-issue" }
];

const Help = () => {
  useNavbar({ title: "Help" });

  return (
    <Layout>
      <ActionableButtonGroup noTopMargin>
        <ActionableButton
          label="Chat With Us"
          color={ColorNames.MIDNIGHT}
          iconRight={<IconChat />}
          onClick={() => window.groove.widget.open()}
        />
        <ActionableButton
          label="Search Knowledgebase"
          color={ColorNames.PINE}
          iconRight={<IconBook />}
          onClick={() => window.open("https://help.letter.co", "_blank")}
        />
        <ActionableButton
          label="(855) 954-1888"
          color={ColorNames.MINT}
          iconRight={<IconPhone />}
          onClick={() => window.open("tel:8559541888")}
        />
        <ActionableButton
          label="help@letter.co"
          color={ColorNames.MINT}
          iconRight={<IconEmail />}
          onClick={() => window.open("mailto:help@letter.co")}
        />
      </ActionableButtonGroup>

      <ActionableButtonGroup backgroundColor={ColorNames.GRAY1}>
        <ActionableButtonGroupLabel children="Quick help for urgent issues" />
        {quickHelpLinks.map((link, index) => (
          <ActionableButton
            key={index}
            label={link.label}
            color={ColorNames.MINT}
            onClick={() => window.open(link.url, "_blank")}
          />
        ))}
      </ActionableButtonGroup>
    </Layout>
  );
};

export default Help;
