import React from "react";
import { ColorNames, ThemeColor } from "src/theme/theme";

import {
  StyledSegmentedControl,
  StyledSegmentedControlOption
} from "src/components/segmented-control/SegmentedControl.styles";

export interface SegmentedControlOption {
  label: string;
  count?: number;
  value: string;
}

export interface SegmentedControlProps {
  options: SegmentedControlOption[];
  active: string;
  onClick: (value: string) => void;
  color?: ThemeColor;
  condensed?: boolean;
}

export interface SegmentedControlTheme {
  primaryText: ThemeColor;
  secondaryText: ThemeColor;
  background: ThemeColor;
}

export const SegmentedControl = ({ options, active, onClick, color, condensed }: SegmentedControlProps): JSX.Element => {
  let themeObject: SegmentedControlTheme = {
    primaryText: ColorNames.PINE,
    secondaryText: ColorNames.TEA,
    background: ColorNames.MINT,
  };
  switch (color) {
    case ColorNames.MIST:
      themeObject = {
        primaryText: ColorNames.COBALT,
        secondaryText: ColorNames.BABY,
        background: ColorNames.MIST,
      };
      break;
    case ColorNames.BREEZE:
      themeObject = {
        primaryText: ColorNames.OCEAN,
        secondaryText: ColorNames.LAGOON,
        background: ColorNames.BREEZE,
      };
      break;
    case ColorNames.CINNAMON:
      themeObject = {
        primaryText: ColorNames.WHITE,
        secondaryText: ColorNames.PEANUT,
        background: ColorNames.CINNAMON,
      };
      break;
  }

  return (
    <StyledSegmentedControl condensed={condensed}>
      {options.map((option) => {
        return (
          <StyledSegmentedControlOption
            key={option.value}
            active={option.value === active}
            onClick={() => onClick(option.value)}
            theme={themeObject}
          >
            <div className="label">{option.label}</div>
            {
              // Yes, we need to explicitly check for undefined here. Just checking falsy would not show a "0" count. We DO want to show "0"s
              option.count !== undefined && (<div className="count">{option.count}</div>)
            }
          </StyledSegmentedControlOption>
        );
      })}
    </StyledSegmentedControl>
  );
};
