import styled from "styled-components";
import { motion } from "framer-motion";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";
import { TIMING_EASE_OUT_CUBIC } from "src/theme/globalStyles";

export const TransferPage = styled(motion.div)`
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TransferSuccessPage = styled(motion.div)`
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TransferContentContainer = styled.div`
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  padding: 0 30px 64px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  &.success {
    justify-content: space-between;
  }
`;

export const TransferFormInput = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  div {
    text-align: center;
  }
`;

export const TransferActionTitle = styled.div`
  ${fonts.PARAGRAPH_L}
  color: ${colors[ColorNames.MIDNIGHT].hex};
  text-align: center;
  margin: 16px 0;

  small {
    display: block;
    font-weight: 500;
    font-size: 14px;
    margin-top: -5px;
  }
`;

export const TransferAccountSelectionContainer = styled.div`
  flex: 1 0 auto;
`;

export const TransferButtonGroup = styled.div`
  flex: 0 0 auto;
  padding-top: 30px;
  pointer-events: none;

  button {
    transform: translateY(50%);
    opacity: 0;
    transition: ${TIMING_EASE_OUT_CUBIC} 0.3s all;
  }

  &[data-enabled="true"] {
    pointer-events: all;

    button {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

export const SuccessHeader = styled.div`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
  padding-top: 24px;

  h2 {
    font-family: "AlbraSans";
    font-weight: 600;
    font-size: 24px;
    line-height: 125%;
    color: ${colors[ColorNames.GRASS].hex};
    text-align: center;
    max-width: 300px;
    margin-top: 1rem;
  }
`;

export const TwoPartyHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 200px;
`;

export const TransferSummary = styled.div`
  margin-bottom: 20px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  ${fonts.PARAGRAPH_M};
`;
export const RowLabel = styled.div`
  color: ${colors[ColorNames.GRAY5].hex};
`;

export const RowContent = styled.div`
  word-break: break-word;
  text-align: right;
  font-feature-settings: "tnum" on, "lnum" on;

  small {
    display: block;
    color: ${colors[ColorNames.GRAY5].hex};
    margin-top: 0.25em;
  }
`;
