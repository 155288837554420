import React, { useEffect, useState } from "react";
import { useFetcher, useParams } from "react-router-dom";

import {
  Line, LoanType, LineDocumentPdf
} from "src/generated/client";
import { NavRoutes } from "src/routes/navRoutes";
import { NavigationDirection } from "src/store/navigationState";
import { ColorNames } from "src/theme/theme";
import { decorateCurrencyValue, formatDate, formatMonthYear } from "src/util/stringUtils";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { useNavbar } from "src/util/useNavbar";
import useNavigate from "src/util/useNavigate";
import { TabRouter, TabView, useSubnav } from "src/util/useSubnav";

import { InfoListItemsWrapper } from "src/components/info-list-item/InfoListItem.styles";
import { ListButtonContainer } from "src/components/lists/Lists.styles";
import { StyledRingCallout } from "src/components/ring-callout/RingCallout.styles";
import {
  AccountDetailsList,
  AccountDetailsRow,
  AccountsPageContainer,
  AmountDollars,
  BalanceAmountHeader,
  BalanceLabel,
  InfoGroup
} from "src/routes/app/bank/accounts/Accounts.styles";
import { GenericWrapper } from "../OneLine.styles";

import { ReactComponent as IconHorse } from "src/assets/icons/horse.svg";
import { ReactComponent as IconPopup } from "src/assets/icons/popup.svg";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { BannerNotice } from "src/components/alert/BannerNotice";
import { BalanceAmount } from "src/components/balance-amount/BalanceAmount";
import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import AmortizationSheet from "src/components/bottom-sheets/amortization-sheet/AmortizationSheet";
import Layout from "src/components/core-layout/Layout";
import { DetailRow } from "src/components/detail-row/DetailRow";
import { InfoListItem } from "src/components/info-list-item/InfoListItem";
import { ListButton } from "src/components/lists/ListButton";
import { RingProgress } from "src/components/ring-progress/RingProgress";

const SUBNAV_TABS = ["overview", "details", "documents"];

const LoanTypeDisplays: Record<LoanType, string> = {
  [LoanType.FullyAmortized]: "Fully Amortized"
};

const LineDetail = () => {
  const { lineId = "", section = "overview" } = useParams();
  const { data: line, state: loadState, load } = useFetcher<Line>();
  const { data: lineDocuments, state: loadingDocState, load: loadLineDocuments } = useFetcher<LineDocumentPdf[]>();

  const navigate = useNavigate();
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();

  const { updateNavbar } = useNavbar({
    back: true,
    title: "...",
    backAction: () =>
      navigate(NavRoutes.ONELINE_LINES, {
        direction: NavigationDirection.BACKWARD
      })
  });
  const { selected } = useSubnav(SUBNAV_TABS, {
    selectedTab: Math.max(SUBNAV_TABS.indexOf(section), 0)
  });

  useEffect(() => {
    if (!line && loadState === "idle") load(`${NavRoutes.API_LINE}?lineId=${lineId}`);
  }, [line, loadState, load]);

  useEffect(() => {
    if (!lineDocuments && loadingDocState === "idle") {
      loadLineDocuments(`${NavRoutes.API_LINE}?get=lineDocuments&lineId=${lineId}`);
    }
  }, [lineDocuments, loadingDocState, loadLineDocuments])

  useEffect(() => {
    if (!line) return;
    updateNavbar({ title: line.lineType.title || "" });
  }, [line]);

  const open = (url: string) => {
    const sliceSize = 512;
    const byteCharacters = window.atob(url);

    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: 'application/pdf' });
    const blobURL = URL.createObjectURL(blob);
    window.open(blobURL, "_blank");
  };

  return (
    <Layout loading={!line}>
      <TabRouter>
        <TabView key={0} show={selected === 0}>
          <BalanceAmountHeader>
            <AmountDollars color={ColorNames.MIDNIGHT}>
              <BalanceAmount availableBalance={line?.loanAmount.display || "--.--"} />
            </AmountDollars>

            <BalanceLabel color={ColorNames.OCEAN}>
              <div className="label">Outstanding Balance</div>
            </BalanceLabel>
          </BalanceAmountHeader>

          <GenericWrapper>
            <BannerNotice
              iconLeft={<IconHorse />}
              iconColor={ColorNames.LAGOON}
              textColor={ColorNames.OCEAN}
              titleColor={ColorNames.DEEP_WATER}
              backgroundColor={ColorNames.BREEZE}
              title={
                (line?.collateral.length || 0) > 1
                  ? `${line?.collateral.length} ${line?.collateral[0].collateralType}s`
                  : line?.collateral[0].description
              }
              children={(line?.collateral.length || 0) > 1 ? undefined : <>{line?.collateral[0].identification}</>}
              iconRight={(line?.collateral.length || 0) > 1 ? <IconPopup /> : undefined}
              onClick={
                !!line && line?.collateral.length > 1
                  ? () => {
                      showBottomSheet("collateral_sheet");
                    }
                  : undefined
              }
            />
          </GenericWrapper>

          <AccountDetailsList>
            <AccountDetailsRow>
              <div className="label">Monthly Payment</div>
              <div className="amount">{decorateCurrencyValue(line?.monthlyRepaymentAmount.display) || "..."}</div>
            </AccountDetailsRow>
            <AccountDetailsRow>
              <div className="label">Next Payment On</div>
              <div className="amount">{formatDate(line?.subscription?.nextPaymentDate)}</div>
            </AccountDetailsRow>
            <AccountDetailsRow>
              <div className="label">Late Payments</div>
              <div className="amount">{line?.subscription?.latePayments?.length}</div>
            </AccountDetailsRow>
          </AccountDetailsList>

          {/* <ActionableButtonRow>
            <ActionableButton
              label="View Payments"
              color={ColorNames.OCEAN}
              onClick={() => {
                navigate(NavRoutes.BANK_TRANSACTIONS);
              }}
            />
          </ActionableButtonRow> */}

          <GenericWrapper>
            <StyledRingCallout>
              <div className="heading">
                <div className="title">
                  {line?.monthsRemaining || -1 > 0
                    ? `${line?.monthsRemaining} Month${line?.monthsRemaining === 1 ? "" : "s"} Remaining`
                    : ""}
                </div>
                <RingProgress
                  value={(line?.monthsTotal || 0) - (line?.monthsRemaining || 0)}
                  total={line?.monthsTotal || 100}
                  color={ColorNames.MIDNIGHT}
                  size={26}
                  strokeWidth={4}
                  trackStrokeWidth={2}
                  showText={false}
                />
              </div>
              <InfoListItem
                title="Approx. Payoff Date"
                value={formatMonthYear(line?.projectedPayoffDate)}
                valueType="number"
                size="small"
              />
            </StyledRingCallout>
          </GenericWrapper>
        </TabView>
        <TabView key={1} show={selected === 1}>
          <AccountsPageContainer>
            <InfoGroup background={ColorNames.GRAY1} dataColor={ColorNames.OCEAN}>
              <DetailRow large label="Loan Amount" data={decorateCurrencyValue(line?.loanAmount.display)} />
              <DetailRow
                large
                label="Projected Interest"
                data={`+${decorateCurrencyValue(line?.loanInterestAmount.display)}`}
                dataColor={ColorNames.GRAY5}
              />
              <DetailRow
                large
                label="Projected Total"
                data={decorateCurrencyValue(line?.amountBorrowed.display)}
                dividerTop
              />
              <ActionableButton
                label="View Amortization Table"
                color={ColorNames.OCEAN}
                iconRight={<IconPopup />}
                onClick={() => showBottomSheet("amortization_sheet")}
              />
            </InfoGroup>

            <InfoGroup background={ColorNames.GRAY1} dataColor={ColorNames.OCEAN}>
              <DetailRow
                large
                label="Outstanding Balance"
                data={decorateCurrencyValue(line?.outstandingBalance.display)}
              />
              <DetailRow
                large
                label="Principal Paid"
                data={`-${decorateCurrencyValue(line?.principalAmountPaidOff.display)}`}
                dataColor={ColorNames.GRAY5}
                dividerTop
              />
              <DetailRow
                large
                label="Interest Paid"
                data={`-${decorateCurrencyValue(line?.interestAmountPaidOff.display)}`}
                dataColor={ColorNames.GRAY5}
              />
            </InfoGroup>

            <InfoListItemsWrapper>
              <InfoListItem title="Interest Rate" value={line?.interestRate.display} valueType="number" size="small" />
              <InfoListItem
                title="Term Length"
                value={`${line?.termLengthInMonths} month${line?.termLengthInMonths === 1 ? "" : "s"}`}
                valueType="number"
                size="small"
              />
              <InfoListItem title="Started On" value={formatDate(line?.createdAt)} valueType="number" size="small" />
              <InfoListItem
                title="Projected Payoff On"
                value={formatDate(line?.projectedPayoffDate)}
                valueType="number"
                size="small"
              />
              <InfoListItem
                title="Loan Type"
                value={line?.loanType ? LoanTypeDisplays[line.loanType] : "–"}
                size="small"
              />
              <InfoListItem
                title="Collateral"
                value={line?.collateral
                  .map((c) => c.collateralType)
                  .filter((i, p, q) => q.indexOf(i) === p)
                  .join(", ")}
                size="small"
              />
              <InfoListItem title="Personal Guarantee" value={line?.personalGuarantee ? "Yes" : "No"} size="small" />
            </InfoListItemsWrapper>
          </AccountsPageContainer>
        </TabView>
        {lineDocuments ? <TabView key={2} show={selected === 2}>
          <ListButtonContainer>
            {lineDocuments.map((document, index) => {
              return (
                <ListButton
                  key={index}
                  label={document.name}
                  onClick={() => open(document.url)}
                />
              );
            })}
          </ListButtonContainer>
        </TabView> : <></>}
      </TabRouter>

      <AmortizationSheet id="amortization_sheet" line={line} />

      <ActionBottomSheet
        id="collateral_sheet"
        title="Collateral"
        actionText="Close"
        actionColor={ColorNames.TRANSPARENT}
        onAction={() => hideBottomSheet()}
      >
        <InfoListItemsWrapper>
          {!!line &&
            line.collateral.map((c, i) => {
              return <InfoListItem key={i} title={c.description} value={c.identification} />;
            })}
        </InfoListItemsWrapper>
      </ActionBottomSheet>
    </Layout>
  );
};

export default LineDetail;
