import { makeVar } from "@apollo/client";
import { ColorNames, ThemeColor } from "src/theme/theme";

export const coreLayoutBodyBackgroundColor = makeVar<ThemeColor>(ColorNames.WHITE);

export const coreLayoutWrapperHasOpenModal = makeVar(false);

export const coreLayoutInsideWrapHasShadow = makeVar(false);

export const coreLayoutScrollYPosition = makeVar(0);
