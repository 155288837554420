import React from "react";
import { CypressTestIds } from "src/util/testing-util/test-utils";

import { MfaContentContainer, MfaDescription, MfaHeader, StepHeader, ExternalLink, ExternalLinkListItem } from "src/pages/mfa/mfa.styles";
import { Button } from "src/components/button/Button";

const DownloadMfa = ({ handleNextStep }: { handleNextStep: () => void }): JSX.Element => {
  return (
    <MfaContentContainer>
      <div>
        <MfaHeader>Protect your account with Multi-Factor Authentication.</MfaHeader>
        <MfaDescription>
          Each time you sign into your Letter account you’ll need to enter your password as well as a separate unique
          code generated by an authenticator app on your device.
          <br />
          <ExternalLink
            href="https://www.nist.gov/itl/applied-cybersecurity/tig/back-basics-multi-factor-authentication"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn More
          </ExternalLink>
        </MfaDescription>

        <StepHeader>Step 1</StepHeader>
        <MfaDescription>
          Download and install one of these authenticator apps on your mobile device:
        </MfaDescription>
        <MfaDescription>
          <ExternalLinkListItem
            href="https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DAndroid&hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Authenticator
          </ExternalLinkListItem>
          <ExternalLinkListItem href="https://1password.com/" target="_blank" rel="noopener noreferrer">
            1Password
          </ExternalLinkListItem>
          <ExternalLinkListItem
            href="https://apps.apple.com/us/app/otp-conductor/id1404947090"
            target="_blank"
            rel="noopener noreferrer"
          >
            OTP Conductor
          </ExternalLinkListItem>
          <ExternalLinkListItem href="https://authy.com/" target="_blank" rel="noopener noreferrer">
            Authy Authenticator
          </ExternalLinkListItem>
        </MfaDescription>
      </div>

      <Button data-cy={CypressTestIds.Q_VERIFY_MFA_CONTINUE_BTN} onClick={handleNextStep} raised>
        Continue
      </Button>
    </MfaContentContainer>
  );
};

export default DownloadMfa;
