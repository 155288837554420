import { useEffect, useCallback } from "react";
import {
  useMutation,
  ApolloError,
  useQuery,
  useLazyQuery,
} from "@apollo/client";
import * as Fullstory from "@fullstory/browser";
import { NavRoutes } from "src/routes/navRoutes";
import { useLogger } from "src/util/useLogger";
import { useSetSession } from "src/services/auth";

import {
  GET_LOGGED_IN_USER,
  CREATE_USER,
  UPDATE_USER,
  ADD_PERSONAL_INFO,
  TRIGGER_VERIFICATION,
  ADD_VERIFICATION_TO_USER,
  CANCEL_ACCOUNT,
  REQUEST_PASSWORD_RESET,
  RESET_PASSWORD,
  OPEN_ACCOUNT,
  ADD_DEVICE_TOKEN,
  EDIT_NOTIFICATION_SETTINGS,
  VALIDATE_RESET_TOKEN,
  REQUEST_MORE_REFERRALS,
} from "src/services/gql/_users";
import { User } from "src/generated/client";

export interface CreateUserData {
  firstName: string;
  lastName: string;
  phone: string;
  netWorth?: string;
  email?: string;
  password?: string;
  referralCode?: string;
  inviteCode?: string;
}

export interface EditUserData {
  firstName: string;
  lastName: string;
  phone: string;
  netWorth?: string;
  userId: string;
}

export interface AddPersonalInfoData {
  DOB: string;
  SSN?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zip?: string;
  userId: string;
}

export interface AddVerificationToUserData {
  verificationType: UserVerificationType;
  verificationImage?: Blob | string;
  token?: string;
  idVerificationName?: string;
  organizationId?: string;
  userId: string;
}

export enum UserVerificationType {
  Id = "Id",
  Phone = "Phone",
  Email = "Email",
  Wealth = "Wealth",
  Survey = "Survey",
  Mfa = "Mfa",
  Face = "Face",
}

export interface AddUserDeviceTokenData {
  deviceToken: string;
}

export interface EditUserNotificationSettingsData {
  receivePush: boolean;
  receiveSms: boolean;
}

interface GetUserOptions {
  includeUnitData?: boolean;
  fromNetwork?: boolean;
}

export const useCurrentUser = () => {
  const { data } = useQuery<{ me: User }>(GET_LOGGED_IN_USER, {
    errorPolicy: "all",
    fetchPolicy: "cache-only",
  });

  return data?.me;
};

export const useFetchCurrentUser = (): {
  fetchCurrentUser: (options?: GetUserOptions) => void;
  userLoading: boolean;
  userError: ApolloError | undefined;
  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;
} => {
  const [
    user,
    {
      data: userData,
      loading: userLoading,
      error: userError,
      startPolling,
      stopPolling,
    },
  ] = useLazyQuery(GET_LOGGED_IN_USER, {
    errorPolicy: "all",
  });

  const fetchCurrentUser = useCallback(
    (options?: GetUserOptions): void => {
      user({
        variables: { includeUnitData: options?.includeUnitData },
        fetchPolicy: options?.fromNetwork ? "network-only" : "cache-first",
      });
    },
    [user]
  );

  useEffect(() => {
    if (userLoading) return;
    if (!!userData?.me?.id) Fullstory.identify(userData.me.email);
  }, [userData, userLoading]);

  return {
    fetchCurrentUser,
    userLoading,
    userError,
    startPolling,
    stopPolling,
  };
};

export const hofundRoutes = () => {
  return {
    [NavRoutes.FORGOT_PASSWORD]: REQUEST_PASSWORD_RESET,
    [NavRoutes.RESET_PASSWORD]: RESET_PASSWORD,
    // [NavRoutes.UNLOCK_ACCOUNT]: LOCAL_UNLOCK,
    [NavRoutes.USER_ACCOUNT_SETTINGS]: ADD_DEVICE_TOKEN,
    [NavRoutes.REQUEST_MORE_REFERRALS]: REQUEST_MORE_REFERRALS,
  };
};

export const useEditUser = (
  create: boolean
): {
  edit: (values: CreateUserData | EditUserData) => Promise<User | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [editUser, { loading, error }] = useMutation(
    create ? CREATE_USER : UPDATE_USER,
    { errorPolicy: "all" }
  );
  const setSession = useSetSession();
  const { captureException } = useLogger();

  const edit = useCallback(
    async (
      values: CreateUserData | EditUserData
    ): Promise<User | undefined> => {
      if (create) setSession();
      try {
        const { data } = await editUser({ variables: values });
        if (!!data?.createUser || !!data?.editBasicUserInformation) {
          const user: User = create
            ? data.createUser
            : data.editBasicUserInformation;
          if (!!user?.token) setSession(user.token);
          return user;
        }
      } catch (err) {
        captureException(err);
      }
    },
    [editUser, setSession, create, captureException]
  );

  return { edit, loading, error };
};

export const useAddPersonalInfo = (): {
  addInfo: (values: AddPersonalInfoData) => Promise<User | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [addPersonalInfo, { loading, error }] = useMutation(ADD_PERSONAL_INFO, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const addInfo = useCallback(
    async (values: AddPersonalInfoData): Promise<User | undefined> => {
      try {
        const { data } = await addPersonalInfo({ variables: values });
        return !!data?.addPersonalInformationToUser
          ? data.addPersonalInformationToUser
          : undefined;
      } catch (err) {
        captureException(err);
      }
    },
    [addPersonalInfo, captureException]
  );

  return { addInfo, loading, error };
};

export const useAddDeviceToken = (): {
  addDeviceToken: (values: AddUserDeviceTokenData) => Promise<User | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [addUserDeviceToken, { loading, error }] = useMutation(
    ADD_DEVICE_TOKEN,
    { errorPolicy: "all" }
  );
  const { captureException } = useLogger();

  const addDeviceToken = useCallback(
    async (values: AddUserDeviceTokenData): Promise<User | undefined> => {
      try {
        const { data } = await addUserDeviceToken({ variables: values });
        console.log(`********************* add device token: `, data);
        return undefined;
      } catch (err) {
        captureException(err);
      }
    },
    [addUserDeviceToken, captureException]
  );

  return { addDeviceToken, loading, error };
};

export const useEditNotificationSettings = (): {
  editNotificationSettings: (
    values: EditUserNotificationSettingsData
  ) => Promise<boolean>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [editUserNotificationSettings, { loading, error }] = useMutation(
    EDIT_NOTIFICATION_SETTINGS,
    {
      errorPolicy: "all",
    }
  );
  const { captureException } = useLogger();

  const editNotificationSettings = useCallback(
    async (values: EditUserNotificationSettingsData): Promise<boolean> => {
      try {
        const { data } = await editUserNotificationSettings({
          variables: values,
        });
        return !!data;
      } catch (err) {
        captureException(err);
        return false;
      }
    },
    [editUserNotificationSettings, captureException]
  );

  return { editNotificationSettings, loading, error };
};

export const useTriggerVerification = (): {
  trigger: (verificationType: UserVerificationType) => Promise<boolean>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [triggerVerification, { loading, error }] = useMutation(
    TRIGGER_VERIFICATION,
    { errorPolicy: "all" }
  );
  const { captureException } = useLogger();

  const trigger = useCallback(
    async (verificationType: UserVerificationType): Promise<boolean> => {
      try {
        const { data } = await triggerVerification({
          variables: { type: verificationType },
        });
        return !!data?.triggerVerification?.success;
      } catch (err) {
        captureException(err);
        return false;
      }
    },
    [triggerVerification, captureException]
  );

  return { trigger, loading, error };
};

export const useValidateResetPasswordToken = (): {
  validateToken: (token: string) => Promise<boolean>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [tokenValidation, { loading, error }] = useMutation(
    VALIDATE_RESET_TOKEN,
    { errorPolicy: "all" }
  );
  const { captureException } = useLogger();

  const validateToken = useCallback(
    async (token: string): Promise<boolean> => {
      try {
        const { data } = await tokenValidation({ variables: { token } });
        return !!data?.validatePasswordResetToken?.valid;
      } catch (err) {
        captureException(err);
        return false;
      }
    },
    [tokenValidation, captureException]
  );

  return { validateToken, loading, error };
};

export const useAddVerificationToUser = (): {
  addVerification: (
    values: AddVerificationToUserData
  ) => Promise<User | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [addVerificationToUser, { loading, error }] = useMutation(
    ADD_VERIFICATION_TO_USER,
    {
      errorPolicy: "all",
    }
  );
  const { captureException } = useLogger();

  const addVerification = useCallback(
    async (values: AddVerificationToUserData): Promise<User | undefined> => {
      try {
        const { data } = await addVerificationToUser({ variables: values });
        return !!data?.addVerificationToUser
          ? data.addVerificationToUser
          : undefined;
      } catch (err) {
        captureException(err);
      }
    },
    [addVerificationToUser, captureException]
  );

  return { addVerification, loading, error };
};

export const useCancelAccount = (): {
  cancel: () => Promise<boolean>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [cancelAccount, { loading, error }] = useMutation(CANCEL_ACCOUNT, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const cancel = useCallback(async (): Promise<boolean> => {
    try {
      const { data } = await cancelAccount();
      return !!data?.cancelAccount?.success;
    } catch (err) {
      captureException(err);
      return false;
    }
  }, [cancelAccount, captureException]);

  return { cancel, loading, error };
};

export const useOpenAccount = (): {
  openAccount: () => Promise<User | undefined>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [openBankingAccount, { loading, error }] = useMutation(OPEN_ACCOUNT, {
    errorPolicy: "all",
  });
  const { captureException } = useLogger();

  const openAccount = useCallback(async (): Promise<User | undefined> => {
    try {
      const { data } = await openBankingAccount();
      return (data?.openAccount as User) || undefined;
    } catch (err) {
      captureException(err);
    }
  }, [openBankingAccount, captureException]);

  return { openAccount, loading, error };
};
