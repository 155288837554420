import styled, { css } from "styled-components";
import { ReactComponent as LeftArrow } from "src/assets/icons/arrow_left.svg";
import { ReactComponent as Close } from "src/assets/icons/close.svg";
import { ColorNames, fonts } from "src/theme/theme";

export const navIconStyles = css`
  path {
    vector-effect: non-scaling-stroke;
  }
`;

export const StyledNavBar = styled.header`
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 128px;
  padding: 0 20px 38px;
  background: ${ColorNames.TRANSPARENT};
  position: relative;
  z-index: 4;

  &.bottomMargin {
    margin-bottom: 20px;
  }

  &.subnav {
    position: relative;
    height: 146px;
    padding-bottom: 58px;
    margin-bottom: 30px;
    background: ${ColorNames.WHITE};
    box-shadow: 0px 3px 26px 0px rgba(25, 27, 31, 0.04), 0px 2px 8px 0px rgba(25, 27, 31, 0.04),
      0px 1px 3px 0px rgba(25, 27, 31, 0.05);
  }
`;

export const StyledNavBarContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const BackArrow = styled(LeftArrow)`
  cursor: pointer;
  opacity: 1;
  transform: scale3d(1, 1, 1) rotate(0);
  transition: transform 0.2s ease-out;
  will-change: transform, opacity;

  &:active {
    transform: scale3d(0.8, 0.8, 0.8);
    opacity: 0.8;
  }

  ${navIconStyles}
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  opacity: 1;
  transform: scale3d(1, 1, 1) rotate(0);
  transition: transform 0.2s ease-out;
  will-change: transform, opacity;

  &:active {
    transform: scale3d(0.8, 0.8, 0.8);
    opacity: 0.8;
  }

  ${navIconStyles}
`;

export const NavTitle = styled.p`
  ${fonts.HEADING_5}
  position: absolute;
  left: 70px;
  right: 70px;
  text-align: center;
  opacity: 0;
  animation: navtitle-fade-in 0.35s 0.2s ease both;
`;

export const NavSpacer = styled.div`
  width: 22px;
`;

export const ActionIconContainer = styled.div`
  flex: 0 0 auto;
  margin-left: auto;
  cursor: pointer;
`;
