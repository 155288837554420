import { gql } from "@apollo/client";

export const CREATE_PAYMENT = gql`
  mutation CreatePayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      id
      amount {
        amount
        display
      }
      description
    }
  }
`;

export const GET_INSTITUTION_INFO = gql`
  query GetInstitutionInfo($routingNumber: String!) {
    institutionInfo(routingNumber: $routingNumber) {
      routingNumber
      name
      address
      isAchSupported
      isWireSupported
    }
  }
`;