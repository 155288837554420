import styled from "styled-components";
import {
  colorPalette as colors,
  ColorNames,
  fonts,
  rgbGradientColors,
  dropShadows,
  ShadowNames,
  borderRadiusOuter
} from "src/theme/theme";
import { borderRadius } from "../../../theme/theme";

export const CAModalTitle = styled.h3`
  display: block;
  text-align: center;
  ${fonts.HEADING_5}
  font-size: 26px;
  color: currentColor;
  margin-bottom: 1em;
`;

export const CAModalBodyTitle = styled.p`
  display: block;
  text-align: center;
  margin: 40px 30px 0 30px;
  ${fonts.PARAGRAPH_L}
`;

export const CAModalBodyContent = styled.div`
  margin: 40px 30px;
`;

export const CAModalButtonWrapper = styled.div`
  margin-top: 50px;
`;

export const ProvidersContainer = styled.div`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  padding-top: 64px;
  overflow: hidden;
`;

export const ProvidersHeader = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  flex: 0 0 auto;
  height: 64px;
  border-bottom: 1px solid ${colors[ColorNames.GRAY2].hex};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  ${fonts.PARAGRAPH_M}

  svg {
    cursor: pointer;
  }
`;

export const ProvidersContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

export const ProvidersFooter = styled.div`
  height: 60px;
  background-color: ${colors[ColorNames.GRAY1].hex};
  display: flex;
  ${fonts.PARAGRAPH_S}
  color: ${colors[ColorNames.GRAY5].hex};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;

  .expanded {
    color: ${colors[ColorNames.MIDNIGHT].hex};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const CAModalAccountTypeButton = styled.button`
  width: 100%;
  border: none;
  outline: none;
  background: ${colors[ColorNames.WHITE].hex};
  border-radius: ${borderRadius};
  box-shadow: ${dropShadows[ShadowNames.LIGHT]};
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 66px;
  align-items: center;
  text-align: left;
  margin-bottom: 20px;
  transition: 0.15s ease all;
  position: relative;
  cursor: pointer;

  &:active {
    transform: scale(0.98);
  }

  .body-text-area {
    padding: 20px 25px;
  }

  h4 {
    ${fonts.PARAGRAPH_M}
    margin-bottom: 6px;
    color: ${colors[ColorNames.MIDNIGHT].hex};
  }

  p {
    ${fonts.PARAGRAPH_S}
    color: ${colors[ColorNames.GRAY5].hex};
  }

  .icon-area {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }

  .traditional {
    background-image: linear-gradient(
      180deg,
      rgb(${rgbGradientColors.tea[0]}) 0%,
      rgb(${rgbGradientColors.tea[1]}) 100%
    );
  }

  .crypto {
    background-image: linear-gradient(
      180deg,
      rgb(${rgbGradientColors.lake[0]}) 0%,
      rgb(${rgbGradientColors.lake[1]}) 100%
    );
  }

  &.disabled {
    background-color: ${colors[ColorNames.GRAY1].hex};
    cursor: not-allowed;

    h4,
    p {
      color: ${colors[ColorNames.GRAY4].hex};
    }

    .crypto {
      background-image: linear-gradient(
        180deg,
        ${colors[ColorNames.GRAY3].hex} 0%,
        ${colors[ColorNames.GRAY3].hex} 100%
      );
    }
  }
`;

export const ConnectedAccountsWidgetModalContainer = styled.div`
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 998;
  opacity: 0;
  transition: opacity 0.4s ease-out;
  background-color: rgba(${colors[ColorNames.GRAY4].rgb}, 0.6);
  backdrop-filter: blur(0);

  &.show {
    pointer-events: all;
    opacity: 1;
    backdrop-filter: blur(8px);
    transition: 0.15s ease opacity;
  }
`;

export const ConnectedAccountsWidgetModal = styled.div`
  width: 100%;
  height: 100%;
  // @media (min-width: 345px) and (min-height: 570px) {
  //   max-height: 88vh;
  //   border-radius: ${borderRadiusOuter};
  // }
  @media (min-width: 900px) {
    width: calc(100% - 40px);
  }
  z-index: 999;
  background: ${colors[ColorNames.WHITE].hex};
  display: grid;
  grid-template-rows: 68px 1fr;
  position: relative;
  overflow: hidden;
  box-shadow: ${dropShadows[ShadowNames.LIGHT]};
  opacity: 0;
  transform: scale(0.9);
  transition: 0.15s ease all;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.zabo {
    grid-template-rows: 1fr;
    padding-top: 24px;
  }
`;

export const EAWidgetTitle = styled.div`
  ${fonts.PARAGRAPH_M}
  text-align: left;
  background-color: ${colors[ColorNames.WHITE].hex};
  border-bottom: 1px solid ${colors[ColorNames.GRAY2].hex};
  padding: 24px 20px;
  position: relative;
  z-index: 5;

  &.zabo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
`;

export const EAWidgetCloseButton = styled.div`
  position: absolute;
  top: 23px;
  right: 16px;
  z-index: 8;
  cursor: pointer;
`;

export const EAWidgetLoading = styled.div`
  position: absolute;
  top: -28px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const EAWidgetEmbed = styled.div`
  background-color: ${colors[ColorNames.GRAY1].hex};
  position: relative;
  z-index: 2;
`;
