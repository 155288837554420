import gql from "graphql-tag";
import { Resolvers, ApolloCache } from "@apollo/client";

export const typeDefs = gql`
  extend type Query {
    session: String!
  }
`;

type ResolverFn = (parent: any, args: any, { cache }: { cache: ApolloCache<object> }) => any;

interface ResolverMap {
  [field: string]: ResolverFn;
}

type AppResolvers = Resolvers;

export const resolvers: AppResolvers = {};
