import styled from "styled-components";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

export const BankAccountDetailsSheetContainer = styled.div``;

export const BankAccountDetailsSheetTitle = styled.p`
  width: 100%;
  ${fonts.HEADING_4}
  color: ${colors[ColorNames.MIDNIGHT].hex};
  margin-bottom: 32px;
  text-align: center;
`;
