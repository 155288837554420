import styled from "styled-components";
import { StyledAuthContainer } from "src/pages/auth/Auth.styles";

export const SignInAuthContainer = styled(StyledAuthContainer)`
  fieldset {
    margin-top: 8vh;
  }
`;

export const MarginButtonContainer = styled.div`
  margin-top: 58px;
  width: 100%;
`;

export const CancelButtonContainer = styled(MarginButtonContainer)`
  padding-bottom: 58px;
`;

export const ActionsContainer = styled.div`
  width: 100%;

  a {
    display: block;
    width: 100%;
    margin-top: 8px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  button {
    width: 100%;
  }
`;
