import styled from "styled-components";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";
import { ReactComponent as LetterLogo } from "src/assets/logo-word.svg";

export const Logo = styled(LetterLogo)`
  margin: 83px 0 45px;
`;

export const StyledNotFoundPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  min-height: 100vh;
  padding: 0 38px;
  max-width: 570px;
  margin: 0 auto;
  background-color: ${colors[ColorNames.WHITE].hex};
  grid-column: 1 / -1;
`;

export const NotFoundCode = styled.p`
  ${fonts.HEADING_2}
  margin-bottom: 24px;
  color: ${colors[ColorNames.MIDNIGHT].hex};
`;

export const NotFoundBanner = styled.p`
  font-size: 30px;
  line-height: 130%;
  margin-bottom: 24px;
  color: ${colors[ColorNames.MIDNIGHT].hex};
`;

export const NotFoundDescription = styled.p`
  ${fonts.PARAGRAPH_M}
  color: ${colors[ColorNames.MIDNIGHT].hex};
`;
