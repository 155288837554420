import React, { useEffect, useRef, useState, useMemo } from "react";
import { AnimatePresence } from "framer-motion";
import { format } from "date-fns";
import { useParams, useFetcher } from "react-router-dom";

import { useCurrentUser } from "src/services/users";
import { useGetAccounts } from "src/services/accounts";
import { useGetConnectedAccounts } from "src/services/connected-accounts";
import { useGetRecipients } from "src/services/recipients";
import { showNotice } from "src/store/alertState";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { useNavbar } from "src/util/useNavbar";

import { TargetAccountType, AccountRef } from "src/generated/client";
import { ERROR_MESSAGE_MFA_VERIFICATION } from "src/routes/api/mfa";
import { lastFour } from "src/util/stringUtils";
import { ColorNames } from "src/theme/theme";
import { NavRoutes } from "src/routes/navRoutes";

import {
  Row,
  RowContent,
  RowLabel,
  TransferAccountSelectionContainer,
  TransferActionTitle,
  TransferButtonGroup,
  TransferContentContainer,
  TransferFormInput,
  TransferPage,
  TransferSummary,
} from "./Transfer.styles";

import { Button } from "src/components/button/Button";
import { LargeCurrencyInput } from "src/components/forms/currency-input/LargeCurrencyInput";
import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import { DetailSelectAccount } from "src/components/forms/detail-select/DetailSelectAccount";
import TransferSuccess from "./Success";
import { LoadingSkeleton } from "src/components/loading-skeleton/LoadingSkeleton";
import Layout from "src/components/core-layout/Layout";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? "100%" : "-100%",
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    transition: {
      ease: "backOut",
      duration: 0.35,
    },
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? "100%" : "-100%",
      transition: {
        ease: "backIn",
        duration: 0.3,
      },
    };
  },
};

export const ERROR_MESSAGE_TRANSFER_NEEDS_CONFIRMATION = "NeedsConfirmation";

enum PaymentErrors {
  InsufficientFunds = "Account has insufficient funds",
}

const TransferSend = (): JSX.Element => {
  const { data, state: fetcherState, Form, ...fetcher } = useFetcher();

  useNavbar({ title: "Move Money" });
  const today = format(new Date(), "MMM d, yyyy");
  const { transferFromAccountId } = useParams();

  const didLoad = useRef(false);
  const currentUser = useCurrentUser();
  const transferForm = useRef<HTMLFormElement>(null);
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();
  const [showSuccess, setShowSuccess] = useState(false);
  const [hasVerifiedMfa, setHasVerifiedMfa] = useState(false);
  const moveMoneyCapable = true; // filter for transfer capable accounts

  const { recipients } = useGetRecipients();
  const { accounts: checkingAccounts, refetch } = useGetAccounts(
    [AccountRef.Checking],
    transferFromAccountId
  );
  const { accounts: connectedAccounts } = useGetConnectedAccounts(
    moveMoneyCapable,
    transferFromAccountId
  );

  const [displayAmount, setDisplayAmount] = useState("");
  const [sourceAccountData, setSourceAccountData] = useState<any>(undefined);
  const [targetAccountData, setTargetAccountData] = useState<any>(undefined);
  const [sourceAccountType, setSourceAccountType] = useState<TargetAccountType>(
    TargetAccountType.Account
  );
  const [targetAccountType, setTargetAccountType] = useState<TargetAccountType>(
    TargetAccountType.Account
  );
  const [transferType, setTransferType] = useState<string>("");
  const [recipient, setRecipient] = useState<any>();
  const [sourceAccountBalance, setSourceAccountBalance] = useState(0);

  useEffect(() => {
    if (!sourceAccountData) {
      return;
    }
    setSourceAccountBalance(sourceAccountData.availableBalance.amount);
  }, [sourceAccountData]);

  useEffect(() => {
    if (!checkingAccounts || !connectedAccounts || didLoad.current) return;
    didLoad.current = true;
    setSourceAccountData(
      [...checkingAccounts, ...connectedAccounts].find(
        ({ selected }) => !!selected
      )
    );
  }, [checkingAccounts, connectedAccounts]);

  useEffect(() => {
    if (!!currentUser && !currentUser.verified.mfa) {
      setHasVerifiedMfa(true);
    }
  }, [currentUser]);

  const handleSelectedRecipient = (data: any) => {
    setRecipient(data);
    setTransferType(data.transferType);
    setTargetAccountType(TargetAccountType.Recipient);
  };

  useEffect(() => {
    if (
      !!data?.validationErrors &&
      Object.keys(data.validationErrors).length > 0
    ) {
      const firstError = Object.values(data.validationErrors)[0] as string;
      if (firstError === ERROR_MESSAGE_MFA_VERIFICATION) {
        showBottomSheet("move_money_mfa_sheet");
      } else if (firstError === ERROR_MESSAGE_TRANSFER_NEEDS_CONFIRMATION) {
        showBottomSheet("transfer_send_confirm_sheet");
      } else {
        showNotice(firstError, { error: true });
      }
    }
  }, [data?.validationErrors]);

  useEffect(() => {
    if (!!data?.error) {
      showNotice(data.error.message, { error: true });

      // TODO: Update error message check with enum from core-types when linked to core-web
      if (data.error?.message === PaymentErrors.InsufficientFunds) {
        refetch();
        hideBottomSheet();
      }
    }
  }, [data?.error]);

  useEffect(() => {
    if (data?.response?.session) {
      hideBottomSheet();
      setHasVerifiedMfa(true);

      setTimeout(() => {
        const formData = new FormData(transferForm.current || undefined);
        formData.set("hasVerifiedMfa", "true");
        fetcher.submit(formData, { method: "post" });
      }, 100);
    } else if (data?.response?.createPayment) {
      hideBottomSheet();
      setShowSuccess(true);
    }
  }, [data?.response]);

  const handleVerifyMfa = (token: string) => {
    if (fetcherState === "submitting") return;
    fetcher.submit(
      { token, preventLogout: "true" },
      { method: "post", action: NavRoutes.API_MFA }
    );
  };

  const confirmTransfer = () => {
    if (fetcherState === "submitting") return;

    const formData = new FormData(transferForm.current || undefined);
    formData.set("hasConfirmedTransfer", "true");

    fetcher.submit(formData, { method: "post" });
  };

  const renderDepositAccountInfo = useMemo(() => {
    if (targetAccountType === TargetAccountType.Recipient && !!recipient) {
      return (
        <RowContent>
          {recipient.nickname}
          <small>•••• {lastFour(recipient.accountNumber)}</small>
        </RowContent>
      );
    } else if (targetAccountData?.id) {
      return (
        <RowContent>
          {targetAccountData?.nickname.length > 1
            ? targetAccountData?.nickname
            : "Account"}
          <small>•••• {targetAccountData?.last4}</small>
        </RowContent>
      );
    } else {
      return (
        <RowContent>
          <LoadingSkeleton width={130} height={30} />
        </RowContent>
      );
    }
  }, [targetAccountType, recipient, targetAccountData]);

  return (
    <Layout
      backgroundColor={ColorNames.GRAY1}
      loading={!checkingAccounts || !recipients || !connectedAccounts}
    >
      <AnimatePresence
        initial={false}
        exitBeforeEnter
        custom={showSuccess ? 1 : -1}
      >
        {!showSuccess ? (
          <TransferPage
            key="send"
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            custom={showSuccess ? 1 : -1}
          >
            {!!checkingAccounts && !!recipients && connectedAccounts && (
              <Form method="post" ref={transferForm}>
                <input
                  type="hidden"
                  name="hasVerifiedMfa"
                  value={hasVerifiedMfa.toString()}
                />
                <input type="hidden" name="transferType" value={transferType} />
                <input
                  type="hidden"
                  name="targetAccountType"
                  value={targetAccountType}
                />
                <input
                  type="hidden"
                  name="sourceAccountType"
                  value={sourceAccountType}
                />
                <input
                  type="hidden"
                  name="sourceAccountBalance"
                  value={sourceAccountBalance}
                />
                <>
                  <TransferContentContainer>
                    <TransferFormInput>
                      <div>
                        <TransferActionTitle>
                          How much money do you want to move?
                        </TransferActionTitle>
                        <LargeCurrencyInput
                          name="amount"
                          onChange={setDisplayAmount}
                        />
                      </div>
                    </TransferFormInput>
                    <TransferAccountSelectionContainer>
                      <DetailSelectAccount
                        pickerId="source_account_id"
                        name="sourceAccountId"
                        bankAccounts={checkingAccounts.filter(({ id }) => id !== targetAccountData?.id)}
                        connectedAccounts={connectedAccounts.filter(({ id }) => id !== targetAccountData?.id)}
                        label="Move from Account"
                        placeholder="Choose account..."
                        defaultValue={sourceAccountData?.id}
                        error={false}
                        search
                        didSelectAccount={(options) => {
                          setSourceAccountType(
                            !options.__typename ||
                              options.__typename === "Account"
                              ? TargetAccountType.Account
                              : TargetAccountType.ConnectedAccount
                          );
                          setSourceAccountData(
                            [...checkingAccounts, ...connectedAccounts].find(
                              ({ id }) => id === options.id
                            )
                          );
                        }}
                        shouldHideNonCheckingTabs={
                          targetAccountType !== TargetAccountType.Account
                        }
                      />
                      <DetailSelectAccount
                        pickerId="target_account_id"
                        name="targetAccountId"
                        bankAccounts={checkingAccounts.filter(({ id }) => id !== sourceAccountData?.id)}
                        connectedAccounts={connectedAccounts.filter(({ id }) => id !== sourceAccountData?.id)}
                        recipients={recipients}
                        didSelectRecipient={handleSelectedRecipient}
                        label="Move into Account"
                        placeholder="Choose account..."
                        error={false}
                        search
                        didSelectAccount={(options) => {
                          setTargetAccountType(
                            !options.__typename ||
                              options.__typename === "Account"
                              ? TargetAccountType.Account
                              : TargetAccountType.ConnectedAccount
                          );
                          setTargetAccountData(
                            [...checkingAccounts, ...connectedAccounts].find(
                              ({ id }) => id === options.id
                            )
                          );
                        }}
                        shouldHideNonCheckingTabs={
                          sourceAccountType !== TargetAccountType.Account
                        }
                      />
                    </TransferAccountSelectionContainer>
                    <TransferButtonGroup data-enabled={true}>
                      <Button
                        raised
                        type="submit"
                        color={ColorNames.MIDNIGHT}
                        loading={fetcherState === "submitting"}
                      >
                        Move Money
                      </Button>
                    </TransferButtonGroup>
                  </TransferContentContainer>
                </>
              </Form>
            )}
          </TransferPage>
        ) : (
          <TransferSuccess
            key="success"
            variants={variants}
            custom={showSuccess ? 1 : -1}
            date={today}
            amount={displayAmount}
            sourceAccount={sourceAccountData}
            targetAccount={targetAccountData}
            recipient={recipient}
            buttonLabel="Make Another Transfer"
            onDismiss={() => {
              // refetchAccounts();
              // refetchRecipients();
              transferForm.current?.reset();
              setRecipient(null);
              setShowSuccess(false);
            }}
          />
        )}
      </AnimatePresence>

      <ActionBottomSheet
        id="transfer_send_confirm_sheet"
        title="Transfer Details"
        actionText="Confirm transaction"
        actionColor={ColorNames.LAKE}
        actionLoading={fetcherState === "submitting"}
        onAction={confirmTransfer}
        secondaryActionText="Cancel"
        onSecondaryAction={(): void => hideBottomSheet()}
      >
        <TransferSummary>
          <Row>
            <RowLabel>Funding from</RowLabel>
            {sourceAccountData?.id ? (
              <RowContent>
                {sourceAccountData?.nickname.length > 1
                  ? sourceAccountData?.nickname
                  : "Account"}
                <small>•••• {sourceAccountData?.last4}</small>
              </RowContent>
            ) : (
              <RowContent>
                <LoadingSkeleton width={150} height={30} />
              </RowContent>
            )}
          </Row>
          <Row>
            <RowLabel>Depositing into</RowLabel>
            {renderDepositAccountInfo}
          </Row>
          <Row>
            <RowLabel>Amount</RowLabel>
            <RowContent>${displayAmount}</RowContent>
          </Row>
          <Row>
            <RowLabel>Transfer date</RowLabel>
            <RowContent>{today}</RowContent>
          </Row>
        </TransferSummary>
      </ActionBottomSheet>

      <ActionBottomSheet
        id="move_money_mfa_sheet"
        title="Authenticate Account"
        description="Enter the MFA code generated by your authenticator app."
        actionText="Continue"
        secondaryActionText="Cancel"
        onAction={(code: string) => handleVerifyMfa(code)}
        onSecondaryAction={(): void => hideBottomSheet()}
        actionLoading={fetcherState === "submitting"}
        mfa
      />
    </Layout>
  );
};

export default TransferSend;
