import styled from "styled-components";
import { colorPalette as colors, ColorNames, fonts, dropShadows, ShadowNames, colorPalette } from "src/theme/theme";
import { Container } from "../avatar/Avatar.styles";

type Dropdown = {
  orgCount: number;
};

export const OrgSwitcherContainer = styled.div`
  margin: 0 auto;
  position: relative;

  .tap-target {
    display: none;
    padding: 10px;
    margin: -10px;
  }

  @media (max-width: 767px) {
    .tap-target {
      display: block;
    }
  }
`;

export const OrgSwitcherDropdown = styled.div<Dropdown>`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: var(--extraDeviceTopPadding);
  z-index: var(--zIndex-bottomSheet);
  background: rgba(${colors[ColorNames.WHITE].rgb}, 0.88);
  box-shadow: ${dropShadows[ShadowNames.LIGHT]};
  transition: opacity 0.25s ease, max-height 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.025);
  opacity: 0;
  pointer-events: none;
  max-height: 92px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(12px);
  }

  @media (min-width: 768px) {
    padding-left: var(--sideMenuWidth);
  }

  &.show {
    opacity: 1;
    pointer-events: auto;
    max-height: ${({ orgCount }) => (orgCount < 6 ? `calc(196px + 60px * ${orgCount})` : "60vh")};
  }
`;

export const OrgSwitcherDropdownContent = styled.div`
  position: relative;

  @media (min-width: 768px) {
    width: var(--maxContentWidth);
    max-width: 100%;
    margin: 0 auto;
  }
`;

export const OrgSwitcherHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 6px;
  padding-left: 20px;
  height: var(--navbarHeight);
  color: ${colors[ColorNames.MIDNIGHT].hex};
  ${fonts.PARAGRAPH_M}

  .org-switcher-collapse-icon {
    position: fixed;
    right: 10px;
    -webkit-appearance: none;
    background: none;
    border: none;
    outline: none;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.15s ease transform;
    cursor: pointer;
    &:active {
      transform: scale(0.92);
    }
  }
`;

export const OrgSwitcherActions = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const OrgSwitcherList = styled.div`
  padding-left: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  overflow-y: scroll;
`;

export const OrganizationRow = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 10px 0;
  padding-right: 30px;
  transform-origin: left center;
  transition: 0.15s ease transform;
  user-select: none;

  @media (pointer: fine) {
    &:hover,
    &:focus {
      cursor: pointer;

      .org-name {
        color: ${colors[ColorNames.LAKE].hex};
      }
    }
  }

  &:active {
    transform: scale(0.98);
  }

  &:last-of-type {
    border-bottom: none;
  }

  .org-name {
    ${fonts.PARAGRAPH_S}
    margin-left: 1rem;
    transition: color 0.15s ease;
  }

  &.selected {
    .org-name {
      color: ${colors[ColorNames.LAKE].hex};
    }

    ${Container} {
      /* avatar */
      &::before {
        content: "";
        border-radius: 100%;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        position: absolute;
        top: -2px;
        left: -2px;
        box-shadow: 0 0 0 1.5px ${colors[ColorNames.LAKE].hex};
      }
    }
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: var(--zIndex-overlay);
`;
