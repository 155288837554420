import React from "react";
import privacyPolicy from "src/data/privacy_policy";
import { TermsPage, Logo } from "src/pages/terms/Terms.styles";

const PrivacyPolicy = (): JSX.Element => {
  return (
    <TermsPage>
      <Logo />
      {privacyPolicy}
    </TermsPage>
  );
};

export default PrivacyPolicy;
