import React from "react";
import { TermsPage, Logo } from "src/pages/terms/Terms.styles";

const Terms = () => (
  <TermsPage>
    <Logo />
    <h1>Terms and Agreements</h1>
    <p>Below you can find a list of our terms and service agreements.</p>
    <hr />
    <ul>
      <li>
        <a href="/terms/account-terms">Account Terms</a>
      </li>
      <li>
        <a href="/terms/banking-business-deposit-agreement">Business Banking Deposit Agreement</a>
      </li>
      <li>
        <a href="/terms/banking-personal-deposit-agreement">Personal Banking Deposit Agreement</a>
      </li>
      <li>
        <a href="/terms/electronic-disclosure">Electronic Disclosure and Consent</a>
      </li>
    </ul>
  </TermsPage>
);

export default Terms;
