import { ActionFunction, LoaderFunction } from "react-router-dom";
import { addOwner, removeOwner, AddOrRemoveOwnerVariables, getAccountMembers } from "src/services/accounts";
import { RouteActionResponse } from "src";

export const ownerLoader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const accountId = url.searchParams.get("accountId");
  const userId = url.searchParams.get("userId");

  try {
    if (userId && accountId) {
      const members = await getAccountMembers(accountId, userId);
      return members.find(({ selected }) => selected);
    }
  } catch (e) {
    throw e;
  }
};

export const ownersAction: ActionFunction = async ({ request }) => {
  const { action, ...rest } = Object.fromEntries(await request.formData());
  const data = rest as AddOrRemoveOwnerVariables;

  try {
    const success = action === 'addOwner' ? await addOwner(data) : await removeOwner(data);

    return { response: { success } } as RouteActionResponse<{success: boolean}>
  } catch (error) {
    const response: RouteActionResponse<unknown> = {};
    response.error = error;

    return response;
  }
};

