export enum NavRoutes {
  LETTER_ROOT = "root",

  PUBLIC_ROOT = "public",
  APPROVAL_REQUEST = "/approval-request",
  DISCLOSURES = "/disclosures",
  FORGOT_PASSWORD = "/forgot-password",
  AUCTION_SALES = "/auction-sales/:auctionHouse",
  LANDING = "/",
  PRIVACY_POLICY = "/privacy",
  REISSUE_TOKEN = "/reissue-token",
  REQUEST_MORE_REFERRALS = "/request-more-referrals",
  RESET_PASSWORD = "/password-reset",
  SECURITY = "/security",
  SIGN_IN = "/sign-in",
  SIGN_UP = "/sign-up",
  SURVEY_COMPLETE = "/survey-complete",
  TERMS = "/terms",
  ACCOUNT_TERMS = "/terms/account-terms",
  BUSINESS_BANKING_DEPOSIT_AGREEMENT = "/terms/banking-business-deposit-agreement",
  PERSONAL_BANKING_DEPOSIT_AGREEMENT = "/terms/banking-personal-deposit-agreement",
  ELECTRONIC_DISCLOSURE = "/terms/electronic-disclosure",
  PUBLIC_ACTIVATE = "/activate",

  // redirects
  REDIRECTS = "/redirects",
  REDIRECTS_DOCUSIGN_COMPLETE = "/redirects/docusign-complete",

  //teton ridge
  TETON_RIDGE_AUCTIONS = "/teton-ridge-auctions",
  TETON_RIDGE_AUCTION_EARLY = "/teton-ridge-auctions/early",
  TETON_RIDGE_AUCTION_LATE = "/teton-ridge-auctions/late",

  //api routes
  API_CARD = "/api/card",
  API_MFA = "/api/mfa",
  API_OWNER = "/api/owner",
  API_ACCOUNT = "/api/account",
  API_CONNECTED_ACCOUNT = "/api/connected-account",
  API_DEAL = "/api/deal",
  API_ORGANIZATION = "/api/organization",
  API_LINE = "/api/line",
  API_ONELINE_AUCTION = "/api/oneline-auction",
  // app routes
  APP_ROOT = "/a",
  APP_GENERIC_ROOT = "/a",
  SETTINGS = "/a/settings",
  USER_ACCOUNT_SETTINGS = "/a/settings/account",
  USER_ACCOUNT_SETTINGS_SECTION = "/a/settings/account/:section",
  EDIT_ORGANIZATION = "/a/settings/organization",
  MEMBERS = "/a/settings/members",
  MEMBERS_DETAIL = "/a/settings/members/:memberId",
  MEMBERS_CARDS = "/a/settings/members/:memberId/cards",
  ONBOARD = "/a/onboard",
  HELP = "/a/help",

  // app bank routes
  BANK_ROOT = "/a/bank",
  BANK_ACTIVATE = "/a/bank/activate",
  BANK_ACCOUNTS = "/a/bank/accounts",
  BANK_ACCOUNT_DETAIL = "/a/bank/accounts/:accountId/:section",
  BANK_ACCOUNT_DETAIL_MEMBER = "/a/bank/accounts/:accountId/members/:memberId",
  BANK_ACCOUNT_CARD_DETAIL = "/a/bank/accounts/:accountId/cards/:cardId",
  BANK_ACCOUNT_CARD_SETTINGS = "/a/bank/accounts/:accountId/cards/:cardId/settings",
  BANK_ACCOUNT_CREATE_CARD = "/a/bank/accounts/:accountId/cards/new",
  BANK_CONNECTED_ACCOUNTS = "/a/bank/connected-accounts",
  BANK_CONNECTED_ACCOUNTS_DETAIL = "/a/bank/connected-accounts/:accountId",
  BANK_CONNECTED_ACCOUNTS_EMPTY = "/a/bank/onboarding-connected-accounts",
  BANK_DASHBOARD = "/a/bank/dashboard",
  BANK_DEPOSIT_CHECKS = "/a/bank/checks/deposit",
  BANK_SEND_MONEY = "/a/bank/send-money",
  BANK_TRANSACTIONS = "/a/bank/transactions",
  BANK_TRANSFER_SEND = "/a/bank/transfer/send",
  BANK_TRANSFER_SEND_SOURCED = "/a/bank/transfer/send/:transferFromAccountId",
  BANK_TRANSFER_SEND_REQUEST = "/a/bank/transfer/send-request",
  BANK_TRANSFER_HANDLE_REQUEST = "/a/bank/transfer/handle-request",
  BANK_RECIPIENTS = "/a/bank/recipients",
  BANK_RECIPIENTS_NEW = "/a/bank/recipients/new",
  BANK_RECIPIENTS_DETAIL = "/a/bank/recipients/:recipientId",

  // app oneline routes
  ONELINE_ROOT = "/a/oneline",
  ONELINE_DASHBOARD = "/a/oneline/dashboard",
  ONELINE_QUALIFY = "/a/oneline/qualify",
  ONELINE_BORROW = "/a/oneline/borrow",
  ONELINE_LINES = "/a/oneline/lines",
  ONELINE_LINE_DETAIL = "/a/oneline/lines/:lineId/:section",
  ONELINE_CREATE_LINE = "/a/oneline/lines/create/:lineTypeId",
  ONELINE_EDIT_LINE = "/a/oneline/lines/edit/:lineId",
  ONELINE_FINALIZE_LINE = "/a/oneline/lines/finalize/:lineId",

  // app marketplace routes
  MARKETPLACE_ROOT = "/a/marketplace",
  MARKETPLACE_LISTINGS = "/a/marketplace/browse",
  MARKETPLACE_LISTING_DETAIL = "/a/marketplace/browse/:listingId/:section",
  MARKETPLACE_LISTING_CATEGORY = "/a/marketplace/browse/:categoryId",
  MARKETPLACE_MY_DEALS = "/a/marketplace/my-deals"
}
