import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { ReactComponent as RightArrow } from "src/assets/icons/arrow_right.svg";
import { colorPalette as colors, rgbGradientColors, ColorNames, borderRadius } from "src/theme/theme";

export const StyledNextModal = styled.div<{
  disabled?: boolean;
  onClick?: () => unknown | void;
}>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 100px;
  background: ${colors[ColorNames.WHITE].hex};
  box-shadow: 0px 5px 25px rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0.05),
    0px 35px 60px rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0.14);
  border-radius: ${borderRadius};
  transform: scale3d(1, 1, 1);
  transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
  will-change: transform, box-shadow;

  ${({ disabled }): FlattenSimpleInterpolation | undefined => {
    if (disabled) {
      return css`
        pointer-events: none;
      `;
    }
  }}

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale3d(0.96, 0.96, 0.96);
    box-shadow: 0px 2px 10px rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0.04),
      0px 20px 40px rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0.14);
  }

  &:hover svg {
    transform: translateX(4px);
  }
`;

export const NextModalTextContainer = styled.div`
  width: calc(100% - 90px);
  padding: 24px 0 24px 30px;
`;

export const NextModalSmallTextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% - 90px);
  padding: 24px 0 24px 30px;

  > * {
    width: 100%;
    margin-top: 0;
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66px;
  border-radius: 0 ${borderRadius} ${borderRadius} 0;
  background-color: ${colors[ColorNames.MIDNIGHT].hex};
  background-image: linear-gradient(
    rgb(${rgbGradientColors[ColorNames.MIDNIGHT][0]}),
    rgb(${rgbGradientColors[ColorNames.MIDNIGHT][1]})
  );
`;

export const Arrow = styled(RightArrow)`
  width: 22px;
  height: 18px;
  transform: translateX(0);
  transition: transform 0.2s ease-out;
  will-change: transform;

  path {
    vector-effect: non-scaling-stroke;
    stroke: ${colors[ColorNames.WHITE].hex};
  }
`;
