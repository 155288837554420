import React, { useState, useMemo, useEffect } from "react";
import { Formik, FormikValues } from "formik";
import { useCurrentUser } from "src/services/users";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { showNotice } from "src/store/alertState";
import { formatPercentage } from "src/util/stringUtils";
import { BeneficialOwner } from "src/generated/client";
import { useOrganizationOwners } from "src/services/organizations";
import AddBusinessOwner from "src/pages/auth/onboarding/business-info/AddBusinessOwner";

import { ActionableButtonGroup } from "src/components/actionable-button/ActionableButton.styles";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { BottomSheet } from "src/components/bottom-sheets/BottomSheet";
import { Button } from "src/components/button/Button";
import { Select } from "src/components/forms/select/FormikSelect";
import { Form } from "src/components/forms/Form";
import { StyledFormInputContainer } from "src/components/forms";
import { ListButton } from "src/components/lists/ListButton";
import { Avatar } from "src/components/avatar/Avatar";
import { LoadingSkeleton } from "src/components/loading-skeleton/LoadingSkeleton";

import { ColorNames } from "src/theme/theme";
import { AuthContainer } from "src/pages/auth/Auth";
import { AuthTitle } from "src/pages/auth/Auth.styles";
import {
  BusinessOwnersList,
  BusinessTermsCopy,
  AdditionalBusinessOwnersRows,
  BusinessOwnersFormSection,
} from "src/pages/auth/onboarding/business-info/BusinessOwners.styles";


const TrustCertification = ({
  nextStep,
  stepDirection,
}: {
  nextStep: (
    owners: BeneficialOwner[],
    setSubmitting: (isSubmitting: boolean) => void
  ) => void;
  stepDirection: number;
}): JSX.Element => {
  const currentUser = useCurrentUser();
  const [owners, setOwners] = useState<BeneficialOwner[]>([]);
  const { owners: ownersListData, loading: ownersLoading } =
    useOrganizationOwners();
  const [editOwner, setEditOwner] = useState<BeneficialOwner | null>(null);
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();

  useEffect(() => {
    if (ownersListData) setOwners(ownersListData);
  }, [ownersListData, setOwners]);

  const showAddSelfOwnerSheet = () => {
    const owner: BeneficialOwner = {
      firstName: currentUser!.firstName,
      lastName: currentUser!.lastName,
      email: currentUser!.email,
      phone: currentUser!.phone,
      dob: currentUser!.DOB,
      ssn: currentUser!.SSN,
      streetAddress: currentUser!.streetAddress,
      city: currentUser!.city,
      state: currentUser!.state,
      zip: currentUser!.zip,
      primaryOfficer: false,
      primaryContact: false,
      ownershipPercentage: "0",
      title: "",
      id: currentUser!.id,
    };

    showAddOwnerSheet(owner);
  };

  const showAddOwnerSheet = (owner?: BeneficialOwner) => {
    setEditOwner(owner || null);
    setTimeout(() => showBottomSheet("add_owner_sheet"), 0);
  };

  const handleDeleteOwner = (owner: BeneficialOwner) => {
    hideBottomSheet("add_owner_sheet");
    setOwners(owners.filter((o) => o.email !== owner.email));
  };

  const handleSaveOwner = (owner: BeneficialOwner) => {
    let newOwners = [...owners];

    hideBottomSheet("add_owner_sheet");
    if (newOwners.some((o) => o.email === owner.email)) {
      newOwners = newOwners.map((o) => (o.email === owner.email ? owner : o));
    } else {
      newOwners.push(owner);
    }
    setOwners(newOwners);
  };

  const handleSubmit = async (
    values: FormikValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(false);
    const totalOwnerships = owners.reduce(
      (total, current) =>
        total + parseInt(current.ownershipPercentage.replace(/[^\d]/g, "")),
      0
    );

    if (totalOwnerships > 100) {
      showNotice("Your combined ownership percentages cannot exceed 100%", {
        error: true,
      });
      return;
    }

    const officerIndex = owners.findIndex(
      (o) => o.email === values.primaryOfficer
    );
    const contactIndex = owners.findIndex(
      (o) => o.email === values.primaryContact
    );

    if (officerIndex < 0) {
      showNotice("You must select a primary officer.", { error: true });
      return;
    }
    if (contactIndex < 0) {
      showNotice("You must select a primary business contact.", {
        error: true,
      });
      return;
    }

    const ownerCopy = [...owners];

    ownerCopy[officerIndex] = {
      ...ownerCopy[officerIndex],
      primaryOfficer: true,
    };
    ownerCopy[contactIndex] = {
      ...ownerCopy[contactIndex],
      primaryContact: true,
    };

    nextStep(ownerCopy, setSubmitting);
  };

  const officers = useMemo(() => {
    return owners.map((owner) => {
      return {
        label: `${owner.firstName} ${owner.lastName}`,
        value: owner.email,
      };
    });
  }, [owners]);

  const initialFormValues = useMemo(() => {
    const firstOwner = owners.length > 0 ? owners[0] : currentUser;
    const contact =
      owners.find((o) => o.primaryContact === true)?.email ?? firstOwner?.email;
    const officer =
      owners.find((o) => o.primaryOfficer === true)?.email ?? firstOwner?.email;

    return {
      primaryContact: contact,
      primaryOfficer: officer,
    };
  }, [currentUser, owners]);

  return (
    <AuthContainer stepDirection={stepDirection} noPadding>
      <BusinessOwnersList>
        <AuthTitle>Owners & Officers</AuthTitle>

        <ActionableButtonGroup fullWidth>
          {ownersLoading ? (
            <LoadingSkeleton width={"auto"} />
          ) : (
            !owners.some((o) => o.email === currentUser?.email) && (
              <ActionableButton
                label="Add Yourself as an Officer"
                onClick={() => showAddSelfOwnerSheet()}
                iconRight={false}
                color={ColorNames.PINE}
              />
            )
          )}
          {ownersLoading ? (
            <LoadingSkeleton width={"auto"} />
          ) : (
            <ActionableButton
              label="Add Another Officer"
              onClick={() => showAddOwnerSheet()}
              iconRight={false}
              color={ColorNames.TEA}
            />
          )}
        </ActionableButtonGroup>
        <BusinessTermsCopy>
          Add anyone with at least 25% ownership, or at least one officer.
        </BusinessTermsCopy>
      </BusinessOwnersList>

      <>
        {ownersLoading && <LoadingSkeleton width={"auto"} />}

        {owners.length > 0 && (
          <>
            <AdditionalBusinessOwnersRows>
              {owners.map((owner) => {
                return (
                  <ListButton
                    key={owner.email}
                    label={`${owner.firstName} ${owner.lastName}`}
                    sublabel={`${owner.title.toLocaleUpperCase()}, ${formatPercentage(
                      owner.ownershipPercentage
                    )}`}
                    onClick={() => {
                      showAddOwnerSheet(owner);
                    }}
                    avatar={
                      <Avatar
                        name={`${owner.firstName} ${owner.lastName}`}
                        size={40}
                      />
                    }
                  />
                );
              })}
            </AdditionalBusinessOwnersRows>

            <BusinessOwnersFormSection>
              <Formik
                initialValues={initialFormValues}
                enableReinitialize={true}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, { setSubmitting }): void => {
                  handleSubmit(values, setSubmitting);
                }}
              >
                {({ isSubmitting }): JSX.Element => (
                  <Form>
                    <>
                      <StyledFormInputContainer>
                        <Select
                          name="primaryContact"
                          label="Primary Business Contact"
                          options={officers}
                        />
                        <Select
                          name="primaryOfficer"
                          label="Primary Officer"
                          options={officers}
                        />
                      </StyledFormInputContainer>

                      <Button
                        type="submit"
                        raised
                        children="Continue"
                        loading={isSubmitting}
                      />
                    </>
                  </Form>
                )}
              </Formik>
            </BusinessOwnersFormSection>
          </>
        )}
      </>

      <BottomSheet fullHeight noPadding id="add_owner_sheet">
        <AddBusinessOwner
          handleSaveOwner={handleSaveOwner}
          handleDeleteOwner={handleDeleteOwner}
          owner={editOwner}
          dismiss={() => hideBottomSheet("add_owner_sheet")}
        />
      </BottomSheet>
    </AuthContainer>
  );
};

export default TrustCertification;
