import styled from "styled-components";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

export const OnfidoContainer = styled.div`
  #onfido-mount {
    height: 600px;
    ${fonts.PARAGRAPH_M}

    .onfido-sdk-ui-Modal-inner {
      border: none;
      position: relative;
      background: white;
    }
  }
`;
