import styled from "styled-components";

import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";
import { NumberText } from "src/components/text";

export const AccountBalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BalanceTitle = styled.p<{
  color: ColorNames.MIDNIGHT | ColorNames.WHITE;
}>`
  ${fonts.PARAGRAPH_S}
  color: ${({ color }): string => `rgb(${colors[color].rgb}, 0.6)`};
`;

export const BalanceText = styled(NumberText)<{
  color: ColorNames.MIDNIGHT | ColorNames.WHITE;
}>`
  color: ${({ color }): string => colors[color].hex};
  ${fonts.NUMBER_L}
  display: flex;

  span {
    ${fonts.NUMBER_S}
    padding-top: 2px;
  }
`;
