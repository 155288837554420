import { atom, atomFamily, selector, SerializableParam } from "recoil";
import { ThemeColor } from "src/theme/theme";

export interface IBottomSheets {
  id: string;
  children: JSX.Element | string | null;
  inline?: boolean;
  fullHeight?: boolean;
  noPadding?: boolean;
  hasHeader?: boolean;
  closeOnOutsideClick?: boolean;
  backgroundColor?: ThemeColor;
  onClose?: () => void;
}

export interface IBottomSheetState {
  showIds: string[];
}

export const bottomSheets = atomFamily<IBottomSheets, SerializableParam>({
  key: "bottomSheets",
  default: {
    id: "",
    children: null,
    inline: false,
    fullHeight: false,
    noPadding: false,
    hasHeader: false,
    closeOnOutsideClick: true,
    onClose: undefined
  }
});

export const bottomSheetState = atom<IBottomSheetState>({
  key: "bottomSheetState",
  default: {
    showIds: []
  }
});

export const bottomSheetSelector = selector({
  key: "bottomSheetSelector",
  get: ({ get }) => {
    const sheetState = get(bottomSheetState);
    return sheetState.showIds.map((sheetId) => get(bottomSheets(sheetId)));
  },

  set: ({ set, get }, newValue: any) => {
    const sheetState = get(bottomSheetState);

    if (newValue.hide) {
      if (newValue.sheetId) {
        set(bottomSheetState, {
          showIds: sheetState.showIds.filter((sheetId) => sheetId !== newValue.sheetId)
        });
      } else {
        set(bottomSheetState, { showIds: [] });
      }
    }

    if (!newValue.hide && !sheetState.showIds.includes(newValue.sheetId)) {
      set(bottomSheetState, {
        showIds: [...sheetState.showIds, newValue.sheetId]
      });
    }
  }
});
