import { navigationState, NavigationDirection } from "src/store/navigationState";

const useNavigate = (): ((
  to: string,
  options?:
    | {
        direction: NavigationDirection;
        force?: boolean;
      }
    | undefined
) => void) => {
  const navigate = (to: string, options?: { direction: NavigationDirection; force?: boolean }) => {
    const currentState = navigationState();
    if (currentState.transitioning && !options?.force) return;

    navigationState({
      to,
      direction: to === "back" ? NavigationDirection.BACKWARD : options?.direction || NavigationDirection.FORWARD,
      transitioning: true
    });
  };

  return navigate;
};

export default useNavigate;
