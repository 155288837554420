import React, { useEffect, useState, useMemo } from "react";
import { useRecoilValue } from "recoil";

import { useCurrentUser } from "src/services/users";
import { showNotice } from "src/store/alertState";
import { useGetCardSession, useCompleteCardSession } from "src/services/cards";
import { useSetBottomSheet, useShowBottomSheet } from "src/util/useBottomSheet";
import {
  bottomSheetState,
  IBottomSheetState,
} from "src/store/bottomSheetState";
import { formatPhoneNumber } from "src/util/stringUtils";

import { LoadingContainer } from "src/components/loading-container/LoadingContainer";
import { CodeInput } from "src/components/forms/code-input/CodeInput";

import {
  CardTokenSheetHolder,
  CardTokenSheetTitle,
  CardTokenSheetInstructions,
} from "./CardTokenBottomSheet.styles";
import { Card } from "src/generated/client";

export interface CardTokenBottomSheetProps {
  id: string;
  title: string;
  card: Card | undefined;
  cardToken?: string;
  cardExternalCardId?: string;
  children: ({
    token,
    externalCardId,
  }: {
    token: string | undefined;
    externalCardId: string | undefined;
  }) => JSX.Element | string;
  closeOnOutsideClick?: boolean;
}

export const CardTokenBottomSheet = ({
  id,
  title,
  card,
  cardToken,
  cardExternalCardId,
  closeOnOutsideClick = true,
  children,
}: CardTokenBottomSheetProps): JSX.Element => {
  const [isShowing, setIsShowing] = useState(false);
  const currentUser = useCurrentUser();
  const {
    getCardSession,
    loading: loadingCardSession,
    error: errorCardSession,
  } = useGetCardSession();
  const {
    completeCardSession,
    loading: loadingCompleteCardSession,
    error: errorCompleteCardSession,
  } = useCompleteCardSession();
  const [token, setToken] = useState<string | undefined>(cardToken);
  const [externalCardId, setExternalCardId] = useState<string | undefined>(
    cardExternalCardId
  );
  const bottomSheets = useRecoilValue<IBottomSheetState>(bottomSheetState);
  const setBottomSheet = useSetBottomSheet();
  const { hideBottomSheet } = useShowBottomSheet();

  useEffect(() => {
    if (!!errorCardSession && !token) {
      showNotice("There was a problem fetching card details.", { error: true });
      hideBottomSheet();
    }
  }, [errorCardSession, token]);

  useEffect(() => {
    if (errorCompleteCardSession) {
      showNotice(
        "There was a problem verifying your identity. Please double-check the code you entered.",
        {
          error: true,
        }
      );
    }
  }, [errorCompleteCardSession]);

  useEffect(() => {
    setIsShowing(!!bottomSheets.showIds.includes(id));
  }, [bottomSheets, id]);

  useEffect(() => {
    if (isShowing && !token) {
      getCardSession();
    }
  }, [isShowing, token, getCardSession]);

  const bottomSheetChildren = useMemo(() => {
    const submitCode = async (code: string) => {
      if (loadingCompleteCardSession) return;
      const completeSession = await completeCardSession({
        code,
        cardId: card?.id || "",
      });
      if (!!completeSession) {
        setToken(completeSession.token);
        setExternalCardId(completeSession.externalCardId);
      }
    };

    return (
      <CardTokenSheetHolder>
        <CardTokenSheetTitle>{title}</CardTokenSheetTitle>

        {loadingCardSession ? (
          <LoadingContainer height="320px" />
        ) : !token || !externalCardId ? (
          <>
            <CardTokenSheetInstructions>
              We've sent a verification code to your phone number
              {currentUser?.phone
                ? ` ${formatPhoneNumber(currentUser.phone)}`
                : ""}
              . Enter that code below.
            </CardTokenSheetInstructions>
            <CodeInput
              autoSubmit={true}
              onSubmit={submitCode}
              actionText="Verify"
              secondaryActionText="Cancel"
              onSecondaryAction={() => hideBottomSheet()}
              mfa={true}
            />
          </>
        ) : (
          children({ token, externalCardId })
        )}
      </CardTokenSheetHolder>
    );
  }, [
    loadingCardSession,
    currentUser,
    token,
    externalCardId,
    children,
    hideBottomSheet,
    title,
    card,
    loadingCompleteCardSession,
    completeCardSession,
  ]);

  useEffect(() => {
    setBottomSheet({ id, closeOnOutsideClick, children: bottomSheetChildren });
  }, [bottomSheetChildren, id, setBottomSheet, closeOnOutsideClick]);

  return <></>;
};
