import styled from "styled-components/macro";
import {
  colorPalette as colors,
  ThemeColor,
  ColorNames,
  borderRadiusOuter,
  fonts,
  borderRadius
} from "src/theme/theme";
import { motion } from "framer-motion";

export const DetailPickerWrapper = styled.div`
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-height: 100vh;
  margin: 0 auto;
  max-width: var(--maxContentWidth);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--zIndex-bottomSheet);

  @media (min-width: 768px) {
    left: var(--sideMenuWidth);
  }
`;

type IDetailPickerContainer = {
  basic?: boolean;
  color: ThemeColor;
  hasValue: boolean;
};

export const DetailPickerContainer = styled(motion.div)<IDetailPickerContainer>`
  display: flex;
  flex-direction: column;
  pointer-events: none;
  width: 100%;
  max-width: calc(var(--maxContentWidth) - 60px);
  margin: 30px;
  border-radius: ${borderRadiusOuter};
  background-color: ${colors[ColorNames.WHITE].hex};
  -webkit-user-select: none;
  user-select: none;
  z-index: 999;
  visibility: hidden;
  overflow: hidden;
  transition: ${({ basic }) => (basic ? "visibility 0.15s ease" : "visibility 0.4s ease, clip-path 0.35s ease-in-out")};

  &.searching {
    // min-height: 320px;
  }
`;

export const DetailPickerHeader = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  min-height: 60px;

  &[data-override-origin="true"] {
    transform: translateY(0) !important;
  }
`;

export const DetailPickerTabs = styled(motion.div)`
  position: relative;
  box-shadow: 0px 1px 3px rgba(25, 27, 31, 0.05), 0px 2px 8px rgba(25, 27, 31, 0.04),
    0px 3px 26px rgba(25, 27, 31, 0.04);

  &::after {
    content: "";
    width: 100%;
    height: 16px;
    position: absolute;
    top: -12px;
    left: 0%;
    background: ${colors[ColorNames.WHITE].hex};
  }
`;

export const DetailPickerTabsRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export const PickerTab = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  padding: 10px 12px;
  transition: 0.25s ease all;
  color: ${colors[ColorNames.GRAY5].hex};
  ${fonts.PARAGRAPH_S}
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  &.active {
    color: ${colors[ColorNames.MIDNIGHT].hex};
  }

  &:active {
    transform: scale(0.96);
  }
`;

export const DetailPickerTabHighlight = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: ${colors[ColorNames.MIDNIGHT].hex};
  transition: 0.25s ease all;

  &.tab0 {
    transform: translateX(0);
  }

  &.tab1 {
    transform: translateX(100%);
  }

  &.tab2 {
    transform: translateX(200%);
  }
`;

export const DetailPickerSearchContainer = styled(motion.div)`
  width: 100%;
  position: absolute;
  left: 20px;
  top: 28px;
  z-index: 5;
  transform-origin: left center;

  svg {
    /* bigger tap target */
    width: 40px;
    height: 40px;
    padding: 10px;
    left: -10px;
    top: -10px;
    position: absolute;
    cursor: pointer;
  }
`;

export const DetailPickerSearchInput = styled.input`
  background-color: ${colors[ColorNames.GRAY1].hex};
  border-radius: ${borderRadius};
  border: none;
  box-shadow: none;
  padding: 4px 10px;
  ${fonts.PARAGRAPH_S}
  position: absolute;
  top: -6px;
  left: 32px;
  width: calc(100% - 64px - 32px);
  height: 32px;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-10px);
  transition: 0.15s ease all, 0.15s cubic-bezier(0.175, 0.885, 0.32, 1.275) transform;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${colors[ColorNames.LAKE].hex};
  }

  &.show {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
`;

export const DetailPickerLabel = styled(motion.div)`
  font-weight: 500;
  text-align: center;
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 52px;
  white-space: nowrap;
  z-index: 4;
  min-width: 10px;
  transform: scale(1);
  transform-origin: left center;
`;

export const IconCollapse = styled.div<{ onClick?: any }>`
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  transform: scale(1);
  height: 44px;
  width: 52px;
  position: absolute;
  right: 0;
  top: 16px;
  transition: transform 0.15s ease, opacity 0.2s ease;
  z-index: 6;

  &:active {
    transform: scale(0.92);
    opacity: 0.5;
  }
`;

export const DetailPickerContent = styled(motion.div)`
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  pointer-events: none;
`;

export const DetailPickerActions = styled.div`
  padding: 20px 20px 0;
`;

export const DetailPickerScrollable = styled.div<{ tall?: boolean }>`
  padding: 10px 0 10px 20px;
`;

export const DetailPickerOptionButton = styled.div`
  padding: 15px 15px 15px 10px;
  display: grid;
  grid-template-columns: 1fr 44px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors[ColorNames.GRAY2].hex};
  font-weight: 500;
  position: relative;
  transition: 0.15s ease transform;

  @media (pointer: fine) {
    &:hover {
      cursor: pointer;
      color: ${colors[ColorNames.LAKE].hex};
    }
  }

  &:active {
    transform: scale(0.98);
  }

  &:last-of-type {
    border-bottom: 0;
  }

  .secondary-label {
    ${fonts.NUMBER_S}
    color: ${colors[ColorNames.GRAY5].hex};
    margin-top: 4px;
    word-break: break-word;
  }

  .detail-picker-option-button-with-avatar {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const DetailPickerImageOptionsHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 30px;
  justify-content: center;
  padding: 10px 20px 20px 0;
`;

export const DetailPickerImageOption = styled.div`
  ${fonts.PARAGRAPH_S}
  text-align: center;
  cursor: pointer;
`;

export const DetailPickerImageOptionThumbnail = styled.div<{
  background: string;
  selected: boolean;
}>`
  position: relative;
  padding-top: 160%;
  border-radius: ${borderRadius};
  border: 1.5px solid ${({ selected }): string => (selected ? colors[ColorNames.LAKE].hex : "transparent")};
  background-color: ${colors[ColorNames.GRAY1].hex};
  background-image: ${({ background }): string => `url(${background})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: border-box;
  margin-bottom: 2px;
  transition: 0.15s ease border-color;

  &:hover {
    border-color: rgba(${colors[ColorNames.LAKE].rgb}, 0.4);
  }
`;
