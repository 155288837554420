import { atom } from "recoil";

export const showMenuState = atom({
  key: "showMenu",
  default: false
});

export const selectedMenuGroup = atom({
  key: "selectedMenuGroup",
  default: "bank"
});
