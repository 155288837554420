import React from "react";
import { useReactiveVar } from "@apollo/client";

import useAlertChecks from "src/util/useAlertChecks";
import { alertVar } from "src/store/alertState";

import { Alert } from "src/components/alert/Alert";

export const AlertController = () => {
  const children = useAlertChecks();
  const alerts = useReactiveVar(alertVar);

  return (
    <>
      {Object.keys(alerts).map((key) => {
        const alert = alerts[key];
        return (
          <Alert
            key={key}
            description={alert.description}
            actionText={alert.actionText}
            loading={alert.loading}
            onAction={alert.onAction}
          />
        );
      })}

      {children}
    </>
  );
};
