import { DateRange, NumberRange, PercentageRange } from "src/generated/client";

interface CentsToStringOptions {
  showSign?: boolean;
  currencySymbol?: CurrencySymbols;
}

export enum CurrencySymbols {
  USD = "$",
}

export const zipCodeFormatRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const convertStringNumberToCents = (stringNumber: string): number => {
  const onlyNums = stringNumber.replace(/[^0-9-]/g, "");
  return +onlyNums;
};

export const formatCentsToString = (
  centNum: number | string,
  options: CentsToStringOptions = {}
) => {
  let centString = centNum === 0 ? "000" : centNum.toString();
  const isNegative = centString.startsWith("-");

  if (isNegative) {
    centString = centString.slice(1);
  }

  const dollars = centString.slice(0, -2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const cents = centString.slice(-2);
  const sign = !!options?.showSign
    ? centNum >= 0
      ? "+"
      : "-"
    : isNegative
    ? "-"
    : "";
  const symbol = !!options?.currencySymbol ? options.currencySymbol : "";

  if (centString === "0") {
    return `${symbol}0`;
  }

  return `${sign}${symbol}${dollars || "0"}.${cents}`;
};

export const decorateCurrencyValue = (value: string | undefined): string => {
  if (value && value.charAt(0) === "$") return value;
  return typeof value === "undefined"
    ? "--.--"
    : ["+", "-"].includes(value.charAt(0))
    ? `${value.charAt(0)}$${value.slice(1)}`
    : `$${value}`;
};

export const formatFloatToString = (
  floatNum: number,
  options: CentsToStringOptions = {}
): string => {
  let floatString: string = floatNum.toFixed(2);
  if (floatString.startsWith("-")) {
    floatString = floatString.slice(1);
  }
  const sign: string = !!options?.showSign ? (floatNum >= 0 ? "+" : "-") : "";
  const symbol: string = !!options?.currencySymbol
    ? options.currencySymbol
    : "";

  return `${sign}${symbol}${floatString}`;
};

//NOTE: May be deprecated
export const formatDOB = (number: string, previousNumber = ""): string => {
  if (!number) return number;
  const currentNumber = number.replace(/[^\d]/g, "");

  if (!previousNumber || number.length >= previousNumber.length) {
    if (currentNumber.length < 3) {
      if (
        +currentNumber[0] > 1 ||
        +currentNumber.slice(0, 2) > 12 ||
        (+currentNumber[0] === 0 && +currentNumber[1] === 0)
      )
        return previousNumber;
      return currentNumber;
    }
    if (currentNumber.length < 5) {
      if (
        +currentNumber[2] > 3 ||
        +currentNumber.slice(2) > 31 ||
        (+currentNumber[2] === 0 && +currentNumber[3] === 0)
      )
        return previousNumber;
      return `${currentNumber.slice(0, 2)}-${currentNumber.slice(2)}`;
    }
    if (+currentNumber[4] === 0 || +currentNumber[4] > 2) return previousNumber;
    return `${currentNumber.slice(0, 2)}-${currentNumber.slice(
      2,
      4
    )}-${currentNumber.slice(4, 8)}`;
  }

  return number;
};

export const formatEIN = (number: string, previousNumber?: string): string => {
  if (!number) return number;
  const currentNumber = number.replace(/[^\d]/g, "");

  if (!previousNumber || number.length > previousNumber.length) {
    if (currentNumber.length < 3) return currentNumber;
    return `${currentNumber.slice(0, 2)}-${currentNumber.slice(2, 9)}`;
  }

  return number;
};

export const formatPhoneNumber = (
  number: string,
  previousNumber?: string
): string => {
  if (!number) return number;
  const currentNumber = number.replace(/[^\d]/g, "");

  if (!previousNumber || number.length > previousNumber.length) {
    if (currentNumber.length < 4) return currentNumber;
    if (currentNumber.length < 7)
      return `(${currentNumber.slice(0, 3)}) ${currentNumber.slice(3)}`;
    return `(${currentNumber.slice(0, 3)}) ${currentNumber.slice(
      3,
      6
    )}-${currentNumber.slice(6, 10)}`;
  }

  return number;
};

export const formatSSN = (number: string, previousNumber?: string): string => {
  if (!number) return number;
  const currentNumber = number.replace(/[^\d]/g, "");

  if (!previousNumber || number.length > previousNumber.length) {
    if (currentNumber.length < 4) return currentNumber;
    if (currentNumber.length < 6)
      return `${currentNumber.slice(0, 3)}-${currentNumber.slice(3)}`;
    return `${currentNumber.slice(0, 3)}-${currentNumber.slice(
      3,
      5
    )}-${currentNumber.slice(5, 9)}`;
  }

  return number;
};

export const formatPercentage = (
  number?: string,
  previousNumber?: string
): string => {
  if (!number) return "";
  let currentNumber = number.replace(/[^\d]/g, "").replace(/^0+/g, "");
  if (currentNumber === "") currentNumber = "0";

  if (!previousNumber || number.length > previousNumber.length) {
    return `${currentNumber}%`;
  }

  return number;
};

export const formatCreditCardExpiry = (expiry: string): string => {
  const pieces = expiry.split("-");
  return pieces.length > 1 ? pieces[1] + " / " + pieces[0] : expiry;
};

export const obfuscateTokenizedSSN = (token: string): string => {
  if (!token) return token;
  const lastTwoDigits = token.slice(-2);

  return `xxx-xx-xx${lastTwoDigits}`;
};

export const isSSNObfuscated = (SSN?: string): boolean => {
  return SSN?.includes("xxx") || false; // should this check be better?
};

export const formatSingleDigit = (number: string): string => {
  if (!number) return number;
  const currentNumber = number.replace(/[^\d]/g, "");
  return currentNumber.charAt(0);
};

export const formatNumberWithCommas = (
  number: number | string | null
): string => {
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return "-";
};

export const lowerDashCase = (str: string): string => {
  return str.toLowerCase().replace(/\s+/g, "-");
};

export const interpolateColors = (
  color1: string,
  color2: string,
  steps: number
): number[][] => {
  if (!color1 || !color2 || !steps) return [];
  const interpolateColor = (
    color1: Array<number>,
    color2: Array<number>,
    factor: number
  ): Array<number> => {
    const result = color1.slice();
    for (let i = 0; i < 3; i++) {
      result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return result;
  };

  const stepFactor: number = 1 / (steps - 1);
  const interpolatedColorArray: any = [];
  const col1 = color1.match(/\d+/g);
  const col2 = color2.match(/\d+/g);
  if (col1 && col2) {
    const cr1 = col1.map(Number);
    const cr2 = col2.map(Number);
    for (let i = 0; i < steps; i++) {
      interpolatedColorArray.push(interpolateColor(cr1, cr2, stepFactor * i));
    }
  }
  return interpolatedColorArray;
};

export const getQueryParam = (key: string, query: string): string | null => {
  const re = new RegExp(`[?&]${key}=([^&]+).*$`);
  const match = query.match(re);
  return match ? match[1] : null;
};

export const lastFour = (digits: string | undefined): string => {
  return digits?.substring(digits.length - 4, digits.length) || "";
};

export const capitalizeTransferType = (name: string) => {
  const formatted = name === "Ach" ? "ACH" : name;

  return formatted;
};

export const formatBalancePeriod = (period: string) => {
  const date = new Date(period);
  const formatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  return formatter.format(date);
};

export const formatTransactionDate = (transactionDate?: string): string => {
  if (!transactionDate) return "";

  const date = Intl.DateTimeFormat(navigator.language, {
    month: "short",
    day: "numeric",
    year: "numeric",

  }).format(new Date(transactionDate));

  const time = Intl.DateTimeFormat(navigator.language, {
    hour: "numeric",
    minute: "2-digit",
  }).format(new Date(transactionDate));

  return `${time} – ${date}`;
}

export const formatDate = (sourceDate?: string): string => {
  if (!sourceDate) return "";
  const date = Intl.DateTimeFormat(navigator.language, {
    month: "short",
    day: "numeric",
    year: "numeric",

  }).format(new Date(sourceDate));

  return date;
}

export const formatDateShort = (sourceDate?: string): string => {
  if (!sourceDate) return "";
  const date = Intl.DateTimeFormat(navigator.language, {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",

  }).format(new Date(sourceDate));

  return date;
}

export const formatMonthYear = (sourceDate?: string): string => {
  if (!sourceDate) return "";
  const date = Intl.DateTimeFormat(navigator.language, {
    month: "short",
    year: "numeric",

  }).format(new Date(sourceDate));

  return date;
}

export const formatDateRange = (range: DateRange): string => {
  if (!range) return "";
  const formatter = Intl.DateTimeFormat(navigator.language, {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const startDate = new Date(range.start);
  const endDate = new Date(range.end);

  // not sure why i have to cast this to any – ts bug?
  return (formatter as any).formatRange(startDate, endDate);
}

export const formatPercentageRange = (range: PercentageRange): string => {
  const { lower, upper } = range;

  if (lower.amount === upper.amount) {
    return `${lower.display}`;
  } else {
    return `${lower.display} to ${upper.display}`;
  }
}

export const formatNumberRange = (range: NumberRange): string => {
  const { lower, upper } = range;

  if (lower === upper)
    return `${lower}`;
  else
    return `${lower} to ${upper}`;
}

export const pluralize = (count: number, noun: string, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;