import { motion } from "framer-motion";
import { fonts } from "src/theme/theme";
import styled from "styled-components";
import { colorPalette as colors, ColorNames, ThemeColor } from "src/theme/theme";

export const NavbarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  transition: 0.15s ease all;
  color: ${colors[ColorNames.MIDNIGHT].hex};

  &.invert-colors {
    color: ${colors[ColorNames.WHITE].hex};
  }
`;

export const NavbarBox = styled.div<{ backgroundColor?: ThemeColor }>`
  z-index: var(--zIndex-nav);
  position: relative;
  width: 100%;
  height: calc(var(--navbarHeight) + var(--extraDeviceTopPadding));
  display: grid;
  grid-template-columns: 44px 1fr 44px;
  align-items: center;
  padding: 0 10px;
  padding-top: var(--extraDeviceTopPadding);
  backdrop-filter: blur(0);
  transition: opacity 0.3s ease, background-color 0.3s linear, backdrop-filter 0.3s linear;
  will-change: opacity, background-color, backdrop-filter;
  

  @media (min-width: 768px) {
    padding-left: calc(var(--sideMenuWidth) + 10px);
  }

  &.disableNav {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
`;

export const NavbarLeft = styled.div`
  position: relative;
  top: 1px;
`;

export const NavbarCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;

  span {
    line-height: 1;
    ${fonts.HEADING_5}
  }
`;

export const NavbarRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const NavbarRightAction = styled.div`
  width: 100%;
  height: var(--navbarHeight);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavTitle = styled(motion.div)`
  position: absolute;
`;

export const MenuButton = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: inherit;
  border: none;
  width: 100%;
  height: var(--navbarHeight);
  @media (min-width: 768px) {
    display: none;
  }
  cursor: pointer;

  svg {
    pointer-events: none;
  }
`;

export const BackButton = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: inherit;
  border: none;
  width: 100%;
  height: var(--navbarHeight);
  cursor: pointer;

  svg {
    pointer-events: none;
  }
`;
