import styled, { css } from "styled-components";
import { InputProps } from "src/components/forms/input/Input";

import { fieldStyle } from "src/components/forms";
import { colorPalette as colors, ColorNames } from "src/theme/theme";

export const StyledInput = styled.input<InputProps>`
  ${fieldStyle}
  padding-left: ${({ currency }): string => (currency ? `10px` : `0`)};
  margin-top: 5px;
  ::-webkit-file-upload-button,
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    display: none;
  }

  ${(props) =>
    props.type === "file" &&
    css`
      cursor: pointer;
      display: none;
    `}
`;

export const StyledMultiInput = styled.div`
  display: flex;
  align-items: center;

  ${StyledInput} {
    width: auto;
    flex: 0 0 auto;
    overflow: hidden;

    &::placeholder {
      color: ${colors[ColorNames.GRAY5].hex};
    }

    &.placeholderShrink::placeholder {
      font-size: 0.86em;
      letter-spacing: -0.08em;
    }
  }
`;

export const StyledMultiInputSeparator = styled.div`
  color: ${colors[ColorNames.GRAY5].hex};
  margin: 5px 0.3ch 0;
  pointer-events: none;
`;

export const FileContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

export const PreviewContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  justify-content: center;
  border-radius: 4px;
  overflow: hidden;

  &:last-of-type {
    width: unset;
  }
`;

export const ImagePreview = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;
