import React, { useMemo, useState } from "react";
import { generatePath } from "react-router-dom";

import { useCurrentOrg } from "src/services/organizations";
import useNavigate from "src/util/useNavigate";
import { NavRoutes } from "src/routes/navRoutes";
import { useNavbar } from "src/util/useNavbar";
import { ColorNames } from "src/theme/theme";
import { decorateCurrencyValue, formatTransactionDate } from "src/util/stringUtils";
import { Line, LineOriginationStatus, LineStatus } from "src/generated/client";

import { LinesEmptyMessage, GenericWrapper } from "../OneLine.styles";

import Layout from "src/components/core-layout/Layout";
import { SegmentedControl, SegmentedControlOption } from "src/components/segmented-control/SegmentedControl";
import { ListButtonContainer } from "src/components/lists/Lists.styles";
import { ListButton } from "src/components/lists/ListButton";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { ReactComponent as ChevronRight } from "src/assets/icons/chevron_right.svg";

const Lines = () => {
  const currentOrg = useCurrentOrg({ fullFetch: true });
  const navigate = useNavigate();
  const totalInProgress = currentOrg.oneLine?.lines.filter((line) => !line.finalized).length;
  const [filterStatus, setFilterStatus] = useState(totalInProgress > 0 ? "in progress" : "open");

  useNavbar({ title: "Lines" });

  const filterOptions: SegmentedControlOption[] = useMemo(() => {
    return [
      {
        label: "Open",
        value: "open",
        count: currentOrg.oneLine?.lines.filter((line) => line.status !== LineStatus.PaidOff && line.finalized).length
      },
      {
        label: "Closed",
        value: "closed",
        count: currentOrg.oneLine?.lines.filter((line) => line.status === LineStatus.PaidOff).length
      },
      {
        label: "In Progress",
        value: "in progress",
        count: totalInProgress
      }
    ];
  }, [currentOrg.oneLine?.lines]);

  const filteredLines: Line[] = useMemo(() => {
    if (!currentOrg.oneLine) return [];

    return currentOrg.oneLine?.lines.filter((line) => {
      if (filterStatus === "open") {
        return line.status !== LineStatus.PaidOff && line.finalized;
      } else if (filterStatus === "closed") {
        return line.status === LineStatus.PaidOff;
      } else {
        return !line.finalized;
      }
    });
  }, [currentOrg.oneLine, filterStatus]);

  return (
    <Layout loading={!currentOrg.oneLine}>
      <>
        <SegmentedControl
          options={filterOptions}
          color={ColorNames.BREEZE}
          active={filterStatus}
          onClick={(value) => setFilterStatus(value as LineStatus)}
        />
        <ListButtonContainer>
          {filteredLines.length > 0 && filteredLines.map((line) => (
            <ListButton
              key={line.id}
              label={line.lineType.title}
              sublabel={`${decorateCurrencyValue(line.loanAmount.display)} – ${formatTransactionDate(line.createdAt)}`}
              onClick={() => {
                if (!line.finalized) {
                  const path = [LineOriginationStatus.LoanDocsSigned, LineOriginationStatus.PurchaseComplete, LineOriginationStatus.LoanFunded].includes(line.originationStatus) ? NavRoutes.ONELINE_FINALIZE_LINE : NavRoutes.ONELINE_EDIT_LINE;
                  
                  navigate(
                    generatePath(path, {
                      lineId: line.id,
                    })
                  );
                } else {
                  navigate(
                    generatePath(NavRoutes.ONELINE_LINE_DETAIL, {
                      lineId: line.id,
                      section: "overview"
                    })
                  );
                }
              }}
            />
          ))}
        </ListButtonContainer>

        {filteredLines.length <= 0 && (
          <GenericWrapper>
            {!currentOrg.oneLine?.lines.some((line) => !line.finalized) && (
              <ActionableButton
                color={ColorNames.BREEZE}
                label="Open a New Line"
                iconRight={<ChevronRight />}
                onClick={() => navigate(NavRoutes.ONELINE_BORROW)}
              />
            )}
            <LinesEmptyMessage
              children={`You don't have any ${filterOptions.find((i) => i.value === filterStatus)?.label} Lines`}
            />
          </GenericWrapper>
        )}
      </>
    </Layout>
  );
};

export default Lines;
