import styled from "styled-components/macro";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

import { StyledActionableButton } from "src/components/actionable-button/ActionableButton.styles";
import { fieldStyle } from "src/components/forms";

export const CardSheetDetails = styled.div`
  ${fonts.PARAGRAPH_M}

  ${StyledActionableButton} {
    margin: 30px 0;
  }

  iframe {
    height: 20px;
    width: 100%;
  }
`;

export const CardSheetInstructions = styled.div`
  ${fonts.PARAGRAPH_M};
  text-align: center;
  margin-bottom: 30px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
export const RowLabel = styled.div`
  color: ${colors[ColorNames.GRAY5].hex};
`;

export const RowContent = styled.div`
  word-break: break-word;
  text-align: right;
  font-feature-settings: "tnum" on, "lnum" on;

  &#cardNumber,
  &#cvv2 {
    min-width: 80px;
    height: 20px;

    &.empty {
      color: ${colors[ColorNames.GRAY5].hex};
    }

    &:empty {
      &:before {
        content: "Loading...";
        color: ${colors[ColorNames.GRAY5].hex};
      }
    }
  }
`;

export const CardSheetActivateInputsHolder = styled.div`
  margin-bottom: 40px;
`;

export const CardSheetActivateInput = styled.div`
  ${fieldStyle}
  margin-top: 5px;
`;
