import { motion } from "framer-motion";
import styled from "styled-components";
import { fonts } from "src/theme/theme";
import { colorPalette as colors, ColorNames } from "src/theme/theme";

export const SUBNAV_FIXED_HEIGHT = "48px";

export const SubnavWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 0;
  backdrop-filter: blur(5px);
  visibility: hidden;
  transform: translateY(-${SUBNAV_FIXED_HEIGHT});
  transition: 0.2s ease transform;
  box-shadow: 0px 20px 26px -20px rgba(0, 0, 0, 0.15), 0px 12px 16px -16px rgba(0, 0, 0, 0.1);

  &.visible {
    transform: translateY(0);
    visibility: visible;
    height: ${SUBNAV_FIXED_HEIGHT};
  }

  @media (min-width: 768px) {
    padding-left: var(--sideMenuWidth);
  }
`;

export const Grid = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Tab = styled.button`
  -webkit-touch-callout: transparent;
  background-color: transparent;
  border: none;
  cursor: pointer;
  user-select: none;
  display: block;
  width: 100%;
  color: ${colors[ColorNames.GRAY5].hex};
  position: relative;
  transition: 0.15s ease color;
  text-transform: capitalize;
  ${fonts.PARAGRAPH_S}

  &.active {
    color: #191b1f;
  }
  
  @media (max-width: 768px) {
    &:first-child:nth-last-child(n + 4),
    &:first-child:nth-last-child(n + 4) ~ * {
      min-width: 29%;
    }
  }
`;

export const TabHighlight = styled(motion.span)`
  display: block;
  background-color: ${colors[ColorNames.MIDNIGHT].hex};
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  will-change: transform;
`;

export const TabViewContainer = styled(motion.div)`
  display: block;
`;
