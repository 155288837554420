import React, { useEffect, useState, useCallback, useRef } from "react";

import {
  RingProgressText,
  RingTrack,
  RingSpinnerWrapper,
  RingBarSpinner
} from "src/components/ring-progress/RingProgress.styles";
import { colorPalette as colors, ThemeColor, ColorNames } from "src/theme/theme";
import { DataTestIds } from "src/util/testing-util/test-utils";
import { interpolateColors } from "src/util/stringUtils";

interface SpinningRingProps {
  fromColor: string;
  toColor: string;
  size: number;
  strokeWidth: number;
  trackStrokeWidth: number;
  totalPercentage: number;
}

const spinningRing = ({ fromColor, toColor, size, strokeWidth, trackStrokeWidth, totalPercentage }: SpinningRingProps): JSX.Element => {
  const svgs: any = [];
  const steps = Math.ceil(size * 3);
  const stroke: number = strokeWidth / 2;
  const circleSize: number = (strokeWidth >= trackStrokeWidth) ? size / 2 : (size - ((trackStrokeWidth - strokeWidth))) / 2;
  const colors: number[][] = interpolateColors(toColor, fromColor, steps);

  let i: number = steps;
  for (; i--; ) {
    const point: number = (i * Math.PI) / (steps / 2);
    const opacity: number = i / steps <= 1 - totalPercentage ? 0 : 1;
    svgs.push(
      <React.Fragment key={`${point}-${opacity}`}>
        <RingBarSpinner
          color={fromColor}
          cx={circleSize - Math.sin(point) * (circleSize - stroke)}
          cy={circleSize - Math.cos(point) * (circleSize - stroke)}
          r={stroke}
          style={{ opacity: `${opacity}`, fill: `rgb(${colors[i][0]},${colors[i][1]},${colors[i][2]})` }}
        />
      </React.Fragment>
    );
  }
  return svgs;
};

export interface RingProgressProps {
  value: number;
  total: number;
  percentage?: boolean;
  color?: ThemeColor;
  textColor?: string;
  trackColor?: string;
  fromColor?: string;
  toColor?: string;
  size?: number;
  trackStrokeWidth?: number;
  strokeWidth?: number;
  showText?: boolean;
}

export const RingProgress = ({
  value,
  total,
  percentage,
  textColor,
  trackColor,
  fromColor,
  toColor,
  color = ColorNames.MIDNIGHT,
  size = 80,
  trackStrokeWidth = 2,
  strokeWidth = 6,
  showText = true
}: RingProgressProps): JSX.Element => {
  const [displayValue, updateDisplayValue] = useState(0);

  const updateTimer = useRef<number | null>(null);

  const updatePercentage = useCallback((): void => {
    const stepSize: number = value / 90;
    const magnitude: number = Math.ceil(value / total);
    updateTimer.current = window.setTimeout(() => {
      updateDisplayValue((displayVal) => displayVal + stepSize);
    }, 5 * magnitude);
  }, [value, total]);

  useEffect(() => {
    if (value > 0) updatePercentage();
  }, [value, updatePercentage]);

  useEffect(() => {
    if (displayValue < value) updatePercentage();
  }, [displayValue, value, updatePercentage]);

  useEffect(() => {
    return () => {
      if (!!updateTimer) {
        clearTimeout(updateTimer.current!);
        updateTimer.current = null;
      }
    };
  }, []);

  let overlap = false;
  if (displayValue > total) {
    overlap = true;
  }

  const rgbColor = `rgb(${colors[color].rgb})`;
  const colorText: string = textColor || rgbColor;
  const colorTrack: string = trackColor || rgbColor;
  const colorFrom: string = fromColor || rgbColor;
  const colorTo: string = toColor || rgbColor;
  const singleColor = !fromColor;
  const radius: number = (size - Math.max(strokeWidth, trackStrokeWidth)) / 2;
  const totalPercentage: number = displayValue / total;
  const leftOver: number = (Math.round(totalPercentage * 100) - 100) * (360 / 100);
  const translate = (strokeWidth >= trackStrokeWidth) ? "0px" : `${(trackStrokeWidth - strokeWidth) / 2}px`;
  const rotation: string = overlap ? `rotate3d(0,0,1,${leftOver}deg) translate(${translate},${translate})` : `rotate3d(0,0,0,0) translate(${translate},${translate})`;

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} data-testis={DataTestIds.RING_PROGRESS}>
      <RingTrack color={colorTrack} strokeWidth={`${trackStrokeWidth}px`} cx={size / 2} cy={size / 2} r={radius} />
      <RingSpinnerWrapper overlap={overlap} singleColor={singleColor} style={{ transform: rotation }} cx={1} cy={10}>
        {spinningRing({ fromColor: colorFrom, toColor: colorTo, trackStrokeWidth, strokeWidth, size, totalPercentage })}
      </RingSpinnerWrapper>
      {showText && (
        <RingProgressText color={colorText} size={size} x="50%" y="51%" dy=".3em" textAnchor="middle">
          {percentage ? `${Math.round((displayValue / total) * 100)}%` : Math.round(displayValue)}
        </RingProgressText>
      )}
    </svg>
  );
};
