import React from "react";
import { Variants } from "framer-motion";
import { Outlet, useOutlet, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useReactiveVar } from "@apollo/client";

import { navigationState } from "src/store/navigationState";
import SessionWatcher from "./SessionWatcher";
import PostMessageHandler from "src/PostMessageHandler";
import { RouteWatcher } from "src/Router";
import { NavigationDirection } from "src/store/navigationState";
import { OverlayController } from "src/OverlayController";
import { coreLayoutBodyBackgroundColor } from "src/store/layoutState";
import CoreNav from "src/components/core-layout/CoreNav";
import { AppMenu } from "src/components/side-menu/AppMenu";
import { CoreBody, CoreLayoutContent } from "src/components/core-layout/CoreLayout.styles";


const TRANSITION_X = window.innerWidth <= 768 ? "-50%" : "-100%";
const TRANSITION_OPACITY_EXIT = window.innerWidth <= 768 ? 0.5 : 0.0;
const TRANSITION_OPACITY_ENTER = window.innerWidth <= 768 ? 1.0 : 0.0;
const TRANSITION_DURATION = 400;
export const TRANSITION_DURATION_PADDED = TRANSITION_DURATION + 100;

export const variants: Variants = {
  enter: (direction: NavigationDirection) => {
    let x = "0";
    let opacity = 0;
    switch (direction) {
      case NavigationDirection.FORWARD:
        x = "105%";
        opacity = TRANSITION_OPACITY_ENTER;
        break;
      case NavigationDirection.BACKWARD:
        x = TRANSITION_X;
        opacity = TRANSITION_OPACITY_EXIT;
        break;
    }
    return {
      zIndex: direction === NavigationDirection.FORWARD ? 2 : 0,
      x,
      opacity
    };
  },
  center: (direction: NavigationDirection) => {
    return {
      zIndex: 1,
      x: 0,
      opacity: 1,
      transition: {
        ease: [0.42, 0.0, 0.58, 1],
        duration: direction === NavigationDirection.REPLACE ? 0 : TRANSITION_DURATION / 1000
      }
    };
  },
  exit: (direction: NavigationDirection) => {
    let x = "0";
    let opacity = 0;
    switch (direction) {
      case NavigationDirection.FORWARD:
        x = TRANSITION_X;
        opacity = TRANSITION_OPACITY_EXIT;
        break;
      case NavigationDirection.BACKWARD:
        x = "105%";
        opacity = TRANSITION_OPACITY_ENTER;
        break;
    }
    return {
      zIndex: direction === NavigationDirection.FORWARD ? 0 : 2,
      x,
      opacity,
      transition: {
        ease: [0.42, 0, 0.58, 1],
        duration: direction === NavigationDirection.REPLACE ? 0 : TRANSITION_DURATION / 1000
      }
    };
  }
};

const LetterRoot = () => {
  const coreBodyLayoutBG = useReactiveVar(coreLayoutBodyBackgroundColor);

  return (
    <CoreBody>
      <AppMenu />
      <CoreLayoutContent backgroundColor={coreBodyLayoutBG}>
        <CoreNav />
        <RouteWatcher />
        <SessionWatcher />
        <PostMessageHandler />

        <Outlet />
      </CoreLayoutContent>
      <OverlayController />
    </CoreBody>
  );
};

export const AnimatedRoot = (): JSX.Element => {
  const outlet = useOutlet();
  const { pathname } = useLocation();
  const navigation = useReactiveVar(navigationState);

  return <>
    <AnimatePresence initial={false} custom={navigation.direction}>
      <React.Fragment key={pathname}>{outlet}</React.Fragment>
    </AnimatePresence>
  </>;
};

export default LetterRoot;
