import React from "react";
import content from "src/data/banking_agreement_individual";
import { TermsPage, Logo } from "src/pages/terms/Terms.styles";

const PersonalBankingDespositAgreement = () => (
  <TermsPage>
    <Logo />
    {content}
  </TermsPage>
);

export default PersonalBankingDespositAgreement;
