import { Money, Scalars, Deal, Account } from "src/generated/client";

export enum DealTypes {
  SECONDARY = "Seondary",
  INVESTMENT = "Investment"
}

export enum DealTransactionTypes {
  BUY = "Buy",
  SELL = "Sell"
}

export enum DealEntityTypes {
  Share = "Share",
  BTC = "BTC",
  ETH = "ETH",
  USDC = "USDC",
  SOL = "SOL"
}

export enum DealTransactionStatuses {
  CANCELED = "Canceled",
  CLEARING = "Clearing",
  COMMITTED = "Committed",
  COMPLETED = "Completed",
  HELD = "Held",
  PENDING = "Pending",
  REJECTED = "Rejected",
  SENT = "Sent"
}

export type DealTransaction = {
  amount: Money;
  createdAt: Scalars["Date"];
  committedAmount: Money;
  dealId: Scalars["String"];
  deal: Deal;
  entityQuantity: Scalars["String"];
  entityType: DealEntityTypes;
  formattedDate: Scalars["String"];
  fundingAccount: Account;
  fundingAccountId: Scalars["String"];
  id: Scalars["ID"];
  lastUpdatedAt: Scalars["Date"];
  organizationId: Scalars["String"];
  status: DealTransactionStatuses;
  transactionDate: Scalars["Date"];
  transactionType: DealTransactionTypes;
};