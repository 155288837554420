import { gql } from "@apollo/client";
import { userFragment, createdUserFragment } from "src/services/gql/_fragments";

export const GET_LOGGED_IN_USER = gql`
  query Me {
    me {
      ...UserData
      checks {
        type
        status
        comments
      }
    }
  }
  ${userFragment}
`;

export const CREATE_USER = gql`
  mutation CreateUser(
    $firstName: String!
    $lastName: String!
    $email: String
    $phone: String!
    $password: String!
    $referralCode: String
    $netWorth: String!
    $inviteCode: String
    $partnershipType: PartnershipType
  ) {
    createUser(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        password: $password
        referralCode: $referralCode
        netWorth: $netWorth
        inviteCode: $inviteCode
        partnershipType: $partnershipType
      }
    ) {
      token
      ...CreatedUserData
    }
  }
  ${createdUserFragment}
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $userId: ID!
    $firstName: String!
    $lastName: String!
    $phone: String!
    $netWorth: String!
  ) {
    editBasicUserInformation(
      input: {
        userId: $userId
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        netWorth: $netWorth
      }
    ) {
      ...UserData
    }
  }
  ${userFragment}
`;

export const ADD_PERSONAL_INFO = gql`
  mutation AddPersonalInfo(
    $userId: ID!
    $DOB: String!
    $SSN: String!
    $streetAddress: String!
    $city: String!
    $state: String!
    $zip: String!
  ) {
    addPersonalInformationToUser(
      input: {
        userId: $userId
        DOB: $DOB
        SSN: $SSN
        streetAddress: $streetAddress
        city: $city
        state: $state
        zip: $zip
      }
    ) {
      ...UserData
    }
  }
  ${userFragment}
`;

export const TRIGGER_VERIFICATION = gql`
  mutation TriggerVerification($type: UserVerificationType!) {
    triggerVerification(input: { type: $type }) {
      success
    }
  }
`;

export const ADD_VERIFICATION_TO_USER = gql`
  mutation AddVerificationToUser(
    $userId: ID!
    $verificationType: UserVerificationType
    $verificationImage: Upload
    $idVerificationName: UserIdVerificationName
    $token: String
    $organizationId: String
  ) {
    addVerificationToUser(
      input: {
        userId: $userId
        verificationType: $verificationType
        verificationImage: $verificationImage
        idVerificationName: $idVerificationName
        token: $token
        organizationId: $organizationId
      }
    ) {
      verified {
        wealth
        id
        mfa
        survey
        phone
        email
        face
        _userId
      }
    }
  }
`;

export const CANCEL_ACCOUNT = gql`
  mutation CancelAccount {
    cancelAccount {
      success
    }
  }
`;

export const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(input: { email: $email }) {
      success
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($password: String!, $token: String!) {
    resetPassword(input: { password: $password, token: $token }) {
      email
    }
  }
`;

export const OPEN_ACCOUNT = gql`
  mutation OpenAccount {
    openAccount {
      ...UserData
    }
  }
  ${userFragment}
`;

export const LOCAL_UNLOCK = gql`
  mutation UnlockAccount {
    unlockAccount {
      ...UserData
    }
  }
  ${userFragment}
`;

export const ADD_DEVICE_TOKEN = gql`
  mutation EditUserDeviceToken($deviceToken: String!) {
    editUserDeviceToken(input: { deviceToken: $deviceToken }) {
      ...UserData
    }
  }
  ${userFragment}
`;

export const EDIT_NOTIFICATION_SETTINGS = gql`
  mutation EditUserNotificationSettings(
    $receivePush: Boolean!
    $receiveSms: Boolean!
  ) {
    editUserNotificationSettings(
      input: { receivePush: $receivePush, receiveSms: $receiveSms }
    ) {
      ...UserData
    }
  }
  ${userFragment}
`;

export const VALIDATE_RESET_TOKEN = gql`
  mutation ValidatePasswordResetToken($token: String!) {
    validatePasswordResetToken(input: { token: $token }) {
      valid
    }
  }
`;

export const REQUEST_MORE_REFERRALS = gql`
  mutation RequestMoreReferrals {
    requestMoreReferrals {
      success
    }
  }
`;
