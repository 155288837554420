import React, { useEffect } from "react";
import { Formik } from "formik";
import { motion } from "framer-motion";
import * as Yup from "yup";
import { showNotice } from "src/store/alertState";

import { zipCodeFormatRegex } from "src/util/stringUtils";
import States from "src/data/states.json";

import useNavigate from "src/util/useNavigate";
import { NavRoutes } from "src/routes/navRoutes";
import { ColorNames } from "src/theme/theme";
import { InstitutionInfo, RecipientsContainer } from "./Recipients.styles";
import { Button } from "src/components/button/Button";
import { Form } from "src/components/forms/Form";
import { FormSpacer, StyledFormInputContainer } from "src/components/forms";
import { Input } from "src/components/forms/input/Input";
import { Select } from "src/components/forms/select/FormikSelect";
import { useCreateRecipient } from "src/services/recipients";
import { Recipient, TransferRecipientType } from "src/generated/client";
import { NavigationDirection } from "src/store/navigationState";

import { ReactComponent as IconCheck } from "src/assets/icons/check.svg";
import { ReactComponent as IconError } from "src/assets/icons/error.svg";
import { ReactComponent as IconClose } from "src/assets/icons/close.svg";
import { useGetInstitutionInfo } from "src/services/payments";
import classnames from "classnames";
import Layout from "src/components/core-layout/Layout";
import { useNavbar } from "src/util/useNavbar";

const RecipientSchema: Yup.ObjectSchema<object> = Yup.object().shape({
  nickname: Yup.string().min(2, "Nickname needs to be at least 2 characters long.").required("Please add a nickname."),
  accountHolderName: Yup.string().min(2, "Please use a valid name.").required("Please add an account holder name."),
  accountNumber: Yup.string().required("Please add an account number."),
  routingNumber: Yup.string().required("Please add a routing number."),
  transferType: Yup.string().required("Please select the transfer type."),
  street: Yup.string().required("Please add a street."),
  city: Yup.string().required("Please add a city."),
  state: Yup.string().required("Please add a state."),
  zipCode: Yup.string().matches(zipCodeFormatRegex, "Please use a valid zip code.").required("Please add a zip code."),
  country: Yup.string().required("Please add a country.")
});

const transferTypeOptions = [
  {
    label: "ACH",
    value: "Ach"
  },
  {
    label: "Wire",
    value: "Wire"
  }
];

const filterTransferTypes = (
  achSupported: boolean | undefined,
  wireSupported: boolean | undefined,
  isBlueRidge: boolean | undefined
) => {
  if (isBlueRidge) return [transferTypeOptions[0]];
  if (achSupported && wireSupported) return transferTypeOptions;
  if (achSupported && !wireSupported) return [transferTypeOptions[0]];
  if (!achSupported && wireSupported) return [transferTypeOptions[1]];

  return transferTypeOptions;
};

const statesForDropdown = [
  {
    label: "Choose state",
    value: ""
  },
  ...States
];

const initialFormValues: Omit<Recipient, "id" | "organizationId"> = {
  nickname: "",
  accountHolderName: "",
  accountNumber: "",
  routingNumber: "",
  transferType: TransferRecipientType.Wire,
  street: "",
  city: "",
  state: "",
  zipCode: "",
  country: "United States",
  wireDescription: ""
};

const NewRecipient = (): JSX.Element => {
  useNavbar({ title: "New Recipient", back: true });
  const navigate = useNavigate();
  
  const { createRecipient, loading, error } = useCreateRecipient();
  const {
    getInstitutionInfo,
    institutionData,
    loading: institutionLoading,
    error: institutionError
  } = useGetInstitutionInfo();
  const hasBadRoutingNumber = institutionError?.message === "Bad routing number";
  const isBlueRidge = institutionData?.name.match("Blue Ridge Bank");

  const queryInstitutionInfo = (routingNumber: string) => {
    getInstitutionInfo(routingNumber);
  };

  const handleCreateRecipient = async (
    values: Omit<Recipient, "id" | "organizationId">,
    setSubmitting: (submitting: boolean) => void
  ): Promise<void> => {
    if (!institutionError) {
      if (values.transferType !== "Wire") values.wireDescription = "";

      const create = await createRecipient(values);

      if (create) {
        showNotice(`Recipient saved.`);
        navigate(`${NavRoutes.BANK_RECIPIENTS}/${create.id}`, { direction: NavigationDirection.BACKWARD });
      }
    } else {
      showNotice("Please enter a valid routing number.", { error: true });
    }

    setSubmitting(false);
  };

  useEffect(() => {
    if (!!error) {
      showNotice(error.message || "There was a problem adding this recipient.", { error: true });
    }
    if (!!institutionError) {
      showNotice(institutionError.message || "Unable to fetch institution info.", { error: true });
    }
  }, [error, institutionError?.message]);

  return (
    <Layout>
      <RecipientsContainer>
        <Formik
          initialValues={initialFormValues}
          enableReinitialize={true}
          validationSchema={RecipientSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values, { setSubmitting }) => {
            handleCreateRecipient(values, setSubmitting);
          }}
        >
          {({ values, isSubmitting }): JSX.Element => (
            <Form>
              <>
                <StyledFormInputContainer>
                  <Input name="accountHolderName" type="text" label="Account Holder Name" autoComplete="off" />
                  <Input name="accountNumber" type="text" label="Account Number" autoComplete="off" />
                  <Input
                    name="routingNumber"
                    type="text"
                    label="Routing Number"
                    autoComplete="off"
                    onBlur={() => queryInstitutionInfo(values.routingNumber)}
                  />
                </StyledFormInputContainer>

                <motion.div
                  initial={{
                    height: 0,
                    width: "100%",
                    overflow: "hidden"
                  }}
                  animate={{
                    height: institutionData || institutionError ? "auto" : 0,
                    overflow: institutionData || institutionError ? "visible" : "hidden"
                  }}
                >
                  <InstitutionInfo className={classnames({ error: institutionError })}>
                    {institutionError && <IconError />}
                    <div>
                      <p>
                        {hasBadRoutingNumber
                          ? "We couldn’t find an institution with that routing number. Please enter a valid routing number to continue."
                          : institutionError?.message}
                      </p>
                      {!!institutionData && !institutionError && (
                        <>
                          <p className="institution-name">{institutionData?.name}</p>
                          <p>{institutionData?.address}</p>
                        </>
                      )}

                      {!!institutionData && !institutionError && (
                        <div className="institution-supported-payments">
                          <div className={classnames({ error: !institutionData?.isAchSupported })}>
                            {institutionData?.isAchSupported ? <IconCheck /> : <IconClose />}
                            <span>ACH</span>
                          </div>
                          {!isBlueRidge && (
                            <div className={classnames({ error: !institutionData?.isWireSupported })}>
                              {institutionData?.isWireSupported ? <IconCheck /> : <IconClose />}
                              <span>Wire</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </InstitutionInfo>
                </motion.div>

                <FormSpacer />

                <StyledFormInputContainer>
                  <Input name="street" type="text" label="Account Holder Address" autoComplete="off" />
                  <Input name="city" type="text" label="City" autoComplete="off" />
                  <Select name="state" label="State" options={statesForDropdown} autoComplete="off" />
                  <Input name="zipCode" type="text" label="ZIP Code" autoComplete="off" />
                  {/* <Input name="country" type="text" label="Country" value="United States" autoComplete="off" disabled /> */}
                </StyledFormInputContainer>

                <FormSpacer />

                <StyledFormInputContainer>
                  <Input name="nickname" type="text" label="Account Nickname" autoComplete="off" />

                  <Select
                    name="transferType"
                    label="Transfer Type"
                    options={filterTransferTypes(
                      institutionData?.isAchSupported,
                      institutionData?.isWireSupported,
                      !!isBlueRidge
                    )}
                    disabled={!!isBlueRidge}
                  />
                  {values.transferType && values.transferType === "Wire" && (
                    <Input name="wireDescription" type="text" label="Message to recipient bank" autoComplete="off" />
                  )}
                </StyledFormInputContainer>

                <FormSpacer />
                <FormSpacer />

                <Button
                  color={ColorNames.LAKE}
                  raised
                  type="submit"
                  loading={isSubmitting || loading || institutionLoading}
                >
                  Add Recipient
                </Button>
              </>
            </Form>
          )}
        </Formik>
      </RecipientsContainer>
    </Layout>
  );
};

export default NewRecipient;
