// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3C%3Fxml version%3D%221.0%22 encoding%3D%22UTF-8%22%3F%3E%3Csvg version%3D%221.1%22 viewBox%3D%220 0 16 16%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E  %3Ctitle%3EGroup%3C%2Ftitle%3E  %3Cdesc%3ECreated with Sketch.%3C%2Fdesc%3E  %3Cdefs%3E    %3Cpath id%3D%22a%22 d%3D%22m8.6667 9.9327c1.5507-0.3189 2.6644-1.6828 2.6667-3.266 0-1.838-1.4953-3.3333-3.3333-3.3333s-3.3333 1.4953-3.3333 3.3333h1.3333c0-1.1027 0.89733-2 2-2s2 0.89733 2 2c0 1.1027-0.89733 2-2 2h-0.66667v2h1.3333v-0.734zm-1.3333 2.0673h1.3333v1.3333h-1.3333v-1.3333z%22%2F%3E  %3C%2Fdefs%3E  %3Cg fill%3D%22none%22 fill-rule%3D%22evenodd%22%3E    %3Cg transform%3D%22translate%28-173 -346%29%22%3E      %3Cg transform%3D%22translate%28173 184%29%22%3E        %3Cg transform%3D%22translate%280 160%29%22%3E          %3Cg transform%3D%22translate%280 2%29%22%3E            %3Ccircle cx%3D%228%22 cy%3D%228%22 r%3D%228%22 fill%3D%22%233640F5%22%2F%3E            %3Cmask fill%3D%22white%22%3E              %3Cuse xlink%3Ahref%3D%22%23a%22%2F%3E            %3C%2Fmask%3E            %3Cuse fill%3D%22%23FFFFFF%22 fill-rule%3D%22evenodd%22 xlink%3Ahref%3D%22%23a%22%2F%3E          %3C%2Fg%3E        %3C%2Fg%3E      %3C%2Fg%3E    %3C%2Fg%3E  %3C%2Fg%3E%3C%2Fsvg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-QRCode-qrCodeHelp{min-height:unset}.onfido-sdk-ui-QRCode-qrCodeHelpButton{cursor:pointer}.onfido-sdk-ui-QRCode-qrCodeHelpIcon{width:1.25em;height:1em;display:inline-block;vertical-align:text-bottom;content:\"\";background-repeat:no-repeat;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.onfido-sdk-ui-QRCode-qrCodeHelpList{font-size:var(--onfido-font-size-small);text-align:left;margin-bottom:1em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"qrCodeHelp": "onfido-sdk-ui-QRCode-qrCodeHelp",
	"qrCodeHelpButton": "onfido-sdk-ui-QRCode-qrCodeHelpButton",
	"qrCodeHelpIcon": "onfido-sdk-ui-QRCode-qrCodeHelpIcon",
	"qrCodeHelpList": "onfido-sdk-ui-QRCode-qrCodeHelpList"
};
export default ___CSS_LOADER_EXPORT___;
