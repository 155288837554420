import styled from "styled-components";
import { StyledFormInputContainer } from "src/components/forms";
import { ButtonGroup } from "src/components/button/Button.styles";

export const PageContent = styled.div`
  padding: 0 30px;
  display: flex;
  align-items: stretch;
  flex: 1 1 100%;
  margin-bottom: 48px;

  ${StyledFormInputContainer} {
    margin-bottom: 30px;
  }

  ${ButtonGroup} {
    gap: 16px;
  }
`;
