import React, { useEffect, useState } from "react";
import { showNotice } from "src/store/alertState";
import { Formik } from "formik";
import * as Yup from "yup";

import { Button } from "src/components/button/Button";
import { Input } from "src/components/forms/input/Input";
import { StyledForm, StyledFormInputContainer } from "src/components/forms";
import { BasicAuthContainer } from "src/pages/auth/Auth.styles";
import { AuthPageWrapper } from "src/pages/auth/Auth.styles";
import { useHoFund } from "src/util/useHoFund";
import { NavRoutes } from "src/routes/navRoutes";
import { useNavbar } from "src/util/useNavbar";
import Layout from "src/components/core-layout/Layout";
import { CypressTestIds } from "src/util/testing-util/test-utils";

const validationText = "Please use a valid email address.";
const EmailSchema: Yup.ObjectSchema<object> = Yup.object().shape({
  email: Yup.string().email(validationText).required(validationText)
});

type ReturnType = { email: string };

const ForgotPassword = (): JSX.Element => {
  useNavbar({ back: true, title: "Forgot Password", orgSwitcher: false });

  const { action: requestReset, loading, error } = useHoFund<boolean, ReturnType>(NavRoutes.FORGOT_PASSWORD);

  const [success, setSuccess] = useState(false);
  

  useEffect(() => {
    if (error) {
      showNotice("There was a problem finding your account.", { error: true });
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      showNotice("A reset link has been sent to your email address.");
    }
  }, [success]);

  const handleRequest = async (
    values: { email: string },
    setSubmitting: (submitting: boolean) => void
  ): Promise<void> => {
    const success = await requestReset({ email: values.email });
    setSuccess(!!success);
    setSubmitting(false);
  };

  const initialFormValues: { email: string } = {
    email: ""
  };

  return (
    <Layout>
      <AuthPageWrapper>
        <BasicAuthContainer>
          <Formik
            initialValues={initialFormValues}
            validationSchema={EmailSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values, { setSubmitting, resetForm }): void => {
              handleRequest(values, setSubmitting);
              resetForm();
            }}
          >
            {({ isSubmitting }): JSX.Element => (
              <StyledForm>
                <StyledFormInputContainer>
                  <Input
                    name="email"
                    data-cy={CypressTestIds.FORGOT_MY_PASSWORD_EMAIL}
                    type="email"
                    label="Email Address"
                    autoComplete="email"
                    autoFocus
                  />
                </StyledFormInputContainer>

                <Button
                  raised
                  type="submit"
                  data-cy={CypressTestIds.FORGOT_MY_PASSWORD_BTN}
                  loading={isSubmitting || loading}
                  complete={success}
                >
                  I Forgot My Password
                </Button>
              </StyledForm>
            )}
          </Formik>
        </BasicAuthContainer>
      </AuthPageWrapper>
    </Layout>
  );
};

export default ForgotPassword;
