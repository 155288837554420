import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";

import { useCurrentUser } from "src/services/users";
import useNavigate from "src/util/useNavigate";
import { NavigationDirection } from "src/store/navigationState";

import { AuthContainer } from "src/pages/auth/Auth";
import { AuthTitle } from "src/pages/auth/Auth.styles";
import { ModalContainer } from "src/routes/app/onboarding/Onboarding.styles";
import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import { SheetTitle, SheetContainer, SheetDescription } from "src/components/bottom-sheets/BottomSheet.styles";
import { BottomSheet } from "src/components/bottom-sheets/BottomSheet";
import EnableMfa from "src/pages/auth/onboarding/mfa/EnableMfa";
import { AddModal } from "src/components/cta/add-modal/AddModal";
import { UserIdVerificationType } from "src/types/user";
import { UserCheckStatus } from "src/generated/client";
import {
  UserVerificationType,
  useTriggerVerification,
  AddVerificationToUserData,
  useAddVerificationToUser
} from "src/services/users";
import { showNotice } from "src/store/alertState";
import { useFetchCurrentUser } from "src/services/users";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { bottomSheetState } from "src/store/bottomSheetState";
import { useWipeStorage } from "src/util/localStorage";
import { NavRoutes } from "src/routes/navRoutes";
import { Button } from "src/components/button/Button";
import { ColorNames } from "src/theme/theme";

import Onfido from "./Onfido";
import { CypressTestIds } from "src/util/testing-util/test-utils";

type Props = {
  nextStep: () => void;
  stepDirection: number;
};

const VerifyIdentity = ({ nextStep, stepDirection }: Props): JSX.Element => {
  const currentUser = useCurrentUser();
  const bottomSheet = useRecoilValue(bottomSheetState);

  const [faceIdSubmitted, setFaceIdSubmitted] = useState(false);
  const [pollCount, setPollCount] = useState(1);
  const [onfidoIdStatus, setOnfidoIdStatus] = useState<UserCheckStatus | undefined>();
  const [isTriggeringVerification, setIsTriggeringVerification] = useState<string | null>(null);
  const [showFinalLoading, setShowFinalLoading] = useState(false);

  const { trigger, loading: triggerLoading, error: triggerError } = useTriggerVerification();
  const { addVerification, loading: addLoading, error: addError } = useAddVerificationToUser();

  
  const navigate = useNavigate();
  const { fetchCurrentUser, startPolling, stopPolling } = useFetchCurrentUser();
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();
  const wipeAllCookiesAndStorage = useWipeStorage();

  const POLL_LIMIT = 6;
  const POLL_INTERVAL = 10000;

  useEffect(() => {
    if (!currentUser) return;

    const status = currentUser.checks
      .filter((_, index, checks) => index === checks.length - 1)
      .find((check) => check.type === "Face")?.status;

    setOnfidoIdStatus(status);
  }, [currentUser?.checks]);

  useEffect(() => {
    const error = triggerError || addError;

    if (!!error) {
      const errorMessage = error ? error.message : "There was a problem verifying your info.";

      showNotice(errorMessage, { error: true });
    }
  }, [triggerError, addError]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    if (faceIdSubmitted) {
      timeout = setTimeout(() => {
        setPollCount(pollCount + 1);
      }, POLL_INTERVAL);
    }

    if (faceIdSubmitted && currentUser?.verified.face === false && pollCount === POLL_LIMIT) {
      showBottomSheet("onfido_problem_sheet");
      stopPolling();
      timeout && clearTimeout(timeout);
    }

    if (faceIdSubmitted && pollCount < POLL_LIMIT) {
      startPolling(POLL_INTERVAL);
    }

    return () => {
      stopPolling();
      timeout && clearTimeout(timeout);
    };
  }, [faceIdSubmitted, pollCount]);

  useEffect(() => {
    setShowFinalLoading(false);
    if (onfidoIdStatus === "Denied") {
      showBottomSheet("fraud_detected_sheet");
      wipeAllCookiesAndStorage({});
      setTimeout(() => {
        navigate(NavRoutes.LANDING, { direction: NavigationDirection.REPLACE });
      }, 500);
    } else if (onfidoIdStatus === "Problem") {
      showBottomSheet("onfido_problem_sheet");
    } else if (onfidoIdStatus === "Approved" && !currentUser?.onboarded) {
      setShowFinalLoading(true);
    } else if (currentUser?.onboarded) {
      nextStep();
    }
  }, [onfidoIdStatus, wipeAllCookiesAndStorage, currentUser]);

  const handleTriggerVerification = async ({ verificationType, resend }: { verificationType: UserVerificationType; resend?: boolean; }): Promise<void> => {
    if (!resend && (!!isTriggeringVerification || bottomSheet.showIds.length > 0)) return;
    setIsTriggeringVerification(verificationType);

    const triggered = await trigger(verificationType);

    if (triggered) {
      switch (verificationType) {
        case UserVerificationType.Email:
          showBottomSheet("verify_email_sheet");
          break;
        case UserVerificationType.Phone:
          showBottomSheet("verify_phone_sheet");
          break;
      }
    } else {
      showNotice(
        `There was problem verifying your ${
          verificationType === UserVerificationType.Email ? "email address" : "phone number"
        }, please try again.`,
        { error: true }
      );
    }

    setIsTriggeringVerification(null);
  };

  type AddVerificationArgs = {
    verificationType: UserVerificationType;
    code?: string;
    idImages?: { main: Blob | string; front?: Blob | string; back?: Blob | string };
    idName?: string;
  };

  const handleAddVerification = async ({
    verificationType,
    code,
    idImages,
    idName: idVerificationName
  }: AddVerificationArgs): Promise<void> => {
    if (addLoading) return;

    if (verificationType === UserVerificationType.Id) {
      if (idVerificationName !== UserIdVerificationType.DrivingLicense) {
        await addVerification({
          verificationType,
          verificationImage: idImages?.main,
          idVerificationName,
          userId: currentUser?.id || ""
        });
      }

      if (idVerificationName === UserIdVerificationType.DrivingLicense) {
        const args = [
          idImages?.front
            ? {
                verificationImage: idImages?.front,
                idVerificationName: UserIdVerificationType.DrivingLicenseFront
              }
            : null,
          idImages?.back
            ? {
                verificationImage: idImages?.back,
                idVerificationName: UserIdVerificationType.DrivingLicenseBack
              }
            : null
        ].filter(Boolean) as AddVerificationToUserData[];

        args.length && (await Promise.all(args.map(async (arg) => addVerification({ ...arg, verificationType }))));
      }
    }

    if (verificationType === UserVerificationType.Mfa) fetchCurrentUser({ fromNetwork: true });

    if (![UserVerificationType.Mfa, UserVerificationType.Id, UserVerificationType.Face].includes(verificationType)) {
      await addVerification({ verificationType, token: code, userId: currentUser?.id || "" });
    }

    hideBottomSheet();
  };

  return (
    <AuthContainer stepDirection={stepDirection}>
      <AuthTitle>Identity Verification</AuthTitle>

      <ModalContainer>
        <AddModal
          title="Verify Email Address"
          description="Enter the code Letter sent to your email address."
          disabled={
            (!!isTriggeringVerification && isTriggeringVerification !== UserVerificationType.Email) ||
            onfidoIdStatus === "Problem"
          }
          onClick={() => !currentUser?.verified.email && handleTriggerVerification({ verificationType: UserVerificationType.Email })}
          complete={!!currentUser?.verified?.email}
          loading={
            !!isTriggeringVerification && isTriggeringVerification === UserVerificationType.Email ? true : undefined
          }
          dataCy={CypressTestIds.SIGN_UP_EMAIL_VERIFICATION_BTN}
        />
        <AddModal
          title="Verify Phone Number"
          description="Enter the code Letter sent to your phone number."
          disabled={
            (!!isTriggeringVerification && isTriggeringVerification !== UserVerificationType.Phone) ||
            onfidoIdStatus === "Problem"
          }
          onClick={() => !currentUser?.verified.phone && handleTriggerVerification({ verificationType: UserVerificationType.Phone })}
          complete={!!currentUser?.verified?.phone}
          loading={!!isTriggeringVerification && isTriggeringVerification === UserVerificationType.Phone}
          dataCy={CypressTestIds.SIGN_UP_PHONE_VERIFICATION_BTN}
        />
        <AddModal
          onClick={() => !currentUser?.verified.mfa && showBottomSheet("enable_mfa_sheet")}
          title="Enable MFA"
          description="Protect your account with Multi-Factor Authentication."
          disabled={!!isTriggeringVerification || onfidoIdStatus === "Problem"}
          complete={currentUser?.verified.mfa}
          dataCy={CypressTestIds.SIGN_UP_MFA_VERIFICATION_BTN}
        />
        <AddModal
          title="Upload a Photo ID"
          description={
            onfidoIdStatus === "Processing"
              ? "We're reviewing your ID..."
              : "Scan your face to verify your ID and protect your account."
          }
          disabled={
            !!isTriggeringVerification ||
            onfidoIdStatus === "Problem" ||
            !currentUser?.verified?.phone ||
            !currentUser?.verified?.email ||
            !currentUser?.verified?.mfa
          }
          onClick={() => showBottomSheet("onfido_sheet")}
          complete={!!currentUser?.verified?.id && onfidoIdStatus === "Approved"}
          loading={faceIdSubmitted ? true : undefined}
          reviewing={onfidoIdStatus === "Processing"}
          dataCy={CypressTestIds.SIGN_UP_ID_VERIFICATION_BTN}
        />
      </ModalContainer>

      <Button
        style={{marginTop: "-15px"}}
        color={ColorNames.TRANSPARENT}
        loading={true}
        children={<></>}
        hidden={!showFinalLoading}
      />

      <div />

      <ActionBottomSheet
        id="verify_email_sheet"
        title="Verify your email address to continue."
        description={`We've sent a verification code to your email at ${currentUser?.email}. Enter that code below.`}
        actionText="Verify"
        secondaryActionText="Resend Code"
        onAction={(code: string) => {
          handleAddVerification({ verificationType: UserVerificationType.Email, code });
        }}
        onSecondaryAction={(): Promise<void> => handleTriggerVerification({ verificationType: UserVerificationType.Email, resend: true })}
        codeInput
        dataCy={CypressTestIds.SIGN_UP_EMAIL_VERIFICATION_CODE_CONTAINER}
        autoSubmit
        actionLoading={addLoading}
        secondaryActionLoading={triggerLoading}
      />
      <ActionBottomSheet
        id="verify_phone_sheet"
        title="Verify your phone number to continue."
        description={`We've sent a verification code to your phone number ${currentUser?.phone}. Enter that code below.`}
        actionText="Verify"
        secondaryActionText="Resend Code"
        onAction={(code: string) => {
          handleAddVerification({ verificationType: UserVerificationType.Phone, code });
        }}
        onSecondaryAction={(): Promise<void> => handleTriggerVerification({ verificationType: UserVerificationType.Phone, resend: true })}
        codeInput
        dataCy={CypressTestIds.SIGN_UP_PHONE_VERIFICATION_CODE_CONTAINER}
        autoSubmit
        actionLoading={addLoading}
        secondaryActionLoading={triggerLoading}
      />

      <BottomSheet id="enable_mfa_sheet" noPadding>
        <EnableMfa onSuccess={() => handleAddVerification({ verificationType: UserVerificationType.Mfa })} />
      </BottomSheet>

      <BottomSheet fullHeight noPadding id="onfido_sheet">
        <Onfido
          onClose={() => {
            hideBottomSheet();
            setFaceIdSubmitted(true);
          }}
        />
      </BottomSheet>

      <BottomSheet id="onfido_problem_sheet" closeOnOutsideClick={false}>
        <SheetContainer>
          <SheetTitle>Manual Review</SheetTitle>
          <SheetDescription>
            We were not able to automatically approve your ID, and your ID is now under manual review. The review may
            take up to two days, please check back later. We'll be in touch with the results.
          </SheetDescription>
        </SheetContainer>
      </BottomSheet>

      <BottomSheet id="fraud_detected_sheet">
        <SheetContainer>
          <SheetTitle>We're Sorry</SheetTitle>
          <SheetDescription>
            Thank you for applying to open a Letter account. Unfortunately, we will not be able to open an account for
            you as we've detected potential fraud in your application. Your application has been cancelled.
            <br />
            <br />
            If you feel this result is in error, please contact us directly:{" "}
            <a href="mailto:help@letter.co">help@letter.co</a>
          </SheetDescription>
        </SheetContainer>
      </BottomSheet>
    </AuthContainer>
  );
};

export default VerifyIdentity;
