import React from "react";
import { useRecoilValue } from "recoil";
import { PanInfo } from "framer-motion";

import { showMenuState } from "src/store/menuState";
import { AppMenuSwipeHandlerContainer } from "./AppMenu.styles";

type PanEndEvent = MouseEvent | TouchEvent | PointerEvent;
export interface HandlerProps {
  onPanEnd: (e: PanEndEvent, pointInfo: PanInfo) => void;
}

export const AppMenuSwipeHandler = ({ onPanEnd }: HandlerProps): JSX.Element => {
  const show = useRecoilValue(showMenuState);

  return (
    <AppMenuSwipeHandlerContainer
      animate={{
        x: show ? 300 : 0
      }}
      transition={{
        ease: [0.215, 0.61, 0.355, 1]
      }}
      onPanStart={(e) => {
        e.preventDefault();
      }}
      onPanEnd={onPanEnd}
    />
  );
};
