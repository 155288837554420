import { ActionFunction, Params } from "react-router-dom";

import { DealTypes } from "src/types/deal";
import { investmentAction } from "./investment/_actions";
import { secondaryAction } from "./secondary/_actions";

export interface ActionInternalProps {
  request: Request,
  params: Params,
  formData: {
    [k: string]: FormDataEntryValue;
  }
}

export const listingAction: ActionFunction = async ({ request, params }) => {
  const formData = Object.fromEntries(await request.formData());
  const dealType = formData.dealType as DealTypes;
  
  switch (dealType) {
    case DealTypes.INVESTMENT:
      return await investmentAction({request, params, formData});
    case DealTypes.SECONDARY:
      return await secondaryAction({request, params, formData});
  }
};
