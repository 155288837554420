import React, { useEffect } from "react";
import { useFetcher } from "react-router-dom";
import { Formik } from "formik";

import { useFetchCurrentUser } from "src/services/users";
import { useCurrentOrg } from "src/services/organizations";
import { OrgType } from "src/generated/client";
import { NavRoutes } from "src/routes/navRoutes";
import { NavigationDirection } from "src/store/navigationState";

import { Button } from "src/components/button/Button";
import { ButtonGroup } from "src/components/button/Button.styles";
import { Input } from "src/components/forms/input/Input";
import { Select } from "src/components/forms/select/FormikSelect";
import {
  StyledForm,
  StyledFormInputContainer,
  InputRow,
} from "src/components/forms";
import { ColorNames } from "src/theme/theme";
import {
  useAddBusinessInfo,
  AddBusinessInfoData,
} from "src/services/organizations";
import { showNotice } from "src/store/alertState";
import useNavigate from "src/util/useNavigate";
import { CypressTestIds } from "src/util/testing-util/test-utils";
import { PageContent } from "./EditOrganization.styles";
import { useNavbar } from "src/util/useNavbar";
import Layout from "src/components/core-layout/Layout";
import { BannerNotice } from "src/components/alert/BannerNotice";
import { ReactComponent as IconAccredited } from "src/assets/icons/accredited.svg";

import States from "src/data/states.json";

type FormFields = {
  organizationName: string;
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
};

const statesForDropdown = [
  {
    label: "Choose state",
    value: "",
  },
  ...States,
];

const EditOrganization = (): JSX.Element | null => {
  useNavbar({ title: "Organization" });

  const { state: fetcherState, ...fetcher } = useFetcher();
  const currentOrg = useCurrentOrg();
  const { fetchCurrentUser } = useFetchCurrentUser();
  const {
    addBusinessInfo: updateOrganizationInformation,
    loading,
    error,
  } = useAddBusinessInfo();

  const navigate = useNavigate();

  useEffect(() => {
    if (!currentOrg) return;

    if (currentOrg.orgType === OrgType.Individual) {
      showNotice("This organization is not editable.", { error: true });
      navigate(NavRoutes.BANK_DASHBOARD, {
        direction: NavigationDirection.BACKWARD,
      });
    } else if (!currentOrg.accountHolder) {
      fetcher.load(
        `${NavRoutes.API_ORGANIZATION}?organizationId=${currentOrg?.id}`
      );
    }
  }, [currentOrg]);

  useEffect(() => {
    if (!!error)
      showNotice("There was a problem updating organization.", { error: true });
  }, [error]);

  const initialFormValues: FormFields = {
    organizationName: currentOrg?.name || "Personal",
    streetAddress: currentOrg?.streetAddress || "",
    city: currentOrg?.city || "",
    state: currentOrg?.state || "",
    zip: currentOrg?.zip || "",
  };

  const onUpdateOrganization = async (values: FormFields) => {
    const payload: AddBusinessInfoData = {
      orgType: currentOrg?.orgType || OrgType.Business,
      organizationId: currentOrg?.id,
      orgName: values.organizationName,
      streetAddress: values.streetAddress,
      city: values.city,
      state: values.state,
      zip: values.zip,
    };

    const updatedOrganization = await updateOrganizationInformation({
      values: payload,
    });

    if (!error && updatedOrganization) {
      fetchCurrentUser({ fromNetwork: true }); // this can be removed when the mutation returns the correct type
      showNotice("Organization updated.");
    }
  };

  return (
    <Layout loading={fetcherState === "loading"}>
      <PageContent>
        <Formik
          initialValues={initialFormValues}
          onSubmit={onUpdateOrganization}
        >
          {({ isSubmitting }): JSX.Element => (
            <StyledForm>
              <div style={{ width: "100%" }}>
                {!!currentOrg?.accredited && (
                  <BannerNotice
                    iconLeft={<IconAccredited />}
                    iconColor={ColorNames.BABY}
                    textColor={ColorNames.BABY}
                    titleColor={ColorNames.COBALT}
                    backgroundColor={ColorNames.POWDER}
                    title="Accredited Investor"
                  >
                    <>
                      Your accreditation status has been verified and confirmed.
                    </>
                  </BannerNotice>
                )}

                <StyledFormInputContainer>
                  <Input
                    name="organizationName"
                    type="input"
                    label="Name"
                    data-cy={CypressTestIds.ORGANIZATION_NAME}
                  />
                  <Input
                    name="streetAddress"
                    type="text"
                    label="Street Address"
                    autocomplete="shipping street-address"
                  />
                  <Input
                    name="city"
                    type="test"
                    label="City"
                    autocomplete="shipping locality"
                  />
                  <InputRow>
                    <Select
                      name="state"
                      label="State"
                      options={statesForDropdown}
                      autocomplete="shipping region"
                    />
                    <Input
                      name="zip"
                      type="test"
                      label="ZIP Code"
                      autocomplete="shipping postal-code"
                    />
                  </InputRow>
                </StyledFormInputContainer>
              </div>

              <ButtonGroup>
                <Button
                  raised
                  color={ColorNames.LAKE}
                  type="submit"
                  loading={isSubmitting || loading}
                  data-cy={CypressTestIds.UPDATE_ORGANIZATION_NAME_BTN}
                >
                  Save
                </Button>
                {/* <ActionableButton
                  label="Close Organization"
                  destructive
                  iconRight={false}
                  data-cy={CypressTestIds.CANCEL_ACCOUNT}
                  onClick={(): void => showBottomSheet("close_organization_sheet")}
                /> */}
              </ButtonGroup>
            </StyledForm>
          )}
        </Formik>
      </PageContent>
    </Layout>
  );
};

export default EditOrganization;
