import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useFetchCurrentUser } from "src/services/users";
import { useIsSessionValid, ValidSession } from "src/util/useIsSessionValid";
import { useAuthRedirect } from "src/services/auth";

import { StyledLandingPage, Logo, LogoContainer, LandingTitle, PartnerHeader, PartnerSheet } from "./Landing.styles";
import { BannerNotice } from "src/components/alert/BannerNotice";
import { ButtonGroup } from "src/components/button/Button.styles";
import { Button } from "src/components/button/Button";
import useNavigate from "src/util/useNavigate";
import { NavRoutes } from "src/routes/navRoutes";
import { ColorNames } from "src/theme/theme";

import Layout from "src/components/core-layout/Layout";
import FailedCreditCheck from "./FailedCreditCheck";
import { ReactComponent as IconCheck } from "src/assets/icons/check.svg";
import { storedCookieDataKey } from "src/util/localStorage";
import { useNavbar } from "src/util/useNavbar";
import { PartnershipType } from "src/generated/client";
import classNames from "classnames";

const Landing = (): JSX.Element => {
  useNavbar({ hidden: true, });
  const { validSession, loading: sessionLoading } = useIsSessionValid();
  const { fetchCurrentUser, userLoading } = useFetchCurrentUser();
  const [queryParams] = useSearchParams();
  const [cookies, setCookie, removeCookie] = useCookies([storedCookieDataKey.INVITE_CODE_PREFIX, storedCookieDataKey.PARTNERSHIP_PREFIX, storedCookieDataKey.CREDIT_CHECK_FAILED_PREFIX]);
  const [inviteCode, setInviteCode] = useState<string>();
  const [partnershipType, setPartnershipType] = useState<PartnershipType>();
  const [failedCreditCheck, setFailedCreditCheck] = useState(false);
  const navigate = useNavigate();
  const didLoad = useRef<boolean>(false);

  useAuthRedirect({
    invite: queryParams.get(storedCookieDataKey.INVITE_CODE_PREFIX) || cookies[storedCookieDataKey.INVITE_CODE_PREFIX]
  });

  useEffect(() => {
    if (!!inviteCode || sessionLoading) return;
    if (validSession === ValidSession.VALID) fetchCurrentUser();
  }, [inviteCode, validSession, sessionLoading, fetchCurrentUser]);

  useEffect(() => {
    if (didLoad.current) return; // only run once

    if (!!cookies[storedCookieDataKey.CREDIT_CHECK_FAILED_PREFIX]) {
      setFailedCreditCheck(true);
      removeCookie(storedCookieDataKey.CREDIT_CHECK_FAILED_PREFIX);
    }

    const invite = queryParams.get(storedCookieDataKey.INVITE_CODE_PREFIX);
    const inviteCookie = cookies[storedCookieDataKey.INVITE_CODE_PREFIX];

    if (!!invite) {
      setInviteCode(invite);

      if (!inviteCookie) {
        const expiration = new Date();
        expiration.setHours(expiration.getHours() + 2);
        setCookie(storedCookieDataKey.INVITE_CODE_PREFIX, invite, { expires: expiration });
      }
    } else if (!!inviteCookie) {
      setInviteCode(inviteCookie);
    }

    const partnership = queryParams.get(storedCookieDataKey.PARTNERSHIP_PREFIX);
    const partnershipCookie = cookies[storedCookieDataKey.PARTNERSHIP_PREFIX];
    if (!!partnership) {
      const match = Object.values(PartnershipType).find((value) => value.toLowerCase() === partnership.toLowerCase());

      if (!!match) {
        setPartnershipType(match);
        setCookie(storedCookieDataKey.PARTNERSHIP_PREFIX, match);
      }
    } else if (!!partnershipCookie) {
      setPartnershipType(partnershipCookie);
    }
    
    didLoad.current = true;
  }, [sessionLoading, validSession, cookies, queryParams, setCookie]);

  const openAccountAction = () => {
    // if (!partnershipType) {
      navigate(NavRoutes.SIGN_UP);
    // } else if (partnershipType === PartnershipType.TetonRidge) {
    //   navigate(NavRoutes.TETON_RIDGE_AUCTIONS);
    // }
  }

  if (failedCreditCheck) return <FailedCreditCheck />;

  return (
    <Layout loading={userLoading} noTopPadding noNavBar noInnerContentWidth={!!partnershipType}>
      <StyledLandingPage className={classNames({ "has-partner": !!partnershipType })}>
        {!partnershipType ? (
          <>
            <LogoContainer>
              <Logo />
              <LandingTitle>Beyond banking</LandingTitle>
            </LogoContainer>

            {inviteCode && (
              <BannerNotice iconLeft={<IconCheck />} textColor={ColorNames.CINNAMON} iconColor={ColorNames.GOLD}>
                <>You’ve been invited to join a Letter organization! Open a new account or sign in below to get started.</>
              </BannerNotice>
            )}

            <ButtonGroup>
              <Button onClick={(): void => navigate(NavRoutes.SIGN_UP)} color={ColorNames.MIDNIGHT} raised={true}>
                Open an Account
              </Button>
              <Button data-cy="signIn" onClick={(): void => navigate(NavRoutes.SIGN_IN)} color={ColorNames.TRANSPARENT}>
                Sign In
              </Button>
            </ButtonGroup>
          </>
        ) : (
          partnershipType === PartnershipType.TetonRidge && <>
            <PartnerHeader>
              <div className="partner-background">
                <img src="/partners/teton-ridge-bg.png" alt="Teton Ridge background" />
              </div>
              <img className="partner-logo" src="/partners/teton-ridge.png" alt="Teton Ridge" />
              <p>Teton Ridge has partnered with Letter to give you the best lending experience.</p>
            </PartnerHeader>
            <PartnerSheet>
              <div className="inner-content">
                <div className="letter-branding">
                  <Logo />
                  <p>Modern banking and thoughtful financial management.</p>
                </div>
                <ButtonGroup>
                  <Button onClick={openAccountAction} color={ColorNames.MIDNIGHT} raised={true}>
                    Open an Account
                  </Button>
                  <Button data-cy="signIn" onClick={(): void => navigate(NavRoutes.SIGN_IN)} color={ColorNames.TRANSPARENT}>
                    Sign In
                  </Button>
                </ButtonGroup>
              </div>
            </PartnerSheet>
          </>
        )}
      </StyledLandingPage>
    </Layout>
  );
};

export default Landing;
