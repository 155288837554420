import styled, { css, FlattenSimpleInterpolation } from "styled-components/macro";
import { colorPalette as colors, ColorNames, fonts, ThemeColor } from "src/theme/theme";

export const StyledDetailRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledDetailRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.dividerTop {
    border-top: 1px solid ${colors[ColorNames.GRAY2].hex};
    margin-top: 4px;
    padding-top: 20px;
  }
`;

export const DetailRowLabel = styled.div`
  ${fonts.PARAGRAPH_S}
  line-height: 16px;
  color: ${colors[ColorNames.GRAY5].hex};

  &.large {
    ${fonts.PARAGRAPH_M}
  }
`;
export const DetailRowData = styled.div<{ color?: ThemeColor }>`
  ${fonts.NUMBER_M}
  font-weight: 500;
  display: flex;
  align-items: center;

  ${({ color }): FlattenSimpleInterpolation | undefined => {
    if (color) {
      return css`
        span { color: ${colors[color].hex}; }
      `;
    }
  }}
  

  &.large {
    ${fonts.NUMBER_M}
  }

  .action-icon {
    margin-left: 0.5em;
    position: relative;
    top: 2px;
    cursor: pointer;
    color: ${colors[ColorNames.GRAY5].hex};

    svg {
      width: 22px;
      height: 22px;
    }
  }
`;
