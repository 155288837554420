import React from "react";
import styled from "styled-components";
import { colorPalette as colors, ColorNames } from "src/theme/theme";

const ElectronicDisclosure = styled.div`
  p {
    margin-bottom: 16px;
  }

  strong.block {
    display: block;
  }

  strong {
    display: block;
  }
  a {
    color: ${colors[ColorNames.MIDNIGHT].hex};
  }
`;

export default (
  <ElectronicDisclosure>
    <h1>Electronic Disclosure and Consent</h1>
    <p>
      In this agreement, &ldquo;we,&rdquo; &ldquo;us,&rdquo; &ldquo;our,&rdquo; and &ldquo;Letter&rdquo; mean Letter
      Technology, Inc. &ldquo;You&rdquo; and &ldquo;your&rdquo; refer to the business entity signing up for a Letter
      account or using a Letter product. &ldquo;Communications&rdquo; means disclosures, notices, agreements, fee
      schedules, privacy policies, statements, records, documents, and other information we provide to you, or that you
      sign and submit or agree to at our request. Letter is dedicated to providing the best online banking experience
      possible, including providing information to you electronically. By accepting this agreement, you agree that you
      are willing and able to receive Communications in electronic form, and consent to receive Communications in
      electronic form. If you do not give your consent to receive Communications in electronic and not paper form, you
      may not open a Letter account. If you withdraw your consent to electronic Communications at any point after
      providing consent hereunder, we reserve the right to terminate your Account.
    </p>
    <p>
      <strong className="block">Delivery Methods</strong>
      We will deliver Communications to you electronically, either through the Letter website{" "}
      <a href="https://letter.co">https://letter.co</a> (&ldquo;Website&rdquo;); the Letter mobile app
      (&ldquo;App&rdquo;); text or SMS messages (&ldquo;Text Messages&rdquo;); or through electronic mail
      (&ldquo;E-mail&rdquo;). If we do not deliver Communications to you through the above manners, we will tell you
      where you can go to receive such Communications. We may be required by law to deliver certain Communications to
      you on paper even though you have consented to receive it electronically.
    </p>
    <p>
      <strong className="block">Hardware and Software Requirements</strong>
      To receive and retain electronic Communications from Letter, you will need the following: A computer or mobile
      device with an operating system that supports everything below; An internet connection; A current version of a web
      browser that we support, including: Edge version 42 or higher, Firefox version 62 or higher, Safari version 12 or
      higher, or Chrome version 69 or higher; A hard drive or other method of storing data; A valid, active email
      address; and. A current version of a program that displays PDF files. We may update these requirements
      periodically in order to maintain our ability to provide electronic Communications; if these requirements change
      in a substantial way, we will notify you of the changes.
    </p>
    <p>
      <strong className="block">Updating Contact Information</strong>
      It is your responsibility to maintain accurate and current contact information so that we may send you electronic
      Communications. You can update your email address and other contact information by logging into your online
      account at <a href="https://letter.co">https://letter.co</a>, in the &ldquo;Settings&rdquo; section, or by
      emailing us at <a href="mailto:help@letter.co">help@letter.co</a>
    </p>
    <p>
      <strong className="block">Requesting Paper Copies</strong>
      We may choose to make paper copies of certain electronic Communications available upon request, but assume no
      obligation to do so. You may obtain a paper copy of an electronic Communication by printing it or by requesting we
      mail a paper copy. You may make requests for paper copies by emailing us at{" "}
      <a href="mailto:help@letter.co">help@letter.co</a>
    </p>
    <p>
      <strong className="block">Communications in Writing</strong>
      We are required by law to give you certain information in writing. All Communications delivered to you in either
      electronic or paper format will be taken as information delivered &ldquo;in writing.&rdquo;
    </p>
    <p>
      <strong>Withdrawing Consent</strong> You have the right to withdraw your consent to this agreement at any time.
      Withdrawing consent will terminate your Letter account, including access to our Website and Mobile App. A balance
      refund check may be issued you upon account termination in accordance with this Agreement. You may withdraw
      consent by emailing us at <a href="mailto:help@letter.co">help@letter.co</a>
    </p>
  </ElectronicDisclosure>
);
