import React from "react";

import { LoadingBox } from "src/components/loading-container/LoadingContainer.styles";
import { BrandColor, ThemeColor, ColorNames } from "src/theme/theme";
import { DataTestIds } from "src/util/testing-util/test-utils";
import { GlobalSvgs } from "../global-svgs/GlobalSvgs";
import { LogoSpinner } from "../logo-spinner/LogoSpinner";

export interface LoadingContainerProps {
  color?: BrandColor;
  backgroundColor?: ThemeColor | ColorNames.TRANSPARENT;
  height?: string | null;
}

export const LoadingContainer = (props: LoadingContainerProps): JSX.Element => {
  const { color = ColorNames.MIDNIGHT, backgroundColor = ColorNames.TRANSPARENT, height = null } = props;

  return (
    <LoadingBox height={height} backgroundColor={backgroundColor} data-testid={DataTestIds.LOADING_CONTAINER}>
      <GlobalSvgs />
      <LogoSpinner color={color} />
    </LoadingBox>
  );
};
