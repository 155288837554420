import { gql } from "@apollo/client";
import { connectedAccountFragment } from "src/services/gql/_fragments";

export const CONNECT_CONNECTED_ACCOUNTS = gql`
  mutation ConnectConnectedAccountProvider($input: ConnectedAccountConnectInput!) {
    connectConnectedAccountProvider(input: $input) {
      expectedNewAccountsNumber
      expectedNewAccountsData {
        id
        type
        subType
        organizationId
        userId
        availableBalance {
          display
          amount
        }
        totalBalance {
          display
          amount
        }
        accountNumber
        routingNumber
        last4
        providerAccountId
        providerEnrollmentId
        connectedInstitutionName
        connectedInstitutionLogo
        connectedInstitutionId
        provider
        nickname
        name
        status
        updatedAt
        createdAt
        selected
      }
    }
  }
`;

export const GET_CONNECTED_TOKEN = gql`
  mutation GetConnectedToken($input: ProviderTokenInput!) {
    getConnectedToken(input: $input) {
      token
    }
  }
`;

export const REFRESH_CONNECTED_ACCOUNT = gql`
  mutation refreshConnectedAccount($accountId: String!) {
    refreshConnectedAccount(accountId: $accountId) {
      nickname
      type
      last4
      id
      accountNumber
      routingNumber
      status
      providerEnrollmentId
      updatedAt
      balance {
        display
        amount
      }
    }
  }
`;

export const REFRESH_CONNECTED_ACCOUNTS = gql`
  mutation refreshConnectedAccounts {
    refreshConnectedAccounts {
      success
    }
  }
`;

export const GET_UPDATE_TOKEN = gql`
  query connectedUpdateToken($enrollmentId: String!) {
    connectedUpdateToken(enrollmentId: $enrollmentId) {
      token
    }
  }
`;

export const UPDATE_CONNECTED_ACCOUNT = gql`
  mutation EditConnectedAccount($accountId: String!, $nickname: String!) {
    editConnectedAccount(input: { accountId: $accountId, nickname: $nickname }) {
      nickname
    }
  }
`;

export const DISCONNECT_CONNECTED_ACCOUNT = gql`
  mutation DisconnectConnectedAccount($input: DisconnectConnectedAccountInput!) {
    disconnectConnectedAccount(input: $input) {
      success
    }
  }
`;

export const GET_CONNECTED_ACCOUNTS = gql`
  query ConnectedAccountsList(
    $timeframe: BalancesTimeframe
    $groupBy: BalancesGrouping
    $moveMoneyCapable: Boolean
    $selectedId: String
  ) {
    connectedAccountsList(connectedParams: { moveMoneyCapable: $moveMoneyCapable, selectedId: $selectedId }) {
      connectedAccounts {
        ...FullConnectedAccountData
      }
      flaggedEnrollmentsList {
        error
        consentExpirationTime
        updateType
        transactions
      }
    }
  }
  ${connectedAccountFragment}
`;

export const GET_CONNECTED_ACCOUNT_BALANCE_BY_ID = gql`
  query ConnectedAccountBalances($accountId: String!, $timeframe: BalancesTimeframe, $groupBy: BalancesGrouping) {
    connectedAccount(accountId: $accountId) {
      balances(timeframe: $timeframe, groupBy: $groupBy) {
        period
        balance {
          amount
          display
        }
      }
    }
  }
`;

export const GET_CONNECTED_ACCOUNT_BY_ID = gql`
  query ConnectedAccount($accountId: String!) {
    connectedAccount(accountId: $accountId) {
      id
      type
      subType
      organizationId
      userId
      availableBalance {
        display
        amount
      }
      totalBalance {
        display
        amount
      }
      accountNumber
      routingNumber
      last4
      providerAccountId
      providerEnrollmentId
      connectedInstitutionName
      connectedInstitutionLogo
      connectedInstitutionId
      provider
      nickname
      name
      status
      updatedAt
      createdAt
      selected
    }
  }
`;
