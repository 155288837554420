import React, { useEffect, useRef, useState } from "react";
import { useReactiveVar } from "@apollo/client";
import { Form, useParams, useFetcher, useActionData, useSubmit, useNavigation, generatePath } from "react-router-dom";

import { useNavbar } from "src/util/useNavbar";
import useNavigate from "src/util/useNavigate";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { showNotice } from "src/store/alertState";
import { NavigationDirection } from "src/store/navigationState";
import { NavRoutes } from "src/routes/navRoutes";
import { ColorNames } from "src/theme/theme";
import { Line, LineOriginationStatus } from "src/generated/client";
import { RouteActionResponse } from "src";
import { docusignSigningCompleted } from "src/store/deviceState";

import { StepHeader } from "src/components/steps/StepsContainer.styles";
import {
  QualifyCaption,
  InsuranceSheetTitle,
  InsuranceTypeButton,
  InsuranceSheetDescription,
  LoanDocSheetContainer,
  LoanDocSheetHeader,
  FinalizeContainer
} from "../OneLine.styles";
import { ActionSheetCenteredContainer } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet.styles";

import Layout from "src/components/core-layout/Layout";
import { LabelInput } from "src/components/forms/input/LabelInput";
import { StepsContainer } from "src/components/steps/StepsContainer";
import { AddModal } from "src/components/cta/add-modal/AddModal";
import { Button } from "src/components/button/Button";
import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import { BottomSheet } from "src/components/bottom-sheets/BottomSheet";
import { LoadingContainer } from "src/components/loading-container/LoadingContainer";
import { ReactComponent as IconClick } from "src/assets/icons/click.svg";
import { ReactComponent as IconUpload } from "src/assets/icons/upload.svg";
import { ReactComponent as IconClose } from "src/assets/icons/close.svg";

const FinalizeLine = () => {
  const { data: line, state: loadState, load } = useFetcher<Line>();
  const { data: docData, state: loadingDocState, load: loadDoc } = useFetcher();
  const { data: uploadData, state: loadingUploadState, submit: upload } = useFetcher();
  const actionData = useActionData() as RouteActionResponse<any>;
  const { state: formState } = useNavigation();
  const submit = useSubmit();

  const { lineId } = useParams();
  const [proofFile, setProofFile] = useState<Blob>();
  const [proofFileName, setProofFileName] = useState("");
  const signingComplete = useReactiveVar(docusignSigningCompleted);
  const [signed, setSigned] = useState(false);
  const navigate = useNavigate();
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();
  const formRef = useRef(null);
  const didLoadLine = useRef(false);

  useNavbar({
    title: "",
    backAction: () =>
      navigate(NavRoutes.ONELINE_DASHBOARD, {
        direction: NavigationDirection.BACKWARD
      })
  });

  // fetch line
  useEffect(() => {
    if (!line && loadState === "idle") load(`${NavRoutes.API_LINE}?lineId=${lineId}`);

    if (!!line && !didLoadLine.current) {
      if (line.finalized) {
        navigate(generatePath(NavRoutes.ONELINE_LINE_DETAIL, { lineId: line.id, section: "overview" }), { direction: NavigationDirection.REPLACE });
      } else if (![LineOriginationStatus.LoanDocsSigned, LineOriginationStatus.PurchaseComplete, LineOriginationStatus.LoanFunded].includes(line.originationStatus)) {
        navigate(generatePath(NavRoutes.ONELINE_EDIT_LINE, { lineId: line.id }), { direction: NavigationDirection.REPLACE });
      } else {
        setSigned(line.originationStatus === LineOriginationStatus.ClosingDocumentsSigned);
      }
      didLoadLine.current = true;
    }
  }, [line, loadState, load]);

  // submit form to finish finalizing
  useEffect(() => {
    if (line?.proofOfInsuranceUploaded && signed) submit(formRef.current);
  }, [line?.proofOfInsuranceUploaded, signed]);

  // got a docusign url
  useEffect(() => {
    if (docData?.url) {
      showBottomSheet("pledge_doc_sheet");
    } else if (docData?.errors) {
      showNotice("There was a problem generating your pledge agreement.", { error: true });
    }
  }, [docData]);

  // validation errors with insurance upload
  useEffect(() => {
    if (!!uploadData?.validationErrors && Object.keys(uploadData.validationErrors).length > 0) {
      const firstError = Object.values(uploadData.validationErrors)[0] as string;
      showNotice(firstError, { error: true });
    }
  }, [uploadData?.validationErrors]);

  // errors with insurance upload
  useEffect(() => {
    if (!!uploadData?.error) {
      const errorMessage = uploadData.error.message || "There was a problem uploading your proof of insurance.";
      showNotice(errorMessage, { error: true });
    }
  }, [uploadData?.error]);

  // successful insurance upload
  useEffect(() => {
    if (!!uploadData?.response) hideBottomSheet("insurance_type_sheet");
  }, [uploadData?.response, hideBottomSheet]);

  // error with form submission
  useEffect(() => {
    if (!!actionData?.error) {
      const errorMessage = actionData.error.message || "There was a problem uploading your proof of insurance.";
      showNotice(errorMessage, { error: true });
    }
  }, [actionData?.error]);

  // successfully finalized
  useEffect(() => {
    if (!!actionData?.response && actionData.response.success === true) {
      showBottomSheet("loan_done_sheet");
    }
  }, [actionData?.response]);

  // doc signing complete
  useEffect(() => {
    if (signingComplete) {
      setSigned(true);
      hideBottomSheet("pledge_doc_sheet");
      docusignSigningCompleted(false);
    }
  }, [signingComplete]);

  const showInsuranceSheet = () => {
    if (!line?.originationStatus) return;

    if (![LineOriginationStatus.PurchaseComplete, LineOriginationStatus.LoanFunded].includes(line.originationStatus)) {
      return showBottomSheet("purchase_waiting_sheet");
    }

    if (!line?.proofOfInsuranceUploaded) showBottomSheet("insurance_type_sheet");
  };

  const showPledgeSheet = () => {
    if (!line?.originationStatus) return;

    if (![LineOriginationStatus.PurchaseComplete, LineOriginationStatus.LoanFunded].includes(line.originationStatus)) {
      return showBottomSheet("purchase_waiting_sheet");
    }

    if (!!docData?.url) showBottomSheet("pledge_doc_sheet");
    else loadDoc(`${NavRoutes.API_LINE}?get=lineDoc&docType=ClosingDocuments&lineId=${line?.id}`);
  };

  const uploadInsuranceProof = () => {
    const formData = new FormData();
    formData.append("lineId", line?.id || "");
    formData.append("insuranceProof", proofFile || "");
    upload(formData, { method: "put", encType: "multipart/form-data" });
  };

  return (
    <Layout loading={!line}>
      <FinalizeContainer>
        <StepHeader>Finalize Loan</StepHeader>
        <Form method="post" ref={formRef}>
          <AddModal
            title="Insurance"
            description="Get or provide proof of insurance for your purchase."
            onClick={showInsuranceSheet}
            complete={line?.proofOfInsuranceUploaded}
            loading={formState === "submitting"}
          />
          <AddModal
            title="Sign Pledge Agreement"
            description="Read and sign the loan docs to open your new line."
            onClick={showPledgeSheet}
            complete={signed}
            loading={loadingDocState === "loading" || formState === "submitting"}
          />

          {signed && (
            <>
              <input type="hidden" name="lineId" value={line?.id} />
              <input type="hidden" name="documentId" defaultValue={docData?.envelopeId} />
            </>
          )}
          <input type="hidden" name="step" value="sign" />

          {formState === "submitting" && <LoadingContainer height="62px" />}
        </Form>
      </FinalizeContainer>

      <ActionBottomSheet
        id="insurance_type_sheet"
        title="Upload Proof of Mortality Insurance"
        description="You are required to upload your proof of insurance within 3 days of finalizing this loan, or else your loan will be in default."
        actionText="Upload Now"
        actionLoading={loadingUploadState === "submitting"}
        onAction={uploadInsuranceProof}
        secondaryActionText="Close"
        onSecondaryAction={() => {
          hideBottomSheet("insurance_type_sheet");
        }}
        children={
          <>
            {/* <InsuranceTypeButton onClick={() => undefined}>
            <div className="body-text-area">
              <h4>Teton Ridge</h4>
              <p>Get insurance with a single tap/click.</p>
            </div>
            <div className="icon-area teton-ridge">
              <IconClick />
            </div>
          </InsuranceTypeButton> */}
            {/* <InsuranceTypeButton onClick={() => undefined}>
            <div className="body-text-area">
              <h4>Upload Insurance Proof</h4>
              <p>Upload proof of insurance from another provider.</p>
            </div>
            <div className="icon-area upload">
              <IconUpload />
            </div>
          </InsuranceTypeButton> */}

            <LabelInput
              fileName={proofFileName}
              name="insuranceProof"
              label="Proof of Insurance"
              placeholderLabel="Choose a file"
              type="file"
              accept=".jpg, .jpeg, .png, .pdf"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setProofFileName(event.target.files ? event.target.files[0].name : "");
                setProofFile(event.target.files ? event.target.files[0] : undefined);
              }}
            />
          </>
        }
      />

      <BottomSheet
        id="pledge_doc_sheet"
        fullHeight
        noPadding
        hasHeader
        closeOnOutsideClick={false}
        children={
          <LoanDocSheetContainer>
            <LoanDocSheetHeader>
              <span>Sign Pledge Agreement</span>
              <IconClose onClick={() => hideBottomSheet("pledge_doc_sheet")} />
            </LoanDocSheetHeader>
            <iframe
              width="100%"
              height="100%"
              style={{ border: "none", flexGrow: "1" }}
              title="loan-doc-iframe"
              src={docData?.url}
            />
          </LoanDocSheetContainer>
        }
      />

      <ActionBottomSheet
        id="purchase_waiting_sheet"
        title="Waiting for Purchase Confirmation"
        actionText="Close"
        actionColor={ColorNames.TRANSPARENT}
        onAction={(): void => {
          hideBottomSheet("purchase_waiting_sheet");
        }}
        children={
          <ActionSheetCenteredContainer>
            <QualifyCaption large children="We haven't received confirmation of your purchase from the auction." />
            <QualifyCaption
              gray
              children="Once the auction sends us your purchase confirmation, we'll notify you, so you can finalize your loan."
            />
          </ActionSheetCenteredContainer>
        }
      />

      <ActionBottomSheet
        id="loan_done_sheet"
        success
        title="You're all done!"
        actionText="Close"
        actionColor={ColorNames.TRANSPARENT}
        onAction={() => {
          hideBottomSheet("loan_done_sheet");
          navigate(NavRoutes.ONELINE_DASHBOARD);
        }}
        onClose={() => {
          navigate(NavRoutes.ONELINE_DASHBOARD);
        }}
        children={
          <ActionSheetCenteredContainer>
            Your new loan is now active. Thank you and congratulations on your purchase!
          </ActionSheetCenteredContainer>
        }
      />
    </Layout>
  );
};

export default FinalizeLine;
