import React, { useRef } from "react";

import { StyledInput, FileContainer, PreviewContainer, ImagePreview } from "src/components/forms/input/Input.styles";

import { StyledFieldContainer } from "src/components/forms";
import { StyledLabelContainer, StyledLabel, StyledPlaceholderLabel } from "src/components/text";
import { DataTestIds } from "src/util/testing-util/test-utils";
import { ReactComponent as Upload } from "src/assets/icons/upload.svg";
import { ReactComponent as Check } from "src/assets/icons/check.svg";

export interface InputProps {
  label?: string;
  name: string;
  type: string;
  value?: string;
  error?: boolean;
  disabled?: boolean;
  style?: object;
  [key: string]: unknown;
}

export const LabelInput = ({
  label,
  name,
  type,
  validationErrors,
  value,
  error,
  disabled,
  style,
  ...props
}: InputProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>();

  return (
    <StyledFieldContainer
      onClick={(): void => type === "file" ? inputRef?.current?.click() : inputRef?.current?.focus()}
      error={error}
      data-testid={DataTestIds.FIELD_CONTAINER}
      type={type}
      style={style}
    >
      <StyledLabelContainer>
        {label && (
          <StyledLabel htmlFor={name} error={error} data-testid={DataTestIds.LABEL}>
            {label}
          </StyledLabel>
        )}
        {type === "file" && !!props.placeholderLabel && (
          <StyledPlaceholderLabel isPlaceholder={!props.fileName || props.fileName === ""}>
            <>
              {props.fileName ? (props.fileName as string).replace(/C:\\fakepath\\/, "") : props.placeholderLabel}
            </>
          </StyledPlaceholderLabel>
        )}
      </StyledLabelContainer>

      <StyledInput
        ref={inputRef}
        type={type}
        name={name}
        defaultValue={value}
        data-testid={DataTestIds.INPUT}
        disabled={disabled}
        {...props}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (!!props.onChange) (props.onChange as any)(e);
        }}
      />

      {type === "file" && (
        <FileContainer>
          <PreviewContainer>{!!props.fileName ? <Check /> : <Upload />}</PreviewContainer>
        </FileContainer>
      )}
    </StyledFieldContainer>
  );
};
