import React, { useState, useEffect, useMemo } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { showNotice } from "src/store/alertState";

import { useCurrentUser } from "src/services/users";

import { useCookies } from "react-cookie";
import { AuthContainer } from "src/pages/auth/Auth";
import { AuthTitle } from "src/pages/auth/Auth.styles";
import { Button } from "src/components/button/Button";
import { Input } from "src/components/forms/input/Input";
import { Select } from "src/components/forms/select/FormikSelect";
import { Form } from "src/components/forms/Form";
import { StyledFormInputContainer } from "src/components/forms";
import { useEditUser, CreateUserData } from "src/services/users";
import { formatPhoneNumber } from "src/util/stringUtils";
import { DataTestIds, CypressTestIds } from "src/util/testing-util/test-utils";
import NetWorth from "src/data/netWorth.json";
import { NewPasswordInput } from "src/components/forms/new-password-input/NewPasswordInput";
import { MINIMUM_PASSWORD_STRENGTH_SCORE } from "src";
import { storedCookieDataKey } from "src/util/localStorage";
import { PartnershipType } from "src/generated/client";

const SignUpSchema: Yup.ObjectSchema<object> = Yup.object().shape({
  firstName: Yup.string().required("Please add your first name."),
  lastName: Yup.string().required("Please add your last name."),
  phone: Yup.string().min(14, "Please use a valid phone number.").required("Please add your phone."),
  netWorth: Yup.string().required("Please choose a net worth."),
  email: Yup.string()
    .email("Please use a valid email address.")
    .when("showEmail", {
      is: true,
      then: Yup.string().required("Please add your email.")
    }),
  password: Yup.string()
    .min(8, "Please use a longer password.")
    .max(100, "Please use a shorter password.")
    .required("Please add a password.")
});

const netWorthOptions = [...NetWorth];

const AccountInfo = ({
  nextStep,
  stepDirection,
  inviteCode
}: {
  nextStep: (values?: CreateUserData, setSubmitting?: (submitting: boolean) => void) => void;
  stepDirection: number;
  inviteCode?: string;
}): JSX.Element => {
  const currentUser = useCurrentUser();
  const { edit, loading, error } = useEditUser(!currentUser);
  const [prevPhoneValue, setPrevPhoneValue] = useState("");
  const [passwordScore, setPasswordScore] = useState(0);
  const [cookies, setCookie, removeCookie] = useCookies([storedCookieDataKey.PARTNERSHIP_PREFIX]);
  const partnershipCookie = cookies[storedCookieDataKey.PARTNERSHIP_PREFIX];
  const isTetonRidge = partnershipCookie && partnershipCookie === PartnershipType.TetonRidge;

  useEffect(() => {
    if (error) {
      showNotice(error.message || "There was a problem adding your info.", { error: true });
    }
  }, [error]);

  const initialFormValues: CreateUserData = useMemo(() => {
    return {
      firstName: currentUser?.firstName || "",
      lastName: currentUser?.lastName || "",
      phone: currentUser?.phone ? formatPhoneNumber(currentUser.phone) : "",
      netWorth: currentUser?.netWorth || netWorthOptions[0].value,
      showEmail: !inviteCode,
      email: currentUser?.email || "",
      password: currentUser ? "1Xxxxxxxxxxxxxxxx" : ""
    };
  }, [currentUser, inviteCode]);

  const handleEditUser = async (
    values: CreateUserData,
    setSubmitting: (submitting: boolean) => void
  ): Promise<void> => {
    if (passwordScore < MINIMUM_PASSWORD_STRENGTH_SCORE && !currentUser) {
      setSubmitting(false);
      showNotice("Please use a stronger password.", { error: true });
      return;
    }

    if (!currentUser) {
      const newUserPayload = { ...values };

      if (!!inviteCode) delete newUserPayload.email;

      nextStep(newUserPayload, setSubmitting);
      return;
    }

    const payload = { ...values, userId: currentUser.id };
    delete payload.password;

    const user = await edit(payload);

    if (user) nextStep();
    setSubmitting(false);
  };

  const handlePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>): string => {
    const formattedNumber = formatPhoneNumber(event.target.value, prevPhoneValue);
    setPrevPhoneValue(formattedNumber);
    return formattedNumber;
  };

  const updatePasswordScore = (data: number) => {
    setPasswordScore(data);
  };

  return (
    <AuthContainer data-testid={DataTestIds.SIGN_UP} stepDirection={stepDirection}>
      <AuthTitle>
        {currentUser ? "Complete your" : "Open a new"}
        <br />
        Letter Account
      </AuthTitle>

      <Formik
        initialValues={initialFormValues}
        enableReinitialize={true}
        validationSchema={SignUpSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { setSubmitting }): void => {
          handleEditUser(values, setSubmitting);
        }}
      >
        {({ isSubmitting, handleChange, setFieldValue }): JSX.Element => (
          <Form>
            <>
              <StyledFormInputContainer>
                <Input
                  name="firstName"
                  data-cy={CypressTestIds.SIGN_UP_FIRST_NAME}
                  type="text"
                  label="First Name"
                  autoComplete="given-name"
                />
                <Input
                  name="lastName"
                  data-cy={CypressTestIds.SIGN_UP_LAST_NAME}
                  type="text"
                  label="Last Name"
                  autoComplete="family-name"
                />
                <Input
                  name="phone"
                  type="text"
                  label="U.S. Phone Number"
                  data-cy={CypressTestIds.SIGN_UP_PHONE}
                  autoComplete="tel"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                    handleChange(event);
                    setFieldValue("phone", handlePhoneNumber(event));
                  }}
                />
                
                <Select
                  data-cy={CypressTestIds.SIGN_UP_NET_WORTH}
                  name="netWorth"
                  label="Net Worth"
                  options={netWorthOptions}
                  hidden={isTetonRidge}
                />

                {!inviteCode && (
                  <Input
                    data-cy={CypressTestIds.SIGN_UP_EMAIL}
                    name="email"
                    type="email"
                    label="Email"
                    autoComplete="email"
                    disabled={!!currentUser}
                  />
                )}

                <NewPasswordInput
                  data-cy={CypressTestIds.SIGN_UP_PASSWORD}
                  name="password"
                  type="password"
                  label="Password"
                  autoComplete="new-password"
                  disabled={!!currentUser}
                  updateFormScore={updatePasswordScore}
                />
              </StyledFormInputContainer>

              <Button
                raised
                type="submit"
                loading={isSubmitting || loading}
                data-cy={CypressTestIds.CONTINUE_SIGN_IN_BTN}
              >
                Continue
              </Button>
            </>
          </Form>
        )}
      </Formik>
    </AuthContainer>
  );
};

export default AccountInfo;
