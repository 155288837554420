import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { AppPage } from "src/routes/app/App.styles";
import useNavigate from "src/util/useNavigate";
import { useOrganizationUsers } from "src/services/organizations";
import { User } from "src/generated/client";

import Layout from "src/components/core-layout/Layout";
import Cards from "src/routes/app/bank/cards/Cards";
import { useNavbar } from "src/util/useNavbar";

const MemberCards = () => {
  const { updateNavbar } = useNavbar({ back: true });
  const { memberId } = useParams();
  const navigate = useNavigate();

  const { users } = useOrganizationUsers({ userId: memberId });
  const [user, setUser] = useState<Partial<User>>();

  useEffect(() => {
    if (users && memberId) {
      const selectedUser = users.find(({ selected }) => selected);
      if (selectedUser) {
        if (selectedUser.cards.length === 0) {
          navigate("back");
          return;
        }
        setUser(selectedUser);
      } else {
        navigate("back");
      }
    }
  }, [memberId, users, navigate]);

  useEffect(() => {
    if (!user) return;
    updateNavbar({ title: `${user?.firstName} ${user?.lastName}'s Cards`, back: true });
  }, [user, updateNavbar]);

  return (
    <Layout loading={!user} noInnerContentWidth>
      <AppPage>
        <Cards cards={user?.cards || []} />
      </AppPage>
    </Layout>
  );
};

export default MemberCards;
