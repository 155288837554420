// index.tsx
import React from "react";
import { createRoot } from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "@apollo/client";
import graphqlClient from "src/graphqlClient";

import { RecoilRoot } from "recoil";
import { ThemeProvider } from "styled-components";
import { CookiesProvider } from "react-cookie";

import { Router } from "src/Router";
import Theme, { DefaultTheme } from "src/theme/theme";
import { GlobalStyle } from "src/theme/globalStyles";
import "./theme/fonts.css";
import "./theme/keyframes.css";

import * as Sentry from "@sentry/browser";
import * as Fullstory from "@fullstory/browser";

const { REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_ENV, REACT_APP_FULLSTORY_ORGID, REACT_APP_FULLSTORY_ENV } = process.env;

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  environment: REACT_APP_SENTRY_ENV
});

Fullstory.init({
  orgId: REACT_APP_FULLSTORY_ORGID || "",
  devMode: REACT_APP_FULLSTORY_ENV !== "production"
});

// have to brute force these types until @types/styled-components is updated with react 18 support
const ThemeProviderFixed = ThemeProvider as unknown as React.FC<React.PropsWithChildren<{ theme: DefaultTheme }>>;
const GlobalStyleFixed = GlobalStyle as unknown as React.FC<React.PropsWithChildren<Record<string, never>>>;

export const client = graphqlClient;

export const MINIMUM_PASSWORD_STRENGTH_SCORE = 3;

export interface RouteActionResponse<T> {
  response?: T;
  validationErrors?: {
    [key: string]: string;
  };
  error?: Error;
}

const Root = () => {
  return (
    <ApolloProvider client={client}>
      <ThemeProviderFixed theme={Theme}>
        <CookiesProvider>
          <GlobalStyleFixed />
          <Router />
        </CookiesProvider>
      </ThemeProviderFixed>
    </ApolloProvider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <RecoilRoot>
    <Root />
  </RecoilRoot>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
