import React, { useState, useEffect, useMemo, useRef } from "react";
import { useReactiveVar } from "@apollo/client";
import { AnimatePresence } from "framer-motion";
import { useCookies } from "react-cookie";
import debounce from "lodash/debounce";
import { useActionData, useNavigation, Form, useFetcher, useParams, useSubmit, generatePath } from "react-router-dom";

import { useCurrentOrg } from "src/services/organizations";
import { useGetConnectedAccounts } from "src/services/connected-accounts";
import { useGetAccounts } from "src/services/accounts";
import { useNavbar } from "src/util/useNavbar";
import useNavigate from "src/util/useNavigate";
import { showNotice } from "src/store/alertState";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { NavRoutes } from "src/routes/navRoutes";
import { ColorNames } from "src/theme/theme";
import { NavigationDirection } from "src/store/navigationState";
import { RouteActionResponse } from "src";
import {
  Account,
  AccountRef,
  EntityTopic,
  Line,
  LineOriginationStatus,
  LineType,
  LoanType,
  useCalculateLineTermsMutation,
  UpcomingAuction,
  ConnectedAccount
} from "src/generated/client";
import { decorateCurrencyValue, formatCentsToString, formatDateShort } from "src/util/stringUtils";
import { storedCookieDataKey } from "src/util/localStorage";
import { docusignSigningCompleted } from "src/store/deviceState";

import { StepHeader, StepParagraph, StepDownPaymentHeader } from "src/components/steps/StepsContainer.styles";
import { LoanDocSheetContainer, LoanDocSheetHeader } from "../OneLine.styles";
import { InfoGroup } from "src/routes/app/bank/accounts/Accounts.styles";
import { InfoListItemsWrapper } from "src/components/info-list-item/InfoListItem.styles";
import { ActionSheetCenteredContainer } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet.styles";
import { ActionableButtonGroup } from "src/components/actionable-button/ActionableButton.styles";

import Layout from "src/components/core-layout/Layout";
import { StepsContainer } from "src/components/steps/StepsContainer";
import { LoadingSkeleton } from "src/components/loading-skeleton/LoadingSkeleton";
import { Button } from "src/components/button/Button";
import { BottomSheet } from "src/components/bottom-sheets/BottomSheet";
import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import AmortizationSheet from "src/components/bottom-sheets/amortization-sheet/AmortizationSheet";
import BankAccountDetailsSheet from "src/components/bottom-sheets/bank-account-details-sheet/BankAccountDetailsSheet";
import { DetailSelectAccount } from "src/components/forms/detail-select/DetailSelectAccount";
import { SliderCurrencyInput } from "src/components/forms/currency-input/SliderCurrencyInput";
import { Select } from "src/components/forms/select/Select";
import { SegmentedControl } from "src/components/segmented-control/SegmentedControl";
import { InfoListItem } from "src/components/info-list-item/InfoListItem";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { DetailRow } from "src/components/detail-row/DetailRow";
import { AddModal } from "src/components/cta/add-modal/AddModal";
import { BannerNotice } from "src/components/alert/BannerNotice";
import { ReactComponent as IconPopup } from "src/assets/icons/popup.svg";
import { ReactComponent as IconInfo } from "src/assets/icons/info.svg";
import { ReactComponent as IconClose } from "src/assets/icons/close.svg";
import { ReactComponent as IconMore } from "src/assets/icons/more.svg";
import { ReactComponent as IconChevron } from "src/assets/icons/chevron_right.svg";
import { LoadingContainer } from "src/components/loading-container/LoadingContainer";

const MINIMUM_LOAN_AMOUNT = 1000000;

enum step {
  AMOUNT,
  REPAYMENT,
  INSURANCE,
  PRE_APPROVAL,
  DOWN_PAYMENT,
  SIGN
}

const LoanTypeDisplays: Record<LoanType, string> = {
  [LoanType.FullyAmortized]: "Fully Amortized"
};

const CreateLine = () => {
  const currentOrg = useCurrentOrg({ fullFetch: true });

  const { data: line, state: loadingState, load } = useFetcher<Line>();
  const { data: lineType, state: lineTypeLoadingState, load: loadLineType } = useFetcher<LineType>();
  const { data: cancelData, state: cancelState, submit } = useFetcher();
  const actionData = useActionData() as RouteActionResponse<any>;

  const { lineTypeId, lineId } = useParams();
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();
  const didLoad = useRef(false);

  const { accounts, refetch: refetchAccounts } = useGetAccounts([AccountRef.Checking]);

  const [steps] = useState<step[]>([
    step.AMOUNT,
    step.REPAYMENT,
    step.INSURANCE,
    step.PRE_APPROVAL,
    step.DOWN_PAYMENT,
    step.SIGN
  ]);
  const [currentStep, setCurrentStep] = useState<number>(-1);
  const [stepDirection, setStepDirection] = useState(1);
  const navigate = useNavigate();

  const { updateNavbar } = useNavbar({
    stepper: true,
    totalSteps: steps.length
  });

  useEffect(() => {
    if (!line && !!lineId && loadingState === "idle") load(`${NavRoutes.API_LINE}?lineId=${lineId}`);
  }, [line, loadingState, load, lineId]);

  useEffect(() => {
    if (!lineType && !!lineTypeId && lineTypeLoadingState === "idle") {
      loadLineType(`${NavRoutes.API_LINE}?get=lineType&lineTypeId=${lineTypeId}`);
    }
  }, [lineType, lineTypeLoadingState, loadLineType, lineTypeId]);

  useEffect(() => {
    if (
      !!line &&
      [LineOriginationStatus.DownPaymentInitiated, LineOriginationStatus.DownPaymentAccountAllocated].includes(
        line.originationStatus
      )
    ) {
      updateNavbar({
        backAction: () => navigate("back"),
        stepper: false,
        title:
          line.originationStatus === LineOriginationStatus.DownPaymentInitiated ? "Line in Progress" : "Complete Line",
        orgSwitcher: false,
        rightIcon: <IconMore />,
        rightAction: moreAction
      });
    } else {
      updateNavbar({
        step: currentStep,
        totalSteps: steps.length,
        backAction: backAction,
        orgSwitcher: !lineId,
        rightIcon: !!lineId ? <IconMore /> : undefined,
        rightAction: !!lineId ? moreAction : undefined
      });
    }
  }, [line, currentStep, steps, updateNavbar]);

  useEffect(() => {
    if (!lineTypeId || !lineType) return;
    setCurrentStep(0);
  }, [lineType, lineTypeId]);

  useEffect(() => {
    if (!!currentOrg?.oneLine?.repaymentAccountId) {
      refetchAccounts({ accountRef: AccountRef.Checking, selectedId: currentOrg.oneLine.repaymentAccountId });
    }
  }, [currentOrg?.oneLine]);

  useEffect(() => {
    if (didLoad.current || !currentOrg?.oneLine || !line) return;

    switch (line.originationStatus) {
      case LineOriginationStatus.Created:
        if (!currentOrg?.oneLine?.repaymentAccountId) setCurrentStep(steps.indexOf(step.REPAYMENT));
        else setCurrentStep(steps.indexOf(step.INSURANCE));
        break;
      case LineOriginationStatus.AcceptedTerms:
        setCurrentStep(steps.indexOf(step.DOWN_PAYMENT));
        break;
      case LineOriginationStatus.DownPaymentInitiated:
        setCurrentStep(steps.indexOf(step.DOWN_PAYMENT));
        break;
      case LineOriginationStatus.DownPaymentAccountAllocated:
        setCurrentStep(steps.indexOf(step.SIGN));
        break;
    }

    didLoad.current = true;
  }, [line, currentOrg, steps]);

  useEffect(() => {
    // i don't think this is needed anymore
    // if (!!actionData?.response?.line) {
    //   load(`${NavRoutes.API_LINE}?lineId=${actionData.response.line.id}`);
    // }

    if (!!actionData?.response?.step) {
      if (actionData.response.step === "amount" && !lineId) {
        navigate(generatePath(NavRoutes.ONELINE_EDIT_LINE, { lineId: actionData.response.line.id }), {
          direction: NavigationDirection.REPLACE
        });
      } else {
        nextAction();
      }
    }
  }, [actionData?.response]);

  useEffect(() => {
    if (!!cancelData && cancelData.response?.cancelLine) {
      showNotice("Your loan application has been canceled.");
      return navigate(NavRoutes.ONELINE_DASHBOARD, { direction: NavigationDirection.BACKWARD });
    } else if (!!cancelData && !!cancelData.error) {
      showNotice("There was a problem canceling your loan application.", { error: true });
    }
  }, [cancelData]);

  const moreAction = () => {
    showBottomSheet("loan_actions_sheet");
  };

  const backAction = () => {
    setStepDirection(-1);
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      navigate(NavRoutes.ONELINE_BORROW, { direction: NavigationDirection.BACKWARD });
    }
  };

  const nextAction = () => {
    setStepDirection(1);
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      showBottomSheet("loan_docs_signed_sheet");
    }
  };

  const cancelLoanApplication = () => {
    submit({ lineId: lineId || "" }, { method: "delete" });
  };

  const getStepComponent = () => {
    switch (currentStep) {
      case step.AMOUNT:
        return <AmountStep lineType={line?.lineType || lineType} line={line} />;
      case step.REPAYMENT:
        return <RepaymentStep accounts={accounts} line={line} />;
      case step.INSURANCE:
        return <InsuranceStep />;
      case step.PRE_APPROVAL:
        return <PreApprovalStep line={line} />;
      case step.DOWN_PAYMENT:
        return <DownPaymentStep line={line} accounts={accounts} />;
      case step.SIGN:
        return <SignStep line={line} />;
      default:
        return <></>;
    }
  };

  return (
    <Layout loading={currentStep === -1}>
      <AnimatePresence initial={false} exitBeforeEnter custom={stepDirection}>
        <StepsContainer key={currentStep} stepDirection={stepDirection} children={getStepComponent()} />
      </AnimatePresence>

      <ActionBottomSheet
        id="loan_docs_signed_sheet"
        success
        title="Loan Docs Signed"
        actionText="Close"
        actionColor={ColorNames.TRANSPARENT}
        onAction={() => {
          hideBottomSheet();
          navigate(NavRoutes.ONELINE_DASHBOARD);
        }}
        onClose={() => {
          navigate(NavRoutes.ONELINE_DASHBOARD);
        }}
        children={
          <ActionSheetCenteredContainer>
            Next, head to the auction to make your purchase.
            <br />
            <br />
            Once you’re purchase has been made, log back into Letter to sign one last document to finalize your loan.
          </ActionSheetCenteredContainer>
        }
      />

      <ActionBottomSheet
        id="loan_actions_sheet"
        children={
          <ActionableButtonGroup fullWidth>
            <ActionableButton
              label="Exit Loan Setup"
              color={ColorNames.OCEAN}
              iconRight={false}
              onClick={() => {
                navigate(NavRoutes.ONELINE_DASHBOARD, { direction: NavigationDirection.BACKWARD });
                hideBottomSheet();
              }}
            />
            <ActionableButton
              label="Cancel Loan Application"
              destructive
              keepBackground
              color={ColorNames.GRAY1}
              iconRight={false}
              onClick={() => {
                showBottomSheet("cancel_confirm_sheet");
              }}
            />
          </ActionableButtonGroup>
        }
        actionColor={ColorNames.TRANSPARENT}
        actionText="Close"
        onAction={(): void => hideBottomSheet()}
      />

      <ActionBottomSheet
        id="cancel_confirm_sheet"
        title="Are you sure?"
        description="Canceling your loan application cannot be undone."
        actionText="Cancel Loan Application"
        actionColor={ColorNames.CARDINAL}
        onAction={cancelLoanApplication}
        actionLoading={cancelState === "submitting"}
        secondaryActionText="Cancel"
        onSecondaryAction={() => (cancelState === "submitting" ? undefined : hideBottomSheet())}
        closeOnOutsideClick={false}
      />
    </Layout>
  );
};

export default CreateLine;

const AmountStep = ({ lineType, line }: { lineType?: LineType; line?: Line }) => {
  const currentOrg = useCurrentOrg();
  const [selectedAuction, setSelectedAuction] = useState<UpcomingAuction>();
  const { data: auctionData, state: loadingAuctionsState, load } = useFetcher<UpcomingAuction[]>();
  const actionData = useActionData() as RouteActionResponse<unknown>;
  const { state: formState } = useNavigation();
  const [calculateLineTerms, { data: calculationData, loading, error }] = useCalculateLineTermsMutation();
  const [cookies] = useCookies([
    storedCookieDataKey.TETON_RIDGE_AUCTION_START_DATE,
    storedCookieDataKey.TETON_RIDGE_AUCTION_END_DATE
  ]);
  const navigate = useNavigate();

  const [loanAmount, setLoanAmount] = useState<number>();
  const [loanTermLength, setLoanTermLength] = useState<number>(36);

  const abortController = useRef<AbortController>();
  const debouncedCalculate = useRef(
    debounce(({ lineTypeId, amount, termLength }) => {
      const controller = new window.AbortController();
      abortController.current = controller;

      calculateLineTerms({
        variables: {
          input: {
            lineTypeId: lineTypeId,
            loanAmount: amount,
            termLengthInMonths: termLength
          }
        },
        context: {
          fetchOptions: { signal: controller.signal }
        }
      });
    }, 500)
  );

  const abortLatestCalculation = () => {
    abortController.current && abortController.current.abort();
  };

  useEffect(() => {
    if (!auctionData && loadingAuctionsState === "idle") load(NavRoutes.API_ONELINE_AUCTION);
  }, [auctionData, loadingAuctionsState, load]);

  useEffect(() => {
    if (!!loanAmount && !!lineType && !!loanTermLength) {
      abortLatestCalculation();
      debouncedCalculate.current({
        lineTypeId: lineType.id,
        amount: loanAmount,
        termLength: loanTermLength
      });
    }
  }, [loanAmount, lineType, loanTermLength]);

  useEffect(() => {
    if (!!error) {
      showNotice("There was a problem calculating your loan terms. Please try again.", { error: true });
    }
  }, [error]);

  useEffect(() => {
    if (!!actionData?.validationErrors && Object.keys(actionData.validationErrors).length > 0) {
      const firstError = Object.values(actionData.validationErrors)[0] as string;
      showNotice(firstError, { error: true });
    }
  }, [actionData?.validationErrors]);

  useEffect(() => {
    if (!!actionData?.error) {
      const errorMessage =
        actionData.error.message || `There was a problem ${!!line ? "updating" : "creating"} your loan.`;
      showNotice(errorMessage, { error: true });
    }
  }, [actionData?.error]);

  const termLengths = useMemo(() => {
    const defaultTermLengths = [
      {
        label: "24 Months",
        value: 24
      },
      {
        label: "36 Months",
        value: 36
      },
      {
        label: "48 Months",
        value: 48
      }
    ];

    if (!!lineType && !!lineType.termLengthMonths) {
      return lineType.termLengthMonths.map((months) => {
        return {
          label: `${months} Months`,
          value: months
        };
      });
    } else {
      return defaultTermLengths;
    }
  }, [lineType]);

  const auctions = useMemo(() => {
    if (!auctionData) return [];

    const auctions = auctionData.map((auction) => {
      const startDate = formatDateShort(auction.date.start);
      return { label: `${startDate} - ${auction.description}`, value: auction.date.start };
    });

    auctions.unshift({ label: "Choose your auction", value: "" });

    if (!!cookies[storedCookieDataKey.TETON_RIDGE_AUCTION_START_DATE]) {
      const auction = auctionData?.find(
        (a) => a.date.start === cookies[storedCookieDataKey.TETON_RIDGE_AUCTION_START_DATE]
      );
      setSelectedAuction(auction);
    }

    return auctions;
  }, [auctionData]);

  const maximumLoanAmount = useMemo(() => {
    if (!currentOrg.oneLineAvailableToBorrow || !lineType) return MINIMUM_LOAN_AMOUNT;

    return Math.min(currentOrg.oneLineAvailableToBorrow.amount, lineType.maxOfferingAmount.amount);
  }, [currentOrg.oneLineAvailableToBorrow, lineType]);

  return (
    <>
      <StepHeader children="Configure Your Loan" />
      <Form method="post">
        <SliderCurrencyInput
          name="amount"
          label="Borrow Amount"
          startingAmount={line?.loanAmount.amount || MINIMUM_LOAN_AMOUNT}
          minimumAmount={MINIMUM_LOAN_AMOUNT}
          maximumAmount={maximumLoanAmount}
          onAmountChange={(amount) => setLoanAmount(amount)}
        />
        <InfoListItemsWrapper>
          <InfoListItem
            title="Available to Borrow"
            value={decorateCurrencyValue(formatCentsToString(maximumLoanAmount))}
            valueType="number"
            size="small"
          />
          <InfoListItem
            title="Interest Rate"
            value={calculationData?.calculateLineTerms?.interestRate.display || "–"}
            valueType="number"
            size="small"
            children={loading && <LoadingSkeleton noMargin width={60} />}
          />
          <InfoListItem
            title="Down Payment"
            value={decorateCurrencyValue(calculationData?.calculateLineTerms?.downPaymentAmount.display)}
            valueType="number"
            size="small"
            children={loading && <LoadingSkeleton noMargin width={60} />}
          />
        </InfoListItemsWrapper>
        <Select
          name="termLength"
          label="Term Length"
          options={termLengths}
          defaultValue={line?.termLengthInMonths || 36}
          onChange={(event) => setLoanTermLength(parseInt(event.currentTarget.value))}
        />

        <Select
          key={auctions.length > 0 ? cookies[storedCookieDataKey.TETON_RIDGE_AUCTION_START_DATE] : ""}
          name="_auction"
          label="Auction"
          options={auctions}
          defaultValue={cookies[storedCookieDataKey.TETON_RIDGE_AUCTION_START_DATE]}
          onChange={(event) => {
            const auction = auctionData?.find((a) => a.date.start === event.currentTarget.value);
            setSelectedAuction(auction);
          }}
        />

        {(currentOrg.oneLineAvailableToBorrow?.amount || 0) < (lineType?.maxOfferingAmount.amount || 0) && (
          <BannerNotice
            iconLeft={<IconInfo />}
            iconRight={<IconChevron />}
            textColor={ColorNames.CINNAMON}
            iconColor={ColorNames.GOLD}
            onClick={() => navigate(NavRoutes.BANK_CONNECTED_ACCOUNTS)}
            style={{ marginTop: "30px" }}
          >
            Want to borrow more? Connect more accounts to qualify for more.
          </BannerNotice>
        )}

        <input type="hidden" name="loanAmount" defaultValue={loanAmount} />
        <input type="hidden" name="minimumAmount" defaultValue={MINIMUM_LOAN_AMOUNT} />
        <input type="hidden" name="availableToBorrow" defaultValue={currentOrg.oneLineAvailableToBorrow?.amount || 0} />
        <input type="hidden" name="lineTypeId" defaultValue={lineType?.id} />
        <input
          type="hidden"
          name="auctionStartDate"
          defaultValue={cookies[storedCookieDataKey.TETON_RIDGE_AUCTION_START_DATE] || selectedAuction?.date.start}
        />
        <input
          type="hidden"
          name="auctionEndDate"
          defaultValue={cookies[storedCookieDataKey.TETON_RIDGE_AUCTION_END_DATE] || selectedAuction?.date.end}
        />

        {!!line && <input type="hidden" name="lineId" defaultValue={line.id} />}

        <Button
          raised
          name="step"
          value="amount"
          type="submit"
          loading={formState === "submitting"}
          children="Continue"
          disabled={!calculationData || loading || loadingAuctionsState === "loading"}
        />
      </Form>
    </>
  );
};

const RepaymentStep = ({ accounts, line }: { accounts?: Partial<Account>[]; line?: Line }) => {
  const currentOrg = useCurrentOrg();
  const actionData = useActionData() as RouteActionResponse<unknown>;
  const { state: formState } = useNavigation();
  const { accounts: connectedAccounts, refetchConnectedAccounts } = useGetConnectedAccounts(true);

  useEffect(() => {
    if (!!currentOrg?.oneLine?.repaymentAccountId) {
      refetchConnectedAccounts({ selectedId: currentOrg?.oneLine?.repaymentAccountId });
    }
  }, [currentOrg?.oneLine?.repaymentAccountId, refetchConnectedAccounts]);

  useEffect(() => {
    if (!!actionData?.validationErrors && Object.keys(actionData.validationErrors).length > 0) {
      const firstError = Object.values(actionData.validationErrors)[0] as string;
      showNotice(firstError, { error: true });
    }
  }, [actionData?.validationErrors]);

  useEffect(() => {
    if (!!actionData?.error) {
      const errorMessage =
        actionData.error.message || `There was a problem ${!!line ? "updating" : "creating"} your loan.`;
      showNotice(errorMessage, { error: true });
    }
  }, [actionData?.error]);

  const selectedAccountId = useMemo(() => {
    return (
      accounts?.find((account) => account.selected)?.id || connectedAccounts?.find((account) => account.selected)?.id
    );
  }, [accounts, connectedAccounts]);

  return (
    <>
      <StepHeader children="Repayments" />
      <Form method="post">
        <div>
          <StepParagraph>
            Monthly loan repayments will be automatically withdrawn from your chosen account.
            <br />
            <br />
            Making payments from your Letter checking account is compeltely free. However, payments made from an outside
            financial institution (a Connected Account) will include a 2% fee.
          </StepParagraph>
          <InfoListItemsWrapper>
            <InfoListItem title="Letter Account Fee" value="0%" valueType="number" size="small" />
            <InfoListItem title="Connected Account Fee" value="2%" valueType="number" size="small" />
          </InfoListItemsWrapper>
        </div>

        <DetailSelectAccount
          pickerId="repayment_account_id"
          name="repaymentAccountId"
          bankAccounts={accounts || []}
          connectedAccounts={connectedAccounts || []}
          label="Repayment Account"
          placeholder="Choose account..."
          search
          defaultValue={selectedAccountId}
        />

        <Button
          raised
          name="step"
          value="repayment"
          type="submit"
          loading={formState === "submitting"}
          children="Continue"
        />
      </Form>
    </>
  );
};

const InsuranceStep = () => {
  const { state: formState } = useNavigation();

  return (
    <>
      <StepHeader children="Insurance" />
      <Form method="post">
        <div>
          <StepParagraph>
            As part of the lending agreement, you are required to get insurance for any horses you purchase.
          </StepParagraph>
          <StepParagraph>
            You’ll have 3 days after closing to provide proof of Mortality insurance, or your loan will be in default.
          </StepParagraph>
          <StepParagraph highlight>We’ve partnered with Acrisure Insurance to make getting insured easy.</StepParagraph>

          <ActionableButton
            color={ColorNames.OCEAN}
            label="More about Acrisure Insurance"
            onClick={() => window.open("https://www.acrisure.com/solutions/insurance/personal-insurance", "_blank")}
            iconRight={false}
          />

          <StepParagraph gray style={{ marginTop: "30px" }}>
            You’ll be able to upload proof of insurance right here in Letter once you get an insurance policy.
          </StepParagraph>
        </div>

        <Button
          raised
          name="step"
          value="insurance"
          type="submit"
          loading={formState === "submitting"}
          children="Continue"
        />
      </Form>
    </>
  );
};

const PreApprovalStep = ({ line }: { line?: Line }) => {
  const currentOrg = useCurrentOrg();
  const { state: formState } = useNavigation();
  const { showBottomSheet } = useShowBottomSheet();

  const purchasingPower = useMemo(() => {
    const loanAmount = line?.loanAmount.amount || 0;
    const downPayment = line?.downPaymentAmount.amount || 0;
    return decorateCurrencyValue(formatCentsToString(loanAmount + downPayment));
  }, [line]);

  return (
    <>
      <StepHeader children="Pre-Approval" />
      <Form method="post">
        <div>
          <StepParagraph>
            You're pre-approved for 30 days with a total purchasing power of {purchasingPower}.
            <br />
            <br />
            If you spend less than your total Purchasing Power, your Loan Amount and Down Payment will also decrease to
            match whatever you spend.
          </StepParagraph>
          <InfoGroup background={ColorNames.GRAY1} dataColor={ColorNames.OCEAN}>
            <DetailRow
              large
              label="Loan Amount"
              data={`+${decorateCurrencyValue(line?.loanAmount.display)}`}
              dataColor={ColorNames.GRAY5}
            />
            <DetailRow
              large
              label="Down Payment"
              data={`+${decorateCurrencyValue(line?.downPaymentAmount.display)}`}
              dataColor={ColorNames.GRAY5}
            />
            <DetailRow large label="Purchasing Power" data={purchasingPower} dividerTop />
            {/* <ActionableButton
            label="View Amortization Table"
            color={ColorNames.OCEAN}
            iconRight={<IconPopup />}
            onClick={() => showBottomSheet("amortization_sheet")}
          /> */}
          </InfoGroup>
          <InfoListItemsWrapper>
            <InfoListItem
              title="Interest Rate"
              value={line?.interestRate.display || "–"}
              valueType="number"
              size="small"
            />
            <InfoListItem
              title="Term Length"
              value={`${line?.termLengthInMonths || 0} months`}
              valueType="number"
              size="small"
            />
            <InfoListItem
              title="Loan Type"
              value={line?.loanType ? LoanTypeDisplays[line.loanType] : "–"}
              size="small"
            />
            <InfoListItem
              title="Repayments From"
              value={`${currentOrg?.oneLine?.repaymentAccount?.nickname || ""} ${
                currentOrg?.oneLine?.repaymentAccount?.last4 || "–"
              }`}
              size="small"
            />
            <InfoListItem
              title="Repayment Fee"
              value={currentOrg?.oneLine?.repaymentFeePercent?.display || "–"}
              valueType="number"
              size="small"
            />
            <InfoListItem title="Pre-Approval Expires In" value="30 days" valueType="number" size="small" />
          </InfoListItemsWrapper>
        </div>
        <input type="hidden" name="lineId" defaultValue={line?.id} />
        <Button
          raised
          name="step"
          value="pre-approval"
          type="submit"
          loading={formState === "submitting"}
          children="Continue"
        />
      </Form>

      <AmortizationSheet id="amortization_sheet" />
    </>
  );
};

enum DownPaymentMethod {
  Wire = "Wire",
  ACH = "ACH",
  Transfer = "Transfer"
}

const DownPaymentStep = ({ line, accounts }: { line?: Line; accounts?: Partial<Account>[] }) => {
  const currentOrg = useCurrentOrg();
  const { accounts: connectedAccounts } = useGetConnectedAccounts(true);
  const actionData = useActionData() as RouteActionResponse<unknown>;
  const { state: formState } = useNavigation();
  const submit = useSubmit();
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [method, setMethod] = useState(DownPaymentMethod.Wire);
  const [canACH, setCanACH] = useState(true);
  const [selectedConnectedAccount, setSelectedConnectedAccount] = useState<ConnectedAccount>();
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();

  useEffect(() => {
    if (!accounts || !line || !connectedAccounts) return;

    const preventACH = () => {
      setCanACH(false);
      setIsLoading(false);
    };

    if (accounts.length > 0) {
      // if user has enough in checking account, make book transfer
      if ((accounts[0].availableBalance?.amount || 0) >= line.downPaymentAmount.amount) {
        setMethod(DownPaymentMethod.Transfer);
        return preventACH();
      }
    }

    if (!!line.auctionDate) {
      // if auction end is less than 7 days away, only allow wires
      const daysAway = Math.round((new Date(line.auctionDate.end).getTime() - new Date().getTime()) / 86400000);
      if (daysAway < 7) {
        return preventACH();
      }
    }

    if (line.downPaymentAmount.amount > 2500000) {
      // if down payment is greater than $25k, only allow wires
      return preventACH();
    }

    setIsLoading(false);
  }, [accounts, line, connectedAccounts]);

  useEffect(() => {
    if (!!actionData?.validationErrors && Object.keys(actionData.validationErrors).length > 0) {
      const firstError = Object.values(actionData.validationErrors)[0] as string;
      showNotice(firstError, { error: true });
    }
  }, [actionData?.validationErrors]);

  useEffect(() => {
    if (!!actionData?.error) {
      const errorMessage = actionData.error.message || "There was a problem creating your loan.";
      showNotice(errorMessage, { error: true });
    }
  }, [actionData?.error]);

  const confirmAction = () => {
    hideBottomSheet();
    submit(formRef.current);
  };

  if (line?.originationStatus === LineOriginationStatus.DownPaymentInitiated && method !== DownPaymentMethod.Transfer) {
    return (
      <>
        <StepHeader children="Waiting for Down Payment" />
        <Form method="post" ref={formRef}>
          <div>
            <StepParagraph>
              We’re currently waiting for your Down Payment. We’ll email you once we receive it, and you can then finish
              setting up your loan.
            </StepParagraph>

            <InfoListItemsWrapper>
              <InfoListItem
                title="Down Payment Amount"
                value={decorateCurrencyValue(line?.downPaymentAmount.display)}
                valueType="number"
                size="small"
              />
            </InfoListItemsWrapper>
          </div>
        </Form>
      </>
    );
  }

  return (
    <>
      <StepHeader children="Down Payment" />
      <Form method="post" ref={formRef}>
        <StepDownPaymentHeader>
          <StepParagraph>
            {method === DownPaymentMethod.Transfer ? (
              <>To complete the loan, use the funds in your Letter checking account for the down payment.</>
            ) : (
              <>To complete the loan, you'll need to deposit the down payment into your Letter checking account.</>
            )}
            <br />
            <a href="https://help.letter.co/" target="_blank" rel="noreferrer">
              Learn More
            </a>
          </StepParagraph>

          <InfoListItemsWrapper>
            <InfoListItem
              title="Down Payment Amount"
              value={decorateCurrencyValue(line?.downPaymentAmount.display)}
              valueType="number"
              size="small"
            />
          </InfoListItemsWrapper>

          {!isLoading && method !== DownPaymentMethod.Transfer && (
            <>
              <StepParagraph>
                {canACH ? (
                  <>Deposit by sending a wire to your Letter checking account or do a bank transfer.</>
                ) : (
                  <>Deposit by sending a wire to your Letter checking account.</>
                )}
              </StepParagraph>

              <InfoListItemsWrapper>
                <InfoListItem title="Send a Wire" value="Next Day" size="small" />
                {canACH && <InfoListItem title="Connected Bank Transfer" value="2-5 Days" size="small" />}
              </InfoListItemsWrapper>
            </>
          )}

          {!isLoading && method !== DownPaymentMethod.Transfer && canACH && (
            <SegmentedControl
              condensed
              options={[
                { label: "Send Wire", value: DownPaymentMethod.Wire },
                { label: "Bank Transfer", value: DownPaymentMethod.ACH }
              ]}
              color={ColorNames.BREEZE}
              active={method}
              onClick={(value) => setMethod(value as DownPaymentMethod)}
            />
          )}
        </StepDownPaymentHeader>

        {isLoading && <LoadingContainer />}

        {!isLoading && method === DownPaymentMethod.Wire && (
          <>
            <ActionableButton
              label="Account & Wire Details"
              color={ColorNames.OCEAN}
              iconRight={<IconPopup />}
              onClick={() => showBottomSheet("bank_account_details_sheet")}
            />

            <BannerNotice
              iconLeft={<IconInfo />}
              textColor={ColorNames.CINNAMON}
              iconColor={ColorNames.GOLD}
              style={{ marginTop: "30px" }}
            >
              If you have already initiated a wire, check back here once the wire has posted to finish your down
              payment.
            </BannerNotice>
          </>
        )}

        {!isLoading && method === DownPaymentMethod.ACH && (
          <>
            <DetailSelectAccount
              pickerId="connected_account_id"
              name="connectedAccountId"
              bankAccounts={[]}
              connectedAccounts={connectedAccounts}
              shouldOnlyShowConnectedAccounts={true}
              label="Transfer from Account"
              placeholder="Choose account..."
              didSelectAccount={(data) => {
                setSelectedConnectedAccount(connectedAccounts?.find(({ id }) => id === data.id));
              }}
            />

            <input type="hidden" name="amount" value={line?.downPaymentAmount.amount} />
            <input
              type="hidden"
              name="depositAccountId"
              value={!!accounts && accounts?.length > 0 ? accounts[0].id : ""}
            />

            <Button
              raised
              className="submit"
              onClick={() => showBottomSheet("transfer_sheet")}
              loading={formState === "submitting"}
              disabled={!selectedConnectedAccount}
              children="Transfer Money"
            />
          </>
        )}

        {!isLoading && method === DownPaymentMethod.Transfer && (
          <>
            <DetailSelectAccount
              pickerId="source_account_id"
              name="sourceAccountId"
              bankAccounts={accounts || []}
              connectedAccounts={[]}
              shouldHideNonCheckingTabs={true}
              label="Funding Account"
              placeholder="Choose account..."
            />

            <Button
              raised
              className="submit"
              onClick={() => showBottomSheet("funds_hold_sheet")}
              loading={formState === "submitting"}
              children="Continue"
            />
          </>
        )}

        <input type="hidden" name="lineId" value={line?.id} />
        <input type="hidden" name="step" value="down-payment" />
      </Form>

      <BankAccountDetailsSheet
        id="bank_account_details_sheet"
        account={!!accounts && accounts.length > 0 ? accounts[0] : undefined}
        accountHolder={currentOrg?.accountHolder}
      />

      <ActionBottomSheet
        id="transfer_sheet"
        title="Confirm Transfer"
        actionText="Transfer Money"
        actionColor={ColorNames.MIDNIGHT}
        onAction={confirmAction}
        secondaryActionText="Cancel"
        onSecondaryAction={(): void => hideBottomSheet()}
      >
        <InfoListItemsWrapper>
          <InfoListItem title="Transfering from" value={selectedConnectedAccount?.nickname} size="small" />
          <InfoListItem
            title="Amount"
            value={decorateCurrencyValue(line?.downPaymentAmount.display)}
            valueType="number"
            size="small"
          />
          <InfoListItem title="Funds available" value="2-5 Days" valueType="number" size="small" />
        </InfoListItemsWrapper>
      </ActionBottomSheet>

      <ActionBottomSheet
        id="funds_hold_sheet"
        title="Funds Put on Hold"
        actionText="Continue"
        onAction={confirmAction}
        secondaryActionText="Cancel"
        onSecondaryAction={(): void => {
          hideBottomSheet();
        }}
        children={
          <ActionSheetCenteredContainer>
            By continuing, your Down Payment ({decorateCurrencyValue(line?.downPaymentAmount.display)}) will be put on
            hold in your Letter checking account.
            <br />
            <br />
            This money will be used as your Down Payment for this loan and will not be able to be transferred from your
            Letter checking account during this loan setup process.
          </ActionSheetCenteredContainer>
        }
      />
    </>
  );
};

const SignStep = ({ line }: { line?: Line }) => {
  const { data, state: loadingState, load } = useFetcher();
  const actionData = useActionData() as RouteActionResponse<unknown>;
  const submit = useSubmit();
  const { state: formState } = useNavigation();
  const signingComplete = useReactiveVar(docusignSigningCompleted);
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();
  const [signed, setSigned] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    if (data?.url) {
      showBottomSheet("loan_doc_sheet");
    } else if (data?.errors) {
      showNotice("There was a problem generating your loan document.", { error: true });
    }
  }, [data]);

  useEffect(() => {
    if (!!actionData?.validationErrors && Object.keys(actionData.validationErrors).length > 0) {
      const firstError = Object.values(actionData.validationErrors)[0] as string;
      showNotice(firstError, { error: true });
    }
  }, [actionData?.validationErrors]);

  useEffect(() => {
    if (!!actionData?.error) {
      const errorMessage = actionData.error.message || "There was a problem signing your loan document.";
      showNotice(errorMessage, { error: true });

      setSigned(false);
    }
  }, [actionData?.error]);

  useEffect(() => {
    if (signingComplete) {
      setSigned(true);
      hideBottomSheet();
      docusignSigningCompleted(false);

      setTimeout(() => submit(formRef.current), 0);
    }
  }, [signingComplete]);

  return (
    <>
      <StepHeader children="Sign & Complete" />
      <Form method="post" ref={formRef}>
        <AddModal
          title="Sign Loan Docs"
          description="Read and sign the loan docs to open your new line."
          onClick={() => {
            if (!!data?.url) showBottomSheet("loan_doc_sheet");
            else load(`${NavRoutes.API_LINE}?get=lineDoc&docType=Loan&lineId=${line?.id}`);
          }}
          complete={signed}
          loading={loadingState === "loading"}
        />

        {/* <BannerNotice
        iconLeft={<IconInfo />}
        textColor={ColorNames.CINNAMON}
        iconColor={ColorNames.GOLD}
      >
        The loaned money will be automatically be transferred to your chosen deposit account after you complete this step.
      </BannerNotice> */}

        {signed && (
          <>
            <input type="hidden" name="lineId" value={line?.id} />
            <input type="hidden" name="documentId" defaultValue={data?.envelopeId} />
          </>
        )}
        <input type="hidden" name="step" value="sign" />

        {formState === "submitting" && <LoadingContainer height="62px" />}
      </Form>

      <BottomSheet
        id="loan_doc_sheet"
        fullHeight
        noPadding
        hasHeader
        closeOnOutsideClick={false}
        children={
          <LoanDocSheetContainer>
            <LoanDocSheetHeader>
              <span>Sign Loan Docs</span>
              <IconClose onClick={() => hideBottomSheet()} />
            </LoanDocSheetHeader>
            <iframe
              width="100%"
              height="100%"
              style={{ border: "none", flexGrow: "1" }}
              title="loan-doc-iframe"
              src={data?.url}
            />
          </LoanDocSheetContainer>
        }
      />
    </>
  );
};
