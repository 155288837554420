import React from "react";
import { generatePath } from "react-router-dom";

import useNavigate from "src/util/useNavigate";
import { NavRoutes } from "src/routes/navRoutes";
import { ReactComponent as ChevronRight } from "src/assets/icons/chevron_right.svg";

import {
  StyledFeatureListing
} from "./FeatureListing.styles";

export interface FeatureListingProps {
  id: string;
  name: string;
  caption: string;
  listings: JSX.Element;
}

export const FeatureListing = ({ id, name, caption, listings }: FeatureListingProps): JSX.Element => {
  const navigate = useNavigate();
  
  return (
    <StyledFeatureListing>
      <header onClick={(): void => {
        navigate(
          generatePath(NavRoutes.MARKETPLACE_LISTING_CATEGORY, {
            categoryId: id
          })
        );
      }}>
        {name}
        <ChevronRight />
      </header>
      <div className="caption">{caption}</div>
      <div className="listings">
        {listings}
      </div>
    </StyledFeatureListing>
  );
};
