import React, { useEffect, useMemo } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import { useCurrentUser } from "src/services/users";

import { AuthContainer } from "src/pages/auth/Auth";
import { AuthTitle } from "src/pages/auth/Auth.styles";
import States from "src/data/states.json";
import { Button } from "src/components/button/Button";
import { Input } from "src/components/forms/input/Input";
import { MultiInput } from "src/components/forms/input/MultiInput";
import { Select } from "src/components/forms/select/FormikSelect";
import { Form } from "src/components/forms/Form";
import { StyledFormInputContainer } from "src/components/forms";
import { useAddPersonalInfo, AddPersonalInfoData } from "src/services/users";
import {
  obfuscateTokenizedSSN,
  isSSNObfuscated,
  zipCodeFormatRegex,
} from "src/util/stringUtils";
import { DataTestIds } from "src/util/testing-util/test-utils";
import { showNotice } from "src/store/alertState";
import { BannerNotice } from "src/components/alert/BannerNotice";
import { ReactComponent as IconInfo } from "src/assets/icons/info.svg";
import { ColorNames } from "src/theme/theme";
import { CypressTestIds } from "src/util/testing-util/test-utils";

const PersonalInfoSchema: Yup.ObjectSchema<object> = Yup.object().shape({
  DOB: Yup.string()
    .min(10, "Please use a valid date of birth (MM/DD/YYYY).")
    .required("Please add your date of birth."),
  SSN: Yup.string()
    .min(11, "Please use a valid SSN.")
    .required("Please add your SSN."),
  ssnConfirm: Yup.string().oneOf(
    [Yup.ref("SSN"), null],
    "Your SSNs do not match."
  ),
  streetAddress: Yup.string().required("Please add an address."),
  city: Yup.string().required("Please add a city."),
  state: Yup.string().required("Please add a state."),
  zip: Yup.string()
    .matches(zipCodeFormatRegex, "Please use a valid zip code.")
    .required("Please add a zip code."),
});

const PersonalInfo = ({
  nextStep,
  stepDirection,
}: {
  nextStep: () => void;
  stepDirection: number;
}): JSX.Element => {
  const currentUser = useCurrentUser();
  const { addInfo, loading, error } = useAddPersonalInfo();

  const maxDOB = new Date();
  maxDOB.setFullYear(maxDOB.getFullYear() - 18);

  useEffect(() => {
    if (!!error)
      showNotice(error.message || "There was a problem adding your info.", {
        error: true,
      });
  }, [error]);

  const handleAddPersonalInfo = async (
    values: AddPersonalInfoData,
    setSubmitting: (submitting: boolean) => void
  ): Promise<void> => {
    if (isSSNObfuscated(values.SSN)) {
      values.SSN = currentUser?.SSN;
    }

    values.userId = currentUser?.id || "";

    const user = await addInfo(values);

    if (user) nextStep();
    setSubmitting(false);
  };

  const statesForDropdown = [
    {
      label: "Choose state",
      value: "",
    },
    ...States,
  ];

  const initialFormValues: AddPersonalInfoData = useMemo(() => {
    return {
      DOB: currentUser?.DOB || "",
      SSN: currentUser?.SSN ? obfuscateTokenizedSSN(currentUser.SSN) : "",
      ssnConfirm: currentUser?.SSN
        ? obfuscateTokenizedSSN(currentUser.SSN)
        : "",
      streetAddress: currentUser?.streetAddress || "",
      city: currentUser?.city || "",
      state: currentUser?.state || "",
      zip: currentUser?.zip || "",
      userId: currentUser?.id || "",
    };
  }, [currentUser]);

  return (
    <AuthContainer
      data-testid={DataTestIds.PERSONAL_INFO}
      stepDirection={stepDirection}
    >
      <AuthTitle>Personal Information</AuthTitle>

      <Formik
        initialValues={initialFormValues}
        enableReinitialize={true}
        validationSchema={PersonalInfoSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { setSubmitting }): void => {
          "ssnConfirm" in values && delete (values as any)["ssnConfirm"];
          handleAddPersonalInfo({ ...values }, setSubmitting);
        }}
      >
        {({ isSubmitting }): JSX.Element => (
          <Form>
            <>
              <StyledFormInputContainer>
                <MultiInput
                  name="DOB"
                  label="Date of Birth"
                  formatTo="$2-$0-$1"
                  separator="/"
                  inputs={[
                    { charsCount: 2, placeholder: "MM", type: "number" },
                    { charsCount: 2, placeholder: "DD", type: "number" },
                    { charsCount: 4, placeholder: "YYYY", type: "number" },
                  ]}
                />
                {/* <Input
                  name="DOB"
                  type="date"
                  label="Date of Birth"
                  min="1900-01-01"
                  max={maxDOB.toISOString().split("T")[0]}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                    handleChange(event);
                    setFieldValue("DOB", event.target.value);
                  }}
                /> */}
                {/* <Input
                  name="SSN"
                  type="text"
                  label="Social Security Number"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                    handleChange(event);
                    setFieldValue("SSN", handleSSN(event));
                  }}
                /> */}
                <MultiInput
                  name="SSN"
                  label="Social Security Number"
                  formatTo="$0-$1-$2"
                  separator="-"
                  doNotCapture
                  inputs={[
                    { charsCount: 3, placeholder: "000", type: "number" },
                    { charsCount: 2, placeholder: "00", type: "number" },
                    { charsCount: 4, placeholder: "0000", type: "number" },
                  ]}
                />
                <MultiInput
                  name="ssnConfirm"
                  label="Verify Social Security Number"
                  formatTo="$0-$1-$2"
                  separator="-"
                  doNotCapture
                  inputs={[
                    { charsCount: 3, placeholder: "000", type: "number" },
                    { charsCount: 2, placeholder: "00", type: "number" },
                    { charsCount: 4, placeholder: "0000", type: "number" },
                  ]}
                />
                <Input
                  name="streetAddress"
                  type="text"
                  label="Personal Street Address"
                  autocomplete="shipping street-address"
                  data-cy={CypressTestIds.SIGN_UP_STREET_ADDRESS}
                />
                <Input
                  data-cy={CypressTestIds.SIGN_UP_CITY}
                  name="city"
                  type="test"
                  label="City"
                  autocomplete="shipping locality"
                />
                <Select
                  data-cy={CypressTestIds.SIGN_UP_STATE}
                  name="state"
                  label="State"
                  options={statesForDropdown}
                  autocomplete="shipping region"
                />
                <Input
                  data-cy={CypressTestIds.SIGN_UP_ZIP}
                  name="zip"
                  type="test"
                  label="ZIP Code"
                  autocomplete="shipping postal-code"
                />
              </StyledFormInputContainer>

              <BannerNotice
                iconLeft={<IconInfo />}
                textColor={ColorNames.CINNAMON}
                iconColor={ColorNames.GOLD}
                style={{ marginTop: "auto" }}
              >
                Your progress will be auto-saved.
              </BannerNotice>

              <Button
                data-cy={CypressTestIds.SIGN_UP_SUBMIT_PERSONAL_INFORMATION_BTN}
                raised
                type="submit"
                loading={isSubmitting || loading}
              >
                Continue
              </Button>
            </>
          </Form>
        )}
      </Formik>
    </AuthContainer>
  );
};

export default PersonalInfo;
