import { Card, CardType } from "src/generated/client";
import {
  cardBackgroundMap,
  physicalCardBackgroundMap,
  defaultCardBackgroundInfo,
  BackgroundInfo,
} from "src/types/card";

export const getCardDesign = (card: Partial<Card>): BackgroundInfo => {
  // PHYSICAL CARDS
  if (card.type === CardType.Physical) {
    return physicalCardBackgroundMap.physicalEarly; // EARLY FOR NOW
  }

  // DEFAULT
  if (!card.design || card.design === "") {
    return defaultCardBackgroundInfo;
  }

  return cardBackgroundMap[card.design];
};
