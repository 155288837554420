/* eslint-disable  @typescript-eslint/no-unused-vars */

import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  useParams,
  useRevalidator,
  useFetcher,
  generatePath,
} from "react-router-dom";

import { useCurrentOrg } from "src/services/organizations";
import useNavigate from "src/util/useNavigate";
import { Avatar } from "src/components/avatar/Avatar";

import {
  useCloseCheckingAccount,
  useUpdateCheckingAccount,
  useGetTransactionsById,
  useGetAccountBalancesById,
  useLazyGetStatementById,
} from "src/services/accounts";

import {
  AccountChart,
  AccountDetailsList,
  AccountDetailsRow,
  AccountTransactions,
  AccountTransactionsTitle,
  AccountsPageContainer,
  AmountDollars,
  BalanceAmountHeader,
  BalanceLabel,
  BalanceDetails,
  InfoGroup,
  AccountsPageEmptyParagraph,
} from "src/routes/app/bank/accounts/Accounts.styles";
import { TransactionItems } from "src/components/transaction-item/TransactionItem.styles";
import { StyledForm, StyledFormInputContainer } from "src/components/forms";
import { Input } from "src/components/forms/input/Input";
import { default as Cards } from "src/routes/app/bank/cards/Cards";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import {
  ActionableButtonGroup,
  ActionableButtonRow,
} from "src/components/actionable-button/ActionableButton.styles";
import { BalanceGraph } from "src/components/balance-graph/BalanceGraph";
import { BalanceAmount } from "src/components/balance-amount/BalanceAmount";
import { NavRoutes } from "src/routes/navRoutes";
import { colorPalette as colors, ColorNames } from "src/theme/theme";
import { decorateCurrencyValue } from "src/util/stringUtils";

import { ReactComponent as IconTransfer } from "src/assets/icons/transfer.svg";
import { ReactComponent as IconPopup } from "src/assets/icons/popup.svg";
import { ReactComponent as IconEdit } from "src/assets/icons/edit.svg";
import { ReactComponent as IconRight } from "src/assets/icons/chevron_right.svg";
import { DetailRow } from "src/components/detail-row/DetailRow";
import { TimeRangePicker } from "src/components/timerange-picker/TimeRangePicker";
import { TransactionItem } from "src/components/transaction-item/TransactionItem";
import { ListButton } from "src/components/lists/ListButton";
import { ListButtonContainer } from "src/components/lists/Lists.styles";

import BankAccountDetailsSheet from "src/components/bottom-sheets/bank-account-details-sheet/BankAccountDetailsSheet";
import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import { showNotice } from "src/store/alertState";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { NavigationDirection } from "src/store/navigationState";
import { formatBalancePeriod } from "src/util/stringUtils";
import Layout from "src/components/core-layout/Layout";
import { useNavbar } from "src/util/useNavbar";
import { TabRouter, TabView, useSubnav } from "src/util/useSubnav";
import { BottomSheet } from "src/components/bottom-sheets/BottomSheet";
import TransactionDetail from "src/pages/transactions/TransactionDetail";
import ErrorElement from "src/ErrorElement";

import { ParagraphLarge } from "src/routes/app/App.styles";
import { Account, UserStatus, AccountBalancesGrouped, Transaction, BalancesTimeframe, OrgType } from "src/generated/client";

export const AccountDetailErrorElement = () => {
  return <ErrorElement redirect={NavRoutes.BANK_ACCOUNTS} alert={true} />;
};

const UpdateAccountSchema: Yup.ObjectSchema<object> = Yup.object().shape({
  accountName: Yup.string()
    .min(2, "Please use a longer name.")
    .max(64, "Please use a shorter name.")
    .required("Please add a nickname for this account."),
});

//const SUBNAV_TABS = ["overview", "cards", "details", "statements", "members"];
const SUBNAV_TABS = ["overview", "cards", "details", "statements"];

const AccountDetail = (): JSX.Element => {
  const {
    data: account,
    state: fetcherState,
    ...fetcher
  } = useFetcher<Account>();
  const revalidator = useRevalidator();

  const { accountId = "", section = "overview" } = useParams();
  const navigate = useNavigate();

  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();

  const { updateNavbar } = useNavbar({
    back: true,
    title: "...",
    backAction: () =>
      navigate(NavRoutes.BANK_ACCOUNTS, {
        direction: NavigationDirection.BACKWARD,
      }),
  });
  const { selected } = useSubnav(SUBNAV_TABS, {
    selectedTab: Math.max(SUBNAV_TABS.indexOf(section), 0),
  });

  const {
    transactions,
    loading: transactionsLoading,
    error: transactionsError,
  } = useGetTransactionsById(accountId);
  const {
    closeAccount,
    loading: closeAccountLoading,
    error: closeAccountError,
  } = useCloseCheckingAccount();
  const {
    updateAccount,
    loading: updateAccountLoading,
    error: updateAccountError,
  } = useUpdateCheckingAccount();
  const {
    getStatement,
    loading: statementLoading,
    error: statementError,
  } = useLazyGetStatementById();
  const { getBalances, accountBalances } = useGetAccountBalancesById();

  const currentOrg = useCurrentOrg({ fullFetch: true });
  const [selectedStatementId, setSelectedStatementId] = useState<string>("");
  const [detailsReveal, setDetailsReveal] = useState(false);
  const [timeRange, setTimeRange] = useState(BalancesTimeframe.LastThirty);
  const updateAccountSubmitRef = useRef<HTMLInputElement>(null);
  const [currentBalance, onUpdateCurrrentBalance] =
    useState<AccountBalancesGrouped | null>(null);
  const [selectedTransaction, setSelectedTransaction] = useState<
    Transaction | undefined
  >();

  useEffect(() => {
    if (!account && fetcherState === "idle") {
      fetcher.load(`${NavRoutes.API_ACCOUNT}?accountId=${accountId}`);
    }
  }, [account, fetcherState, fetcher]);

  useEffect(() => {
    updateNavbar({ title: account?.nickname || "" });
  }, [account?.nickname, updateNavbar]);

  useEffect(() => {
    getBalances(accountId, timeRange);
  }, [getBalances, accountId, timeRange]);

  useEffect(() => {
    if (closeAccountError) {
      showNotice(
        closeAccountError.message ||
          "There was a problem closing this account.",
        { error: true }
      );
    }
    if (updateAccountError) {
      showNotice(
        updateAccountError.message ||
          "There was a problem updating this account.",
        { error: true }
      );
    }
    if (statementError) {
      showNotice(
        statementError.message ||
          "There was a problem fetching this statement.",
        { error: true }
      );
    }
  }, [closeAccountError, updateAccountError, statementError]);

  const handleCloseAccount = async (id: string): Promise<void> => {
    const close = await closeAccount(id);

    if (close) {
      hideBottomSheet();
      showNotice(`Account was closed.`);
      navigate(NavRoutes.BANK_ACCOUNTS);
    }
  };

  const handleUpdateAccount = async (
    values: { accountName: string },
    setSubmitting: (submitting: boolean) => void,
    resetForm: () => void
  ): Promise<void> => {
    const update = await updateAccount({ accountId, name: values.accountName });

    if (update) {
      hideBottomSheet();
      showNotice(`Account was updated.`);
      revalidator.revalidate();
    }
  };

  const handleStatementClick = async (statementId: string) => {
    if (statementLoading) return;

    setSelectedStatementId(statementId);

    const url = await getStatement({ statementId, accountId });

    if (!!url && url !== "") {
      const contentType = "application/pdf";
      const sliceSize = 512;
      const byteCharacters = window.atob(url);

      const byteArrays = [];
      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {
        type: contentType,
      });
      const blobURL = URL.createObjectURL(blob);
      const newWindow = window.open(blobURL, "_blank");
      newWindow?.focus();
    }
  };

  return (
    <Layout
      loading={fetcherState === "loading"}
      noInnerContentWidth={selected === 1}
    >
      <TabRouter>
        <TabView key={0} show={selected === 0}>
          <BalanceAmountHeader>
            <AmountDollars>
              <BalanceAmount
                availableBalance={account?.availableBalance?.display || ""}
                currentBalance={currentBalance}
              />
              {/* TODO balance formatting is off */}
            </AmountDollars>

            <BalanceLabel>
              <div className="label">
                {currentBalance
                  ? formatBalancePeriod(currentBalance.period)
                  : "Available Balance"}
              </div>
              <TimeRangePicker
                pickerId="time-picker"
                label="Time Range"
                value={timeRange}
                onChange={setTimeRange}
              />
            </BalanceLabel>
          </BalanceAmountHeader>

          <AccountChart className="tall">
            <BalanceGraph
              lineColor={colors[ColorNames.SEAFOAM].hex}
              playheadColor={colors[ColorNames.SEAFOAM].hex}
              data={accountBalances || []}
              onUpdateCurrrentBalance={onUpdateCurrrentBalance}
            />
          </AccountChart>

          <AccountDetailsList>
            <AccountDetailsRow>
              <div className="label">Deposits</div>
              <div className="amount">
                {decorateCurrencyValue(account?.deposits.display) || "..."}
              </div>
            </AccountDetailsRow>
            <AccountDetailsRow>
              <div className="label">Spent</div>
              <div className="amount">
                {decorateCurrencyValue(account?.spent.display) || "..."}
              </div>
            </AccountDetailsRow>
          </AccountDetailsList>

          <ActionableButtonRow>
            <ActionableButton
              label="Transfer"
              color={ColorNames.PINE}
              iconLeft={<IconTransfer />}
              onClick={() => {
                navigate(
                  generatePath(NavRoutes.BANK_TRANSFER_SEND_SOURCED, {
                    transferFromAccountId: accountId,
                  })
                );
              }}
            />
            {/*
                <ActionableButton
                  label="Deposit"
                  color={ColorNames.PINE}
                  iconLeft={<IconDownload />}
                  onClick={() => {
                    // do something
                  }}
                />
                */}
          </ActionableButtonRow>

          <AccountTransactions>
            <AccountTransactionsTitle
              onClick={() => navigate(NavRoutes.BANK_TRANSACTIONS)}
            >
              <span>Recent Transactions</span>
              <IconRight />
            </AccountTransactionsTitle>
            <TransactionItems>
              {!!transactions &&
                transactions.map((transaction) => (
                  <TransactionItem
                    key={transaction.id}
                    transaction={transaction}
                    clickHandler={() => {
                      setSelectedTransaction(transaction);
                      setTimeout(() => {
                        showBottomSheet("transaction_item_sheet");
                      }, 0);
                    }}
                  />
                ))}
            </TransactionItems>
            <BottomSheet
              noPadding
              closeOnOutsideClick={true}
              id="transaction_item_sheet"
            >
              <TransactionDetail transaction={selectedTransaction} />
            </BottomSheet>

            <ActionableButtonRow>
              <ActionableButton
                label="View All Transactions"
                color={ColorNames.TRANSPARENT}
                iconRight={false}
                onClick={() => {
                  navigate(NavRoutes.BANK_TRANSACTIONS);
                }}
              />
            </ActionableButtonRow>
          </AccountTransactions>
        </TabView>
        <TabView key={1} show={selected === 1}>
          <Cards accountId={accountId} cards={account?.cards || []} />
        </TabView>
        <TabView key={2} show={selected === 2}>
          <AccountsPageContainer>
            <BalanceDetails>
              <DetailRow
                label="Available Balance"
                data={
                  decorateCurrencyValue(account?.availableBalance?.display) ||
                  "-"
                }
                large
              />
              <DetailRow
                label="Total Balance"
                data={
                  decorateCurrencyValue(account?.totalBalance.display) || "-"
                }
                large
              />
            </BalanceDetails>

            <InfoGroup>
              <DetailRow
                large
                label="Account Number"
                data={`••••${account?.externalAccountNumber?.slice(-4)}`}
                fullData={account?.externalAccountNumber}
                action="copy"
                reveal={detailsReveal}
                onClick={() => {
                  setDetailsReveal(true);
                }}
              />
              <DetailRow
                large
                label="Routing Number"
                data={`••••${account?.externalRoutingNumber?.slice(-4)}`}
                fullData={account?.externalRoutingNumber}
                action="copy"
                reveal={detailsReveal}
                onClick={() => {
                  setDetailsReveal(true);
                }}
              />
              <ActionableButton
                label="Routing & Wire Details"
                color={ColorNames.PINE}
                iconRight={<IconPopup />}
                onClick={() => showBottomSheet("bank_account_details_sheet")}
              />
            </InfoGroup>
            <ActionableButtonGroup fullWidth>
              <ActionableButton
                label="Account Name"
                extraLabel={account?.nickname || ""}
                color={ColorNames.MINT}
                iconRight={<IconEdit />}
                onClick={() => showBottomSheet("update_account_sheet")}
              />
              {/*
                <ActionableButton label="Beneficiaries" extraLabel="420" color={ColorNames.MINT} onClick={() => {}} />
                <ActionableButton label="Subscriptions" extraLabel="8" color={ColorNames.MINT} onClick={() => {}} />
                */}
              <ActionableButton
                label="Close Account"
                destructive
                iconRight={false}
                onClick={(): void => showBottomSheet("close_account_sheet")}
              />
            </ActionableButtonGroup>
          </AccountsPageContainer>

          <BankAccountDetailsSheet
            id="bank_account_details_sheet"
            account={account}
            accountHolder={currentOrg?.accountHolder}
          />

          <ActionBottomSheet
            id="close_account_sheet"
            title="Are you sure?"
            description={`${
              decorateCurrencyValue(account?.availableBalance?.display) ||
              "Available funds"
            } from "${
              account?.nickname
            }" will be transferred to your default account upon closing.`}
            actionText="Close Account"
            actionColor={ColorNames.CARDINAL}
            actionLoading={closeAccountLoading}
            onAction={() => handleCloseAccount(account?.id || "")}
            secondaryActionText="Cancel"
            onSecondaryAction={(): void => hideBottomSheet()}
          />
          <ActionBottomSheet
            id="update_account_sheet"
            title="Update Account"
            description=""
            actionText="Update Account"
            actionColor={ColorNames.LAKE}
            actionLoading={updateAccountLoading}
            onAction={(): void => {
              updateAccountSubmitRef?.current?.click();
            }}
            secondaryActionText="Cancel"
            onSecondaryAction={(): void => hideBottomSheet()}
          >
            <Formik
              initialValues={{ accountName: account?.nickname || "" }}
              enableReinitialize={true}
              validationSchema={UpdateAccountSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values, { setSubmitting, resetForm }): void => {
                handleUpdateAccount(values, setSubmitting, resetForm);
              }}
            >
              {({ isSubmitting, handleChange, setFieldValue }): JSX.Element => (
                <StyledForm
                  style={{
                    marginBottom: 44,
                  }}
                  autoComplete="off"
                >
                  <StyledFormInputContainer>
                    <Input
                      name="accountName"
                      type="text"
                      label="Account Name"
                      autoComplete="off"
                    />
                  </StyledFormInputContainer>
                  {/* 
                      hacky: explicit submit with ref here because Formik 
                      cannot have the ActionBottomSheet as a child 
                    */}
                  <input type="submit" hidden ref={updateAccountSubmitRef} />
                </StyledForm>
              )}
            </Formik>
          </ActionBottomSheet>
        </TabView>
        <TabView key={3} show={selected === 3}>
          {/* <AccountsPageContainer>
            <ActionableButton
              label="Download All"
              color={ColorNames.PINE}
              iconRight={<IconDownload />}
              onClick={() => undefined}
            />
          </AccountsPageContainer> */}
          <ListButtonContainer>
            {account?.statements &&
              account.statements.length > 0 &&
              account.statements?.map((statement: any) => {
                const date = new Date();
                date.setMonth((parseInt(statement.month) || 1) - 1);
                date.setFullYear(parseInt(statement.year) || 2022);
                return (
                  <ListButton
                    key={statement.statementId}
                    label={date.toLocaleDateString("en-us", {
                      year: "numeric",
                      month: "long",
                    })}
                    onClick={() => handleStatementClick(statement.statementId)}
                    loading={
                      statementLoading &&
                      selectedStatementId === statement.statementId
                    }
                  />
                );
              })}
            {(!account?.statements || account.statements.length <= 0) && (
              <AccountsPageEmptyParagraph>
                <ParagraphLarge>No Statements Yet</ParagraphLarge>
              </AccountsPageEmptyParagraph>
            )}
          </ListButtonContainer>
        </TabView>
        {/* <TabView key={4} show={selected === 4}>
          <ListButtonContainer>
            {account?.owners &&
              account?.owners.map(({ firstName, lastName, status, email, id, isAccountOwner }) => {
                if (status !== UserStatus.Active) {
                  return;
                }

                return (
                  <ListButton
                    label={`${firstName} ${lastName}`}
                    sublabel={isAccountOwner ? 'Account Owner' : 'Account Member'}
                    key={email}
                    onClick={ currentOrg.orgType ===  OrgType.Individual ? () => navigate(
                      generatePath(NavRoutes.BANK_ACCOUNT_DETAIL_MEMBER, { accountId: accountId, memberId: id })
                    ) : undefined}
                    avatar={<Avatar name={`${firstName} ${lastName}`} size={40} />}
                  />
                );
              })}
          </ListButtonContainer>
        </TabView> */}
      </TabRouter>
    </Layout>
  );
};

export default AccountDetail;
