import { makeVar } from "@apollo/client";
import { CardType } from "src/generated/client";

export interface ICreatedCardState {
  newCard: boolean;
  cardType: CardType;
}

export const createdCardVar = makeVar<ICreatedCardState>({
  newCard: false,
  cardType: CardType.Virtual,
});
