import React, { useEffect } from "react";
import { useFetcher } from "react-router-dom";

import { useNavbar } from "src/util/useNavbar";
import { NavRoutes } from "src/routes/navRoutes";
import useNavigate from "src/util/useNavigate";

import ErrorElement from "src/ErrorElement";
import Layout from "src/components/core-layout/Layout";
import { ColorNames } from "src/theme/theme";
import { NavigationDirection } from "src/store/navigationState";
import { ListButtonContainer } from "src/components/lists/Lists.styles";
import { ListButton } from "src/components/lists/ListButton";
import { Avatar } from "src/components/avatar/Avatar";

import {
  StyledBrowseContent,
  StyledBrowseHeading
} from "./Listings.styles";

export const ListingsErrorElement = () => {
  return <ErrorElement redirect={NavRoutes.BANK_DASHBOARD} alert={true} />;
};

const ListingCategory = () => {
  const { data, state: fetcherState, ...fetcher } = useFetcher();
  const navigate = useNavigate();

  useNavbar({
    back: true,
    title: "Startup Private Equity" || "...",
    backgroundColor: ColorNames.WHITE,
    backAction: () => navigate(NavRoutes.MARKETPLACE_LISTINGS, { direction: NavigationDirection.BACKWARD })
  });

  useEffect(() => {
    if (!data?.deals && fetcherState === "idle") fetcher.load(NavRoutes.API_DEAL);
  }, [data, fetcherState, fetcher]);

  return (
    <Layout loading={fetcherState === "loading"}>
      <StyledBrowseContent>
        <StyledBrowseHeading>
          <p>Buy stock in your favorite startups, before they go public. Letter has access to private equity in the very best startups.</p>
        </StyledBrowseHeading>

        <ListButtonContainer>
            <ListButton
              key={1}
              label={"Chime"}
              sublabel={"$4.98"}
              avatar={<Avatar name={"Chime"} size={40} textColor={ColorNames.GRAY5} color={ColorNames.GRAY1} />}
              onClick={() => {
                //
              }}
            />
            <ListButton
              key={1}
              label={"Discord"}
              sublabel={"$12.35"}
              avatar={<Avatar name={"Discord"} size={40} textColor={ColorNames.GRAY5} color={ColorNames.GRAY1} />}
              onClick={() => {
                //
              }}
            />
            <ListButton
              key={1}
              label={"Stripe"}
              sublabel={"$2.18"}
              avatar={<Avatar name={"Stripe"} size={40} textColor={ColorNames.GRAY5} color={ColorNames.GRAY1} />}
              onClick={() => {
                //
              }}
            />
        </ListButtonContainer>
      </StyledBrowseContent>
    </Layout>
  );
};

export default ListingCategory;