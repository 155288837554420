import { client } from "src";
import { CommitToDealInput, DealsQuery, UpdateDealCommitmentDocument, DeleteDealCommitmentInput, UpdateDealCommitmentMutation, CommitToDealDocument, CommitToDealMutation, DeleteDealCommitmentMutation, DeleteDealCommitmentDocument, DealsDocument,  } from "src/generated/client";

export const getAllDeals = async () => {
  const res = await client.query<DealsQuery>({
    query: DealsDocument,
    errorPolicy: "all",
    variables: {}
  });

  if (!!res.data?.deals) {
    const deals =
      res.data?.deals?.deals?.filter(Boolean).map((deal) => ({
        ...deal
      })).reverse() || [];
    return deals;
  } else {
    throw res.errors;
  }
};

export const commitToDeal = async (values: CommitToDealInput) => {
  try {
    const res = await client.mutate<CommitToDealMutation, CommitToDealInput>({
      mutation: CommitToDealDocument,
      errorPolicy: "all",
      variables: { ...values }
    });

    if (!!res.data?.commitToDeal?.success) {
      return { commitToDeal: true };
    } else {
      let errorMessage = "There was a problem committing to this deal.";
      if (!!res.errors && res.errors.length > 0) {
        errorMessage = res.errors[0].message;
      }
      throw Error(errorMessage);
    }
  } catch (err) {
    throw err;
  }
};

export const updateDealCommitment = async (values: CommitToDealInput) => {
  try {
    const res = await client.mutate<UpdateDealCommitmentMutation, CommitToDealInput>({
      mutation: UpdateDealCommitmentDocument,
      errorPolicy: "all",
      variables: { ...values }
    });

    if (!!res.data?.updateDealCommitment?.success) {
      return { updateDealCommitment: true };
    } else {
      let errorMessage = "There was a problem updating your commitment.";
      if (!!res.errors && res.errors.length > 0) {
        errorMessage = res.errors[0].message;
      }
      throw Error(errorMessage);
    }
  } catch (err) {
    throw err;
  }
};

export const deleteDealCommitment = async (values: { dealId: DeleteDealCommitmentInput['dealId'] }) => {
  try {
    const res = await client.mutate<DeleteDealCommitmentMutation, DeleteDealCommitmentInput>({
      mutation: DeleteDealCommitmentDocument,
      errorPolicy: "all",
      variables: { ...values }
    });

    if (!!res.data?.deleteDealCommitment?.success) {
      return { deleteDealCommitment: true };
    } else {
      let errorMessage = "There was a problem cancelling your commitment.";
      if (!!res.errors && res.errors.length > 0) {
        errorMessage = res.errors[0].message;
      }
      throw Error(errorMessage);
    }
  } catch (err) {
    throw err;
  }
};
