import React from "react";
import classnames from "classnames";
import { AnimatePresence, Variants } from "framer-motion";
import { useRecoilValue } from "recoil";
import { bottomSheetSelector, IBottomSheets } from "src/store/bottomSheetState";
import { useShowBottomSheet } from "src/util/useBottomSheet";

import { BottomSheet, Overlay } from "src/components/bottom-sheets/BottomSheet.styles";

import { DataTestIds } from "src/util/testing-util/test-utils";

const variants: Variants = {
  enter: {
    y: "100%"
  },
  center: {
    y: 0,
    transition: {
      ease: [0.215, 0.61, 0.355, 1.0],
      duration: 0.4
    }
  },
  exit: {
    y: "100%",
    transition: {
      ease: [0.215, 0.61, 0.355, 1.0],
      duration: 0.4
    }
  }
};

export const BottomSheetController = (): JSX.Element => {
  const bottomSheets = useRecoilValue<IBottomSheets[]>(bottomSheetSelector);
  const { hideBottomSheet } = useShowBottomSheet();

  const onClick = () => {
    const selectedBottomSheet = bottomSheets.length > 0 ? bottomSheets[bottomSheets.length - 1] : undefined;
    if (!selectedBottomSheet) {
      hideBottomSheet();
    } else {
      !!selectedBottomSheet.onClose && selectedBottomSheet.onClose();
      if (selectedBottomSheet.closeOnOutsideClick) hideBottomSheet(selectedBottomSheet.id);
    }
  };

  return (
    <>
      <Overlay
        show={bottomSheets.length > 0}
        data-testid={DataTestIds.BOTTOM_SHEET_OVERLAY}
        onClick={() => onClick()}
      />

      <AnimatePresence>
        {bottomSheets.map((sheet: IBottomSheets, index: number) => {
          return (
            <BottomSheet
              key={sheet.id}
              backgroundColor={sheet.backgroundColor}
              sheetIndex={index + 1}
              sheetsTotal={bottomSheets.length}
              data-testid={DataTestIds.BOTTOM_SHEET}
              data-sheetid={sheet.id}
              className={classnames({
                inline: sheet.inline,
                "full-height": sheet.fullHeight,
                "no-padding": sheet.noPadding,
                "has-header": sheet.hasHeader
              })}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
            >
              {sheet.children}
            </BottomSheet>
          );
        })}
      </AnimatePresence>
    </>
  );
};
