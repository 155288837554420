import React from "react";
import { TermsPage, Logo } from "src/pages/terms/Terms.styles";

const Disclosures = (): JSX.Element => {
  return (
    <TermsPage>
      <Logo />
      <div>
        <strong>Letter Advisors, LLC</strong> is a Securities and Exchange Commission (SEC) Registered Investment
        Advisor. Please note that such registration does not constitute an endorsement by the SEC, nor does it indicate
        that Letter Advisors has attained any particular level of skill or ability. Nothing presented herein is, or is
        intended, to constitute investment advice, and no investment decision should be made solely based on any
        information provided herein. The information provided reflects the views of the authors as of a particular time
        and are subject to change at any time without notice. Neither the information provided, nor any opinion
        expressed, constitutes a solicitation for the purchase or sale of any security. Past performance does not
        guarantee future results.
      </div>
    </TermsPage>
  );
};

export default Disclosures;
