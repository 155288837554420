import React, { useEffect } from "react";
import { useSetBottomSheet } from "src/util/useBottomSheet";

import {
  AboutSheetLogo,
  AboutSheetTitle,
  AboutSheetDescription
} from "src/components/bottom-sheets/about-bottom-sheet/AboutBottomSheet.styles";
import { Button } from "src/components/button/Button";
import { CypressTestIds } from "src/util/testing-util/test-utils";

import logoSrc from "src/assets/about-letter-logo.png";

export interface AboutBottomSheetProps {
  id: string;
  actionText: string;
  onAction: (...args: any[]) => unknown;
  actionLoading?: boolean;
}

export const AboutBottomSheet = ({ id, actionText, onAction, actionLoading }: AboutBottomSheetProps): JSX.Element => {
  const setBottomSheet = useSetBottomSheet();

  const bottomSheetChildren = (
    <>
      <AboutSheetLogo src={logoSrc} />
      <AboutSheetTitle>Congrats! You've been invited to join Letter.</AboutSheetTitle>
      <AboutSheetDescription>
        Letter is a beautifully designed banking experience made exclusively for high net-worth individuals.
        <br />
        <br />
        Create your account today and you'll be one the first in line to get a Letter bank account when we launch.
      </AboutSheetDescription>
      <Button
        raised
        onClick={onAction}
        data-cy={CypressTestIds.WELCOME_SHEET_BUTTON}
        loading={!!actionLoading}
        children={actionText}
      />
    </>
  );

  useEffect(() => {
    setBottomSheet({ id, children: bottomSheetChildren });
  }, [bottomSheetChildren, id, setBottomSheet]);

  return <></>;
};
