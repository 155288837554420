import styled from "styled-components";
import { colorPalette as colors, ColorNames, BrandColor, fonts } from "src/theme/theme";

export const ToggleContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

export const ToggleRow = styled.div<{ align: "left" | "right" }>`
  display: flex;
  justify-content: ${({ align }): string => (align === "left" ? "flex-start" : "space-between")};
  align-items: center;

  .displayLabel {
    display: flex;
    align-items: center;
    ${fonts.PARAGRAPH_S}
    color: ${colors[ColorNames.GRAY5].hex};
    order: ${({ align }): number => (align === "left" ? 1 : -1)};
    margin-left: ${({ align }): string => (align === "left" ? "15px" : "0")};

    a {
      color: ${colors[ColorNames.MIDNIGHT].hex};
    }
  }
`;

export const StyledLabel = styled.label`
  display: block;
  width: 50px;
  overflow: hidden;
  border: 0 solid ${colors[ColorNames.GRAY3].hex};
  border-radius: 50vh;
  margin: 0;
  background-color: ${colors[ColorNames.GRAY3].hex};
  transform-style: preserve-3d;
  perspective: 1000;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledTrack = styled.span<{
  color: BrandColor;
  disabled: boolean;
}>`
  display: block;
  width: 100%;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.2s ease-out;
  will-change: transform;

  &:before {
    display: block;
    float: left;
    width: 100%;
    height: 28px;
    color: ${colors[ColorNames.WHITE].hex};
    box-sizing: border-box;
    border-radius: 50vh;
    content: attr(data-yes);
    background-color: ${({ color }): string => (color ? colors[color].hex : colors[ColorNames.LAKE].hex)};
    opacity: ${({ disabled }): number => (disabled ? 0.4 : 1)};
  }
`;

export const StyledSwitch = styled.span<{ disabled: boolean }>`
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  margin: 4px;
  border: 5px solid ${colors[ColorNames.WHITE].hex};
  border-radius: 50%;
  background-color: transparent;
  box-shadow: 0px 3px 6px rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0.1),
    0px 8px 20px rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0.08);
  transform: translate3d(0, 0, 0);
  transition: transform 0.2s ease-out, border-width 0.2s ease-out, box-shadow 0.2s ease-out;
  opacity: ${({ disabled }): number => (disabled ? 0.5 : 1)};
  will-change: transform, border-width, box-shadow;
`;

export const StyledToggle = styled.input<{ disabled: boolean }>`
  display: none;
  &:checked + ${StyledLabel} {
    ${StyledTrack} {
      transform: translate3d(0, 0, 0);
    }
    ${StyledSwitch} {
      transform: translate3d(22px, 0, 0);
      border-width: 10px;
      box-shadow: 0px 3px 6px rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0.3),
        0px 8px 20px rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0.1);
    }
  }
`;
