import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import useNavigate from "src/util/useNavigate";
import { useCurrentUser, useFetchCurrentUser } from "src/services/users";

import { AnimatedRoot } from "src/LetterRoot";
import { NavRoutes } from "src/routes/navRoutes";
import { NavigationDirection } from "src/store/navigationState";


const AppRoot = (): JSX.Element => {
  const { fetchCurrentUser } = useFetchCurrentUser();
  const currentUser = useCurrentUser();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCurrentUser();
  }, [fetchCurrentUser]);

  useEffect(() => {
    if (!currentUser) return;

    if (!currentUser.onboarded) {
      if (location.pathname !== NavRoutes.ONBOARD)
        navigate(NavRoutes.ONBOARD, { direction: NavigationDirection.REPLACE });
    }
  }, [currentUser, navigate, location.pathname]);

  return !!currentUser ? (
    <Outlet />
  ) : (
    <></>
  );
};

export const AppGenericRoot = () => {
  return <AnimatedRoot />
}

export default AppRoot;
