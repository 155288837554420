import React, { useEffect, useState } from "react";
import { useRouteError } from "react-router-dom";
import useNavigate from "src/util/useNavigate";
import { showNotice } from "src/store/alertState";
import { SESSION_ERROR_CODES } from "src/graphqlClient";
import { NavigationDirection } from "./store/navigationState";
import Layout from "src/components/core-layout/Layout";

interface ErrorElementProps {
  children?: JSX.Element | string;
  redirect?: string;
  alert?: boolean;
}

const ErrorElement = ({ children, redirect, alert }: ErrorElementProps): JSX.Element => {
  const errors: any = useRouteError();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const navigate = useNavigate();
  

  useEffect(() => {
    const error = errors.length > 0 ? errors[0] : undefined;
    setErrorMessage(error?.message);
    const errorCode: string = !!error?.extensions?.code ? error?.extensions.code : "";
    
    const isSessionError = SESSION_ERROR_CODES.includes(errorCode);

    if (!isSessionError) {
      if (alert) {
        showNotice(error?.message || "There was a problem.", { error: true });
      }
      if (!!redirect) {
        navigate(redirect, { direction: NavigationDirection.BACKWARD, force: true });
      }
    }
  }, [errors]);

  return (
    <Layout style={{ alignItems: "center", justifyContent: "center" }}>{children || errorMessage || "There was a problem."}</Layout>
  );
};

export default ErrorElement;
