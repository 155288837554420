import React from "react";

import { NavRoutes } from "src/routes/navRoutes";

import { AnimatedRoot } from "src/LetterRoot";
import ErrorElement from "src/ErrorElement";

export const OneLineErrorElement = () => {
  return <ErrorElement redirect={NavRoutes.ONELINE_DASHBOARD} alert={true} />;
};

const OneLineRoot = (): JSX.Element => {
  return <AnimatedRoot />;
};

export default OneLineRoot;
