import React, { useState, useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { Formik } from "formik";

import { AppPage } from "src/routes/app/App.styles";
import { Avatar } from "src/components/avatar/Avatar";
import useNavigate from "src/util/useNavigate";
import { showNotice } from "src/store/alertState";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { ActionableButtonGroup, ActionableButtonRow } from "src/components/actionable-button/ActionableButton.styles";
import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import { colorPalette as colors, ColorNames } from "src/theme/theme";
import {
  useOrganizationUsers,
  useRevokeOrgInvite,
  useRemoveOrgUser,
  useToggleFreezeOrgUser,
  useEditOrgUser
} from "src/services/organizations";
import { User, UserStatus, Role } from "src/generated/client";

import { StyledForm, StyledFormInputContainer } from "src/components/forms";
import { Select, SelectOption } from "src/components/forms/select/FormikSelect";
import { MemberDetailContent, MemberDetailInfo } from "./Members.styles";
import { BannerNotice } from "src/components/alert/BannerNotice";
import { ReactComponent as IconInfo } from "src/assets/icons/info.svg";
import { ReactComponent as IconFreeze } from "src/assets/icons/snowflake.svg";
import { ReactComponent as IconThaw } from "src/assets/icons/play.svg";
import { ReactComponent as IconTrash } from "src/assets/icons/trash.svg";
import { ReactComponent as ChevronRight } from "src/assets/icons/chevron_right.svg";
import Layout from "src/components/core-layout/Layout";
import { useNavbar } from "src/util/useNavbar";
import { LoadingSkeleton } from "src/components/loading-skeleton/LoadingSkeleton";
import { NavRoutes } from "src/routes/navRoutes";

type FormFields = {
  role: string;
};

const MemberDetail = (): JSX.Element => {
  const { updateNavbar } = useNavbar({ back: true });
  const { memberId } = useParams();

  const navigate = useNavigate();
  
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();
  const { users, currentUserRole, refetch, error: orgUsersError } = useOrganizationUsers({ userId: memberId });
  const { edit, loading: editLoading, error: editError } = useEditOrgUser();
  const { revokeInvite, loading: revokeLoading, error: revokeError } = useRevokeOrgInvite();
  const { removeMember, loading: removeLoading, error: removeError } = useRemoveOrgUser();
  const { freeze, thaw, loading: freezeLoading, error: freezeError } = useToggleFreezeOrgUser();
  const [user, setUser] = useState<Partial<User>>();
  const settingsSubmitRef = useRef<any>(null);

  useEffect(() => {
    if (!!orgUsersError) {
      showNotice(orgUsersError.message || "There was a problem fetching organization members, try again later.", {
        error: true
      });
    }
  }, [orgUsersError]);

  useEffect(() => {
    if (users && memberId) {
      const user = users.find(({ selected }) => selected);
      if (!!user) {
        setUser(user);
      } else {
        showNotice("There was a problem loading this member.", { error: true });
        navigate("back");
      }
    }
  }, [memberId, users]);

  const canCurrentUserTakeActions: boolean = useMemo(() => {
    if (!currentUserRole || !user) return false;
    if (user.isThisMe) return false;
    if (currentUserRole === Role.Owner) return true;
    if (currentUserRole === Role.Admin && user.orgRole !== Role.Owner) return true;
    return false;
  }, [currentUserRole, user]);

  useEffect(() => {
    if (!user) return;
    updateNavbar({ title: `${user?.firstName} ${user?.lastName}`, back: true });
  }, [user, updateNavbar]);

  useEffect(() => {
    if (!!revokeError) {
      showNotice("There was a problem revoking this invitation, try again later.", { error: true });
    }
    if (!!removeError) {
      showNotice("There was a problem removing this member, please try again later.", { error: true });
    }
    if (!!editError) {
      showNotice("There was a problem updating this member, please try again later.", { error: true });
    }
    if (!!freezeError) {
      showNotice(
        `There was a problem ${
          user?.orgRole === Role.Frozen ? "unfreezing" : "freezing"
        } this member, try again later.`,
        { error: true }
      );
    }
  }, [revokeError, removeError, freezeError, editError]);

  const revokeInviteAction = async () => {
    if (!user) return;
    const revoke = await revokeInvite({ userId: user.id || "" });
    if (revoke) navigate("back");
  };

  const removeMemberAction = async () => {
    if (!user) return;
    const remove = await removeMember({ userId: user.id || "" });
    hideBottomSheet();

    if (remove) {
      navigate("back");
      showNotice(`${user?.firstName} ${user?.lastName} has been successfully removed as a member of this organization.`);
    }
  };

  const toggleFreezeAction = async () => {
    if (!user) return;
    let isFreezing = false;
    if (user.orgRole === Role.Frozen) {
      await thaw({ userId: user.id || "" });
    } else {
      isFreezing = true;
      await freeze({ userId: user.id || "" });
    }

    hideBottomSheet();
    showNotice(`${user?.firstName} ${user?.lastName} has been successfully ${isFreezing ? "frozen" : "unfrozen"}.`);
    setUser(undefined);
    refetch();
  };

  const editAction = async (values: FormFields) => {
    const editSuccess = await edit({ userId: user?.id || "", ...values });

    if (editSuccess) {
      showNotice(`You have successfully updated ${user?.firstName} ${user?.lastName}.`);
    } else {
      showNotice("There was a problem updating this member, please try again later.", { error: true });
    }
    hideBottomSheet();
    refetch();
  };

  const perimssionLevelOptions: SelectOption[] = [
    {
      value: "ADMIN",
      label: Role.Admin
    }
  ];

  const initialFormValues: FormFields = useMemo(() => {
    return { role: user?.orgRole || "ADMIN" };
  }, [user]);

  return (
    <Layout loading={!user}>
      <AppPage>
        <MemberDetailContent>
          <Avatar
            shadow
            color={ColorNames.GRAY5}
            name={user?.firstName ? `${user?.firstName} ${user?.lastName}` : user?.email}
            size={60}
          />

          <MemberDetailInfo>
            {user?.email}
            <div className="role">{`${user?.orgRole}`}</div>
          </MemberDetailInfo>

          <ActionableButtonGroup fullWidth>
            {user?.status !== UserStatus.Pending && (
              <>
                <ActionableButton
                  label="Checking Accounts"
                  extraLabel={`${user?.accounts?.length || ""}`}
                  color={ColorNames.MIDNIGHT}
                  iconRight={!!user?.accounts && user?.accounts.length > 0 ? <ChevronRight /> : <></>}
                  disabled={!!user?.accounts && user?.accounts.length <= 0}
                  onClick={() => {
                    if (!!user?.accounts && user?.accounts?.length > 0) {
                      updateNavbar({ persistedBackAction: () => navigate("back") });
                      navigate(NavRoutes.BANK_ACCOUNTS);
                    }
                  }}
                />
                <ActionableButton
                  label="Cards"
                  extraLabel={`${user?.cards?.length || ""}`}
                  color={ColorNames.GRAY2}
                  iconRight={!!user?.cards && user?.cards.length > 0 ? <ChevronRight /> : <></>}
                  disabled={!!user?.cards && user?.cards.length <= 0}
                  onClick={() => {
                    if (!!user?.cards && user?.cards.length > 0) navigate(`${NavRoutes.MEMBERS}/${memberId}/cards`);
                  }}
                />
                {/* <ActionableButton label="Devices" color={ColorNames.MINT} onClick={() => console.log("1")} /> */}
              </>
            )}
            {canCurrentUserTakeActions && (
              <ActionableButton
                label="Settings"
                color={ColorNames.GRAY2}
                onClick={() => showBottomSheet("settings_member_sheet")}
              />
            )}
          </ActionableButtonGroup>

          {user?.status === UserStatus.Pending && (
            <>
              <BannerNotice
                iconLeft={<IconInfo />}
                textColor={ColorNames.CINNAMON}
                iconColor={ColorNames.GOLD}
                children="Invite has been sent."
              />
              {revokeLoading && <LoadingSkeleton height={40} width="100%" />}
              {!revokeLoading && (
                <ActionableButton
                  label="Revoke Invitation"
                  destructive
                  iconRight={false}
                  onClick={revokeInviteAction}
                />
              )}
            </>
          )}

          {user?.status !== UserStatus.Pending && canCurrentUserTakeActions && (
            <>
              <ActionableButtonRow>
                <ActionableButton
                  label={user?.orgRole === Role.Frozen ? "Unfreeze" : "Freeze"}
                  color={ColorNames.GRAY1}
                  iconRight={user?.orgRole === Role.Frozen ? <IconThaw /> : <IconFreeze />}
                  onClick={() => showBottomSheet("confirm_freeze_member_sheet")}
                />
                <ActionableButton
                  label="Remove"
                  destructive
                  keepBackground
                  iconRight={<IconTrash />}
                  onClick={() => showBottomSheet("confirm_remove_member_sheet")}
                />
              </ActionableButtonRow>
            </>
          )}
        </MemberDetailContent>
      </AppPage>

      <ActionBottomSheet
        id="confirm_freeze_member_sheet"
        title={user?.orgRole === Role.Frozen ? "Unfreeze Member" : "Freeze Member"}
        description={`Are you sure you want to ${user?.orgRole === Role.Frozen ? "unfreeze" : "freeze"} ${
          user?.firstName
        } ${user?.lastName}?`}
        actionText={`${user?.orgRole === Role.Frozen ? "Unfreeze" : "Freeze"} Member`}
        actionLoading={freezeLoading}
        secondaryActionText="Close"
        onAction={toggleFreezeAction}
        onSecondaryAction={() => hideBottomSheet()}
      />

      <ActionBottomSheet
        id="confirm_remove_member_sheet"
        title="Remove Member"
        description={`Are you sure you want to remove ${user?.firstName} ${user?.lastName} from this organization?`}
        actionText="Remove Member"
        actionLoading={removeLoading}
        actionColor={ColorNames.CARDINAL}
        secondaryActionText="Close"
        onAction={removeMemberAction}
        onSecondaryAction={() => hideBottomSheet()}
      />

      <ActionBottomSheet
        id="settings_member_sheet"
        title="Member Settings"
        actionText="Save"
        actionColor={ColorNames.LAKE}
        actionLoading={editLoading}
        onAction={(): void => {
          settingsSubmitRef.current.click();
        }}
        secondaryActionText="Cancel"
        onSecondaryAction={(): void => hideBottomSheet()}
      >
        <Formik validateOnChange={false} validateOnBlur={false} initialValues={initialFormValues} onSubmit={editAction}>
          {({ handleChange, values }) => (
            <StyledForm>
              <StyledFormInputContainer>
                <Select
                  style={{ paddingTop: "unset" }}
                  value={values.role}
                  name="role"
                  options={perimssionLevelOptions}
                  onChange={handleChange}
                />
              </StyledFormInputContainer>
              <input type="submit" hidden ref={settingsSubmitRef} />
            </StyledForm>
          )}
        </Formik>
      </ActionBottomSheet>
    </Layout>
  );
};

export default MemberDetail;
