import React, { useState, useEffect, useRef } from "react";
import { useFetcher } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { useCurrentUser } from "src/services/users";
import { useCurrentOrg } from "src/services/organizations";
import useNavigate from "src/util/useNavigate";
import { showNotice } from "src/store/alertState";
import { useNavbar } from "src/util/useNavbar";
import { useFetchCurrentUser } from "src/services/users";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import {
  useAddBusinessInfo,
  AddBusinessInfoData,
  AddTrustInfoData,
} from "src/services/organizations";
import { useAddVerificationToUser } from "src/services/users";

import { needsLogoutVar } from "src/store/currentUserState";
import AccountType from "src/pages/auth/onboarding/account-type/AccountType";
import { NavRoutes } from "src/routes/navRoutes";
import { ColorNames } from "src/theme/theme";
import { NavigationDirection } from "src/store/navigationState";
import {
  clearOnboardingTermsProgress,
  storedOnboardingLegalDataKey,
} from "src/util/localStorage";

import { DisableAppMenu } from "src/components/side-menu/AppMenu.styles";

import AccountInfo from "src/pages/auth/account-info/AccountInfo";
import PersonalInfo from "src/pages/auth/onboarding/personal-info/PersonalInfo";
import BusinessInfo from "src/pages/auth/onboarding/business-info/BusinessInfo";
import BusinessOwners from "src/pages/auth/onboarding/business-info/BusinessOwners";
import TrustInfo from "src/pages/auth/onboarding/trust-info/TrustInfo";
import TrustCertification from "src/pages/auth/onboarding/trust-info/TrustCertification";
import Terms from "src/pages/auth/onboarding/terms/Terms";
import VerifyIdentity from "src/pages/auth/onboarding/verify-identity/VerifyIdentity";
import { AboutBottomSheet } from "src/components/bottom-sheets/about-bottom-sheet/AboutBottomSheet";
import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { ActionableButtonGroup } from "src/components/actionable-button/ActionableButton.styles";
import Layout from "src/components/core-layout/Layout";
import { ReactComponent as IconHelp } from "src/assets/icons/help.svg";
import { ReactComponent as IconChat } from "src/assets/icons/chat.svg";
import { ReactComponent as IconSignOut } from "src/assets/icons/logout.svg";
import {
  BeneficialOwner,
  OrgType,
  PartnershipType,
  UserIdVerificationName,
  UserVerificationType,
} from "src/generated/client";

// have to brute force these types until @types/styled-components is updated with react 18 support
const DisableAppMenuFixed = DisableAppMenu as unknown as React.FC<
  React.PropsWithChildren<Record<string, never>>
>;

enum AllSteps {
  ACCOUNT_INFO = "account_info",
  PERSONAL = "personal",
  ACCOUNT_TYPE = "account_type",
  TRUST = "trust",
  TRUST_CERTIFICATION = "trust_certification",
  BUSINESS = "business",
  BUSINESS_OWNERS = "business_owners",
  TERMS = "terms",
  VERIFY = "verify",
}

const Onboarding = (): JSX.Element => {
  const { hideBottomSheet, showBottomSheet } = useShowBottomSheet();
  const currentUser = useCurrentUser();
  const currentOrg = useCurrentOrg({ fullFetch: true });

  const { updateNavbar } = useNavbar({
    menu: false,
    stepper: true,
    orgSwitcher: false,
    rightIcon: <IconHelp />,
    rightAction: !!currentUser
      ? () => showBottomSheet("onboarding_help_bottom_sheet")
      : () => {
          window.groove.widget.open();
        },
  });

  const didLoad = useRef<boolean>(false);
  const { fetchCurrentUser } = useFetchCurrentUser();
  const { addBusinessInfo, addOwnerInfo, error } = useAddBusinessInfo();
  const { addVerification, error: addError } = useAddVerificationToUser();

  const [openAccountSteps, setOpenAccountSteps] = useState<string[]>([]);
  const [step, setStep] = useState<string>("");
  const [stepDirection, setStepDirection] = useState(1);

  const [orgType, setOrgType] = useState<OrgType>(OrgType.Individual);
  const [accountPersonalLoading, setAccountPersonalLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (didLoad.current) return;
    if (!currentUser || (!!currentOrg && !currentOrg.accountHolder)) return;

    didLoad.current = true;

    const currentOrgType = currentOrg?.orgType || OrgType.Individual;
    setOrgType(currentOrgType);

    const hasAgreedToAllTerms =
      !!localStorage.getItem(storedOnboardingLegalDataKey.TERMS) &&
      !!localStorage.getItem(storedOnboardingLegalDataKey.BANKING_AGREEMENT) &&
      !!localStorage.getItem(
        storedOnboardingLegalDataKey.ELECTRONIC_DISCLOSURE
      ) &&
      !!localStorage.getItem(storedOnboardingLegalDataKey.PRIVACY_POLICY);

    if (currentUser.onboarded) {
      navigate(NavRoutes.BANK_DASHBOARD, {
        direction: NavigationDirection.FADE,
      });
    } else {
      if (!currentUser.DOB || currentUser.DOB === "")
        return setStep(AllSteps.PERSONAL);
      if (currentUser.organizations.length === 0)
        return setStep(AllSteps.ACCOUNT_TYPE);
      if (currentOrgType === OrgType.Business && !currentOrg?.taxId)
        return setStep(AllSteps.BUSINESS);
      if (
        currentOrgType === OrgType.Business &&
        (!currentOrg?.beneficialOwners ||
          currentOrg.beneficialOwners.length === 0)
      )
        return setStep(AllSteps.BUSINESS_OWNERS);
      if (!hasAgreedToAllTerms) return setStep(AllSteps.TERMS);

      setStep(AllSteps.VERIFY);
    }
  }, [currentUser, currentOrg, navigate]);

  useEffect(() => {
    if (!currentUser) return;
    if (!!currentUser.inviteCode) {
      setOpenAccountSteps([
        AllSteps.ACCOUNT_INFO,
        AllSteps.PERSONAL,
        AllSteps.TERMS,
        AllSteps.VERIFY,
      ]);
    } else {
      if (orgType === OrgType.Individual) {
        setOpenAccountSteps([
          AllSteps.ACCOUNT_INFO,
          AllSteps.PERSONAL,
          AllSteps.ACCOUNT_TYPE,
          AllSteps.TERMS,
          AllSteps.VERIFY,
        ]);
      // } else if (orgType === OrgType.Trust) {
      //   setOpenAccountSteps([
      //     AllSteps.ACCOUNT_INFO,
      //     AllSteps.PERSONAL,
      //     AllSteps.ACCOUNT_TYPE,
      //     AllSteps.TRUST,
      //     AllSteps.TRUST_CERTIFICATION,
      //     AllSteps.TERMS,
      //     AllSteps.VERIFY,
      //   ]);
      } else {
        setOpenAccountSteps([
          AllSteps.ACCOUNT_INFO,
          AllSteps.PERSONAL,
          AllSteps.ACCOUNT_TYPE,
          AllSteps.BUSINESS,
          AllSteps.BUSINESS_OWNERS,
          AllSteps.TERMS,
          AllSteps.VERIFY,
        ]);
      }
    }
  }, [currentUser, orgType, setOpenAccountSteps]);

  useEffect(() => {
    updateNavbar({
      totalSteps: openAccountSteps.length,
      step: openAccountSteps.indexOf(step),
      back: !["", AllSteps.ACCOUNT_INFO].includes(step),
      backAction: !["", AllSteps.ACCOUNT_INFO].includes(step)
        ? handleBackStep
        : undefined,
    });
  }, [step, openAccountSteps]);

  useEffect(() => {
    const err = error || addError;

    if (!!err) {
      showNotice(err.message || "There was a problem creating your account.", {
        error: true,
      });
    }
  }, [error, addError]);

  const addPersonalAccountAsOrg = async (): Promise<void> => {
    setAccountPersonalLoading(true);

    const orgPayload: AddBusinessInfoData = {
      orgType: OrgType.Individual,
      organizationId:
        !currentUser?.inviteCode &&
        currentUser?.organizations &&
        currentUser.organizations.length > 0
          ? currentUser.organizations[0].id
          : undefined,
    };
    const org = await addBusinessInfo({
      values: orgPayload,
      inviteCode: currentUser?.inviteCode,
    });
    if (!error && org) {
      fetchCurrentUser({ fromNetwork: true });
      setOrgType(OrgType.Individual);
      setStepDirection(1);
      setStep(AllSteps.TERMS);
    }
    setAccountPersonalLoading(false);
  };

  const addBusinessAccountAsOrg = async (
    values: Omit<AddBusinessInfoData, "orgType">,
    docs: {
      incorporationCertificate: Blob | undefined;
      einConfirmation: Blob | undefined;
    },
    setSubmitting: (submitting: boolean) => void
  ): Promise<void> => {
    // if (
    //   values.businessOrgData &&
    //   orgData &&
    //   orgData.businessOrgData &&
    //   isSSNObfuscated(values.businessOrgData?.officerSsn)
    // ) {
    //   values.businessOrgData.officerSsn = orgData?.businessOrgData?.officerSsn;
    // }
    // if (values.beneficialOwners && values.beneficialOwners.length && orgData && orgData.beneficialOwners) {
    //   values.beneficialOwners = values.beneficialOwners.map((b) => {
    //     return {
    //       ...b,
    //       // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    //       SSN: (isSSNObfuscated(b.SSN) ? orgData?.beneficialOwners?.find((_b) => _b.email === b.email)?.SSN : b.SSN)!
    //     };
    //   });
    // }

    setSubmitting(true);

    const orgPayload: AddBusinessInfoData = {
      orgType: OrgType.Business,
      organizationId:
        currentUser?.organizations && currentUser.organizations.length > 0
          ? currentUser.organizations[0].id
          : undefined,
      ...values,
    };
    const org = await addBusinessInfo({ values: orgPayload });
    const certificateUpload = await addVerification({
      verificationType: UserVerificationType.Id,
      verificationImage: docs.incorporationCertificate,
      idVerificationName: UserIdVerificationName.CertificateOfIncorporation,
      organizationId: org?.id,
      userId: currentUser?.id || "",
    });

    if (!certificateUpload) {
      setSubmitting(false);
      return;
    }

    const confirmationUpload = await addVerification({
      verificationType: UserVerificationType.Id,
      verificationImage: docs.einConfirmation,
      idVerificationName: UserIdVerificationName.EinConfirmation,
      organizationId: org?.id,
      userId: currentUser?.id || "",
    });

    if (!confirmationUpload) {
      setSubmitting(false);
      return;
    }
    setSubmitting(false);

    if (!!org) {
      fetchCurrentUser({ fromNetwork: true });
      handleNextStep();
    }
  };

  const addBusinessOwners = async (
    owners: BeneficialOwner[],
    setSubmitting: (isSubmitting: boolean) => void
  ): Promise<void> => {
    setSubmitting(true);

    const orgPayload: AddBusinessInfoData = {
      orgType: OrgType.Business,
      organizationId: currentUser?.organizations[0].id,
      beneficialOwners: owners,
    };
    const org = await addOwnerInfo({ values: orgPayload });
    setSubmitting(false);

    if (org) handleNextStep();
  };

  const addTrustAccountAsOrg = async (
    values: Omit<AddTrustInfoData, "orgType">,
    setSubmitting: (submitting: boolean) => void
  ): Promise<void> => {
  //   setSubmitting(true);

  //   const orgPayload: AddTrustInfoData = {
  //     orgType: OrgType.Trust,
  //     organizationId:
  //       currentUser?.organizations && currentUser.organizations.length > 0
  //         ? currentUser.organizations[0].id
  //         : undefined,
  //     ...values,
  //   };
  //   const org = await addBusinessInfo({ values: orgPayload });
  //   setSubmitting(false);

  //   if (org) handleNextStep();
  };

  const acceptTerms = async (
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setSubmitting(false);
    handleNextStep();
  };

  const handleBackStep = async (): Promise<void> => {
    setStepDirection(-1);

    if (step !== AllSteps.ACCOUNT_INFO) {
      setStep(openAccountSteps[openAccountSteps.indexOf(step) - 1]);
    } else {
      navigate(NavRoutes.LANDING, { direction: NavigationDirection.BACKWARD });
    }
  };

  const handleNextStep = async () => {
    setStepDirection(1);
    if (step === AllSteps.PERSONAL && !!currentUser?.inviteCode) {
      await addPersonalAccountAsOrg();
    } else if (step !== AllSteps.VERIFY) {
      setStep(openAccountSteps[openAccountSteps.indexOf(step) + 1]);
    } else {
      clearOnboardingTermsProgress();
      navigate(currentUser?.partnershipType === PartnershipType.TetonRidge ? NavRoutes.ONELINE_DASHBOARD : NavRoutes.BANK_DASHBOARD, {
        direction: NavigationDirection.FADE,
      });
    }
  };

  const getStepComponent = () => {
    let component: React.ReactElement | null = null;
    switch (step) {
      case AllSteps.ACCOUNT_INFO:
        component = (
          <AccountInfo
            nextStep={handleNextStep}
            stepDirection={stepDirection}
          />
        );
        break;
      case AllSteps.PERSONAL:
        component = (
          <PersonalInfo
            nextStep={handleNextStep}
            stepDirection={stepDirection}
          />
        );
        break;
      case AllSteps.ACCOUNT_TYPE:
        component = (
          <AccountType
            loadingPersonal={accountPersonalLoading}
            handlePersonalAccount={addPersonalAccountAsOrg}
            handleBusinessAccount={() => {
              setOrgType(OrgType.Business);
              setStepDirection(1);
              setStep(AllSteps.BUSINESS);
            }}
            handleTrustAccount={() => {
              // setOrgType(OrgType.Trust);
              // setStepDirection(1);
              // setStep(AllSteps.TRUST);
            }}
            stepDirection={stepDirection}
          />
        );
        break;
      case AllSteps.BUSINESS:
        component = (
          <BusinessInfo
            nextStep={addBusinessAccountAsOrg}
            stepDirection={stepDirection}
            orgData={currentOrg}
          />
        );
        break;
      case AllSteps.BUSINESS_OWNERS:
        component = (
          <BusinessOwners
            nextStep={addBusinessOwners}
            stepDirection={stepDirection}
          />
        );
        break;
      case AllSteps.TRUST:
        component = (
          <TrustInfo
            nextStep={addTrustAccountAsOrg}
            stepDirection={stepDirection}
            orgData={
              !!currentUser?.organizations &&
              currentUser?.organizations.length > 0
                ? currentUser?.organizations[0]
                : undefined
            }
          />
        );
        break;
      case AllSteps.TRUST_CERTIFICATION:
        component = (
          <TrustCertification
            nextStep={addBusinessOwners}
            stepDirection={stepDirection}
          />
        );
        break;
      case AllSteps.TERMS:
        component = (
          <Terms
            orgType={orgType}
            nextStep={acceptTerms}
            stepDirection={stepDirection}
          />
        );
        break;
      case AllSteps.VERIFY:
        component = (
          <VerifyIdentity
            nextStep={handleNextStep}
            stepDirection={stepDirection}
          />
        );
        break;
    }

    return component;
  };

  return (
    <Layout loading={!currentUser}>
      <DisableAppMenuFixed />

      {!!currentUser && (
        <AnimatePresence initial={false} exitBeforeEnter custom={stepDirection}>
          <React.Fragment key={step}>{getStepComponent()}</React.Fragment>
        </AnimatePresence>
      )}

      <AboutBottomSheet
        id="open_account_about_bottom_sheet"
        actionText="Continue"
        onAction={(): void => hideBottomSheet()}
      />
      <ActionBottomSheet
        id="onboarding_help_bottom_sheet"
        title="How can we help?"
        children={
          <ActionableButtonGroup fullWidth>
            <ActionableButton
              label="Chat With Us"
              color={ColorNames.PINE}
              iconRight={<IconChat />}
              onClick={() => {
                window.groove.widget.open();
                hideBottomSheet();
              }}
            />
            <ActionableButton
              label="Sign Out"
              color={ColorNames.MINT}
              iconRight={<IconSignOut />}
              onClick={() => {
                needsLogoutVar({ logout: true });
                hideBottomSheet();
              }}
            />
          </ActionableButtonGroup>
        }
        actionColor={ColorNames.TRANSPARENT}
        actionText="Continue"
        onAction={(): void => hideBottomSheet()}
      />
    </Layout>
  );
};

export default Onboarding;
