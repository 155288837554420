import { gql } from "@apollo/client";

export const GET_ALERTS = gql`
  query alerts {
    alerts {
      alerts {
        id
        meta
        alertType
        createdAt
      }
    }
  }
`;