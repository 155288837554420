import React, { useEffect, useState } from "react";

import { AccountTransactionStatuses } from "src/types/account";
import { Transaction } from "src/generated/client";

import { formatTransactionDate, decorateCurrencyValue } from "src/util/stringUtils";

import {
  TransactionItemContainer,
  TransactionItemLeftContainer,
  TransactionItemRightContainer,
  TransactionItemTitleContainer,
  TransactionItemSubtitle,
  TransactionItemAmountContainer
} from "src/components/transaction-item/TransactionItem.styles";
import { DataTestIds } from "src/util/testing-util/test-utils";
import { ReactComponent as IconError } from "src/assets/icons/error.svg";
import { ReactComponent as IconClock } from "src/assets/icons/clock.svg";

export const TransactionItem = ({
  transaction,
  clickHandler
}: {
  transaction: Transaction;
  clickHandler?: any;
}): JSX.Element => {
  const [strikeClass, setStrikeClass] = useState("");
  const [spendClass, setSpendClass] = useState("");
  const [transactionIcon, setTransactionIcon] = useState<JSX.Element>();

  useEffect(() => {
    switch (true) {
      case transaction.status === AccountTransactionStatuses.PENDING ||
        transaction.status === AccountTransactionStatuses.INITIATED ||
        transaction.status === AccountTransactionStatuses.CLEARING ||
        transaction.status === AccountTransactionStatuses.HELD ||
        transaction.status === AccountTransactionStatuses.SENT:
        setTransactionIcon(<IconClock />);
        setStrikeClass("");
        break;
      case transaction.status === AccountTransactionStatuses.DECLINED ||
        transaction.status === AccountTransactionStatuses.REJECTED ||
        transaction.status === AccountTransactionStatuses.DENIED ||
        transaction.status === AccountTransactionStatuses.CANCELED:
        setTransactionIcon(<IconError />);
        setStrikeClass("strike");
        break;
      default:
        setTransactionIcon(undefined);
        setStrikeClass("");
        break;
    }
  }, [transaction.status]);

  useEffect(() => {
    setSpendClass(transaction?.spendType === "Debit" ? "debit" : "");
  }, [transaction.spendType]);

  return (
    <TransactionItemContainer
      data-testid={DataTestIds.LIST_ITEM}
      onClick={clickHandler}
      className={!!clickHandler ? "clickable" : ""}
    >
      <TransactionItemLeftContainer>
        <TransactionItemTitleContainer>{transaction.merchantDescription}</TransactionItemTitleContainer>
        <TransactionItemSubtitle>{formatTransactionDate(transaction.transactionDate)}</TransactionItemSubtitle>
      </TransactionItemLeftContainer>
      <TransactionItemRightContainer>
        <TransactionItemAmountContainer className={`${strikeClass} ${spendClass}`}>
          {decorateCurrencyValue(transaction.amount?.display)}
        </TransactionItemAmountContainer>
        {transaction.status !== AccountTransactionStatuses.SETTLED && transactionIcon}
      </TransactionItemRightContainer>
    </TransactionItemContainer>
  );
};
