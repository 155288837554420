import React, { useMemo } from "react";

import { AccountBalancesGrouped } from "src/generated/client";

import { BalanceGraphContainer } from "./BalanceGraph.styles";
import { colorPalette as colors, ColorNames } from "src/theme/theme";
import { XYChart, AreaSeries, Tooltip, buildChartTheme } from "@visx/xychart";
import { curveMonotoneX } from "@visx/curve";
import { LinearGradient } from "@visx/gradient";

const accessors = {
  xAccessor: (d: AccountBalancesGrouped) => new Date(d?.period),
  yAccessor: (d: AccountBalancesGrouped) => d?.balance.amount,
};

interface BalanceGraphProps {
  data: AccountBalancesGrouped[];
  lineColor?: string;
  playheadColor?: string;
  onUpdateCurrrentBalance?: (currentBalace: AccountBalancesGrouped | null) => void;
}

export const BalanceGraph = ({
  data,
  lineColor = "white",
  onUpdateCurrrentBalance,
  playheadColor = colors[ColorNames.PINE].hex,
}: BalanceGraphProps) => {
  const theme = useMemo(
    () =>
      buildChartTheme({
        backgroundColor: "transparent",
        colors: [lineColor],
        gridColor: playheadColor,
        gridColorDark: "transparent",
        svgLabelBig: { fill: "transparent" },
        tickLength: 0,
      }),
    [lineColor, playheadColor]
  );

  const onUpdateBalance = (balance: null | AccountBalancesGrouped) => {
    if (onUpdateCurrrentBalance) {
      onUpdateCurrrentBalance(balance);
    }
  };

  return (
    <BalanceGraphContainer>
      <XYChart<any, any, AccountBalancesGrouped>
        theme={theme}
        xScale={{ type: "time" }}
        yScale={{ type: "linear" }}
        margin={{ left: 0, top: 20, bottom: 20, right: 0 }}
        onPointerMove={({ datum: currentBalance }) => onUpdateBalance(currentBalance)}
        onPointerOut={() => onUpdateBalance(null)}
      >
        <LinearGradient id="area-gradient" from={lineColor} to={lineColor} toOpacity={0.0} fromOpacity={0.3} />

        <AreaSeries
          fill="url(#area-gradient)"
          strokeWidth={2}
          dataKey="primary_line"
          curve={curveMonotoneX}
          data={data}
          {...accessors}
        />

        <Tooltip
          renderGlyph={() => (
            <circle r="5" stroke={playheadColor} style={{ strokeOpacity: 0.3 }} strokeWidth="10" fill={playheadColor} />
          )}
          showSeriesGlyphs
          showVerticalCrosshair
          showDatumGlyph
          snapTooltipToDatumX
          renderTooltip={() => <></>}
        />
      </XYChart>
    </BalanceGraphContainer>
  );
};
