import React, { useEffect } from "react";

import {
  StyledReadOnlyInput,
  CopyContainer,
  CopyIcon,
  CheckIcon
} from "src/components/forms/read-only-input/ReadOnlyInput.styles";
import { StyledFieldContainer } from "src/components/forms";
import { StyledLabel } from "src/components/text";
import useCopyToClipboard from "src/util/useCopyToClipboard";
import { CypressTestIds, DataTestIds } from "src/util/testing-util/test-utils";

export interface ReadOnlyInputProps {
  label: string;
  name: string;
  value: string;
  copy?: boolean;
  [key: string]: unknown;
}

export const ReadOnlyInput = ({ label, name, value, copy, ...props }: ReadOnlyInputProps): JSX.Element => {
  const { copied, setCopied, handleCopyValue } = useCopyToClipboard();

  useEffect(() => {
    let copiedTimeout: number | null;
    if (copied) {
      copiedTimeout = window.setTimeout(() => {
        setCopied(false);
      }, 3000);
    }

    return (): void => {
      if (copiedTimeout) clearTimeout(copiedTimeout);
    };
  });

  return (
    <StyledFieldContainer data-testid={DataTestIds.FIELD_CONTAINER}>
      <StyledLabel htmlFor={name} data-testid={DataTestIds.LABEL}>
        {label}
      </StyledLabel>

      <CopyContainer>
        <StyledReadOnlyInput data-testid={DataTestIds.READ_ONLY_INPUT} disabled={true} value={value} {...props} />
        {copy &&
          (copied ? (
            <CheckIcon data-testid={DataTestIds.CHECK_ICON} />
          ) : (
            <CopyIcon
              title="Copy Contents"
              onClick={(): void => handleCopyValue(value)}
              data-testid={DataTestIds.COPY_ICON}
              data-cy={CypressTestIds.SIGN_UP_MFA_CODE_COPY}
            />
          ))}
      </CopyContainer>
    </StyledFieldContainer>
  );
};
