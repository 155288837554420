import React, { useState, useEffect } from "react";
import { useParams, useFetcher } from "react-router-dom";

import { showNotice } from "src/store/alertState";
import { useCurrentOrg } from "src/services/organizations";
import { useFetchCurrentUser } from "src/services/users";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { TabRouter, TabView, useSubnav } from "src/util/useSubnav";

import { Deal } from "src/generated/client";
import { AccountRef, Account } from "src/generated/client";
import { ListingDetailComponentProps } from "src/routes/app/marketplace/listings/ListingDetail";
import { decorateCurrencyValue } from "src/util/stringUtils";
import { NavRoutes } from "src/routes/navRoutes";
import { ColorNames } from "src/theme/theme";

import {
  ListingDetailSectionWrapper,
  ListingDetailSectionContent,
  ListingDetailBodyCopy,
  ListingDetailButtonsWrapper
} from "../ListingDetail.styles";

import {
  InvestmentDetailImg
} from "./InvestmentDetail.styles";

import {
  TransferAccountSelectionContainer,
  TransferActionTitle,
  TransferButtonGroup,
  TransferContentContainer,
  TransferFormInput
} from "src/routes/app/bank/transfer/Transfer.styles";

import ErrorElement from "src/ErrorElement";
import { Button } from "src/components/button/Button";
import { LargeCurrencyInput } from "src/components/forms/currency-input/LargeCurrencyInput";
import { CurrencyInput } from "src/components/forms/currency-input/CurrencyInput";
import { DetailSelectAccount } from "src/components/forms/detail-select/DetailSelectAccount";
import { ReactComponent as IconInfo } from "src/assets/icons/info.svg";
import { BannerNotice } from "src/components/alert/BannerNotice";
import { StyledFormInputContainer } from "src/components/forms";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import { ActionableButtonGroup } from "src/components/actionable-button/ActionableButton.styles";
import { InfoListItem } from "src/components/info-list-item/InfoListItem";
import { InfoListItemsWrapper } from "src/components/info-list-item/InfoListItem.styles";
import { ReactComponent as IconLinkOut } from "src/assets/icons/link_out.svg";
import { ReactComponent as IconPlay } from "src/assets/icons/play.svg";
import { ReactComponent as WelcomefundLogo } from "src/assets/marketplace/welcomefund-logo-dark.svg";
import WelcomefundImg from "src/assets/marketplace/welcomefund-details.jpg";
import { ReactComponent as MammothLogo } from "src/assets/marketplace/mammoth-logo-dark.svg";
import MammothImg from "src/assets/marketplace/mammoth-details.jpg";
import { AccreditedInvestorSheet } from "src/components/bottom-sheets/accredited-investor-sheet/AccreditedInvestorSheet";
import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import Layout from "src/components/core-layout/Layout";

export const ListingDetailErrorElement = () => {
  return <ErrorElement redirect={NavRoutes.MARKETPLACE_LISTINGS} alert={true} />;
};

const SUBNAV_TABS = ["overview", "details", "invest"];

const InvestmentDetail = (props: ListingDetailComponentProps): JSX.Element => {
  const { deal, orgDeal, accounts, refetchAccounts } = props;

  const { data, state: fetcherState, Form, ...fetcher } = useFetcher();

  const { section = "overview" } = useParams();
  const { userLoading } = useFetchCurrentUser();
  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();
  const currentOrg = useCurrentOrg();

  const [amountInvesting, setAmountInvesting] = useState(0);
  const [accountSelected, setAccountSelected] = useState(false);
  const [updatedAmountInvesting, setUpdatedAmountInvesting] = useState(0);

  const { selected, selectTab } = useSubnav(SUBNAV_TABS, {
    selectedTab: Math.max(SUBNAV_TABS.indexOf(section), 0)
  });

  useEffect(() => {
    if (!orgDeal) return;
    refetchAccounts({ accountRef: [AccountRef.Checking], selectedId: orgDeal.fundingAccountId });
    orgDeal.committedAmount && setUpdatedAmountInvesting((orgDeal.committedAmount.amount || 0) / 100);
  }, [orgDeal]);

  useEffect(() => {
    if (!!data?.validationErrors && Object.keys(data.validationErrors).length > 0) {
      if (Object.keys(data.validationErrors)[0] === "accredited") {
        showBottomSheet("accredited_investor_sheet");
      } else {
        const firstError = Object.values(data.validationErrors)[0] as string;
        showNotice(firstError, { error: true });
      }
    }
  }, [data?.validationErrors]);

  useEffect(() => {
    if (!!data?.error) {
      const errorMessage = data.error.message || "There was a problem.";
      showNotice(errorMessage, { error: true });
    }
  }, [data?.error]);

  useEffect(() => {
    if (!data?.response) return;

    let message = "";
    if (data.response.commitToDeal) {
      message = "You have successfully committed to this deal.";
    } else if (data.response.updateDealCommitment) {
      message = "You have updated your commitment to this deal.";
    } else if (data.response.deleteDealCommitment) {
      message = "You have cancelled your commitment to this deal.";
      hideBottomSheet();
    }

    showNotice(message);
  }, [data?.response]);

  const didSelectAccount = (account: any) => {
    // returns an array when account is deselected. Bug? What's the expected behaviour?
    if (Array.isArray(account) && account.length === 0) {
      setAccountSelected(false);
    } else {
      setAccountSelected(true);
      // Let's hold off for now on requiring account balances to have the commitment amount, we'll add it back later
      // if (account.totalBalance.amount < (deal?.minInvestment.amount || Infinity)) {
      //   showNotice("Please select an account that has enough funds.", { error: true });
      // }
    }
  };

  const filterAccounts = (deal?: Deal, accounts?: Partial<Account>[]) => {
    if (!accounts || !deal) {
      return [];
    }

    return accounts.filter(
      ({ availableBalance, selected }) => ((deal?.minInvestment && availableBalance) && availableBalance.amount >= deal?.minInvestment?.amount) || selected
    );
  };

  return (
    <Layout backgroundColor={selected === 2 ? ColorNames.GRAY1 : undefined} loading={!deal || userLoading}>
      <TabRouter>
        <TabView key={0} show={selected === 0}>
          <ListingDetailSectionWrapper>
            <ListingDetailSectionContent>
              <InvestmentDetailImg src={deal?.name.toLowerCase() !== "welcomefund" ? MammothImg : WelcomefundImg} />
              {deal?.name.toLowerCase() !== "welcomefund" ? <MammothLogo style={{ width: "150px" }} /> : <WelcomefundLogo style={{ width: "150px" }} />}
              <ListingDetailBodyCopy dangerouslySetInnerHTML={{ __html: deal?.description || "" }} />
              <ActionableButtonGroup fullWidth>
                <ActionableButton
                  label="Invest Now"
                  color={ColorNames.COBALT}
                  iconRight={false}
                  onClick={() => selectTab(SUBNAV_TABS.indexOf("invest"))}
                />
                <ActionableButton
                  label="Deal Details"
                  color={ColorNames.GRAY2}
                  iconRight={false}
                  onClick={() => selectTab(SUBNAV_TABS.indexOf("details"))}
                />
              </ActionableButtonGroup>
            </ListingDetailSectionContent>
          </ListingDetailSectionWrapper>
        </TabView>
        <TabView key={1} show={selected === 1}>
          <ListingDetailSectionWrapper>
            <ListingDetailSectionContent>
              <ActionableButtonGroup fullWidth noTopMargin>
                {deal?.name.toLowerCase() === "mammoth" && 
                  <ActionableButton
                    label={`Watch our Mammoth interview`}
                    color={ColorNames.COBALT}
                    iconRight={<IconPlay />}
                    onClick={() => {
                      window.open("https://youtu.be/i6bZDdvVFt4", "_blank");
                    }}
                  />   
                }
                <ActionableButton
                  label={`View full details at ${deal?.name}`}
                  color={deal?.name.toLowerCase() === "mammoth" ? ColorNames.GRAY2 : ColorNames.COBALT}
                  iconRight={<IconLinkOut />}
                  onClick={() => {
                    window.open(deal?.infoLink, "_blank");
                  }}
                />
              </ActionableButtonGroup>
              <InfoListItemsWrapper>
                <InfoListItem
                  title="Total Raising"
                  value={decorateCurrencyValue(deal?.targetAmount.display)}
                  valueType="number"
                  size="small"
                />
                <InfoListItem
                  title="Min. Investment"
                  value={decorateCurrencyValue(deal?.minInvestment.display)}
                  valueType="number"
                  size="small"
                />
                <InfoListItem
                  title="Targeted Total Return"
                  value={`${deal?.targetedTotalReturn.display}%`}
                  valueType="number"
                  size="small"
                />
                <InfoListItem title="Term Length" value={deal?.termLength} valueType="number" size="small" />
                <InfoListItem title="Close Date" value={deal?.closeDate} valueType="number" size="small" />
                {!!deal?.distributions ? <InfoListItem title="Distributions" value={deal?.distributions} size="small" /> : ''}
                <InfoListItem title="Letter Carry" value={`${deal?.letterFee?.amount}%`} valueType="number" size="small" />
              </InfoListItemsWrapper>

              <ListingDetailBodyCopy dangerouslySetInnerHTML={{ __html: deal?.details || "" }} />

              <ListingDetailBodyCopy size="small">
                Investments carry risk and returns are not guaranteed.
              </ListingDetailBodyCopy>
            </ListingDetailSectionContent>
          </ListingDetailSectionWrapper>
        </TabView>
        <TabView key={2} show={selected === 2}>
          {!orgDeal ? (
            <ListingDetailSectionWrapper>
              <Form method="post">
                <TransferContentContainer>
                  <TransferFormInput>
                    <div>
                      <TransferActionTitle>How much money do you want to invest? </TransferActionTitle>
                      <LargeCurrencyInput
                        name="committedAmount"
                        onChange={(value) => setAmountInvesting(parseFloat(value.replace(/,/g, "")))}
                      />
                    </div>
                  </TransferFormInput>
                  <TransferAccountSelectionContainer>
                    <DetailSelectAccount
                      pickerId="funding_account_id"
                      name="fundingAccountId"
                      bankAccounts={accounts || []}
                      label="Funding Account"
                      placeholder="Choose account..."
                      error={!!data?.validationErrors?.fundingAccountId}
                      search
                      shouldHideNonCheckingTabs
                      didSelectAccount={didSelectAccount}
                    />
                  </TransferAccountSelectionContainer>

                  <InfoListItemsWrapper>
                    <InfoListItem
                      title="Min. Investment"
                      value={decorateCurrencyValue(deal?.minInvestment?.display)}
                      valueType="number"
                      size="small"
                    />
                    <InfoListItem title="Close Date" value={deal?.closeDate || ""} valueType="number" size="small" />
                  </InfoListItemsWrapper>

                  <BannerNotice iconLeft={<IconInfo />} textColor={ColorNames.CINNAMON} iconColor={ColorNames.GOLD}>
                    <>
                      Funds must be available in your account by the Close Date to qualify for investment and allocation.
                    </>
                  </BannerNotice>

                  <input type="hidden" name="accredited" value={(currentOrg?.accredited || false).toString()} />
                  <TransferButtonGroup data-enabled={true}>
                    <Button
                      raised
                      type="submit"
                      color={ColorNames.MIDNIGHT}
                      loading={fetcherState === "submitting"}
                      disabled={!deal || (deal?.minInvestment && amountInvesting * 100 < deal.minInvestment.amount) || !accountSelected}
                    >
                      {currentOrg?.accredited ? "Commit to Invest" : "Continue"}
                    </Button>
                  </TransferButtonGroup>
                </TransferContentContainer>
              </Form>
            </ListingDetailSectionWrapper>
          ) : (
            <ListingDetailSectionWrapper>
              <ListingDetailSectionContent>
                <BannerNotice iconLeft={<IconInfo />} textColor={ColorNames.CINNAMON} iconColor={ColorNames.GOLD}>
                  <>We'll be in touch as the Close Date approaches to finalize the investment.</>
                </BannerNotice>

                <Form method="put">
                  <StyledFormInputContainer>
                    <CurrencyInput
                      name="committedAmount"
                      type="text"
                      label="Investment Amount"
                      defaultValue={(orgDeal.committedAmount?.amount || 0)}
                      error={!!data?.validationErrors?.committedAmount}
                      onChange={(value) => setUpdatedAmountInvesting(parseFloat(value.replace(/,/g, "")))}
                    />
                  </StyledFormInputContainer>

                  <DetailSelectAccount
                    pickerId="funding_account_id"
                    name="fundingAccountId"
                    bankAccounts={filterAccounts(deal, accounts)}
                    defaultValue={accounts?.find((a) => a.selected)?.id}
                    label="Funding Account"
                    placeholder="Choose account..."
                    search
                    error={!!data?.validationErrors?.fundingAccountId}
                    shouldHideNonCheckingTabs={true}
                  />

                  <ListingDetailButtonsWrapper>
                    <Button
                      raised
                      color={ColorNames.LAKE}
                      type="submit"
                      loading={fetcherState === "submitting"}
                      disabled={!deal || !!(deal.minInvestment && updatedAmountInvesting * 100 < deal.minInvestment.amount)}
                      //data-cy={CypressTestIds.Q_ACCOUNT_SAVE}
                    >
                      Update Investment
                    </Button>

                    <ActionableButton
                      label="Cancel Investment"
                      destructive
                      iconRight={false}
                      //data-cy={CypressTestIds.CANCEL_ACCOUNT}
                      onClick={(): void => showBottomSheet("cancel_investment_sheet")}
                    />
                  </ListingDetailButtonsWrapper>
                </Form>
              </ListingDetailSectionContent>
            </ListingDetailSectionWrapper>
          )}
        </TabView>
      </TabRouter>

      <AccreditedInvestorSheet id="accredited_investor_sheet" />

      <ActionBottomSheet
        id="cancel_investment_sheet"
        title="Are you sure?"
        actionText="Cancel Investment"
        actionColor={ColorNames.CARDINAL}
        actionLoading={fetcherState === "submitting"}
        onAction={() => {
          if (fetcherState === "submitting") return;
          fetcher.submit({}, { method: "delete" });
        }}
        secondaryActionText="Close"
        onSecondaryAction={(): void => hideBottomSheet()}
      />
    </Layout>
  );
};

export default InvestmentDetail;
