import React from "react";
import styled from "styled-components";
import { colorPalette as colors, ColorNames } from "src/theme/theme";

const PrivacyPolicyHolder = styled.div`
  ol {
    padding-inline-start: 15px;
  }
  li {
    margin-bottom: 10px;
  }
  table {
    margin: 20px 0;
  }
  td {
    border: 1px solid black;
    vertical-align: top;
    padding: 10px;
  }
  a {
    color: ${colors[ColorNames.MIDNIGHT].hex};
  }
`;

export default (
  <PrivacyPolicyHolder>
    <h1>Privacy Policy</h1>
    <p>By accessing our Website or using our Services you are agreeing to the following Privacy Policy.</p>
    <p>Effective as of: June 1st, 2021.</p>
    <hr />

    <ol>
      <h2>
        <li>Introduction &ndash; Who We Are</li>
      </h2>

      <ol type="I">
        <li>
          Letter Technologies, Inc. and its subsidiaries, affiliates and related entities (collectively, &ldquo;Company"
          or "We" or &ldquo;Us&rdquo;) respects your privacy and is committed to protecting it through our compliance
          with this Privacy Policy. We are also committed to protecting your privacy in accordance with the principles
          laid out in the applicable legislation. This Privacy Policy describes the types of information we may collect
          from you or that you may provide when you access and use www.letter.co (&ldquo;Website&rdquo;) and the
          Company&rsquo;s mobile app or services offered thereon (&ldquo;Letter Platform&rdquo;, and together with the
          Website, collectively, the "Service") and our practices for collecting, using, maintaining, protecting, and
          disclosing that information. This Privacy Policy applies to information We collect: (a) on the Letter Platform
          or through our Services; and (b) in any call, conference, email, text, and other electronic messages between
          you and Us, or through the Service. This Privacy Policy does not apply to any information collected by: (a) Us
          offline or through any other means; and (b) any third party, including through any application or content that
          may link to or be accessible from or in the Service.
        </li>
        <li>
          Please read this Privacy Policy carefully to understand our policies and practices regarding your information
          and how We will treat it. If you do not agree with our policies and practices, your choice is not to use our
          Service. By accessing or using the Service, you agree to this Privacy Policy. This policy may change from time
          to time. Your continued use of the Service after We make changes is deemed to be acceptance of those changes,
          so please check the Privacy Policy periodically for updates.
        </li>
        <li>
          Except as required in order to open a custodial Account, We do not knowingly collect Personal Information
          (defined below) from persons under 18. If you are under 18, do not use or provide any information to our
          Website or on or through the Service - your parent or guardian is responsible for establishing access to the
          Service on your behalf. If you believe We might have received any information from, please contact Us at the
          e-mail at the bottom of this Privacy Policy.
        </li>
      </ol>

      <h2>
        <li>Information We Collect About You and How We Collect It</li>
      </h2>

      <ol type="I">
        <li>
          We may collect several types of information from and about users of our Service, including information: (a) by
          which you may be personally identified, as outlined in the table below ("Personal Information"); (b) that is
          about you but individually does not identify you; and/or (c) about your internet connection, the equipment you
          use to access our Service, and usage details.
        </li>

        <table cellSpacing="0">
          <tbody>
            <tr>
              <td width="50%">
                <p>
                  <strong>Personal Information Category</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Examples of Data</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Identity Information</p>
              </td>
              <td>
                <p>
                  Name, address, and password, Drivers License, Social Security Number, Gender, and/or Other ID types
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Contact Information</p>
              </td>
              <td>
                <p>Home address, email address, billing address, and telephone number.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Financial Information</p>
              </td>
              <td>
                <p>
                  Bank name, bank account and bank routing number, income and expense information,payment card details,
                  investment account balances and account numbers, investment transactions and assets.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Content Information</p>
              </td>
              <td>
                <p>Any content you share while using the Services.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Technical Information</p>
              </td>
              <td>
                <p>
                  Internet protocol (&ldquo;IP&rdquo;) address, your login data, browser type and version, time zone
                  setting and location, browser plug-in types and versions, operating system and platform, and other
                  technology on the devices you use to access this website or use our Services.
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <li>
          We collect this information: (a) directly from you when you provide it to Us; and (b) automatically as you
          navigate through the Website. Information collected automatically may include usage details, location, IP
          address, browser type, and other information collected through cookies, web beacons, and other tracking
          technologies.
        </li>
        <li>
          We may also collect information provided by third parties, including, without limitation, websites or other
          services that post our advertising materials.At times, our business partners, such as our service providers,
          collect personal information and share some or all of this information with Us.
        </li>
      </ol>

      <h2>
        <li>Information You Provide to Us</li>
      </h2>

      <ol type="I">
        <li>
          The information We collect on or through our Service may include: (a) information that you provide by filling
          in forms on our Website, including information provided at the time of registering to use our Website and/or
          Service, requesting services, or reporting a problem with our Service; (b) records and copies of your
          correspondence (including email addresses and voice call details), if you contact Us; (c) details of
          transactions you carry out through our Website, as you may be required to provide financial or other
          information; and (d) your response to surveys We may ask you to complete.
        </li>
        <li>
          We may also receive Personal Information that you provide when you subscribe to our Service and the order is
          processed by another party, such as a payment processor. Such Personal Information may include billing and
          payment information.
        </li>
        <li>
          We receive Personal Information that you provide when registering and communicating with Us or using our
          Service.
        </li>
      </ol>

      <h2>
        <li>Information We Collect Through Automatic Data Collection Technologies</li>
      </h2>

      <ol type="I">
        <li>
          As you navigate through and interact with our Service or with any of our advertising materials on third party
          services, We may use automatic data collection technologies to collect certain information about your
          equipment, interactions with the Service, and patterns, including, without limitation: (a) details of your
          visits to our Website or Service, including traffic data, location data, and other communication data and the
          resources that you access and use on the Service; and (b) information about your computer and internet
          connection.
        </li>
        <li>
          The information We collect through automatic data collection technologies may include Personal Information, or
          We may maintain it or associate it with Personal Information We collect or receive from third parties. It
          helps Us to improve our Service, including by enabling Us to estimate our audience size and usage patterns and
          store information about users&rsquo; preferences. For more information about this, please refer to the
          sections of this Privacy Policy relating to cookies below.
        </li>
        <li>The technologies We use for this automatic data collection may include, without limitation:</li>
        <ol type="a">
          <li>
            Cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. You may
            refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you
            select this setting, you may be unable to access certain parts of our Website or Service. Unless you have
            adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct
            your browser to our Website or Service.
          </li>
          <li>
            Web Beacons. Pages of our Website and our e-mails may contain small electronic files known as web beacons
            (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to
            count users who have visited those pages or opened an email and for other related websites.
          </li>
          <li>
            Tracking Pixels. Certain features of our Service may use tracking pixels to allow Us to advertise more
            efficiently by excluding certain users from promotional content or delivering advertisements on other
            websites.
          </li>
          <li>
            Google Analytics and Other Similar Services. We may use Google Analytics and other similar services that use
            cookies to help Us analyze how users interact with our Services and improve your experience in it.
          </li>
        </ol>
      </ol>

      <h2>
        <li>Third Party Use of Cookies and Other Tracking Technologies</li>
      </h2>

      <ol type="I">
        <li>
          Some content or applications, including advertisements, on the Service are served by one or more third
          parties, including advertisers, ad networks and servers, content providers, and application providers. These
          third parties may use cookies or other tracking technologies to collect information about you when you use our
          Service. The information they collect may be associated with your Personal Information or they may collect
          information, including Personal Information, about your online activities over time and across different
          websites and other online services.
        </li>
        <li>
          We do not control these third parties&rsquo; tracking technologies or how they may be used. If you have any
          questions about an advertisement or other targeted content, you should contact the responsible provider
          directly.
        </li>
      </ol>

      <h2>
        <li>How We Use Your Information</li>
      </h2>

      <ol type="I">
        <li>
          We use information that We collect about you or that you provide to Us, including any Personal Information:
          (a) to present our Service and its contents to you; (b) to provide you with information, products, or services
          that you request from Us; (c) to fulfill any other purpose for which you provide it; (d) to perform and
          develop services, deliveries, payments, and products in connection with our Service; (e) to carry out our
          obligations and enforce our rights arising from any contracts entered into between you and Us, including for
          billing, collection, bookkeeping, support, and marketing; (f) to notify you about changes to our Website,
          Service or any products or services We offer or provide through them;(g) to develop our Services and provide
          better services, including by identifying and correcting technical issues; (h) in any other way We may
          describe when you provide the information; and (I) for any other purpose with your consent.
        </li>
        <li>
          We will keep your Personal Information in our possession for the period necessary for the fulfillment of our
          collection purposes, as provided in Section 6 (1) above.
        </li>
      </ol>

      <h2>
        <li>Disclosure of Your Information</li>
      </h2>

      <ol type="I">
        <li>
          We may disclose aggregated information about our users, and information that does not identify any individual,
          without restriction.
        </li>
        <li>
          We may disclose Personal Information that We collect or you provide as described in this Privacy Policy: (a)
          to contractors, service providers, and other third parties We use to support our business, including, but not
          limited to firms for payment processing, developing, operations, sales, support, regulatory and tax filings,
          data analysis, and marketing; (b) to a buyer or other successor in the event of a merger, divestiture,
          restructuring, reorganization, dissolution, or other sale or transfer of some or all of Company&rsquo;s
          assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which
          Personal Information held by Company about our customers and/or users is among the assets transferred; (c) to
          fulfill the purpose for which you provide it; (d) for any other purpose disclosed by us when you provide the
          information; or (e) with your consent.
        </li>
        <li>
          We may also disclose your Personal Information: (a) to comply with any court order, law, or legal process,
          including to respond to any legal duty, government or regulatory request; (b) to enforce or apply any other
          agreements, including but not limited to for billing and collection purposes; (c) if we believe disclosure is
          necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others,
          including but not limited to exchanging information with other companies and organizations for the purposes of
          fraud protection and credit risk reduction.
        </li>
      </ol>

      <h2>
        <li>Choices About How We Use and Disclose Your Information</li>
      </h2>

      <ol type="I">
        <li>
          We strive to provide you with choices regarding the Personal Information you provide to Us. We have created
          mechanisms to provide you with the following control over your information:
        </li>
        <ol type="a">
          <li>
            Tracking Technologies and Advertising. You can set your browser to refuse all or some browser cookies, or to
            alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of the
            Service may then be inaccessible or not function properly.
          </li>
        </ol>
        <li>
          We do not control third parties&rsquo; collection or use of your information to serve interest-based
          advertising. However, these third parties may provide you with ways to choose not to have your information
          collected or used in this way.
        </li>
      </ol>

      <h2>
        <li>Your Privacy Rights</li>
      </h2>

      <ol type="I">
        <li>
          Certain jurisdictions may permit certain users of our Service to withdraw their consent to this Privacy
          Policy. To make such a request, please send an email to the e-mail address at the bottom of this Privacy
          Policy. Please note that by withdrawing your consent to this Privacy Policy, you will not be able to use the
          Website or use our Service. We will process such request in accordance with the applicable law.
        </li>
        <li>
          You may send Us an email at the e-mail address at the bottom of this Privacy Policy to request access to,
          object, correct, or delete any Personal Information that you have provided to Us, as well as obtain
          restriction of processing in the cases provided by the applicable law. However, We reserve the right to not
          accommodate a request to change information if We believe the change would violate any law or legal
          requirement or cause the information to be incorrect.
        </li>
        <li>
          Except as required in order to open a custodial Account, Our Services are not directed to, and We do not
          intend to, or knowingly, collect or solicit personal information directly from children under the age of 18.
          If you are under the age of 18, do not use our Website or Services or provide Us with any personal information
          either directly or by other means. If a child under 18 has provided personal information to Us, We encourage
          the child&rsquo;s parent or guardian to contact Us to request that We remove the personal information from our
          systems. If We learn that any personal information We collect has been provided by a child under the age of
          18, We will promptly delete that personal information. A child&rsquo;s parent or guardian may provide the
          child&rsquo;s information to Us as part of a household.
        </li>
        <li>
          In case of any inobservance with our legal requirements and duties, you have the right to file a complaint
          with the applicable data protection authority.
        </li>
      </ol>

      <h2>
        <li>Data Security</li>
      </h2>

      <ol type="I">
        <li>
          We have implemented measures designed to secure your personal information from accidental loss and from
          unauthorized access, use, alteration, and disclosure, which include physical, technical, and operational
          controls.Data security is achieved through technical safeguards.Personal data is encrypted and accessible to
          the User and other parties to which the User may provide access. We also conduct vulnerability scans of
          applications and systems periodically.
        </li>
        <li>
          The safety and security of your information also depends on you. When you have provided a password or login
          details for access to certain parts of our Website, you are responsible for keeping this password or login
          confidential. We require you not to share your password and login details with anyone.
        </li>
        <li>
          The Company takes the security of your data seriously. Unfortunately, the transmission of information via the
          internet is not completely secure. Although We do our best to protect your Personal Information, We cannot
          guarantee the security of your Personal Information transmitted to our Service. Unless otherwise provided in
          the applicable legislation, any transmission of Personal Information is at your own risk.
        </li>
      </ol>

      <h2>
        <li>Transfer to other Countries</li>
      </h2>

      <ol type="I">
        <li>
          European Union citizens and others understand and agree that the Personal Information collected may be
          transferred to third countries, as the Company is based in the United States and some third parties supporting
          our business may be located in the United States or other countries. Your Personal Information may therefore
          be subject to privacy laws that are different from those in your country of residence. Please note that some
          countries may not offer the same type of protection and privacy for Personal Information provided. If you are
          located outside of the United States, please note you are agreeing that we transfer the data, including
          Personal Information, to the United States and process it there.
        </li>
      </ol>

      <h2>
        <li>Notice to European Union Residents</li>
      </h2>

      <ol type="I">
        <p>
          <em>
            Legal Basis for Processing Personal Data under the General Data Protection Regulation (&ldquo;GDPR&rdquo;)
          </em>
        </p>
        <li>
          We may process your Personal Information because: (a) We need to perform a contract with you; (b) you have
          given Us permission to do so under the terms of this Privacy Policy; (c) the processing is in our legitimate
          interest and it is not overridden by your rights; (d) We may need to collect or process payment in exchange
          for the Services; and (e) We need to comply with applicable laws.
        </li>
        <p>
          <em>Your Data Protection Rights under the General Data Protection Regulation (GDPR)</em>
        </p>
        <li>
          We strive to comply with all applicable laws relating to our collection and processing of your Personal
          Information. In certain circumstances and as provided under applicable law, you have the following data
          protection rights: (a) the right to access, update, or delete the information We have on you; (b) the right to
          rectify any information that is inaccurate or incomplete; (c) the right to object to any unlawful processing
          of your Personal Information; (d) the right to request the restriction of our processing of your Personal
          Information; (e) the right to know and receive a copy of the information We may have on you; and (f) the right
          to withdraw your consent at any time where We relied on your consent to process your Personal Information.
        </li>
        <li>
          For any requests relating to your rights under the GDPR, please notify Us at the email address at the bottom
          of this page. Furthermore, you have the right to complain to a Data Protection Authority about our collection
          and use of your Personal Information.
        </li>
      </ol>

      <h2>
        <li>Privacy Policies of Linked Sites and Apps</li>
      </h2>

      <p>
        Our Service may contain links to other sites, web-apps, forms, files, and documents managed by the Company to
        conduct our services to you. Additionally, our Service may contain links to third party websites or
        applications. We are not responsible for the privacy practices or the content of such third parties. Please note
        that, once you leave our Service or Website, third parties&rsquo; privacy policies will apply to any information
        collected. If you have any questions about how these other websites or applications use your information, you
        should review their policies and contact them directly. This Privacy Policy applies solely to information
        collected by Us or otherwise stored by Us.
      </p>

      <h2>
        <li>Changes to Our Privacy Policy</li>
      </h2>

      <p>
        It is our practice to post any changes we make to our Privacy Policy on this page. If we make material changes
        to how We treat our users&rsquo; Personal Information, We will notify you by updating our Privacy Policy on the
        Website. You are responsible for periodically visiting our Website and this Privacy Policy to check for any
        changes.
      </p>

      <h2>
        <li>Governing Law and Dispute Resolution</li>
      </h2>

      <p>
        This Privacy Policy and any dispute arising hereunder will be governed by the laws of the State of Texas and/or
        applicable federal laws of the United States of America without regard to its choice of law or conflicts of law
        principles. Both you and the Company agree that all claims and disputes arising out of or relating to this
        Privacy Policy will be litigated exclusively in the courts located in McKinney, Texas.
      </p>

      <h2>
        <li>Contact Information</li>
      </h2>

      <p>
        To ask questions or comment about this Privacy Policy and our privacy practices, contact us by email:{" "}
        <a href="mailto:help@letter.co">help@letter.co</a>
      </p>
    </ol>
  </PrivacyPolicyHolder>
);
