import React from "react";

import {
  StyledAddModal,
  AddModalTextContainer,
  CheckIconContainer,
  IconContainer,
  CheckIcon,
  AddIcon
} from "src/components/cta/add-modal/AddModal.styles";
import { CtaTitle, CtaDescription } from "src/components/text";
import { ColorNames } from "src/theme/theme";
import { DataTestIds } from "src/util/testing-util/test-utils";
import { GlobalSvgs } from "src/components/global-svgs/GlobalSvgs";
import { LogoSpinner } from "src/components/logo-spinner/LogoSpinner";
import { ReactComponent as Eye } from "src/assets/icons/eye.svg";
export interface AddModalProps {
  title: string;
  description?: string;
  complete?: boolean;
  disabled?: boolean;
  loading?: boolean;
  reviewing?: boolean;
  dataCy?: string;
  onClick?: () => void;
}

export const AddModal = ({
  title,
  description,
  complete,
  disabled,
  loading,
  reviewing,
  dataCy = "",
  onClick
}: AddModalProps): JSX.Element => {
  return (
    <StyledAddModal
      data-cy={dataCy}
      onClick={onClick && onClick}
      small={!description}
      complete={complete}
      disabled={disabled}
      $loading={loading}
      reviewing={reviewing}
      data-testid={DataTestIds.ADD_MODAL}
    >
      <AddModalTextContainer>
        <CtaTitle>{title}</CtaTitle>
        {description && <CtaDescription>{description}</CtaDescription>}
      </AddModalTextContainer>
      {complete ? (
        <CheckIconContainer small={!description}>
          <CheckIcon data-testid={DataTestIds.CHECK_ICON} />
        </CheckIconContainer>
      ) : loading ? (
        <IconContainer small={!description}>
          <GlobalSvgs />
          <LogoSpinner color={ColorNames.MIDNIGHT} data-testid={DataTestIds.LOGO_SPINNER} />
        </IconContainer>
      ) : reviewing ? (
        <IconContainer small={!description}>
          <Eye />
        </IconContainer>
      ) : (
        <IconContainer small={!description}>
          <AddIcon data-testid={DataTestIds.ADD_ICON} />
        </IconContainer>
      )}
    </StyledAddModal>
  );
};
