import { useCallback, useEffect, useState } from "react";
import { client } from "src";
import { useMutation, ApolloError, useLazyQuery } from "@apollo/client";
import { CreatePaymentInput } from "src/generated/client";
import { useLogger } from "src/util/useLogger";
import { convertStringNumberToCents } from "src/util/stringUtils";


// TODO: REPLACE WITH GENERATED GQL
import {
  CREATE_PAYMENT,
  GET_INSTITUTION_INFO
} from "src/services/gql/_payments";


export interface InstitutionSchema {
  routingNumber: string;
  name: string;
  isAchSupported: boolean;
  isWireSupported: boolean;
  address: string;
}

export enum TargetAccountType {
  ACCOUNT = "Account",
  CONNECTED_ACCOUNT = "ConnectedAccount",
  RECIPIENT = "Recipient"
}

export type CreatePaymentVariables = {
  input: CreatePaymentInput;
};

export type CreatePaymentData = {
  createPayment: {
    id: string;
    amount: number;
    description: string;
  };
};

export const createPayment = async (values: CreatePaymentInput) => {
  try {
    const res = await client.mutate({
      mutation: CREATE_PAYMENT,
      errorPolicy: "all",
      variables: { input: { ...values } }
    });

    if (!!res.data?.createPayment) {
      return { createPayment: true };
    } else {
      let errorMessage = "There was a problem making this transfer.";
      if (!!res.errors && res.errors.length > 0) {
        errorMessage = res.errors[0].message;
      }
      throw Error(errorMessage);
    }
  } catch (err) {
    throw err;
  }
};

export const useCreatePayment = (): {
  payment: (values: CreatePaymentInput) => Promise<boolean>;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [account, { loading, error }] = useMutation<CreatePaymentData, CreatePaymentVariables>(CREATE_PAYMENT, {
    errorPolicy: "all"
  });
  const { captureException } = useLogger();

  const payment = useCallback(
    async (values: CreatePaymentInput): Promise<boolean> => {
      try {
        const { data } = await account({
          variables: {
            input: {
              ...values,
              amount: convertStringNumberToCents(values.amount.toString())
            }
          }
        });
        return !!data?.createPayment;
      } catch (err) {
        captureException(err);
        return false;
      }
    },
    [account, captureException]
  );

  return { payment, loading, error };
};

export const useGetInstitutionInfo = (): {
  getInstitutionInfo: (options?: any | undefined) => void;
  institutionData: InstitutionSchema | undefined;
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const [institution, { data, loading, error }] = useLazyQuery(GET_INSTITUTION_INFO, {
    errorPolicy: "all",
    fetchPolicy: "network-only"
  });
  const [institutionData, setInstitutionData] = useState<InstitutionSchema>();

  const getInstitutionInfo = useCallback(
    (routingNumber: string): void => {
      institution({ variables: { routingNumber } });
    },
    [institution]
  );

  useEffect(() => {
    if (!!data?.institutionInfo) {
      setInstitutionData(data?.institutionInfo);
    }
  }, [data]);

  return { getInstitutionInfo, institutionData, loading, error };
};
