import React, { useEffect, useState, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { useShowBottomSheet } from "src/util/useBottomSheet";
import { showNotice } from "src/store/alertState";

import { useCurrentUser } from "src/services/users";
import { needsLogoutVar } from "src/store/currentUserState";
import { CypressTestIds } from "src/util/testing-util/test-utils";
import { deviceState } from "src/store/deviceState";
import States from "src/data/states.json";
import { ActionBottomSheet } from "src/components/bottom-sheets/action-bottom-sheet/ActionBottomSheet";
import { Button } from "src/components/button/Button";
import { Input } from "src/components/forms/input/Input";
import { Select } from "src/components/forms/select/FormikSelect";
import { Toggle } from "src/components/toggle/Toggle";
import { ActionableButton } from "src/components/actionable-button/ActionableButton";
import {
  PageContent,
  TabViewContainerOverride,
  ButtonsWrapper,
} from "./MyAccount.styles";
import {
  StyledFormInputContainer,
  StyledForm,
  InputRow,
  StyledFieldContainer,
  FormSectionHeader,
} from "src/components/forms";
import { useChangePassword } from "src/services/auth";
import {
  useEditUser,
  EditUserData,
  useAddPersonalInfo,
  useCancelAccount,
  useAddDeviceToken,
  useEditNotificationSettings,
} from "src/services/users";
import { formatPhoneNumber } from "src/util/stringUtils";
import { ColorNames } from "src/theme/theme";
import NetWorth from "src/data/netWorth.json";
import { NewPasswordInput } from "src/components/forms/new-password-input/NewPasswordInput";
import { MINIMUM_PASSWORD_STRENGTH_SCORE } from "src";
import { useNavbar } from "src/util/useNavbar";
import Layout from "src/components/core-layout/Layout";
import { TabRouter, TabView, useSubnav } from "src/util/useSubnav";
import {
  useTriggerVerification,
  useAddVerificationToUser,
  UserVerificationType,
} from "src/services/users";
import { ReactComponent as IconLock } from "src/assets/icons/lock.svg";
import { ReactComponent as IconAccredited } from "src/assets/icons/accredited.svg";
import { BottomSheet } from "src/components/bottom-sheets/BottomSheet";
import EnableMfa, {
  EnableMfaSteps,
} from "src/pages/auth/onboarding/mfa/EnableMfa";
import { BannerNotice } from "src/components/alert/BannerNotice";

// have to brute force these types until @types/styled-components is updated with react 18 support
const TabViewContainerOverrideFixed =
  TabViewContainerOverride as unknown as React.FC<
    React.PropsWithChildren<Record<string, never>>
  >;

interface UpdateUserFormValues extends EditUserData {
  oldPassword: string;
  newPassword: string;
  DOB: string;
  SSN: string;
  email?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zip?: string;
  userId: string;
}

const netWorthOptions = [...NetWorth];

const UpdateUserSchema: Yup.ObjectSchema<object> = Yup.object().shape({
  firstName: Yup.string().required("Please add your first name."),
  lastName: Yup.string().required("Please add your last name."),
  phone: Yup.string()
    .min(14, "Please use a valid phone number.")
    .required("Please add your phone."),
  // email: Yup.string().email("Please use a valid email address.").required("Please add your email."),
  oldPassword: Yup.string().when("newPassword", {
    is: (newPassword) => !!newPassword,
    then: Yup.string().required("Please enter your current password."),
    otherwise: Yup.string(),
  }),
  newPassword: Yup.string()
    .min(8, "Please use a longer password.")
    .max(100, "Please use a shorter password."),
});

const SUBNAV_TABS = ["settings", "personal", "security"];

const MyAccount = (): JSX.Element => {
  useNavbar({ title: "Account" });
  const { section = "settings" } = useParams();
  const [prevPhoneValue, setPrevPhoneValue] = useState("");
  const [areValuesSet, toggleValues] = useState(false);
  const [initialFormValues, setInitialFormValues] =
    useState<UpdateUserFormValues>({
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      netWorth: "",
      DOB: "",
      SSN: "",
      oldPassword: "",
      newPassword: "",
      streetAddress: "",
      city: "",
      state: "",
      zip: "",
      userId: "",
    });
  const [receiveSms, setSMS] = useState(false);
  const [receivePush, setReceivePush] = useState(false);
  const updateAccountSubmitRef = useRef<HTMLInputElement>(null);
  const [notificationSettingsDirty, setNotificationSettingsDirty] =
    useState(false);
  const { edit, loading, error } = useEditUser(false);
  const {
    addInfo,
    loading: addLoading,
    error: addError,
  } = useAddPersonalInfo();
  const {
    changePassword,
    loading: passwordLoading,
    error: passwordError,
  } = useChangePassword();
  const { addDeviceToken, error: deviceTokenError } = useAddDeviceToken();
  const {
    editNotificationSettings,
    loading: notificationSettingsLoading,
    error: editNotificationsError,
  } = useEditNotificationSettings();
  const {
    trigger,
    loading: triggerLoading,
    error: triggerError,
  } = useTriggerVerification();
  const {
    addVerification,
    loading: verificationInProgress,
    error: verificationError,
  } = useAddVerificationToUser();
  const {
    cancel,
    loading: cancelLoading,
    error: cancelAccountError,
  } = useCancelAccount();
  const currentUser = useCurrentUser();
  const device = useRecoilValue(deviceState);

  const { showBottomSheet, hideBottomSheet } = useShowBottomSheet();
  const [passwordScore, setPasswordScore] = useState(0);
  const { selected } = useSubnav(SUBNAV_TABS, {
    selectedTab: Math.max(SUBNAV_TABS.indexOf(section), 0),
  });
  const statesForDropdown = [
    {
      label: "Choose state",
      value: "",
    },
    ...States,
  ];

  useEffect(() => {
    if (currentUser && !areValuesSet) {
      setSMS(currentUser?.notifications?.receiveSms || false);
      setReceivePush(currentUser?.notifications?.receivePush || false);
      setInitialFormValues({
        firstName: currentUser?.firstName || "",
        lastName: currentUser?.lastName || "",
        phone: currentUser?.phone ? formatPhoneNumber(currentUser?.phone) : "",
        email: currentUser?.email || "",
        netWorth: currentUser?.netWorth || netWorthOptions[0].value,
        DOB: currentUser?.DOB || "",
        SSN: currentUser?.SSN || "",
        oldPassword: "",
        newPassword: "",
        streetAddress: currentUser?.streetAddress || "",
        city: currentUser?.city || "",
        state: currentUser?.state || "",
        zip: currentUser?.zip || "",
        userId: currentUser?.id || "",
      });
      toggleValues(true);
    }
  }, [currentUser, areValuesSet]);

  useEffect(() => {
    if (passwordError) {
      showNotice(
        error?.message || "There was a problem updating your password.",
        { error: true }
      );
    }
    if (error || deviceTokenError || editNotificationsError) {
      showNotice(error?.message || "There was a problem updating your info.", {
        error: true,
      });

      if (deviceTokenError) {
        setReceivePush(false);
      }
    }
  }, [
    error,
    passwordError,
    cancelAccountError,
    deviceTokenError,
    editNotificationsError,
  ]);

  useEffect(() => {
    if (cancelAccountError) {
      showNotice("There was a problem closing your account.", { error: true });
    }
  }, [cancelAccountError]);

  useEffect(() => {
    if (verificationError) {
      showNotice(
        "There was problem verifying your phone number, please try again.",
        { error: true }
      );
    }
  }, [verificationError]);

  const handleUpdatePersonal = async (
    values: UpdateUserFormValues,
    setSubmitting: (submitting: boolean) => void
  ): Promise<void> => {
    const {
      oldPassword,
      newPassword,
      email,
      firstName,
      lastName,
      phone,
      netWorth,
      ...addressInfo
    } = values;

    await addInfo({ ...addressInfo, userId: currentUser?.id || "" });
    const user = await edit({
      firstName,
      lastName,
      phone,
      netWorth,
      userId: currentUser?.id || "",
    });

    if (user) {
      showNotice("Account updated");
      const {
        lastName,
        firstName,
        phone,
        streetAddress,
        zip,
        state,
        city,
        verified,
      } = user;

      if (!verified.phone) triggerPhoneNumberVerification();

      const updatedUserDetails = {
        lastName,
        firstName,
        phone,
        streetAddress,
        zip,
        state,
        city,
      };

      setInitialFormValues((prevUserDetails) => ({
        ...prevUserDetails,
        ...updatedUserDetails,
        phone: formatPhoneNumber(phone),
      }));
    }

    setSubmitting(false);
  };

  const handleUpdateSecurity = async (
    values: UpdateUserFormValues,
    setSubmitting: (submitting: boolean) => void,
    setFieldValue: (field: string, value: string) => void
  ): Promise<void> => {
    const { oldPassword, newPassword } = values;

    if (!!newPassword && passwordScore < MINIMUM_PASSWORD_STRENGTH_SCORE) {
      setSubmitting(false);
      showNotice("Please use a stronger password.", { error: true });
      return;
    }

    if (oldPassword && newPassword) {
      const passwordUpdated = await changePassword({
        oldPassword,
        newPassword,
      });
      if (passwordUpdated) showNotice("Password updated");
    }

    setSubmitting(false);
    setFieldValue("newPassword", "");
    setFieldValue("oldPassword", "");
  };

  const handlePhoneNumber = (
    event: React.ChangeEvent<HTMLInputElement>
  ): string => {
    const formattedNumber = formatPhoneNumber(
      event.target.value,
      prevPhoneValue
    );
    setPrevPhoneValue(formattedNumber);
    return formattedNumber;
  };

  const handleCancelAccount = async (): Promise<void> => {
    const success = await cancel();
    hideBottomSheet();
    if (success) {
      needsLogoutVar({ logout: true, supressAlert: true });
      showNotice("You have successfully cancelled your account.");
    }
  };

  const updatePasswordScore = (data: number) => {
    setPasswordScore(data);
  };

  const triggerPhoneNumberVerification = async () => {
    if (triggerLoading) return;

    const triggered = await trigger(UserVerificationType.Phone);

    if (triggered) {
      showBottomSheet("verify_phone_sheet");
    } else {
      showNotice(
        "There was problem verifying your phone number, please try again.",
        { error: true }
      );
    }
  };

  const handleMfaVerification = async (): Promise<void> => {
    showNotice("MFA successfully updated.");
    hideBottomSheet();
  };

  return (
    <>
      <TabViewContainerOverrideFixed />
      <Layout>
        <TabRouter>
          <TabView key={0} show={selected === 0}>
            <PageContent>
              <Formik
                enableReinitialize={true}
                initialValues={initialFormValues}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={async (values, { setSubmitting }): Promise<void> => {
                  if (notificationSettingsDirty) {
                    const updateNotifications = await editNotificationSettings({
                      receivePush,
                      receiveSms,
                    });

                    setTimeout(() => {
                      if (receivePush && device.deviceToken)
                        addDeviceToken({ deviceToken: device.deviceToken });
                    }, 500);

                    if (updateNotifications) showNotice("Settings updated");
                  }
                  setSubmitting(false);
                }}
              >
                <StyledForm>
                  <div style={{ width: "100%" }}>
                    {!!currentUser?.accredited && (
                      <BannerNotice
                        iconLeft={<IconAccredited />}
                        iconColor={ColorNames.BABY}
                        textColor={ColorNames.BABY}
                        titleColor={ColorNames.COBALT}
                        backgroundColor={ColorNames.POWDER}
                        title="Accredited Investor"
                      >
                        <>
                          Your accreditation status has been verified and
                          confirmed.
                        </>
                      </BannerNotice>
                    )}

                    <StyledFormInputContainer className="input-container-for-toggle">
                      <StyledFieldContainer>
                        <Toggle
                          displayLabel="Push Notifications"
                          name="notifications.receivePush"
                          checked={receivePush}
                          align="left"
                          onChange={(): void => {
                            setNotificationSettingsDirty(true);
                            setReceivePush(!receivePush);
                          }}
                          cy={CypressTestIds.TOGGLE_PUSH}
                        />
                      </StyledFieldContainer>
                      <StyledFieldContainer>
                        <Toggle
                          displayLabel="SMS Notifications"
                          name="notifications.receiveSMS"
                          checked={receiveSms}
                          align="left"
                          onChange={(): void => {
                            setNotificationSettingsDirty(true);
                            setSMS(!receiveSms);
                          }}
                          cy={CypressTestIds.TOGGLE_SMS}
                        />
                      </StyledFieldContainer>
                    </StyledFormInputContainer>
                  </div>

                  <Button
                    raised
                    color={ColorNames.LAKE}
                    type="submit"
                    loading={loading || notificationSettingsLoading}
                    data-cy={CypressTestIds.Q_ACCOUNT_SAVE}
                  >
                    Save
                  </Button>
                </StyledForm>
              </Formik>
            </PageContent>
          </TabView>
          <TabView key={1} show={selected === 1}>
            <PageContent>
              <Formik
                enableReinitialize
                initialValues={initialFormValues}
                validationSchema={UpdateUserSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, { setSubmitting }) => {
                  handleUpdatePersonal(values, setSubmitting);
                }}
              >
                {({ handleChange, setFieldValue, values }): JSX.Element => (
                  <StyledForm>
                    <StyledFormInputContainer>
                      <Input
                        name="firstName"
                        type="text"
                        label="First Name"
                        autoComplete="given-name"
                      />
                      <Input
                        name="lastName"
                        type="text"
                        label="Last Name"
                        autoComplete="family-name"
                      />
                      <Input
                        name="phone"
                        type="text"
                        label="Phone"
                        autoComplete="tel"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ): void => {
                          handleChange(event);
                          setFieldValue("phone", handlePhoneNumber(event));
                        }}
                      />
                      <Input
                        disabled
                        name="email"
                        type="email"
                        label="Email"
                        autoComplete="email"
                      />
                      <Input
                        name="streetAddress"
                        type="text"
                        label="Street Address"
                        autocomplete="shipping street-address"
                      />
                      <Input
                        name="city"
                        type="test"
                        label="City"
                        autocomplete="shipping locality"
                      />
                      <InputRow>
                        <Select
                          name="state"
                          label="State"
                          options={statesForDropdown}
                          autocomplete="shipping region"
                        />
                        <Input
                          name="zip"
                          type="test"
                          label="ZIP Code"
                          autocomplete="shipping postal-code"
                        />
                      </InputRow>
                    </StyledFormInputContainer>
                    <ButtonsWrapper>
                      <Button
                        raised
                        color={ColorNames.LAKE}
                        type="submit"
                        loading={loading || addLoading || triggerLoading}
                        data-cy={CypressTestIds.Q_ACCOUNT_SAVE}
                      >
                        Save
                      </Button>

                      <ActionableButton
                        label="Close Account"
                        destructive
                        iconRight={false}
                        dataCy={CypressTestIds.CANCEL_ACCOUNT}
                        onClick={(): void =>
                          showBottomSheet("queue_cancel_account_sheet")
                        }
                      />
                    </ButtonsWrapper>
                    <input type="submit" hidden ref={updateAccountSubmitRef} />
                  </StyledForm>
                )}
              </Formik>

              <ActionBottomSheet
                id="queue_cancel_account_sheet"
                title="Are you sure?"
                description={`Canceling your account will permanently remove all your data, and you will be removed from all other Letter organizations you've joined. 
  
  You will not be able to cancel your account until: 
  1) All transactions in your accounts have cleared 
  2) All checking account deposits you own are moved to another owner or withdrawn from Letter`}
                actionText="Cancel My Account"
                actionColor={ColorNames.CARDINAL}
                secondaryActionText="Never Mind, Keep My Account"
                onAction={handleCancelAccount}
                onSecondaryAction={(): void => hideBottomSheet()}
                actionLoading={cancelLoading}
                primaryActionTag={CypressTestIds.CONFIRM_CANCEL_ACCOUNT}
              />
              <ActionBottomSheet
                id="verify_phone_sheet"
                title="Verify your phone number to continue."
                description={`We’ve sent a verification code to your phone number ${initialFormValues.phone}. Enter that code below.`}
                actionText="Verify"
                secondaryActionText="Resend Code"
                onAction={async (token: string) => {
                  const user = await addVerification({
                    verificationType: UserVerificationType.Phone,
                    token,
                    userId: currentUser?.id || "",
                  });
                  if (!!user) {
                    hideBottomSheet();
                    showNotice(
                      "You have successfully verified your phone number."
                    );
                  }
                }}
                onSecondaryAction={() => triggerPhoneNumberVerification()}
                codeInput
                autoSubmit
                actionLoading={verificationInProgress}
                secondaryActionLoading={triggerLoading}
              />
            </PageContent>
          </TabView>
          <TabView key={2} show={selected === 2}>
            <PageContent>
              <Formik
                enableReinitialize={true}
                initialValues={initialFormValues}
                validationSchema={UpdateUserSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, { setSubmitting, setFieldValue }): void => {
                  handleUpdateSecurity(values, setSubmitting, setFieldValue);
                }}
              >
                <StyledForm>
                  <div style={{ width: "100%" }}>
                    <ActionableButton
                      label="Reconfigure MFA"
                      color={ColorNames.GRAY2}
                      iconRight={<IconLock />}
                      onClick={(): void =>
                        showBottomSheet("reconfigure_mfa_sheet")
                      }
                    />

                    <StyledFormInputContainer style={{ marginTop: "30px" }}>
                      <FormSectionHeader>Change Password</FormSectionHeader>
                      <Input
                        name="oldPassword"
                        type="password"
                        label="Old Password"
                        autoComplete="current-password"
                      />
                      <NewPasswordInput
                        name="newPassword"
                        label="New Password"
                        updateFormScore={updatePasswordScore}
                      />
                    </StyledFormInputContainer>
                  </div>

                  <Button
                    raised
                    color={ColorNames.LAKE}
                    type="submit"
                    loading={loading || passwordLoading}
                    data-cy={CypressTestIds.Q_ACCOUNT_SAVE}
                  >
                    Save
                  </Button>
                </StyledForm>
              </Formik>
            </PageContent>
          </TabView>
        </TabRouter>
        <BottomSheet noPadding id="reconfigure_mfa_sheet">
          <EnableMfa
            startingStep={EnableMfaSteps.VERIFY}
            onSuccess={() => handleMfaVerification()}
          />
        </BottomSheet>
      </Layout>
    </>
  );
};

export default MyAccount;
