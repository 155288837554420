import React, { useState } from "react";
import { useShowBottomSheet } from "src/util/useBottomSheet";
import { StepperNav } from "src/components/nav-bars/stepper-nav/StepperNav";
import DownloadMfa from "src/pages/mfa/download-mfa/DownloadMfa";
import VerifyMfa from "src/pages/mfa/verify-mfa/VerifyMfa";
import { ReactComponent as IconClose } from "src/assets/icons/close.svg";

export enum EnableMfaSteps {
  DOWNLOAD,
  VERIFY
}

const EnableMfa = ({
  onSuccess,
  startingStep
}: {
  onSuccess: () => void;
  startingStep?: EnableMfaSteps;
}): JSX.Element => {
  const stepToStartAt = startingStep ? startingStep : EnableMfaSteps.DOWNLOAD;
  const [step, setStep] = useState<number>(stepToStartAt);
  const { hideBottomSheet } = useShowBottomSheet();

  const handleNextStep = (): void => {
    if (step === EnableMfaSteps.DOWNLOAD) {
      setStep((step) => step + 1);
    }
  };

  const handleBackClick = (): void => {
    setStep(EnableMfaSteps.DOWNLOAD);
  };

  let mfaStepComponent;
  switch (step) {
    case EnableMfaSteps.DOWNLOAD:
      mfaStepComponent = <DownloadMfa handleNextStep={handleNextStep} />;
      break;
    case EnableMfaSteps.VERIFY:
      mfaStepComponent = <VerifyMfa onSuccess={onSuccess} />;
      break;
  }

  return (
    <>
      <StepperNav
        hideBackButton={step === EnableMfaSteps.DOWNLOAD}
        onBackClick={handleBackClick}
        step={step}
        totalSteps={2}
        actionIcon={<IconClose />}
        onActionClick={() => hideBottomSheet()}
      />
      {mfaStepComponent}
    </>
  );
};

export default EnableMfa;
