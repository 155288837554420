import styled from "styled-components";
import { LayoutFullPage } from "src/components/core-layout/CoreLayout.styles";
import { BannerNoticeContainer } from "src/components/alert/Alerts.styles";
import { ReactComponent as LetterLogo } from "src/assets/logo-word.svg";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

export const StyledLandingPage = styled(LayoutFullPage)`
  @supports (-webkit-touch-callout: none) {
    min-height: calc(100vh - 83px);
  }
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;

  ${BannerNoticeContainer} {
    margin-top: auto;
  }

  &.has-partner {
    background: ${colors[ColorNames.MIDNIGHT].hex};
    padding: 0;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  form {
    flex: 0 0 auto;

    &.auction-early {
      margin-top: auto;

      button {
        margin-top: 40px;
      }
    }
  }
`;

export const LogoContainer = styled.div`
  padding: 0 15px;
  padding-top: 15vh;
`;

export const Logo = styled(LetterLogo)`
  margin-bottom: 38px;
`;

export const LandingTitle = styled.h1`
  ${fonts.HEADING_1}
  color: ${colors[ColorNames.MIDNIGHT].hex};
`;

export const LandingCaption = styled.p<{ gray?: boolean }>`
  ${fonts.PARAGRAPH_L}
  text-align: center;
  color: ${({ gray }): string => gray ? colors[ColorNames.GRAY5].hex : colors[ColorNames.MIDNIGHT].hex};
  margin: 30px 0 10px;
`;


export const PartnerHeader = styled.div`
  position: relative;
  flex: 1 1 0px;
  color: ${colors[ColorNames.WHITE].hex};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px 60px;

  &:after {
    content: "";
    position: absolute;
    top: 20%;
    right: 0;
    bottom: -40px;
    left: 0;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0, rgba(0,0,0,0.7) 70%);
  }

  .partner-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: -40px;
    left: 0;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .partner-logo {
    z-index: 1;
    margin-bottom: 40px;
    width: 100px;
  }

  p {
    z-index: 1;
  }
`;

export const PartnerSheet = styled.div`
  position: relative;
  z-index: 2;
  flex: 1 1 0px;
  background: ${colors[ColorNames.WHITE].hex};
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  padding: 60px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  text-align: center;

  .inner-content {
    flex: 1 1 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    max-width: var(--maxContentWidth);
  }

  p {
    padding: 0 20px;
  }

  @media (min-width: 768px) {
    flex: 2 1 0px;
    border-radius: 0;

    .letter-branding {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
`;

export const TetonRidgeAuctionsContainer = styled.div`
  flex: 1 1 0px;
  overflow-y: scroll;
  margin: 20px 0 40px;
`;

export const StyledFailedCreditCheckPage = styled(StyledLandingPage)`
  text-align: center;
  justify-content: center;
  gap: 2em;

  ${LandingTitle} {
    ${fonts.HEADING_4}
    margin-bottom: 20px;
  }

  ${LandingCaption} {
    margin: 0;
  }

  a {
    color: ${colors[ColorNames.MIDNIGHT].hex};
  }
`;