import { ActionFunction } from "react-router-dom";
import * as Yup from "yup";

import { createPayment } from "src/services/payments";
import { CreatePaymentInput, UnitAchDirection, TargetAccountType } from "src/generated/client";
import { ERROR_MESSAGE_MFA_VERIFICATION } from "src/routes/api/mfa";
import { ERROR_MESSAGE_TRANSFER_NEEDS_CONFIRMATION } from "./Send";
import { getFormValidationErrors } from "src/util/forms";
import { convertStringNumberToCents } from "src/util/stringUtils";
import { RouteActionResponse } from "src";

export const sendAction: ActionFunction = async ({ request }) => {
  const formData = Object.fromEntries(await request.formData());

  const data = {
    sourceAccountId: (formData.sourceAccountId as string) || "",
    targetAccountId: (formData.targetAccountId as string) || "",
    amount: convertStringNumberToCents((formData.amount as string) || ""),
    targetAccountType: (formData.targetAccountType as TargetAccountType) || "",
    sourceAccountType: (formData.sourceAccountType as TargetAccountType) || "",
    transferType: (formData.transferType as string) || "",
    sourceAccountBalance: (formData.sourceAccountBalance),
    hasVerifiedMfa: ((formData.hasVerifiedMfa as string) || "false") === "true",
    hasConfirmedTransfer: ((formData.hasConfirmedTransfer as string) || "false") === "true"
  }

  type TransferSchema = {
    sourceAccountId: string;
    targetAccountId: string;
    amount: number;
    hasConfirmedTransfer: boolean;
  }

  const schema = Yup.object<TransferSchema>().shape({
    sourceAccountId: Yup.string().required("Please choose a funding account."),
    targetAccountId: Yup.string().required("Please choose an account where the deposit will be sent."),
    amount: Yup.number()
      .when(["sourceAccountType", "targetAccountType"], {
        is: (sourceAccountType, targetAccountType) => sourceAccountType && targetAccountType && sourceAccountType === TargetAccountType.Account && targetAccountType === TargetAccountType.Account,
        then: Yup.number().min(1, "Please set an amount greater than $0."),
        otherwise: Yup.number().min(300, "Please set an amount over $3."),
      })
      .when("transferType", {
        is: (value) => value && value.toLowerCase() === "wire",
        then: Yup.number().min(10000, "Wire transaction amounts must be at least $100.")
      })
      .when("sourceAccountType", {
        is: (value) => value && value === TargetAccountType.ConnectedAccount,
        then: Yup.number().max(2500000, "There is a $25,000 limit on pulling money via ACH.")
      })
      .when("hasVerifiedMfa", {
        is: (value) => !value,
        then: Yup.number().max(100000, ERROR_MESSAGE_MFA_VERIFICATION)
      })
      .when("sourceAccountBalance", (sourceAccountBalance: number, schema) =>
        schema.test({
          test: (amount: number) => sourceAccountBalance >= amount,
          message: "The source account does not have enough funds to complete this transaction.",
        })
      ),
    hasConfirmedTransfer: Yup.boolean().oneOf([true], ERROR_MESSAGE_TRANSFER_NEEDS_CONFIRMATION)
  });

  try {
    await schema.validate(data, { abortEarly: false });

    let payload: CreatePaymentInput = {
      sourceAccountId: data.sourceAccountId,
      targetAccountId: data.targetAccountId,
      amount: data.amount,
      direction: UnitAchDirection.Credit,
      targetAccountType: data.targetAccountType
    };

    if (data.sourceAccountType === TargetAccountType.ConnectedAccount) {
      // flip these details for the transer
      payload = {
        sourceAccountId: data.targetAccountId,
        targetAccountId: data.sourceAccountId,
        amount: data.amount,
        direction: UnitAchDirection.Debit,
        targetAccountType: TargetAccountType.ConnectedAccount
      };
    }

    const response = await createPayment(payload);
    return { response };
  } catch (error) {
    const response: RouteActionResponse<unknown> = {};
    if (error instanceof Yup.ValidationError) {
      response.validationErrors = getFormValidationErrors(error);
    } else {
      response.error = error;
    }

    return response;
  }
};
