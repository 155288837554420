import styled from "styled-components";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";

export const MfaContentContainer = styled.div`
  width: 100%;
  padding: 0 30px;
  max-width: 570px;
  margin: 0 auto;
  min-height: calc(100% - 128px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 50px;

  button {
    margin-top: 30px;
  }

  .desktop-only {
    display: none;
    @media (min-width: 900px) {
      display: inline;
    }
  }
`;

export const MfaHeader = styled.p`
  ${fonts.HEADING_4}
  margin-bottom: 20px;
  color: ${colors[ColorNames.MIDNIGHT].hex};
`;

export const MfaDescription = styled.p`
  ${fonts.PARAGRAPH_M}
  margin-bottom: 26px;
  color: ${colors[ColorNames.MIDNIGHT].hex};
`;

export const StepHeader = styled.p`
  font-weight: bold;
  font-size: 28px;
  line-height: 140%;
  margin-bottom: 10px;
  color: ${colors[ColorNames.MIDNIGHT].hex};
`;

export const ExternalLink = styled.a`
  color: ${colors[ColorNames.LAKE].hex};
  text-decoration: none;
  transition: opacity 0.2s;
  will-change: opacity;

  &:hover {
    opacity: 0.5;
  }
`;

export const ExternalLinkListItem = styled(ExternalLink)`
  margin-bottom: 12px;
  display: list-item;
  list-style-position: inside;
`;

export const InputContainer = styled.div`
  margin: 5px 0 55px;
`;
