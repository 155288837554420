import { useState, useEffect } from "react";
import { useRecoilCallback, useSetRecoilState } from "recoil";
import { detailPickers, detailPickerSelector, IDetailPicker } from "src/store/detailPickerState";

// registers a detail picker
export const useSetDetailPicker = () => {
  const [pickerId, setPickerId] = useState<string>();

  const setDetailPicker = useRecoilCallback(
    ({ set }) =>
      (options: IDetailPicker) => {
        setPickerId(options.id);
        set(detailPickers(options.id), { ...options });
      },
    []
  );

  const unsetDetailPicker = useRecoilCallback(
    ({ reset, set }) =>
      () => {
        if (!!pickerId) {
          reset(detailPickers(pickerId));
          set(detailPickerSelector, { pickerId, hide: true });
        }
      },
    [pickerId]
  );

  useEffect(() => {
    return unsetDetailPicker;
  }, [unsetDetailPicker]);

  return setDetailPicker;
};

// shows / hides a registered picker
export const useShowDetailPicker = () => {
  const setDetailPickers = useSetRecoilState(detailPickerSelector);

  const showDetailPicker = (pickerId: string | null) => {
    setDetailPickers({ pickerId, hide: false });
  };

  // showId = null to hide all pickers
  const hideDetailPicker = (pickerId?: string) => {
    setDetailPickers({ pickerId, hide: true });
  };

  return { showDetailPicker, hideDetailPicker };
};
