import styled, { css, keyframes, FlattenSimpleInterpolation } from "styled-components";
import { LogoSpinnerProps } from "src/components/logo-spinner/LogoSpinner";

export const StyledLogoSpinner = styled.div<LogoSpinnerProps>`
  ${({ theme, height, color }: LogoSpinnerProps): FlattenSimpleInterpolation => {
    const defaultHeight = height || 32;
    const heightNumber = parseInt(defaultHeight.toString().replace(/\D/g, ""));
    const totalWidth = heightNumber * 50.5;

    const keys = keyframes`
      100% { transform: translate3d(-${totalWidth - heightNumber}px, 0, 0); }
    `;

    return css`
      height: ${heightNumber}px;
      width: ${heightNumber}px;
      overflow: hidden;

      svg {
        height: ${heightNumber}px;
        width: ${totalWidth}px;
        fill: ${theme?.colors[color || "dark"]};
        transform: translate3d (0, 0, 0);
        animation: ${keys} 2s steps(50) infinite;
        backface-visibility: hidden;
        perspective: 1000;
      }
    `;
  }}
`;
