import styled from "styled-components/macro";
import { DetailRowData, DetailRowLabel, StyledDetailRow } from "src/components/detail-row/DetailRow.styles";
import {
  ActionableButtonRow,
  ActionableButtonGroup,
  StyledActionableButton
} from "src/components/actionable-button/ActionableButton.styles";
import { colorPalette as colors, ColorNames, fonts } from "src/theme/theme";
import CardFrost from "src/assets/card-frost.png";

export const CardDetailsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 46px;
  position: relative;
`;

export const FrozenCardOverlay = styled.div`
  background: url(${CardFrost}) repeat center;
  background-color: rgba(255, 255, 255, 0);
  background-size: 350px;
  backdrop-filter: blur(8px);
  border-radius: 10px;
  mix-blend-mode: screen;
  clip-path: circle(0% at 41% 76%);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: clip-path 0.75s ease, background-color 2s ease;

  &.frozen {
    clip-path: circle(100% at 50% 50%);
    background-color: rgba(0, 0, 255, 0.25);
    transition: clip-path 1.75s ease, background-color 4s ease;
  }
`;

export const CardDetailsContainer = styled.div`
  position: relative;
  border-radius: 10px;
  margin: 0 30px;
  /* z-index fix for org switcher backdrop blur */
  z-index: 1;
`;

export const CardDetailsDropShadow = styled.div`
  position: absolute;
  left: 8px;
  right: 8px;
  top: 60px;
  bottom: 0px;
  background: transparent;
  box-shadow: 0px 76px 80px rgba(163, 178, 174, 0.2), 0px 31.751px 33.4221px rgba(163, 178, 174, 0.143771),
    0px 16.9756px 17.869px rgba(163, 178, 174, 0.119221), 0px 9.51638px 10.0172px rgba(163, 178, 174, 0.1),
    0px 5.05408px 5.32008px rgba(163, 178, 174, 0.0807786), 0px 2.10311px 2.21381px rgba(163, 179, 174, 0.0562291);
  border-radius: 10px;
`;

export const StyledCardDetails = styled.div<{ background: string }>`
  position: relative;
  border-radius: 10px;
  padding: 20px;
  background-image: ${({ background }): string => `url(${background})`};
  background-repeat: none;
  background-size: cover;
  box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CardNumber = styled.p<{
  color: ColorNames.MIDNIGHT | ColorNames.WHITE;
}>`
  ${fonts.NUMBER_XL}
  color: ${({ color }): string => colors[color].hex};
`;

export const CardNameHolder = styled.div<{
  color: ColorNames.MIDNIGHT | ColorNames.WHITE | ColorNames.GRAY4;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${({ color }): string => colors[color].hex};

  svg {
    flex: 0 0 auto;
    margin-left: 10px;
  }
`;

export const CardStatusHolder = styled.div`
  ${fonts.TAG}
  line-height: 18px;
  height: 17px;
  padding: 0 10px;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  border-radius: 9px;
  color: white;
  text-transform: uppercase;
`;

export const CardHolderInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CardName = styled.p<{
  color: ColorNames.MIDNIGHT | ColorNames.WHITE;
}>`
  ${fonts.PARAGRAPH_XL}
  color: ${({ color }): string => colors[color].hex};
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
`;

export const CardDetailRows = styled.div<{
  color: ColorNames.MIDNIGHT | ColorNames.WHITE;
}>`
  position: relative;
  margin: 40px 0 30px;

  ${StyledDetailRow} {
    padding: 7px 0;
  }

  ${DetailRowLabel} {
    color: rgba(${({ color }): string => colors[color].rgb}, 0.6);
  }

  ${DetailRowData} {
    color: ${({ color }): string => colors[color].hex};
  }
`;

export const CardActions = styled.div`
  ${ActionableButtonRow} {
    padding: 0;
    gap: 10px;
    margin: 0;

    & > * {
      flex: 1 1 0%;
      margin: 0;
    }
    ${StyledActionableButton} {
      color: white;
      background: rgba(${colors[ColorNames.MIDNIGHT].rgb}, 0.4);
      backdrop-filter: blur(10px);
    }
  }

  ${ActionableButtonGroup} {
    padding: 10px 0 0;
    gap: 10px;
    margin: 0;

    ${StyledActionableButton} {
      margin: 0;
      color: ${colors[ColorNames.MIDNIGHT].hex};
      background: rgba(${colors[ColorNames.GRAY1].rgb}, 0.4);
      backdrop-filter: blur(10px);
    }
  }
`;

export const CardGraphHolder = styled.div`
  margin: 40px 0 30px;
  padding: 0 30px;
`;

export const CardGraphLabel = styled.div`
  ${fonts.PARAGRAPH_M}
  color: ${colors[ColorNames.GRAY5].hex};
  margin-bottom: 16px;
`;

export const CardGraph = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`;

export const CardGraphRow = styled.div<{ current: boolean; future: boolean }>`
  height: 112px;
  flex: 0 0 0%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 8px;

  .track {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;

    .track-bar {
      flex: 1 1 auto;
      width: 6px;
      border-radius: 3px;
      background-color: ${({ future }): string =>
        future ? colors[ColorNames.GRAY1].hex : colors[ColorNames.MINT].hex};

      &.spent {
        flex: 0 0 auto;
        background-color: ${colors[ColorNames.SEAFOAM].hex};
      }
    }
  }

  .label {
    flex: 0 0 28px;
    width: 28px;
    ${fonts.PARAGRAPH_S}
    color: ${({ future }): string => (future ? colors[ColorNames.GRAY3].hex : colors[ColorNames.PINE].hex)};
    text-align: center;
    line-height: 28px;
    border-radius: 14px;
    background-color: ${({ current }): string => (current ? colors[ColorNames.MINT].hex : "transparent")};
  }
`;

export const CardTransactions = styled.div`
  background: ${colors[ColorNames.GRAY1].hex};
  padding: 30px 0;
`;

export const CardTransactionsTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${fonts.PARAGRAPH_L}
  padding: 0 30px;
  margin-bottom: 8px;
`;

export const CardTransactionsRows = styled.div`
  padding-left: 30px;
`;

export const CardTransactionsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 30px 16px 10px;
  border-bottom: 1px solid ${colors[ColorNames.GRAY2].hex};
`;

export const CardTransactionsRowInfo = styled.div`
  ${fonts.PARAGRAPH_S}

  .date {
    margin-top: 2px;
    color: ${colors[ColorNames.GRAY5].hex};
  }
`;

export const CardTransactionsRowAmount = styled.div`
  ${fonts.NUMBER_M}
  text-align: right;
`;
